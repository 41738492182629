import {
  Dropdown,
  DropDownOptionProps,
  NativeButton,
  Textbox,
} from "@rpe-js/marcom-web-components";
import * as React from "react";
import {
  FormEvent,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import APP_CONSTANTS from "../../../utilities/appConstants";
import {
  fetchStoreLocations,
  getPostLocationTypeahead,
} from "../../api/fetchClient";
import { LocationPickerContext } from "../../contexts/LocationPickerContext";
import useIntlMessage from "../../hooks/useIntlMessage";
import { idGenerator } from "../../utils/idGenerator";
import LocationPickerTypeahead from "./LocationPickerTypeahead";

export function LocationPickerSearch() {
  const context = useContext(LocationPickerContext);
  if (!context) {
    throw new Error(
      "LocationPickerHeader must be used within a LocationPickerProvider",
    );
  }
  const {
    setLocationResults,
    setLocationResultsLoading,
    setLocationResultsError,
    setZipResults,
    setZipResultsLoading,
    setZipResultsError,
    distance,
    setDistance,
    jobDetails,
    zip,
    setZip,
    setSearch,
    mode,
    setMode,
    postLocation,
    setPostLocation,
    countryCode,
    clientParams,
    setClientParams,
    setSelectedStores,
    search,
  } = context;
  const { id, locations } = jobDetails;
  const locationId = locations.length > 0 && locations[0].postLocationCountryID;
  const isPostingLocationUS = locationId === APP_CONSTANTS.POST_LOCATION_USA;
  const { t } = useIntlMessage();
  const dropdownOptionsObject: DropDownOptionProps[] =
    APP_CONSTANTS.LOCATION_PICKER_DISTANCES.map((distance) => ({
      label: `${distance} miles`,
      value: `${distance}`,
    }));
  const [typeAheadResults, setTypeAheadResults] = useState<Array<any>>([]);
  const [typeAheadLoading, setTypeAheadLoading] = useState(false);
  const [typeAheadError, setTypeAheadError] = useState(false);
  const [zipInput, setZipInput] = useState("");
  const [locationInput, setLocationInput] = useState("");
  const [popoverOpen, setPopoverOpen] = useState(false);

  const isInitialRender = useRef(true);
  const locationInputRef = useRef<HTMLInputElement>(null);
  const zipInputRef = useRef<HTMLInputElement>(null);
  const typeAheadRef = useRef<any>(null);
  const typeAheadButtonRef = useRef<any>(null);

  const typeaheadAllyLabelId = idGenerator(
    "locationPicker",
    "search",
  ).generateId("typeahead-label");

  function highlightedReducer(
    state: number,
    action: { type: any; payload?: any },
  ) {
    switch (action.type) {
      case "increment":
        return state + 1;
      case "decrement":
        return state - 1;
      case "set":
        return action.payload;
      case "reset":
        return 0;
      default:
        return 0;
    }
  }

  const [highlightedPopoverIndex, highlightedDispatch] = useReducer(
    highlightedReducer,
    0,
  );

  function searchInputChanged(value: any) {
    setLocationInput(value);
    setPopoverOpen(true);
  }

  function handleSuggestionSelect(
    suggestion: Record<string, string>,
    event: React.KeyboardEvent<HTMLInputElement>,
  ) {
    handleSubmit(event as unknown as React.FormEvent<HTMLFormElement>);
  }

  function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    const { key } = event;
    if (!typeAheadResults || typeAheadResults.length < 1) {
      return;
    }

    switch (key) {
      case "ArrowUp":
        // don't increment if user reached the top
        if (highlightedPopoverIndex === 0) {
          return;
        }
        highlightedDispatch({ type: "decrement" });
        break;
      case "ArrowDown":
        // don't increment if user reached the end
        if (highlightedPopoverIndex === typeAheadResults.length - 1) {
          return;
        }
        highlightedDispatch({ type: "increment" });
        break;
      case "Enter":
        if (typeAheadResults.length > 0) {
          handleSuggestionSelect(
            typeAheadResults[highlightedPopoverIndex],
            event,
          );
        }
        break;
      case "Escape":
        if (popoverOpen) {
          setPopoverOpen(false);
        }
      default:
        break;
    }
  }

  function onInputFocus() {
    setPopoverOpen(true);
  }

  function resetZipInput() {
    setSelectedStores([]);
    resetState();
    zipInputRef.current?.focus();
  }

  function resetSearchInput() {
    setSelectedStores([]);
    resetState();
    locationInputRef.current?.focus();
  }

  function resetState() {
    // reset zip related state
    setZip("");
    setZipResults([]);
    setZipInput("");
    setDistance("10");
    // location related state
    setLocationInput("");
    setPostLocation("");
    setSearch("");
    setLocationResults([]);
    setTypeAheadResults([]);
    setPopoverOpen(false);
  }

  function handleModeChange() {
    if (mode === "zip") {
      setMode("location");
      resetState();
    }
    if (mode === "location") {
      setMode("zip");
      setPostLocation("");
      resetState();
    }
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (zipInput.length === 0 && locationInput.length === 0) {
      return;
    }
    if (mode === "location") {
      if (typeAheadResults.length > 0) {
        const highlightedLocation: any =
          typeAheadResults[highlightedPopoverIndex];

        setPopoverOpen(false);
        setPostLocation(highlightedLocation.id);
        setLocationInput(highlightedLocation.titleName);
        setSearch(highlightedLocation.titleName);
      } else {
        // else continue with invalid posting location
        setPopoverOpen(false);
        setLocationResults([]);
        setPostLocation(locationInput);
        setSearch(locationInput);
        setLocationInput(locationInput);
      }
    }
    if (mode === "zip") {
      if (zipInput.length === 0) {
        return;
      }
      setZip(zipInput);
    }
  }

  const handleClickOutside = (event: any) => {
    if (typeAheadRef.current && !typeAheadRef.current.contains(event.target)) {
      setPopoverOpen(false);
    }
  };
  const handleFocusOutside = (event: any) => {
    if (
      (typeAheadRef.current && !typeAheadRef.current.contains(event.target)) ||
      (typeAheadButtonRef.current &&
        typeAheadButtonRef.current.contains(event.target))
    ) {
      setPopoverOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("focus", handleFocusOutside, true);
    return () => {
      document.removeEventListener("focus", handleFocusOutside, true);
    };
  }, []);

  useEffect(() => {
    // Ignore auto focus on input on initial render since we want to auto focus on heading instead
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    if (mode === "zip") {
      zipInputRef.current?.focus();
      setZipInput(zip);
    } else if (mode === "location") {
      locationInputRef.current?.focus();
      setLocationInput(search);
      setPopoverOpen(false);
    }
  }, [mode, search, zip]);

  // fetch postLocation store results
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLocationResultsError(false);
        setLocationResultsLoading(true);
        const storeLocations = (await fetchStoreLocations(
          id,
          {
            fieldValue: postLocation,
          },
          countryCode,
        )) as Record<string, any>[];
        setLocationResultsLoading(false);
        setLocationResults(storeLocations);
        setLocationResultsError(false);
      } catch (error) {
        setLocationResultsError(true);
        setLocationResultsLoading(false);
        setLocationResults([]);
      }
    };

    if (postLocation && id) {
      fetchData();
    }
  }, [
    id,
    postLocation,
    countryCode,
    setLocationResults,
    setLocationResultsError,
    setLocationResultsLoading,
  ]);
  // fetch zip code results
  useEffect(() => {
    const fetchData = async () => {
      try {
        setZipResults([]);
        setZipResultsError(false);
        setZipResultsLoading(true);
        const storeLocations = (await fetchStoreLocations(
          id,
          {
            searchField: "zipCode",
            fieldValue: zip,
            milesRange: distance,
          },
          countryCode,
        )) as Record<string, any>[];
        setZipResultsLoading(false);
        setZipResults(storeLocations);
        setZipResultsError(false);
      } catch (error) {
        setZipResultsError(true);
        setZipResultsLoading(false);
        setZipResults([]);
      }
    };
    // must have loaded id and only make the api call if it is valid
    if (id && zip.length === 5 && distance) {
      fetchData();
    } else {
      // bad input show no results
      setZipResults([]);
    }
  }, [
    id,
    distance,
    zip,
    countryCode,
    setZipResultsError,
    setZipResultsLoading,
    setZipResults,
  ]);
  // fetch type ahead results
  useEffect(() => {
    const fetchData = async () => {
      try {
        setTypeAheadError(false);
        setTypeAheadLoading(true);
        const typeAheadLocations = (await getPostLocationTypeahead(
          locationInput,
          locationId,
          countryCode.data,
        )) as Array<any>;
        highlightedDispatch({ type: "reset" });
        setTypeAheadLoading(false);
        setTypeAheadResults(typeAheadLocations);
        setTypeAheadError(false);
      } catch (error) {
        setTypeAheadError(true);
        setTypeAheadLoading(false);
        setTypeAheadResults([]);
      }
    }; // only make the api call if it is valid
    if (locationInput.length > 0) {
      fetchData();
    }
  }, [locationInput, countryCode, locationId]);
  useEffect(() => {
    const params = new URLSearchParams(clientParams);
    if (postLocation) {
      params.set("l", postLocation);
    } else {
      params.delete("l");
    }
    if (search) {
      params.set("s", search);
    } else {
      params.delete("s");
    }
    if (zip) {
      params.set("z", zip);
    } else {
      params.delete("z");
    }
    if (distance) {
      params.set("d", distance);
    } else {
      params.delete("d");
    }
    if (mode) {
      if (!isPostingLocationUS) {
        params.set("m", "location");
      } else {
        params.set("m", mode);
      }
      if (mode === "location") {
        params.delete("z");
        params.delete("d");
      } else if (mode === "zip") {
        params.delete("l");
        params.delete("s");
      }
    }
    setClientParams(params, { replace: true });
  }, [
    postLocation,
    mode,
    clientParams,
    isPostingLocationUS,
    setClientParams,
    search,
    zip,
    distance,
  ]);

  function handleZipKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(event as unknown as React.FormEvent<HTMLFormElement>);
    }
  }

  function handleZipInputChange(val: string) {
    setZipInput(val);
  }

  return (
    <section className="text-center mt-30 py-30 search-section">
      <form
        onSubmit={handleSubmit}
        className={"search-section-form px-10"}
        id="locationPicker-search"
      >
        <label className="t-label" htmlFor="store-search">
          {t("jobsite.locationPicker.whereToWork")}
        </label>
        {mode === "zip" && isPostingLocationUS ? (
          <>
            <Textbox
              id={idGenerator("locationPicker", "search").generateId("zip")}
              required={true}
              value={zipInput}
              label={t("jobsite.locationPicker.searchByZip") as string}
              onValueChange={handleZipInputChange}
              search={true}
              classes={{ input: "mb-0 search-by-zip" }}
              onReset={resetZipInput}
              ref={zipInputRef}
              maxLength={5}
              onKeyDown={handleZipKeyDown}
              resetA11y={t("jobsite.common.clearField") as string}
              errorA11y={t("jobsite.common.errorIconLabel") as string}
            />
            <Dropdown
              id={idGenerator("locationPicker", "distance").generateId()}
              aria-label={`${t("jobsite.common.within") as string} ${t("jobsite.common.miles") as string}`}
              required={false}
              options={dropdownOptionsObject}
              hideLabel={true}
              name="distance-selection-dropdown"
              classes={{ root: "mb-0" }}
              value={distance}
              handleValueSelect={(e, option) => setDistance(option.value)}
            />
            <NativeButton
              id={idGenerator("locationPicker", "searchByLocation").generateId(
                "button",
              )}
              className={"t-body blue link"}
              onClick={() => handleModeChange()}
              label={t("jobsite.locationPicker.searchByLocation") as string}
            />
          </>
        ) : (
          <>
            <div className={"type-ahead"} ref={typeAheadRef}>
              <Textbox
                required={true}
                value={locationInput}
                id={idGenerator("locationPicker", "search").generateId(
                  "location",
                )}
                label={t("jobsite.locationPicker.searchByLocation") as string}
                onValueChange={searchInputChanged}
                search={true}
                onReset={resetSearchInput}
                ref={locationInputRef}
                onFocus={onInputFocus}
                onKeyDown={onKeyDown}
                classes={{ input: "mb-0" }}
                resetA11y={t("jobsite.common.clearField") as string}
                errorA11y={t("jobsite.common.errorIconLabel") as string}
                aria-expanded={typeAheadResults.length > 0}
                aria-autocomplete={"list"}
                aria-owns={popoverOpen ? "listbox-container" : undefined}
                role="combobox"
                aria-activedescendant={
                  popoverOpen && highlightedPopoverIndex !== null
                    ? `suggestion-${highlightedPopoverIndex}-button`
                    : undefined
                }
                aria-describedby={typeaheadAllyLabelId}
              />
              <span className="a11y" id={typeaheadAllyLabelId}>
                {t("jobsite.search.typeaheadHelpText") as string}
              </span>
              {popoverOpen &&
                !typeAheadLoading &&
                !typeAheadError &&
                typeAheadResults.length > 0 && (
                  <LocationPickerTypeahead
                    typeAheadResults={typeAheadResults}
                    setPopoverOpen={setPopoverOpen}
                    setLocationInput={setLocationInput}
                    highlightedDispatch={highlightedDispatch}
                    highlightedPopoverIndex={highlightedPopoverIndex}
                    locationInputRef={locationInputRef}
                  />
                )}
            </div>
            {isPostingLocationUS && (
              <NativeButton
                id={idGenerator("locationPicker", "searchByZip").generateId(
                  "button",
                )}
                className={"t-body blue link"}
                onClick={() => handleModeChange()}
                label={t("jobsite.locationPicker.searchByZip") as string}
              />
            )}
          </>
        )}
      </form>
    </section>
  );
}
