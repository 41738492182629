import React from "react";

interface StepProgressProps {
  id: string;
  count: number;
  currStep: number;
}

export function StepProgress({ id, count, currStep }: StepProgressProps) {
  return (
    <section key={`${id}-${currStep}`} className="circle-container">
      {Array.from({ length: count }, (_, index) => (
        <>
          <section
            key={`${id}-${index}`}
            className={
              currStep - 1 == index ? "circle fill-blue" : "circle fill-grey"
            }
          ></section>
          <section key={`${id}-line-${index}`} className="line"></section>
        </>
      ))}
    </section>
  );
}
