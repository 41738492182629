import {
  InfoTooltip,
  LinkComponent,
  TextButton,
} from "@rpe-js/marcom-web-components";
import React, { useCallback } from "react";
import { WorkdayConfig } from "../../../../shared/types/config";
import { Contact, GenderPronoun } from "../../../../shared/types/talent/talent";
import { APAC_REGIONS } from "../../../app.constants";
import { Info } from "../../../components/base/Info";
import { Label } from "../../../components/base/Label";
import useIntlMessage from "../../../hooks/useIntlMessage";

import HtmlRenderer from "../../../components/base/HtmlRenderer";
import { NameView } from "../../../components/feature/NameView";
import translateReplace from "../../../hooks/translateReplace";
import useIsMobile from "../../../hooks/useIsMobile";
import { idGenerator } from "../../../utils/idGenerator";
import { ProfileFormField } from "../types";
import { NameTextBox } from "./components/NameTextBox";
import { getDisplayPronoun } from "./nameFormUtils";
import { ContactNameForm } from "./types";

interface NameSectionProps {
  isEdit: boolean;
  isInternal: boolean;
  locale: string;
  contactInformation?: Contact;
  contactForm?: ContactNameForm;
  displayKanji?: boolean;
  displayLocal?: boolean;
  displayPronouns?: boolean;
  displayPreferredName?: boolean;
  genderPronoun?: GenderPronoun;
  workDayLinks?: WorkdayConfig;
  onFormFieldChange?: (field: ProfileFormField, value: string) => void;
  showErrors?: boolean;
}

export const NameSection = ({
  isEdit,
  isInternal,
  locale,
  displayKanji,
  displayLocal,
  displayPronouns,
  displayPreferredName = true,
  genderPronoun,
  contactInformation,
  contactForm,
  workDayLinks,
  onFormFieldChange,
  showErrors,
}: NameSectionProps) => {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const isApacLocale = APAC_REGIONS.includes(locale);

  const { local, kanji } = contactInformation || {};

  const PRONOUNS_INFO_ID = idGenerator(
    "profile",
    "pronouns-information",
  ).generateId();

  const onChange = useCallback(
    (field: ProfileFormField, value: string) => {
      if (onFormFieldChange && isEdit) {
        onFormFieldChange(field, value);
      }
    },
    [isEdit, onFormFieldChange],
  );

  const renderNameTextBox = useCallback(
    (profileFormField: ProfileFormField) => {
      return (
        <NameTextBox
          profileFormField={profileFormField}
          onChange={onChange}
          showErrors={showErrors}
        />
      );
    },
    [onChange, showErrors],
  );

  return (
    <>
      {/* VIEW NAME SECTION */}
      {!isEdit && (
        <>
          <div
            className={`row ${isApacLocale ? (isMobile ? "column-reverse" : "row-reverse") : ""}`}
          >
            <div className="column large-6 small-12 d-flex flex-column mb-10">
              <NameView
                labelKey="firstName"
                value={contactInformation?.firstName}
              ></NameView>
            </div>
            <div className="column large-6 small-12 d-flex flex-column mb-10">
              <NameView
                labelKey="lastName"
                value={contactInformation?.lastName}
              ></NameView>
            </div>
          </div>
          <div className="row">
            {displayPreferredName && (
              <div className="column large-6 small-12 d-flex flex-column mb-10">
                <NameView
                  labelKey="preferredName"
                  value={
                    contactInformation?.preferredName ||
                    (t("jobsite.common.notSpecified") as string)
                  }
                ></NameView>
              </div>
            )}
            {isInternal && (
              <div className="column large-6 small-12 d-flex mb-10">
                <div className="d-flex-equal">
                  <div className="d-flex">
                    <Label
                      label={t("jobsite.common.displayPronouns") as string}
                    />
                    <InfoTooltip
                      configuration={{
                        overrideDefaultBehaviour: true,
                        showOnHover: true,
                        icon: (
                          <>
                            <TextButton
                              id={idGenerator(
                                "profile",
                                "name-section-pronouns",
                              ).generateId("button")}
                              icon="icon-questionsolid"
                              onClick={() => {}}
                              classes="link"
                              aria-label={
                                t("jobsite.common.pronouns") as string
                              }
                              aria-describedby={PRONOUNS_INFO_ID}
                            />
                          </>
                        ),
                      }}
                      iconLabel={
                        t("jobsite.common.pronounsInformationIconVO") as string
                      }
                      positionClass="form-tooltip-after d-flex align-center"
                      tooltipPointer={isMobile ? "middle" : "left"}
                      setAsHtml={true}
                      isClickable={true}
                      infoTooltipBody={
                        t("jobsite.information.pronounsInformation") as string
                      }
                    />
                  </div>
                  <Info
                    value={
                      t(
                        `${displayPronouns ? "jobsite.common.yes" : "jobsite.common.no"}`,
                      ) as string
                    }
                  />
                </div>
                {displayPronouns && getDisplayPronoun(genderPronoun) && (
                  <div className="d-flex flex-column">
                    <NameView
                      labelKey="pronouns"
                      value={getDisplayPronoun(genderPronoun)}
                    ></NameView>
                  </div>
                )}
              </div>
            )}
          </div>

          {displayKanji && (
            <div
              className={`row ${isApacLocale ? (isMobile ? "column-reverse" : "row-reverse") : ""}`}
            >
              <div className="column large-6 small-12 d-flex flex-column mb-10">
                <NameView
                  labelKey="kanjiFirstName"
                  value={
                    kanji?.firstName ||
                    (t("jobsite.common.notSpecified") as string)
                  }
                ></NameView>
              </div>
              <div className="column large-6 small-12 d-flex flex-column mb-10">
                <NameView
                  labelKey="kanjiLastName"
                  value={
                    kanji?.lastName ||
                    (t("jobsite.common.notSpecified") as string)
                  }
                ></NameView>
              </div>
            </div>
          )}
          {displayLocal && (
            <div
              className={`row ${isApacLocale ? (isMobile ? "column-reverse" : "row-reverse") : ""}`}
            >
              <div className="column large-6 small-12 d-flex flex-column mb-10">
                <NameView
                  labelKey="localFirstName"
                  value={
                    local?.firstName ||
                    (t("jobsite.common.notSpecified") as string)
                  }
                ></NameView>
              </div>
              <div className="column large-6 small-12 d-flex flex-column mb-10">
                <NameView
                  labelKey="localLastName"
                  value={
                    local?.lastName ||
                    (t("jobsite.common.notSpecified") as string)
                  }
                ></NameView>
              </div>
            </div>
          )}
        </>
      )}

      {/* INTERNAL EDIT SECTION */}
      {isEdit && isInternal && (
        <>
          <HtmlRenderer
            classes="hyperlink-underline"
            initialContent={translateReplace(
              t("jobsite.information.internalInformationMessage") as string,
              ["{linkurl}"],
              [workDayLinks?.homePage as string],
            )}
            htmlTag="p"
          />
          <div
            className={`row mb-10 ${isApacLocale ? (isMobile ? "column-reverse" : "row-reverse") : ""}`}
          >
            <div className="column large-6 small-12 d-flex flex-column">
              <div className="d-flex">
                <Label
                  label={t("jobsite.common.firstName") as string}
                  variant="prominent"
                />
                <span className="ml-20 mt-0">
                  <LinkComponent
                    text={t("jobsite.common.edit") as string}
                    type="standalone"
                    link={`${workDayLinks?.firstName}`}
                  />
                </span>
              </div>
              <Info value={contactInformation?.firstName} />
            </div>
            <div className="column large-6 small-12">
              <div className="d-flex">
                <Label
                  label={t("jobsite.common.lastName") as string}
                  variant="prominent"
                />
                <span className="ml-20 mt-0">
                  <LinkComponent
                    text={t("jobsite.common.edit") as string}
                    type="standalone"
                    link={`${workDayLinks?.lastName}`}
                  />
                </span>
              </div>
              <Info value={contactInformation?.lastName} />
            </div>
          </div>
          <div className="row mb-10">
            <div className="column large-6 small-12">
              <div className="d-flex">
                <Label
                  label={t("jobsite.common.preferredName") as string}
                  variant="prominent"
                />
                <span className="ml-20 mt-0">
                  <LinkComponent
                    text={t("jobsite.common.edit") as string}
                    type="standalone"
                    link={`${workDayLinks?.preferredName}`}
                  />
                </span>
              </div>
              <Info
                value={
                  contactInformation?.preferredName
                    ? contactInformation?.preferredName
                    : (t("jobsite.common.notSpecified") as string)
                }
              />
            </div>
          </div>

          {displayKanji && (
            <div
              className={`row mb-10 ${isApacLocale ? (isMobile ? "column-reverse" : "row-reverse") : ""}`}
            >
              <div className="column large-6 small-12 d-flex flex-column">
                <Label
                  label={`${t("jobsite.common.kanjiFirstName")} ${t("jobsite.common.optional")}`}
                  variant="prominent"
                />
                <Info
                  value={
                    kanji?.firstName ||
                    (t("jobsite.common.notSpecified") as string)
                  }
                />
              </div>
              <div className="column large-6 small-12 d-flex flex-column">
                <Label
                  label={`${t("jobsite.common.kanjiLastName")} ${t("jobsite.common.optional")}`}
                  variant="prominent"
                />
                <Info
                  value={
                    kanji?.lastName ||
                    (t("jobsite.common.notSpecified") as string)
                  }
                />
              </div>
            </div>
          )}
          {displayLocal && (
            <div
              className={`row mb-10 ${isApacLocale ? (isMobile ? "column-reverse" : "row-reverse") : ""}`}
            >
              <div className="column large-6 small-12 pr-15">
                <Label
                  label={`${t("jobsite.common.localFirstName")} ${t("jobsite.common.optional")}`}
                  variant="prominent"
                />
                <Info
                  value={
                    local?.firstName ||
                    (t("jobsite.common.notSpecified") as string)
                  }
                />
              </div>
              <div className="column large-6 small-12 d-flex flex-column">
                <Label
                  label={`${t("jobsite.common.localLastName")} ${t("jobsite.common.optional")}`}
                  variant="prominent"
                />
                <Info
                  value={
                    local?.lastName ||
                    (t("jobsite.common.notSpecified") as string)
                  }
                />
              </div>
            </div>
          )}
        </>
      )}

      {/* EXTERNAL EDIT SECTION */}
      {isEdit && !isInternal && (
        <>
          <div className="row">
            <div
              className={`column large-6 small-12 mb-10 ${!isMobile && "pr-15"}`}
            >
              {renderNameTextBox(
                isApacLocale
                  ? (contactForm as ContactNameForm).lastName
                  : (contactForm as ContactNameForm).firstName,
              )}
            </div>
            <div
              className={`column large-6 small-12 mb-10 ${!isMobile && "pr-15"}`}
            >
              {renderNameTextBox(
                isApacLocale
                  ? (contactForm as ContactNameForm).firstName
                  : (contactForm as ContactNameForm).lastName,
              )}
            </div>
          </div>
          <div className="row">
            <div
              className={`column large-6 small-12 mb-10 ${!isMobile && "pr-15"}`}
            >
              {renderNameTextBox(
                (contactForm as ContactNameForm).preferredName,
              )}
            </div>
          </div>
          {displayKanji && (
            <div className="row">
              <div
                className={`column large-6 small-12 mb-10 ${!isMobile && "pr-15"}`}
              >
                {renderNameTextBox(
                  isApacLocale
                    ? ((contactForm as ContactNameForm)
                        .kanjiLastName as ProfileFormField)
                    : ((contactForm as ContactNameForm)
                        .kanjiFirstName as ProfileFormField),
                )}
              </div>
              <div
                className={`column large-6 small-12 mb-10 ${!isMobile && "pr-15"}`}
              >
                {renderNameTextBox(
                  isApacLocale
                    ? ((contactForm as ContactNameForm)
                        .kanjiFirstName as ProfileFormField)
                    : ((contactForm as ContactNameForm)
                        .kanjiLastName as ProfileFormField),
                )}
              </div>
            </div>
          )}
          {displayLocal && (
            <div className="row">
              <div
                className={`column large-6 small-12 mb-10 ${!isMobile && "pr-15"}`}
              >
                {renderNameTextBox(
                  isApacLocale
                    ? ((contactForm as ContactNameForm)
                        .localLastName as ProfileFormField)
                    : ((contactForm as ContactNameForm)
                        .localFirstName as ProfileFormField),
                )}
              </div>
              <div
                className={`column large-6 small-12 mb-10 ${!isMobile && "pr-15"}`}
              >
                {renderNameTextBox(
                  isApacLocale
                    ? ((contactForm as ContactNameForm)
                        .localFirstName as ProfileFormField)
                    : ((contactForm as ContactNameForm)
                        .localLastName as ProfileFormField),
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
