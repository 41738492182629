import { ClipboardEvent, KeyboardEvent } from "react";
import {
  testInvalidCharOnSchoolSkill,
  testInvalidCharRegexOnJobTitle,
  testInvalidCharRegexOnName,
  testInvalidCharRegexOnReasonAndRelation,
  testInvalidCountryStateZipCodeChar,
  testRegexonEmail,
  testRegexOnEmailChar,
  testRegexOnPhoneNumber,
  testRegexOnStreetCityDistrict,
} from "./RegExpConfiguration";

const getClipboardData = (
  evt: ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>,
) => {
  const pastedValue = (evt.clipboardData || window.clipboardData).getData(
    "Text",
  );
  return pastedValue;
};

export function useFormInputValidator() {
  const validateCountryStateZipCode = (
    evt: KeyboardEvent<HTMLInputElement>,
    preventDefault: boolean = true,
  ): boolean => {
    if (testInvalidCountryStateZipCodeChar(evt.key)) {
      if (preventDefault) evt.preventDefault();
      return false;
    }
    return true;
  };

  const onPasteValidateCountryStateZipCode = (
    evt: ClipboardEvent<HTMLInputElement>,
    preventDefault: boolean = true,
  ): boolean => {
    const pastedValue = getClipboardData(evt);
    let isValid = true;
    for (const chr of pastedValue) {
      if (testInvalidCountryStateZipCodeChar(chr)) {
        if (preventDefault) evt.preventDefault();
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  const isValidCountryStateZipCode = (value: string): boolean => {
    for (const chr of value) {
      if (testInvalidCountryStateZipCodeChar(chr)) {
        return false;
      }
    }
    return true;
  };

  const validateStreetCityDistrict = (
    evt: KeyboardEvent<HTMLInputElement>,
    preventDefault: boolean = true,
  ): boolean => {
    if (testRegexOnStreetCityDistrict(evt.key)) {
      if (preventDefault) evt.preventDefault();
      return false;
    }
    return true;
  };

  const onPasteValidateStreetCityDistrict = (
    evt: ClipboardEvent<HTMLInputElement>,
    preventDefault: boolean = true,
  ) => {
    const pastedValue = getClipboardData(evt);
    let isValid = true;
    for (const chr of pastedValue) {
      if (testRegexOnStreetCityDistrict(chr)) {
        if (preventDefault) evt.preventDefault();
        isValid = false;
        break;
      }
    }
    return isValid;
  };
  const isValidStreetCityDistrict = (value: string): boolean => {
    for (const chr of value) {
      if (testRegexOnStreetCityDistrict(chr)) {
        return false;
      }
    }
    return true;
  };

  const validateNameEntered = (
    evt: KeyboardEvent<HTMLInputElement>,
    preventDefault: boolean = true,
  ): boolean => {
    if (testInvalidCharRegexOnName(evt.key)) {
      if (preventDefault) evt.preventDefault();
      return false;
    }
    return true;
  };

  const validateJobTitle = (
    evt: KeyboardEvent<HTMLInputElement>,
    preventDefault: boolean = true,
  ): boolean => {
    if (testInvalidCharRegexOnJobTitle(evt.key)) {
      if (preventDefault) evt.preventDefault();
      return false;
    }
    return true;
  };

  const isValidJobTitle = (jobTitle: string): boolean => {
    for (const chr of jobTitle) {
      if (testInvalidCharRegexOnJobTitle(chr)) {
        return false;
      }
    }
    return true;
  };

  const onPasteValidateJobTitle = (
    evt: ClipboardEvent<HTMLInputElement>,
    preventDefault: boolean = true,
  ) => {
    const pastedValue = getClipboardData(evt);
    let isValid = true;
    for (const chr of pastedValue) {
      if (testInvalidCharRegexOnJobTitle(chr)) {
        if (preventDefault) evt.preventDefault();
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  const onPasteValidateName = (
    evt: ClipboardEvent<HTMLInputElement>,
    preventDefault: boolean = true,
  ) => {
    const pastedValue = getClipboardData(evt);
    let isValid = true;
    for (const chr of pastedValue) {
      if (testInvalidCharRegexOnName(chr)) {
        if (preventDefault) evt.preventDefault();
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  const isValidName = (name: string): boolean => {
    for (const chr of name) {
      if (testInvalidCharRegexOnName(chr)) {
        return false;
      }
    }
    return true;
  };

  const validateSchoolSkill = (
    evt: KeyboardEvent<HTMLInputElement>,
    preventDefault: boolean = true,
  ) => {
    if (testInvalidCharOnSchoolSkill(evt.key)) {
      if (preventDefault) evt.preventDefault();
      return false;
    }
    return true;
  };

  const validateReasonForLeavingAndRelation = (
    evt: KeyboardEvent<HTMLTextAreaElement> | KeyboardEvent<HTMLInputElement>,
    preventDefault: boolean = true,
  ) => {
    if (testInvalidCharRegexOnReasonAndRelation(evt.key)) {
      if (preventDefault) evt.preventDefault();
      return false;
    }
    return true;
  };

  const onPasteValidateReasonForLeavingAndRelation = (
    evt: ClipboardEvent<HTMLTextAreaElement> | ClipboardEvent<HTMLInputElement>,
    preventDefault: boolean = true,
  ): boolean => {
    const pastedValue = getClipboardData(evt);
    let isValid = true;
    for (const chr of pastedValue) {
      if (testInvalidCharRegexOnReasonAndRelation(chr)) {
        if (preventDefault) evt.preventDefault();
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  const isValidReasonForLeavingAndRelation = (value: string): boolean => {
    for (const chr of value) {
      if (testInvalidCharRegexOnReasonAndRelation(chr)) {
        return false;
      }
    }
    return true;
  };

  const onPasteValidateSchoolSkill = (
    evt: ClipboardEvent<HTMLInputElement>,
    preventDefault: boolean = true,
  ): boolean => {
    const pastedValue = getClipboardData(evt);
    let isValid = true;
    for (const chr of pastedValue) {
      if (testInvalidCharOnSchoolSkill(chr)) {
        if (preventDefault) evt.preventDefault();
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  const isValidSchoolSkill = (value: string) => {
    for (const chr of value) {
      if (testInvalidCharOnSchoolSkill(chr)) {
        return false;
      }
    }
    return true;
  };

  const isValidEmailAddressEntered = (
    evt: KeyboardEvent<HTMLInputElement>,
    preventDefault: boolean = true,
  ) => {
    if (testRegexOnEmailChar(evt.key)) {
      if (preventDefault) evt.preventDefault();
      return false;
    }
    return true;
  };

  const isValidEmailAddressPasted = (
    evt: ClipboardEvent<HTMLInputElement>,
    preventDefault: boolean = true,
  ): boolean => {
    const pastedValue = getClipboardData(evt);
    let isValid = true;
    for (const chr of pastedValue) {
      if (testRegexOnEmailChar(chr)) {
        if (preventDefault) evt.preventDefault();
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  const isValidEmail = (value: string) => {
    return testRegexonEmail(value);
  };

  const isValidPhoneNumberEntered = (
    evt: KeyboardEvent<HTMLInputElement>,
    preventDefault: boolean = true,
  ) => {
    if (testRegexOnPhoneNumber(evt.key)) {
      if (preventDefault) evt.preventDefault();
      return false;
    }
    return true;
  };

  const isValidPhoneNumberPasted = (
    evt: ClipboardEvent<HTMLInputElement>,
    preventDefault: boolean = true,
  ): boolean => {
    const pastedValue = getClipboardData(evt);
    let isValid = true;
    for (const chr of pastedValue) {
      if (testRegexOnPhoneNumber(chr)) {
        if (preventDefault) evt.preventDefault();
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  const isValidPhoneNumber = (value: string) => {
    for (const chr of value) {
      if (testRegexOnPhoneNumber(chr)) {
        return false;
      }
    }
    return true;
  };

  return {
    validateCountryStateZipCode,
    onPasteValidateCountryStateZipCode,
    isValidCountryStateZipCode,
    onPasteValidateName,
    validateNameEntered,
    isValidName,
    isValidSchoolSkill,
    onPasteValidateSchoolSkill,
    validateSchoolSkill,
    validateStreetCityDistrict,
    onPasteValidateStreetCityDistrict,
    isValidStreetCityDistrict,
    isValidEmail,
    isValidEmailAddressEntered,
    isValidEmailAddressPasted,
    isValidPhoneNumber,
    isValidPhoneNumberEntered,
    isValidPhoneNumberPasted,
    isValidJobTitle,
    onTypeaheadPaste: onPasteValidateSchoolSkill,
    onTypeaheadKeydown: validateSchoolSkill,
    validateJobTitle,
    onPasteValidateJobTitle,
    validateReasonForLeavingAndRelation,
    onPasteValidateReasonForLeavingAndRelation,
    isValidReasonForLeavingAndRelation,
  };
}
