/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Overlay,
  ProgressIndicatorLoader,
} from "@rpe-js/marcom-web-components";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import {
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigation,
  useRouteLoaderData,
  useSearchParams,
} from "react-router";
import { keepAlive } from "../api/fetchClient";
import {
  KEEP_ALIVE_INTERVAL_MILLI_SECONDS,
  LOGOUT,
  TIMEOUT,
} from "../app.constants";
import AppContext, { AppContextProvider } from "../AppContext";
import { GlobalAlert } from "../components/feature/GlobalAlert";
import GlobalError from "../components/feature/GlobalError";
import Header from "../components/feature/Header";
import ParsingBanner from "../components/feature/resumeparsing/ParsingBanner";
import { SaveCancelAction } from "../components/feature/saveAndCancel";
import SessionInactivityModal from "../components/feature/sessionInactivity/SessionInactivityModal";
import SignedOutModal from "../components/feature/signOut/SignedOutModal";
import { AppAlert } from "../contexts/AppAlert";
import { GlobalContextProvider } from "../contexts/GlobalContext";
import useIntlMessage from "../hooks/useIntlMessage";
import { useSessionTimer } from "../hooks/useSessionTimer";
import {
  ResumeParsing,
  useOnResumeParseModalClose,
} from "../pages/resumeParsedModal";
import { RouteID } from "../routes";
import { AppData } from "../types";
import { redirectToPath } from "../util";
import { idGenerator } from "../utils/idGenerator";

export function Layout() {
  const { state } = useNavigation();
  const appData = useRouteLoaderData(RouteID.root) as AppData;
  const {
    locale,
    appBasePathPrefix,
    isSessionAuthenticated,
    isInternal,
    isExternal,
    translations,
    hasTalentError,
  } = appData;
  const [searchParams] = useSearchParams();
  const {
    showSignedOutModal,
    setShowSignedOutModal,
    sessionTimeout,
    setSessionTimeout,
    showSessionInactivityModal,
    setShowSessionInactivityModal,
    sendMessage,
    start,
    redirectToLogout,
  } = useSessionTimer(isSessionAuthenticated, appData);

  const [keepAliveId, setKeepAliveId] = useState<string>("");
  const [openParsedModal, setOpenParsedModal] = useState(false);
  const location = useLocation();

  const isLocationApplyOrERP = useCallback(() => {
    return (
      location.pathname.includes(RouteID.apply) ||
      location.pathname.includes(RouteID.erp)
    );
  }, [location.pathname]);

  const startKeepAlive = useCallback(() => {
    keepAlive();
    const id = setInterval(async () => {
      keepAlive();
    }, KEEP_ALIVE_INTERVAL_MILLI_SECONDS);
    setKeepAliveId(id.toString());
  }, [setKeepAliveId]);

  // this effect checks for action query param whenever url updates
  useEffect(() => {
    const action = searchParams.get("action") || "";
    if ((action === LOGOUT || action === TIMEOUT) && isExternal) {
      setShowSignedOutModal(true);
      if (action === TIMEOUT) {
        setSessionTimeout(true);
      }
    }
  }, [
    searchParams,
    isExternal,
    isSessionAuthenticated,
    setShowSignedOutModal,
    setSessionTimeout,
  ]);

  // this effect executes only once when the page loads and starts keepalive if authenticated
  useEffect(() => {
    if (isSessionAuthenticated) {
      start(); // start the UI session timer
      startKeepAlive();
    }
  }, [isSessionAuthenticated, start, startKeepAlive]);

  // this effect executes when session inactivity modal triggers and we should not make a keepalive call
  useEffect(() => {
    if (showSessionInactivityModal) {
      clearInterval(keepAliveId);
    }
  }, [showSessionInactivityModal, keepAliveId]);

  // this effect executes when loading navigation, scroll to the top of page to show loader and will disable the scrolling.
  useEffect(() => {
    if (state === "loading") {
      document.body.classList.add("loader-active");
    } else {
      document.body.classList.remove("loader-active");
    }
  }, [state]);

  // It will be invoked when user clicks on StaySignedIn in SessionInactivity modal
  const continueSession = () => {
    startKeepAlive(); // keep extending the session
    setShowSessionInactivityModal(false); // Close inactivity modal
    sendMessage("active"); // Send 'active' message to all tabs
  };

  // It will be invoked when user clicks on Signout in SessionInactivity modal
  const signOut = () => {
    sendMessage("signOut");
    redirectToLogout();
  };

  const onReviewLater = useCallback(() => {
    setOpenParsedModal(false);
  }, []);

  const onReviewNow = useCallback(() => {
    setOpenParsedModal(true);
  }, []);

  return (
    <AppContextProvider>
      <IntlProvider locale={locale} messages={translations}>
        <Header />
        <GlobalError hasError={hasTalentError} isInternal={isInternal}>
          <GlobalContextProvider>
            <div>
              <ScrollRestoration />
              <AppAlert>
                <GlobalAlert></GlobalAlert>
                {isSessionAuthenticated && !isLocationApplyOrERP() && (
                  <ParsingBanner
                    setOpenParsedModal={setOpenParsedModal}
                  ></ParsingBanner>
                )}
                {state === "loading" && (
                  <ProgressIndicatorLoader
                    isFixedCurtain={true}
                    showLoading={true}
                  ></ProgressIndicatorLoader>
                )}
                <Outlet />
                {showSignedOutModal && (
                  <SignedOutModal
                    showModal={showSignedOutModal}
                    onClose={() =>
                      redirectToPath(appBasePathPrefix, `${locale}/search`)
                    }
                    afterSessionTimeout={sessionTimeout}
                  />
                )}
                {showSessionInactivityModal && (
                  <SessionInactivityModal
                    showModal={showSessionInactivityModal}
                    continueSession={continueSession}
                    signOut={signOut}
                  />
                )}
                {openParsedModal && (
                  <ResumeParsing setOpenParsedModal={setOpenParsedModal} />
                )}
              </AppAlert>
              <ResumeActionsModal
                onClose={onReviewLater}
                onReviewNow={onReviewNow}
              />
            </div>
          </GlobalContextProvider>
        </GlobalError>
      </IntlProvider>
    </AppContextProvider>
  );
}

function ResumeActionsModal(props: {
  onClose: () => void;
  onReviewNow: () => void;
}) {
  const { onClose, onReviewNow } = props;
  const { t } = useIntlMessage();
  const { appUIState, dispatch } = useContext(AppContext);
  const { resumeParsingData, appData, showResumeActionsModal } = appUIState;
  const resumeActionModalTitle = idGenerator("resume", "actions").generateId(
    "title",
  );
  const onReviewLater = useOnResumeParseModalClose(
    resumeParsingData,
    appData.talentId,
  );

  const reviewLater = useCallback(() => {
    dispatch({
      type: "RESUME_PARSING_REVIEW_ACTIONS",
      payload: {
        showResumeActionsModal: false,
      },
    });
    onReviewLater(onClose);
  }, [onReviewLater, onClose, dispatch]);

  const reviewNow = useCallback(() => {
    dispatch({
      type: "RESUME_PARSING_REVIEW_ACTIONS",
      payload: {
        showResumeActionsModal: false,
      },
    });
    onReviewNow();
  }, [onReviewNow, dispatch]);

  return (
    <>
      {showResumeActionsModal && (
        <Overlay
          id={idGenerator("resume", "actions").generateId("overlay")}
          elementIdToFocus="" // Focus on close/Cancel should be on Review Now , Focus onSave Action should be the next focusible element , refer to current behavior in UT
          wide={false}
          disableEsc
          onClose={reviewLater}
          visible={showResumeActionsModal || false}
          disableClickAway={true}
          ariaLabel={resumeActionModalTitle}
          closeButtonAttrs={{
            ariaLabel: t("jobsite.common.close") as string,
          }}
          footerContent={
            <div className="d-flex justify-center u-border-top mb-30 ml-25 mr-25">
              <SaveCancelAction
                onCancel={reviewLater}
                onSave={reviewNow}
                cancelLabelName={t("jobsite.common.reviewLater") as string}
                saveLabelName={t("jobsite.common.reviewNow") as string}
                cancelButtonId={idGenerator("resume", "actions").generateId(
                  "reviewlater-button",
                )}
                saveButtonId={idGenerator("resume", "actions").generateId(
                  "reviewnow-button",
                )}
              />
            </div>
          }
        >
          <div>
            {resumeParsingData?.profileUpdateSelection === "resume" && (
              <span className="text-center">
                <h2 id={resumeActionModalTitle} className="t-eyebrow-elevated">
                  {t("jobsite.common.resumeParsingTitle")}
                </h2>
                <p>{t("jobsite.common.resumeParsingContent")}</p>
              </span>
            )}
            {resumeParsingData?.profileUpdateSelection === "linkedin" && (
              <span className="text-center">
                <h2 id={resumeActionModalTitle} className="t-eyebrow-elevated">
                  {t("jobsite.common.linkedinParsingTitle")}
                </h2>
                <p>{t("jobsite.common.linkedinParsingContent")}</p>
              </span>
            )}
          </div>
        </Overlay>
      )}
    </>
  );
}
