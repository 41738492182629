import { NativeButton } from "@rpe-js/marcom-web-components";
import { find, forEach } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getRecentlyViewedJobs } from "../../api/fetchClient";
import { RECENTLY_VIEWED_ROLES } from "../../app.constants";
import IconButton from "../../components/base/IconButton";
import { CLEAR_FILTERS, SET_FILTERS_VISIBLE } from "../../contexts/actionTypes";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { useSearchContext } from "../../hooks/useSearchContext";
import { idGenerator } from "../../utils/idGenerator";
import { SessionStorage } from "../../utils/sessionStorageUtil";
import FavoriteSearchFilter from "./FavoriteSearchFilter";
import { SavedRolesFilter } from "./SavedRolesFilter";
import SavedSearchFilter from "./SavedSearchFilter";

interface RecentViewedRole {
  reqId: string;
  jobNumber: string;
  postingTitle: string;
}

function ManageFilters() {
  const filtersControlRef = useRef<HTMLButtonElement>(null);
  const { state, dispatch } = useSearchContext();
  const { t } = useIntlMessage();
  const [recentViewedRoles, setRecentViewedRoles] = useState<
    Array<RecentViewedRole>
  >([]);
  const idSeqGenerator = idGenerator("search", "filters");
  const isMobile = useIsMobile();

  const handleSetFilterVisible = () => {
    dispatch({ type: SET_FILTERS_VISIBLE, payload: !state.filterVisible });
  };
  const handleClearFilters = () => {
    dispatch({ type: CLEAR_FILTERS });
    if (filtersControlRef.current) {
      filtersControlRef.current.focus();
    }
  };

  const getRolesInSession = useCallback(() => {
    let viewedRoles = [];
    if (SessionStorage.get(RECENTLY_VIEWED_ROLES)) {
      viewedRoles = JSON.parse(
        SessionStorage.get(RECENTLY_VIEWED_ROLES) as any,
      );
    }
    return viewedRoles.reverse();
  }, []);

  const getRecentlyViewedJobInfo = useCallback(async () => {
    const rolesInSession = getRolesInSession();
    if (rolesInSession.length > 0) {
      const response = await getRecentlyViewedJobs(
        rolesInSession
          .filter((role: RecentViewedRole) => role.reqId != null)
          .map((role: RecentViewedRole) => role.reqId),
      );
      const viewedRoles: Array<RecentViewedRole> = [];
      if (response && response.length > 0) {
        forEach(rolesInSession, (role) => {
          const rolesObj = find(response, { positionID: role.reqId });
          if (rolesObj)
            viewedRoles.push({
              reqId: rolesObj.positionID,
              jobNumber: rolesObj.positionID,
              postingTitle: rolesObj.postingTitle,
            });
        });
        setRecentViewedRoles(viewedRoles);
      } else setRecentViewedRoles(rolesInSession);
    }
  }, [getRolesInSession]);

  useEffect(() => {
    getRecentlyViewedJobInfo();
  }, [getRecentlyViewedJobInfo]);

  return (
    <section
      className={
        isMobile ? "mobile-manage-filters pb-10" : "manage-filters pb-10"
      }
    >
      {!isMobile && (
        <div className="d-flex">
          <IconButton
            iconButtonRef={filtersControlRef}
            onClick={handleSetFilterVisible}
            name={"filter-list-blue"}
            size={"xsmall"}
            cursor={"pointer"}
            classes={
              "cursor-pointer manager-filter-filters-button-container pr-10 blue"
            }
            iconClasses={"t-body-reduced"}
            label={t("jobsite.search.filters") as string}
            aria-expanded={state.filterVisible}
            aria-controls={idSeqGenerator.generateId("container")}
            id={idSeqGenerator.generateId("manage-filters-button")}
          />
          {Object.keys(state.filters)?.length > 0 && (
            <NativeButton
              id={idSeqGenerator.generateId("clear-all-button")}
              onClick={handleClearFilters}
              className={"link"}
              aria-label={
                t("jobsite.common.ActionsAriaLabel", {
                  action: `${t("jobsite.common.clearAll")}`,
                  item: `${t("jobsite.search.filters")}`,
                }) as string
              }
              label={t("jobsite.common.clearAll") as string}
            ></NativeButton>
          )}
        </div>
      )}

      <div className="saved-filters">
        <SavedSearchFilter
          id={idSeqGenerator.generateId("saved")}
        ></SavedSearchFilter>
        <FavoriteSearchFilter
          id={idSeqGenerator.generateId("favorite")}
        ></FavoriteSearchFilter>
        <SavedRolesFilter
          id={idSeqGenerator.generateId("viewed-roles")}
          recentViewedRoles={recentViewedRoles}
        ></SavedRolesFilter>
      </div>
    </section>
  );
}

export default ManageFilters;
