import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MyApplication } from "../../../../../../shared/types/talent/myApplication";
import { Info } from "../../../../../components/base/Info";
import { Label } from "../../../../../components/base/Label";
import RadioGroup, {
  RadioOption,
} from "../../../../../components/feature/form/radioGroup";
import { useCurrentUserContext } from "../../../../../CurrentUserContext";
import useIntlMessage from "../../../../../hooks/useIntlMessage";
import { idGenerator } from "../../../../../utils/idGenerator";
import { useMyApplicationContext } from "../../context/context";
import { MyApplicationActionDispatcher } from "../../context/state";
import { ApplicationSection, SectionMode } from "../../context/types";
import { useUpdateApplication } from "../../hooks/createAndUpdateApplicationHooks";
import { SectionHeader } from "../SectionHeader";
import { SectionActions } from "./sectionActions";

interface SectionProps {
  id: string;
  onSave: (
    name: ApplicationSection,
    mode: SectionMode,
    application: MyApplication,
  ) => void;
  mode: SectionMode;
}

interface LegalProps extends SectionProps {}

export function LegalSection(props: LegalProps) {
  const { id, mode, onSave } = props;
  const { state, dispatch } = useMyApplicationContext();
  const { t } = useIntlMessage();
  const { legalSection, application, currentStep } = state;
  const { currentUser } = useCurrentUserContext();
  const { title } = legalSection;
  const sectionName: ApplicationSection = "legal";
  const stepLegalInfo = useMemo(() => {
    return legalSection.legal || application?.legal || null;
  }, [legalSection, application]);

  const {
    //isUpdateApplicationError,
    isUpdateApplicationLoading,
    isUpdateApplicationSuccess,
    updateApplication,
    resetUpdateApplication,
    updatedApplication,
  } = useUpdateApplication();

  const [stepLegalForm, setStepLegalForm] = useState<"true" | "false" | null>(
    stepLegalInfo?.rightToWork === true
      ? "true"
      : stepLegalInfo?.rightToWork === false
        ? "false"
        : null,
  );
  const [showError] = useState<boolean>(false);

  const onCancel = useCallback(() => {
    MyApplicationActionDispatcher.onApplicationSectionCancel(
      dispatch,
      sectionName,
    );
  }, [sectionName, dispatch]);

  const onAppStepSectionSave = useCallback(
    (value: string) => {
      MyApplicationActionDispatcher.onAppSecLegalSave(dispatch, {
        rightToWork:
          value === "true" ? true : value === "false" ? false : undefined,
      });
      onSave(sectionName, mode, application as MyApplication);
    },
    [dispatch, onSave, mode, application, sectionName],
  );

  const onSectionSave = useCallback(async () => {
    if (application && currentUser.talentId) {
      const requestBody = cloneDeep(application);
      requestBody.legal = {
        rightToWork:
          stepLegalForm === "true"
            ? true
            : stepLegalForm === "false"
              ? false
              : undefined,
      };
      await updateApplication(
        currentUser.talentId,
        application?.id,
        "legal",
        requestBody as MyApplication,
      );
    }
  }, [currentUser, application, stepLegalForm, updateApplication]);

  const onSectionEdit = useCallback(() => {
    MyApplicationActionDispatcher.onApplicationSectionEdit(
      dispatch,
      sectionName,
    );
    setStepLegalForm(
      stepLegalInfo?.rightToWork === true
        ? "true"
        : stepLegalInfo?.rightToWork === false
          ? "false"
          : null,
    );
  }, [dispatch, stepLegalInfo?.rightToWork]);

  const options: RadioOption[] = useMemo((): RadioOption[] => {
    return [
      "jobsite.profile.myApplication.yes",
      "jobsite.profile.myApplication.no",
    ].map(
      (rf, idx) =>
        ({
          id: idGenerator("profileApplication", "legal").generateId(`${idx}`),
          label: t(rf),
          value: idx === 0 ? "true" : "false",
        }) as RadioOption,
    );
  }, [t]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setStepLegalForm(event.target.value as any);
      if (currentStep === "application") {
        onAppStepSectionSave(event.target.value);
      }
    },
    [onAppStepSectionSave, currentStep],
  );

  useEffect(() => {
    if (
      !isUpdateApplicationLoading &&
      isUpdateApplicationSuccess &&
      updatedApplication
    ) {
      MyApplicationActionDispatcher.onAppSecLegalSave(
        dispatch,
        updatedApplication.legal,
      );
      onSave(sectionName, mode, updatedApplication as MyApplication);
      resetUpdateApplication();
    }
  }, [
    dispatch,
    isUpdateApplicationLoading,
    isUpdateApplicationSuccess,
    mode,
    onSave,
    updatedApplication,
    resetUpdateApplication,
  ]);

  return (
    <SectionHeader id={id} title={title} mode={mode} onEdit={onSectionEdit}>
      {(mode === "edit" || mode == "create") && (
        <div className="large=12 small-12">
          <fieldset>
            <RadioGroup
              required={true}
              id={id}
              classes={{
                root: "mt-0",
              }}
              title={
                t("jobsite.profile.myApplication.provideLegalDoc") as string
              }
              name={`legal`}
              options={options}
              selectedValue={stepLegalForm}
              onChange={handleChange}
              errorMessage={
                showError
                  ? (t("jobsite.profile.myApplication.chooseOption") as string)
                  : undefined
              }
              errorA11y={t("jobsite.common.errorIconLabel") as string}
            />
          </fieldset>
        </div>
      )}
      {mode !== "view" && currentStep === "reviewSubmit" && (
        <SectionActions
          onCancel={onCancel}
          onSave={onSectionSave}
          mode={mode}
        />
      )}
      {mode === "view" && (
        <div className="d-flex flex-column mt-10">
          <Label
            label={t("jobsite.profile.myApplication.provideLegalDoc") as string}
          ></Label>
          <Info
            value={
              legalSection.legal?.rightToWork
                ? (t("jobsite.profile.myApplication.yes") as string)
                : (t("jobsite.profile.myApplication.no") as string)
            }
          ></Info>
        </div>
      )}
    </SectionHeader>
  );
}
