import { ISetFilters } from "../hooks/useSearchPageQueryParams";
import { encodeUrl } from "./encodeUrl";
import APP_CONSTANTS from "../../utilities/appConstants";
import { getTotalPages } from "../util";

const PRIMARY_PARAMS = ["team", "product", "location", "homeOffice"];
const SECONDARY_PARAMS = ["search", "key", "lang", "sort"];
const PAGINATION_PARAMS = ["page"];
const getMetaForNoIndex = (params: {
  primary: any;
  secondary: any;
}): boolean => {
  const { primary, secondary } = params;
  if (secondary && Object.keys(secondary).length > 0) {
    return true;
  }
  if (primary && Object.keys(primary).length >= 3) {
    return true;
  }
  if (primary && primary.hasMorePrimary) {
    return true;
  }
  return false;
};

function parseQueryParams(urlSearchParams: URLSearchParams) {
  const primary: Record<string, any> = {};
  const secondary: Record<string, any> = {};
  const page: Record<string, any> = {};

  urlSearchParams.forEach((value, key) => {
    const valuesArray = value.split("+");

    if (PRIMARY_PARAMS.includes(key)) {
      if (valuesArray.length >= 2) {
        primary.hasMorePrimary = true;
      }
      primary[key] = valuesArray;
    }

    if (SECONDARY_PARAMS.includes(key)) {
      if (valuesArray.length >= 2) {
        primary.hasMoreSecondary = true;
      }
      secondary[key] = valuesArray;
    }

    if (PAGINATION_PARAMS.includes(key)) {
      page[key] = valuesArray;
    }
  });

  return { primary, secondary, pageNumber: page };
}

export function getPaginationLinks(
  url: string,
  totalRecords: number,
  currentPage: number,
) {
  const requestUrl = new URL(url);
  const origin = requestUrl.origin;
  const pathname = requestUrl.pathname;
  const queryParams = requestUrl.searchParams;
  const totalPages = getTotalPages(totalRecords);
  const previousPage = currentPage - 1;
  const nextPage = currentPage + 1;
  let links;
  if (currentPage === 1) {
    // no previous page
    queryParams.set(
      APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.PAGE,
      nextPage.toString(),
    );
    links = [
      {
        rel: "next",
        href: `${origin}${pathname}${queryParams.toString()}`,
      },
    ];
  } else if (currentPage === totalPages) {
    // no next page
    queryParams.set(
      APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.PAGE,
      previousPage.toString(),
    );
    links = [
      {
        rel: "prev",
        href: `${origin}${pathname}${queryParams.toString()}`,
      },
    ];
  } else {
    // both next and previous page
    queryParams.delete(APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.PAGE);
    const nextPageQueryParams = new URLSearchParams(queryParams);
    nextPageQueryParams.set(
      APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.PAGE,
      nextPage.toString(),
    );

    const prevPageQueryParams = new URLSearchParams(queryParams);
    prevPageQueryParams.set(
      APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.PAGE,
      previousPage.toString(),
    );

    links = [
      {
        rel: "prev",
        href: `${origin}${pathname}${prevPageQueryParams.toString()}`,
      },
      {
        rel: "next",
        href: `${origin}${pathname}${nextPageQueryParams.toString()}`,
      },
    ];
  }
  return links;
}

function getCanonicalLinks(
  primary: Record<string, any>,
  locale: string,
  remainingPath: string,
  origin: string,
  page: number,
  localeList: string[],
) {
  const searchParams = new URLSearchParams();

  if (primary.location) {
    searchParams.set(
      APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.LOCATION,
      primary.location[0],
    );
  }
  if (primary.product) {
    searchParams.set(
      APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.PRODUCT,
      primary.product[0],
    );
  }
  if (primary.team) {
    searchParams.set(
      APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.TEAM,
      primary.team[0],
    );
  }
  if (primary.homeOffice) {
    searchParams.set(
      APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.HOME_OFFICE,
      primary.homeOffice[0],
    );
  }
  if (page > 1) {
    searchParams.set(
      APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.PAGE,
      page.toString(),
    );
  }
  const searchParamsSize = [searchParams.keys()].length;
  const url = `${origin}/${locale}/${remainingPath}${searchParamsSize > 0 ? "?" : ""}${searchParams.toString()}`;
  const alternateUrls = localeList.map((alternateLocale) => {
    return {
      rel: "alternate",
      href: `${origin}/${alternateLocale}/${remainingPath}${searchParamsSize > 0 ? "?" : ""}${searchParams.toString()}`,
      hreflang: alternateLocale,
    };
  });
  const canonicalLink = [
    {
      rel: "canonical",
      href: url,
    },
  ];
  return [...canonicalLink, ...alternateUrls];
}

export function getSearchMetaLinks(
  requestUrl: URL,
  localeList: string[],
  filters: any,
  searchQuery: string,
  sort: string,
  page: number,
) {
  const results: any = {};
  const metaResults = [
    {
      name: "robots",
      content: "noindex,follow",
    },
  ];
  const refDataFilters: Partial<ISetFilters> = {
    search: searchQuery || "",
    sort: sort || "",
    page: page || 1,
    location: filters?.locations || [],
    key: filters?.keywords || [],
    team: filters?.teams || [],
    product: filters?.products || [],
    lang: filters?.languages || [],
    homeOffice: filters && filters?.homeOffice ? "true" : "false",
  };
  const origin = requestUrl.origin;
  const pathName = requestUrl.pathname;
  const locale = pathName.split("/")[1];
  const remainingPath = pathName.split("/")[2];
  const params = new URLSearchParams();
  const encodedQueryParams = encodeUrl(params, refDataFilters, false);
  const { primary, secondary } = parseQueryParams(encodedQueryParams);
  const noIndexMeta = getMetaForNoIndex({ primary, secondary });
  if (noIndexMeta) {
    results.meta = metaResults;
  } else {
    results.links = getCanonicalLinks(
      primary,
      locale,
      remainingPath,
      origin,
      page,
      localeList,
    );
  }
  const paramsString = encodedQueryParams.toString();
  const requestUrlWithCorrectParams = `${origin}/${locale}/search${paramsString.length > 0 ? `?${paramsString}` : ""}`;

  return { searchMeta: results, requestUrlWithCorrectParams };
}

export function getJobDetailsMetaLinks(url: string, localeList: string[]) {
  const requestUrl = new URL(url);
  const origin = requestUrl.origin;
  const pathName = requestUrl.pathname;
  const pastLangUrl = pathName.split("/details/")[1];
  const alternateUrls = localeList.map((alternateLocale) => {
    return {
      rel: "alternate",
      href: `${origin}/${alternateLocale}/details/${pastLangUrl}`,
      hreflang: alternateLocale,
    };
  });
  const canonicalLink = [
    {
      rel: "canonical",
      href: url,
    },
  ];

  return [...canonicalLink, ...alternateUrls];
}
