import { Checkbox, Counter } from "@rpe-js/marcom-web-components";
import Accordion, {
  AccordionItem,
} from "@rpe-js/marcom-web-components/lib/CustomAccordion/CustomAccordion";
import React, { useCallback, useMemo } from "react";
import {
  SubTeamRefData,
  TeamsRefData,
} from "../../../../../shared/types/refData";
import useIntlMessage from "../../../../hooks/useIntlMessage";
import useIsMobile from "../../../../hooks/useIsMobile";

type TeamOfInterestWidgetProps = {
  teamsList: TeamsRefData[];
  selectedTeams: Record<string, { subTeam: string[]; isSelectAll: boolean }>;
  onChange: (
    team: TeamsRefData,
    subTeam: SubTeamRefData,
    checked: boolean,
  ) => void;
  onSelectDeselectClick?: (team: TeamsRefData, checked: boolean) => void;
  enableSelectDeselectAll?: boolean;
};

export const TeamOfInterestWidget = ({
  teamsList,
  selectedTeams,
  onChange,
  enableSelectDeselectAll = false,
  onSelectDeselectClick,
}: TeamOfInterestWidgetProps) => {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const isSubteamSelected = useCallback(
    (teamID: string, subTeamID: string): boolean => {
      return selectedTeams[teamID]?.subTeam?.includes(subTeamID) ?? false;
    },
    [selectedTeams],
  );

  const isSelectDeselectAllChecked = useCallback(
    (teamID: string) => {
      return selectedTeams[teamID]?.isSelectAll ?? false;
    },
    [selectedTeams],
  );

  const handleSelectDeselectAll = useCallback(
    (category: TeamsRefData, select: boolean) => {
      if (onSelectDeselectClick) onSelectDeselectClick(category, select);
    },
    [onSelectDeselectClick],
  );

  const selectedCounts = useMemo(() => {
    const map = new Map<string, number>();
    teamsList.forEach((category) => {
      const selectedCount = category.teams.filter((subTeam) =>
        isSubteamSelected(category.id, subTeam.id),
      ).length;
      map.set(category.id, selectedCount);
    });
    return map;
  }, [teamsList, isSubteamSelected]);

  return (
    <Accordion
      id="teamsOfInterest"
      compact={true}
      role="list"
      classes={{ root: "teams-widget-accordion" }}
      aria-label={t("jobsite.common.teamsInterestTitle") as string}
    >
      {teamsList.map((category) => {
        const count = selectedCounts.get(category.id) || 0;

        return (
          <AccordionItem
            titleWrapperTag={"h3"}
            titleWrapperTagAttrs={{ className: "fw-regular" }}
            key={category.id}
            title={
              <>
                <span className="d-flex flex-1 text-align-start">
                  {category.type}
                </span>
                <span role="status" aria-live="polite">
                  <Counter
                    count={count}
                    className={`d-inline-block ml-5 mr-5`}
                  />
                  <span className="a11y">
                    {t("jobsite.search.teamsSelected") as string}
                  </span>
                </span>
              </>
            }
            noPadding={false}
            expandableIconsPlus={true}
            classes={{ button: "teams-title" }}
            role="listitem"
          >
            {enableSelectDeselectAll && (
              <Checkbox
                required={false}
                id={`selectAll-${category.id}`}
                label={t("jobsite.common.selectall") as string}
                checked={isSelectDeselectAllChecked(category.id)}
                showTileView={isMobile}
                onValueChange={(checked) =>
                  handleSelectDeselectAll(category, checked)
                }
              />
            )}
            <div className="accordion-checkbox">
              {category.teams.map((subTeam) => (
                <div key={subTeam.uniqueKey}>
                  <Checkbox
                    required={false}
                    id={subTeam.uniqueKey}
                    label={subTeam.name}
                    checked={isSubteamSelected(category.id, subTeam.id)}
                    showTileView={isMobile}
                    onValueChange={(checked) =>
                      onChange(category, subTeam, checked)
                    }
                  />
                </div>
              ))}
            </div>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};
