import { BaseRefData } from "@rpe-js/core/src/types/refData";
import { TextButton } from "@rpe-js/marcom-web-components";
import { cloneDeep, isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { MyApplication } from "../../../../../../shared/types/talent/myApplication";
import { Address, Contact } from "../../../../../../shared/types/talent/talent";
import { Label } from "../../../../../components/base/Label";
import { useCurrentUserContext } from "../../../../../CurrentUserContext";
import useFocusFirstErrorField from "../../../../../hooks/useFocusFirstErrorField";
import useIntlMessage from "../../../../../hooks/useIntlMessage";
import { LabelInfoView } from "../../../components/LabelInfoView";
import { useEmailPhone } from "../../../hooks/useEmailPhone";
import { useValidateContactForm } from "../../../hooks/useValidateContactForm";
import { AddressView } from "../../../information/components/address/addressView";
import { NameTextBox } from "../../../information/components/NameTextBox";
import {
  getAddressFormForCountry,
  getAddressFromConfig,
  getConfigFromAddress,
} from "../../../information/components/utils";
import { EditAddress } from "../../../information/editAddress";
import {
  EmailTextField,
  PhoneTextField,
} from "../../../information/editEmailAndPhoneNumber";
import {
  getFormConfigFromContact,
  getMyAppContactFormConfig,
  getPreferredEmailPhoneFromConfig,
} from "../../../information/nameFormUtils";
import { AddressFormConfig, ContactNameForm } from "../../../information/types";
import { ProfileFormField } from "../../../types";
import { useMyApplicationContext } from "../../context/context";
import { MyApplicationActionDispatcher } from "../../context/state";
import { ApplicationSection, SectionMode } from "../../context/types";
import { useUpdateApplication } from "../../hooks/createAndUpdateApplicationHooks";
import { SectionHeader } from "../SectionHeader";
import { SectionActions } from "./sectionActions";

interface SectionProps {
  id: string;
  onSave: (
    name: ApplicationSection,
    mode: SectionMode,
    application: MyApplication,
  ) => void;
  mode: SectionMode;
}

interface ContactInfoProps extends SectionProps {}

export function ContactInfoSection(props: ContactInfoProps) {
  const { id, mode, onSave } = props;
  const [showErrors, setShowErrors] = useState(false);
  const { state, dispatch } = useMyApplicationContext();
  const { currentUser } = useCurrentUserContext();
  const { contactInfoSection, application, talent, applicationStep } = state;
  const { refData } = applicationStep;
  const { title } = contactInfoSection;
  const sectionName: ApplicationSection = "contactInformation";
  const { t } = useIntlMessage();
  const { setFocusFirstErrorField } = useFocusFirstErrorField(
    `profileApplication-application-${sectionName}`,
  );

  const [stepContactInfo, setStepContactInfo] = useState<Contact | undefined>(
    contactInfoSection?.contact || application?.contact || talent?.contact,
  );
  const [stepAddressInfo, setStepAddressInfo] = useState<Address[] | undefined>(
    contactInfoSection?.addresses ||
      application?.addresses ||
      talent?.addresses,
  );

  const [contactNameForm, setContactNameForm] = useState<ContactNameForm>(
    getFormConfigFromContact("myapplication", stepContactInfo),
  );
  const {
    emailPhoneForm,
    onOtherPhoneOrEmailAdd,
    onOtherPhoneOrEmailDelete,
    onOtherPhoneOrEmailUpdate,
    onPreferredEmailUpdate,
    onPreferredPhoneUpdate,
    validateEmailPhone,
    resetEmailPhoneForm,
  } = useEmailPhone(stepContactInfo as Contact, true, "myapplication");

  const { validateNameSection, validateAddressForm } = useValidateContactForm();

  const [addressForm, setAddressForm] = useState<AddressFormConfig[]>(
    getConfigFromAddress(stepAddressInfo as Address[], "myapplication"),
  );

  useEffect(() => {
    if (mode === "view") {
      setStepContactInfo(contactInfoSection?.contact);
      setStepAddressInfo(contactInfoSection?.addresses);
    }
  }, [contactInfoSection?.addresses, contactInfoSection?.contact, mode]);

  const {
    //isUpdateApplicationError,
    isUpdateApplicationLoading,
    isUpdateApplicationSuccess,
    updateApplication,
    resetUpdateApplication,
    updatedApplication,
  } = useUpdateApplication();

  const onNameFormFieldUpdate = useCallback(
    (formField: ProfileFormField, value: string) => {
      const updated = { ...contactNameForm };
      if (formField.name === "firstName") {
        updated.firstName = { ...formField, value: value };
      }

      if (formField.name === "lastName") {
        updated.lastName = { ...formField, value: value };
      }
      const validatedContactForm = validateNameSection(updated, false, false);
      setContactNameForm(validatedContactForm.updatedContactForm);
    },
    [contactNameForm, validateNameSection],
  );

  const onCountryChange = useCallback(
    (key: number, refData: BaseRefData) => {
      const index = addressForm.findIndex((p) => p.key === key);
      const updated = [...addressForm];
      if (index === 0) {
        updated[index].country = {
          value: refData?.id,
          label: refData?.name,
          type: "dropdown",
        };
      } else {
        const configForm = getAddressFormForCountry(refData);
        updated[index] = configForm;
      }
      setAddressForm(updated);
    },
    [addressForm],
  );

  const onAddressUpdate = useCallback(
    (
      addressIndex: number,
      rowIndex: number,
      columnIndex: number,
      value: string,
    ) => {
      const addressFormField =
        addressForm[addressIndex].fields[rowIndex][columnIndex];
      const updated = [...addressForm];
      updated[addressIndex].fields[rowIndex][columnIndex] = {
        ...addressFormField,
        value: value,
      };
      if (!updated[addressIndex].fields[rowIndex][columnIndex]?.optional) {
        updated[addressIndex].fields[rowIndex][columnIndex].error = value
          ? false
          : true;
      }
      setAddressForm(updated);
    },
    [addressForm],
  );

  const onAddAddress = useCallback(() => {
    setAddressForm((prevValue: AddressFormConfig[]) => [
      ...prevValue,
      getAddressFormForCountry(),
    ]);
  }, []);

  const onRemoveAddress = useCallback((key: number) => {
    setAddressForm(function (prevState: AddressFormConfig[]) {
      const updated = prevState.filter((p) => p.key !== key);
      return [...updated];
    });
  }, []);

  const validate = useCallback((): boolean => {
    const validatedContactForm = validateNameSection(
      contactNameForm,
      false,
      false,
    );
    setContactNameForm(validatedContactForm.updatedContactForm);
    const validatedAddressForm = validateAddressForm(addressForm);
    setAddressForm(validatedAddressForm.updatedAddressForm);
    const validationEmailHasError = validateEmailPhone();
    return (
      validatedContactForm.hasError ||
      validationEmailHasError ||
      validatedAddressForm.hasError
    );
  }, [
    addressForm,
    contactNameForm,
    validateAddressForm,
    validateEmailPhone,
    validateNameSection,
  ]);

  const getUpdatedApplication = useCallback((): MyApplication => {
    const addresses = getAddressFromConfig(addressForm);
    const updatedContact: Contact = getMyAppContactFormConfig(
      contactNameForm,
      stepContactInfo || {},
    );
    getPreferredEmailPhoneFromConfig(emailPhoneForm, false, updatedContact);
    const updated = cloneDeep(application) || ({} as MyApplication);
    updated.contact = updatedContact;
    updated.addresses = addresses;
    return updated;
  }, [
    application,
    addressForm,
    contactNameForm,
    emailPhoneForm,
    stepContactInfo,
  ]);

  useEffect(() => {
    if (
      !isUpdateApplicationLoading &&
      isUpdateApplicationSuccess &&
      updatedApplication
    ) {
      MyApplicationActionDispatcher.onAppSecContactInfoSave(dispatch, {
        addresses: updatedApplication.addresses,
        contact: updatedApplication.contact,
      });
      setShowErrors(false);
      onSave(sectionName, mode, updatedApplication as MyApplication);
      resetUpdateApplication();
    }
  }, [
    dispatch,
    isUpdateApplicationLoading,
    isUpdateApplicationSuccess,
    mode,
    onSave,
    updatedApplication,
    resetUpdateApplication,
  ]);

  const onSectionSave = useCallback(async () => {
    setShowErrors(true);
    const hasError = validate();
    if (!hasError && currentUser.talentId) {
      const requestBody = getUpdatedApplication();
      await updateApplication(
        currentUser.talentId,
        (application as MyApplication).id,
        "contact",
        requestBody as MyApplication,
      );
    } else {
      setFocusFirstErrorField(true);
    }
  }, [
    validate,
    currentUser.talentId,
    getUpdatedApplication,
    updateApplication,
    application,
    setFocusFirstErrorField,
  ]);

  const onCancel = useCallback(() => {
    setShowErrors(false);
    MyApplicationActionDispatcher.onApplicationSectionCancel(
      dispatch,
      sectionName,
    );
  }, [sectionName, dispatch]);

  const onSectionEdit = useCallback(() => {
    MyApplicationActionDispatcher.onApplicationSectionEdit(
      dispatch,
      sectionName,
    );
    setContactNameForm(
      getFormConfigFromContact("myapplication", stepContactInfo),
    );
    resetEmailPhoneForm();
    setAddressForm(
      getConfigFromAddress(stepAddressInfo as Address[], "myapplication"),
    );
  }, [dispatch, resetEmailPhoneForm, stepAddressInfo, stepContactInfo]);

  return (
    <SectionHeader id={id} title={title} mode={mode} onEdit={onSectionEdit}>
      {mode === "view" && (
        <ContactView
          stepAddressInfo={stepAddressInfo}
          stepContactInfo={stepContactInfo}
        />
      )}
      {(mode === "edit" || mode === "create") && (
        <div className="pt-20">
          <div>
            <NameTextBox
              profileFormField={contactNameForm.firstName}
              onChange={onNameFormFieldUpdate}
              showErrors={showErrors}
            />
            <NameTextBox
              profileFormField={contactNameForm.lastName}
              onChange={onNameFormFieldUpdate}
              showErrors={showErrors}
            />
          </div>
          <div>
            <EmailTextField
              index={0}
              field={emailPhoneForm.preferredEmail as ProfileFormField}
              onUpdate={onPreferredEmailUpdate}
              required={true}
              showErrors={showErrors}
            />
            {emailPhoneForm.otherEmail?.map((e, idx) => (
              <EmailTextField
                key={e.key}
                index={idx}
                field={e}
                showErrors={showErrors}
                onUpdate={(field: ProfileFormField, val: string) =>
                  onOtherPhoneOrEmailUpdate("email", field, val)
                }
                onDelete={(field) => onOtherPhoneOrEmailDelete("email", field)}
              />
            ))}
            {(emailPhoneForm.otherEmail || []).length < 2 && (
              <div className="pt-10 pb-10">
                <TextButton
                  label={t("jobsite.common.addEmailAddress") as string}
                  onClick={() => onOtherPhoneOrEmailAdd("email")}
                  icon="icon-pluscircle"
                />
              </div>
            )}
          </div>
          <div>
            <PhoneTextField
              index={0}
              showErrors={showErrors}
              field={emailPhoneForm.preferredPhone as ProfileFormField}
              onUpdate={onPreferredPhoneUpdate}
            />
            {emailPhoneForm.otherPhone?.map((e, idx) => (
              <PhoneTextField
                key={e.key}
                index={idx}
                field={e}
                showErrors={showErrors}
                onUpdate={(field: ProfileFormField, val: string) =>
                  onOtherPhoneOrEmailUpdate("phone", field, val)
                }
                onDelete={(field) => onOtherPhoneOrEmailDelete("phone", field)}
              />
            ))}
            {(emailPhoneForm.otherPhone || []).length < 2 && (
              <div className="pt-10 pb-10">
                <TextButton
                  label={t("jobsite.common.addPhoneNumber") as string}
                  onClick={() => onOtherPhoneOrEmailAdd("phone")}
                  icon="icon-pluscircle"
                />
              </div>
            )}
          </div>
          <EditAddress
            form={addressForm}
            dropdownData={refData?.countryRefData as BaseRefData[]}
            mode="myapplication"
            isInternal={false}
            onAddAddress={onAddAddress}
            onAddressUpdate={onAddressUpdate}
            onCountryChange={onCountryChange}
            onRemoveAddress={onRemoveAddress}
          />
        </div>
      )}
      {mode !== "view" && (
        <SectionActions
          onCancel={onCancel}
          onSave={onSectionSave}
          mode={mode}
        />
      )}
    </SectionHeader>
  );
}

function ContactView(props: {
  stepContactInfo?: Contact;
  stepAddressInfo?: Address[];
}) {
  const { stepContactInfo, stepAddressInfo } = props;
  const { t } = useIntlMessage();
  return (
    <div className="row large-12 small-12 mt-10">
      <div className="large-6 small-12">
        <div className="flex-column mb-10">
          <LabelInfoView
            i18nLabel="jobsite.common.firstName"
            value={stepContactInfo?.firstName}
          ></LabelInfoView>
        </div>
        <div className="flex-column mb-10">
          <LabelInfoView
            i18nLabel="jobsite.common.lastName"
            value={stepContactInfo?.lastName}
          ></LabelInfoView>
        </div>
        <div className="flex-column mb-10">
          <LabelInfoView
            i18nLabel="jobsite.common.preferredEmail"
            value={stepContactInfo?.preferredEmail}
          ></LabelInfoView>
        </div>
        {(!isEmpty(stepContactInfo?.secondaryEmail) ||
          !isEmpty(stepContactInfo?.otherEmail)) && (
          <div className="flex-column mb-10">
            <Label label={t("jobsite.common.otherEmail") as string} />
            {!isEmpty(stepContactInfo?.secondaryEmail) && (
              <span className="info mb-10">
                {stepContactInfo?.secondaryEmail}
              </span>
            )}
            {!isEmpty(stepContactInfo?.otherEmail) && (
              <span className="info mb-10">{stepContactInfo?.otherEmail}</span>
            )}
          </div>
        )}
        <div className="flex-column mb-10">
          <LabelInfoView
            i18nLabel="jobsite.common.preferredPhone"
            value={stepContactInfo?.preferredPhone}
          ></LabelInfoView>
        </div>
        <div className="flex-column mb-10">
          {(!isEmpty(stepContactInfo?.secondaryPhone) ||
            !isEmpty(stepContactInfo?.otherPhone)) && (
            <div className="flex-column mb-10">
              <Label label={t("jobsite.common.otherPhone") as string} />
              {!isEmpty(stepContactInfo?.secondaryPhone) && (
                <span className="info mb-10">
                  {stepContactInfo?.secondaryPhone}
                </span>
              )}
              {!isEmpty(stepContactInfo?.otherPhone) && (
                <span className="info mb-10">
                  {stepContactInfo?.otherPhone}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="large-6 small-12">
        {stepAddressInfo?.map((address, idx) => (
          <div className="flex-column mb-10" key={idx}>
            <AddressView address={address} displayMode="application" />
          </div>
        ))}
      </div>
    </div>
  );
}
