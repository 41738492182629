import { Button } from "@rpe-js/marcom-web-components";
import React from "react";
import useIntlMessage from "../../../../../hooks/useIntlMessage";
import { idGenerator } from "../../../../../utils/idGenerator";
import { SectionMode } from "../../context/types";

interface SectionActionsProps {
  mode: SectionMode;
  onCancel: () => void;
  onSave: () => void;
}

export function SectionActions(props: SectionActionsProps) {
  const { t } = useIntlMessage();
  const { mode, onCancel, onSave } = props;
  return (
    <div className="mt-20 mb-20 d-flex justify-center">
      {mode === "edit" && (
        <span className="mr-20">
          <Button
            size="base"
            color="secondary"
            blockedVariant={true}
            label={t("jobsite.profile.myApplication.cancel") as string}
            onClick={onCancel}
            id={idGenerator("profileApplication", "section-edit").generateId(
              "button",
            )}
          />
        </span>
      )}
      {mode === "create" && (
        <span>
          <Button
            size="base"
            color="primary"
            blockedVariant={true}
            label={t("jobsite.profile.myApplication.saveContinue") as string}
            onClick={onSave}
            id={idGenerator(
              "profileApplication",
              "section-save-continue",
            ).generateId("button")}
          />
        </span>
      )}
      {mode === "edit" && (
        <span>
          <Button
            size="base"
            color="primary"
            blockedVariant={true}
            label={t("jobsite.profile.myApplication.save") as string}
            onClick={onSave}
            id={idGenerator("profileApplication", "section-save").generateId(
              "button",
            )}
          />
        </span>
      )}
    </div>
  );
}
