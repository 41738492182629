import React from "react";
// import getPreferredName from "../../utils/get-preferred-name";
import translateReplace from "../../hooks/translateReplace";
import useIntlMessage from "../../hooks/useIntlMessage";
import { getPreferredName } from "../../util";
import { idGenerator } from "../../utils/idGenerator";
import InternalJobInformationItem from "./InternalJobInformationItem";

interface Person {
  firstName?: string;
  lastName?: string;
  preferredName?: string;
}

interface Company {
  name?: string;
}

interface JobDetails {
  hiringManager?: Person;
  recruiter?: Person;
  shift?: string;
  employmentType?: string;
  company?: Company;
  glSiteDepartmentName?: string;
  highJobTitle?: string;
  lowJobTitle?: string;
  internalDeadlineDate?: number;
}

interface JobDetailTranslations {
  [key: string]: string;
}

interface InternalJobInformationProps {
  jobDetails: JobDetails;
  jobDetailTranslations?: JobDetailTranslations;
  selectedLocale?: string;
  id?: string;
}

const InternalJobInformation: React.FC<InternalJobInformationProps> = ({
  jobDetails = {},
  jobDetailTranslations,
  id,
  // selectedLocale,
}) => {
  const { t } = useIntlMessage();
  const idGeneratorContainer = idGenerator("jobdetails", id || "");
  const {
    hiringManager = {},
    recruiter = {},
    shift,
    employmentType,
    company = {},
    glSiteDepartmentName,
    highJobTitle,
    lowJobTitle,
    internalDeadlineDate,
  } = jobDetails || {};

  hiringManager.firstName = getPreferredName(
    hiringManager.firstName as string,
    hiringManager.preferredName,
  );
  recruiter.firstName = getPreferredName(
    recruiter.firstName as string,
    recruiter.preferredName,
  );

  const hiringManagerLabel =
    jobDetailTranslations?.["jobsite.jobdetails.hiringManager"] ||
    (t("jobsite.jobdetails.hiringManager", {
      firstName: hiringManager.firstName || "",
      lastName: hiringManager.lastName || "",
    }) as string);
  const recruiterLabel =
    jobDetailTranslations?.["jobsite.jobdetails.recruiter"] ||
    (t("jobsite.jobdetails.recruiter", {
      firstName: recruiter.firstName || "",
      lastName: recruiter.lastName || "",
    }) as string);
  const shiftLabel =
    jobDetailTranslations?.["jobsite.jobdetails.jobShift"] ||
    (t("jobsite.jobdetails.jobShift", {
      shiftSelection: shift || "",
    }) as string);
  const empTypeLabel =
    jobDetailTranslations?.["jobsite.information.jobEmployeeTypeTitle"] ||
    (t("jobsite.information.jobEmployeeTypeTitle", {
      type: employmentType || "",
    }) as string);
  const deadLineLabel =
    jobDetailTranslations?.["jobsite.jobdetails.jobDeadLine"] ||
    (t("jobsite.jobdetails.jobDeadLine", {
      title: internalDeadlineDate?.toString() || "",
    }) as string);
  const companyLabel =
    jobDetailTranslations?.["jobsite.jobdetails.jobCompany"] ||
    (t("jobsite.jobdetails.jobCompany", {
      company: company.name || "",
    }) as string);
  const siteDeptLabel =
    jobDetailTranslations?.["jobsite.jobdetails.jobCostCenter"] ||
    (t("jobsite.jobdetails.jobCostCenter", {
      dept: glSiteDepartmentName || "",
    }) as string);
  const highJobLabel =
    jobDetailTranslations?.["jobsite.jobdetails.jobHighProfileTitle"] ||
    (t("jobsite.jobdetails.jobHighProfileTitle", {
      title: highJobTitle || "",
    }) as string);
  const lowJobLabel =
    jobDetailTranslations?.["jobsite.jobdetails.jobLowProfileTitle"] ||
    (t("jobsite.jobdetails.jobLowProfileTitle", {
      title: lowJobTitle || "",
    }) as string);

  return (
    <div className="row">
      <ul
        role="list"
        id={idGeneratorContainer.generateId("itemlist")}
        className="column large-6 small-12 list-nobullet"
      >
        <InternalJobInformationItem
          id={idGeneratorContainer.generateId("managername")}
          label={translateReplace(
            hiringManagerLabel,
            ["{firstName}", "{lastName}"],
            [hiringManager.firstName || "", hiringManager.lastName || ""],
          )}
          hide={!hiringManager.firstName && !hiringManager.lastName}
        />
        <InternalJobInformationItem
          id={idGeneratorContainer.generateId("recruitername")}
          label={translateReplace(
            recruiterLabel,
            ["{firstName}", "{lastName}"],
            [recruiter.firstName || "", recruiter.lastName || ""],
          )}
          hide={!recruiter.firstName && !recruiter.lastName}
        />
        <InternalJobInformationItem
          id={idGeneratorContainer.generateId("shift")}
          label={translateReplace(
            shiftLabel,
            ["{shiftSelection}"],
            [shift || ""],
          )}
          hide={!shift}
        />
        <InternalJobInformationItem
          id={idGeneratorContainer.generateId("employmenttype")}
          label={translateReplace(
            empTypeLabel,
            ["{type}"],
            [employmentType || ""],
          )}
          hide={!employmentType}
        />
        <InternalJobInformationItem
          id={idGeneratorContainer.generateId("deadline")}
          label={translateReplace(
            deadLineLabel,
            ["{title}"],
            [internalDeadlineDate?.toString() || ""],
          )}
          hide={!internalDeadlineDate}
        />
      </ul>
      <ul
        role="list"
        id={idGeneratorContainer.generateId("companyinfo")}
        className="column large-6 small-12 list-nobullet"
      >
        <InternalJobInformationItem
          id={idGeneratorContainer.generateId("companyname")}
          label={translateReplace(
            companyLabel,
            ["{company}"],
            [company.name || ""],
          )}
          hide={!company.name}
        />
        <InternalJobInformationItem
          id={idGeneratorContainer.generateId("dept")}
          label={translateReplace(
            siteDeptLabel,
            ["{dept}"],
            [glSiteDepartmentName || ""],
          )}
          hide={!glSiteDepartmentName}
        />
        <InternalJobInformationItem
          id={idGeneratorContainer.generateId("jobtitle")}
          label={translateReplace(
            highJobLabel,
            ["{title}"],
            [highJobTitle || ""],
          )}
          hide={!highJobTitle}
        />
        <InternalJobInformationItem
          id={idGeneratorContainer.generateId("lowjobtitle")}
          label={translateReplace(
            lowJobLabel,
            ["{title}"],
            [lowJobTitle || ""],
          )}
          hide={!lowJobTitle}
        />
      </ul>
    </div>
  );
};

export default InternalJobInformation;
