import React, { ReactNode, useEffect, useReducer } from "react";
import { ProfileContext } from "./ProfileContext";
import { reducer } from "./ProfileReducer";
import { ProfileState } from "./ProfileState";
import { getTalentResume, resumeMapper } from "../../../utils/fileUtil";

interface ProfileProviderProps {
  children: ReactNode;
  initialState?: ProfileState;
}

export const ProfileProvider: React.FC<ProfileProviderProps> = ({
  children,
  initialState = {
    id: "",
    talentId: "",
    talentDSID: "",
  },
}: ProfileProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (state.fileMetaData && state.fileMetaData.length) {
      const resumeResponse = getTalentResume(state.fileMetaData);
      if (resumeResponse && resumeResponse.length > 0) {
        const resumeDetails = resumeMapper(resumeResponse[0]);
        dispatch({ type: "UPDATE_RESUME", payload: resumeDetails });
      }
    }
  }, [state.fileMetaData]);

  return (
    <ProfileContext.Provider value={{ state, dispatch }}>
      {children}
    </ProfileContext.Provider>
  );
};
