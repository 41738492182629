import { useEffect } from "react";
/**
 * Temporary Solution: Fix for Modal Overlay Focus Issues with VoiceOver
 *
 * This hook is a temporary workaround to address focus order flickering and focus lost issues
 * for interactive components (typeahead, input, and dropdown) within modal overlay when using VoiceOver
 *
 * The modal overlay component (rendered from createPortal) may reapply a tabindex on the overlay content,
 * which interferes with proper focus management. This hook uses a MutationObserver to continuously remove the tabindex
 * attribute from the element with [data-core-overlay-content] so that interactive elements remain accessible
 *
 * NOTE: This is a temporary solution. The ideal fix is to correct the underlying focus management in the modal overlay
 */
export function useRemoveOverlayTabIndex() {
  useEffect(() => {
    const portal = document.getElementById("portal") || document.body;
    // MutationObserver to watch for changes in the portal subtree
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        // watch for childlist additions or attribute changes
        if (mutation.type === "childList" || mutation.type === "attributes") {
          const overlayContent = portal.querySelector(
            "[data-core-overlay-content]",
          );
          if (overlayContent && overlayContent.hasAttribute("tabindex")) {
            overlayContent.removeAttribute("tabindex");
          }
        }
      });
    });
    // observing the portal node for subtree modifications
    observer.observe(portal, {
      childList: true,
      attributes: true,
      subtree: true,
    });
    // clean up the observer
    return () => observer.disconnect();
  }, []);
}
