import React from "react";
import { idGenerator } from "../../utils/idGenerator";

export interface LabelProps {
  id?: string;
  label: string;
  classes?: string[];
  variant?: "prominent" | "less-prominent"; // prominent -> black, less-prominent -> grey
  setAsHtml?: boolean;
  htmlFor?: string;
}
export const Label = ({
  id,
  label,
  variant = "less-prominent",
  classes = [],
  setAsHtml = false,
  htmlFor,
}: LabelProps) => {
  const labelIdGenerator = idGenerator("global", "label");
  const labelId = id ? id : labelIdGenerator.generateId(label);

  return (
    <>
      {!setAsHtml && (
        <label
          id={labelId}
          className={`label${variant === "less-prominent" ? " label-grey" : ""} ${classes.join(" ")}`}
          {...(htmlFor ? { htmlFor } : {})}
        >
          {label}
        </label>
      )}
      {setAsHtml && (
        <label
          id={labelId}
          className={`label${variant === "less-prominent" ? " label-grey" : ""} ${classes.join(" ")}`}
          dangerouslySetInnerHTML={{ __html: label }}
          {...(htmlFor ? { htmlFor } : {})}
        ></label>
      )}
    </>
  );
};
