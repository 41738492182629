import React from "react";
import { ICON_SIZE } from "../../../shared/types";

export interface IconProps {
  name:
    | "add-blue"
    | "apple-logo"
    | "attachment-grey"
    | "saved-search-blue"
    | "share-blue"
    | "arrow-back-black"
    | "audio"
    | "checkmark-white-thin"
    | "checkmark-white"
    | "chevron-blue-down"
    | "chevron-blue-left"
    | "chevron-blue-right"
    | "chevron-blue-up"
    | "chevron-grey-down"
    | "chevron-grey-up"
    | "circle-checkmark-green"
    | "cog-blue"
    | "cog-grey"
    | "drop-down-arrow"
    | "favorites-filled-grey"
    | "favorites-filled"
    | "favorites"
    | "filter-list-black"
    | "filter-list-blue"
    | "geo-blue"
    | "linkedin-upload"
    | "linkedin-review"
    | "link-grey"
    | "minus-blue"
    | "new-image"
    | "new-text"
    | "plus"
    | "radio"
    | "recently-viewed-blue"
    | "recently-viewed-grey"
    | "resume"
    | "saved-search-blue"
    | "saved-search-grey"
    | "search-blue"
    | "search-grey"
    | "share-blue"
    | "throbber-blue"
    | "x-circle"
    | "x-grey"
    | "x-red";
  size?: ICON_SIZE;
  color?: string;
  cursor?: "pointer" | "default";
  width?: string;
  height?: string;
  classes?: string;
  id?: string;
  altString?: string;
}

const Icon = ({
  name,
  size = "medium",
  color,
  cursor = "default",
  width,
  height,
  classes = "",
  id,
  altString = "",
}: IconProps) => {
  const iconSize = {
    xsmall: 15,
    small: 20,
    medium: 24,
    large: 34,
    xlarge: 50,
  };
  const appBasePath = process.env.APP_BASE_PATH || "";
  const className = `${color ? `icon ${color}` : "icon"} cursor-${cursor} ${classes}`;

  return (
    <img
      id={id}
      src={`${appBasePath}/public/images/${name}.svg`}
      alt={altString}
      width={width ? width : iconSize[size]}
      height={height ? height : iconSize[size]}
      className={className}
    />
  );
};

export default Icon;
