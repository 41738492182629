import { Button } from "@rpe-js/marcom-web-components";
import React, { useCallback, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router";
import { ALERT_WRAPPER_ID } from "../../app.constants";
import AppContext from "../../AppContext";
import { AlertWrapper } from "../../components/base/AlertWrapper";
import HtmlRenderer from "../../components/base/HtmlRenderer";
import { useLocaleAwareLink } from "../../components/feature/LocaleAwareLink";
import { useCurrentUserContext } from "../../CurrentUserContext";
import translateReplace from "../../hooks/translateReplace";
import useIntlMessage from "../../hooks/useIntlMessage";
import { getPreferredName } from "../../util";
import { idGenerator } from "../../utils/idGenerator";

export function WelcomeTalent() {
  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get("returnUrl");
  const navigate = useNavigate();
  const getLink = useLocaleAwareLink();
  const { appUIState } = useContext(AppContext);
  const { isExternal } = appUIState.appData;
  const { currentUser } = useCurrentUserContext();
  const { t } = useIntlMessage();

  const onClose = useCallback(() => {
    navigate(returnUrl || getLink(false, "search"));
  }, [returnUrl, getLink, navigate]);
  return (
    <>
      <div className="text-center mt-40 mb-40">
        <h1 className="t-headline fw-medium mb-20">
          {t("jobsite.common.displayName", {
            firstName: getPreferredName(
              currentUser?.contact?.firstName as string,
              currentUser?.contact?.preferredName,
            ),
            lastName: currentUser?.contact?.lastName,
          })}
        </h1>
        {isExternal && <p>{t("jobsite.common.profileInfoMessage")}</p>}
      </div>
      <AlertWrapper
        remove={{
          closeBtnAriaLabel: t("jobsite.common.closeAlert") as string,
          onRemove: onClose,
        }}
        aria-labelledby={idGenerator(
          "profile",
          "welcome-talent-title",
        ).generateId()}
        id={ALERT_WRAPPER_ID.generateId("welcome-talent")}
      >
        <div>
          <span>
            <h2
              className="t-quote-reduced"
              id={idGenerator("profile", "welcome-talent-title").generateId()}
            >
              {t("jobsite.common.welcomeToAppleCareers")}
            </h2>
            <p>{t("jobsite.common.profileInitiated")}</p>
            <p className="d-inline-block pt-10 pb-10">
              <Button
                id={"reviewAndUpdate"}
                onClick={() => navigate(getLink(true, "profile/info"))}
                label={t("jobsite.common.reviewAndUpdateProfile") as string}
                size="base"
                blockedVariant={true}
              />
            </p>
            <HtmlRenderer
              classes="hyperlink-underline"
              htmlTag="p"
              initialContent={translateReplace(
                t("jobsite.common.dontWantToBeInSystem") as string,
                ["{linkUrl}"],
                ["mailto:dpo@apple.com"],
              )}
            />
          </span>
        </div>
      </AlertWrapper>
    </>
  );
}
