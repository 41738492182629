import { idGenerator } from "../../utils/idGenerator";

export const parsedModalIdGenerator = idGenerator("parsedmodal", "review");

export const PARSED_MODAL_SECTION_EDUCATION = "education";
export const PARSED_MODAL_SECTION_EMPLOYMENTS = "employments";
export const PARSED_MODAL_SECTION_SKILLS = "skills";
export const PARSED_MODAL_SECTION_LANGUAGES = "languages";

export const PARSED_MODAL_CONTAINER_ID =
  parsedModalIdGenerator.generateId("container");
export const PARSED_MODAL_OVERLAY_ID =
  parsedModalIdGenerator.generateId("overlay");

export const PARSED_MODAL_SECTION_EDUCATION_ID =
  parsedModalIdGenerator.generateId(PARSED_MODAL_SECTION_EDUCATION);
export const PARSED_MODAL_SECTION_EMPLOYMENTS_ID =
  parsedModalIdGenerator.generateId(PARSED_MODAL_SECTION_EMPLOYMENTS);
export const PARSED_MODAL_SECTION_SKILLS_ID = parsedModalIdGenerator.generateId(
  PARSED_MODAL_SECTION_SKILLS,
);
export const PARSED_MODAL_SECTION_LANGUAGES_ID =
  parsedModalIdGenerator.generateId(PARSED_MODAL_SECTION_LANGUAGES);

export const PARSED_MODAL_EDUCATION_TITLE_ID =
  parsedModalIdGenerator.generateId("education-title");
export const PARSED_MODAL_EMPLOYMENTS_TITLE_ID =
  parsedModalIdGenerator.generateId("employments-title");
export const PARSED_MODAL_SKILLS_TITLE_ID =
  parsedModalIdGenerator.generateId("skills-title");
export const PARSED_MODAL_LANGUAGES_TITLE_ID =
  parsedModalIdGenerator.generateId("languages-title");

export const PARSED_MODAL_SAVE_ALL_BUTTON_ID = idGenerator(
  "parsedmodal",
  "saveall",
).generateId("button");
export const PARSED_MODAL_DISCARD_ALL_BUTTON_ID = idGenerator(
  "parsedmodal",
  "discard",
).generateId("button");
