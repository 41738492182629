import { DropDownOptionProps } from "@rpe-js/marcom-web-components";
import { I18nValues } from "../shared/types";
import { TeamsRefData } from "../shared/types/refData";
import { Team } from "../shared/types/talent/talent";
import APP_CONSTANTS from "../utilities/appConstants";
import { NO, YES } from "./app.constants";

export function getFullPath(
  appBasePath: string | undefined,
  routePath: string,
): string {
  return appBasePath ? `${appBasePath}/${routePath}` : `/${routePath}`;
}

export function getPreferredName(
  firstName: string,
  preferredName: string | undefined,
): string {
  if (preferredName && preferredName !== firstName) {
    return preferredName;
  }
  return firstName;
}

export function redirectToPath(
  appBasePathPrefix: string | undefined,
  routePath: string,
) {
  window.location = getFullPath(appBasePathPrefix, routePath) as string &
    Location;
}

export function transformUrlParam(urlParam: string) {
  let title = (urlParam || "").trim().toLowerCase();
  const regex = new Map([
    [/[!$%^&*()_+–|~=`\\'{}"\[\]:\/;<>?,.@#’‘“– ——]/g, "-"], // removes any special characters and replace with hyphen ** Please do not remove the space at the end of regEx
    [/(^-+)|(-+$)/g, ""], // removes hyphens('-') at start and end
    [/(--+)/g, "-"], // removes multiple hyphens and replace with one hyphen
  ]);
  regex.forEach((value, key) => {
    title = title.replace(key, value);
  });
  title = encodeURI(title);
  return title;
}

export function isRetailType(jobDetails: any) {
  return jobDetails.jobType == APP_CONSTANTS.JOB_TYPE.RETAIL;
}

//TODO: map jobDetails param to type instead of any
export function isCorporate(jobDetails: any) {
  return jobDetails.jobType == APP_CONSTANTS.JOB_TYPE.CORPORATE;
}

export function getYesOrNoValue(value: boolean | undefined) {
  if (value === true) return YES;
  else if (value === false) return NO;
  else return null;
}

export function getYesOrNoi18nStr(value: boolean | undefined): string {
  if (value === true) {
    return "jobsite.common.yes";
  } else if (value === false) {
    return "jobsite.common.no";
  }
  return "jobsite.common.notSpecified";
}

// TODO: label must be i18n string
export function getYesAndNoRadioOptions(
  yesId: string,
  noId: string,
  translationFn: (id: string, values?: I18nValues) => React.ReactNode,
) {
  return [
    {
      id: yesId,
      label: translationFn("jobsite.common.yes") as string,
      value: YES,
    },
    {
      id: noId,
      label: translationFn("jobsite.common.no") as string,
      value: NO,
    },
  ];
}

// method that returns current date in YYYY-MM-DD format for self-disclosure save API
export function getDisablityCompletedOnDate() {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function getPastNYear(
  disabledi18nOption: string,
  noOfYears?: number,
): DropDownOptionProps[] {
  noOfYears = noOfYears || 100;
  const currentDate = new Date();
  const toYr = currentDate.getFullYear();
  const fromYr = toYr - noOfYears;
  const years = [];
  for (let idx = toYr; idx >= fromYr; idx--) {
    years.push({
      value: idx + "",
      label: idx + "",
    });
  }
  return [{ disabled: true, label: disabledi18nOption, value: "" }, ...years];
}

export function getValidatedParam(
  searchParams: URLSearchParams,
  key: string,
): string | null {
  if (APP_CONSTANTS.JOB_DETAILS_QUERY_PARAMS.includes(key)) {
    return searchParams.get(key); // Return value if the key is valid
  } else {
    return null; // Return null for invalid keys
  }
}

// Helper function to parse query parameters from loaders
export function parseSearchQueryParams(queryParams: Record<string, string>) {
  return {
    search: queryParams.search || "",
    sort: queryParams.sort || "newest",
    filters: {
      location: queryParams.location || "",
      teams: queryParams.teams || "",
      product: queryParams.product || "",
      lang: queryParams.lang || "",
    },
    page: parseInt(queryParams.page || "1", 10),
  };
}

export function getTotalPages(totalRecords = 0, recordsPerPage = 20) {
  return totalRecords > 0 ? Math.ceil(totalRecords / recordsPerPage) : 1;
}

export function teamsTypeMapper(
  selectedTeams: Record<string, { subTeam: string[]; isSelectAll: boolean }>,
  teamsRefData: TeamsRefData[],
): Team[] {
  const teams: Team[] = [];
  const selectedTeamsRefData = teamsRefData.filter((team) =>
    Object.keys(selectedTeams).includes(team.id),
  );
  selectedTeamsRefData.map((selectedTeam) => {
    if (selectedTeams[selectedTeam.id].subTeam.length) {
      const subTeams = selectedTeams[selectedTeam.id].isSelectAll
        ? selectedTeam.teams
        : selectedTeam.teams.filter((subTeam) =>
            selectedTeams[selectedTeam.id].subTeam.includes(subTeam.id),
          );
      teams.push({
        teamID: selectedTeam.id,
        teamName: selectedTeam.type,
        subTeams: subTeams.map((subTeam) => ({
          id: subTeam.id,
          name: subTeam.name,
          subTeamName: subTeam.name,
          subTeamID: subTeam.id,
        })),
      });
    }
  });
  return teams;
}

export const getLocationName = (
  city?: string,
  state?: string,
  country?: string,
): string => {
  if (city && state && country) {
    return city + ", " + state + ", " + country;
  } else if (state && country) {
    return state + ", " + country;
  } else {
    return country || "";
  }
};

export function getCheckboxClasses(isMobile?: boolean) {
  return {
    input: isMobile ? "checkbox-button-input" : "",
    label: isMobile ? "checkbox-button-label" : "",
  };
}
