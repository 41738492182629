import dateUtil from "@rpe-js/core/dist/util/dateUtil";
import { ProgressIndicatorLoader } from "@rpe-js/marcom-web-components";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  EmployeeReferral,
  ERPTalentFileMetaData,
} from "../../../shared/types/erp";
import {
  BaseRefData,
  MappedERPPostLocation,
} from "../../../shared/types/refData";
import { TalentFileMetaData } from "../../../shared/types/talent/talentFileMetatdata";
import { ApiResponse } from "../../api/apiService";
import {
  createReferral,
  uploadResume,
  uploadSupportingFilePromises,
  uploadTextResumePromise,
} from "../../api/fetchClient";
import {
  CREATE_SOURCE_ERP,
  ERP_TALENTID,
  REFERRAL_TYPE_CORPORATE,
} from "../../app.constants";
import AppContext from "../../AppContext";
import HtmlRenderer from "../../components/base/HtmlRenderer";
import Icon from "../../components/base/IconComponent";
import { Info } from "../../components/base/Info";
import { Label } from "../../components/base/Label";
import { ReviewInfoListView } from "../../components/base/ReviewInfoListView";
import { SupportingFileView } from "../../components/base/SupportingFileView";
import { TeamsView } from "../../components/base/TeamsView";
import { ReviewSection } from "../../components/feature/reviewInfo/ReviewSection";
import { SaveCancelAction } from "../../components/feature/saveAndCancel";
import { useAppAlertContext } from "../../contexts/AppAlert";
import { useCurrentUserContext } from "../../CurrentUserContext";
import useAutoFocus from "../../hooks/useAutoFocus";
import { useFetchData } from "../../hooks/useFetchData";
import useIntlMessage from "../../hooks/useIntlMessage";
import { idGenerator } from "../../utils/idGenerator";
import { useERPContext } from "./context/ERPContext";
import { StepName } from "./context/ErpState";

interface ReviewInformationStepProps {
  goBack: () => void;
  goToStep: (stepName: StepName, id?: string) => void;
}

export function ReviewInformationStep({
  goBack,
  goToStep,
}: ReviewInformationStepProps) {
  const headingRef = useAutoFocus<HTMLHeadingElement>();
  const { t } = useIntlMessage();
  const { currentUser } = useCurrentUserContext();
  const referrerDSID = currentUser.talentDSID as string;
  const { appUIState } = useContext(AppContext);
  const { locale } = appUIState.appData;
  const { state } = useERPContext();
  const idSeqContactStep = idGenerator("erp", "contactinfo");
  const idSeqGenerator = idGenerator("erp", "review-step");
  const {
    referenceData,
    contact,
    resume,
    textResume,
    language,
    supportFiles,
    links,
    jobPositionIDs,
    typeID,
    notes,
    retailLocations,
    globaleManagedRoleIds,
    corporateLocations,
    address,
    teamsPayload,
    teamsViewData,
  } = state;
  const uploadedResume = resume || textResume;
  const {
    isError,
    isLoading: isCreateReferralLoading,
    isSuccess,
    error: createReferralError,
    fetchData,
  } = useFetchData(createReferral);
  const [isSupportFilesLoading, setIsSupportFilesLoading] =
    useState<boolean>(false);
  const { updateAlert, deleteAlert } = useAppAlertContext();

  const createReferralPayload = useCallback(
    (talentFileMetadata: ERPTalentFileMetaData[]) => {
      return {
        _id: null,
        talentID: null,
        typeID: typeID,
        referrerDSID: referrerDSID,
        contact: contact,
        address: address,
        links: links ?? [],
        contactLanguageID: language.languageID,
        contactLanguageName: null,
        languages: [],
        jobPositionIDs: jobPositionIDs,
        teamsOfInterest:
          typeID === REFERRAL_TYPE_CORPORATE ? teamsPayload : null,
        globalManagedRoles:
          typeID !== REFERRAL_TYPE_CORPORATE
            ? globaleManagedRoleIds.map((managedRole) => ({
                globalManagedRoleID: managedRole.id,
              }))
            : null,
        preferredLocations:
          typeID === REFERRAL_TYPE_CORPORATE
            ? corporateLocations.map((loc) => ({
                locationID: loc.postLocationId,
              }))
            : retailLocations.map((loc) => ({
                locationID: loc.postLocationId,
              })),
        notes: notes ?? "",
        asOf: null,
        endDate: null,
        active: null,
        lastModification: null,
        talentFileMetadata: talentFileMetadata,
      } as EmployeeReferral;
    },
    [
      address,
      contact,
      corporateLocations,
      globaleManagedRoleIds,
      jobPositionIDs,
      language,
      links,
      notes,
      retailLocations,
      typeID,
      referrerDSID,
      teamsPayload,
    ],
  );

  const onSubmit = useCallback(async () => {
    const filePromises: Array<() => Promise<ApiResponse<TalentFileMetaData>>> =
      [];
    const metaData: ERPTalentFileMetaData[] = [];
    if (resume && resume.name) {
      filePromises.push(
        uploadResume(
          ERP_TALENTID,
          resume,
          false,
          referrerDSID,
          CREATE_SOURCE_ERP,
        ),
      );
    } else if (textResume && textResume.content) {
      filePromises.push(
        uploadTextResumePromise(
          ERP_TALENTID,
          textResume.content,
          false,
          CREATE_SOURCE_ERP,
        ),
      );
    }
    if (supportFiles) {
      filePromises.push(
        ...uploadSupportingFilePromises(
          ERP_TALENTID,
          supportFiles,
          false,
          referrerDSID,
          CREATE_SOURCE_ERP,
        ),
      );
    }
    let successCount = 0;
    setIsSupportFilesLoading(true);
    deleteAlert();
    for (const filePromise of filePromises) {
      const promiseResult = await filePromise();
      if (promiseResult && promiseResult.success) {
        successCount++;
        if (promiseResult.data)
          metaData.push({
            ...promiseResult.data,
            talentFileMetadataId: promiseResult.data.fileId,
          });
      } else {
        if (promiseResult.error) {
          updateAlert(false, false, t(promiseResult.error) as string);
        }
        break;
      }
    }
    setIsSupportFilesLoading(false);
    if (successCount === filePromises.length) {
      fetchData([createReferralPayload(metaData)]);
    }
  }, [
    resume,
    textResume,
    supportFiles,
    deleteAlert,
    referrerDSID,
    updateAlert,
    t,
    fetchData,
    createReferralPayload,
  ]);

  useEffect(() => {
    if (!isCreateReferralLoading && isSuccess) {
      goToStep(StepName.THANKS_PAGE);
    }
    if (!isCreateReferralLoading && isError && createReferralError) {
      updateAlert(false, false, t(createReferralError.error) as string);
    }
  }, [
    isError,
    isCreateReferralLoading,
    isSuccess,
    goToStep,
    updateAlert,
    t,
    createReferralError,
  ]);

  return (
    <>
      <section className="text-center mb-40">
        <h1 ref={headingRef} tabIndex={0} className="erp-heading">
          <span role="text">
            <span>{t("jobsite.erp.introduceFriend.reviewInformation")}</span>
            <span className="a11y">, </span>
            <span className="a11y">
              {t("jobsite.common.stepof", {
                startValue: 4,
                endValue: 4,
              })}
            </span>
          </span>
        </h1>
        <div className="erp-title">
          {t("jobsite.erp.introduceFriend.reviewInformation.subTitle")}
        </div>
      </section>
      <section className="reviewinfo-container mtb-auto-0">
        <ReviewSection
          headerText={t("jobsite.common.contactInfo") as string}
          onAction={() => goToStep(StepName.CONTACT_INFO)}
          id={idSeqGenerator.generateId("contactinfo")}
        >
          <div className="row row-gap-10 flex-column">
            <div className="column large-6 small-12 d-flex flex-column">
              <Label label={t("jobsite.common.firstName") as string} />
              <Info value={contact.firstName} />
            </div>
            <div className="column large-6 small-12 d-flex flex-column">
              <Label label={t("jobsite.common.lastName") as string} />
              <Info value={contact.lastName} />
            </div>
            <div className="column large-6 small-12 d-flex flex-column">
              <Label label={t("jobsite.common.preferredEmail") as string} />
              <Info value={contact.preferredEmail} />
            </div>
            <div className="column large-6 small-12 d-flex flex-column">
              <Label label={t("jobsite.erp.introduceFriend.Phone") as string} />
              <Info value={contact?.preferredPhone} />
            </div>
          </div>
        </ReviewSection>
        <ReviewSection
          headerText={t("jobsite.information.resume") as string}
          onAction={() =>
            goToStep(
              StepName.CONTACT_INFO,
              idSeqContactStep.generateId("friend-resume-container"),
            )
          }
          id={idSeqGenerator.generateId("resume")}
        >
          <div className="d-flex align-center">
            <span aria-hidden={true}>
              <Icon name={"resume"} size={"xlarge"}></Icon>
            </span>
            <div className="row flex-column ml-15">
              <span className="file-info label-grey">
                {uploadedResume && uploadedResume.name}
              </span>
              <p className="mt-0">
                {t("jobsite.common.resumeUploaded", {
                  date: dateUtil.formatDateTimeByLocaleUtil(
                    (uploadedResume && uploadedResume.createdTime) as string,
                    locale,
                  ),
                })}
              </p>
            </div>
          </div>
        </ReviewSection>
        <ReviewSection
          headerText={t("jobsite.common.supportFilesLinks") as string}
          onAction={() =>
            goToStep(
              StepName.CONTACT_INFO,
              idSeqContactStep.generateId("supportingfilewrapper"),
            )
          }
          id={idSeqGenerator.generateId("supportfiles")}
        >
          <SupportingFileView
            supportFiles={supportFiles}
            supportLinks={links}
            noResultsLabel={t("jobsite.common.notSpecified") as string}
          />
        </ReviewSection>
        <ReviewSection
          headerText={
            t("jobsite.erp.introduceFriend.contactlanguage") as string
          }
          onAction={() =>
            goToStep(
              StepName.CONTACT_INFO,
              idSeqContactStep.generateId("language-section"),
            )
          }
          id={idSeqGenerator.generateId("language")}
        >
          <Info value={language.languageName} />
        </ReviewSection>
        <ReviewSection
          headerText={t("jobsite.erp.introduceFriend.roleOfInterest") as string}
          onAction={() =>
            goToStep(
              StepName.CONTACT_INFO,
              idSeqContactStep.generateId("roleOfInterest-section"),
            )
          }
          id={idSeqGenerator.generateId("rolesofinterest")}
        >
          <ReviewInfoListView
            data={jobPositionIDs}
            dataOptions={{
              getKey: (value: string) => value,
              getLabel: (value: string) => value,
            }}
            noResultsLabel={t("jobsite.common.notSpecified") as string}
            classes={["mb-5"]}
          />
        </ReviewSection>
        <ReviewSection
          headerText={t("jobsite.erp.introduceFriend.referralType") as string}
          onAction={() =>
            goToStep(
              StepName.CONTACT_INFO,
              idSeqContactStep.generateId("referraltype-section"),
            )
          }
          id={idSeqGenerator.generateId("referraltype")}
        >
          {referenceData.referralType?.map(
            (type) =>
              type.id === typeID && <Info key={type.id} value={type.name} />,
          )}
        </ReviewSection>
        <ReviewSection
          headerText={t("jobsite.erp.introduceFriend.yourComments") as string}
          onAction={() =>
            goToStep(
              StepName.CONTACT_INFO,
              idSeqContactStep.generateId("note-textarea"),
            )
          }
          id={idSeqGenerator.generateId("notes")}
        >
          {notes ? (
            <p className="white-space-pre-wrap">{notes}</p>
          ) : (
            <Info value={t("jobsite.common.notSpecified") as string} />
          )}
        </ReviewSection>
        {typeID !== REFERRAL_TYPE_CORPORATE && (
          <>
            <ReviewSection
              headerText={
                t("jobsite.erp.introduceFriend.appleStoreRoles") as string
              }
              onAction={() => goToStep(StepName.INTEREST)}
              id={idSeqGenerator.generateId("roles-retail")}
            >
              <ReviewInfoListView
                data={globaleManagedRoleIds}
                dataOptions={{
                  getKey: (value: BaseRefData) => value.id,
                  getLabel: (value: BaseRefData) => value.name,
                }}
                noResultsLabel={t("jobsite.common.notSpecified") as string}
              />
            </ReviewSection>
            <ReviewSection
              headerText={t("jobsite.getDiscovered.roleLocations") as string}
              onAction={() => goToStep(StepName.LOCATION)}
              id={idSeqGenerator.generateId("location-retail")}
            >
              <ReviewInfoListView
                data={retailLocations}
                dataOptions={{
                  getKey: (value: MappedERPPostLocation) =>
                    value.postLocationId,
                  getLabel: (value: MappedERPPostLocation) =>
                    `${value.city}, ${value.name}`,
                }}
                noResultsLabel={t("jobsite.common.notSpecified") as string}
              />
            </ReviewSection>
          </>
        )}
        {typeID === REFERRAL_TYPE_CORPORATE && (
          <>
            <ReviewSection
              headerText={t("jobsite.common.teamsInterestTitle") as string}
              onAction={() => goToStep(StepName.INTEREST)}
              id={idSeqGenerator.generateId("roles-corporate")}
            >
              <TeamsView
                teams={teamsViewData}
                noResultsLabel={t("jobsite.common.notSpecified") as string}
              />
            </ReviewSection>
            <ReviewSection
              headerText={t("jobsite.getDiscovered.roleLocations") as string}
              onAction={() => goToStep(StepName.LOCATION)}
              id={idSeqGenerator.generateId("location-corporate")}
            >
              <ReviewInfoListView
                data={corporateLocations}
                dataOptions={{
                  getKey: (value: MappedERPPostLocation) =>
                    value.postLocationId,
                  getLabel: (value: MappedERPPostLocation) => value.countryName,
                }}
                noResultsLabel={t("jobsite.common.notSpecified") as string}
              />
            </ReviewSection>
          </>
        )}
        <HtmlRenderer
          initialContent={t("jobsite.common.reviewDisclaimer") as string}
          classes="hyperlink-underline"
        ></HtmlRenderer>
      </section>
      <section className="d-flex justify-center reviewinfo-container mtb-auto-0">
        <SaveCancelAction
          cancelLabelName={t("jobsite.common.back") as string}
          saveLabelName={t("jobsite.common.submit") as string}
          onCancel={goBack}
          onSave={onSubmit}
          classes="w-50 mt-40"
          cancelButtonId={idGenerator("erp", "review-info-step").generateId(
            "back-button",
          )}
          saveButtonId={idGenerator("erp", "review-info-step").generateId(
            "submit-button",
          )}
        />
      </section>
      {(isCreateReferralLoading || isSupportFilesLoading) && (
        <ProgressIndicatorLoader
          showLoading={true}
          curtain={true}
        ></ProgressIndicatorLoader>
      )}
    </>
  );
}
