import { isEmpty } from "lodash";
import React, { createContext, useMemo, useState } from "react";
import { SetURLSearchParams, useSearchParams } from "react-router";

interface ILocationPickerContext {
  jobDetails: any;
  selectedStores: Record<string, any>[];
  setSelectedStores: (selectedStores: Record<string, any>[]) => void;
  postLocation: string | null;
  setPostLocation: (postLocation: string) => void;
  search: string;
  setSearch: (search: string) => void;
  distance: string;
  setDistance: (distance: string) => void;
  zipResults: Record<string, any>[];
  zipResultsError: boolean;
  zipResultsLoading: boolean;
  setZipResults: (zipResults: Record<string, any>[]) => void;
  setZipResultsError: (error: boolean) => void;
  setZipResultsLoading: (loading: boolean) => void;
  locationResults: Record<string, any>[];
  locationResultsError: boolean;
  locationResultsLoading: boolean;
  setLocationResults: (locationResults: Record<string, any>[]) => void;
  setLocationResultsError: (error: boolean) => void;
  setLocationResultsLoading: (loading: boolean) => void;
  isMobile: boolean;
  setIsMobile: (isMobile: boolean) => void;
  zip: string;
  setZip: (zip: string) => void;
  mode: string;
  setMode: (mode: string) => void;
  location: any;
  serverLocation: any;
  countryCode: any;
  clientParams: any;
  setClientParams: SetURLSearchParams;
}

export const LocationPickerContext = createContext<
  ILocationPickerContext | undefined
>(undefined);

export function LocationPickerProvider({
  children,
  jobDetails,
  location,
  serverLocation,
  countryCode,
}: {
  children: React.ReactNode;
  jobDetails: Record<string, any>;
  location: any;
  serverLocation: any;
  countryCode: any;
}) {
  // const clientParams = new URLSearchParams(location.search);
  const [clientParams, setClientParams] = useSearchParams();
  // TODO: Remove after review

  // const paramsObject: { [key: string]: string } = {};
  // Array.from(clientParams.entries()).forEach(([key, value]) => {
  //   paramsObject[key] = value;
  // });

  // const { l, m } = paramsObject;
  // const isNewLocationPicker =
  //   (clientParams.get("state") || "") ===
  //   APP_CONSTANTS.LOCATION_PICKER_INIITIAL_STATE;

  // const getQueryParamValue = (param: string | null) => {
  //   return !isNewLocationPicker ? param || "" : "";
  // };

  const defaultPostLocation = clientParams.get("l") || "";

  // TODO: Remove after review
  // const defaultSearch = clientParams.get("s") || getQueryParamValue(s);
  // const defaultZip = clientParams.get("z") || getQueryParamValue(z);
  // const defaultDistance =
  //   clientParams.get("d") || getQueryParamValue(d) || "10";
  const defaultSearch = clientParams.get("s") || "";
  const defaultZip = clientParams.get("z") || "";
  const defaultDistance = clientParams.get("d") || "10";
  const defaultMode = clientParams.get("m") || "location";

  const [selectedStores, setSelectedStores] = useState<Record<string, any>[]>(
    [],
  );
  const [search, setSearch] = useState<string>(defaultSearch);
  const [postLocation, setPostLocation] = useState<string | null>(
    defaultPostLocation,
  );
  const [zip, setZip] = useState<string>(defaultZip);
  const [mode, setMode] = useState<string>(defaultMode);
  const [distance, setDistance] = useState<string>(defaultDistance);
  const [zipResults, setZipResults] = useState<Record<string, any>[]>([]);
  const [zipResultsError, setZipResultsError] = useState<boolean>(false);
  const [zipResultsLoading, setZipResultsLoading] = useState<boolean>(false);
  const [locationResults, setLocationResults] = useState<Record<string, any>[]>(
    [],
  );
  const [locationResultsError, setLocationResultsError] =
    useState<boolean>(false);
  const [locationResultsLoading, setLocationResultsLoading] =
    useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const isEmptyJobDetails = isEmpty(jobDetails);
  const defaultJobDetails = useMemo(() => {
    return {
      positionId: "",
      id: "",
    };
  }, []);

  const value = useMemo(
    () => ({
      jobDetails: isEmptyJobDetails ? defaultJobDetails : jobDetails,
      selectedStores,
      setSelectedStores,
      search,
      setSearch,
      distance,
      setDistance,
      zipResults,
      zipResultsLoading,
      zipResultsError,
      setZipResults,
      setZipResultsLoading,
      setZipResultsError,
      locationResults,
      locationResultsLoading,
      locationResultsError,
      setLocationResults,
      setLocationResultsLoading,
      setLocationResultsError,
      isMobile,
      setIsMobile,
      zip,
      setZip,
      mode,
      setMode,
      postLocation,
      setPostLocation,
      clientParams,
      setClientParams,
      location,
      serverLocation,
      countryCode,
    }),
    [
      jobDetails,
      selectedStores,
      search,
      distance,
      zipResults,
      zipResultsLoading,
      zipResultsError,
      locationResults,
      locationResultsLoading,
      locationResultsError,
      isMobile,
      zip,
      mode,
      postLocation,
      location,
      serverLocation,
      countryCode,
      clientParams,
      setClientParams,
      isEmptyJobDetails,
      defaultJobDetails,
    ],
  );

  return (
    <LocationPickerContext.Provider value={value}>
      {children}
    </LocationPickerContext.Provider>
  );
}
