/**
 ** This is a placeholder file with mock implementation
 **/
import React from "react";
import useIntlMessage from "../../hooks/useIntlMessage";
import { idGenerator } from "../../utils/idGenerator";
import CheckboxFilter from "./CheckboxFilter";
import { MappedProducsFilterOptions } from "./ProductsFilterWrapper";

interface ProductFilterProps {
  productsData: Array<MappedProducsFilterOptions>;
  onUpdateProducts: (products: Array<any>) => void;
  onRemoveProducts: () => void;
  isAccordionOpen: boolean;
  selectedCount: number;
}

const ProductsFilter: React.FC<ProductFilterProps> = ({
  productsData,
  onUpdateProducts,
  onRemoveProducts,
  selectedCount,
}) => {
  const { t } = useIntlMessage();

  return (
    <CheckboxFilter
      title={t("jobsite.search.productAndServices") as string}
      data={productsData}
      onUpdateSelectedItems={onUpdateProducts}
      onRemoveAllItems={onRemoveProducts}
      selectedCount={selectedCount}
      isAccordionOpen={false}
      legendLabel={t("jobsite.search.productAndServices") as string}
      idPrefix={"productsAndServices"}
      filterParamKey={"product"}
      index={3}
      accordionId={idGenerator(
        "search",
        "productsAndServices-filter-accordion",
      ).generateId()}
      classNames={{ listWrapper: "checkbox-container" }}
    />
  );
};

export default ProductsFilter;
