import { Talent } from "../../../../../shared/types";
import {
  AppEducationDetails,
  AppEmploymentDetails,
  EmployeeBackGround,
  Legal,
  MyApplication,
  TalentReference,
} from "../../../../../shared/types/talent/myApplication";
import {
  Address,
  Contact,
  CountrySpecificSelfDisclosureConfig,
  SelfDisclosure,
} from "../../../../../shared/types/talent/talent";
import { handleScrollToElement } from "../../../../utils/focusUtil";
import { MyApplicationAction } from "./actionTypes";
import {
  ApplicationAllowedSteps,
  ApplicationSection,
  AppSectionRefData,
  PageState,
  ReviewSection,
  SectionMode,
} from "./types";

export interface MyApplicationState {
  page: PageState;
  currentStep?: ApplicationAllowedSteps;
  isPageDataLoading: boolean;
  pageError: {
    applicationDisabled: boolean;
  };
  showPageErrorBanner: boolean;
  talent?: Talent;
  application?: MyApplication;
  submitted: boolean;
  selfDisclosureConfig?: CountrySpecificSelfDisclosureConfig;
  selfDisclosureStep: {
    canSave: boolean;
    selfDisclosure?: SelfDisclosure;
    title: string;
  };
  applicationStep: {
    contactInformation: SectionMode;
    employmentBackground: SectionMode;
    education: SectionMode;
    professionalExperience: SectionMode;
    references: SectionMode;
    legal: SectionMode;
    canSave: boolean; // can the step be saved
    refData?: AppSectionRefData;
  };
  reviewStep: {
    contactInformation: SectionMode;
    employmentBackground: SectionMode;
    education: SectionMode;
    professionalExperience: SectionMode;
    references: SectionMode;
    legal: SectionMode;
    selfDisclosure: SectionMode;
    infoRelease: SectionMode;
    informationRelease?: SectionMode; // TODO: Can this be used instead of infoRelease?
    canSubmit: boolean; // can the step be saved
  };
  infoReleaseStep: {
    title: string;
    canSave: boolean;
    ack?: boolean;
  };
  reviewSubmitStep: {
    canSave: boolean;
  };
  contactInfoSection: {
    title: string;
    contact?: Contact;
    addresses?: Address[];
  };
  employmentSection: {
    title: string;
    employeeBackground?: EmployeeBackGround;
  };
  educationSection: {
    title: string;
    educationDegree?: AppEducationDetails[];
  };
  professionSection: {
    title: string;
    employmentHasAtLeastOneJob?: boolean;
    employments?: AppEmploymentDetails[];
  };
  referencesSection: {
    title: string;
  };
  legalSection: {
    title: string;
    legal?: Legal;
  };
}

export const MyApplicationActionDispatcher = {
  onApplicationStepSave: function (
    dispatch: React.Dispatch<MyApplicationAction>,
    application: MyApplication,
  ) {
    dispatch({
      type: "ON_APPLICATION_STEP_SAVE",
      payload: application,
    });
  },
  onInfoReleaseStepSave: function (
    dispatch: React.Dispatch<MyApplicationAction>,
    application: MyApplication,
  ) {
    dispatch({
      type: "ON_INFO_RELEASE_STEP_SAVE",
      payload: application,
    });
  },
  onApplicationSubmit: function (
    dispatch: React.Dispatch<MyApplicationAction>,
    application: MyApplication,
  ) {
    dispatch({
      type: "ON_APPLICATION_SUBMIT",
      payload: application,
    });
  },

  onApplicationSectionSave: function (
    dispatch: React.Dispatch<MyApplicationAction>,
    section: ApplicationSection | ReviewSection,
    mode: SectionMode,
    application: MyApplication,
  ) {
    dispatch({
      type: "ON_APPLICATION_SECTION_SAVE",
      payload: {
        sectionName: section,
        mode: mode,
        application,
      },
    });
  },
  onApplicationSectionCancel: function (
    dispatch: React.Dispatch<MyApplicationAction>,
    section: ReviewSection,
  ) {
    dispatch({
      type: "ON_APPLICATION_SECTION_CANCEL",
      payload: {
        sectionName: section,
      },
    });
  },
  onApplicationSectionEdit: function (
    dispatch: React.Dispatch<MyApplicationAction>,
    section: ReviewSection,
  ) {
    handleScrollToElement(`profileApplication-application-${section}`);
    dispatch({
      type: "ON_APPLICATION_SECTION_EDIT",
      payload: {
        sectionName: section,
      },
    });
  },
  onAppSecContactInfoSave: function (
    dispatch: React.Dispatch<MyApplicationAction>,
    payload: Pick<MyApplication, "contact" | "addresses">,
  ) {
    dispatch({ type: "ON_APP_SEC_CONTACT_INFO_SAVE", payload: payload });
  },
  onAppSecEmpBckSave: function (
    dispatch: React.Dispatch<MyApplicationAction>,
    payload: EmployeeBackGround,
  ) {
    dispatch({ type: "ON_APP_SEC_EMP_BCK_SAVE", payload: payload });
  },
  onAppSecEduSave: function (
    dispatch: React.Dispatch<MyApplicationAction>,
    payload: AppEducationDetails[],
  ) {
    dispatch({ type: "ON_APP_SEC_EDU_SAVE", payload: payload });
  },
  onAppSecProfExpSave: function (
    dispatch: React.Dispatch<MyApplicationAction>,
    payload: Pick<MyApplication, "employmentHasAtLeastOneJob" | "employments">,
  ) {
    dispatch({ type: "ON_APP_SEC_PROF_EXP_SAVE", payload: payload });
  },
  onAppSecReferenceSave: function (
    dispatch: React.Dispatch<MyApplicationAction>,
    payload: TalentReference[],
  ) {
    dispatch({ type: "ON_APP_SEC_REF_SAVE", payload: payload });
  },
  onAppSecSelfDisclosureSave: function (
    dispatch: React.Dispatch<MyApplicationAction>,
    payload: {
      selfDisclosureEncrypted: SelfDisclosure;
      selfDisclosure: SelfDisclosure;
    },
  ) {
    dispatch({ type: "ON_APP_SEC_SD_SAVE", payload: payload });
  },
  onAppSecLegalSave: function (
    dispatch: React.Dispatch<MyApplicationAction>,
    payload: Legal,
  ) {
    dispatch({ type: "ON_APP_SEC_LEGAL_SAVE", payload: payload });
  },
  setAppSecRefData: function (
    dispatch: React.Dispatch<MyApplicationAction>,
    payload: AppSectionRefData,
  ) {
    dispatch({ type: "SET_APPLICATION_STEP_REFDATA", payload: payload });
  },
};
