import { Textbox, TextButton } from "@rpe-js/marcom-web-components";
import { isEmpty } from "lodash";
import React, { useContext } from "react";
import AppContext from "../../../AppContext";
import { Info } from "../../../components/base/Info";
import { Label } from "../../../components/base/Label";
import TextIcon from "../../../components/base/TextIcon";
import { PreferredEmail } from "../../../components/feature/emailVerification/preferredEmail";
import { useFormInputValidator } from "../../../components/feature/form/inputValidators/useFormInputValidator";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { useProfileContext } from "../context/ProfileContext";
import { ProfileFormField } from "../types";
import { getFieldLabel } from "./components/utils";
import { EmailPhoneForm } from "./types";

interface EditEmailAndPhoneNumber {
  form: EmailPhoneForm;
  showErrors: boolean;
  onPreferredEmailUpdate: (field: ProfileFormField, emailId: string) => void;
  onPreferredPhoneUpdate: (field: ProfileFormField, phone: string) => void;
  onOtherEmailUpdate: (field: ProfileFormField, val: string) => void;
  onOtherPhoneUpdate: (field: ProfileFormField, val: string) => void;
  onOtherPhoneAdd: () => void;
  onOtherEmailAdd: () => void;
  onOtherPhoneDelete: (field: ProfileFormField) => void;
  onOtherEmailDelete: (field: ProfileFormField) => void;
}

export function PhoneTextField(props: {
  index: number;
  field: ProfileFormField;
  showErrors: boolean;
  onUpdate: (field: ProfileFormField, val: string) => void;
  onDelete?: (field: ProfileFormField) => void;
}) {
  const { index, field, showErrors, onUpdate, onDelete } = props;
  const { t } = useIntlMessage();
  const { isValidPhoneNumberEntered, isValidPhoneNumberPasted } =
    useFormInputValidator();
  return (
    <div className="d-flex" key={`${field.key}`}>
      <div className="d-flex-equal">
        <Textbox
          id={`${field.id}-${index}`}
          required={field.required || false}
          label={getFieldLabel(
            field.label as string,
            field.optional ?? false,
            t,
          )}
          value={field.value}
          onValueChange={(val) => {
            onUpdate(field, val);
          }}
          maxLength={field.maxLength}
          onKeyDown={isValidPhoneNumberEntered}
          onPaste={isValidPhoneNumberPasted}
          error={
            showErrors && field.error
              ? (t(field.errori18nMsgKey as string, {
                  ...(field.errori18nMsgParams || {}),
                }) as string)
              : false
          }
          errorA11y={t("jobsite.common.errorIconLabel") as string}
        />
      </div>
      {onDelete && (
        <div className="ml-10" style={{ alignContent: "center" }}>
          <TextIcon
            textColor="blue"
            name="icon-minuscircle"
            onClick={() => onDelete(field)}
            ariaLabelForButton={
              t("jobsite.common.removeOtherPhoneNumberVO", {
                phoneNumberCount: index > 0 ? index : "",
              }) as string
            }
            idForButton={`${field.id}-remove-${index}`}
          />
        </div>
      )}
    </div>
  );
}

export function EmailTextField(props: {
  index: number;
  field: ProfileFormField;
  onUpdate: (field: ProfileFormField, val: string) => void;
  onDelete?: (field: ProfileFormField) => void;
  required?: boolean;
  showErrors?: boolean;
}) {
  const { index, field, onUpdate, onDelete, required, showErrors } = props;
  const { t } = useIntlMessage();
  const { isValidEmailAddressEntered, isValidEmailAddressPasted } =
    useFormInputValidator();
  return (
    <div className="d-flex" key={`${field.key}`}>
      <div className="d-flex-equal">
        <Textbox
          id={`${field.id}-${index}`}
          required={field.required || false}
          label={getFieldLabel(field.label as string, !required, t)}
          value={field.value}
          onValueChange={(val) => {
            onUpdate(field, val);
          }}
          maxLength={field.maxLength}
          onKeyDown={isValidEmailAddressEntered}
          onPaste={isValidEmailAddressPasted}
          error={
            showErrors && field.error
              ? (t(field.errori18nMsgKey as string, {
                  ...(field.errori18nMsgParams || {}),
                }) as string)
              : false
          }
          errorA11y={t("jobsite.common.errorIconLabel") as string}
        />
      </div>
      {onDelete && (
        <div className="ml-10" style={{ alignContent: "center" }}>
          <TextIcon
            textColor="blue"
            name="icon-minuscircle"
            onClick={() => onDelete(field)}
            ariaLabelForButton={
              t("jobsite.common.removeOtherEmailAddressVO", {
                emailCount: index > 0 ? index : "",
              }) as string
            }
            idForButton={`${field.id}-remove-${index}`}
          />
        </div>
      )}
    </div>
  );
}

export const EditEmailAndPhoneNumber = (props: EditEmailAndPhoneNumber) => {
  const {
    form,
    onPreferredEmailUpdate,
    onPreferredPhoneUpdate,
    onOtherEmailUpdate,
    onOtherPhoneUpdate,
    onOtherPhoneAdd,
    onOtherPhoneDelete,
    onOtherEmailAdd,
    onOtherEmailDelete,
    showErrors,
  } = props;
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const { state } = useProfileContext();
  const { appUIState } = useContext(AppContext);
  const { isInternal } = appUIState.appData;

  return (
    <>
      <div className="row mb-10">
        <div className="column large-6 small-12 d-flex mb-10">
          <div className="d-flex flex-column w-100">
            <div className="row">
              <PreferredEmail
                isEdit={true}
                preferredEmail={state?.contact?.preferredEmail}
                verifiedStatus={state?.emails?.preferredEmail?.verifiedStatus}
                onSuccess={(emailId: string) => {
                  onPreferredEmailUpdate(
                    form.preferredEmail as ProfileFormField,
                    emailId,
                  );
                }}
              />
            </div>
            <div className="pr-15 mt-10">
              {!isEmpty(form.otherEmail) &&
                form.otherEmail?.map((emailField, idx) => (
                  <EmailTextField
                    key={emailField.id}
                    field={emailField}
                    index={idx}
                    showErrors={showErrors}
                    onDelete={onOtherEmailDelete}
                    onUpdate={onOtherEmailUpdate}
                  />
                ))}
            </div>
            {(form.otherEmail || []).length < 2 && (
              <div>
                <TextButton
                  label={t("jobsite.common.addEmailAddress") as string}
                  onClick={onOtherEmailAdd}
                  icon="icon-pluscircle"
                />
              </div>
            )}
          </div>
        </div>
        <div className={`column large-6 small-12 ${!isMobile && "pr-15"}`}>
          {!isInternal && (
            <PhoneTextField
              field={form?.preferredPhone as ProfileFormField}
              index={0}
              showErrors={showErrors}
              onUpdate={onPreferredPhoneUpdate}
            />
          )}
          {isInternal && (
            <div className="d-flex flex-column mb-10">
              <Label
                label={t("jobsite.common.preferredPhone") as string}
                variant="prominent"
              />
              <Info
                value={
                  state?.contact?.preferredPhone
                    ? state?.contact?.preferredPhone
                    : (t("jobsite.common.notSpecified") as string)
                }
              />
            </div>
          )}
          {(form.otherPhone || []).map((phoneField, idx) => (
            <PhoneTextField
              key={phoneField.id}
              field={phoneField}
              index={idx}
              showErrors={showErrors}
              onDelete={onOtherPhoneDelete}
              onUpdate={onOtherPhoneUpdate}
            />
          ))}
          {(form.otherPhone || []).length < 2 && (
            <TextButton
              label={t("jobsite.common.addPhoneNumber") as string}
              onClick={onOtherPhoneAdd}
              icon="icon-pluscircle"
            />
          )}
        </div>
      </div>
    </>
  );
};
