import { random } from "lodash";
import { Language } from "../../../../shared/types/talent/talent";

export interface LanguageForm {
  languageID: string | undefined;
  languageName?: string;
  preferredLanguage: boolean | undefined;
  proficiencyID: string | undefined;
  proficiencyName?: string;
  languageError?: string;
  key: number;
}

export const getLanguageForm = (language?: Language) => {
  return {
    languageID: language ? language.languageID : "",
    languageName: language ? language.languageName : "",
    preferredLanguage: language ? language.preferredLanguage : false,
    proficiencyID: language ? language.proficiencyID : "",
    proficiencyName: language ? language.proficiencyName : "",
    languageError: "",
    key: random(1000),
  };
};
