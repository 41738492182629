import React, { useContext } from "react";
import AppContext from "../../AppContext";
import FavoriteComponent from "../../components/feature/FavoriteComponent";
// import ShareJobComponent from "./ShareJobComponent";
import ShareJobComponent from "../../components/feature/shareJob/ShareJobComponent";
import { idGenerator } from "../../utils/idGenerator";

export interface JobDetailHeaderProps {
  id?: string;
  handleFavoriteChange: (favorited: boolean) => void;
  handleShareJob: (data: any) => void;
  jobData: any;
}

export default function JobDetailHeaderIcons({
  // eslint-disable-next-line
  handleFavoriteChange,
  // eslint-disable-next-line
  handleShareJob,
  jobData,
  id,
}: JobDetailHeaderProps) {
  const { appUIState } = useContext(AppContext);
  const { isInternal } = appUIState.appData;
  const idGeneratorContainer = idGenerator("jobdetails", id || "");

  return (
    <div className="d-flex justify-content-center align-center gap-15">
      <div
        id={id}
        className={"d-inline-block"}
        style={{ position: "relative" }}
      >
        <FavoriteComponent
          positionId={jobData.reqId}
          isFavorited={jobData.isFavorited}
          handleFavoriteChange={handleFavoriteChange}
          id={idGeneratorContainer.generateId("favorites")}
          postingTitle={jobData.postingTitle}
        ></FavoriteComponent>
      </div>
      {!isInternal ? (
        <div className={"d-inline-block"}>
          <ShareJobComponent
            jobData={jobData}
            classes="d-flex"
            type={"icon"}
            id={`${id}`}
          ></ShareJobComponent>
        </div>
      ) : null}
    </div>
  );
}
