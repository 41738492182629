import React, { useContext } from "react";
import { APP_TYPE } from "../../../app.constants";
import AppContext from "../../../AppContext";
import { Info } from "../../../components/base/Info";
import { Label } from "../../../components/base/Label";
import RadioGroup from "../../../components/feature/form/radioGroup";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { getYesAndNoRadioOptions } from "../../../util";
import { focusElementWithFallback } from "../../../utils/focusUtil";
import { idGenerator } from "../../../utils/idGenerator";
import { useProfileContext } from "../context/ProfileContext";
import { EditEmployeeSection } from "./editEmployeeSection";
import {
  EmploymentForm,
  getEmploymentForm,
  validateDateRange,
} from "./employmentFormUtils";

type EditEmployeeSummaryProps = {
  employmentsForm: EmploymentForm[];
  monthOptions: { value: string; label: string }[];
  yearOptions: { value: string; label: string }[];
  setEmploymentsForm: React.Dispatch<React.SetStateAction<EmploymentForm[]>>;
  employmentHistory: string | null | undefined;
  setEmploymentHistory: (e: string) => void;
  displayErrors: boolean;
};

export const EditEmployeeSummary = ({
  employmentsForm,
  setEmploymentsForm,
  monthOptions,
  yearOptions,
  employmentHistory,
  setEmploymentHistory,
  displayErrors,
}: EditEmployeeSummaryProps) => {
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { appType } = appUIState.appData;
  const { state } = useProfileContext();
  const mode = "profile";
  const employmentHistoryOptions = getYesAndNoRadioOptions(
    "employmentHistoryYes",
    "employmentHistoryNo",
    t,
  );
  const profileEmployerIdGenerator = idGenerator(mode, "employer");
  const addEmployer = (index: number) => {
    setEmploymentsForm((prevValue: EmploymentForm[]) => [
      ...prevValue,
      getEmploymentForm(),
    ]);
    const newIndex = index + 1;
    focusElementWithFallback(
      profileEmployerIdGenerator.generateId(`${newIndex}-suggestion-textbox`),
    );
  };

  const removeEmployer = (key: number, index: number) => {
    const updatedEmploymentsForm = employmentsForm.filter(
      (employment: EmploymentForm) => employment.key !== key,
    );
    setEmploymentsForm([...updatedEmploymentsForm]);
    const newIndex = Math.min(index, updatedEmploymentsForm.length - 1);
    const addEmploymentButtonId = idGenerator(
      mode,
      "add-employment",
    ).generateId(`${newIndex}`);
    const removeEmploymentButtonId = idGenerator(
      mode,
      "remove-employment",
    ).generateId(`${newIndex}`);
    focusElementWithFallback(addEmploymentButtonId, [removeEmploymentButtonId]);
  };

  const handleValueChange = (index: number, option: any, name: string) => {
    const employmentsFormCopy = employmentsForm;
    if (name === "employer") {
      if (option.id) {
        employmentsFormCopy[index].employerID.value = option.id;
        employmentsFormCopy[index].employerName.value = option.name;
        employmentsFormCopy[index].employerName.requiredError = false;
      } else if (employmentsFormCopy[index].employerName.value !== option) {
        employmentsFormCopy[index].employerID.value = "";
        employmentsFormCopy[index].employerName.value = option;
        if (option) {
          employmentsFormCopy[index].employerName.requiredError = false;
        }
      }
    } else if (name === "jobTitle") {
      employmentsFormCopy[index].jobTitle.value = option;
      if (option) {
        employmentsFormCopy[index].jobTitle.requiredError = false;
      }
    } else if (name === "currentEmployer") {
      employmentsFormCopy[index].currentEmployer.value = option;
      if (option) {
        employmentsFormCopy[index].currentEmployer.requiredError = false;
      }
      if (option === "Yes") {
        employmentsFormCopy[index].endMonthName.value = "";
        employmentsFormCopy[index].endMonthID.value = "";
        employmentsFormCopy[index].endYear.value = "";
        employmentsFormCopy[index].endMonthID.invalidErrorMessage = "";
      }
      employmentsFormCopy[index].startMonthID.invalidErrorMessage =
        validateDateRange(employmentsFormCopy, index, "startDate");
    } else if (name === "startMonth") {
      employmentsFormCopy[index].startMonthID.value = option.value;
      employmentsFormCopy[index].startMonthName.value = option.label;
      if (option.value) {
        employmentsFormCopy[index].startMonthID.requiredError = false;
        employmentsFormCopy[index].startMonthID.invalidErrorMessage =
          validateDateRange(employmentsFormCopy, index, "startDate");
        if (employmentsFormCopy[index].endMonthID.invalidErrorMessage) {
          employmentsFormCopy[index].endMonthID.invalidErrorMessage =
            validateDateRange(employmentsFormCopy, index, "endDate");
        }
      }
    } else if (name === "startYear") {
      employmentsFormCopy[index].startYear.value = option.value;
      if (option.value) {
        employmentsFormCopy[index].startYear.requiredError = false;
        employmentsFormCopy[index].startMonthID.invalidErrorMessage =
          validateDateRange(employmentsFormCopy, index, "startDate");
        if (employmentsFormCopy[index].endMonthID.invalidErrorMessage) {
          employmentsFormCopy[index].endMonthID.invalidErrorMessage =
            validateDateRange(employmentsFormCopy, index, "endDate");
        }
      }
    } else if (name === "endMonth") {
      employmentsFormCopy[index].endMonthID.value = option.value;
      employmentsFormCopy[index].endMonthName.value = option.label;
      if (option.value) {
        employmentsFormCopy[index].endMonthID.requiredError = false;
        employmentsFormCopy[index].endMonthID.invalidErrorMessage =
          validateDateRange(employmentsFormCopy, index, "endDate");
        if (employmentsFormCopy[index].startMonthID.invalidErrorMessage) {
          employmentsFormCopy[index].startMonthID.invalidErrorMessage =
            validateDateRange(employmentsFormCopy, index, "startDate");
        }
      }
    } else if (name === "endYear") {
      employmentsFormCopy[index].endYear.value = option.value;
      if (option.value) {
        employmentsFormCopy[index].endYear.requiredError = false;
        employmentsFormCopy[index].endMonthID.invalidErrorMessage =
          validateDateRange(employmentsFormCopy, index, "endDate");
        if (employmentsFormCopy[index].startMonthID.invalidErrorMessage) {
          employmentsFormCopy[index].startMonthID.invalidErrorMessage =
            validateDateRange(employmentsFormCopy, index, "startDate");
        }
      }
    }
    setEmploymentsForm([...employmentsFormCopy]);
  };

  return (
    <>
      <Label
        label={t("jobsite.common.empSummaryHistory") as string}
        variant="prominent"
      />
      {appType === APP_TYPE.EXTERNAL && (
        <RadioGroup
          required={true}
          title=""
          name="employmentHistory"
          options={employmentHistoryOptions}
          selectedValue={employmentHistory as string}
          onChange={(e) => setEmploymentHistory(e.target.value)}
        ></RadioGroup>
      )}
      {appType === APP_TYPE.INTERNAL &&
        ((state?.employments?.length as number) > 0 ? (
          <p>{t("jobsite.common.yes")}</p>
        ) : (
          <Info value={t("jobsite.common.no") as string} />
        ))}
      {employmentHistory === "Yes" && (
        <EditEmployeeSection
          employmentsForm={employmentsForm}
          displayErrors={displayErrors}
          mode={mode}
          monthOptions={monthOptions}
          yearOptions={yearOptions}
          addEmployer={addEmployer}
          removeEmployer={removeEmployer}
          handleValueChange={handleValueChange}
        />
      )}
    </>
  );
};
