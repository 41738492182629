import React, { useCallback } from "react";
import APP_CONSTANTS from "../../../utilities/appConstants";
import { getSearchTypeahead } from "../../api/fetchClient";
import SearchTypeahead from "../../components/base/Typeahead/SearchTypeahead";
import { SET_PAGE, SET_SEARCH, SET_SORT } from "../../contexts/actionTypes";
import useIntlMessage from "../../hooks/useIntlMessage";
import { useSearchContext } from "../../hooks/useSearchContext";
import { idGenerator } from "../../utils/idGenerator";

const SearchInput: React.FC = () => {
  const { state, dispatch } = useSearchContext();
  const { t } = useIntlMessage();

  function handleSelected(selected: string) {
    if (selected.length > 0) {
      dispatch({ type: SET_SEARCH, payload: selected });
      dispatch({
        type: SET_SORT,
        payload: APP_CONSTANTS.SORT_MODIFIERS.RELEVANCE,
      });
      dispatch({ type: SET_PAGE, payload: 1 });
    }
  }

  function handleClearInput(input: string) {
    dispatch({ type: SET_SEARCH, payload: input });
  }

  const apiEndpoint = useCallback((searchTerm: string) => {
    return getSearchTypeahead(encodeURIComponent(searchTerm));
  }, []);

  return (
    <SearchTypeahead
      id={idGenerator("search", "typeahead").generateId()}
      apiEndpoint={apiEndpoint}
      onSelect={handleSelected}
      getSuggestionLabel={(suggestion) => suggestion}
      minChars={2}
      defaulValue={state.search}
      showSearchIcon={true}
      highlightMatches={true}
      onInputChange={() => {}}
      onClearInput={handleClearInput}
      suggestionPlaceholder={t("jobsite.search.roles") as string}
      placeholder={t("jobsite.search.searchContent") as string}
      classNames={{
        container: "search-typeahead-container",
        inputWrapper: "search-typeahead-input-wrapper",
        input: "search-typeahead-input",
        list: "search-typeahead-list",
        listItem: "search-typeahead-list-item",
        button: "search-typeahead-button",
        highlightedItem: "search-typeahead-highlighted-item",
        searchIcon: "search-typeahead-search-icon",
        clearButton: `search-typeahead-clear-button`,
        suggestionIcon: "p-5",
        suggestionPlaceholder:
          "search-typeahead-suggestion-placeholder t-intro fw-regular pb-10",
        highlight: "search-typeahead-highlighted-text",
      }}
    />
  );
};

export default SearchInput;
