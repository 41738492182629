import React, { useCallback, useEffect, useState } from "react";
import { LinkedInProfilePreview } from "../../../../shared/types/config";
import { ExternalProfile } from "../../../../shared/types/talent/talent";
import { useCurrentUserContext } from "../../../CurrentUserContext";
import { LinkedInPreviewWidget } from "./LinkedInPreviewWidget";
import { LinkedInUploadWidget } from "./LinkedInUploadWidget";

interface LinkedInWidgetProps {
  addedOn: number | string;
  url: string;
  locale: string;
  removeLinkedIn: () => void;
  addLinkedIn: (profileData: LinkedInProfilePreview) => void;
}

export function LinkedInWidget({
  url,
  addedOn,
  locale,
  removeLinkedIn,
  addLinkedIn,
}: LinkedInWidgetProps) {
  const [linkedInData, setLinkedInData] = useState<ExternalProfile>();
  const { config } = useCurrentUserContext();

  const removeFile = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setLinkedInData(undefined);
      removeLinkedIn();
    },
    [removeLinkedIn],
  );

  useEffect(() => {
    if (url) {
      setLinkedInData({
        url,
        addedOn,
      });
    }
  }, [addedOn, url]);

  const uploadLinkedIn = useCallback(
    (data: LinkedInProfilePreview) => {
      setLinkedInData({
        url: data.publicProfileUrl,
        addedOn: new Date().getTime(),
      });
      addLinkedIn(data);
    },
    [addLinkedIn],
  );

  return (
    <>
      <section className="linkedin-attachment-container">
        {linkedInData ? (
          <LinkedInPreviewWidget
            locale={locale}
            linkedInUrl={linkedInData?.url || ""}
            addedOn={linkedInData?.addedOn as string}
            removeFile={removeFile}
          ></LinkedInPreviewWidget>
        ) : (
          config && (
            <LinkedInUploadWidget
              config={config}
              uploadLinkedIn={uploadLinkedIn}
            ></LinkedInUploadWidget>
          )
        )}
      </section>
    </>
  );
}
