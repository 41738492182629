import { Questionnaire } from "../../../../shared/types";
import {
  DataUsageConsent,
  Education,
  Emails,
  EmploymentDetails,
  Language,
  MobilityPreference,
  PreferredLocations,
  SelfDisclosure,
  Skill,
  Team,
} from "../../../../shared/types/talent/talent";
import { TalentConnection } from "../../../../shared/types/talentConnection";
import { FileData } from "../../../types/File";
import { LinkedInData } from "../../../types/LinkedIn";
import {
  TalentSupportingFile,
  TalentSupportingLink,
} from "../../../types/SupportingFileWidget";

export enum StepName {
  RESUME = "resume",
  SELFDISCLOSURE = "selfDisclosure",
  QUESTIONNAIRE = "questionnaire",
  REVIEW_INFO = "reviewinfo",
}

export enum profileUpdateMode {
  MANUAL = "manual",
  RESUME = "resume",
  LINKEDIN = "linkedin",
}

export interface PageState {
  stepNames: Array<string>;
  stepMapping?: Record<string, number>;
  firstStep?: StepName;
  skipSelfDisclosure: boolean;
  questionnaireError?: boolean;
  showReviewSubmit?: boolean;
}

export interface SelectedLocations {
  locationIds: Array<string>;
}

export interface ApplyState {
  page: PageState;
  selectedLocations?: SelectedLocations;
  resume: FileData | null;
  textResume?: any;
  linkedin?: any;
  supportFiles: Array<TalentSupportingFile> | null;
  updatedFileCategories: Record<string, string> | null;
  discardResume: FileData | null;
  discardedFiles: Array<TalentSupportingFile> | null;
  connection: TalentConnection | null;
  talent: Talent;
  selfDisclosureConfig?: SelfDisclosureConfig;
  questionnaireAnswers: Questionnaire | null;
  linkedinSnapshot: string;
}

export type ProfileUpdateSelection =
  | profileUpdateMode.MANUAL
  | profileUpdateMode.RESUME
  | profileUpdateMode.LINKEDIN;

export interface Talent {
  talentId: string | null;
  talentTypeID?: string;
  addresses?: Array<Address>;
  mobilityPreferences?: MobilityPreference;
  preferredLocations?: Array<PreferredLocations>;
  skills?: Array<Skill>;
  languages?: Array<Language>;
  links?: Array<TalentSupportingLink>;
  employments?: Array<EmploymentDetails>;
  profileUpdateSelection?: ProfileUpdateSelection;
  educationDegrees?: Array<Education> | null;
  teamsOfInterest?: Array<Team>;
  _id?: string;
  localeNameIndicator?: boolean;
  kanjiIndicator?: boolean;
  selfDisclosures?: Record<string, SelfDisclosure>;
  contact: {
    firstName?: string;
    lastName?: string;
    preferredEmail?: string;
    preferredPhone?: string;
  };
  externalProfiles: {
    linkedin?: LinkedInData;
  } | null;
  connectionsCount?: number;
  userType?: string;
  dsType?: number;
  hideNationality?: boolean;
  hideGender?: boolean;
  sourceName?: string;
  sourceID?: string;
  submittedFlow?: boolean;
  lastLoginTime?: string;
  privacyPolicy?: {
    acknowledgedDate?: string;
    version?: number;
    countryID?: string;
    dataConsent?: DataUsageConsent;
  };
  talentDSID?: string;
  resumeLastAction?: string;
  linkedinLastAction?: string;
  emails?: Emails;
  discovered?: boolean;
  displayPronouns?: boolean;
  genderPronoun?: {
    subjective?: string;
    objective?: string;
    possessive?: string;
  };
}

interface Address {
  typeID: string;
  line1: string;
  line2: string;
  line3: string;
  district: string;
  county: string;
  state: string;
  countryID: string;
  countryName: string;
  zip: string;
}

export interface SelfDisclosureConfig {
  gender: boolean;
  veteranStatus: boolean;
  ethnicity: boolean;
  nationality: boolean;
  disability: boolean;
}
