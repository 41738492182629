import React, { useMemo } from "react";
import { GraduationStatusRefData } from "../../../../../shared/types/refData";
import RadioGroup, {
  RadioOption,
} from "../../../../components/feature/form/radioGroup";
import useIntlMessage from "../../../../hooks/useIntlMessage";

export function EducationGradStatus(props: {
  id: string;
  value: string;
  showError: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  refData: GraduationStatusRefData[];
}) {
  const { t } = useIntlMessage();
  const { id, showError, handleChange, value, refData } = props;

  const options: RadioOption[] = useMemo((): RadioOption[] => {
    return refData.map(
      (rf) =>
        ({
          id: ``,
          label: rf.name,
          value: rf.id,
        }) as RadioOption,
    );
  }, [refData]);
  return (
    <RadioGroup
      required={true}
      id={id}
      classes={{
        root: "mt-0",
      }}
      title={t("jobsite.common.graduated") as string}
      name={id}
      options={options}
      selectedValue={value}
      onChange={handleChange}
      errorMessage={
        showError ? (t("jobsite.common.chooseOption") as string) : undefined
      }
      errorA11y={t("jobsite.common.errorIconLabel") as string}
    />
  );
}
