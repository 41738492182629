import {
  Dropdown,
  DropDownOptionProps,
  NativeButton,
  Textbox,
  TextButton,
} from "@rpe-js/marcom-web-components";
import { random } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { getFileLinkRefData } from "../../../api/fetchClient";
import AppContext from "../../../AppContext";
import useAriaLiveStatus from "../../../hooks/useAriaLiveStatus";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { FileData } from "../../../types/File";
import {
  TalentSupportingFile,
  TalentSupportingLink,
} from "../../../types/SupportingFileWidget";
import { validateFile } from "../../../utils/fileUtil";
import {
  focusElement,
  focusManagementOnRemove,
} from "../../../utils/focusUtil";
import { idGenerator } from "../../../utils/idGenerator";
import { URL_REGEX } from "../../../utils/validatorUtil";
import { AlertWrapper } from "../../base/AlertWrapper";
import FileUploader from "../../base/FileUploader";

interface SupportingFileWidgetProps {
  align?: "left" | "center";
  dataLocale?: string;
  maxLinkCount?: number;
  maxFileCount?: number;
  canShowError?: boolean;
  talentSupportFileData: Array<TalentSupportingFile> | null;
  talentSupportLinkData: Array<TalentSupportingLink> | null;
  onSupportingFileChange: (supportFileData: TalentSupportingFile[]) => void;
  onSupportingFileCategoryChange: (
    supportFileData: TalentSupportingFile,
  ) => void;
  onSupportingLinkChange: (supportLinkData: TalentSupportingLink[]) => void;
  onRemoveSupportingFile: (removedFile: TalentSupportingFile) => void;
  setCanShowError: (value: boolean) => void;
}

interface LinkItem {
  id: string;
  name: string;
}

function supportFilemapper(fileObj: TalentSupportingFile) {
  return {
    ...fileObj,
    id: random(10000),
  };
}

function supportLinkMapper(fileObj: TalentSupportingLink) {
  return {
    ...fileObj,
    id: random(10000),
  };
}

export function SupportingFileWidget({
  align = "center",
  maxFileCount = 3,
  maxLinkCount = 10,
  dataLocale = "",
  canShowError = false,
  talentSupportFileData,
  talentSupportLinkData,
  onSupportingFileChange,
  onSupportingLinkChange,
  onRemoveSupportingFile,
  onSupportingFileCategoryChange,
  setCanShowError,
}: SupportingFileWidgetProps) {
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { countryCode } = appUIState.appData;
  const idSeqGeneratorLink = idGenerator("resume", "supportlink");
  const idSeqGeneratorFile = idGenerator("resume", "supportfile");
  const categoryLabel = t("jobsite.common.chooseCategory") as string;
  const [supportFileData, setSupportFileData] = useState<
    TalentSupportingFile[]
  >(
    (talentSupportFileData && talentSupportFileData.map(supportFilemapper)) ||
      [],
  );
  const [supportLinkData, setSupportLinkData] = useState<
    TalentSupportingLink[]
  >(
    (talentSupportLinkData && talentSupportLinkData.map(supportLinkMapper)) ||
      [],
  );
  const [showAddFile, setShowAddFile] = useState(
    !talentSupportFileData ||
      (talentSupportFileData.length < maxFileCount && true),
  );

  const [showAddLink, setShowAddLink] = useState(
    !talentSupportLinkData ||
      (talentSupportLinkData.length < maxLinkCount && true),
  );

  const [supportedFileCategories, setSupportedFileCategories] = useState<
    DropDownOptionProps[]
  >([]);
  const [supportedLinkCategories, setSupportedLinkCategories] = useState<
    DropDownOptionProps[]
  >([]);

  const [errorMsg, setErrorMsg] = useState<string>("");
  const itemsListRef = useRef<HTMLDivElement | null>(null);
  const liveRegionSpanRef = useRef<HTMLSpanElement>(null);
  const { announceAriaMessage } = useAriaLiveStatus(liveRegionSpanRef, 4000);

  /**
   *
   * @param fileData
   * This method adds new file data to the supportFileData and validates if the total supported file count reached the maxFileCount
   */
  const addFiles = useCallback(
    (fileData: FileData) => {
      const errorObj = validateFile(fileData);
      if (errorObj.error) {
        setErrorMsg(t(`jobsite.common.${errorObj.errorMsg}`) as string);
        focusElement(idSeqGeneratorFile.generateId("alert-wrapper"));
        return;
      }
      if (supportFileData.length + 1 == maxFileCount) {
        setShowAddFile(false);
      }
      const newSupportFileData = [
        ...supportFileData,
        { category: "", categoryName: "", fileData, id: random(10000) },
      ];
      setSupportFileData([...newSupportFileData]);
      onSupportingFileChange(newSupportFileData);
      setErrorMsg("");
      // focus on newly added supporting file category when add file is done
      focusElement(
        `resume-supportfile-category-${newSupportFileData.length - 1}`,
        0,
      );
      //to clear the error on adding new file
      setCanShowError(false);
    },
    [
      maxFileCount,
      onSupportingFileChange,
      supportFileData,
      setCanShowError,
      t,
      idSeqGeneratorFile,
    ],
  );

  /**
   * This method adds new link data to the setSupportLinkData and validates if the total supported links added reached the maxLinkCount
   */
  const addLinks = useCallback(() => {
    if (supportLinkData.length + 1 == maxLinkCount) {
      setShowAddLink(false);
    }
    const newLinkData = [
      ...supportLinkData,
      {
        link: "",
        categoryID: "",
        categoryName: "",
        id: random(10000),
      },
    ];
    setSupportLinkData(newLinkData);
    onSupportingLinkChange(newLinkData);
    // focus on newly added supporting link text when add link is done
    focusElement(`resume-supportlink-text-${newLinkData.length - 1}`, 0);
    //to clear the error on adding new link
    setCanShowError(false);
  }, [maxLinkCount, onSupportingLinkChange, supportLinkData, setCanShowError]);

  const focusNextRemoveButton = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (itemsListRef.current) {
        const removeButtonsList =
          itemsListRef.current.querySelectorAll("button");
        const indexToBeRemoved = Array.from(removeButtonsList).findIndex(
          (btn) => btn.id === event.currentTarget.id,
        );
        announceAriaMessage(t("jobsite.common.fileRemoved") as string);
        focusManagementOnRemove(
          itemsListRef,
          indexToBeRemoved,
          removeButtonsList.length,
          idGenerator("file", idSeqGeneratorFile.generateId()).generateId(),
          750,
        );
      }
    },
    [announceAriaMessage, idSeqGeneratorFile, t],
  );

  /**
   *
   * @param index
   * This method removes the specific support link row and updates the supportLinkData and also
   * validates if the Add Link Button can be shown if it is less than maxLinkCount
   */
  const removeLink = useCallback(
    (index: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (supportLinkData.length <= maxLinkCount) {
        setShowAddLink(true);
      }
      const newLinkData = supportLinkData.filter((_, i) => i !== index);
      setSupportLinkData(newLinkData);
      onSupportingLinkChange(newLinkData);
      focusNextRemoveButton(event);
    },
    [
      focusNextRemoveButton,
      maxLinkCount,
      onSupportingLinkChange,
      supportLinkData,
    ],
  );

  /**
   *
   * @param index
   * This method removes the specific support file row and updates the supportFileData and also
   * validates if the Add File Button can be shown if it is less than maxFileCount
   */
  const removeFile = useCallback(
    (index: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const removedFile = { ...supportFileData[index] };
      const newFileData = supportFileData.filter((_, i) => i !== index);
      if (supportFileData.length <= maxFileCount) {
        setShowAddFile(true);
      }
      setSupportFileData(newFileData);
      onSupportingFileChange(newFileData);
      onRemoveSupportingFile(removedFile);
      focusNextRemoveButton(event);
    },
    [
      focusNextRemoveButton,
      maxFileCount,
      onRemoveSupportingFile,
      onSupportingFileChange,
      supportFileData,
    ],
  );

  /**
   *
   * @param value
   * @param index
   * This method updates the support link content when link change
   */
  const onLinkTextChange = useCallback(
    (value: string, index: number) => {
      const updatedSupportLinkData = [...supportLinkData];
      updatedSupportLinkData[index].link = value;
      setSupportLinkData(updatedSupportLinkData);
      onSupportingLinkChange(updatedSupportLinkData);
    },
    [onSupportingLinkChange, supportLinkData],
  );

  /**
   *
   * @param index
   * @param option
   *  This method is triggered if the category dropdown value changes in support link section ,
   *  it updates the support link content appropriately
   */
  const onLinkCategoryChange = useCallback(
    (index: number, option: DropDownOptionProps) => {
      const updatedSupportLinkData = [...supportLinkData];
      updatedSupportLinkData[index].categoryID = option.value;
      updatedSupportLinkData[index].categoryName = option.label;
      setSupportLinkData(updatedSupportLinkData);
      onSupportingLinkChange(updatedSupportLinkData);
    },
    [onSupportingLinkChange, supportLinkData],
  );

  /**
   *
   * @param index
   * @param option
   * This method is triggered if the category dropdown value changes in support file section ,
   * it updates the support file data appropriately
   */
  const onFileCategoryChange = useCallback(
    (index: number, option: DropDownOptionProps) => {
      const updatedSupportFileData = [...supportFileData];
      updatedSupportFileData[index].category = option.value;
      updatedSupportFileData[index].categoryName = option.label;
      setSupportFileData(updatedSupportFileData);
      onSupportingFileChange(updatedSupportFileData);
      onSupportingFileCategoryChange({ ...updatedSupportFileData[index] });
    },
    [onSupportingFileCategoryChange, onSupportingFileChange, supportFileData],
  );

  const getLinkRefDataInfo = useCallback(async () => {
    const fileLinkRefData = await getFileLinkRefData({
      headers: { locale: dataLocale || countryCode?.data },
    });
    if (fileLinkRefData && fileLinkRefData.supportingFile) {
      setSupportedFileCategories(
        [
          {
            label: categoryLabel,
            value: "",
            disabled: true,
          },
        ].concat(
          fileLinkRefData.supportingFile.map((item: LinkItem) => ({
            label: item.name,
            value: item.id,
            disabled: false,
          })),
        ),
      );
    }

    if (fileLinkRefData && fileLinkRefData.supportingLink) {
      setSupportedLinkCategories(
        [
          {
            label: categoryLabel,
            value: "",
            disabled: true,
          },
        ].concat(
          fileLinkRefData.supportingLink.map((item: LinkItem) => ({
            label: item.name,
            value: item.id,
            disabled: false,
          })),
        ),
      );
    }
  }, [categoryLabel, countryCode?.data, dataLocale]);

  const checkDuplicateLinkCategory = useCallback(
    (talentSupportingLink: TalentSupportingLink) => {
      if (!talentSupportingLink.link || !talentSupportingLink.categoryID) {
        return false;
      }
      // this will check for duplicate links with same category
      const duplicateLinks = supportLinkData.filter(
        (supportLink) =>
          supportLink.categoryID == talentSupportingLink.categoryID &&
          supportLink.link == talentSupportingLink.link,
      );
      return duplicateLinks.length > 1;
    },
    [supportLinkData],
  );

  const validateLinks = useCallback(
    (talentSupportingLink: TalentSupportingLink) => {
      if (!supportLinkData) {
        return "";
      }
      if (checkDuplicateLinkCategory(talentSupportingLink)) {
        return t("jobsite.common.duplicateLink") as string;
      }
      // this will check for valid url
      if (!URL_REGEX().test(talentSupportingLink.link)) {
        return t("jobsite.common.validUrl") as string;
      }
      return "";
    },
    [checkDuplicateLinkCategory, supportLinkData, t],
  );

  const validateLinkCategory = useCallback(
    (talentSupportingLink: TalentSupportingLink) => {
      if (!supportLinkData) {
        return "";
      }
      if (checkDuplicateLinkCategory(talentSupportingLink)) {
        return t("jobsite.common.duplicateLink") as string;
      }
      if (!talentSupportingLink.categoryID) {
        return t("jobsite.common.errorCategory") as string;
      }
      return "";
    },
    [checkDuplicateLinkCategory, supportLinkData, t],
  );

  const checkDuplicateFile = useCallback(
    (talentSupportingFile: TalentSupportingFile) => {
      if (!talentSupportingFile.category) {
        return false;
      }
      const duplicateFiles = supportFileData.filter(
        (supportFile) =>
          supportFile.category == talentSupportingFile.category &&
          supportFile.fileData?.name == talentSupportingFile.fileData?.name,
      );
      return duplicateFiles.length > 1;
    },
    [supportFileData],
  );

  const validateFiles = useCallback(
    (talentSupportingFile: TalentSupportingFile) => {
      if (!supportFileData) {
        return "";
      }
      if (checkDuplicateFile(talentSupportingFile)) {
        return t("jobsite.common.duplicateFile") as string;
      }
      return "";
    },
    [checkDuplicateFile, supportFileData, t],
  );

  const validateFileCategory = useCallback(
    (talentSupportingFile: TalentSupportingFile) => {
      if (!supportFileData) {
        return "";
      }
      if (checkDuplicateFile(talentSupportingFile)) {
        return t("jobsite.common.duplicateFile") as string;
      }
      if (!talentSupportingFile.category) {
        return t("jobsite.common.errorCategory") as string;
      }
      return "";
    },
    [checkDuplicateFile, supportFileData, t],
  );

  useEffect(() => {
    if (supportedFileCategories.length == 0) {
      getLinkRefDataInfo();
    }
  }, [getLinkRefDataInfo, supportedFileCategories.length]);

  const getAriaLabelForRemoveBtn = (
    type: "link" | "file",
    value: string,
    index: number,
  ) => {
    if (type === "file") {
      return t("jobsite.common.removeEntity", {
        name: `${
          value
            ? value
            : (t("jobsite.common.supportingFileVO", {
                number: index,
              }) as string)
        }`,
      }) as string;
    } else if (type === "link") {
      return t("jobsite.common.removeEntity", {
        name: `${
          value
            ? value
            : (t("jobsite.common.supportingLinkVO", {
                number: index,
              }) as string)
        }`,
      }) as string;
    }
  };

  return (
    <>
      <section className={`text-${align} ${align === "center" ? "px-30" : ""}`}>
        <h2 className="t-label" id={idSeqGeneratorFile.generateId("title")}>
          {t("jobsite.common.optionalInfo")}
        </h2>
        <p>{t("jobsite.common.optionalTitle")}</p>
        {align === "center" && <p className="u-border-bottom"></p>}
      </section>
      <section>
        {errorMsg && (
          <AlertWrapper
            message={errorMsg}
            closePosition="right"
            role="group"
            error={true}
            remove={{
              closeBtnAriaLabel: ` ${t("jobsite.apply.closeFileTypeAlertOptionalInfoVO") as string} `,
              onRemove: () => {
                setErrorMsg("");
              },
              idToFocus: idGenerator(
                "file",
                idSeqGeneratorFile.generateId(),
              ).generateId(),
            }}
            id={idSeqGeneratorFile.generateId("alert-wrapper")}
            aria-label={
              t("jobsite.apply.fileTypeAlertOptionalInfoVO") as string
            }
          ></AlertWrapper>
        )}
        {!!(supportLinkData?.length || supportFileData?.length) && (
          <div
            className="px-30 pt-20 pb-30 mt-10 background-fill-tertiary"
            ref={itemsListRef}
          >
            <ul role="list" className="m-0">
              {supportFileData.map((supportFileItem, index) => (
                <li
                  role="listitem"
                  key={`supporFile${supportFileItem.id}`}
                  className="pos-rel no-bullet"
                >
                  <div className="row mb-5">
                    <div className="column large-12 d-flex flex-column align-end">
                      <NativeButton
                        id={idSeqGeneratorFile.generateId("remove", true)}
                        onClick={(event) => removeFile(index, event)}
                        className="link"
                        label={t("jobsite.common.remove") as string}
                        aria-label={getAriaLabelForRemoveBtn(
                          "file",
                          supportFileItem.fileData.name,
                          index + 1,
                        )}
                      ></NativeButton>
                    </div>
                  </div>
                  <div className="row gap-5">
                    <div
                      key={`supportFileText${supportFileItem.id}`}
                      className="column d-flex flex-column mb-5 supportingfile-widget-section"
                    >
                      <Textbox
                        id={idSeqGeneratorFile.generateId("text", true)}
                        label={t("jobsite.common.supportingFile") as string}
                        value={supportFileItem.fileData.name || ""}
                        disabled={true}
                        error={
                          canShowError ? validateFiles(supportFileItem) : ""
                        }
                        errorA11y={t("jobsite.common.errorIconLabel") as string}
                        required={true}
                      ></Textbox>
                    </div>
                    <div
                      key={`supportFileDropdown${supportFileItem.id}`}
                      className="column d-flex flex-column mb-5 supportingfile-widget-section"
                    >
                      <Dropdown
                        required={true}
                        id={idSeqGeneratorFile.generateId("category", true)}
                        options={supportedFileCategories}
                        handleValueSelect={(evt, option) =>
                          onFileCategoryChange(index, option)
                        }
                        label={t("jobsite.common.chooseCategory") as string}
                        value={supportFileItem.category}
                        name={t("jobsite.common.chooseCategory") as string}
                        error={
                          canShowError && validateFileCategory(supportFileItem)
                        }
                        {...(checkDuplicateFile(supportFileItem)
                          ? { classes: { error: "d-none" } }
                          : {})}
                        errorA11y={t("jobsite.common.errorIconLabel") as string}
                      ></Dropdown>
                    </div>
                  </div>

                  <p className="u-border-bottom mt-10"></p>
                </li>
              ))}

              {supportLinkData.map((supportLinkItem, index) => (
                <li
                  role="listitem"
                  key={`supportLink${supportLinkItem.id}`}
                  className="pos-rel no-bullet"
                >
                  <div className="row mb-5">
                    <div className="column large-12 d-flex flex-column align-end">
                      <NativeButton
                        id={idSeqGeneratorFile.generateId("remove", true)}
                        onClick={(event) => removeLink(index, event)}
                        className="link"
                        label={t("jobsite.common.remove") as string}
                        aria-label={getAriaLabelForRemoveBtn(
                          "link",
                          supportLinkItem.link,
                          index + 1,
                        )}
                      ></NativeButton>
                    </div>
                  </div>
                  <div className="row gap-5">
                    <div
                      className="column d-flex flex-column supportingfile-widget-section"
                      key={`supportLinkText${supportLinkItem.id}`}
                    >
                      <Textbox
                        id={idSeqGeneratorLink.generateId("text", true)}
                        label={t("jobsite.common.supportingLink") as string}
                        value={supportLinkItem.link || ""}
                        onValueChange={(val) => onLinkTextChange(val, index)}
                        error={
                          canShowError ? validateLinks(supportLinkItem) : ""
                        }
                        errorA11y={t("jobsite.common.errorIconLabel") as string}
                        maxLength={300}
                        required={true}
                      ></Textbox>
                    </div>
                    <div
                      className="column d-flex flex-column supportingfile-widget-section"
                      key={`supportLinkCategory${supportLinkItem.id}`}
                    >
                      <Dropdown
                        required={true}
                        id={idSeqGeneratorLink.generateId("category", true)}
                        options={supportedLinkCategories}
                        handleValueSelect={(evt, option) =>
                          onLinkCategoryChange(index, option)
                        }
                        label={categoryLabel}
                        value={supportLinkItem.categoryID}
                        name={categoryLabel}
                        error={
                          canShowError
                            ? validateLinkCategory(supportLinkItem)
                            : ""
                        }
                        {...(checkDuplicateLinkCategory(supportLinkItem)
                          ? { classes: { error: "d-none" } }
                          : {})}
                        errorA11y={t("jobsite.common.errorIconLabel") as string}
                      ></Dropdown>
                    </div>
                  </div>
                  <p className="u-border-bottom mt-10"></p>
                </li>
              ))}
            </ul>
          </div>
        )}
        <section
          className={`text-${align}  ${align === "center" ? "mt-10 px-30" : "mt-20"}`}
        >
          {showAddFile && (
            <div className={"d-inline-block px-5"}>
              <FileUploader
                onFileUpload={addFiles}
                id={idSeqGeneratorFile.generateId()}
                label={t("jobsite.common.addFile") as string}
                showPlusIcon={true}
              ></FileUploader>
            </div>
          )}
          {showAddLink && (
            <div
              className={"d-inline-block px-10 attach-file-label align-middle"}
            >
              <TextButton
                id={idSeqGeneratorFile.generateId()}
                onClick={addLinks}
                label={t("jobsite.common.addLink") as string}
                icon="icon-pluscircle"
              ></TextButton>
            </div>
          )}
        </section>
        <span
          ref={liveRegionSpanRef}
          role="status"
          aria-live="polite"
          className="a11y"
        />
      </section>
    </>
  );
}
