import { MonthsRefData } from "@rpe-js/core/src/types/refData";
import { ProgressIndicatorLoader } from "@rpe-js/marcom-web-components";
import { cloneDeep, isEmpty } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  EducationGradStatusRefData,
  ProficiencyLanguageRefData,
} from "../../../shared/types/refData";
import {
  EmploymentDetails,
  Language,
  Skill,
  Talent,
  TalentParsedExternalModel,
} from "../../../shared/types/talent/talent";
import { RESUME_PARSING_DATA } from "../../AppActionTypes";
import AppContext from "../../AppContext";
import {
  getEducationGradStatus,
  getLanguagesProficiency,
  getMonths,
  getTalent,
  parsedLinkedInSnapshot,
  parsedResumeSnapshot,
  talentSubmit,
} from "../../api/fetchClient";
import { useLocaleAwareLink } from "../../components/feature/LocaleAwareLink";
import { useFormInputValidator } from "../../components/feature/form/inputValidators/useFormInputValidator";
import { RESUME_REVIEW } from "../../components/feature/resumeparsing/types/ResumeParsing";
import useFocusFirstErrorField from "../../hooks/useFocusFirstErrorField";
import useIntlMessage from "../../hooks/useIntlMessage";
import useRefresh from "../../hooks/useRefresh";
import { ResumeParsingData } from "../../types";
import { idGenerator } from "../../utils/idGenerator";
import {
  EducationDegreeForm,
  getEducationDegreeForm,
  getEducationPayload,
} from "../profile/components/educationSummary/form";
import {
  EmploymentForm,
  getEmploymentForm,
  getEmploymentsPayload,
} from "../profile/information/employmentFormUtils";
import {
  getLanguageForm,
  LanguageForm,
} from "../profile/information/spokenLanguagesFormUtils";
import { ParsedModal } from "./ParsedModal";

type ResumeParsingProps = {
  setOpenParsedModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useOnResumeParseModalClose(
  resumeParsingData: ResumeParsingData | null | undefined,
  talentId: string | undefined,
) {
  const onClose = useCallback(
    (onSuccess: () => void) => {
      if (resumeParsingData?.profileUpdateSelection === "resume") {
        parsedResumeSnapshot(
          talentId as string,
          RESUME_REVIEW.reviewLater,
        ).then(() => onSuccess());
      } else if (resumeParsingData?.profileUpdateSelection === "linkedin") {
        parsedLinkedInSnapshot(
          talentId as string,
          RESUME_REVIEW.reviewLater,
        ).then(() => onSuccess());
      }
    },
    [resumeParsingData, talentId],
  );
  return onClose;
}

export const ResumeParsing = ({ setOpenParsedModal }: ResumeParsingProps) => {
  const [skills, setSkills] = useState<Skill[] | null>(null);
  const [skillsErrorMessage, setSkillsErrorMessage] = useState("");
  const [showErrors, setShowErrors] = useState(false);
  const [languages, setLanguages] = useState<LanguageForm[]>([]);
  const [educationDegrees, setEducationDegrees] = useState<
    EducationDegreeForm[]
  >([]);
  const [employmentsForm, setEmploymentsForm] = useState<EmploymentForm[] | []>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);
  const [proficiencyLanguageRefData, setProficiencyLanguageRefData] =
    useState<ProficiencyLanguageRefData | null>(null);
  const [monthsRefData, setMonthsRefData] = useState<MonthsRefData[] | null>(
    [],
  );
  const [educationGradStatusRefData, setEducationGradStatusRefData] =
    useState<EducationGradStatusRefData | null>(null);
  const { t } = useIntlMessage();
  const { appUIState, dispatch } = useContext(AppContext);
  const { talentId, locale } = appUIState.appData;
  const { resumeParsingData } = appUIState;
  const getLink = useLocaleAwareLink();
  const { isValidSchoolSkill } = useFormInputValidator();
  const refresh = useRefresh(`/app/${locale}/profile`);
  const onCloseModal = useOnResumeParseModalClose(resumeParsingData, talentId);
  const { setFocusFirstErrorField } = useFocusFirstErrorField(
    idGenerator("parsedmodal", "review").generateId("container"),
  );

  const validateSkills = useCallback(() => {
    if (skills?.length === 0) {
      setSkillsErrorMessage(t("jobsite.common.skillserror") as string);
      return false;
    }
    setSkillsErrorMessage("");
    return true;
  }, [skills, t]);

  const validateLanguages = useCallback(() => {
    const languagesFormCopy = cloneDeep(languages);
    let isValid = true;
    languagesFormCopy.forEach((language: LanguageForm) => {
      if (!language.languageID) {
        language.languageError = t("jobsite.common.errorlanguage") as string;
      }
      if (language.languageError) {
        isValid = false;
      }
    });
    if (!isValid) {
      setLanguages([...languagesFormCopy]);
    }
    return isValid;
  }, [languages, t]);

  const validateEducationForm = useCallback((): boolean => {
    let isError = false;
    const educationDegreesCopy = cloneDeep(educationDegrees);
    educationDegreesCopy.forEach((d) => {
      const objs = [d.degree, d.school, d.major, d.gradStatus];
      objs.forEach((obj) => {
        if ((isEmpty(obj.label) && isEmpty(obj.value)) || isEmpty(obj.label)) {
          obj.requiredError = obj.required && true;
          if (!isError) {
            isError = obj.requiredError;
          }
        } else {
          obj.requiredError = false;
        }
      });
      if (!isEmpty(d.school.label)) {
        d.school.invalidError = !isValidSchoolSkill(d.school.label as string);
      }

      if (!isEmpty(d.major.label)) {
        d.major.invalidError = !isValidSchoolSkill(d.major.label as string);
      }
      isError =
        isError || d.school?.invalidError || d.major?.invalidError || false;
    });
    setEducationDegrees(educationDegreesCopy);
    return isError;
  }, [educationDegrees, isValidSchoolSkill]);

  const validateEmployments = useCallback(() => {
    const emplomentsFormCopy = cloneDeep(employmentsForm);
    const validationKeys = [
      "employerName",
      "startMonthID",
      "startYear",
      "jobTitle",
      "currentEmployer",
    ];
    let isError = false;
    emplomentsFormCopy.forEach((employment: EmploymentForm) => {
      Object.keys(employment).forEach((key) => {
        if (validationKeys.includes(key)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          if (!employment[key].value) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            employment[key].requiredError = true;
            isError = true;
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            employment[key].requiredError = false;
          }
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        if (employment[key]?.invalidErrorMessage) {
          isError = true;
        }
      });
      if (employment.currentEmployer.value === "No") {
        if (!employment.endMonthID.value) {
          employment.endMonthID.requiredError = true;
          isError = true;
        } else {
          employment.endMonthID.requiredError = false;
        }
        if (!employment.endYear.value) {
          employment.endYear.requiredError = true;
          isError = true;
        } else {
          employment.endYear.requiredError = false;
        }
      }
    });
    setEmploymentsForm(emplomentsFormCopy);
    return isError;
  }, [employmentsForm]);

  const updateLastAction = useCallback(
    (action: string) => {
      if (resumeParsingData?.profileUpdateSelection === "resume") {
        dispatch({
          type: RESUME_PARSING_DATA,
          payload: {
            ...resumeParsingData,
            resumeLastAction: action,
          },
        });
      } else if (resumeParsingData?.profileUpdateSelection === "linkedin") {
        dispatch({
          type: RESUME_PARSING_DATA,
          payload: {
            ...resumeParsingData,
            linkedinLastAction: action,
          },
        });
      }
    },
    [dispatch, resumeParsingData],
  );

  const updateRefDataNames = useCallback(
    (
      field: string,
      object: LanguageForm | EmploymentForm | EducationDegreeForm,
    ) => {
      if (field === "language") {
        const refData = proficiencyLanguageRefData?.language?.filter(
          (r) => r.id === (object as LanguageForm).languageID,
        );
        if (refData?.length) {
          (object as LanguageForm).languageName = refData[0].name;
        }
      } else if (field === "proficiency") {
        const refData = proficiencyLanguageRefData?.language?.filter(
          (r) => r.id === (object as LanguageForm).proficiencyID,
        );
        if (refData?.length) {
          (object as LanguageForm).proficiencyName = refData[0].name;
        }
      } else if (field === "startMonth") {
        const refData = monthsRefData?.filter(
          (r) => r.id === (object as EmploymentForm).startMonthID?.value,
        );
        if (refData?.length) {
          (object as EmploymentForm).startMonthName.value = refData[0].name;
        }
      } else if (field === "endMonth") {
        const refData = monthsRefData?.filter(
          (r) => r.id === (object as EmploymentForm).endMonthID?.value,
        );
        if (refData?.length) {
          (object as EmploymentForm).endMonthName.value = refData[0].name;
        }
      } else if (field === "degree") {
        const refData = educationGradStatusRefData?.education?.filter(
          (r) => r.id === (object as EducationDegreeForm).degree?.value,
        );
        if (refData?.length) {
          (object as EducationDegreeForm).degree.label = refData[0].name;
        }
      } else if (field === "gradStatus") {
        const refData = educationGradStatusRefData?.gradStatus?.filter(
          (r) => r.id === (object as EducationDegreeForm).gradStatus?.value,
        );
        if (refData?.length) {
          (object as EducationDegreeForm).gradStatus.label = refData[0].name;
        }
      }
    },
    [
      educationGradStatusRefData?.education,
      educationGradStatusRefData?.gradStatus,
      proficiencyLanguageRefData?.language,
      monthsRefData,
    ],
  );

  const onSave = async () => {
    setShowErrors(true);
    let isValid = true;
    if (languages?.length && !validateLanguages()) {
      isValid = false;
    }
    if (Boolean(skills) && !validateSkills()) {
      isValid = false;
    }
    if (validateEducationForm()) {
      isValid = false;
    }
    if (validateEmployments()) {
      isValid = false;
    }
    if (!isValid) {
      setFocusFirstErrorField(true);
      return;
    }
    setIsLoading(true);
    const talentData = (await getTalent(talentId as string)) as Talent;

    if (skills?.length) {
      talentData.skills = skills;
    }

    if (languages?.length) {
      const languagesCopy = cloneDeep(languages);
      languagesCopy.forEach((language: LanguageForm) => {
        if (language.languageID && !language.languageName) {
          updateRefDataNames("language", language);
        }
        if (language.proficiencyID && !language.proficiencyName) {
          updateRefDataNames("proficiency", language);
        }
      });
      talentData.languages = languagesCopy.map((language: LanguageForm) => ({
        languageID: language.languageID as string,
        languageName: language.languageName,
        preferredLanguage: language.preferredLanguage,
        proficiencyID: language.proficiencyID,
        proficiencyName: language.proficiencyName,
      }));
    }

    if (employmentsForm?.length) {
      const employmentsCopy = cloneDeep(employmentsForm);
      employmentsCopy.forEach((employment: EmploymentForm) => {
        if (employment.startMonthID.value && !employment.startMonthName.value) {
          updateRefDataNames("startMonth", employment);
        }
        if (employment.endMonthID.value && !employment.endMonthName.value) {
          updateRefDataNames("endMonth", employment);
        }
      });
      talentData.employments = getEmploymentsPayload(employmentsCopy);
    }

    if (educationDegrees?.length) {
      const educationDegreesCopy = cloneDeep(educationDegrees);
      educationDegreesCopy.forEach((education: EducationDegreeForm) => {
        if (education.degree.value && !education.degree.label) {
          updateRefDataNames("degree", education);
        }
        if (education.gradStatus.value && !education.gradStatus.label) {
          updateRefDataNames("gradStatus", education);
        }
      });
      talentData.educationDegrees = getEducationPayload(educationDegreesCopy);
    }
    await talentSubmit(talentId as string, { talent: talentData }).then(() => {
      updateLastAction(RESUME_REVIEW.reviewedLabel);
      if (location.pathname.includes("/profile/info")) {
        refresh(getLink(true, "profile/info"));
      }
      setOpenParsedModal(false);
    });
    setIsLoading(false);
  };

  const onDismiss = useCallback(() => {
    if (resumeParsingData?.profileUpdateSelection === "resume") {
      parsedResumeSnapshot(talentId as string, RESUME_REVIEW.discard).then(
        () => {
          dispatch({
            type: RESUME_PARSING_DATA,
            payload: {
              ...resumeParsingData,
              resumeLastAction: RESUME_REVIEW.discardedLabel,
            },
          });
          setOpenParsedModal(false);
        },
      );
    } else if (resumeParsingData?.profileUpdateSelection === "linkedin") {
      parsedLinkedInSnapshot(talentId as string, RESUME_REVIEW.discard).then(
        () => {
          dispatch({
            type: RESUME_PARSING_DATA,
            payload: {
              ...resumeParsingData,
              linkedinLastAction: RESUME_REVIEW.discardedLabel,
            },
          });
          setOpenParsedModal(false);
        },
      );
    }
    updateLastAction(RESUME_REVIEW.discardedLabel);
  }, [
    dispatch,
    resumeParsingData,
    setOpenParsedModal,
    talentId,
    updateLastAction,
  ]);

  const handleResponse = useCallback(
    (
      data: [
        TalentParsedExternalModel,
        MonthsRefData[],
        EducationGradStatusRefData,
        ProficiencyLanguageRefData,
      ],
    ) => {
      if (data[0]?.skills) {
        setSkills(data[0].skills as Skill[]);
      }
      if (data[0]?.languages?.length) {
        const languagesForm: LanguageForm[] = [];
        data[0].languages?.forEach((language: Language) => {
          languagesForm.push(getLanguageForm(language));
        });
        setLanguages([...languagesForm]);
      }
      if (data[1]) {
        setMonthsRefData(data[1]);
      }
      if (data[2]) {
        setEducationGradStatusRefData(data[2]);
      }
      if (data[3]) {
        setProficiencyLanguageRefData(data[3]);
      }
      if (data[0]?.educationDegrees?.length) {
        const eduDegreeForm: EducationDegreeForm[] = [];
        data[0]?.educationDegrees.forEach((ied) => {
          eduDegreeForm.push(getEducationDegreeForm(ied));
        });
        setEducationDegrees(eduDegreeForm);
      }
      if (data[0]?.employments?.length) {
        const employmentFormCopy: EmploymentForm[] = [];
        data[0]?.employments?.forEach((employment: EmploymentDetails) => {
          employmentFormCopy.push(getEmploymentForm(employment));
        });
        setEmploymentsForm(employmentFormCopy);
      }
    },
    [],
  );

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const promiseArray = [];
    if (resumeParsingData?.profileUpdateSelection === "resume") {
      promiseArray.push(
        parsedResumeSnapshot(talentId as string, RESUME_REVIEW.reviewNow),
      );
    } else if (resumeParsingData?.profileUpdateSelection === "linkedin") {
      promiseArray.push(
        parsedLinkedInSnapshot(talentId as string, RESUME_REVIEW.reviewNow),
      );
    }
    promiseArray.push(
      getMonths(),
      getEducationGradStatus(),
      getLanguagesProficiency(),
    );
    const data = await Promise.all(promiseArray);
    handleResponse(
      data as [
        TalentParsedExternalModel,
        MonthsRefData[],
        EducationGradStatusRefData,
        ProficiencyLanguageRefData,
      ],
    );
    setIsLoading(false);
  }, [handleResponse, resumeParsingData?.profileUpdateSelection, talentId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {isLoading && <ProgressIndicatorLoader showLoading={true} />}

      {!isLoading &&
        proficiencyLanguageRefData &&
        monthsRefData &&
        educationGradStatusRefData && (
          <ParsedModal
            showErrors={showErrors}
            skills={skills as Skill[]}
            setSkills={setSkills}
            languages={languages}
            setLanguages={setLanguages}
            educationDegrees={educationDegrees}
            setEducationDegrees={setEducationDegrees}
            employmentsForm={employmentsForm}
            setEmploymentsForm={setEmploymentsForm}
            skillsErrorMessage={skillsErrorMessage}
            monthsRefData={monthsRefData}
            proficiencyLanguageRefData={proficiencyLanguageRefData}
            educationGradStatusRefData={
              educationGradStatusRefData as EducationGradStatusRefData
            }
            onClose={() => onCloseModal(() => setOpenParsedModal(false))}
            onSave={onSave}
            onCancel={onDismiss}
          />
        )}
    </>
  );
};
