import React from "react";

interface ErrorMessageProps {
  errorId?: string;
  message: string;
  errorA11y: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  message,
  errorA11y,
  errorId,
}) => {
  return (
    <div className="error-message" id={errorId}>
      <span className="icon icon-after icon-exclamationcircle" />
      {errorA11y && <span className="visuallyhidden">{errorA11y}</span>}
      <span className="ml-10">{message}</span>
    </div>
  );
};

export default ErrorMessage;
