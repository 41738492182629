import { Dropdown, DropDownOptionProps } from "@rpe-js/marcom-web-components";
import React, { useMemo } from "react";
import { EducationDegreeRefData } from "../../../../../shared/types/refData";
import useIntlMessage from "../../../../hooks/useIntlMessage";

const getDropdownOptions = (refData: EducationDegreeRefData[]) => {
  return refData.map((rf): DropDownOptionProps => {
    return {
      label: rf.name,
      value: rf.id,
    } as DropDownOptionProps;
  });
};

export function EducationDegree(props: {
  id: string;
  refData: EducationDegreeRefData[];
  value?: string;
  onChange: (
    evt: React.ChangeEvent<HTMLInputElement>,
    selectedOption: DropDownOptionProps,
  ) => void;
  showError: boolean;
}) {
  const { t } = useIntlMessage();
  const { id, refData, value, onChange, showError } = props;

  const degreeOptions: DropDownOptionProps[] = useMemo(() => {
    const options = getDropdownOptions(refData);
    return [
      {
        label: t("jobsite.common.selectDegree") as string,
        value: "",
        disabled: true,
      },
      ...options,
    ];
  }, [refData, t]);

  return (
    <Dropdown
      id={id}
      required={true}
      options={degreeOptions}
      label={t("jobsite.common.selectDegree") as string}
      name={t("jobsite.common.selectDegree") as string}
      value={value}
      handleValueSelect={onChange}
      error={showError ? (t("jobsite.common.errorDegree") as string) : false}
      errorA11y={t("jobsite.common.errorIconLabel") as string}
    />
  );
}
