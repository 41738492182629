import React, { useContext, useEffect } from "react";
import APP_CONSTANTS from "../../../utilities/appConstants";
import AppContext from "../../AppContext";
import HtmlRenderer from "../../components/base/HtmlRenderer";
import useDtm from "../../hooks/useAdobeAnalytics";
import useIntlMessage from "../../hooks/useIntlMessage";

export default function InternalUnAuthorized() {
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { locale } = appUIState.appData;
  const { trackPageLoad } = useDtm();
  useEffect(() => {
    const pageInfo = {
      pageName: APP_CONSTANTS.ANALYTICS.PAGE_NAME.LOGGED_OUT,
      locale: appUIState?.appData?.locale || APP_CONSTANTS.DEFAULT_LOCALE,
      title: APP_CONSTANTS.ANALYTICS.PAGE_NAME.LOGGED_OUT,
      isInternal: appUIState?.appData?.isInternal,
    };
    trackPageLoad(pageInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <main>
      <div className={"text-center p-50"}>
        <h1 className={"t-eyebrow-elevated"}>
          {t("jobsite.common.signedOut", { locale })}
        </h1>
        <HtmlRenderer
          classes="hyperlink-underline"
          initialContent={
            t("jobsite.common.internalUnauthorizedInformation", {
              locale,
            }) as string
          }
          htmlTag="div"
        />
      </div>
    </main>
  );
}
