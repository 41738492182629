import { isEmpty } from "lodash";
import { Location, Role } from "../../../../shared/types/role";
import { isNo, isYes } from "../../../../shared/utils/roles";
import { RoleSectioName, RoleUIObj } from "./types";

const FAVORITES = "Favorites";
const SUBMISSIONS = "Submissions";

export function deriveLocationName(location: Location): string {
  const { city, stateProvince, countryName, metro, region } = location;
  if (city) {
    return [city, stateProvince, countryName].join(", ");
  } else if (metro) {
    return [metro, stateProvince, countryName].join(", ");
  } else if (stateProvince) {
    return [stateProvince, countryName].join(", ");
  } else if (region) {
    return [region, countryName].join(", ");
  } else {
    return countryName;
  }
}
export const getRoleUiObj = (
  role: Role,
  selectedSection: RoleSectioName,
): RoleUIObj => {
  const submittedDate = role.submittedDate || 0;
  const favoritedDate = role.favoritedDate || 0;
  return {
    ...role,
    submittedDate,
    favoritedDate,
    isStatusClosed:
      !isEmpty(role.statusID) &&
      ["closed", "cancelled", "unposted"].indexOf(role.statusID.toLowerCase()) >
        0,
    reSubmit:
      role.roleLocation ||
      false ||
      (role.resubmitted && isYes(role.resubmitted)),
    positionActive: isYes(role.roleVisible),
    isSubmitted: isYes(role.submitted),
    isResubmitted: isYes(role.resubmitted),
    isFavorite: isYes(role.favorited),
    displaySubmitDate: (() => {
      if (selectedSection === SUBMISSIONS) {
        return true;
      } else if (
        (submittedDate > favoritedDate && selectedSection !== FAVORITES) ||
        (isNo(role.favorited) && isYes(role.submitted)) // If the role is unfavorited we still get the favoritedDate and if that is recent action then submittedDate > favoritedDate becomes false always
      ) {
        return true;
      } else {
        return false;
      }
    })(),
    getDisplaySubmittedDate: (() => {
      if (role && submittedDate) {
        return submittedDate;
      }
      return null;
    })(),
    displayFavoriteDate: (() => {
      if (selectedSection === FAVORITES) {
        return true;
      } else if (
        submittedDate <= favoritedDate &&
        selectedSection !== SUBMISSIONS &&
        isYes(role.favorited)
      ) {
        return submittedDate <= favoritedDate;
      } else {
        return false;
      }
    })(),
    displayFavAndNotResetted: (() => {
      return isYes(role.favorited) && role.reset !== true;
    })(),
    displayIfNotFavOrResetted: (() => {
      return !isYes(role.favorited) || role.reset === true;
    })(),
  };
};

export function filterRolesOnSelection(
  roles: RoleUIObj[],
  selected: RoleSectioName,
): RoleUIObj[] {
  let filtered = roles;
  if (selected === FAVORITES) {
    filtered = roles.filter(
      (r) => r.isFavorite === true && r.positionActive === true,
    );
  } else if (selected === SUBMISSIONS) {
    filtered = roles.filter(
      (r) =>
        (r.isSubmitted === true || r.reSubmit === true) &&
        r.positionActive === true,
    );
  } else {
    filtered = roles.filter(
      (r) =>
        (r.isSubmitted === true ||
          r.reSubmit === true ||
          r.isFavorite === true) &&
        r.positionActive === true,
    );
  }
  return filtered.sort((a, b) => b.sortingDate - a.sortingDate);
}

export function hasUnAvailableRoles(roles: RoleUIObj[]): boolean {
  const inActiveRoles = (roles || []).filter((role) => role.isStatusClosed);
  return inActiveRoles.length > 0;
}
