import { ModifiedSearchResult } from "@rpe-js/core/dist/types/searchResponseTypes";
import React, { useContext } from "react";
import { useLocation } from "react-router";
import APP_CONSTANTS from "../../../../utilities/appConstants";
import AppContext from "../../../AppContext";
import HtmlRenderer from "../../../components/base/HtmlRenderer";
import LocaleAwareLink from "../../../components/feature/LocaleAwareLink";
import RoleSubmitButton from "../../../components/feature/RoleSubmitButton";
import ShareJobComponent from "../../../components/feature/shareJob/ShareJobComponent";
import useIdGenerator from "../../../hooks/useIdGenerator";
import useIntlMessage from "../../../hooks/useIntlMessage";
import textTruncate from "../../../utils/text-truncate";

interface JobContentPorps {
  id: string;
  job: ModifiedSearchResult;
}

export default function JobContent({ id, job }: JobContentPorps) {
  const { t } = useIntlMessage();
  const searchLocation = useLocation();
  const { appUIState } = useContext(AppContext);
  const { isInternal } = appUIState.appData;
  const weeklyHoursId = useIdGenerator(
    "search",
    `weekly-hours-${job.positionId}`,
    true,
  );
  const homeOfficeId = useIdGenerator(
    "search",
    `home-office-${job.positionId}`,
    true,
  );
  const submitResume = () => {
    // TODO: handle submitResume
  };
  let url = `details/${job.positionId}/${job.transformedPostingTitle}`;
  if (job.team && job.team.teamCode) {
    url = `${url}?team=${job.team.teamCode}`;
  }

  const locationPickerUrl = `details/${job.positionId}/${job.transformedPostingTitle}/${APP_CONSTANTS.LOCATION_PICKER_RELATIVE_PATH_NAME}`;
  const referAFriendUrl = `erp/?roleId=${job.positionId}`;

  return (
    <>
      <div
        id={useIdGenerator("search", `${id}`, false)}
        className="large-12 job-list-item row mb-40 pt-10"
        aria-labelledby={`trigger-${id}`}
      >
        <div className="large-6 job-content-links">
          <ul role="list" className="ml-0">
            <li role="listitem">
              <LocaleAwareLink
                link={url}
                text={t("jobsite.search.fullRoleDesc") as string}
                ariaLabel={`${t("jobsite.search.fullRoleDesc") as string}: ${job.postingTitle}`}
                state={searchLocation}
                type="more"
              ></LocaleAwareLink>
            </li>
            {job.managedPipelineRole ? (
              <li role="listitem">
                <LocaleAwareLink
                  link={locationPickerUrl}
                  text={t("jobsite.search.whereWeAreHiring") as string}
                  state={searchLocation}
                  type="more"
                  ariaLabel={`${t("jobsite.search.whereWeAreHiring") as string} ${job.positionId && job.postingTitle ? `${job.postingTitle} ${job.positionId}` : ""}`}
                ></LocaleAwareLink>
              </li>
            ) : null}
            {!isInternal ? (
              <li role="listitem">
                <ShareJobComponent
                  jobData={job}
                  jobId={job.positionId}
                  localeCode={appUIState.appData.locale}
                  size="small"
                  type={"text-icon"}
                  id={`search-${job.positionId}`}
                />
              </li>
            ) : null}
            {isInternal ? (
              <li role="listitem">
                <LocaleAwareLink
                  link={referAFriendUrl}
                  addAppPrefix={true}
                  text={t("jobsite.common.referAFriendLabel") as string}
                  state={searchLocation}
                  type="more"
                ></LocaleAwareLink>
              </li>
            ) : null}
          </ul>
        </div>
        <div className="large-6 job-content-links">
          <ul role="list" className="ml-0">
            <li role="listitem" className="mb-5 d-flex flex-wrap">
              <span className={"fw-medium"}>
                {t("jobsite.jobdetails.roleNumber")}{" "}
              </span>
              &nbsp;
              <span
                id={useIdGenerator("search", `role-number_${job.reqId}`, true)}
                className="job-content-basic-info"
              >
                {job.positionId}
              </span>
            </li>
            {job.standardWeeklyHours ? (
              <li role="listitem" className="mb-5 d-flex flex-wrap">
                <span
                  className={"fw-medium"}
                >{`${t("jobsite.search.rowRetailHours")}`}</span>
                &nbsp;
                <span id={weeklyHoursId} className="job-content-basic-info">
                  {job.standardWeeklyHours
                    ? (t("jobsite.jobdetails.standardHours", {
                        hours: job.standardWeeklyHours,
                      }) as string)
                    : ""}
                </span>
              </li>
            ) : null}
            {isInternal || job.homeOffice ? (
              <li role="listitem" className="mb-5 d-flex flex-wrap">
                <span
                  className={"fw-medium"}
                >{`${t("jobsite.jobdetails.jobHomeOffice")}`}</span>
                &nbsp;
                <span id={homeOfficeId} className="job-content-basic-info">
                  {job.homeOffice
                    ? t("jobsite.common.yes")
                    : t("jobsite.common.no")}
                </span>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
      <div
        id={useIdGenerator("search", `${id}-job-summary`, true)}
        className="job-list-item pb-30 row"
        aria-labelledby={`trigger-${id}`}
      >
        <p className="text-align-start pb-20 pt-10 column large-12">
          <HtmlRenderer
            initialContent={textTruncate(job.jobSummary)}
            htmlTag="span"
          />
        </p>
        <span className="column">
          <RoleSubmitButton
            id={useIdGenerator("search", `${id}-submit-role`, true)}
            managedPipelineRole={job.managedPipelineRole}
            submitted={job.submitted || false}
            resubmitted={job.resubmitted || false}
            disabled={false}
            jobId={job.positionId}
            handleSubmitResume={submitResume}
            transformedPostingTitle={job.transformedPostingTitle}
            postingTitle={job.postingTitle}
          ></RoleSubmitButton>
        </span>
      </div>
    </>
  );
}
