import React, { useCallback } from "react";
import {
  ESCAPE_SEQUENCE_REGEX,
  HandleKeyDownProps,
  TypeaheadCssClasses,
} from "./types";

export function useTypeahead<T>() {
  const renderSuggestionWithHighlight = useCallback(
    (
      getSuggestionLabel: (suggestion: T) => string,
      suggestion: T,
      highlightMatches: boolean,
      query: string,
      classNames: TypeaheadCssClasses,
    ) => {
      const suggestionLabel = getSuggestionLabel(suggestion);
      if (!highlightMatches || !query) return suggestionLabel;
      const newQuery = query
        .trim()
        .replace(ESCAPE_SEQUENCE_REGEX(), (s) => `\\${s}`);
      const parts = suggestionLabel.split(new RegExp(`(${newQuery})`, "gi"));
      return (
        <>
          {parts.map((part, index) =>
            part.toLowerCase() === query.toLowerCase() ? (
              <span key={index} className={classNames.highlight}>
                {part}
              </span>
            ) : (
              part
            ),
          )}
        </>
      );
    },
    [],
  );

  const scrollToItem = useCallback(
    (
      suggestionsRef: React.RefObject<HTMLOListElement | HTMLUListElement>,
      index: number,
    ) => {
      if (suggestionsRef.current) {
        const item = suggestionsRef.current?.children[index];
        if (item) {
          const containerRect = suggestionsRef.current.getBoundingClientRect();
          const itemRect = item.getBoundingClientRect();
          const bufferZoneHeight = 121; // Adjust this value for the desired buffer

          // Calculate scroll offset relative to container's current scroll position
          const scrollOffset =
            itemRect.top - containerRect.top + suggestionsRef.current.scrollTop;
          // Scroll to the calculated offset
          // Scroll up only if the item is above the buffer zone
          if (scrollOffset > bufferZoneHeight) {
            suggestionsRef.current.scrollTo({
              top: scrollOffset,
              behavior: "smooth",
            });
            // Scroll down only if the item is below the buffer zone
          } else {
            suggestionsRef.current.scrollTo({
              top: scrollOffset - (containerRect.height - itemRect.height), // Adjust scroll to align item to bottom
              behavior: "smooth",
            });
          }
        }
      }
    },
    [],
  );

  // Handle key presses for keyboard navigation and Enter to submit custom input or select suggestion
  const handleKeyDown = useCallback(
    ({
      e,
      isOpen,
      strict,
      suggestionsRef,
      suggestions,
      highlightedIndex,
      setHighlightedIndex,
      handleSuggestionSelect,
      handleCustomInputSelect,
      inputValue,
      query,
      setInputValue,
      inputRef,
      setIsOpen,
      getSuggestionLabel,
    }: HandleKeyDownProps<T>) => {
      // Handle Enter key action outside the `isOpen` block
      if (e.key === "Enter") {
        e.preventDefault();
        if (isOpen) {
          // Handle Enter when dropdown is open
          if (strict) {
            // In strict mode, select the first suggestion if none is highlighted
            if (
              highlightedIndex >= 0 &&
              highlightedIndex < suggestions.length
            ) {
              handleSuggestionSelect(suggestions[highlightedIndex]);
            } else if (suggestions.length > 0) {
              handleSuggestionSelect(suggestions[0]);
            }
          } else {
            // If not strict, allow submission of custom input
            if (
              highlightedIndex >= 0 &&
              highlightedIndex < suggestions.length
            ) {
              handleSuggestionSelect(suggestions[highlightedIndex]);
            } else {
              handleCustomInputSelect(inputValue);
            }
          }
        } else {
          // Handle Enter when dropdown is closed and only on non-strict mode
          if (!strict) handleCustomInputSelect(inputValue);
        }

        return; // Exit after handling Enter to prevent further processing
      }
      // Handle other keys only if the dropdown is open
      if (isOpen) {
        switch (e.key) {
          case "ArrowDown":
            e.preventDefault();
            setHighlightedIndex((prevIndex) => {
              const newIndex =
                prevIndex < suggestions.length - 1
                  ? prevIndex + 1
                  : suggestions.length - 1; // Assigning last index when it reaches the end instead of 0 so that there will be no loop
              scrollToItem(suggestionsRef, newIndex); // Scroll after updating the index
              setInputValue(getSuggestionLabel(suggestions[newIndex]));
              return newIndex;
            });
            break;
          case "ArrowUp":
            e.preventDefault();
            setHighlightedIndex((prevIndex) => {
              const newIndex = prevIndex > 0 ? prevIndex - 1 : -1;
              if (newIndex > -1) {
                scrollToItem(suggestionsRef, newIndex); // Scroll after updating the index
                setInputValue(getSuggestionLabel(suggestions[newIndex]));
              } else {
                // If up arrow is clicked when we are on input field or at the first option the initial user
                // query should be retained and focus should move to the input element
                setInputValue(query);
                inputRef.current?.focus();
              }
              return newIndex;
            });
            break;
          case "Escape":
            setIsOpen(false);
            setHighlightedIndex(-1);
            inputRef.current?.focus(); // Return focus to input
            break;
          case "Tab":
            setIsOpen(false); // Close dropdown on Tab
            break;
          case "End":
            e.preventDefault();
            setHighlightedIndex(suggestions.length - 1); // Go to last suggestion
            break;
          case "Home":
            e.preventDefault();
            setHighlightedIndex(0); // Go to first suggestion
            break;
          default:
            break;
        }
      }
    },
    [scrollToItem],
  );

  return {
    renderSuggestionWithHighlight,
    handleKeyDown,
  };
}
