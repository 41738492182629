import { isEmpty } from "lodash";
import { getNextSection } from "../utils";
import { MyApplicationAction } from "./actionTypes";
import { MyApplicationState } from "./state";
import { ApplicationSection, ReviewSection } from "./types";
import { COUNTRY_USA } from "../../../../app.constants";
import { Talent } from "../../../../../shared/types/talent/talent";
import { MyApplication } from "../../../../../shared/types/talent/myApplication";

export function getInitialState(): MyApplicationState {
  return {
    page: {
      stepNames: [
        "selfDisclosure",
        "application",
        "informationRelease",
        "reviewSubmit",
      ],
      firstStep: "selfDisclosure",
      stepMapping: {
        selfDisclosure: 1,
        application: 2,
        informationRelease: 3,
        reviewSubmit: 4,
      },
    },
    isPageDataLoading: true,
    pageError: {
      applicationDisabled: false,
    },
    showPageErrorBanner: false,
    submitted: false,
    selfDisclosureStep: {
      canSave: true,
      title: "jobsite.profile.myApplication.selfDisclosure",
    },
    applicationStep: {
      canSave: false,
      contactInformation: "create",
      employmentBackground: "disabled",
      education: "disabled",
      professionalExperience: "disabled",
      references: "disabled",
      legal: "disabled",
    },
    reviewStep: {
      contactInformation: "view",
      employmentBackground: "view",
      education: "view",
      professionalExperience: "view",
      references: "view",
      legal: "view",
      selfDisclosure: "view",
      infoRelease: "view",
      canSubmit: true,
    },
    contactInfoSection: {
      title: "jobsite.profile.myApplication.contactInfoLabel",
    },
    educationSection: {
      title: "jobsite.profile.myApplication.education",
    },
    employmentSection: {
      title: "jobsite.profile.myApplication.employmentBck",
    },
    professionSection: {
      title: "jobsite.profile.myApplication.profExp",
    },
    referencesSection: {
      title: "jobsite.profile.myApplication.references",
    },
    legalSection: {
      title: "jobsite.profile.myApplication.legal",
    },
    infoReleaseStep: {
      title: "jobsite.profile.myApplication.infoRelease",
      canSave: true,
      ack: false,
    },
    reviewSubmitStep: {
      canSave: true,
    },
  };
}

export const reducer = (
  state: MyApplicationState,
  action: MyApplicationAction,
): MyApplicationState => {
  switch (action.type) {
    case "SET_CURRENT_STEP":
      const payload = action.payload;
      return {
        ...state,
        currentStep: payload,
      };
    case "SET_PAGE_LOADING": {
      const payload = action.payload;
      return { ...state, isPageDataLoading: payload };
    }
    case "SET_PAGE_LOADING": {
      const payload = action.payload;
      return { ...state, showPageErrorBanner: payload };
    }
    case "SET_TALENT": {
      const payload = action.payload;
      return {
        ...state,
        talent: payload,
        submitted:
          !isEmpty(payload.applicationId) && payload.resetStatus === false,
        isPageDataLoading: false,
        showPageErrorBanner: false,
      };
    }
    case "SET_COUNTRY_SPECIFIC_SELFDISCLOSURE_CONFIG": {
      const payload = action.payload;
      return {
        ...state,
        selfDisclosureConfig: payload,
      };
    }
    case "SET_MY_APPLICATION": {
      const payload = action.payload;
      return {
        ...state,
        application: payload,
      };
    }
    case "SET_MY_APP_USER_SELF_DISCLOSURE": {
      const payload = action.payload;
      return {
        ...state,
        selfDisclosureStep: {
          canSave: true,
          title: state.selfDisclosureStep.title,
          selfDisclosure: payload,
        },
      };
    }
    case "SET_APPLICATION_STEP_REFDATA": {
      const payload = action.payload;
      return {
        ...state,
        applicationStep: {
          ...state.applicationStep,
          refData: payload,
        },
      };
    }
    case "ON_APPLICATION_SECTION_EDIT": {
      const { sectionName } = action.payload;
      const { applicationStep, reviewStep, currentStep } = state;
      const updatedState = {
        ...state,
      };

      if (currentStep === "application") {
        updatedState.applicationStep = {
          ...applicationStep,
        };
        updatedState.applicationStep[sectionName as ApplicationSection] =
          "edit";
      }

      if (currentStep === "reviewSubmit") {
        updatedState.reviewStep = {
          ...reviewStep,
        };
        updatedState.reviewStep[sectionName as ReviewSection] = "edit";
      }
      return updatedState;
    }
    case "ON_APPLICATION_STEP_SAVE": {
      const application = action.payload;
      const updatedState = {
        ...state,
        application,
      };
      return updatedState;
    }
    case "ON_APPLICATION_SECTION_SAVE": {
      const { sectionName, application } = action.payload;
      const { applicationStep, reviewStep, currentStep } = state;

      const updatedState = {
        ...state,
        application,
      };

      if (currentStep === "application") {
        updatedState.applicationStep = {
          ...applicationStep,
        };
        if (sectionName !== "legal") {
          updatedState.applicationStep[sectionName as ApplicationSection] =
            "view";
          if (
            updatedState.applicationStep[
              getNextSection(sectionName as ApplicationSection)
            ] === "disabled"
          ) {
            updatedState.applicationStep[
              getNextSection(sectionName as ApplicationSection)
            ] = "create";
          }
        } else {
          updatedState.applicationStep[sectionName] = "edit";
          updatedState.applicationStep.canSave = true;
        }
      }

      if (currentStep === "reviewSubmit") {
        updatedState.reviewStep = {
          ...reviewStep,
        };
        updatedState.reviewStep[sectionName] = "view";
      }

      return updatedState;
    }
    case "ON_APPLICATION_SECTION_CANCEL": {
      const { sectionName } = action.payload;
      const { applicationStep, reviewStep, currentStep } = state;

      const updatedState = {
        ...state,
      };

      if (currentStep === "application") {
        updatedState.applicationStep = {
          ...applicationStep,
        };
        if (sectionName !== "legal") {
          updatedState.applicationStep[sectionName as ApplicationSection] =
            "view";
        }
      }

      if (currentStep === "reviewSubmit") {
        updatedState.reviewStep = {
          ...reviewStep,
        };
        updatedState.reviewStep[sectionName] = "view";
      }
      return updatedState;
    }
    case "ON_APP_SEC_CONTACT_INFO_SAVE": {
      const payload = action.payload;
      return {
        ...state,
        contactInfoSection: {
          ...state.contactInfoSection,
          contact: payload.contact,
          addresses: payload.addresses,
        },
      };
    }
    case "ON_APP_SEC_EMP_BCK_SAVE": {
      const payload = action.payload;
      return {
        ...state,
        employmentSection: {
          ...state.employmentSection,
          employeeBackground: payload,
        },
      };
    }
    case "ON_APP_SEC_EDU_SAVE": {
      const payload = action.payload;
      return {
        ...state,
        educationSection: {
          ...state.educationSection,
          educationDegree: payload,
        },
      };
    }
    case "ON_APP_SEC_PROF_EXP_SAVE": {
      const payload = action.payload;
      return {
        ...state,
        professionSection: {
          ...state.professionSection,
          employmentHasAtLeastOneJob: payload.employmentHasAtLeastOneJob,
          employments: payload.employments,
        },
      };
    }
    case "ON_APP_SEC_REF_SAVE": {
      //const payload = action.payload;
      return {
        ...state,
      };
    }
    case "ON_APP_SEC_SD_SAVE": {
      const selfDisclosureEncrypted = action.payload.selfDisclosureEncrypted;
      const selfDisclosure = action.payload.selfDisclosure;
      return {
        ...state,
        application: {
          ...(state.application as MyApplication),
          selfDisclosure: { ...selfDisclosureEncrypted },
        } as MyApplication,
        talent: {
          ...state.talent,
          selfDisclosures: {
            ...state.talent?.selfDisclosures,
            [COUNTRY_USA]: { ...selfDisclosure },
          },
        } as Talent,
      };
    }
    case "ON_APP_SEC_LEGAL_SAVE": {
      const payload = action.payload;
      return {
        ...state,
        legalSection: {
          ...state.legalSection,
          legal: payload,
        },
      };
    }
    case "ON_INFO_RELEASE_STEP_SAVE": {
      const application = action.payload;
      const updatedState = {
        ...state,
        application,
      };
      return updatedState;
    }
    case "ON_APPLICATION_SUBMIT": {
      const application = action.payload;
      const updatedState = {
        ...state,
        application,
        submitted: true,
      };
      return updatedState;
    }
    default:
      return state;
  }
};
