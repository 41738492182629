import Accordion from "@rpe-js/marcom-web-components/lib/CustomAccordion/CustomAccordion";
import React, { useContext } from "react";
import AppContext from "../../AppContext";
import { SEARCH_FILTER_TYPE } from "../../contexts/SearchContext";
import { useSearchContext } from "../../hooks/useSearchContext";
import { HiringManagerFilterWrapper } from "./HiringManagerFilterWrapper";
import { InternalJobLevelFilterWrapper } from "./InternalJobLevelFilterWrapper";
import { InternalStoreRolesFilterWrapper } from "./InternalStoreRolesFilterWrapper";
import { InternalWeeklyHoursFilterWrapper } from "./InternalWeeklyHoursFilterWrapper";
import KeywordFilterWrapper from "./KeywordFilterWrapper";
import { LanguageFilterWrapper } from "./LanguageFilterWrapper";
import { LocationFilterWrapper } from "./LocationFilterWrapper";
import { ProductsFilterWrapper } from "./ProductsFilterWrapper";
import TeamsFilterWrapper from "./TeamsFilterWrapper";

interface FiltersSectionAccordionProps {
  onMobileFilterChange?: (
    filterType: SEARCH_FILTER_TYPE,
    filterValue: any,
  ) => void; // specific for mobile usecase
}

function FiltersSectionAccordion({
  onMobileFilterChange,
}: FiltersSectionAccordionProps) {
  const { state } = useSearchContext();
  const { appUIState } = useContext(AppContext);
  const isInternal = appUIState.appData.isInternal;
  return (
    // As the Accordion items have titleWrapperTag we do not need to add any heading here
    <Accordion compact={true} id={"filter"}>
      <LocationFilterWrapper
        locationsFilterData={state?.filters.locations || []}
        homeOfficeFilterData={state?.filters?.homeOffice || false}
        onMobileFilterChange={onMobileFilterChange}
      />
      <KeywordFilterWrapper
        keywordFilterData={state?.filters?.keywords || []}
        onMobileFilterChange={onMobileFilterChange}
      />
      <TeamsFilterWrapper
        teamsFilterData={state?.filters?.teams || []}
        onMobileFilterChange={onMobileFilterChange}
      ></TeamsFilterWrapper>
      <ProductsFilterWrapper
        productsData={state?.refData?.products || []}
        productFilterData={state?.filters?.products || []}
        onMobileFilterChange={onMobileFilterChange}
      />
      <LanguageFilterWrapper
        languageFilterData={state?.filters?.languages || []}
        onMobileFilterChange={onMobileFilterChange}
      />

      {isInternal && (
        <>
          <InternalStoreRolesFilterWrapper
            rolesData={state?.refData?.roles || []}
            retailRolesFilterData={state?.filters?.retailRoles || []}
            onMobileFilterChange={onMobileFilterChange}
          />
          <HiringManagerFilterWrapper
            hiringManagerFilterData={state?.filters?.hiringManagers || []}
            onMobileFilterChange={onMobileFilterChange}
          />
          <InternalWeeklyHoursFilterWrapper
            minimumHours={state?.filters?.minimumHours || ""}
            maximumHours={state?.filters?.maximumHours || ""}
            onMobileFilterChange={onMobileFilterChange}
          />
          <InternalJobLevelFilterWrapper
            internalJobLevelFilterData={state?.filters?.jobLevel || []}
            onMobileFilterChange={onMobileFilterChange}
          />
        </>
      )}
    </Accordion>
  );
}

export default FiltersSectionAccordion;
