import React from "react";
import { ApplyStepWrapper } from "./ApplyStepWrapper";
import { ApplyProvider } from "./context/ApplyProvider";

export function Apply() {
  return (
    <>
      <ApplyProvider>
        <ApplyStepWrapper></ApplyStepWrapper>
      </ApplyProvider>
    </>
  );
}
