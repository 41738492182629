import { TextButton } from "@rpe-js/marcom-web-components";
import { isEmpty } from "lodash";
import React, { useCallback, useContext } from "react";
import { Role } from "../../../../shared/types/role";
import { isYes } from "../../../../shared/utils/roles";
import { deleteTalentRole } from "../../../api/fetchClient";
import AppContext from "../../../AppContext";
import FavoriteComponent from "../../../components/feature/FavoriteComponent";
import LocaleAwareLink, {
  LocaleAwareDateFormat,
} from "../../../components/feature/LocaleAwareLink";
import RoleSubmitButton from "../../../components/feature/RoleSubmitButton";
import { useAppAlertContext } from "../../../contexts/AppAlert";
import { useCurrentUserContext } from "../../../CurrentUserContext";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { useRolesContext } from "./RolesContext";
import { deriveLocationName } from "./roleUtils";
import { RoleSectioName, RoleUIObj } from "./types";

export interface RoleItem {
  title: string;
}

export interface RoleInformationProps {
  roles: Role[];
  sectionName: RoleSectioName;
}

interface RoleCardProps {
  roleItem: RoleUIObj;
}

function RenderRoleActionDate(props: { roleItem: RoleUIObj }) {
  const { roleItem } = props;
  const { t } = useIntlMessage();
  return (
    <>
      {roleItem.isFavorite &&
        !roleItem.isSubmitted &&
        !roleItem.isStatusClosed && (
          <div className="t-caption mb-10">
            <span>
              <strong>{t("jobsite.profile.yourRoles.favorite")} - </strong>
            </span>
            <span>
              <LocaleAwareDateFormat dateInMillis={roleItem.favoritedDate} />
            </span>
          </div>
        )}
      {((roleItem.isFavorite &&
        roleItem.isSubmitted &&
        !roleItem.isStatusClosed) ||
        (roleItem.isSubmitted && !roleItem.isStatusClosed)) && (
        <div className="t-caption mb-10">
          {roleItem.displaySubmitDate && (
            <div>
              <span id={`role-favorite-${roleItem.positionID}`}>
                <strong>{t("jobsite.common.submitted")} - </strong>
              </span>

              <span>
                <LocaleAwareDateFormat
                  dateInMillis={roleItem.getDisplaySubmittedDate || 0}
                />
              </span>
            </div>
          )}
          {roleItem.displayFavoriteDate && (
            <div className="t-caption mb-10">
              {roleItem.displayFavAndNotResetted && (
                <span id={`role-favorite-${roleItem.positionID}`}>
                  <strong>{t("jobsite.profile.yourRoles.favorite")} - </strong>
                </span>
              )}
              {roleItem.displayIfNotFavOrResetted && (
                <span id={`role-favorite-${roleItem.positionID}`}>
                  <strong>{t("jobsite.common.submitted")} - </strong>
                </span>
              )}

              {roleItem.displayFavAndNotResetted && (
                <span>
                  <LocaleAwareDateFormat
                    dateInMillis={roleItem.favoritedDate}
                  />
                </span>
              )}
              {roleItem.displayIfNotFavOrResetted && (
                <span>
                  <LocaleAwareDateFormat
                    dateInMillis={roleItem.getDisplaySubmittedDate || 0}
                  />
                </span>
              )}
            </div>
          )}
        </div>
      )}
      {roleItem.isStatusClosed && (
        <div className="t-caption">
          <span id={`role-notavailable-${roleItem.positionID}`}>
            <strong>{t("jobsite.profile.yourRoles.notAvailable")} - </strong>
          </span>
          {roleItem.submittedDate > roleItem.favoritedDate && (
            <span>
              <LocaleAwareDateFormat
                dateInMillis={roleItem.getDisplaySubmittedDate || 0}
              />
            </span>
          )}
          {roleItem.submittedDate <= roleItem.favoritedDate &&
            roleItem.isFavorite && (
              <span>
                <LocaleAwareDateFormat dateInMillis={roleItem.favoritedDate} />
              </span>
            )}
          {roleItem.submittedDate <= roleItem.favoritedDate &&
            !roleItem.isFavorite && (
              <span>
                <LocaleAwareDateFormat
                  dateInMillis={roleItem.getDisplaySubmittedDate || 0}
                />
              </span>
            )}
        </div>
      )}
    </>
  );
}

export function RoleCard(props: RoleCardProps) {
  const { roleItem } = props;

  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const { state, dispatch } = useRolesContext();
  const { dispatch: currentUserDispatch } = useCurrentUserContext();
  const { talentId } = state;
  const { updateAlert, deleteAlert } = useAppAlertContext();
  const {
    appUIState: {
      appData: { countryCode },
    },
  } = useContext(AppContext);

  const handleFavoriteChange = useCallback(
    (favorited: boolean) => {
      const roleCount = state.roles?.length;
      dispatch({
        type: "UPDATE_FAVORITE_ROLE",
        payload: {
          roleItem,
          favorited,
        },
      });
      if (!favorited && !roleItem.isSubmitted) {
        currentUserDispatch({
          type: "SET_ROLES_COUNT",
          payload: roleCount - 1,
        });
      }
    },
    [currentUserDispatch, dispatch, roleItem, state.roles?.length],
  );

  const removeRole = useCallback(async () => {
    try {
      deleteAlert();
      const roleCount = state.roles?.length;
      await deleteTalentRole(talentId, roleItem.jobPositionID);
      dispatch({
        type: "REMOVE_INACTIVE_ROLE",
        payload: roleItem,
      });
      // set total roles count once the inactive role is removed
      currentUserDispatch({
        type: "SET_ROLES_COUNT",
        payload: roleCount - 1,
      });
    } catch (e) {
      updateAlert(false);
    }
  }, [
    currentUserDispatch,
    deleteAlert,
    dispatch,
    roleItem,
    state.roles?.length,
    talentId,
    updateAlert,
  ]);

  const postingTitleInUrl = roleItem.postingTitle
    .replace(/[^a-zA-Z0-9,&]/g, " ")
    .replace(/\s+/g, "-");
  const mapRoleLocation = (location: Record<string, Array<object>>) => {
    if (location.hasOwnProperty(countryCode?.data as string)) {
      const firstArray = location[countryCode?.data as string];
      if (firstArray.length > 0) {
        return firstArray[0];
      }
    }
    // Default fallback
    const allLocations = Object.values(location) as any;
    if (allLocations.length > 0 && allLocations[0].length > 0) {
      return allLocations[0][0];
    }
    return undefined;
  };
  return (
    <div
      className="row large-12 small-12 u-border-bottom rolecard"
      role="listitem"
    >
      <div className="large-8 small-12 row">
        <div className="d-flex-equal">
          <RenderRoleActionDate roleItem={roleItem} />
          {!roleItem.isStatusClosed && (
            <h3 className="t-eyebrow-reduced word-break-break-word">
              <LocaleAwareLink
                type="standalone"
                text={roleItem.postingTitle}
                link={`details/${roleItem.positionID}/${postingTitleInUrl}`}
              />
            </h3>
          )}
          {roleItem.isStatusClosed && (
            <h3 className="t-eyebrow-reduced label-grey">
              {roleItem.postingTitle}
            </h3>
          )}
          <div id={`role-location-${roleItem.positionID}`}>
            {!roleItem.roleLocation &&
              !isEmpty(roleItem.location) &&
              countryCode?.data &&
              roleItem.location[countryCode?.data]?.map((loc, idx) => {
                return (
                  <>
                    <span key={`role-location-${roleItem.positionID}-${idx}`}>
                      {deriveLocationName(loc)}
                    </span>
                    {idx < roleItem.location[countryCode?.data]?.length - 1 && (
                      <span>, </span>
                    )}
                  </>
                );
              })}
            {roleItem.roleLocation && (
              <span>
                {t("jobsite.profile.yourRoles.variousLocationsWithin", {
                  locationName: deriveLocationName(
                    mapRoleLocation(roleItem.location),
                  ),
                })}
              </span>
            )}
          </div>
          <div id={`role-team-${roleItem.positionID}`}>
            {(roleItem.teams || []).map((team, idx) => (
              <span
                key={`role-team-${roleItem.positionID}-${idx}`}
                id={`role-team-${roleItem.positionID}-${idx}`}
              >
                {team.teamName}
                {idx < (roleItem.teams || []).length - 1 && (
                  <span>,&nbsp;</span>
                )}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className="rolecard-actions-section">
        <div className="favorite-wrapper">
          <FavoriteComponent
            iconSize="small"
            positionId={roleItem.jobPositionID}
            isFavorited={roleItem.isFavorite || false}
            handleFavoriteChange={handleFavoriteChange}
            id={`favorite-${roleItem.positionID}`}
            postingTitle={roleItem.postingTitle}
            disableActions={roleItem.isStatusClosed}
          ></FavoriteComponent>
        </div>
        <div className="rolecard-actions">
          <RoleSubmitButton
            id="role-submit-resume"
            managedPipelineRole={false}
            postingTitle={roleItem.postingTitle}
            resubmitted={roleItem.reSubmit as boolean}
            submitted={roleItem.isSubmitted}
            jobId={roleItem.jobPositionID}
            handleSubmitResume={() => {}}
            transformedPostingTitle={roleItem.postingTitle}
            disabled={
              (roleItem.isStatusClosed && !roleItem.isSubmitted) ||
              (roleItem.isSubmitted &&
                roleItem.reSubmit === true &&
                roleItem.isStatusClosed)
            }
            showLinkOnMobileView={true}
          />
          {roleItem.isStatusClosed && (
            <TextButton
              id={`js-role-remove-${roleItem.positionID}`}
              label={t("jobsite.common.remove") as string}
              onClick={removeRole}
              aria-label={`${t("jobsite.common.remove")} ${roleItem.postingTitle}`}
              classes={`t-body-reduced mt-15 ${isMobile ? "d-block" : ""}`}
            />
          )}
          {!isEmpty(roleItem.questionnaireTemplateID) &&
            (isYes(roleItem.submitted) || isYes(roleItem.resubmitted)) && (
              <div className="pt-10 pb-10 t-body-reduced">
                <TextButton
                  id={`js-questionnaire-link-${roleItem.positionID}`}
                  label={
                    (!roleItem.reset
                      ? t("jobsite.profile.yourRoles.viewQuestionnaire")
                      : t(
                          "jobsite.profile.yourRoles.questionnaireEdit",
                        )) as string
                  }
                  aria-label={`${
                    !roleItem.reset
                      ? t("jobsite.profile.yourRoles.viewQuestionnaire")
                      : t("jobsite.profile.yourRoles.questionnaireEdit")
                  } ${roleItem.postingTitle}`}
                  onClick={() => {
                    dispatch({
                      type: "SHOW_QUESTIONAIRE_MODAL",
                      payload: roleItem,
                    });
                  }}
                />
              </div>
            )}
          {roleItem.roleLocation && (
            <TextButton
              id={`js-location-link-${roleItem.positionID}`}
              label={
                t("jobsite.profile.yourRoles.viewselectedlocations") as string
              }
              aria-label={`${t("jobsite.profile.yourRoles.viewselectedlocations")} ${roleItem.postingTitle}`}
              classes={"t-body-reduced"}
              onClick={() => {
                dispatch({ type: "SHOW_LOCATION_MODAL", payload: roleItem });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
