import React, { ReactNode, useCallback, useContext } from "react";
import { IntlShape, useIntl } from "react-intl";
import sanitizeHtml from "sanitize-html";
import { I18nValues } from "../../shared/types";
import { sanitizeOptions } from "../app.constants";
import AppContext from "../AppContext";

// Preprocessing function to convert {{}} to {}
const preprocessMessage = (message: string): string => {
  return message.replace(/{{/g, "{").replace(/}}/g, "}");
};
export default function useIntlMessage() {
  const intl: IntlShape = useIntl();
  const { appUIState } = useContext(AppContext);
  const { disableSanitizeHtml } = appUIState.appData;

  const t = useCallback(
    (id: string, values?: I18nValues) => {
      // Get the raw message from intl;
      const rawMessage: string | undefined = intl.messages[id] as
        | string
        | undefined;
      if (rawMessage) {
        // Preprocess the message to replace {{}} with {}
        let processedMessage: string = preprocessMessage(rawMessage);
        if (!disableSanitizeHtml) {
          // sanitize html
          processedMessage = sanitizeHtml(processedMessage, sanitizeOptions);
        }
        // Use intl to format the message with dynamic value
        return intl.formatMessage(
          { id, defaultMessage: processedMessage },
          {
            ...values,
            span: (chunks: ReactNode) => <span>{chunks}</span>,
          },
        );
      } else {
        // TODO: Handle Error if any
        return "";
      }
    },
    [disableSanitizeHtml, intl],
  );

  return { t };
}
