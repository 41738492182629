import React from "react";
import useIntlMessage from "../../../hooks/useIntlMessage";

interface ShareJobAfterSubmitContentProps {
  success: boolean;
}

export default function ShareJobAfterSubmitContent({
  success,
}: ShareJobAfterSubmitContentProps) {
  const { t } = useIntlMessage();
  const header = success ? "jobsite.search.shareRoleSuccess" : "";
  const body = success
    ? "jobsite.search.emailSent"
    : "jobsite.general.serviceError";
  return (
    <div className={"form-group"}>
      <h2
        id="share-job-header"
        className={"t-headline-reduced text-center share-job-header"}
      >
        {t(header) as string}
      </h2>
      <h3
        className={
          "t-body align-self-center share-job-header mb-20 text-center"
        }
      >
        {t(body) as string}
      </h3>
    </div>
  );
}
