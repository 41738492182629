import { Overlay } from "@rpe-js/marcom-web-components";
import React, { useContext } from "react";
import AppContext from "../../../AppContext";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { idGenerator } from "../../../utils/idGenerator";
import SignedOut from "./SignedOut";

interface SignOutComponentProps {
  showModal: boolean;
  afterSessionTimeout: boolean;
  onClose: () => void;
}

export default function SignOutModal({
  showModal,
  afterSessionTimeout = false,
  onClose,
}: SignOutComponentProps) {
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { locale } = appUIState.appData;
  const isMobile = useIsMobile();
  const idSeqGenerator = idGenerator("signout", "modal");
  const titleId = idSeqGenerator.generateId("title");

  let signoutMessage = t("jobsite.common.signOutThanksMsg", { locale });
  if (afterSessionTimeout) {
    signoutMessage = t("jobsite.common.signOutMessage", { locale });
  }

  return (
    <Overlay
      id={idSeqGenerator.generateId("")}
      elementIdToFocus="" // Not setting any element to focus as on close the callback will refresh the page.
      wide={false}
      visible={showModal}
      onClose={onClose}
      closeButtonAttrs={{
        ariaLabel: t("jobsite.common.close") as string,
      }}
      isFullscreen={isMobile}
      ariaLabel={titleId}
    >
      <SignedOut
        title={t("jobsite.common.signedOut") as string}
        titleId={titleId}
        signoutMessage={signoutMessage}
        linkedInMessage={t("jobsite.common.timeoutLinkedinAlert", {
          locale,
        })}
      ></SignedOut>
    </Overlay>
  );
}
