import { Button } from "@rpe-js/marcom-web-components";
import React, { useContext } from "react";
import { Link } from "react-router";
import AppContext from "../../AppContext";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { getLocationPickerOrApplyUrl } from "../../utils/jobDetailUtil";
interface RoleSubmitButtonProps {
  id: string;
  managedPipelineRole: boolean;
  submitted: boolean;
  resubmitted: boolean;
  transformedPostingTitle: string;
  postingTitle?: string;
  handleSubmitResume: (data: any) => void;
  jobId?: string;
  disabled?: boolean;
  showLinkOnMobileView?: boolean;
}

export default function RoleSubmitButton({
  id,
  managedPipelineRole,
  submitted,
  resubmitted,
  disabled = false,
  jobId,
  transformedPostingTitle,
  handleSubmitResume,
  postingTitle,
  showLinkOnMobileView = false,
}: RoleSubmitButtonProps) {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const alreadyApplied = submitted && !resubmitted;
  const resubmitRequired =
    (submitted && resubmitted) || (managedPipelineRole && submitted);
  const buttonDisabled = disabled || (alreadyApplied && !resubmitRequired);
  const { appUIState } = useContext(AppContext);
  const { locale } = appUIState.appData;
  const buttonHiddenDesc = postingTitle ? postingTitle : "";
  const url = getLocationPickerOrApplyUrl(
    locale,
    jobId as string,
    transformedPostingTitle,
    managedPipelineRole,
  );

  const handleOnSubmit = () => {
    handleSubmitResume({});
  };

  const getButtonText = () => {
    if (resubmitRequired) {
      return t("jobsite.profile.yourRoles.resubmitresume");
    } else if (alreadyApplied) {
      return t("jobsite.profile.yourRoles.submittedresume");
    } else {
      return t("jobsite.profile.yourRoles.submitresume");
    }
  };

  const getButtonColor = (): "neutral" | "primary" => {
    if (buttonDisabled) {
      return "neutral";
    } else {
      return "primary";
    }
  };

  function getLocationPickerOrApplyLink() {
    const url = getLocationPickerOrApplyUrl(
      locale,
      jobId as string,
      transformedPostingTitle,
      managedPipelineRole,
    );
    const buttonText = getButtonText();
    const classNames = buttonDisabled
      ? "role-submit-link disable-role-submit-button"
      : "role-submit-link";

    if (managedPipelineRole) {
      return (
        <Link
          id={id}
          to={url}
          className={classNames}
          aria-disabled={buttonDisabled}
        >
          {buttonText}
          <span className="a11y">{buttonHiddenDesc}</span>
        </Link>
      );
    }
    return (
      <a
        id={id}
        aria-disabled={buttonDisabled}
        className={classNames}
        tabIndex={buttonDisabled ? -1 : undefined}
        href={!buttonDisabled ? url : undefined}
        role="link"
      >
        {buttonText}
        <span className="a11y">{buttonHiddenDesc}</span>
      </a>
    );
  }

  return isMobile && showLinkOnMobileView ? (
    getLocationPickerOrApplyLink()
  ) : (
    <Button
      id={id}
      href={!buttonDisabled ? url : undefined}
      size="base"
      isLinkButton={true}
      disabled={buttonDisabled}
      blockedVariant={true}
      color={getButtonColor()}
      label={getButtonText() as string}
      aria-disabled={buttonDisabled}
      tabIndex={buttonDisabled ? -1 : undefined}
      aria-label={`${getButtonText()}: ${postingTitle ? postingTitle : ""}`}
      onClick={handleOnSubmit}
    />
  );
}
