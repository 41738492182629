import {
  ProgressIndicatorLoader,
  TextButton,
} from "@rpe-js/marcom-web-components";
import { default as React, useCallback, useEffect, useState } from "react";
import {
  EducationDegreeRefData,
  EducationGradStatusRefData,
  EmploymentTypeRefData,
  GraduationStatusRefData,
  MonthsRefData,
  ProficiencyLanguageRefData,
  RefData,
  TeamsEmpTypeDisablityRefdata,
} from "../../../shared/types/refData";
import { TalentFileMetaData } from "../../../shared/types/talent/talentFileMetatdata";
import {
  getEducationGradStatus,
  getLanguagesProficiency,
  getMonths,
  getTalent,
  getTalentFileMetaData,
  getTeamsTalentEmpTypDisability,
} from "../../api/fetchClient";
import { ALERT_WRAPPER_ID, PRIMARY_TYPE } from "../../app.constants";
import { AlertWrapper } from "../../components/base/AlertWrapper";
import { usePageAlertContext } from "../../contexts/PageAlert";
import { useCurrentUserContext } from "../../CurrentUserContext";
import { useFetchMultipleData } from "../../hooks/useFetchMultipleData";
import useIntlMessage from "../../hooks/useIntlMessage";
import useSetPageTitle, { PAGE_TITLES } from "../../hooks/useSetPageTitle";
import { handleScrollToElement } from "../../utils/focusUtil";
import { idGenerator } from "../../utils/idGenerator";
import { setFileMetaData, setProfileData } from "./context/Actions";
import { useProfileContext } from "./context/ProfileContext";
import { getRefData } from "./information/components/utils";
import { ContactInformationSection } from "./information/contact";
import { EductionSummarySection } from "./information/educationSummary";
import { EmployeeSummary } from "./information/employeeSummary";
import { EmploymentTypeSection } from "./information/employmentType";
import { MobilityPreferencesSection } from "./information/mobilityPreferences";
import { PreferredLocationSection } from "./information/preferredLocation";
import { ResumeSection } from "./information/resume";
import { SelfDisclosureSection } from "./information/selfDisclosure";
import { SkillsSection } from "./information/skills";
import { SpokenLanguagesSection } from "./information/spokenLanguages";
import { SupportLinkSection } from "./information/supportLinks";
import { TeamsInterestSection } from "./information/teamsOfInterest";

export function ProfileInformation() {
  const [teamsTalentEmpTypDisability, setTeamsTalentEmpTypDisability] =
    useState<TeamsEmpTypeDisablityRefdata | null>(null);
  const [monthsRefData, setMonthsRefData] = useState<MonthsRefData[] | null>(
    [],
  );
  const [proficiencyLanguage, setProficiencyLanguage] =
    useState<ProficiencyLanguageRefData | null>(null);
  const [educationGradStatus, setEducationGradStatus] =
    useState<EducationGradStatusRefData | null>(null);
  const { currentUser } = useCurrentUserContext();
  const [hideGetStartedBanner, setHideGetStartedBanner] = useState(true);
  const [resumeEdit, setResumeEdit] = useState(false);

  const { t } = useIntlMessage();
  const { data, fetchData, isSuccess, isLoading, isError } =
    useFetchMultipleData([
      getTalent,
      getTalentFileMetaData,
      getTeamsTalentEmpTypDisability,
      getMonths,
      getEducationGradStatus,
      getLanguagesProficiency,
    ]);
  const { state, dispatch } = useProfileContext();
  const { updateAlert } = usePageAlertContext();
  const { setPageTitle } = useSetPageTitle();

  const displayGetStartedAlert = useCallback(() => {
    const resumeData = state?.fileMetaData?.filter(
      (fileData: TalentFileMetaData) => fileData.typeId === PRIMARY_TYPE,
    );
    return (
      !state.discovered &&
      !state.educationDegrees?.length &&
      !state.employments?.length &&
      !state.externalProfiles?.linkedin?.url &&
      !resumeData?.length &&
      currentUser.privacyPolicyAckDate
    );
  }, [
    currentUser.privacyPolicyAckDate,
    state.discovered,
    state.educationDegrees?.length,
    state.employments?.length,
    state.externalProfiles?.linkedin?.url,
    state?.fileMetaData,
  ]);

  const handleAttachResumeClick = () => {
    setResumeEdit(true);
    handleScrollToElement("profile-resume");
  };

  useEffect(() => {
    fetchData([[currentUser.talentId], [currentUser.talentId]]);
    setPageTitle(PAGE_TITLES.PROFILE.INFORMATION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(setProfileData({ ...data[0] }));
      dispatch(setFileMetaData(data[1]));
      setTeamsTalentEmpTypDisability(data[2]);
      setMonthsRefData(data[3]);
      setEducationGradStatus(data[4]);
      setProficiencyLanguage(data[5]);
    }
    if (!isLoading && isError) {
      updateAlert(true, true, t("jobsite.error.talentNotFound") as string);
    }
  }, [data, dispatch, isError, isLoading, isSuccess, t, updateAlert]);

  return (
    <>
      {state?.talentId && !isLoading ? (
        <>
          {displayGetStartedAlert() && hideGetStartedBanner && (
            <AlertWrapper
              remove={{
                closeBtnAriaLabel: t("jobsite.common.closeAlert") as string,
                onRemove: () => setHideGetStartedBanner(false),
                idToFocus: "profile-contact-editButton",
              }}
              classes="mb-30"
              aria-labelledby={idGenerator(
                "profile",
                "getting-started-title",
              ).generateId()}
              id={ALERT_WRAPPER_ID.generateId("getting-started")}
            >
              <>
                <h2
                  className="t-quote-reduced"
                  id={idGenerator(
                    "profile",
                    "getting-started-title",
                  ).generateId()}
                >
                  {t("jobsite.information.getStarted")}
                </h2>
                <p>{t("jobsite.information.getStartedDescription")}</p>
                <TextButton
                  id={idGenerator(
                    "profile",
                    "attach-resume-or-linkedin",
                  ).generateId("button")}
                  label={
                    t("jobsite.information.attachResumeorLinkedin") as string
                  }
                  onClick={handleAttachResumeClick}
                  classes="t-body-reduced underline"
                />
              </>
            </AlertWrapper>
          )}
          <div className="u-border-top pt-30">
            <ContactInformationSection />
            <ResumeSection isEdit={resumeEdit} setEdit={setResumeEdit} />
            <SupportLinkSection />
            <EductionSummarySection
              gradStatusData={
                educationGradStatus?.gradStatus as GraduationStatusRefData[]
              }
              educationDegreesData={
                educationGradStatus?.education as EducationDegreeRefData[]
              }
            />
            <EmployeeSummary
              monthsData={getRefData(
                monthsRefData as RefData[],
                t("jobsite.common.month") as string,
              )}
            />
            <PreferredLocationSection />
            <MobilityPreferencesSection />
            <SelfDisclosureSection
              disabilityData={teamsTalentEmpTypDisability?.disabilityStatus}
            />
            <SkillsSection />
            <SpokenLanguagesSection
              langaugesData={getRefData(
                proficiencyLanguage?.language as RefData[],
                t("jobsite.common.chooseLanguage") as string,
              )}
              proficiencyData={getRefData(
                proficiencyLanguage?.proficiency as RefData[],
                t("jobsite.common.selectProficiency") as string,
              )}
            />
            <TeamsInterestSection
              teamsData={teamsTalentEmpTypDisability?.teams}
            />
            <EmploymentTypeSection
              employmentTypes={
                teamsTalentEmpTypDisability?.employmentType as EmploymentTypeRefData[]
              }
            />
          </div>
        </>
      ) : (
        <ProgressIndicatorLoader showLoading={true} />
      )}
    </>
  );
}
