import React, { useContext, useEffect } from "react";
import APP_CONSTANTS from "../../utilities/appConstants";
import AppContext from "../AppContext";
import HtmlRenderer from "../components/base/HtmlRenderer";
import useDtm from "../hooks/useAdobeAnalytics";
import useIntlMessage from "../hooks/useIntlMessage";

export function UserNotFound({}) {
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { trackPageLoad } = useDtm();

  useEffect(() => {
    const pageInfo = {
      pageName: APP_CONSTANTS.ANALYTICS.PAGE_NAME.LOGGED_OUT,
      locale: appUIState?.appData?.locale || APP_CONSTANTS.DEFAULT_LOCALE,
      title: APP_CONSTANTS.ANALYTICS.PAGE_NAME.LOGGED_OUT,
      isInternal: appUIState?.appData?.isInternal,
    };
    trackPageLoad(pageInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <main>
      <div className={"text-center p-50"}>
        <h2 className="t-eyebrow-elevated">
          {t("jobsite.common.inactiveUserHeading")}
        </h2>
        <HtmlRenderer
          classes="hyperlink-underline"
          initialContent={t("jobsite.common.inactiveUser") as string}
          htmlTag="p"
        />
      </div>
    </main>
  );
}
