import { APP_TYPE } from "@rpe-js/core";
import { decodeUrl } from "../server/utils/decodeUrl";
import { mappedFiltersForService } from "../server/utils/searchUtils/mappedFiltersForService";
import { Role } from "../shared/types/role";
import { getRolesWithUpdatedFlags } from "../shared/utils/roles";
import {
  getJobDetails,
  getRefDataById,
  getRefDataForSearch,
  getTalentRoles,
  search,
} from "./api/fetchClient";
import { findRouteById, getRoutes, RouteID } from "./routes";

function getClientDataLoaderRoutes(
  appBasePath: string,
  appType: APP_TYPE,
  isSessionAuthenticated: boolean,
  talentId?: string,
) {
  const clientDataLoaderRoutes = [...getRoutes(appType)];

  const RootRoute = findRouteById(RouteID.root, clientDataLoaderRoutes);

  const JobDetailsRoute = findRouteById(
    RouteID.jobDetails,
    clientDataLoaderRoutes,
  );

  const SearchList = findRouteById(RouteID.search, clientDataLoaderRoutes);

  const LocationPickerRoute = findRouteById(
    RouteID.locationPicker,
    clientDataLoaderRoutes,
  );

  const ApplyRoute = findRouteById(RouteID.apply, clientDataLoaderRoutes);

  if (RootRoute) {
    RootRoute.loader = async () => {
      return Promise.resolve(
        ((window as any).__staticRouterHydrationData as any).loaderData[
          RouteID.root
        ],
      );
    };
  }

  if (SearchList) {
    SearchList.loader = async ({ params, request }) => {
      try {
        const { locale } = params;
        const requestUrl = new URL(request.url);
        const queryParams = Object.fromEntries(
          requestUrl.searchParams.entries(),
        );
        const {
          refDataSearchFilters,
          dynamicFilters,
          searchQuery,
          sort,
          page,
        } = decodeUrl(queryParams, appType === "internal");
        const refDataFilters = await getRefDataById(
          locale as string,
          refDataSearchFilters,
        );
        const filters = { ...refDataFilters, ...dynamicFilters };
        const filtersForService = mappedFiltersForService(filters, true);
        const response = await search(
          locale as string,
          filtersForService,
          page || 1,
          sort || "newest",
          searchQuery || "",
        );

        let talentRoles: Role[] = [];

        if (isSessionAuthenticated) {
          if (talentId) {
            const roles = await getTalentRoles(talentId);
            talentRoles = roles || [];
          }
        }

        // Map the favorited flag to search results
        let searchResults = response.searchResults;
        if (isSessionAuthenticated && talentRoles.length) {
          searchResults = getRolesWithUpdatedFlags(searchResults, talentRoles);
        }

        // TODO: Figure our a better way for static data required for search
        const serverLoaderData =
          window && (window as any)?.__staticRouterHydrationData
            ? ((window as any).__staticRouterHydrationData as any)?.loaderData[
                RouteID.search
              ]
            : {};
        let staticData = undefined;
        if (serverLoaderData) {
          staticData = serverLoaderData.staticData;
        } else {
          const refData = await getRefDataForSearch();
          staticData = { refData: refData };
        }

        return {
          staticData: {
            ...staticData,
          },
          searchResults,
          totalRecords: response.totalRecords,
          talentRoles,
          queryParams,
          requestUrl,
          filters,
          search: searchQuery || "",
          sort: sort || "",
          page: (page as number) || 1,
          searchMeta: {},
        };
      } catch (err) {
        throw err;
      }
    };
  }

  if (JobDetailsRoute) {
    JobDetailsRoute.loader = async ({ params, request }) => {
      try {
        const { jobId = "", locale = "" } = params;
        const response = await getJobDetails(jobId, locale);
        return { jobsData: response, requestUrl: request.url };
      } catch (error) {
        throw error;
      }
    };
  }

  if (LocationPickerRoute) {
    LocationPickerRoute.loader = async ({ params, request }) => {
      const { jobId = "", locale = "" } = params;
      const response = await getJobDetails(jobId, locale);
      return { jobsData: response, requestUrl: request.url };
    };
  }

  if (ApplyRoute) {
    ApplyRoute.loader = async ({ params }) => {
      const { jobId = "", locale = "" } = params;
      return await getJobDetails(jobId, locale);
    };
  }

  return clientDataLoaderRoutes;
}

export default getClientDataLoaderRoutes;
