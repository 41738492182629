import React from "react";
import useIsMobile from "../../hooks/useIsMobile";
import { idGenerator } from "../../utils/idGenerator";
import InternalJobInformation from "./InternalJobInformation";

interface Props {
  id: string;
  title: string;
  jobDetails: Record<string, any>;
  jobDetailsTranslations: Record<string, string>;
}

function InternalJobInformationContainer(props: Props) {
  const { id, title, jobDetails, jobDetailsTranslations } = props;
  const isMobile = useIsMobile();
  const idGeneratorContainer = idGenerator("jobdetails", id);

  function renderContent() {
    return (
      <div
        id={idGeneratorContainer.generateId()}
        className={`${isMobile ? "" : "py-40 background-fill-tertiary"} row text-left `}
      >
        {!isMobile ? (
          <>
            <aside className={"column large-3 px-10"}>
              {title && (
                <h2
                  id={idGeneratorContainer.generateId("internalinfotitle")}
                  className={"t-label fw-medium"}
                >
                  {title}
                </h2>
              )}
            </aside>
            <div className={"column large-9"}>
              <InternalJobInformation
                id={id}
                jobDetails={jobDetails}
                jobDetailTranslations={jobDetailsTranslations}
              />
            </div>
          </>
        ) : (
          <div className={"column large-12 small-12 pb-40 t-body"}>
            <InternalJobInformation
              id={id}
              jobDetails={jobDetails}
              jobDetailTranslations={jobDetailsTranslations}
            />
          </div>
        )}
      </div>
    );
  }

  return jobDetails ? renderContent() : null;
}

export default InternalJobInformationContainer;
