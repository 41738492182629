import React from "react";
import {
  Answer,
  Question,
  TalentQuestion,
} from "../../../../shared/types/questionnaire";

interface QuestionnaireSectionProps {
  questions: Array<Question> | Array<TalentQuestion>;
  rolesSection?: boolean;
}

export function QuestionnaireSection({
  questions,
  rolesSection = false,
}: QuestionnaireSectionProps) {
  return (
    <>
      {questions?.map((question: Question) => (
        <section
          key={question.questionId}
          className={`${rolesSection ? "mt-20" : "mt-10"}`}
        >
          <h3
            className={`t-body instructions${rolesSection ? "" : " question"}`}
          >
            {question.question}
          </h3>
          {question.questionType == "MULTIPLE" &&
            question.answerOptions &&
            question.answerOptions.map((answerOption: Answer) => {
              return (
                (answerOption.answeredMultiple && (
                  <p key={answerOption.answerId} className="answer t-body">
                    {answerOption.label}
                  </p>
                )) ||
                ""
              );
            })}
          {question.questionType == "TEXT" && (
            <p className="answer t-body">{question.answeredFreeText}</p>
          )}
          {question.questionType == "RANKED" &&
            question.answerOptions &&
            question.answerOptions.map((answerOption: Answer) => {
              return (
                <p key={answerOption.answerId} className="answer t-body">
                  {answerOption.answeredRank} - {answerOption.label}
                </p>
              );
            })}
        </section>
      ))}
    </>
  );
}
