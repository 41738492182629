import { httpStatusCodes as httpStatus } from "@rpe-js/core";
import { v4 as uuidv4 } from "uuid";
import { HttpMethod } from "../../utilities/httpMethods";
import {
  APPLICATION_JSON,
  APPLICATION_OCTET_STREAM,
  DEFAULT_LOCALE,
  DEFAULT_LOCALE_API_HEADER,
} from "../app.constants";
import apiDictionary, { ApiEndpoint } from "./apiDictionary";

// Options for API requests

type AllowedHttpRequestHeaders =
  | "locale"
  | "browserLocale"
  | "responseType"
  | "Content-Type"
  | "Accept"
  | "Accept-Language"
  | "Accept-Encoding"
  | "x-b3-traceid"
  | "postingLocation"
  | "roverEmailLocaleCode"
  | "X-Apple-CSRF-Token";

type ExtendedHeaders = Partial<
  Record<AllowedHttpRequestHeaders, string | null>
>;

export interface RequestOptions<B = BodyInit>
  extends Pick<RequestInit, "redirect"> {
  pathParams?: Record<string, string | number>;
  queryParams?: Record<string, string>;
  body?: B;
  headers?: ExtendedHeaders;
  handleRedirect?: boolean;
}

// Response type for API calls
export interface ApiResponse<R = any> {
  success: boolean;
  status: number;
  data?: R;
  error?: string;
  code?: string; // added to handle error code from api
}

// API Service structure
type ApiService = Record<
  string,
  Record<
    string,
    {
      get: <R>(options?: RequestOptions) => Promise<ApiResponse<R>>;
      post: <R, B>(options: RequestOptions<B>) => Promise<ApiResponse<R>>;
      put: <R, B>(options: RequestOptions<B>) => Promise<ApiResponse<R>>;
      delete: (options?: RequestOptions) => Promise<ApiResponse>;
    }
  >
>;
// TODO: CSRF Token
const csrfToken = "";

// Helper function to build URLs with path and query params
const buildUrl = (
  baseUrl: string,
  url: string,
  pathParams?: Record<string, string | number>,
  queryParams?: Record<string, any>,
): string => {
  let builtUrl = `${baseUrl}${url}`;

  // Replace path params
  if (pathParams) {
    Object.keys(pathParams).forEach((param) => {
      builtUrl = builtUrl.replace(`:${param}`, String(pathParams[param]));
    });
  }

  // Append query params
  if (queryParams) {
    const queryString = new URLSearchParams(queryParams).toString();
    builtUrl += `?${queryString}`;
  }

  return builtUrl;
};

// Helper function to handle API responses
const handleResponse = async <R>(
  response: Response,
): Promise<ApiResponse<R>> => {
  try {
    const data = await response.json();
    if (response.ok) {
      return { success: true, status: response.status, data: data.res };
    } else {
      return {
        success: false,
        status: response.status,
        error: data.error || "API error",
        code: data?.data?.code ? data.data.code : "",
      };
    }
  } catch (error) {
    if (error instanceof Error) {
      const errorMessage = error.message || "Internal Server Error";
      const errorStack = error.stack || "No stack trace available";
      // eslint-disable-next-line no-console
      console.error(`API response handling error: ${errorMessage}`, errorStack);

      return {
        success: false,
        status: response.status || httpStatus.INTERNAL_SERVER_ERROR,
        error: `${errorMessage} - Stack: ${errorStack}`,
      };
    } else {
      return {
        success: false,
        status: response.status || httpStatus.INTERNAL_SERVER_ERROR,
        error: "Unknown error occurred",
      };
    }
  }
};

function buildHeaders(
  dataLocale: string,
  browserLocale: string,
  extendedHeader: ExtendedHeaders = {},
) {
  const headers: ExtendedHeaders = {
    locale: dataLocale || DEFAULT_LOCALE_API_HEADER,
    browserLocale: browserLocale || DEFAULT_LOCALE,
    "Content-Type": APPLICATION_JSON,
    "X-Apple-CSRF-Token": csrfToken,
    "x-b3-traceid": uuidv4(),
  };
  const finalHeaders = {} as Record<AllowedHttpRequestHeaders, string>;
  Object.assign(finalHeaders, headers, extendedHeader);
  // Delete any headers which are not requested from caller. These will be set to null
  const keyToDelete: AllowedHttpRequestHeaders[] = [];

  for (const [key, value] of Object.entries(finalHeaders)) {
    if (!value || value.length === 0)
      keyToDelete.push(key as AllowedHttpRequestHeaders);
  }
  keyToDelete.forEach((k: AllowedHttpRequestHeaders) => delete finalHeaders[k]);
  return finalHeaders;
}

async function getcsrfToken(
  baseUrl: string,
  cachedCsrfToken: CachedCSRFToken,
): Promise<string> {
  if (cachedCsrfToken.token !== null) {
    const token = cachedCsrfToken.token;
    cachedCsrfToken.token = null;
    return Promise.resolve(token);
  } else {
    const csrfResponse = await fetch(
      `${baseUrl}${apiDictionary.csrf.token.url}`,
    );
    const csrfTokenFromheader = csrfResponse.headers.get(
      "X-Apple-CSRF-Token",
    ) as string;
    return csrfTokenFromheader;
  }
}

// Common request handler for POST, PUT, DELETE
const request = async <R, B>(
  baseUrl: string,
  url: string,
  method: HttpMethod.POST | HttpMethod.PUT | HttpMethod.DELETE,
  dataLocale: string,
  browserLocale: string,
  cachedCsrfToken: CachedCSRFToken,
  options?: RequestOptions<B>,
): Promise<ApiResponse<R>> => {
  const { body, headers = {} } = options || {};

  const isFormData = body instanceof FormData;
  const csrfToken = await getcsrfToken(baseUrl, cachedCsrfToken);
  headers["X-Apple-CSRF-Token"] = csrfToken;
  const mergedHeaders = buildHeaders(dataLocale, browserLocale, headers);
  try {
    const requestInit: RequestInit = {
      method,
      headers: mergedHeaders,
      body: body
        ? isFormData
          ? (body as BodyInit)
          : JSON.stringify(body)
        : undefined,
      redirect: options?.redirect,
    };
    const response = await fetch(url, requestInit);
    const incomingCsrfToken = response.headers.get(
      "X-Apple-CSRF-Token",
    ) as string;
    cachedCsrfToken.token = incomingCsrfToken ? incomingCsrfToken : null;
    if (response.redirected && response.url && options?.handleRedirect) {
      window.location.href = response.url;
    }
    return await handleResponse<R>(response);
  } catch (error) {
    if (error instanceof Error) {
      // TODO: Figure out client-side logging
      // eslint-disable-next-line no-console
      console.error(
        `API ${method} request to ${url} failed: ${error.message}`,
        error.stack,
      );

      return {
        success: false,
        status: httpStatus.INTERNAL_SERVER_ERROR,
        error: `${error.message} - Stack: ${error.stack || "No stack trace available"}`,
      };
    } else {
      return {
        success: false,
        status: httpStatus.INTERNAL_SERVER_ERROR,
        error: "Unknown error occurred",
      };
    }
  }
};

// GET handler
const get = async <R>(
  url: string,
  dataLocale: string,
  browserLocale: string,
  options?: RequestOptions,
): Promise<ApiResponse<R>> => {
  const { headers } = options || {};
  const mergeHeaders = buildHeaders(dataLocale, browserLocale, headers);
  try {
    const requestInit: RequestInit = {
      method: HttpMethod.GET,
      headers: mergeHeaders,
    };
    const response = await fetch(url, requestInit);

    // file download specific
    if (headers && headers.responseType == APPLICATION_OCTET_STREAM) {
      // if the file download fails, it will be a json
      if (response.headers.get("Content-Type") == APPLICATION_JSON) {
        return { success: false, status: response.status };
      } else {
        // file download is successful, it returns stream
        return { success: true, status: response.status, data: response as R };
      }
    }

    return await handleResponse<R>(response);
  } catch (error) {
    if (error instanceof Error) {
      // TODO: Figure out client-side logging
      // eslint-disable-next-line no-console
      console.error(
        `API GET request to ${url} failed: ${error.message}`,
        error.stack,
      );

      return {
        success: false,
        status: httpStatus.INTERNAL_SERVER_ERROR,
        error: `${error.message} - Stack: ${error.stack || "No stack trace available"}`,
      };
    } else {
      return {
        success: false,
        status: httpStatus.INTERNAL_SERVER_ERROR,
        error: "Unknown error occurred",
      };
    }
  }
};

interface CachedCSRFToken {
  token: string | null;
}
// Dynamic API service creator function
const createApiService = (
  apiDictionary: Record<string, Record<string, ApiEndpoint>>,
  baseUrl: string,
  dataLocale: string,
  browserLocale: string,
): ApiService => {
  const cachedCsrfToken: CachedCSRFToken = { token: null };
  return Object.keys(apiDictionary).reduce((service, serviceName) => {
    service[serviceName] = {};

    Object.keys(apiDictionary[serviceName]).forEach((action) => {
      const { url } = apiDictionary[serviceName][action];

      service[serviceName][action] = {
        get: <R>(options?: RequestOptions) =>
          get<R>(
            buildUrl(baseUrl, url, options?.pathParams, options?.queryParams),
            dataLocale,
            browserLocale,
            options,
          ),
        post: <R, B>(options: RequestOptions<B>) =>
          request<R, B>(
            baseUrl,
            buildUrl(baseUrl, url, options?.pathParams, options?.queryParams),
            HttpMethod.POST,
            dataLocale,
            browserLocale,
            cachedCsrfToken,
            options,
          ),
        put: <R, B>(options: RequestOptions<B>) =>
          request<R, B>(
            baseUrl,
            buildUrl(baseUrl, url, options?.pathParams, options?.queryParams),
            HttpMethod.PUT,
            dataLocale,
            browserLocale,
            cachedCsrfToken,
            options,
          ),
        delete: (options?: RequestOptions) =>
          request(
            baseUrl,
            buildUrl(baseUrl, url, options?.pathParams, options?.queryParams),
            HttpMethod.DELETE,
            dataLocale,
            browserLocale,
            cachedCsrfToken,
            options,
          ),
      };
    });

    return service;
  }, {} as ApiService);
};

const ApiServiceHandler = {
  service: {} as ApiService,
  init: function (
    baseUrl: string,
    appBasePathPrefix: string,
    dataLocale: string,
    browserLocale: string,
  ) {
    const apiBaseUrl = `${baseUrl}${appBasePathPrefix}`;
    this.service = createApiService(
      apiDictionary,
      apiBaseUrl,
      dataLocale,
      browserLocale,
    ) as ApiService;
  },
};

// Exporting the apiService
export default ApiServiceHandler;
