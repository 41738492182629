import { Button } from "@rpe-js/marcom-web-components";
import React, { useContext, useState } from "react";
import AppContext from "../../../AppContext";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { Info } from "../../base/Info";
import { Label } from "../../base/Label";
import TextIcon from "../../base/TextIcon";
import { EMAIL_REPLACE_BUTTON } from "./constants";
import { EmailVerification } from "./emailVerification";

type PreferredEmailProps = {
  isEdit: boolean;
  classes?: string;
  preferredEmail: string | undefined;
  verifiedStatus: boolean | undefined;
  onSuccess?: (emailId: string) => void;
};

export const PreferredEmail = ({
  isEdit,
  classes,
  preferredEmail,
  verifiedStatus,
  onSuccess,
}: PreferredEmailProps) => {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const { appUIState } = useContext(AppContext);
  const { isExternal } = appUIState.appData;
  const [showEmailVerification, setShowEmailVerification] = useState(false);

  return (
    <>
      <div
        className={`column ${isEdit ? "large-8 pr-15 large-grow" : !classes ? "large-6 small-12 mb-10" : classes}`}
      >
        <div className="d-flex flex-column pr-15">
          <Label label={t("jobsite.common.preferredEmail") as string} />
          <Info
            value={
              preferredEmail
                ? preferredEmail
                : (t("jobsite.common.notSpecified") as string)
            }
          />
          {verifiedStatus && (
            <TextIcon
              name="icon-checksolid"
              textColor="green"
              label={t("jobsite.common.verified") as string}
            />
          )}
          {!verifiedStatus && (
            <TextIcon
              name="icon-exclamationsolid"
              textColor="red"
              label={t("jobsite.common.unVerified") as string}
            />
          )}
        </div>
      </div>
      {isEdit && isExternal && (
        <div
          className={`d-flex align-self-center column large-4 large-grow ${!isMobile ? "pr-15" : ""}`}
        >
          <Button
            label={t("jobsite.common.replace") as string}
            size="base"
            color="primary"
            blockedVariant={true}
            onClick={() => setShowEmailVerification(true)}
            id={EMAIL_REPLACE_BUTTON}
          />
        </div>
      )}
      {showEmailVerification && (
        <EmailVerification
          showEmailVerification={showEmailVerification}
          setShowEmailVerification={setShowEmailVerification}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};
