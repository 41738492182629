import { useEffect } from "react";
import { useNavigate } from "react-router";

// Hook to refresh route without page reload
export default function useRefresh(path: string) {
  let handler: any;
  const navigate = useNavigate();
  const refresh = (resetRoute: string) => {
    navigate(path);
    handler = setTimeout(() => navigate(resetRoute), 50);
  };

  useEffect(() => {
    return () => handler && clearTimeout(handler);
  }, [handler]);

  return refresh;
}
