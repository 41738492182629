import { TextButton } from "@rpe-js/marcom-web-components";
import React from "react";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";

type ParsedSectionProps = {
  sectionId: string;
  sectionTitle: string;
  sectionName: "employments" | "education" | "skills" | "languages";
  displayDiscardButton: boolean;
  onDiscard: (sectionName: string) => void;
  children: React.ReactNode;
};

export const ParsedSection = ({
  sectionId,
  sectionTitle,
  onDiscard,
  children,
  sectionName,
  displayDiscardButton,
}: ParsedSectionProps) => {
  const isMobile = useIsMobile();
  const { t } = useIntlMessage();
  return (
    <section
      className={`mt-20 pb-20 ${!isMobile ? " u-border-top" : ""}`}
      id={sectionId}
      aria-labelledby={`${sectionId}-title`}
    >
      <div className="row pt-30">
        <div
          className={`column large-3 small-12 mr-20 ${isMobile ? "d-flex justify-content-spacebetween ml-20 mb-20 pb-10 u-border-bottom" : ""}`}
        >
          <h3
            id={`${sectionId}-title`}
            className="t-eyebrow-reduced fw-medium"
            tabIndex={-1}
          >
            {sectionTitle}
          </h3>
          {displayDiscardButton && (
            <TextButton
              id={`${sectionId}-discardButton`}
              label={t("jobsite.common.discard") as string}
              onClick={() => onDiscard(sectionName)}
              classes="t-body-reduced"
              aria-label={`${t("jobsite.common.discard")} ${sectionTitle}`}
            />
          )}
        </div>
        <div className="column large-8 small-12 mx-20">{children}</div>
      </div>
    </section>
  );
};
