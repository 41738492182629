import {
  InfoTooltip,
  TooltipConfiguration,
} from "@rpe-js/marcom-web-components";
import React, { useContext, useEffect, useState } from "react";
import { ICON_SIZE } from "../../../shared/types";
import APP_CONSTANTS from "../../../utilities/appConstants";
import { favoritedRole } from "../../api/fetchClient";
import AppContext from "../../AppContext";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import UnauthenticatedPopover from "../../pages/search/UnauthenticatedPopover";
import IconComponent from "../base/IconComponent";

export interface FavoriteComponentProps {
  positionId: string;
  isFavorited: boolean;
  handleFavoriteChange: (favorited: boolean) => void;
  id?: string;
  disableActions?: boolean;
  postingTitle?: string;
  iconSize?: ICON_SIZE;
}

export default function FavoriteComponent({
  positionId,
  isFavorited,
  id,
  disableActions,
  postingTitle,
  handleFavoriteChange,
  iconSize,
}: FavoriteComponentProps) {
  const [showSignInPopover, setShowSignInPopover] = useState(false);
  const [favorited, setFavorited] = useState(isFavorited || false);
  const [showTip, setShowTip] = useState(false);
  const { appUIState } = useContext(AppContext);
  const { isSessionAuthenticated, talentId } = appUIState.appData;

  const favoriteHandler = async () => {
    if (!isSessionAuthenticated) {
      setShowSignInPopover(!showSignInPopover);
      return;
    }
    if (talentId && positionId) {
      setFavorited(!favorited);
      setShowTip(true);
      setTimeout(() => {
        setShowTip(false);
      }, APP_CONSTANTS.TOOLTIP_TIMER);
      await favoritedRole({
        favorited: favorited ? "No" : "Yes",
        positionID: positionId,
        talentID: talentId,
        userType: null,
      });
      handleFavoriteChange(!favorited || false);
    }
  };
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const toolTipHintText = t("jobsite.common.addToFavoritesTitle");
  const onUnAuthenticatedPopupClose = () => setShowSignInPopover(false);

  function getTooltipConfiguration() {
    return {
      overrideDefaultBehaviour: true,
      showOnHover: false,
      show: showTip,
      icon: (
        <IconComponent
          name={
            disableActions
              ? "favorites-filled-grey"
              : favorited
                ? "favorites-filled"
                : "favorites"
          }
          classes="fav-star-icon"
          cursor={disableActions ? "default" : "pointer"}
          size={iconSize}
        ></IconComponent>
      ),
    } as TooltipConfiguration;
  }

  function getTooltipText() {
    return favorited
      ? (t("jobsite.common.addToFavorites") as string)
      : (t("jobsite.common.removeFromFavorites") as string);
  }

  useEffect(() => {
    setFavorited(isFavorited);
  }, [isFavorited]);

  return (
    <>
      <span id={`add-favorite-${id}`} tabIndex={-1}>
        <input
          type="checkbox"
          aria-labelledby={`btn-content_${positionId}-${id}`}
          disabled={disableActions}
          key={`addToFavoriteId-${id}`}
          className="fav-checkbox"
          id={`addToFavoriteId-${id}`}
          onChange={favoriteHandler}
          checked={favorited}
        />
        <label
          className="fav-label t-label"
          htmlFor={`addToFavoriteId-${id}`}
          aria-hidden={true}
        >
          <span className="button-icon fav-checkbox-icon" />
          <span className="a11y">{`${toolTipHintText} ${postingTitle}`}</span>
          <InfoTooltip
            positionClass="form-tooltip-after ml-0"
            tooltipPointer={isMobile ? "left" : "middle"}
            iconLabel={getTooltipText()}
            setAsHtml={true}
            infoTooltipBody={getTooltipText()}
            configuration={getTooltipConfiguration()}
          ></InfoTooltip>
        </label>
      </span>
      {showSignInPopover && !isSessionAuthenticated && (
        <div className="favorite-unauthenticated">
          <UnauthenticatedPopover
            id={`${id}-unauthenticated-popover`}
            view={isMobile ? "overlay" : "popover"}
            message="jobsite.search.favoritesSigninMessage"
            triggerElementId={`addToFavoriteId-${id}`}
            onClose={onUnAuthenticatedPopupClose}
            positionId={positionId}
          ></UnauthenticatedPopover>
        </div>
      )}
    </>
  );
}
