import React, { useEffect, useReducer } from "react";
import useSetPageTitle, { PAGE_TITLES } from "../../../hooks/useSetPageTitle";
import { SavedSearchesContext } from "./context/SavedSearchesContext";
import { reducer } from "./context/SavedSearchesReducer";
import { SavedSearchesState } from "./context/SavedSearchesState";
import { SavedSearchesLayout } from "./SavedSearchesLayout";

interface SavedSearchesProps {
  initialState?: SavedSearchesState;
}

export const SavedSearches: React.FC<SavedSearchesProps> = ({
  initialState = {
    savedSearches: [],
    jobAgentEmailFrequency: [],
    jobAgentEmailExperience: [],
    showEmailAlertModal: false,
    showRemoveSearchModal: false,
    searchToRemove: null,
    editSavedSearches: {},
    refreshData: false,
    emailAlertModalForId: "",
  },
}: SavedSearchesProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { setPageTitle } = useSetPageTitle();

  useEffect(() => {
    setPageTitle(PAGE_TITLES.PROFILE.SAVED_SEARCHES);
  }, [setPageTitle]);

  return (
    <SavedSearchesContext.Provider value={{ state, dispatch }}>
      <SavedSearchesLayout />
    </SavedSearchesContext.Provider>
  );
};
