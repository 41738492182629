import {
  LinkComponent,
  Textbox,
  TextButton,
} from "@rpe-js/marcom-web-components";
import { cloneDeep, isEmpty } from "lodash";
import React, {
  ClipboardEvent,
  KeyboardEvent,
  useCallback,
  useMemo,
} from "react";
import { WorkdayConfig } from "../../../../shared/types/config";
import { BaseRefData } from "../../../../shared/types/refData";
import { Info } from "../../../components/base/Info";
import { Label } from "../../../components/base/Label";
import { useFormInputValidator } from "../../../components/feature/form/inputValidators/useFormInputValidator";
import { CategoryCountConfigurations } from "../../../components/feature/form/maxLengthConfiguration";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { CountryDropdownView } from "../components/CountryDropdownView";
import { ProfileFormField } from "../types";
import { getFieldLabel } from "./components/utils";
import { AddressAllowedFields, AddressFormConfig } from "./types";

type EditAddressProps = {
  mode?: "profile" | "myapplication";
  isInternal: boolean;
  workDayLinks?: WorkdayConfig;
  form: AddressFormConfig[];
  onCountryChange: (key: number, selected: BaseRefData) => void;
  onAddAddress: () => void;
  onRemoveAddress: (key: number) => void;
  dropdownData: BaseRefData[];
  onAddressUpdate: (
    addressIndex: number,
    rowIndex: number,
    columnIndex: number,
    value: string,
  ) => void;
};

export const EditAddress = ({
  mode = "profile",
  isInternal,
  workDayLinks,
  form,
  onCountryChange,
  onAddAddress,
  onRemoveAddress,
  onAddressUpdate,
  dropdownData,
}: EditAddressProps) => {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const {
    validateStreetCityDistrict,
    onPasteValidateStreetCityDistrict,
    validateCountryStateZipCode,
    onPasteValidateCountryStateZipCode,
  } = useFormInputValidator();

  const addressForm = useMemo(() => {
    return cloneDeep(form);
  }, [form]);

  const validateOnKeyDown = useCallback(
    (
      evt: KeyboardEvent<HTMLInputElement>,
      addressIndex: number,
      rowIndex: number,
      columnIndex: number,
    ) => {
      const fieldConfig =
        addressForm[addressIndex].fields[rowIndex][columnIndex];
      if (
        (fieldConfig.name as AddressAllowedFields) === "line1" ||
        (fieldConfig.name as AddressAllowedFields) === "line2" ||
        (fieldConfig.name as AddressAllowedFields) === "line3" ||
        (fieldConfig.name as AddressAllowedFields) === "city" ||
        (fieldConfig.name as AddressAllowedFields) === "district"
      ) {
        validateStreetCityDistrict(evt);
      }

      if (
        (fieldConfig.name as AddressAllowedFields) === "state" ||
        (fieldConfig.name as AddressAllowedFields) === "county" ||
        (fieldConfig.name as AddressAllowedFields) === "zip"
      ) {
        validateCountryStateZipCode(evt);
      }
    },
    [addressForm, validateStreetCityDistrict, validateCountryStateZipCode],
  );
  const validateOnPaste = useCallback(
    (
      evt: ClipboardEvent<HTMLInputElement>,
      addressIndex: number,
      rowIndex: number,
      columnIndex: number,
    ) => {
      const fieldConfig =
        addressForm[addressIndex].fields[rowIndex][columnIndex];
      if (
        (fieldConfig.name as AddressAllowedFields) === "line1" ||
        (fieldConfig.name as AddressAllowedFields) === "line2" ||
        (fieldConfig.name as AddressAllowedFields) === "line3" ||
        (fieldConfig.name as AddressAllowedFields) === "city" ||
        (fieldConfig.name as AddressAllowedFields) === "district"
      ) {
        onPasteValidateStreetCityDistrict(evt);
      }

      if (
        (fieldConfig.name as AddressAllowedFields) === "state" ||
        (fieldConfig.name as AddressAllowedFields) === "county" ||
        (fieldConfig.name as AddressAllowedFields) === "zip"
      ) {
        onPasteValidateCountryStateZipCode(evt);
      }
    },
    [
      addressForm,
      onPasteValidateCountryStateZipCode,
      onPasteValidateStreetCityDistrict,
    ],
  );

  return (
    <>
      {mode === "profile" && (
        <div>
          {!isInternal &&
            addressForm.map(
              (addressData: AddressFormConfig, addressIndex: number) => (
                <div
                  className="u-border-top pt-25"
                  key={`addressSection${addressData?.key}`}
                >
                  <div className="row">
                    <div
                      className={`column large-6 small-12 mb-10 ${!isMobile && "pr-15"}`}
                    >
                      <CountryDropdownView
                        refData={dropdownData}
                        selectedCountry={addressData?.country?.value || null}
                        onChange={(e, option, refData) =>
                          onCountryChange(addressData.key, refData)
                        }
                        showError={addressData.country.error}
                        id={`address-section-country-dropdown-${addressIndex}`}
                      ></CountryDropdownView>
                    </div>
                  </div>
                  {addressData?.fields?.map(
                    (
                      rowAddressFormFields: ProfileFormField[],
                      rowIndex: number,
                    ) => (
                      <div
                        className="row mb-10"
                        key={`${addressData?.key}-${rowIndex}`}
                      >
                        {rowAddressFormFields.map(
                          (formField, columnIndex: number) => (
                            <div
                              className={`column large-6 small-12 mb-10 ${!isMobile && "pr-15"}`}
                              key={`${columnIndex}${addressData?.key}`}
                            >
                              {formField.type === "text" && (
                                <Textbox
                                  id={formField?.id as string}
                                  required={formField?.required || false}
                                  label={getFieldLabel(
                                    formField?.label as string,
                                    formField?.optional || false,
                                    t,
                                  )}
                                  error={
                                    formField.error
                                      ? (t(
                                          formField.errori18nMsgKey as string,
                                          {
                                            ...(formField.errori18nMsgParams ||
                                              {}),
                                          },
                                        ) as string)
                                      : false
                                  }
                                  errorA11y={
                                    t("jobsite.common.errorIconLabel") as string
                                  }
                                  onKeyDown={(
                                    evt: KeyboardEvent<HTMLInputElement>,
                                  ) =>
                                    validateOnKeyDown(
                                      evt,
                                      addressIndex,
                                      rowIndex,
                                      columnIndex,
                                    )
                                  }
                                  onPaste={(evt) =>
                                    validateOnPaste(
                                      evt,
                                      addressIndex,
                                      rowIndex,
                                      columnIndex,
                                    )
                                  }
                                  value={formField?.value}
                                  onValueChange={(val) =>
                                    onAddressUpdate(
                                      addressIndex,
                                      rowIndex,
                                      columnIndex,
                                      val,
                                    )
                                  }
                                />
                              )}
                            </div>
                          ),
                        )}
                      </div>
                    ),
                  )}
                  <div className="d-flex mb-20">
                    {addressForm.length >
                      CategoryCountConfigurations.addressMinCount &&
                      addressIndex !== 0 && (
                        <span className="mr-20">
                          <TextButton
                            label={t("jobsite.common.remove") as string}
                            onClick={() => onRemoveAddress(addressData.key)}
                            icon="icon-minuscircle"
                          />
                        </span>
                      )}
                    {addressForm.length !==
                      CategoryCountConfigurations.addressMaxCount &&
                      addressIndex === addressForm.length - 1 && (
                        <TextButton
                          label={
                            t("jobsite.common.addAnotherAddress") as string
                          }
                          onClick={onAddAddress}
                          icon="icon-pluscircle"
                        />
                      )}
                  </div>
                </div>
              ),
            )}
          {isInternal &&
            addressForm.map((addressData: AddressFormConfig) => (
              <React.Fragment key={addressData?.key}>
                <div className="row">
                  <div
                    className={`column large-6 small-12 mb-10 ${!isMobile && "pr-15"}`}
                  >
                    <div>
                      <Label
                        label={t("jobsite.common.country") as string}
                        variant="prominent"
                      />
                      <span className="ml-20 mt-0">
                        <LinkComponent
                          text={t("jobsite.common.edit") as string}
                          type="standalone"
                          link={`${workDayLinks?.preferredAddress}`}
                        />
                      </span>
                    </div>
                    <Info value={addressData?.country?.label} />
                  </div>
                </div>
                {addressData?.fields?.map(
                  (
                    rowAddressFormFields: ProfileFormField[],
                    rowIndex: number,
                  ) => (
                    <div
                      className="row"
                      key={`${addressData?.key}-${rowIndex}`}
                    >
                      {rowAddressFormFields.map(
                        (formField, columnIndex: number) =>
                          !isEmpty(formField.value) && (
                            <div
                              className={`column large-6 small-12 mb-10 ${!isMobile && "pr-15"}`}
                              key={`${columnIndex}${addressData?.key}`}
                            >
                              <div>
                                <Label
                                  label={
                                    t(formField?.label as string) as string
                                  }
                                  variant="prominent"
                                />
                              </div>
                              <Info
                                value={
                                  isEmpty(formField.value)
                                    ? (t(
                                        "jobsite.common.notSpecified",
                                      ) as string)
                                    : formField.value
                                }
                              />
                            </div>
                          ),
                      )}
                    </div>
                  ),
                )}
              </React.Fragment>
            ))}
        </div>
      )}
      {mode === "myapplication" && (
        <div>
          {addressForm.map(
            (addressData: AddressFormConfig, addressIndex: number) => (
              <div
                className={`${addressIndex > 0 ? "u-border-top" : ""} pt-10`}
                key={`addressSection${addressData?.key}`}
              >
                <div className="row">
                  <div
                    className={`column large-6 small-12 mb-10 ${!isMobile && "pr-15"}`}
                  >
                    <CountryDropdownView
                      refData={dropdownData}
                      selectedCountry={addressData?.country?.value || null}
                      onChange={(e, option, refData) =>
                        onCountryChange(addressData.key, refData)
                      }
                      showError={addressData.country.error}
                      id={`address-section-country-dropdown-${addressIndex}`}
                    ></CountryDropdownView>
                  </div>
                </div>
                {addressData?.fields?.map(
                  (
                    rowAddressFormFields: ProfileFormField[],
                    rowIndex: number,
                  ) => (
                    <div
                      className="row"
                      key={`${addressData?.key}-${rowIndex}`}
                    >
                      {rowAddressFormFields.map(
                        (formField, columnIndex: number) => (
                          <div
                            className={`column large-6 small-12 mb-10 ${!isMobile && "pr-15"}`}
                            key={`${columnIndex}${addressData?.key}`}
                          >
                            {formField.type === "text" && (
                              <Textbox
                                id={formField?.id as string}
                                required={formField?.required || false}
                                label={getFieldLabel(
                                  formField?.label as string,
                                  formField?.optional || false,
                                  t,
                                )}
                                error={
                                  formField.error
                                    ? (t(formField.errori18nMsgKey as string, {
                                        ...(formField.errori18nMsgParams || {}),
                                      }) as string)
                                    : false
                                }
                                errorA11y={
                                  t("jobsite.common.errorIconLabel") as string
                                }
                                onKeyDown={(
                                  evt: KeyboardEvent<HTMLInputElement>,
                                ) =>
                                  validateOnKeyDown(
                                    evt,
                                    addressIndex,
                                    rowIndex,
                                    columnIndex,
                                  )
                                }
                                onPaste={(evt) =>
                                  validateOnPaste(
                                    evt,
                                    addressIndex,
                                    rowIndex,
                                    columnIndex,
                                  )
                                }
                                value={formField?.value}
                                onValueChange={(val) =>
                                  onAddressUpdate(
                                    addressIndex,
                                    rowIndex,
                                    columnIndex,
                                    val,
                                  )
                                }
                              />
                            )}
                          </div>
                        ),
                      )}
                    </div>
                  ),
                )}
                <div className="d-flex mb-20">
                  {addressForm.length >
                    CategoryCountConfigurations.addressMinCount &&
                    addressIndex !== 0 && (
                      <span className="mr-20">
                        <TextButton
                          label={t("jobsite.common.remove") as string}
                          onClick={() => onRemoveAddress(addressData.key)}
                          icon="icon-minuscircle"
                        />
                      </span>
                    )}
                  {addressForm.length !==
                    CategoryCountConfigurations.addressMaxCount &&
                    addressIndex === addressForm.length - 1 && (
                      <TextButton
                        label={t("jobsite.common.addAnotherAddress") as string}
                        onClick={onAddAddress}
                        icon="icon-pluscircle"
                      />
                    )}
                </div>
              </div>
            ),
          )}
        </div>
      )}
    </>
  );
};
