import { TextButton } from "@rpe-js/marcom-web-components";
import React from "react";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { FileData } from "../../../types/File";
import { idGenerator } from "../../../utils/idGenerator";
import FileUploader from "../../base/FileUploader";

interface ResumeUploadWidgetProps {
  attachFile: (fileData: FileData) => void;
  pasteText: () => void;
}

export function ResumeUploadWidget({
  attachFile,
  pasteText,
}: ResumeUploadWidgetProps) {
  const { t } = useIntlMessage();

  return (
    <section className="pt-30 pt-15 pb-30 pl-15 pr-15">
      <h3
        className="t-tout"
        id={idGenerator("resume", "attachresumeheading").generateId()}
      >
        {t("jobsite.common.attachResume")}
      </h3>
      <div className="text-center">
        <p
          className="attach-title"
          id={idGenerator("resume", "attachtitle").generateId()}
        >
          {t("jobsite.common.attachTitle")}
        </p>
      </div>
      <div className={"mt-30 text-center"}>
        <FileUploader
          id={idGenerator("resume", "fileupload").generateId()}
          label={t("jobsite.common.attachFile") as string}
          showPlusIcon={false}
          onFileUpload={attachFile}
        ></FileUploader>
        <span className="ml-30 align-middle">
          <TextButton
            id={idGenerator("resume", "textupload").generateId()}
            label={`${t("jobsite.common.pasteText")}`}
            onClick={pasteText}
          ></TextButton>
        </span>
      </div>
    </section>
  );
}
