import { idGenerator } from "../../../../utils/idGenerator";

export function useSectionIdGenerator(section: "application" | "review") {
  const { generateId } = idGenerator("profileApplication", `${section}`);
  const contactInfoSectionId = generateId("contactInformation");
  const empBckSectionId = generateId("employmentBackground");
  const eduSectionId = generateId("education");
  const profExpSectionId = generateId("professionalExperience");
  const refSectionId = generateId("references");
  const legSectionId = generateId("legal");
  const selfDisclosureSectionId = generateId("selfDisc");
  const infoReleaseSectionId = generateId("infoRel");

  return {
    contactInfoSectionId,
    empBckSectionId,
    eduSectionId,
    profExpSectionId,
    refSectionId,
    legSectionId,
    selfDisclosureSectionId,
    infoReleaseSectionId,
  };
}
