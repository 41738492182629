import React, { useState } from "react";
import { RefData } from "../../../../../shared/types/refData";
import { RequestOptions } from "../../../../api/apiService";
import { getMajorByInput } from "../../../../api/fetchClient";
import TypeaheadComponent, {
  typeaheadstandardCssClasses,
} from "../../../../components/base/Typeahead/TypeaheadComponent";
import { useFormInputValidator } from "../../../../components/feature/form/inputValidators/useFormInputValidator";
import useIntlMessage from "../../../../hooks/useIntlMessage";

export function EducationMajor(props: {
  id: string;
  elementToFocusOnClose: string;
  onSuggestionSelect: (value: RefData | string) => void;
  onBlur: (value: string | null) => void;
  onReset: () => void;
  initialValue?: string;
  showError?: boolean;
  typeaheadApiOptions?: RequestOptions;
}) {
  const { t } = useIntlMessage();
  const { validateSchoolSkill, onPasteValidateSchoolSkill } =
    useFormInputValidator();
  const [educationMajor, setEducationMajor] = useState<string | null>(
    props.initialValue || "",
  );
  const {
    id,
    initialValue,
    showError = false,
    onSuggestionSelect,
    onBlur,
    onReset,
    typeaheadApiOptions,
    elementToFocusOnClose,
  } = props;
  return (
    <TypeaheadComponent
      id={id}
      elementToFocusOnClose={elementToFocusOnClose}
      apiEndpoint={getMajorByInput}
      apiOptions={typeaheadApiOptions}
      highlightMatches={true}
      showSearchIcon={true}
      strict={false}
      onSelect={onSuggestionSelect}
      onBlur={onBlur}
      onReset={onReset}
      getSuggestionLabel={(suggestion: RefData) => suggestion?.name}
      onInputChange={(val: string | null) => {
        setEducationMajor(val);
      }}
      minChars={1}
      initialValue={initialValue}
      label={t("jobsite.common.enterField") as string}
      suggestionPlaceholder={t("jobsite.common.majorTitle") as string}
      classNames={typeaheadstandardCssClasses}
      errorA11y={t("jobsite.common.errorIconLabel") as string}
      errorMessage={
        showError && !educationMajor
          ? (t("jobsite.common.majormsg") as string)
          : null
      }
      validators={{
        onKeyDown: validateSchoolSkill,
        onPaste: onPasteValidateSchoolSkill,
      }}
    />
  );
}
