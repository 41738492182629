import { Dropdown, DropDownOptionProps } from "@rpe-js/marcom-web-components";
import React from "react";
import useIntlMessage from "../../hooks/useIntlMessage";
import { idGenerator } from "../../utils/idGenerator";

interface LocaleObj extends DropDownOptionProps {
  code: string;
  name: string;
}

interface LocaleSelectProps {
  locales: Array<LocaleObj>;
  selectedLocale: string;
  localeSelectHandler: (value: string) => void;
}

export default function JobDetailsLocaleSelect({
  locales,
  localeSelectHandler,
  ...props
}: LocaleSelectProps) {
  const { t } = useIntlMessage();
  const localeList = locales.map(({ name, code }) => {
    return { name, value: code, label: name };
  });
  const languageDropdownIdGenerator = idGenerator(
    "jobdetails",
    "localeselectdropdown",
  );
  const languageDropdownHint = languageDropdownIdGenerator.generateId("hint");
  return (
    <div className={"pt-20 locale-select-dropdown"}>
      <span className="a11y" aria-hidden="true" id={languageDropdownHint}>
        {t("jobsite.common.chooseLanguageAriaHint")}
      </span>
      <Dropdown
        required={true}
        aria-label={t("jobsite.common.chooseLanguage") as string}
        id={languageDropdownIdGenerator.generateId()}
        name=""
        hideLabel={true}
        value={props.selectedLocale}
        options={localeList}
        handleValueSelect={function (evt, option): void {
          localeSelectHandler(option.value);
        }}
        errorA11y={t("jobsite.common.errorIconLabel") as string}
        aria-describedby={languageDropdownHint}
      ></Dropdown>
    </div>
  );
}
