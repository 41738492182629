import * as React from "react";
import { useCallback } from "react";
import { SET_FILTER, UNSET_FILTER } from "../../contexts/actionTypes";
import { FilterValue, SEARCH_FILTER_TYPE } from "../../contexts/SearchContext";
import useIsMobile from "../../hooks/useIsMobile";
import { useSearchContext } from "../../hooks/useSearchContext";
import { InternalWeeklyHoursFilter } from "./InternalWeeklyHoursFilter";

interface InternalWeeklyHoursFilterWrapperProps {
  minimumHours: string;
  maximumHours: string;
  onMobileFilterChange?: (
    filterType: SEARCH_FILTER_TYPE,
    filterValue: FilterValue,
  ) => void;
}
export function InternalWeeklyHoursFilterWrapper({
  minimumHours,
  maximumHours,
  onMobileFilterChange,
}: InternalWeeklyHoursFilterWrapperProps) {
  const { dispatch } = useSearchContext();
  const isMobile = useIsMobile();

  const updateMobileFilters = useCallback(
    (filterType: SEARCH_FILTER_TYPE, filterValue: FilterValue) => {
      if (onMobileFilterChange) onMobileFilterChange(filterType, filterValue);
    },
    [onMobileFilterChange],
  );

  const onAddHours = useCallback(
    (filterType: string, hours: string) => {
      if (!isMobile)
        dispatch({
          type: SET_FILTER,
          filterName: filterType,
          payload: hours,
        });
      else updateMobileFilters(filterType as SEARCH_FILTER_TYPE, hours);
    },
    [dispatch, isMobile, updateMobileFilters],
  );

  const onRemoveHours = useCallback(
    (filterType: string) => {
      if (!isMobile)
        dispatch({
          type: UNSET_FILTER,
          filterName: filterType,
        });
      else updateMobileFilters(filterType as SEARCH_FILTER_TYPE, []);
    },
    [dispatch, isMobile, updateMobileFilters],
  );

  return (
    <InternalWeeklyHoursFilter
      defaultMinHours={minimumHours}
      defaultMaxHours={maximumHours}
      onAddHours={onAddHours}
      onRemoveHours={onRemoveHours}
    />
  );
}
