import { NativeButton } from "@rpe-js/marcom-web-components";
import Accordion, {
  AccordionItem,
} from "@rpe-js/marcom-web-components/lib/CustomAccordion/CustomAccordion";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import useAriaLiveStatus from "../../hooks/useAriaLiveStatus";
import useIntlMessage from "../../hooks/useIntlMessage";
import { focusManagementOnRemove } from "../../utils/focusUtil";
import { idGenerator, ModuleName } from "../../utils/idGenerator";
import PillButton from "./PillButton";

interface PillButtonListViewProps<T> {
  moduleName: ModuleName; //  name of the module for id creation
  showAsAccordion?: boolean; //Config to wrap pillBtnList buttons inside accordion
  showOnePillPerLine?: boolean; // set to true if we want to show one pill button per line
  showCount?: boolean; // show number of pill button count
  showClearAll?: boolean; // show clear all button
  showBorder?: boolean; //  add border to pill button
  selectTranslationLabel: string; // label for count key
  countReplacementKey: string; // count replacement key to replace in translation
  pillBtnList: Array<T>; // list of pill button objects
  pillBtnOptions: {
    // methods to retrieve necessary key and labels
    getKey: (data: T) => string;
    getLabel: (data: T) => string;
    getLabelNode?: (data: T) => ReactNode;
  };
  itemAriaLabel?: string;
  onPillBtnRemove: (data: T) => void; // on click of remove button callback
  onClearAllBtnClick: () => void; // on click of clearall button callback
}

export function PillButtonListView<T>({
  moduleName,
  showAsAccordion,
  showCount = true,
  showBorder = false,
  showClearAll = true,
  showOnePillPerLine = false,
  pillBtnList,
  selectTranslationLabel,
  countReplacementKey,
  pillBtnOptions,
  itemAriaLabel = "",
  onPillBtnRemove,
  onClearAllBtnClick,
}: PillButtonListViewProps<T>) {
  const { t } = useIntlMessage();
  const idSeqGenerator = idGenerator(moduleName, "pillbtn");
  const [moreBtnClick, setMoreBtnClick] = useState<boolean>(false);
  const [showMoreBtn, setShowMoreBtn] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement | null>(null);
  const itemsListRef = useRef<HTMLUListElement | null>(null);
  const selectedCountRef = useRef<HTMLSpanElement | null>(null);
  const liveRegionSpanRef = useRef<HTMLSpanElement>(null);
  const timerRef = useRef<number | null>(null);
  const SELECTED_COUNT_ID = idSeqGenerator.generateId("selected-count");
  const { announceAriaMessage } = useAriaLiveStatus(liveRegionSpanRef);

  const selectionCount = (id: string) => {
    return (
      showCount && (
        <span
          id={id}
          className="pr-10 mt-7"
          tabIndex={-1}
          ref={selectedCountRef}
        >
          {t(selectTranslationLabel, {
            [`${countReplacementKey}`]: pillBtnList.length,
          })}
        </span>
      )
    );
  };

  const handleOnClearAllBtnClick = () => {
    if (onClearAllBtnClick) {
      onClearAllBtnClick();
    }
    timerRef.current = window.setTimeout(() => {
      selectedCountRef.current?.focus();
    }, 250);
  };

  useEffect(() => {
    if (selectedCountRef.current && selectedCountRef.current.textContent) {
      announceAriaMessage(selectedCountRef.current.textContent);
    }
    const current = divRef.current;
    if (current) {
      const minHeight = parseInt(window.getComputedStyle(current).minHeight);
      setShowMoreBtn(
        current.scrollHeight >
          (isNaN(minHeight) ? current.clientHeight : minHeight),
      );
    }
  }, [announceAriaMessage, pillBtnList]);

  useEffect(() => {
    if (!showMoreBtn) setMoreBtnClick(false);
  }, [showMoreBtn]);

  useEffect(() => {
    if (moreBtnClick && itemsListRef.current) {
      const selectedItemsButtons =
        itemsListRef.current.querySelectorAll("button");
      if (selectedItemsButtons.length > 1) {
        (selectedItemsButtons[1] as HTMLElement).focus();
      }
    }
  }, [moreBtnClick]);

  useEffect(() => {
    return () => {
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const onPillRemove = useCallback(
    (data: T, index: number) => {
      focusManagementOnRemove(
        itemsListRef,
        index,
        pillBtnList.length,
        SELECTED_COUNT_ID,
      );
      onPillBtnRemove(data);
    },
    [SELECTED_COUNT_ID, onPillBtnRemove, pillBtnList.length],
  );

  const pillListView = (isAccordion?: boolean) => {
    return (
      <>
        <div
          className={`selected-options-pillbtns ${!showOnePillPerLine ? "d-flex" : ""} ${moreBtnClick ? "selected-options-pillbtns-nolimit" : "selected-options-pillbtns-limit"} ${isAccordion && "selected-options-accordion-pills"}`}
          ref={divRef}
        >
          {pillBtnList.length > 0 && (
            <ul
              ref={itemsListRef}
              role="list"
              aria-label={t("jobsite.search.filtersApplied") as string}
              className="list-nobullet"
            >
              {pillBtnList.map((data: T, index) => {
                return (
                  <li role="listitem" key={pillBtnOptions.getKey(data)}>
                    <PillButton
                      id={idSeqGenerator.generateId(`${index}`)}
                      key={pillBtnOptions.getKey(data)}
                      label={pillBtnOptions.getLabel(data)}
                      labelNode={
                        (pillBtnOptions.getLabelNode &&
                          pillBtnOptions.getLabelNode(data)) ||
                        null
                      }
                      showBorder={showBorder}
                      buttonSize="base"
                      onRemove={() => onPillRemove(data, index)}
                      className={
                        !showAsAccordion && index > 0 && !moreBtnClick
                          ? "selected-options-hidden"
                          : ""
                      } //hide pills beyond the first one if "more" button hasn't been clicked
                    ></PillButton>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        {pillBtnList.length > 0 && (
          <div className={`t-body-reduced ${isAccordion && "mb-10"}`}>
            {!isAccordion && (
              <NativeButton
                id={idGenerator(moduleName, "pillbtn-clear").generateId()}
                className={`link mr-5 pr-5 ${showClearAll ? "u-border-right" : ""} ${showMoreBtn || pillBtnList.length > 1 ? "" : "d-none"}`}
                onClick={() => setMoreBtnClick((prev) => !prev)}
                aria-label={
                  t("jobsite.common.ActionsAriaLabel", {
                    action: !moreBtnClick
                      ? `${t("jobsite.common.more")}`
                      : `${t("jobsite.common.less")}`,
                    item: itemAriaLabel,
                  }) as string
                }
                label={
                  (!moreBtnClick
                    ? t("jobsite.common.more")
                    : t("jobsite.common.less")) as string
                }
              ></NativeButton>
            )}
            {showClearAll && (
              <NativeButton
                className="link"
                id={idGenerator(moduleName, "pillbtn-clear").generateId()}
                onClick={handleOnClearAllBtnClick}
                aria-label={
                  t("jobsite.common.ActionsAriaLabel", {
                    action: `${t("jobsite.common.clearAll")}`,
                    item: itemAriaLabel,
                  }) as string
                }
                label={t("jobsite.common.clearAll") as string}
              ></NativeButton>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <span
        ref={liveRegionSpanRef}
        role="status"
        aria-live="polite"
        className="a11y"
      />
      {showAsAccordion && pillBtnList.length ? (
        <Accordion
          id="selected-options-toggle"
          compact={true}
          classes={{ root: "w-100" }}
        >
          <AccordionItem
            titleWrapperTag={React.Fragment} // TODO: Need to update with correct html wrapper tag based on the PullButton usage
            title={selectionCount(SELECTED_COUNT_ID)}
            classes={{
              root: "selected-options-item",
              button: "selected-options-button",
            }}
            noPadding={true}
            clickableTitle={true}
          >
            {pillListView(true)}
          </AccordionItem>
        </Accordion>
      ) : (
        selectionCount(SELECTED_COUNT_ID)
      )}
      {!showAsAccordion && pillListView()}
    </>
  );
}
