import { useCallback } from "react";
import { useSearchParams } from "react-router";
import { QueryParam_Page_Id } from "../constants";
import { useMyApplicationContext } from "../context/context";
import { ApplicationAllowedSteps } from "../context/types";
import { getNextAndPreviousStep } from "../utils";

export function useGotoNextStep() {
  const { state, dispatch } = useMyApplicationContext();
  const [, setSearchParams] = useSearchParams();
  const { page, currentStep } = state;
  const { stepMapping } = page;
  const goToNextStep = useCallback(
    (direction: "next" | "prev") => {
      const { next, prev } = getNextAndPreviousStep(
        currentStep as ApplicationAllowedSteps,
        stepMapping,
      );
      const goToStep = direction === "next" ? next : prev;
      if (goToStep !== currentStep) {
        const params = new URLSearchParams();
        params.set(QueryParam_Page_Id, `${stepMapping[goToStep]}`);
        setSearchParams(params);
        dispatch({
          type: "SET_CURRENT_STEP",
          payload: goToStep,
        });
      }
    },
    [currentStep, stepMapping, dispatch, setSearchParams],
  );

  return goToNextStep;
}
