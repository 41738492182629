import {
  InfoTooltip,
  NativeButton,
  TooltipConfiguration,
} from "@rpe-js/marcom-web-components";
import React, { useCallback, useContext, useState } from "react";
import AppContext from "../../AppContext";
import Icon from "../../components/base/IconComponent";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { useSearchContext } from "../../hooks/useSearchContext";
import { idGenerator } from "../../utils/idGenerator";
import FiltersListPopover, { FilterItem } from "./FiltersListPopover";
import UnauthenticatedPopover from "./UnauthenticatedPopover";

export interface FavoriteSearchFilterProps {
  id: string;
}

function FavoriteSearchFilter({ id }: FavoriteSearchFilterProps) {
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { isSessionAuthenticated, locale } = appUIState.appData;
  const {
    state: { talentRoles },
  } = useSearchContext();
  const [showSignInPopover, setShowSignInPopover] = useState(false);
  const [showListPopover, setShowListPopover] = useState(false);
  // const [searchIdToRemove, setSearchIdToRemove] = useState("");
  const [hovered, setHovered] = useState(false);
  const [closeFromPopover, setCloseFromPopover] = useState(false);
  const search = "search";
  const favRoleId = idGenerator(search, "favourited-list-popover").generateId;
  const isMobile = useIsMobile();

  const [favoritedList, setFavoritedList] = useState<FilterItem[]>([]);
  const onUnAuthenticatedPopupClose = useCallback(() => {
    setCloseFromPopover(true);
    setShowSignInPopover(false);
  }, []);

  const onFavoritedSearchFiltersPopupClose = useCallback(() => {
    setCloseFromPopover(true);
    setShowListPopover(false);
  }, []);

  const favoritedSearchFiltersHandler = async () => {
    if (closeFromPopover) {
      setCloseFromPopover(false);
      return;
    }
    if (!isSessionAuthenticated) {
      setShowSignInPopover(!showSignInPopover);
    } else {
      handleListPopover();
    }
  };
  const handleListPopover = () => {
    if (!showListPopover && talentRoles) {
      // Filter favorited roles from talentRoles
      const favoritedRoles = talentRoles
        .filter((role) => role?.favorited === "Yes" || role?.isFavorited)
        .map((role) => ({
          id: role.reqId || role.jobPositionID,
          name: role.postingTitle,
          url: `/${locale}/details/${role.reqId || role.jobPositionID}/${role.postingTitle}`,
        }));

      setFavoritedList(favoritedRoles);
    }

    setShowListPopover(!showListPopover);
  };

  const getTooltipConfiguration = () => {
    return {
      overrideDefaultBehaviour: true,
      showOnHover: false,
      show: hovered,
      icon: (
        <Icon
          name={hovered ? "favorites-filled" : "favorites-filled-grey"}
          size="medium"
          cursor="pointer"
        ></Icon>
      ),
    } as TooltipConfiguration;
  };

  return (
    <section>
      <NativeButton
        id="favorite-search-filters"
        className={"saved-search-filters"}
        onClick={favoritedSearchFiltersHandler}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        aria-label={t("jobsite.profile.yourRoles.favorites") as string}
        aria-haspopup
      >
        {!isMobile && (
          <>
            <InfoTooltip
              positionClass="form-tooltip-after ml-0 saved-search-filters-icon"
              tooltipPointer="middle"
              iconLabel={t("jobsite.profile.yourRoles.favorites") as string}
              setAsHtml={true}
              infoTooltipBody={
                t("jobsite.profile.yourRoles.favorites") as string
              }
              configuration={getTooltipConfiguration()}
            />
            <Icon
              classes="ml-5"
              name={
                showSignInPopover || showListPopover
                  ? "chevron-grey-up"
                  : "chevron-grey-down"
              }
              width="12"
              height="6"
              cursor="pointer"
            />
          </>
        )}
        {isMobile && <Icon name="favorites" size="medium" cursor="pointer" />}
      </NativeButton>
      {showSignInPopover && !isSessionAuthenticated && (
        <div className="search-unauthenticated">
          <UnauthenticatedPopover
            id={`${id}-unauthenticated-popover`}
            //ariaLabel="labelSavedSearchUnauthenticated"
            //ariaDescription="descSavedSearchUnauthenticated"
            message="jobsite.search.favoritesSigninMessage"
            triggerElementId="favorite-search-filters"
            onClose={onUnAuthenticatedPopupClose}
            view={isMobile ? "overlay" : "popover"}
          ></UnauthenticatedPopover>
        </div>
      )}
      {showListPopover && isSessionAuthenticated && (
        <div className="saved-search-filters-popover">
          <FiltersListPopover
            id={`${favRoleId}`}
            {...{
              "aria-labelledby": "labelFavoriteSearchFilters",
              "aria-describedby": "descFavoriteSearchFilters",
            }}
            manageLabel="jobsite.search.manageFavorites"
            noDataLabel="jobsite.search.noFavorites"
            noDataSubLabel="jobsite.search.favoritesMessage"
            listAriaLabel={t("jobsite.search.favoriteRoles") as string}
            manageLink={`/app/${locale}/profile/roles`}
            showManageIcon={true}
            showCount={true}
            showNoDataLabel={!favoritedList || favoritedList.length === 0}
            isRemoveOptionAvail={false}
            filterList={favoritedList}
            triggerElementId="favorite-search-filters"
            onClose={onFavoritedSearchFiltersPopupClose}
          ></FiltersListPopover>
        </div>
      )}
    </section>
  );
}

export default FavoriteSearchFilter;
