import {
  Button,
  NativeButton,
  Overlay,
  Textbox,
} from "@rpe-js/marcom-web-components";
import { AccordionItem } from "@rpe-js/marcom-web-components/lib/CustomAccordion/CustomAccordion";
import { findIndex } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { HiringManagerDetails } from "../../../shared/types/refData";
import { getHiringManagerRefData } from "../../api/fetchClient";
import { PillButtonListView } from "../../components/base/PillButtonListView";
import { SaveCancelAction } from "../../components/feature/saveAndCancel";
import useIdGenerator from "../../hooks/useIdGenerator";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { HiringManagerInfo } from "../../types/HiringManagerInfo";
import { getCheckboxClasses } from "../../util";
import { mergeSelectedIntoAll } from "../../utils/filterUtil";
import { idGenerator } from "../../utils/idGenerator";
import { FilterCheckboxGroup } from "./FilterCheckboxGroup";

interface HiringManagerFilterProps {
  selectedManagers: Array<HiringManagerInfo>;
  onUpdateHiringManager: (
    selectedHiringManagers: Array<HiringManagerInfo>,
  ) => void;
  onRemoveHiringManager: () => void;
  onApplyFilter: (selectedManagers: Array<HiringManagerInfo>) => void;
}

export function HiringManagerFilter({
  selectedManagers,
  onRemoveHiringManager,
  onUpdateHiringManager,
  onApplyFilter,
}: HiringManagerFilterProps) {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const [resultCountText, setResultCountText] = useState(""); // result label to show in hiring manager modal
  const [filterCount, setFilterCount] = useState<number>(
    selectedManagers.length,
  ); // filter count to show in hiring manager filter section
  const [showHiringManagerModal, setShowHiringManagerModal] = useState(false);
  const [hiringManagerInput, setHiringManagerInput] = useState(""); // search input in hiring manager modal
  const inputRef = useRef<HTMLInputElement>(null); // Ref for the input
  const [hiringManagers, setHiringManagers] = useState<
    Array<HiringManagerInfo>
  >([]); // total hiring managers to show in hiring manager modal
  const [selectedHiringManagers, setSelectedHiringManagers] =
    useState<Array<HiringManagerInfo>>(selectedManagers); // selections in hiring manager modal is tracked in this
  const [hiringManagerFilters, setHiringManagerFilters] =
    useState<Array<HiringManagerInfo>>(selectedManagers); // filters to show in hiring manager filter section after apply/ on load
  const ACCORDION_ID = idGenerator(
    "search",
    "hiringManager-filter-accordion",
  ).generateId();
  const SEARCH_ADD_MANAGER_BTN_ID = idGenerator(
    "search",
    "add-manager",
  ).generateId("button");
  const SEARCH_ADD_MANAGER_TITLE_ID = idGenerator(
    "search",
    "add-manager",
  ).generateId("title");
  /**
   *
   * @param hiringManagerList
   * @param id
   * @returns the hiring manager which matches the provided id
   */

  const findHiringManagerById = useCallback(
    (hiringManagerList: Array<HiringManagerInfo>, id: string) => {
      return findIndex(
        hiringManagerList,
        (hiringManager) => hiringManager.id == id,
      );
    },
    [],
  );
  /**
   * This methods updates the hiring manager result count in modal
   * @param resultCount
   */
  const updateResultCountText = useCallback(
    (resultCount: number) => {
      // initial result count
      if (resultCount > 0) {
        setResultCountText(
          t("jobsite.search.resultCount", {
            number: resultCount.toString(),
          }) as string,
        );
      } else {
        setResultCountText("");
      }
    },
    [t],
  );

  /**
   * This method adds selected field to false for every hiring manager initially
   */
  const formatHiringManagers = useCallback(
    (hiringManagerDetails: HiringManagerDetails) => {
      return {
        ...hiringManagerDetails,
        selected: false,
      };
    },
    [],
  );

  /**
   * @param hiringManagerRefData -> total hiring manager data from api
   * @param selectedHiringManagerRefData -> selected hiring manager data
   * @returns the non selected hiring manager data from total hiring manager data
   */
  const getNonSelectedHiringManagers = useCallback(
    (
      hiringManagerRefData: Array<HiringManagerInfo>,
      selectedHiringManagerRefData: Array<HiringManagerInfo>,
    ) => {
      const allManagerList = [];
      for (let i = 0; i < hiringManagerRefData.length; i++) {
        // find if this hiring manager is already selected
        const matchedIndex = findHiringManagerById(
          selectedHiringManagerRefData,
          hiringManagerRefData[i].id,
        );
        if (matchedIndex < 0) {
          allManagerList.push({ ...hiringManagerRefData[i], selected: false });
        }
      }
      return allManagerList;
    },
    [findHiringManagerById],
  );

  /**
   * This method makes an api call to fetch hiring manager data based on search input and
   * updates the total hiring managers list with the result and updates the result count
   */

  const getHiringManagerList = useCallback(async () => {
    const hiringManagerRefData = (
      (await getHiringManagerRefData(hiringManagerInput)) || []
    ).map(formatHiringManagers);

    const nonSelectedHiringManagers = getNonSelectedHiringManagers(
      hiringManagerRefData,
      selectedHiringManagers,
    );

    // merge non selected and selected into one list to show in hiring manager modal
    const allHiringManagers = [
      ...nonSelectedHiringManagers,
      ...selectedHiringManagers,
    ];

    setHiringManagers([...allHiringManagers]);
    updateResultCountText(allHiringManagers.length);
  }, [
    formatHiringManagers,
    getNonSelectedHiringManagers,
    hiringManagerInput,
    selectedHiringManagers,
    updateResultCountText,
  ]);

  /**
   * This method is called on toggle of hiring manager filter checkbox in search page
   * @param id to update the hiring manager filters on toggle of filter checkbox
   * @param selected to update ths selected state true/false
   *
   */

  const updateHiringManagerFilter = useCallback(
    (id: string, selected: boolean) => {
      const updatedHiringManagers = hiringManagerFilters.map(
        (hiringManager: HiringManagerInfo) =>
          hiringManager.id === id
            ? { ...hiringManager, selected }
            : hiringManager,
      );
      setHiringManagerFilters(updatedHiringManagers);

      const selectedHiringManagers = updatedHiringManagers.filter(
        (hiringManager: HiringManagerInfo) => hiringManager.selected,
      );

      setSelectedHiringManagers(selectedHiringManagers);
      setFilterCount(selectedHiringManagers.length);
      if (selectedHiringManagers.length == 0) {
        onRemoveHiringManager();
      } else {
        onUpdateHiringManager(selectedHiringManagers);
      }
    },
    [hiringManagerFilters, onRemoveHiringManager, onUpdateHiringManager],
  );

  /**
   *
   * This method is called when user clicks on Add button in hiring manager modal and
   * updates the hiring manager list and selected hiring manager list
   * @param id indicates the id of hiring manager
   *
   */

  const addFilter = useCallback(
    (id: string) => {
      const updatedHiringManagers = [...hiringManagers];
      const selectedIndex = findHiringManagerById(updatedHiringManagers, id);
      updatedHiringManagers[selectedIndex] = {
        ...updatedHiringManagers[selectedIndex],
        selected: true,
      };

      setHiringManagers([...updatedHiringManagers]); // update the total hiring manager list

      const updatedSelectedHiringManagers = [...selectedHiringManagers];
      updatedSelectedHiringManagers.push({
        ...updatedHiringManagers[selectedIndex],
      });
      setSelectedHiringManagers([...updatedSelectedHiringManagers]); // update the selected hiring manager list
    },
    [findHiringManagerById, hiringManagers, selectedHiringManagers],
  );

  /**
   * This method is called when user clicks on remove button in hiring manager modal and
   * updates the hiring manager list and selected hiring manager list
   * @param id
   */

  const removeFilter = useCallback(
    (id: string) => {
      const updatedHiringManagers = [...hiringManagers];
      const filterMatchedIndex = findHiringManagerById(
        hiringManagerFilters,
        id,
      );
      const managerIndex = findHiringManagerById(updatedHiringManagers, id);
      /*
     On click of remove button, it can behave in 2 ways 
      1. change the remove option to add option,
         update both hiringManagers, selectedHiringManagers
      2. remove the hiring manager from entire row if it is already selected from previous apply, 
         update both hiringManagers, selectedHiringManagers
    */

      if (
        filterMatchedIndex >= 0 &&
        hiringManagerFilters[filterMatchedIndex].selected
      ) {
        updatedHiringManagers.splice(managerIndex, 1);
      } else {
        updatedHiringManagers[managerIndex] = {
          ...updatedHiringManagers[managerIndex],
          selected: false,
        };
      }

      setHiringManagers([...updatedHiringManagers]);
      updateResultCountText(updatedHiringManagers.length);

      const selectedManagerIndex = findIndex(
        selectedHiringManagers,
        (selectedHiringManager) => selectedHiringManager.id == id,
      );
      const updatedSelectedHiringManagers = [...selectedHiringManagers];
      updatedSelectedHiringManagers.splice(selectedManagerIndex, 1);
      setSelectedHiringManagers([...updatedSelectedHiringManagers]);
    },
    [
      findHiringManagerById,
      hiringManagerFilters,
      hiringManagers,
      selectedHiringManagers,
      updateResultCountText,
    ],
  );

  /**
   * Clear the hiring manager list and search input
   */
  const clearData = useCallback(() => {
    setShowHiringManagerModal(false);
    setHiringManagerInput("");
    setHiringManagers([]);
  }, []);

  /**
   * This method is called when user clicks on "Add Manager" button in filters section
   * which opens the hiring manager modal and
   * sets the initial hiring manager list and initial selected manager list
   * This data can be from manager filter query in url
   * (or) data can come from selected filters in modal
   */
  const onOpen = useCallback(() => {
    setShowHiringManagerModal(true); //open modal
    setHiringManagerInput(""); // reset the search input

    const initialManagerFilters = (hiringManagerFilters || []).filter(
      (hiringManagerFilter) => hiringManagerFilter.selected,
    ); // initial hiring manager filters in filters section are the hiring managers to be shown in modal

    // initially hiring manager list and selected hiring manager list is same
    setHiringManagers([...initialManagerFilters]);
    setSelectedHiringManagers([...initialManagerFilters]);
    updateResultCountText(initialManagerFilters.length);
  }, [hiringManagerFilters, updateResultCountText]);

  /**
   * This method is called on click of close button in hiring manager modal
   */
  const onClose = useCallback(() => {
    clearData();
  }, [clearData]);

  /**
   * This method is called on click of cancel button in hiring manager modal
   */
  const onCancel = useCallback(() => {
    clearData();
  }, [clearData]);

  /**
   * This method is called on click of Apply Filters button in hiring manager modal and
   * Updates the filter count, hiring manager filters and gives a callback to parent component
   */
  const onApply = useCallback(() => {
    setShowHiringManagerModal(false);
    setFilterCount(selectedHiringManagers.length);

    // get the unselected filters from previously selected filters
    const previousUnSelectedfilters = getNonSelectedHiringManagers(
      hiringManagerFilters,
      selectedHiringManagers,
    );

    // we have to show the previous selected filters also which are now unselected
    // merge unselected filters and selected filters in hiring manager filter section
    setHiringManagerFilters([
      ...previousUnSelectedfilters,
      ...selectedHiringManagers,
    ]);

    onApplyFilter([...selectedHiringManagers]);
  }, [
    getNonSelectedHiringManagers,
    hiringManagerFilters,
    onApplyFilter,
    selectedHiringManagers,
  ]);

  /**
   * This method is called when the added filter in modal is removed and returns the removed filter
   * @param hiringManager
   */
  const onRemovePillButton = useCallback(
    (hiringManager: HiringManagerInfo) => {
      removeFilter(hiringManager.id);
    },
    [removeFilter],
  );

  const handleSearchEnter = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key == "Enter") getHiringManagerList();
    },
    [getHiringManagerList],
  );
  const handleResetInput = () => {
    setHiringManagerInput(""); // Clear the input value
    inputRef.current?.focus(); // Return focus to input
  };
  /**
   * This method is called on click of "Clear All" button in selected pill button list
   * which shows up in the bottom of hiring manager modal
   */
  const onClearAll = useCallback(() => {
    const updatedHiringManagers = hiringManagers.map((hiringManager) => ({
      ...hiringManager,
      selected: false,
    }));
    setHiringManagers(updatedHiringManagers);
    setSelectedHiringManagers([]);
  }, [hiringManagers]);

  useEffect(() => {
    const updatedHiringManagerFilters = [
      ...mergeSelectedIntoAll(hiringManagerFilters, selectedManagers),
    ];
    setHiringManagerFilters(updatedHiringManagerFilters);
    setFilterCount(selectedManagers.length);
    // const selectedFilters = updatedHiringManagerFilters
    //   .filter((hiringManager: HiringManagerInfo) => hiringManager.selected)
    //   .map((hiringManager) => hiringManager.id);
    // setFilters({ hiringManager: selectedFilters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedManagers]);

  return (
    <>
      <AccordionItem
        title={`<span class="d-flex-equal fw-medium">${t("jobsite.search.hiringManagerLink")}</span>
                <span class="d-inline-block mr-5 mt-0 ${filterCount > 0 ? "counter" : "d-none"}" aria-hidden="true">${filterCount}</span>
                <span class="a11y">${t("jobsite.common.filterAppliedCount", { count: filterCount })}</span>`}
        titleWrapperTag={"h3"}
        titleWrapperTagAttrs={{
          className: "d-flex",
        }}
        clickableTitle={true}
        expandableIconsPlus={true}
        noPadding={true}
        index={5}
        id={ACCORDION_ID}
      >
        <NativeButton
          onClick={onOpen}
          className="link"
          id={SEARCH_ADD_MANAGER_BTN_ID}
          label={t("jobsite.search.addManager") as string}
        ></NativeButton>
        <div className="mt-20">
          <FilterCheckboxGroup
            id={useIdGenerator("filter", "hiring-manager")}
            filterList={hiringManagerFilters || []}
            onFilterChange={updateHiringManagerFilter}
            classNames={getCheckboxClasses(isMobile)}
          ></FilterCheckboxGroup>
        </div>
      </AccordionItem>
      {showHiringManagerModal && (
        <Overlay
          id="hiring-manager-modal"
          visible={showHiringManagerModal}
          wide={true}
          onClose={onClose}
          disableClickAway={true}
          noPadding={true}
          closeButtonAttrs={{
            ariaLabel: t("jobsite.common.close") as string,
          }}
          classes={{ root: "custom-overlay-fullscreen" }}
          isFullscreen={isMobile}
          elementIdToFocus={SEARCH_ADD_MANAGER_BTN_ID}
          ariaLabel={SEARCH_ADD_MANAGER_TITLE_ID}
          footerContent={
            <>
              <div className="u-border-top">
                {!isMobile && (
                  <div className="d-flex selected-options justify-center pt-25 pr-50 pb-5 pl-50">
                    <PillButtonListView
                      moduleName="filter"
                      selectTranslationLabel={"jobsite.common.countSelected"}
                      countReplacementKey="count"
                      itemAriaLabel={
                        t("jobsite.search.hiringManagers") as string
                      }
                      pillBtnList={selectedHiringManagers}
                      pillBtnOptions={{
                        getKey: (data: HiringManagerInfo) =>
                          `hiring_manager_${data.id}`,
                        getLabel: (data: HiringManagerInfo) => `${data.name}`,
                      }}
                      onPillBtnRemove={onRemovePillButton}
                      onClearAllBtnClick={onClearAll}
                    ></PillButtonListView>
                  </div>
                )}
                <div
                  className={`d-flex justify-center ${isMobile ? "px-20 pb-20" : "mt-10 mb-40"}`}
                >
                  <SaveCancelAction
                    onCancel={onCancel}
                    onSave={() => onApply()}
                    cancelLabelName={t("jobsite.common.cancel") as string}
                    saveLabelName={
                      t(
                        isMobile
                          ? "jobsite.profile.yourRoles.done"
                          : "jobsite.search.applyFilters",
                      ) as string
                    }
                    cancelButtonId={idGenerator(
                      "filter",
                      "hiring-manager-cancel",
                    ).generateId("button")}
                    saveButtonId={idGenerator(
                      "filter",
                      "hiring-manager-save",
                    ).generateId("button")}
                  />
                </div>
              </div>
            </>
          }
        >
          <>
            {isMobile ? (
              <h3
                className="mobile-modal-header"
                id={SEARCH_ADD_MANAGER_TITLE_ID}
              >
                {t("jobsite.search.hiringManagerLink")}
              </h3>
            ) : (
              <>
                <h2
                  className="t-headline pt-50 text-center"
                  id={SEARCH_ADD_MANAGER_TITLE_ID}
                >
                  {t("jobsite.search.hiringManagerLink")}
                </h2>
              </>
            )}
            <div className="row hiring-manager-form align-center justify-center">
              <div className="large-6 small-11">
                <Textbox
                  id={idGenerator("filter", "hiringmanager").generateId()}
                  required={false}
                  hideLabel={true}
                  search={true}
                  ref={inputRef}
                  value={hiringManagerInput}
                  onKeyDown={handleSearchEnter}
                  onReset={handleResetInput}
                  onValueChange={(val) => setHiringManagerInput(val)}
                  aria-label={t("jobsite.search.hiringManager") as string}
                  labelA11y={t("jobsite.search.hiringManager") as string}
                  resetA11y={t("jobsite.common.clearField") as string}
                ></Textbox>
              </div>
              <div className="large-3 small-11 hiring-manager-form-search-input">
                <Button
                  id={idGenerator("filter", "hiring-manager-modal").generateId(
                    "search-button",
                  )}
                  color="secondary"
                  size="base"
                  blockedVariant={true}
                  onClick={getHiringManagerList}
                  label={t("jobsite.search.search") as string}
                ></Button>
              </div>
            </div>
            <div className="u-border-bottom"></div>
            <div
              className={`pt-30 hiring-manager-modal-content ${isMobile ? "px-30" : "px-50"}`}
            >
              <div role="status" aria-live="polite">
                <span
                  className={`${isMobile ? "t-body-reduced fw-bold" : "t-eyebrow-reduced text-center"}`}
                >
                  {resultCountText}
                </span>
              </div>

              <ul role="list" className="list-nobullet">
                {hiringManagers.map(
                  (hiringManagerInfo: HiringManagerInfo, index: number) => {
                    const {
                      fullName,
                      email,
                      department,
                      location,
                      selected,
                      id: managerId,
                    } = hiringManagerInfo;
                    const label = selected
                      ? t("jobsite.common.remove")
                      : t("jobsite.search.add");
                    const ariaLabel = `${label} ${fullName}`;
                    const handleButtonClick = () =>
                      selected ? removeFilter(managerId) : addFilter(managerId);
                    return (
                      <li
                        key={fullName}
                        role="listitem"
                        className={`row u-list-style-none ml-0 ${
                          index < hiringManagers.length - 1
                            ? "u-border-bottom"
                            : ""
                        } ${isMobile ? "pt-20 pb-30 t-body-reduced" : "py-20 gap-10"}`}
                      >
                        <div className="large-4 small-12">
                          <h3>{fullName}</h3>
                          <p className="item-details">{email}</p>
                        </div>
                        <div className="large-4 small-12">
                          <p>{department}</p>
                          <p className="item-details mt-15">{location}</p>
                        </div>
                        <div
                          className={`large-offset-1 large-2 small-offset-0 small-12 text-center ${isMobile ? "mt-15" : ""}`}
                        >
                          <Button
                            label={label as string}
                            size="base"
                            blockedVariant={true}
                            color="secondary"
                            onClick={handleButtonClick}
                            aria-label={ariaLabel}
                            id={idGenerator(
                              "filter",
                              "hiring-manager-modal",
                            ).generateId(`toggle-button-${managerId}`)}
                          />
                        </div>
                      </li>
                    );
                  },
                )}
              </ul>
            </div>
          </>
        </Overlay>
      )}
    </>
  );
}
