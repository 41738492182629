export function testInvalidCountryStateZipCodeChar(char: string) {
  const regexp = /[\<\>\!\@\#\$\%\*\&\^\_\=\{\}\[\]\|\;\:\~\/]+$/g;
  return regexp.test(char);
}

export function testInvalidCharRegexOnName(char: string) {
  const regexp = /[\<\>\/\!\@\#\$\%\*\&\^\=\{\}\[\]\|\\\;\:\~\_]+$/g;
  return regexp.test(char);
}

export function testInvalidCharRegexOnJobTitle(char: string) {
  const regexp = /[\<\>\*\^\_\{\}\[\]]/g;
  return regexp.test(char);
}

export function testInvalidCharOnSchoolSkill(char: string) {
  const regexp = /[\<\>\*\^\{\}\[\]\|]+$/g;
  return regexp.test(char);
}

export function testInvalidCharRegexOnReasonAndRelation(char: string) {
  const regexp = /[\<\>\*\^\_\{\}\[\]\|\\\\\\]/g;
  return regexp.test(char);
}

/**
 * This function will test regular expression against Street, City or District
 * @param {string} char This is the Street, City or District to be tested against regular expression
 * @returns boolean true if Street, City or District has bad data
 */
export function testRegexOnStreetCityDistrict(char: string) {
  const regexp = /[\<\>\$\*\^\_\=\{\}\[\]\|\;\:\~\\\\\\]+$/g;
  return regexp.test(char);
}

/**
 * This function will test regular expression against email
 * @param {string} char This is the email to be tested against regular expression
 * @returns boolean false if email has bad data
 */
export function testRegexOnEmailChar(char: string) {
  const regexp = /[a-zA-Z0-9\.\@\!\#\\\$\%\&\'\*\+\-\/\=\?\^\_\`\{\|\}\~]+$/;
  return !regexp.test(char);
}

/**
 * This function will test if pass value is a valid email format or not
 * @param {string} value This is the email to be tested against regular expression
 * @returns boolean false if email has bad data
 */

export function testRegexonEmail(value: string) {
  const reg =
    /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+)*))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gim;

  if (reg.test(value)) {
    return true;
  }
  return false;
}

/**
 * This function will test regular expression against phone number
 * @param {string} char This is the phone number to be tested against regular expression
 * @returns boolean true if phone number has bad data
 */
export function testRegexOnPhoneNumber(char: string) {
  const regexp = /[\<\>\$\%\&\^\_\=\{\}\[\]\|\:\\\\\\]+$/g;
  return regexp.test(char);
}
