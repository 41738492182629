import * as React from "react";
import APP_CONSTANTS from "../../../utilities/appConstants";
import HtmlRenderer from "../../components/base/HtmlRenderer";
import translateReplace from "../../hooks/translateReplace";
import useIntlMessage from "../../hooks/useIntlMessage";

export function LocationPickerHero() {
  const { t } = useIntlMessage();
  return (
    <section className="hero-section">
      <div className="hero-text-container">
        <h2 className="hero-header t-headline-reduced">
          {t("jobsite.locationPicker.learnAboutRolesInAppleRetail")}
        </h2>
      </div>
      <HtmlRenderer
        // linkUrl in translation is in single quotes so only using useIntlMessage is not replacing the linkUrl with actual value
        initialContent={translateReplace(
          t("jobsite.common.learnMoreAboutAppleRetail") as string,
          ["{linkUrl}"],
          [APP_CONSTANTS.LEARN_MORE_RETAIL_URL],
        )}
        htmlTag="div"
        classes="hero-link t-label mb-5"
      />
      <img
        alt=""
        className="hero-image"
        src="./../../../../public/images/hero-image.jpg"
      />
    </section>
  );
}
