import { ApplyAction } from "./ActionTypes";
import { ApplyState } from "./ApplyState";

export const reducer = (state: ApplyState, action: ApplyAction): ApplyState => {
  switch (action.type) {
    case "STEP":
      return {
        ...state,
        page: {
          firstStep: action.payload.firstStep,
          stepNames: action.payload.stepNames,
          stepMapping: action.payload.stepMapping,
          skipSelfDisclosure: action.payload.skipSelfDisclosure,
          questionnaireError: action.payload.questionnaireError,
          showReviewSubmit: action.payload.showReviewSubmit,
        },
      };
    case "SELECTED_LOCATIONS":
      return {
        ...state,
        selectedLocations: action.payload,
      };
    case "TALENT":
      return {
        ...state,
        talent: action.payload,
      };

    case "CONNECTION":
      return {
        ...state,
        connection: action.payload,
      };

    case "DISCARD_FILE":
      return {
        ...state,
        discardedFiles: [
          ...(state.discardedFiles ? state.discardedFiles : []),
          { ...action.payload },
        ],
      };

    case "RESUME":
      return {
        ...state,
        resume: action.payload,
      };

    case "TEXT_RESUME":
      return {
        ...state,
        textResume: action.payload,
      };

    case "DISCARD_RESUME":
      return {
        ...state,
        discardResume: action.payload,
      };

    case "LINKEDIN":
      return {
        ...state,
        talent: {
          ...state.talent,
          externalProfiles: { linkedin: action.payload },
        },
      };

    case "LINKEDIN_DISCARD":
      return {
        ...state,
        talent: {
          ...state.talent,
          externalProfiles: null,
        },
      };

    case "PROFILE_UPDATE_SELECTION":
      return {
        ...state,
        talent: {
          ...state.talent,
          profileUpdateSelection: action.payload,
        },
      };

    case "SUPPORTING_FILE":
      return {
        ...state,
        supportFiles: action.payload,
      };

    case "SUPPORTING_FILE_CATEGORY":
      return {
        ...state,
        updatedFileCategories: {
          ...state.updatedFileCategories,
          [action.payload.key]: action.payload.value,
        },
      };

    case "SUPPORTING_LINK":
      return {
        ...state,
        talent: {
          ...state.talent,
          links: action.payload,
        },
      };

    case "SELF_DISCLOSURE":
      return {
        ...state,
        talent: {
          ...state.talent,
          selfDisclosures: action.payload,
        },
      };

    case "SELF_DISCLOSURE_COUNTRY":
      return {
        ...state,
        talent: {
          ...state.talent,
          selfDisclosures: {
            ...state.talent.selfDisclosures,
            [action.payload.country]: action.payload.value,
          },
        },
      };

    case "QUESTIONNAIRE":
      return {
        ...state,
        questionnaireAnswers: action.payload,
      };
    case "CONTACT_UPDATE":
      return {
        ...state,
        talent: {
          ...state.talent,
          contact: {
            ...state.talent.contact,
            [action.payload.key]: action.payload.value,
          },
        },
      };
    case "ADDRESS_UPDATE":
      const addresses = {
        ...((state.talent &&
          state.talent.addresses &&
          state.talent.addresses[0]) ||
          {}),
      };
      return {
        ...state,
        talent: {
          ...state.talent,
          addresses: [
            {
              ...addresses,
              ...action.payload,
            },
          ],
        },
      };
    case "UPDATE_LINKEDIN_SNAPSHOT":
      return {
        ...state,
        linkedinSnapshot: action.payload,
      };
    case "PRONOUNS_UPDATE":
      return {
        ...state,
        talent: { ...state.talent, displayPronouns: action.payload },
      };
    default:
      return state;
  }
};
