import { Alert, NativeButton } from "@rpe-js/marcom-web-components";
import React, { HTMLAttributes, useEffect } from "react";
import { focusElement } from "../../utils/focusUtil";
import HtmlRenderer from "./HtmlRenderer";
import Icon from "./IconComponent";

interface AlertWrapperProps extends HTMLAttributes<HTMLDivElement> {
  message?: string;
  closePosition?: string;
  error?: boolean;
  classes?: string;
  children?: React.ReactNode;
  alignCenter?: boolean;
  remove?: {
    closeBtnAriaLabel: string;
    onRemove?: () => void;
    idToFocus?: string;
  };
  id: string;
}
export function AlertWrapper({
  message,
  closePosition = "left",
  children,
  error = false,
  classes,
  alignCenter = true,
  remove,
  id,
  ...rest
}: AlertWrapperProps) {
  const alertContentId = id.toString();
  const ariaLabelWrapper = rest["aria-label"]
    ? (rest["aria-label"] as string)
    : "";

  const closeButton = () => {
    return (
      <div>
        <NativeButton
          className={`d-flex ${closePosition == "left" ? "mr-10" : "ml-10"} `}
          onClick={remove?.onRemove}
          aria-label={remove?.closeBtnAriaLabel}
          id={`${alertContentId}-button`}
        >
          <Icon name="x-grey" size="xsmall" cursor="pointer"></Icon>
        </NativeButton>
      </div>
    );
  };

  useEffect(() => {
    // Invoking focusElement method only if `remove?.idToFocus` exists
    return () => {
      if (remove?.idToFocus) {
        focusElement(remove.idToFocus);
      }
    };
  }, [remove?.idToFocus]);

  return (
    <>
      <Alert
        error={error}
        className={`${classes ? classes : ""}`}
        role={error ? "alert" : "group"}
        //per AX req, do not include aria-labelledby if there is an aria-label
        {...(ariaLabelWrapper.length < 1
          ? { ["aria-labelledby"]: alertContentId }
          : { ["aria-label"]: ariaLabelWrapper })}
        {...rest}
      >
        <section className={`d-flex ${alignCenter ? "text-center" : ""}`}>
          {remove?.onRemove && closePosition == "left" && closeButton()}
          {!children && (
            <HtmlRenderer
              initialContent={message as string}
              htmlTag="span"
              id={alertContentId}
              classes="flex-1"
            />
          )}
          {children && (
            <span id={alertContentId} className="flex-1">
              {children}
            </span>
          )}
          {remove?.onRemove && closePosition !== "left" && closeButton()}
        </section>
      </Alert>
    </>
  );
}
