import React from "react";
import { Info } from "./Info";

interface ReviewInfoListViewProps<T> {
  data: T[] | null;
  dataOptions: {
    getKey: (value: T) => string;
    getLabel: (value: T) => string;
  };
  noResultsLabel: string;
  classes?: string[];
}

export function ReviewInfoListView<T>({
  data,
  dataOptions,
  noResultsLabel,
  classes = [],
}: ReviewInfoListViewProps<T>) {
  return (
    <>
      {data && data.length > 0 ? (
        <ul role="list" className="row list-nobullet">
          {data.map((item) => (
            <li
              key={dataOptions.getKey(item)}
              role="listitem"
              className={`column large-6 small-12 pr-5 ${classes.join(" ")}`}
            >
              {dataOptions.getLabel(item)}
            </li>
          ))}
        </ul>
      ) : (
        <Info value={noResultsLabel} />
      )}
    </>
  );
}
