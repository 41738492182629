import { LinkedInProfilePreview } from "../config";

export interface Talent {
  id: string;
  talentId: string;
  talentDSID: string;
  talentTypeID?: string;
  languages?: Language[];
  addresses?: Address[];
  selfDisclosures?: Record<string, SelfDisclosure>;
  contact?: Contact;
  educationDegrees?: Education[];
  employments?: EmploymentDetails[];
  externalProfiles?: Record<string, ExternalProfile | null> | null;
  localeNameIndicator?: boolean;
  kanjiIndicator?: boolean;
  connectionsCount?: bigint;
  userType?: string;
  dsType?: number;
  sourceName?: string;
  sourceID?: string;
  submittedFlow?: boolean;
  lastLoginTime?: Date;
  displayPronouns?: boolean;
  genderPronoun?: GenderPronoun;
  privacyPolicy?: PrivacyPolicy | null;
  enableUSEmploymentApplication?: boolean;
  applicationId?: string;
  resetStatus?: boolean;
  employmentHasAtLeastOneJob?: boolean;
  links?: Link[];
  skills?: Skill[];
  mobilityPreferences?: MobilityPreference;
  teamsOfInterest?: Team[];
  preferredLocations?: PreferredLocations[];
  employmentTypes?: EmploymentType[];
  discovered?: boolean;
  hideNationality?: boolean;
  hideGender?: boolean;
  emails?: Emails;
  _id?: string;
  profileUpdateSelection?: string;
  linkedinLastAction?: string;
  resumeLastAction?: string;
  lastAction?: string;
  recruiterLastModification?: RecruiterLastModification;
}
export interface RecruiterLastModification {
  date?: Date;
  by?: bigint;
  subDocs?: string[];
  lastModification?: LastModification;
  talentLastModificationTime?: Date;
}

export interface LastModification {
  time: number;
  by: bigint;
  byDSID?: string;
  oldValues?: Record<string, any>;
  old?: Record<string, any>;
  firstName?: string;
  lastName?: string;
  preferredName?: string;
}

export interface Language {
  languageID: string;
  languageName?: string;
  proficiencyID?: string;
  proficiencyName?: string;
  preferredLanguage?: boolean;
  dateAcquired?: string;
  langDateAcquired?: bigint;
}

export enum AddressCategory {
  "addressType-preferred" = "addressType-preferred",
  "addressType-secondary" = "addressType-secondary",
  "addressType-other" = "addressType-other",
}
export type AddressType = keyof typeof AddressCategory;

export interface Address {
  typeID?: AddressType;
  line1?: string;
  line2?: string;
  line3?: string;
  district?: string;
  city?: string;
  county?: string;
  state?: string;
  countryID?: string;
  countryName?: string;
  zip?: string;
  addressTemplateID?: string;
}

export interface Emails {
  preferredEmail: {
    verifiedStatus: boolean;
    verificationTypeID: string;
    verifiedDate: Date;
  };
}

export interface SelfDisclosure {
  genderID: string;
  genderName?: string;
  nationalityID?: string;
  nationalityName?: string;
  ethnicityID?: string;
  ethnicityName?: string;
  veteranStatus?: VeteranStatus;
  disability?: DisabilityInfo;
  countryID?: string;
  countryName?: string;
  updated?: boolean;
}

export interface SelfDisclosureInfo extends SelfDisclosure {
  attributePermissions?: Record<string, AttributePermissionDetails>;
  addedSource?: string;
  version?: number;
}

export interface VeteranStatus {
  veteranStatusID: string;
  veteranStatusName?: string;
}

export interface VeteranStatusInfo extends VeteranStatus {
  version?: number;
}

export interface Disability {
  statusID: string;
  statusName?: string;
  name?: string;
  completedOn?: Date | string;
}

export interface DisabilityInfo extends Disability {
  version?: number;
  employeeID?: string;
  jobTitle?: string;
  dateOfHire?: Date;
  lastRoleApplyJobID?: string;
}

export interface AttributePermissionDetails {
  editable?: boolean;
  visible?: boolean;
}

export interface Contact {
  firstName?: string;
  lastName?: string;
  preferredName?: string;
  local?: Name;
  kanji?: Name;
  fullName?: string;
  preferredEmail?: string;
  preferredPhone?: string;
  preferredPhoneExtension?: string;
  secondaryEmail?: string;
  secondaryPhone?: string;
  otherEmail?: string;
  otherPhone?: string;
  workDayLinks?: {
    firstName: string;
    lastName: string;
    preferredName: string;
    pronoun: string;
    homePage: string;
    preferredAddress: string;
  };
}

export interface Name {
  firstName?: string;
  lastName?: string;
}

export interface Education {
  degreeName?: string;
  degreeID?: string;
  school?: string; // Contains name of the School field name chosen or typed by user
  schoolID?: string;
  schoolName?: string;
  majorField?: string; // Contains name of the Major field name chosen or typed by user
  majorFieldID?: string;
  majorFieldName?: string;
  graduationStatusName?: string;
  graduationStatusID?: string;
  rating?: string;
}

export interface EmploymentDetails {
  currentEmployer?: boolean;
  employerID?: string;
  employerName?: string;
  employer?: string;
  jobTitle?: string;
  startMonthID?: string;
  startMonthName?: string;
  startYear?: string;
  endMonthID?: string;
  endMonthName?: string;
  endYear?: string;
  workdayData?: boolean;
  addedSource?: string;
}

export interface ExternalProfile {
  url?: string;
  addedOn?: Date | number | string;
}

export interface LinkedInProfileData {
  externalProfile: ExternalProfile;
  profileData: LinkedInProfilePreview;
}

export interface ExternalProfileLinkedRequest {
  snapshotType: string;
  talentId: string;
  source: string;
  snapshot: string;
  publicProfileUrl: string;
}

export interface GenderPronoun {
  deleted?: boolean;
  updated?: boolean;
  subjective?: string;
  objective?: string;
  possessive?: string;
  other?: string;
  id?: bigint;
}

export interface InvitedTalentDetail {
  talentId?: string;
}

export interface VerifyInvitedProfile {
  invitedEmailId: string;
  loggedInUserEmailId: string;
  invitedTalentId?: string;
  invitedInvitationId?: string;
}
export interface TalentPrivacyPolicyStatus {
  privacyPolicyAccepted: boolean;
  preferredEmailVerified: boolean;
  invitedTalentDetails?: InvitedTalentDetail | null;
  verifyInvitedProfile?: VerifyInvitedProfile | null;
  active?: boolean;
  dsType?: string;
}

export interface PrivacyPolicy {
  acknowledgedDate?: number;
  version?: number;
  sourceID?: string;
  countryID?: string;
  countrySpecific?: CountrySpecificPrivacyPolicy[];
  dataConsent?: DataUsageConsent | null;
}

export interface CountrySpecificPrivacyPolicy {
  countryID?: string;
  acknowledgedDate?: Date;
  version?: number;
}

export interface Link extends TalentBaseModel {
  link?: string;
  categoryName?: string;
  categoryID?: string;
  addedByFirstName?: string;
  addedByPreferredName?: string;
  addedByLastName?: string;
}

export interface TalentBaseModel {
  addedSource: string;
  addedByDSID: bigint;
  addedOn: Date;
}

export interface Skill {
  skillID: string;
  skillName?: string;
  skill?: string;
}

export interface MobilityPreference {
  willingToRelocate?: boolean;
  willingToTravel?: boolean;
  country?: string;
  state?: string;
  city?: string;
  typeOfAssignment?: string;
  lastUpdatedDate?: string;
  countryID?: string;
  stateID?: string;
  cityID?: string;
}

export interface Team {
  teamID?: string;
  teamName?: string;
  subTeams?: SubTeam[];
}

export interface SubTeam {
  id?: string;
  name?: string;
  code?: string;
  subTeamID?: string;
  subTeamName?: string;
}

export interface PreferredLocations {
  cityName?: string;
  stateProvinceName?: string;
  countryName?: string;
  locationID?: string;
  stateProvinceID?: string;
  cityID?: string;
  countryID?: string;
  locationCode?: string;
  locationName?: string;
  state?: string;
  country?: string;
  city?: string;
}

export interface EmploymentType {
  employmentTypeID: string;
  employmentTypeName?: string;
}

export interface TalentParsedExternalModel
  extends Pick<
    Talent,
    | "talentId"
    | "skills"
    | "languages"
    | "employments"
    | "educationDegrees"
    | "lastAction"
    | "externalProfiles"
  > {
  talentLinks?: TalentLink[];
}

export interface TalentLink {
  id: string;
  link?: string;
  talentMongoId?: string;
  category?: string;
}

type YesOrNo = "Yes" | "No";
export interface TalentRoleUpdateRequestBody {
  favorited: YesOrNo;
  positionID: string;
  talentID: string;
  userType: number | null;
}
export interface PrivacyPolicyUpdateResponse {
  preferredEmailVerified?: boolean;
}

export interface CountrySpecificSelfDisclosureConfig {
  id: string;
  countryID?: string;
  gender?: boolean;
  ethnicity?: boolean;
  veteranStatus?: boolean;
  disability?: boolean;
  pendingHire?: boolean;
  nationality?: boolean;
}

export type SourceID = "talentCreateSource-erp" | "talentCreateSource-js";
export enum ProfileSaveCategory {
  mobilityPreferences = "mobilityPreferences",
  employmentTypes = "employmentTypes",
  skills = "skills",
  languages = "languages",
  preferredLocations = "preferredLocations",
  educationDegrees = "educationDegrees",
  selfDisclosures = "selfDisclosures",
  contactInfo = "contactInfo",
  links = "links",
  employments = "employments",
  teamsOfInterest = "teamsOfInterest",
  parentFields = "parentFields",
}

export type AllowedProfileSaveCategories = keyof typeof ProfileSaveCategory;

export interface ExternalProfileLinkedRequest {
  snapshotType: string;
  talentId: string;
  source: string;
  snapshot: string;
  publicProfileUrl: string;
}

export interface DataUsageConsent
  extends Pick<CountrySpecificPrivacyPolicy, "countryID" | "version"> {
  acknowledgedDate?: number;
  optIn?: boolean;
}
