import { findIndex } from "lodash";

export function mergeSelectedIntoAll(allList: any, selectedList: any) {
  const updatedAllList = [...allList];
  for (let i = 0; i < updatedAllList.length; i++) {
    updatedAllList[i].selected = false;
  }
  for (let i = 0; i < selectedList.length; i++) {
    const index = findIndex(
      updatedAllList,
      (item) =>
        (!selectedList[i].teamID &&
          selectedList[i].id &&
          selectedList[i].id == item.id) ||
        selectedList[i] == item.name ||
        (selectedList[i].id == item.id &&
          selectedList[i].teamID == item.teamID),
    );
    if (index >= 0) {
      updatedAllList[index].selected = true;
    }
    if (index === -1) {
      updatedAllList.push({ ...selectedList[i], selected: true });
    }
  }
  return updatedAllList;
}

export function regExpEscapeForTeamsFilter(input: string) {
  let replacePattern = "";
  if (input) {
    replacePattern = input.replace(/[()]/g, "");
  }
  return replacePattern;
}
