import React, { createContext, useContext } from "react";
import { ApplyAction } from "./ActionTypes";
import { ApplyState } from "./ApplyState";

// Create a context
export const ApplyContext = createContext<
  | {
      state: ApplyState;
      dispatch: React.Dispatch<ApplyAction>;
    }
  | undefined
>(undefined);

// Custom hook to use the ApplyContext
export const useApplyContext = (): {
  state: ApplyState;
  dispatch: React.Dispatch<ApplyAction>;
} => {
  const context = useContext(ApplyContext);
  if (!context) {
    throw new Error("useApplyContext must be used within an ApplyProvider");
  }
  return context;
};
