import React, { useCallback, useRef } from "react";
import { SkillByInputRefData } from "../../../../shared/types/refData";
import { Skill } from "../../../../shared/types/talent/talent";
import { getSkillsByInput } from "../../../api/fetchClient";
import PillButton from "../../../components/base/PillButton";
import TypeaheadComponent, {
  typeaheadstandardCssClasses,
} from "../../../components/base/Typeahead/TypeaheadComponent";
import { getTypeaheadTextBoxId } from "../../../components/base/Typeahead/typeaheadUtils";
import { useFormInputValidator } from "../../../components/feature/form/inputValidators/useFormInputValidator";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { focusManagementOnRemove } from "../../../utils/focusUtil";
import { idGenerator } from "../../../utils/idGenerator";

type EditSkillsProps = {
  handleAddSkill: (skillToAdd: SkillByInputRefData) => void;
  handleRemoveSkill: (index: number) => void;
  errorMessage: string;
  skills: Skill[];
  mode: "profile" | "parsedmodal";
  elementToFocusOnClose: string;
};

export const EditSkills = ({
  handleAddSkill,
  handleRemoveSkill,
  errorMessage,
  skills,
  mode,
  elementToFocusOnClose,
}: EditSkillsProps) => {
  const { t } = useIntlMessage();
  const { validateSchoolSkill, onPasteValidateSchoolSkill } =
    useFormInputValidator();
  const itemsListRef = useRef<HTMLUListElement | null>(null);
  const SKILLS_TYPEAHEAD_ID = idGenerator(
    mode,
    "skills-typeahead",
  ).generateId();

  const onHandleRemoveSkill = useCallback(
    (index: number) => {
      focusManagementOnRemove(
        itemsListRef,
        index,
        skills ? skills.length : 0,
        getTypeaheadTextBoxId(SKILLS_TYPEAHEAD_ID),
      );
      handleRemoveSkill(index);
    },
    [SKILLS_TYPEAHEAD_ID, handleRemoveSkill, skills],
  );

  return (
    <>
      <TypeaheadComponent
        apiEndpoint={getSkillsByInput}
        elementToFocusOnClose={elementToFocusOnClose}
        onSelect={(e: any) => {
          handleAddSkill(e);
        }}
        highlightMatches={true}
        showSearchIcon={true}
        getSuggestionLabel={(suggestion) => suggestion?.name}
        label={t("jobsite.common.enterSkills") as string}
        suggestionPlaceholder={t("jobsite.common.suggestedSkills") as string}
        removeOnSelect={true}
        minChars={2}
        classNames={typeaheadstandardCssClasses}
        errorMessage={errorMessage}
        errorA11y={t("jobsite.common.errorIconLabel") as string}
        id={SKILLS_TYPEAHEAD_ID}
        strict={false}
        validators={{
          onKeyDown: validateSchoolSkill,
          onPaste: onPasteValidateSchoolSkill,
        }}
      />
      <ul
        className="d-flex flex-wrap column large-12 small-12 list-nobullet"
        ref={itemsListRef}
        role="list"
      >
        {skills.map((skill: Skill, index) => (
          <li
            role="listitem"
            key={`skill_${[skill.skillID, skill.skillName?.replaceAll(" ", "_")].filter(Boolean).join("_")}`}
          >
            <PillButton
              id={idGenerator(mode, "skills").generateId(`${index}`)}
              label={skill.skillName as string}
              buttonSize="small"
              onRemove={() => onHandleRemoveSkill(index)}
            />
          </li>
        ))}
      </ul>
    </>
  );
};
