import { useCallback, useState } from "react";

interface FetchDataStatus<D> {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error?: any;
  data: D | null | undefined;
}

export function useFetchDataStatus<T>() {
  const [fetchDataStatus, setFetchDataStatus] = useState<FetchDataStatus<T>>({
    isError: false,
    isLoading: false,
    isSuccess: false,
    data: null,
    error: null,
  });

  const updateFetchStatus = useCallback(
    (
      isLoading: boolean,
      isSuccess: boolean,
      isError: boolean,
      data: T | null | undefined,
      error: any,
    ) => {
      setFetchDataStatus({
        isError,
        isLoading,
        isSuccess,
        data,
        error,
      });
    },
    [],
  );

  const resetFetchStatus = useCallback(() => {
    setFetchDataStatus({
      isError: false,
      isLoading: false,
      isSuccess: false,
      data: null,
      error: null,
    });
  }, []);

  return { ...fetchDataStatus, updateFetchStatus, resetFetchStatus };
}

export function useFetchData<T>(fn: (...args: any) => Promise<T | undefined>) {
  const {
    isError,
    isLoading,
    isSuccess,
    data,
    error,
    updateFetchStatus,
    resetFetchStatus,
  } = useFetchDataStatus<T>();

  const fetchData = useCallback(
    async (args?: Array<any>) => {
      updateFetchStatus(true, false, false, null, null);
      const params: any = args?.length ? args : [];
      if (!isLoading) {
        fn(...params)
          .then((result: T | undefined) => {
            updateFetchStatus(false, true, false, result, null);
          })
          .catch((e: any) => {
            updateFetchStatus(false, false, true, null, e);
          });
      }
    },
    [updateFetchStatus, fn, isLoading],
  );

  return {
    isLoading,
    data,
    fetchData,
    resetFetchStatus,
    isSuccess,
    error,
    isError,
  };
}
