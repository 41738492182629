import { Button } from "@rpe-js/marcom-web-components";
import React from "react";
import { SaveCancelAction } from "../../components/feature/saveAndCancel";
import useIntlMessage from "../../hooks/useIntlMessage";
import { idGenerator, ModuleName } from "../../utils/idGenerator";

interface FooterSectionProps {
  onContinue: () => void;
  onBack: () => void;
  onReviewAndSubmit: () => void;
  enableReviewSubmitBtn: boolean;
  module: ModuleName;
}

export function FooterSection({
  onContinue,
  onBack,
  onReviewAndSubmit,
  enableReviewSubmitBtn,
  module,
}: FooterSectionProps) {
  const { t } = useIntlMessage();

  return (
    <>
      <section className="reviewinfo-container mtb-auto-0 d-flex justify-center pt-20 save-cancel-btngroup">
        <SaveCancelAction
          cancelLabelName={t("jobsite.common.back") as string}
          saveLabelName={t("jobsite.common.continue") as string}
          onCancel={onBack}
          onSave={onContinue}
          classes="w-50"
          cancelButtonId={idGenerator(module, "step-cancel").generateId(
            "button",
          )}
          saveButtonId={idGenerator(module, "step-continue").generateId(
            "button",
          )}
        />
        {enableReviewSubmitBtn && (
          <>
            <div className="d-flex w-25 save-cancel-btngroup">
              <span className="ml-10 d-flex-equal">
                <Button
                  id={idGenerator(module, "step-review-submit").generateId(
                    "button",
                  )}
                  size="base"
                  color="primary"
                  blockedVariant={true}
                  label={t("jobsite.common.reviewSubmit") as string}
                  onClick={onReviewAndSubmit}
                />
              </span>
            </div>
          </>
        )}
      </section>
    </>
  );
}
