import { Dropdown, DropDownOptionProps } from "@rpe-js/marcom-web-components";
import { isEmpty } from "lodash";
import React, { useCallback, useMemo } from "react";
import { BaseRefData, RefData } from "../../../../shared/types/refData";
import useIntlMessage from "../../../hooks/useIntlMessage";

export function CountryDropdownView(props: {
  refData: BaseRefData[];
  id: string;
  selectedCountry: string | null;
  showError?: boolean;
  onChange?: (
    evt: React.ChangeEvent<HTMLInputElement>,
    selectedOption: DropDownOptionProps,
    selectedRefData: RefData,
  ) => void;
  isDisabled?: boolean;
  noTranslation?: boolean;
}) {
  const {
    refData,
    id,
    selectedCountry,
    showError = false,
    onChange,
    isDisabled = false,
    noTranslation = false,
  } = props;
  const { t } = useIntlMessage();

  const options: DropDownOptionProps[] = useMemo((): DropDownOptionProps[] => {
    const options = refData.map(
      (rf) =>
        ({
          label: rf.name,
          value: rf.id,
        }) as DropDownOptionProps,
    );
    return [
      {
        label: t("jobsite.common.selectCountry") as string,
        value: "",
        disabled: true,
      },
      ...options,
    ];
  }, [refData, t]);

  const onCountryChange = useCallback(
    (
      evt: React.ChangeEvent<HTMLInputElement>,
      selectedOption: DropDownOptionProps,
    ) => {
      const selectedRefData = refData.find(
        (rf) => rf.id === selectedOption.value,
      );
      if (onChange) {
        onChange(evt, selectedOption, selectedRefData as RefData);
      }
    },
    [refData, onChange],
  );

  const getSelectedCountry = useCallback(() => {
    if (!isEmpty(selectedCountry)) {
      return selectedCountry;
    }
    return "";
  }, [selectedCountry]);

  return (
    <>
      <Dropdown
        required={true}
        name={
          t(
            noTranslation
              ? "jobsite.profile.myApplication.selectcountryorregion"
              : "jobsite.common.selectCountry",
          ) as string
        }
        label={
          t(
            noTranslation
              ? "jobsite.profile.myApplication.selectcountryorregion"
              : "jobsite.common.selectCountry",
          ) as string
        }
        options={options}
        allowNone={false}
        id={id}
        value={getSelectedCountry() as string}
        error={
          showError
            ? (t(
                noTranslation
                  ? "jobsite.profile.myApplication.errorCountry"
                  : "jobsite.common.errorCountry",
              ) as string)
            : ""
        }
        errorA11y={t("jobsite.common.errorIconLabel") as string}
        handleValueSelect={onCountryChange}
        disabled={isDisabled}
      />
    </>
  );
}
