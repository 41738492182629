import { Dropdown, DropDownOptionProps } from "@rpe-js/marcom-web-components";
import React, { useCallback } from "react";
import { Answer } from "../../../../shared/types/questionnaire";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { idGenerator } from "../../../utils/idGenerator";
import ErrorMessage from "../../base/ErrorMessage";

interface RankedQuestionProps {
  questionId: string;
  question: string;
  answerOptions: Array<Answer>;
  instructions: string;
  canShowError: boolean;
  validateCorrectRankOrder: (answerOptions: Array<Answer>) => boolean;
  updateAnswer: (result: any) => void;
  requiredRankErrorMessage: string;
  selectCorrectRankErrorMessage: string;
}
export function RankedQuestion({
  questionId,
  question,
  answerOptions,
  instructions,
  canShowError,
  validateCorrectRankOrder,
  updateAnswer,
  requiredRankErrorMessage,
  selectCorrectRankErrorMessage,
}: RankedQuestionProps) {
  const { t } = useIntlMessage();
  const defaultLabel = t("jobsite.apply.selectRank") as string;
  const selectCorrectRankErrorMsg = t(selectCorrectRankErrorMessage) as string;
  const options: DropDownOptionProps[] = [
    {
      label: defaultLabel,
      value: defaultLabel,
      disabled: true,
    },
  ].concat(
    answerOptions.map((item, index) => ({
      label: item.rankOrder?.toString() || "",
      value: (index + 1).toString(),
      disabled: false,
    })),
  );

  const onOptionChange = useCallback(
    (answerId: string, option: DropDownOptionProps) => {
      updateAnswer({ questionId, answer: { answerId, rank: option } });
    },
    [questionId, updateAnswer],
  );

  return (
    <>
      <legend className="mb-10">{question}</legend>
      {instructions && <p>{instructions}</p>}
      {answerOptions.map((item, index) => (
        <section key={item.answerId}>
          <Dropdown
            id={idGenerator("profile", "questionnaire").generateId(
              `rankedquestion-${index}`,
            )}
            required={true}
            classes={{ select: "t-body" }}
            label={item.label}
            value={item.answeredRank?.toString() || defaultLabel}
            name={defaultLabel}
            options={options}
            handleValueSelect={(evt, option) =>
              onOptionChange(item.answerId, option)
            }
            error={
              canShowError && !item.answeredRank
                ? (t(requiredRankErrorMessage) as string)
                : ""
            }
            errorA11y={t("jobsite.common.errorIconLabel") as string}
          ></Dropdown>
        </section>
      ))}
      {canShowError && !validateCorrectRankOrder(answerOptions) && (
        <ErrorMessage
          errorId={`${questionId}_error`}
          message={selectCorrectRankErrorMsg}
          errorA11y={t("jobsite.common.errorIconLabel") as string}
        ></ErrorMessage>
      )}
    </>
  );
}
