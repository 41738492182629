import React, { useCallback, useState } from "react";
import {
  EducationDegreeRefData,
  GraduationStatusRefData,
} from "../../../../shared/types/refData";
import { Education } from "../../../../shared/types/talent/talent";
import { idGenerator } from "../../../utils/idGenerator";
import { EducationDegreeView } from "../components/educationSummary/EducationDegreeView";
import { useProfileContext } from "../context/ProfileContext";
import { EditEducationSummary } from "./editEducationSummary";
import { ProfileSection } from "./profileSection";

type EductionSummarySectionProps = {
  gradStatusData: GraduationStatusRefData[];
  educationDegreesData: EducationDegreeRefData[];
};

export const EductionSummarySection = ({
  gradStatusData,
  educationDegreesData,
}: EductionSummarySectionProps) => {
  const [isEdit, setEdit] = useState<boolean>(false);
  const { state } = useProfileContext();

  const { educationDegrees: incomingEducationDegrees = [] } = state;

  const onCancel = useCallback(() => {
    setEdit(false);
  }, []);

  const onSave = useCallback(() => {
    setEdit(false);
  }, []);

  return (
    <ProfileSection
      isEdit={isEdit}
      i18nTitle="jobsite.common.educationSummaryTitle"
      onEdit={() => setEdit(true)}
      id={idGenerator("profile", "educationDegrees").generateId()}
      sectionName="educationDegrees"
    >
      {isEdit && (
        <>
          <EditEducationSummary
            degreeOptions={educationDegreesData}
            gradStatusOptions={gradStatusData}
            educationDegree={incomingEducationDegrees}
            onSave={onSave}
            onCancel={onCancel}
          />
        </>
      )}
      {!isEdit && (
        <>
          {!(incomingEducationDegrees as Education[])?.length && (
            <EducationDegreeView education={null} />
          )}
          {(incomingEducationDegrees as Education[])?.length > 0 &&
            incomingEducationDegrees?.map((education, index) => (
              <div
                className={`${index !== 0 ? "u-border-top pt-10" : ""}`}
                key={index}
              >
                <EducationDegreeView education={education} />
              </div>
            ))}
        </>
      )}
    </ProfileSection>
  );
};
