import * as React from "react";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { getCheckboxClasses } from "../../util";
import { idGenerator } from "../../utils/idGenerator";
import CheckboxFilter from "./CheckboxFilter";
import { MappedRetailRolesOptions } from "./InternalStoreRolesFilterWrapper";

type Props = {
  rolesData: Array<MappedRetailRolesOptions>;
  onUpdateRoles: (products: Array<any>) => void;
  onRemoveRoles: () => void;
  isAccordionOpen: boolean;
  selectedCount: number;
};

export function InternalStoreRolesFilter({
  rolesData,
  onUpdateRoles,
  onRemoveRoles,
  selectedCount,
}: Props) {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  return (
    <CheckboxFilter
      title={t("jobsite.search.retailHrs") as string}
      data={rolesData}
      onUpdateSelectedItems={onUpdateRoles}
      onRemoveAllItems={onRemoveRoles}
      selectedCount={selectedCount}
      isAccordionOpen={false}
      legendLabel={t("jobsite.search.retailHrs") as string}
      idPrefix={"storeRoles"}
      filterParamKey={"retailRoles"}
      index={7}
      accordionId={idGenerator(
        "search",
        "storeRoles-filter-accordion",
      ).generateId()}
      classNames={getCheckboxClasses(isMobile)}
    />
  );
}
