import { AccordionItem } from "@rpe-js/marcom-web-components/lib/CustomAccordion/CustomAccordion";
import React, { useCallback, useEffect, useState } from "react";
import { getLanguagesByInput } from "../../api/fetchClient";
import TypeaheadComponent from "../../components/base/Typeahead/TypeaheadComponent";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { getCheckboxClasses } from "../../util";
import { mergeSelectedIntoAll } from "../../utils/filterUtil";
import { idGenerator } from "../../utils/idGenerator";
import { FilterCheckboxGroup } from "./FilterCheckboxGroup";
import { MappedLanguagesFilterOptions } from "./LanguageFilterWrapper";

interface Props {
  languageData: Array<MappedLanguagesFilterOptions>;
  onUpdateLanguages: (languages: Array<MappedLanguagesFilterOptions>) => void;
  onRemoveLanguages: () => void;
}

export function LanguageFilter({
  languageData,
  onUpdateLanguages,
  onRemoveLanguages,
}: Props) {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const [languages, setLanguages] = useState<MappedLanguagesFilterOptions[]>([
    ...languageData,
  ]);
  const [count, setCount] = useState(languages.length);
  const ACCORDION_ID = idGenerator(
    "search",
    "language-filter-accordion",
  ).generateId();

  // Handle adding new languages to the list
  // If the language already exists, update its properties
  const handleAddLanguage = useCallback(
    (newLanguage: MappedLanguagesFilterOptions) => {
      const updatedLanguages = languages.map(
        (language: MappedLanguagesFilterOptions) =>
          language.id === newLanguage.id
            ? { ...language, ...newLanguage } // Update existing language
            : language,
      );

      // Check if the language already exists
      const isExisting = languages.some(
        (language: MappedLanguagesFilterOptions) =>
          language.id === newLanguage.id,
      );

      // If not existing, add the new language
      if (!isExisting) {
        updatedLanguages.push({ ...newLanguage });
      }

      // Update state and invoke callback
      setLanguages(updatedLanguages);
      setCount(updatedLanguages.length);
      onUpdateLanguages(
        updatedLanguages.filter((language) => language.selected),
      );
    },
    [languages, onUpdateLanguages],
  );

  const updateLanguage = useCallback(
    (id: string, selected: boolean) => {
      const updatedlanguages = languages.map(
        (language: MappedLanguagesFilterOptions) =>
          language.id === id ? { ...language, selected } : language,
      );
      setLanguages(updatedlanguages);

      const selectedLanguages = updatedlanguages.filter(
        (language: MappedLanguagesFilterOptions) => language.selected,
      );
      if (selectedLanguages.length == 0) {
        onRemoveLanguages();
      } else {
        onUpdateLanguages(selectedLanguages);
        setCount(selectedLanguages.length);
      }
    },
    [languages, onRemoveLanguages, onUpdateLanguages],
  );

  useEffect(() => {
    const updatedLanguages = [...mergeSelectedIntoAll(languages, languageData)];
    setLanguages(updatedLanguages);
    setCount(languageData.length);
    // const selectedLanguages = updatedLanguages.filter(
    //   (language) => language.selected,
    // );
    // setFilters({ lang: selectedLanguages });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageData]);

  return (
    <>
      <AccordionItem
        noPadding={true}
        clickableTitle={true}
        expandableIconsPlus={true}
        titleWrapperTag={"h3"}
        titleWrapperTagAttrs={{
          className: "d-flex",
        }}
        title={`<span class="d-flex-equal fw-medium">${t("jobsite.common.languageSkills")}</span>
                <span class="d-inline-block mr-5 mt-0 ${count > 0 ? "counter" : "d-none"}" aria-hidden="true">${count}</span>
                <span class="a11y">${t("jobsite.common.filterAppliedCount", { count: count })}</span>`}
        index={4}
        id={ACCORDION_ID}
      >
        <TypeaheadComponent
          elementToFocusOnClose="filter-mobile-modal-save-button"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          apiEndpoint={getLanguagesByInput}
          disableSelectedItem={true}
          selectedItems={{ key: "name", items: languages as any }}
          onSelect={(e) => {
            handleAddLanguage({
              ...(e as MappedLanguagesFilterOptions),
              selected: true,
            });
          }}
          hideLabel={true}
          removeOnSelect={true}
          getSuggestionLabel={(suggestion) => suggestion?.name}
          minChars={2}
          highlightMatches={true}
          showSearchIcon={!isMobile}
          placeholder={t("jobsite.search.enterLanguage") as string}
          suggestionPlaceholder={
            t("jobsite.search.suggestedLanguages") as string
          }
          isInputRequired={false}
          classNames={{
            container: "typeahead-container",
            inputWrapper: "typeahead-input-wrapper",
            list: "filter-typeahead-list",
            listItem: "filter-typeahead-list-item",
            button: "filter-typeahead-button",
            highlightedItem: "filter-typeahead-highlighted-item",
            suggestionPlaceholder: "filter-typeahead-suggestion-placeholder",
            highlight: "typeahead-highlighted-text",
            suggestionIcon: "filter-typeahead-suggestion-icon",
            suggestionText: "filter-typeahead-suggestion-text",
          }}
          id={idGenerator("filter", "language-typeahead").generateId()}
          strict={true}
          resetA11y={t("jobsite.search.clearLanguageVO") as string}
        />
        <FilterCheckboxGroup
          id={idGenerator("filter", "language").generateId()}
          filterList={languages}
          onFilterChange={updateLanguage}
          classNames={getCheckboxClasses(isMobile)}
        ></FilterCheckboxGroup>
      </AccordionItem>
    </>
  );
}
