import { ProgressIndicatorLoader } from "@rpe-js/marcom-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { SubTeamRefData, TeamsRefData } from "../../../../shared/types/refData";
import { Team } from "../../../../shared/types/talent/talent";
import { updateTalent } from "../../../api/fetchClient";
import { PROFILE_SECTIONS } from "../../../app.constants";
import ErrorMessage from "../../../components/base/ErrorMessage";
import { TeamsView } from "../../../components/base/TeamsView";
import { SaveCancelAction } from "../../../components/feature/saveAndCancel";
import { TeamOfInterestWidget } from "../../../components/feature/widgets/teamsOfInterest/teamOfInterestWidget";
import { useAppAlertContext } from "../../../contexts/AppAlert";
import { useFetchData } from "../../../hooks/useFetchData";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { handleScrollToElement } from "../../../utils/focusUtil";
import { idGenerator } from "../../../utils/idGenerator";
import { updateTeams } from "../context/Actions";
import { useProfileContext } from "../context/ProfileContext";
import { ProfileSection } from "./profileSection";

type TeamsInterestSectionProps = {
  teamsData: TeamsRefData[] | undefined;
};

export const TeamsInterestSection = ({
  teamsData,
}: TeamsInterestSectionProps) => {
  const [isEdit, setEdit] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<string | null>(null);
  const { t } = useIntlMessage();
  const { updateAlert, deleteAlert } = useAppAlertContext();
  const { state, dispatch } = useProfileContext();
  const teamsOfInterestIdGenerator = idGenerator("profile", "teamsOfInterest");
  const teamsOfInterestSectionId = teamsOfInterestIdGenerator.generateId();
  const [selectedTeams, setSelectedTeams] = useState<
    Record<string, { subTeam: string[]; isSelectAll: boolean }>
  >({});
  const [teamsList, setTeamsList] = useState<TeamsRefData[]>(teamsData || []);
  const [selectedTeamListView, setSelectedTeamListView] = useState<Team[]>([]);
  // Using selectedTeamListView to store the transformed teams list from teamsOfInterest
  // so that it can be displayed in view mode with the relevant details (team name, sub-teams).

  const {
    isLoading: isSaveTalentLoading,
    fetchData: saveTalent,
    isSuccess: isSaveTalentSuccess,
    isError,
    resetFetchStatus,
  } = useFetchData(updateTalent);

  // Initialize selected teams based on profile context
  useEffect(() => {
    const teamsDataRecord: Record<
      string,
      { subTeam: string[]; isSelectAll: boolean }
    > = {};
    const newTeams: Team[] = [];
    state?.teamsOfInterest?.forEach((team) => {
      if (team.teamID && team.subTeams?.length) {
        teamsDataRecord[team.teamID] = {
          subTeam: [
            ...team.subTeams?.map((subTeam) => subTeam?.subTeamID as string),
          ],
          isSelectAll: false,
        };
        const findTeam = teamsData?.find((teamRefData) => {
          return teamRefData.id === team.teamID;
        });
        const subTeams = team.subTeams?.map((subTeam) => {
          const findTeamSubteam = findTeam?.teams.find(
            (findSubteam) => findSubteam.id === subTeam.subTeamID,
          );
          return {
            ...subTeam,
            subTeamName: findTeamSubteam?.name,
          };
        });

        newTeams.push({
          ...team,
          teamName: findTeam?.type,
          ...subTeams,
          subTeams: [...subTeams],
        });
      }
    });
    setSelectedTeamListView(newTeams);
    setSelectedTeams(teamsDataRecord);
  }, [state?.teamsOfInterest, teamsData]);

  // Handle checkbox changes
  const handleCheckboxChange = useCallback(
    (updatedTeam: TeamsRefData, subTeam: SubTeamRefData, checked: boolean) => {
      setSelectedTeams((prevSelectedTeams) => {
        const updatedTeams = { ...prevSelectedTeams };
        const teamID = updatedTeam.id;
        const subTeamID = subTeam.id as string;
        updatedTeams[teamID] = checked
          ? updatedTeams[teamID]
            ? {
                subTeam: [...updatedTeams[teamID].subTeam, subTeamID],
                isSelectAll: false,
              }
            : { subTeam: [subTeamID], isSelectAll: false }
          : {
              subTeam:
                updatedTeams[teamID]?.subTeam?.filter(
                  (id) => id !== subTeamID,
                ) || [],
              isSelectAll: false,
            };
        if (updatedTeams[teamID]?.subTeam.length === 0) {
          delete updatedTeams[teamID];
        }
        return updatedTeams;
      });
    },
    [],
  );

  const getTeamsPayload = useCallback(() => {
    const groupedTeams = Object.keys(selectedTeams).reduce(
      (acc, teamID) => {
        const team = teamsList.find((t) => t.id === teamID);
        if (team) {
          const subTeams = selectedTeams[teamID].subTeam
            .map((subTeamID) => {
              const subTeam = team.teams.find((sub) => sub.id === subTeamID);
              return subTeam
                ? {
                    subTeamID: subTeam.id as string,
                    subTeamName: subTeam.name as string,
                  }
                : null;
            })
            .filter(
              (
                subTeam,
              ): subTeam is { subTeamID: string; subTeamName: string } =>
                subTeam !== null,
            );

          const uniqueSubTeams = [
            ...new Map(subTeams.map((st) => [st.subTeamID, st])).values(),
          ];

          if (uniqueSubTeams.length > 0) {
            acc.push({
              teamID: team.id as string,
              teamName: team.type as string,
              subTeams: uniqueSubTeams,
            });
          }
        }
        return acc;
      },
      [] as {
        teamID: string;
        teamName: string;
        subTeams: { subTeamID: string; subTeamName: string }[];
      }[],
    );
    return groupedTeams;
  }, [selectedTeams, teamsList]);

  useEffect(() => {
    if (!isSaveTalentLoading && isSaveTalentSuccess) {
      dispatch(updateTeams(getTeamsPayload()));
      resetFetchStatus();
      setEdit(false);
      handleScrollToElement(teamsOfInterestSectionId);
    }
    if (!isSaveTalentLoading && isError) {
      updateAlert(false);
      resetFetchStatus();
    }
  }, [
    dispatch,
    getTeamsPayload,
    isError,
    isSaveTalentLoading,
    isSaveTalentSuccess,
    resetFetchStatus,
    t,
    teamsOfInterestSectionId,
    updateAlert,
  ]);

  const onCancel = useCallback(() => {
    setTeamsList(teamsData || []);
    setValidationError(null);
    setEdit(false);
  }, [teamsData]);

  const onSave = useCallback(async () => {
    deleteAlert();
    const selectedTeamsPayload = getTeamsPayload();
    if (
      !Array.isArray(selectedTeamsPayload) ||
      selectedTeamsPayload.length === 0
    ) {
      setValidationError(t("jobsite.common.selectAtleastOneTeam") as string);
      return;
    }
    saveTalent([
      state?.talentId,
      PROFILE_SECTIONS.TEAMS,
      { teamsOfInterest: selectedTeamsPayload },
    ]);
    setValidationError(null);
  }, [deleteAlert, getTeamsPayload, saveTalent, state?.talentId, t]);

  return (
    <ProfileSection
      isEdit={isEdit}
      i18nTitle="jobsite.common.teamsInterestTitle"
      onEdit={() => setEdit(true)}
      id={teamsOfInterestSectionId}
      sectionName="teamsOfInterest"
    >
      {isEdit ? (
        <>
          {isSaveTalentLoading && (
            <ProgressIndicatorLoader showLoading={true} />
          )}
          <TeamOfInterestWidget
            teamsList={teamsList}
            selectedTeams={selectedTeams}
            onChange={handleCheckboxChange}
          />
          {validationError && (
            <ErrorMessage
              message={validationError}
              errorA11y={t("jobsite.common.errorIconLabel") as string}
            />
          )}
          <SaveCancelAction
            onCancel={onCancel}
            onSave={onSave}
            cancelButtonId={teamsOfInterestIdGenerator.generateId(
              "cancelButton",
            )}
            saveButtonId={teamsOfInterestIdGenerator.generateId("saveButton")}
            scrollToTopSectionId={teamsOfInterestSectionId}
          />
        </>
      ) : (
        <TeamsView
          teams={selectedTeamListView}
          noResultsLabel={t("jobsite.common.notSpecified") as string}
        />
      )}
    </ProfileSection>
  );
};
