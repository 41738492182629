import { MarcomIconNames, NativeButton } from "@rpe-js/marcom-web-components";
import React from "react";

interface TextIconProp {
  id?: string;
  name: MarcomIconNames;
  label?: string;
  textColor?: "green" | "red" | "blue" | "warning";
  marginClass?: string;
  onClick?: () => void;
  ariaLabelForButton?: string;
  idForButton?: string;
}

const TextIcon = ({
  name,
  label,
  marginClass = "ml-10",
  textColor,
  onClick,
  ariaLabelForButton,
  idForButton,
}: TextIconProp) => {
  const iconClassName = `icon ${textColor || ""}`;

  const renderIcon = () => {
    return <i className={`${iconClassName} ${name}`} />;
  };

  return (
    <div className={textColor ? textColor : ""}>
      {onClick ? (
        <NativeButton
          onClick={onClick}
          className="icon-button"
          aria-label={ariaLabelForButton ?? ""}
          id={idForButton ?? ""}
        >
          {renderIcon()}
        </NativeButton>
      ) : (
        renderIcon()
      )}
      {label && (
        <span className={`${marginClass} ${textColor || ""}`}>{label}</span>
      )}
    </div>
  );
};

export default TextIcon;
