import { ExternalProfile } from "../../../../shared/types/talent/talent";
import { ProfileAction } from "./ActionTypes";
import { ProfileState } from "./ProfileState";

export const reducer = (
  state: ProfileState,
  action: ProfileAction,
): ProfileState => {
  switch (action.type) {
    case "SET_PROFILE_DATA":
      return {
        ...(action.payload as ProfileState),
      };
    case "SET_FILE_META_DATA":
      return {
        ...state,
        fileMetaData: action.payload,
      };
    case "UPDATE_CONTACT_INFORMATION": {
      const partialTalent: any = {
        contact: action.payload.contact,
        addresses: action.payload.addresses,
        displayPronouns: action.payload.displayPronouns,
      };
      if (action.payload.privacyPolicy) {
        partialTalent.privacyPolicy = action.payload.privacyPolicy;
      }
      return {
        ...state,
        ...partialTalent,
      };
    }
    case "UPDATE_MOBILITY_PREFERENCES":
      return {
        ...state,
        mobilityPreferences: {
          ...state.mobilityPreferences,
          ...action.payload,
        },
      };
    case "UPDATE_EMPLOYMENT_TYPE":
      return {
        ...state,
        employmentTypes: [...action.payload],
      };
    case "UPDATE_SKILLS":
      return {
        ...state,
        skills: [...action.payload],
      };
    case "UPDATE_LANGUAGES":
      return {
        ...state,
        languages: [...action.payload],
      };
    case "UPDATE_LOCATIONS":
      return {
        ...state,
        preferredLocations: [...action.payload],
      };
    case "UPDATE_EDUCATION":
      return {
        ...state,
        educationDegrees: [...action.payload],
      };
    case "UPDATE_SELF_DISCLOSURES":
      return {
        ...state,
        selfDisclosures: { ...action.payload },
      };
    case "UPDATE_LINKS":
      return {
        ...state,
        links: [...action.payload],
      };
    case "UPDATE_EMPLOYMENTS":
      return {
        ...state,
        employments: [...action.payload],
      };
    case "UPDATE_TEAMS_INTEREST":
      return {
        ...state,
        teamsOfInterest: action.payload.map((team) => {
          const groupedSubTeams = team.subTeams?.map((subTeam) => ({
            subTeamID: subTeam.subTeamID,
            subTeamName: subTeam.subTeamName,
          }));

          return {
            teamID: team.teamID,
            teamName: team.teamName,
            subTeams: groupedSubTeams,
          };
        }),
      };
    case "UPDATE_RECRUITER_LAST_MODIFICATION":
      return {
        ...state,
        recruiterLastModification: {
          ...state.recruiterLastModification,
          subDocs: action.payload,
        },
      };
    case "UPDATE_AT_LEAST_ONE_JOB":
      return {
        ...state,
        employmentHasAtLeastOneJob: action.payload,
      };
    case "UPDATE_PREFERRED_EMAIL":
      return {
        ...state,
        contact: {
          ...state.contact,
          preferredEmail: action.payload,
        },
        emails: {
          ...state.emails,
          preferredEmail: {
            verificationTypeID: state.emails?.preferredEmail
              ?.verificationTypeID as string,
            verifiedDate: state.emails?.preferredEmail?.verifiedDate as Date,
            verifiedStatus: true,
          },
        },
      };
    case "UPDATE_PROFILE_UPDATE_SELECTION":
      return {
        ...state,
        profileUpdateSelection: action.payload,
      };

    case "UPDATE_LINKEDIN":
      return {
        ...state,
        externalProfiles: {
          ...state.externalProfiles,
          linkedin: action.payload as ExternalProfile,
        },
      };
    case "DISCARD_LINKEDIN":
      return {
        ...state,
        externalProfiles: {
          ...state.externalProfiles,
          linkedin: null,
        },
      };
    case "DISCARD_RESUME":
      return {
        ...state,
        discardResume: action.payload,
      };
    case "TOGGLE_SHOW_PROFILE_OPTIONS":
      return {
        ...state,
        canShowProfileOptions: action.payload,
      };
    case "UPDATE_RESUME":
      return {
        ...state,
        resume: action.payload,
      };

    case "UPDATE_TEXT_RESUME":
      return {
        ...state,
        textResume: action.payload,
      };
    case "CLEAR_RESUME":
      return {
        ...state,
        resume: null,
      };
    default:
      return state;
  }
};
