import { DropDownOptionProps, Textbox } from "@rpe-js/marcom-web-components";
import React, { useCallback, useEffect, useState } from "react";
import {
  Answer,
  Question,
  TalentQuestion,
} from "../../../shared/types/questionnaire";
import useFocusFirstErrorField from "../../hooks/useFocusFirstErrorField";
import useIntlMessage from "../../hooks/useIntlMessage";
import ApplyActionContainer from "../../pages/apply/ApplyActionContainer";
import { handleScrollToElement } from "../../utils/focusUtil";
import { MultiChoiceMultiAnsQues } from "./questionnaireTypes/MultiChoiceMultiAnsQues";
import { MultiChoiceSingleAnsQues } from "./questionnaireTypes/MultiChoiceSingleAnsQues";
import { RankedQuestion } from "./questionnaireTypes/RankedQuestion";

export type QuestionAnswerResult = {
  questionId?: string;
  answer?: string;
  answered?: boolean;
};

type EditQuestionnaireViewProps = {
  questionList: Array<TalentQuestion> | Array<Question>;
  contentId: string;
  footerId: string;
  updateAnswer: (answer: QuestionAnswerResult) => void;
  updateFreeTextAnswer: (answer: QuestionAnswerResult) => void;
  updateRankedAnswer: (result: {
    questionId: string;
    answer: { answerId: string; rank: DropDownOptionProps };
  }) => void;
  onCancel: (error?: boolean) => void;
  onSave: () => void;
  showBackButton?: boolean;
  showReviewSubmit?: boolean;
  cancelLabel: string;
  saveLabel: string;
  reviewSubmitLabel?: string;
  freeTextErrorMessage: string;
  singleSelectErrorMessage: string;
  multiSelectErrorMessage: string;
  multiSelectPossibleAnswersErrorMessage: string;
  requiredRankErrorMessage: string;
  selectCorrectRankErrorMessage: string;
};

const EditQuestionnaireView = ({
  questionList,
  contentId,
  footerId,
  updateAnswer,
  updateFreeTextAnswer,
  updateRankedAnswer,
  onCancel,
  onSave,
  showBackButton = false,
  showReviewSubmit = false,
  cancelLabel,
  saveLabel,
  reviewSubmitLabel = "",
  freeTextErrorMessage,
  singleSelectErrorMessage,
  multiSelectErrorMessage,
  multiSelectPossibleAnswersErrorMessage,
  requiredRankErrorMessage,
  selectCorrectRankErrorMessage,
}: EditQuestionnaireViewProps) => {
  const { t } = useIntlMessage();
  const [canShowError, setCanShowError] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const errorMessage = t(freeTextErrorMessage) as string;
  const { setFocusFirstErrorField } = useFocusFirstErrorField(contentId);

  const checkForPossibleAnswerErrorText = (
    questionId: string,
    answers: Array<Answer>,
    possibleAnswers: number | undefined,
  ) => {
    if (!possibleAnswers) {
      return "";
    }

    if (!checkForPossibleAnswerValidation(answers, possibleAnswers)) {
      if (possibleAnswers) {
        return t(multiSelectPossibleAnswersErrorMessage, {
          possibleAnswers: possibleAnswers.toString(),
        }) as string;
      }
    }

    if (questionId && errors[questionId]) {
      return t(multiSelectErrorMessage) as string;
    }

    return "";
  };

  const checkForPossibleAnswerValidation = useCallback(
    (answers: Array<Answer>, possibleAnswers: number | undefined) => {
      if (possibleAnswers) {
        const answered = answers.filter(
          (option) => option.answeredMultiple,
        ).length;
        if (answered > possibleAnswers) return false;
      }
      return true;
    },
    [],
  );

  const isError = useCallback(() => {
    for (let i = 0; i < questionList.length; i++) {
      if (errors[questionList[i].questionId]) {
        return true;
      }
    }
    return false;
  }, [errors, questionList]);

  const handleOnSave = useCallback(() => {
    setCanShowError(true);
    let errorState = false;
    let firstQuestionWithError: TalentQuestion | Question | null = null;
    for (let i = 0; i < questionList.length; i++) {
      if (errors[questionList[i].questionId]) {
        firstQuestionWithError = questionList[i];
        errorState = true;
        break;
      }
    }
    if (!errorState) onSave();
    else {
      // Checking if question with error type is checkbox and passing it to the handleScrollToElement with focus as true
      if (
        firstQuestionWithError &&
        firstQuestionWithError.questionType === "MULTIPLE" &&
        firstQuestionWithError.possibleAnswers &&
        firstQuestionWithError.possibleAnswers > 1 &&
        firstQuestionWithError.answerOptions
      ) {
        handleScrollToElement(
          firstQuestionWithError.answerOptions[0].answerId,
          true,
        );
      } else {
        setFocusFirstErrorField(true); // For all different type of questions setFocusFirstErrorField will take care of focusing first element
      }
    }
  }, [errors, onSave, questionList, setFocusFirstErrorField]);

  const validateCorrectRankOrder = useCallback(
    (answerOptions: Array<Answer>) => {
      const unAnsweredOptions = answerOptions.filter(
        (answer) => !answer.answeredRank,
      );
      // if some options are unanswered, first let them be answered before validating rank order of options
      if (unAnsweredOptions.length > 0) {
        return true;
      }

      // all options are given a rank, now validate they are having unique rank order
      const answeredOptions = answerOptions.map(
        (answer) => answer.answeredRank,
      );
      answeredOptions.sort();
      for (let i = 0; i < answeredOptions.length; i++) {
        if (answeredOptions[i] != i + 1) {
          return false;
        }
      }
      return true;
    },
    [],
  );

  const validateAnswers = useCallback(() => {
    const newErrors: Record<string, boolean> = {};

    questionList?.forEach((question: TalentQuestion | Question) => {
      if (question.questionType == "TEXT") {
        if (!question.answeredFreeText) {
          newErrors[question.questionId as string] = true;
        } else {
          delete newErrors[question.questionId as string];
        }
      } else if (question.questionType == "RANKED") {
        if (
          question.answerOptions &&
          !question.answerOptions.every((answer: Answer) => answer.answeredRank)
        ) {
          newErrors[question.questionId as string] = true;
        } else if (
          !validateCorrectRankOrder(question.answerOptions as Array<Answer>)
        ) {
          newErrors[question.questionId as string] = true;
        } else {
          delete newErrors[question.questionId as string];
        }
      } else {
        if (
          question.answerOptions &&
          question.answerOptions.every(
            (answer: Answer) => !answer.answeredMultiple,
          )
        ) {
          newErrors[question.questionId as string] = true;
        } else {
          if (
            !checkForPossibleAnswerValidation(
              question.answerOptions || [],
              question.possibleAnswers,
            )
          ) {
            newErrors[question.questionId as string] = true;
          } else {
            delete newErrors[question.questionId as string];
          }
        }
      }
    });
    setErrors(newErrors);
  }, [
    checkForPossibleAnswerValidation,
    questionList,
    validateCorrectRankOrder,
  ]);

  useEffect(() => {
    validateAnswers();
  }, [questionList, validateAnswers]);

  return (
    <section id={contentId}>
      {questionList &&
        questionList.map((item, idx) => (
          <fieldset
            className="u-border-bottom mb-15 pb-20"
            id={item.questionId}
            key={item.questionId}
            aria-describedby={`${item.questionId}_error`}
          >
            {item &&
              item.questionType == "MULTIPLE" &&
              item.answerOptions &&
              (!item.possibleAnswers || item.possibleAnswers == 1) && (
                <>
                  <MultiChoiceSingleAnsQues
                    questionId={item.questionId}
                    question={item.question}
                    answerOptions={item.answerOptions}
                    instructions={item.instructions || ""}
                    errorMessage={
                      canShowError && errors[item.questionId]
                        ? (t(singleSelectErrorMessage) as string)
                        : ""
                    }
                    updateAnswer={updateAnswer}
                  ></MultiChoiceSingleAnsQues>
                </>
              )}

            {item &&
              item.questionType == "MULTIPLE" &&
              item.answerOptions &&
              (!item.possibleAnswers || item.possibleAnswers > 1) && (
                <MultiChoiceMultiAnsQues
                  questionId={item.questionId}
                  question={item.question}
                  answerOptions={item.answerOptions}
                  instructions={item.instructions || ""}
                  possibleAnswers={item.possibleAnswers || 1}
                  updateAnswer={updateAnswer}
                  errorMessage={
                    canShowError && errors[item.questionId]
                      ? checkForPossibleAnswerErrorText(
                          item.questionId as string,
                          item.answerOptions,
                          item.possibleAnswers || undefined,
                        )
                      : ""
                  }
                ></MultiChoiceMultiAnsQues>
              )}

            {item && item.questionType == "RANKED" && item.answerOptions && (
              <RankedQuestion
                canShowError={canShowError}
                questionId={item.questionId}
                question={item.question}
                answerOptions={item.answerOptions}
                instructions={item.instructions || ""}
                validateCorrectRankOrder={validateCorrectRankOrder}
                updateAnswer={updateRankedAnswer}
                requiredRankErrorMessage={requiredRankErrorMessage}
                selectCorrectRankErrorMessage={selectCorrectRankErrorMessage}
              ></RankedQuestion>
            )}
            {item && item.questionType == "TEXT" && (
              <>
                <p
                  className={
                    canShowError && !item.answeredFreeText
                      ? "red mb-10"
                      : "mb-10"
                  }
                >
                  {item.question}
                </p>
                {item.instructions && <p>{item.instructions}</p>}
                <p className="t-body">
                  <Textbox
                    id={`${contentId}-questiontype-${item.questionType.toLowerCase()}-${idx}`}
                    required={true}
                    hideLabel={true}
                    value={item.answeredFreeText || ""}
                    onValueChange={(val) =>
                      updateFreeTextAnswer({
                        answer: val,
                        questionId: item.questionId,
                      })
                    }
                    aria-label={item.question}
                    error={
                      canShowError && !item.answeredFreeText ? errorMessage : ""
                    }
                    errorA11y={t("jobsite.common.errorIconLabel") as string}
                  ></Textbox>
                </p>
              </>
            )}
          </fieldset>
        ))}
      <section id={footerId} className="d-flex justify-center">
        <ApplyActionContainer
          cancelLabelName={t(cancelLabel) as string}
          saveLabelName={t(saveLabel) as string}
          reviewLabelName={t(reviewSubmitLabel) as string}
          showBackButton={showBackButton}
          showContinueButton={true}
          showReviewButton={showReviewSubmit}
          onBack={() => {
            onCancel(isError());
          }}
          onContinue={handleOnSave}
          onReviewSubmit={handleOnSave}
        ></ApplyActionContainer>
      </section>
    </section>
  );
};

export default EditQuestionnaireView;
