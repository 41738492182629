import React from "react";
import { Label } from "../../base/Label";
import LocaleAwareLink from "../LocaleAwareLink";
interface MessageContainerProps {
  message: React.ReactElement;
  subText: string;
  linkText: string;
  link: string;
}
export function MessageContainer(props: MessageContainerProps) {
  const { message, subText, linkText, link } = props;
  return (
    <div className="message-container">
      {message}
      <div className="d-flex flex-column">
        <div className="message-subtext">
          <Label label={subText} />
        </div>
        <div>
          <LocaleAwareLink link={link} type="more" text={linkText} />
        </div>
      </div>
    </div>
  );
}
