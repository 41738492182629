import { ProgressIndicatorLoader } from "@rpe-js/marcom-web-components";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { IntlProvider } from "react-intl";
import { useRouteLoaderData } from "react-router";
import {
  DisabilityInfo,
  SelfDisclosure,
  Talent as TalentType,
  VeteranStatus,
} from "../../../../shared/types/talent/talent";
import { TalentFileMetaData } from "../../../../shared/types/talent/talentFileMetatdata";
import { TalentConnection } from "../../../../shared/types/talentConnection";
import APP_CONSTANTS from "../../../../utilities/appConstants";
import {
  getTalent,
  getTalentConnectionData,
  getTalentFileMetaData,
  getTranslationPromise,
} from "../../../api/fetchClient";
import {
  DEFAULT_LOCALE,
  ROLE_SUBMIT,
  SELECTED_LOCATIONS_FOR_ROLE,
} from "../../../app.constants";
import AppContext from "../../../AppContext";
import { useCurrentUserContext } from "../../../CurrentUserContext";
import { RouteID } from "../../../routes";
import { AppData } from "../../../types";
import {
  isCorporate,
  isRetailType,
  redirectToPath,
  transformUrlParam,
} from "../../../util";
import {
  getTalentResume,
  getTalentSupportingFiles,
  resumeMapper,
  supportFileMapper,
} from "../../../utils/fileUtil";
import { SessionStorage } from "../../../utils/sessionStorageUtil";
import {
  locationsAction,
  resumeAction,
  stepAction,
  supportingFileAction,
  talentAction,
  talentConnectionAction,
} from "./Actions";
import { ApplyContext } from "./ApplyContext";
import { reducer } from "./ApplyReducer";
import { ApplyState, profileUpdateMode, StepName, Talent } from "./ApplyState";

interface ApplyProviderProps {
  children: ReactNode;
  initialState?: ApplyState;
}

export const ApplyProvider: React.FC<ApplyProviderProps> = ({
  children,
  initialState = {
    page: { stepNames: [], skipSelfDisclosure: false },
    resume: null,
    discardResume: null,
    supportFiles: null,
    updatedFileCategories: {},
    connection: null,
    talent: {
      talentId: "",
      externalProfiles: null,
      links: [],
      contact: {},
      displayPronouns: false,
      genderPronoun: {},
    },
    discardedFiles: [],
    questionnaireAnswers: null,
    linkedinSnapshot: "",
  },
}: ApplyProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { appBasePathPrefix } = useRouteLoaderData(RouteID.root) as AppData;
  const { appUIState } = useContext(AppContext);
  const { locale, translations } = appUIState.appData;
  const jobDetails = useRouteLoaderData(RouteID.apply) as any;
  const { currentUser } = useCurrentUserContext();
  const retailType = isRetailType(jobDetails);

  const [loaded, setLoaded] = useState<boolean>(false);
  const [initCallMade, setInitCallMade] = useState<boolean>(false);
  const [updatedTranslations, setUpdatedTranslations] = useState(translations);
  const [updatedLocale, setUpdatedLocale] = useState(locale);
  const isCorporateRole = isCorporate(jobDetails);

  const getTalentConnectionInfo = useCallback(
    async (talentId: string, positionId: string) => {
      const connectionResponse = await getTalentConnectionData(
        talentId,
        positionId,
      );
      return Promise.resolve(connectionResponse);
    },
    [],
  );

  const getTalentFileMetaInfo = useCallback(async (talentId: string) => {
    const fileMetaResponse = await getTalentFileMetaData(talentId);
    return Promise.resolve(fileMetaResponse);
  }, []);

  const prepareTalentConnection = useCallback((connectionResponse: any) => {
    if (connectionResponse && Object.keys(connectionResponse).length > 0) {
      dispatch(talentConnectionAction({ ...connectionResponse }));
    }
    return connectionResponse;
  }, []);

  const prepareTalentState = useCallback((talentInfo: TalentType) => {
    if (talentInfo && talentInfo.talentId) {
      const talentObj = {
        talentId: talentInfo.talentId || null,
        talentDSID: talentInfo.talentDSID || null,
        externalProfiles: talentInfo.externalProfiles || null,
        displayPronouns: talentInfo.displayPronouns && true,
        genderPronoun: {
          subjective: talentInfo.genderPronoun?.subjective || "",
          objective: talentInfo.genderPronoun?.objective || "",
          possessive: talentInfo.genderPronoun?.possessive || "",
        },
        contact: {
          firstName: talentInfo.contact?.firstName || "",
          lastName: talentInfo.contact?.lastName || "",
          preferredPhone: talentInfo.contact?.preferredPhone || "",
          preferredEmail: talentInfo.contact?.preferredEmail || "",
        },
        addresses: talentInfo.addresses || [
          {
            state: "",
            countryID: "",
            countryName: "",
          },
        ],
        mobilityPreferences: talentInfo.mobilityPreferences,
        preferredLocations: talentInfo.preferredLocations,
        skills: talentInfo.skills,
        emails: talentInfo.emails,
        discovered: talentInfo.discovered,
        languages: talentInfo.languages,
        employments: talentInfo.employments,
        profileUpdateSelection:
          talentInfo.profileUpdateSelection || profileUpdateMode.MANUAL,
        educationDegrees: talentInfo.educationDegrees,
        teamsOfInterest: talentInfo.teamsOfInterest,
        _id: talentInfo.id,
        localeNameIndicator: talentInfo.localeNameIndicator,
        kanjiIndicator: talentInfo.kanjiIndicator || false,
        connectionsCount: talentInfo.connectionsCount,
        userType: talentInfo.userType,
        dsType: talentInfo.dsType,
        sourceName: talentInfo.sourceName,
        sourceID: talentInfo.sourceID,
        submittedFlow: talentInfo.submittedFlow,
        lastLoginTime: talentInfo.lastLoginTime,
        talentTypeID: talentInfo.talentTypeID,
        privacyPolicy: talentInfo.privacyPolicy,
        hideGender: talentInfo.hideGender,
        hideNationality: talentInfo.hideNationality,
        links:
          (talentInfo.links &&
            talentInfo.links.map((item) => ({
              categoryID: item.categoryID as string,
              categoryName: item.categoryName as string,
              link: item.link as string,
            }))) ||
          null,
        selfDisclosures:
          talentInfo.selfDisclosures &&
          Object.entries(talentInfo.selfDisclosures).reduce(
            (acc, [key, selfDisclosure]) => {
              acc[key] = {
                genderID: selfDisclosure.genderID,
                genderName: selfDisclosure.genderName,
                veteranStatus: {
                  ...selfDisclosure.veteranStatus,
                } as VeteranStatus,
                disability: {
                  ...selfDisclosure.disability,
                } as DisabilityInfo,
                ethnicityID: selfDisclosure.ethnicityID,
                ethnicityName: selfDisclosure.ethnicityName,
                nationalityID: selfDisclosure.nationalityID,
                nationalityName: selfDisclosure.nationalityName,
              };
              return acc;
            },
            {} as Record<string, SelfDisclosure>,
          ),
      };
      dispatch(
        talentAction({
          ...talentObj,
        } as Talent),
      );
      return talentObj;
    }
    return null;
  }, []);

  const prepareTalentFileMeta = useCallback(
    (fileMetaResponse: Array<TalentFileMetaData>) => {
      let fileMeta = null;
      if (fileMetaResponse) {
        const resumeResponse = getTalentResume(fileMetaResponse);
        if (resumeResponse && resumeResponse.length > 0) {
          const resumeDetails = resumeMapper(resumeResponse[0]);
          fileMeta = {
            resume: resumeDetails,
            supportFileDetails: null,
          };
          dispatch(resumeAction(resumeDetails));
        }

        const supportingFileResponse =
          getTalentSupportingFiles(fileMetaResponse);
        if (supportingFileResponse && supportingFileResponse.length > 0) {
          const supportingFileDetails =
            supportingFileResponse.map(supportFileMapper);
          fileMeta = {
            ...(fileMeta ? fileMeta : {}),
            supportFileDetails: supportingFileDetails,
          };
          dispatch(supportingFileAction(supportingFileDetails));
        }
      }
      return fileMeta;
    },
    [],
  );

  const getTalentInfo = useCallback(async (talentId: string) => {
    const talentInfo = await getTalent(talentId);
    return Promise.resolve(talentInfo);
  }, []);

  const checkForSelectedLocations = useCallback(() => {
    if (jobDetails?.managedPipelineRole && retailType) {
      // get the title to redirect to location picker
      const title = transformUrlParam(
        jobDetails.postingTitleInEnglish || jobDetails.postingTitle,
      );
      // selected locations need to be set in sessionStorage
      const selectedLocationItem = SessionStorage.get(
        SELECTED_LOCATIONS_FOR_ROLE,
      );
      if (!selectedLocationItem) {
        redirectToPath(
          appBasePathPrefix,
          `${locale}/details/${jobDetails.jobNumber}/${title}/locationPicker`,
        );
        return true;
      } else {
        const selectedLocationsForRole = JSON.parse(selectedLocationItem);
        if (!selectedLocationsForRole.locationIds) {
          redirectToPath(
            appBasePathPrefix,
            `${locale}/details/${jobDetails.jobNumber}/${title}/locationPicker`,
          );
          return true;
        } else {
          dispatch(locationsAction(selectedLocationsForRole));
        }
      }
    }
    return false;
  }, [
    appBasePathPrefix,
    jobDetails.jobNumber,
    jobDetails.postingTitle,
    jobDetails.postingTitleInEnglish,
    jobDetails.managedPipelineRole,
    locale,
    retailType,
  ]);

  const getSelfDisclosureConfigAfterTalentOverride = (
    selfDisclosureConfig: any,
    talentSelfDisclosurePreference: Talent,
  ) => {
    if (talentSelfDisclosurePreference.hideGender) {
      selfDisclosureConfig.gender = false;
    }
    if (talentSelfDisclosurePreference.hideNationality) {
      selfDisclosureConfig.nationality = false;
    }
    return selfDisclosureConfig;
  };

  const canShowSelfDisclosureStep = useCallback(
    (selfdisclosureConfig: any, talentSelfDisclosurePreference: Talent) => {
      // there is no selfdisclosure config for selected role
      if (!selfdisclosureConfig) {
        return false;
      }
      selfdisclosureConfig = getSelfDisclosureConfigAfterTalentOverride(
        selfdisclosureConfig,
        talentSelfDisclosurePreference,
      );
      if (selfdisclosureConfig) {
        return Object.values(selfdisclosureConfig).indexOf(true) !== -1;
      }
      return false;
    },
    [],
  );

  /**
   * This method checks over self disclosure config of jobdetails
   * and checks if those self disclosure config details are filled by talent already
   */
  const hasSelfDisclosure = useCallback(
    (talent: Talent) => {
      let selfDisclosureConfig = jobDetails.selfDisclosureConfig;
      // there is no selfdisclosure config for selected role
      if (!selfDisclosureConfig) {
        return false;
      }
      const countryID = selfDisclosureConfig.countryID;
      const selfDisclosures = talent.selfDisclosures;
      if (
        !selfDisclosureConfig ||
        !selfDisclosures ||
        !selfDisclosures[countryID]
      )
        return false;

      selfDisclosureConfig = getSelfDisclosureConfigAfterTalentOverride(
        selfDisclosureConfig,
        talent,
      );

      if (selfDisclosureConfig.gender && !selfDisclosures[countryID].genderID) {
        return false;
      }

      if (
        selfDisclosureConfig.ethnicity &&
        !selfDisclosures[countryID].ethnicityID
      ) {
        return false;
      }

      if (
        selfDisclosureConfig.veteranStatus &&
        !selfDisclosures[countryID].veteranStatus?.veteranStatusID
      ) {
        return false;
      }

      if (
        selfDisclosureConfig.nationality &&
        !selfDisclosures[countryID].nationalityID
      ) {
        return false;
      }
      if (
        selfDisclosureConfig.disability &&
        !selfDisclosures[countryID].disability?.statusID &&
        !selfDisclosures[countryID].disability?.completedOn
      ) {
        return false;
      }

      return true;
    },
    [jobDetails.selfDisclosureConfig],
  );

  const getStepsToShow = useCallback(
    (
      talent: Talent,
      resume: TalentFileMetaData | null,
      talentConnection: any,
    ) => {
      // steps to show
      const stepNames = [StepName.RESUME];
      const showSelfDisclosure = canShowSelfDisclosureStep(
        jobDetails.selfDisclosureConfig,
        talent,
      );
      if (showSelfDisclosure) {
        stepNames.push(StepName.SELFDISCLOSURE);
      }
      if (
        jobDetails.questionarieTemplateID &&
        !(talentConnection && talentConnection.reset)
      ) {
        stepNames.push(StepName.QUESTIONNAIRE);
      }
      stepNames.push(StepName.REVIEW_INFO);
      return stepNames;
    },
    [
      canShowSelfDisclosureStep,
      jobDetails.questionarieTemplateID,
      jobDetails.selfDisclosureConfig,
    ],
  );

  /**
   * This method decides which is the first step the apply flow should start with and
   * what are the steps in apply flow
   */
  const getLandingStep = useCallback(
    (
      talent: Talent,
      resume: TalentFileMetaData | null,
      talentConnection: any,
    ) => {
      const roleSubmitted = SessionStorage.get(ROLE_SUBMIT); // if user has submitted already a job in same session
      const showSelfDisclosure = canShowSelfDisclosureStep(
        jobDetails.selfDisclosureConfig,
        talent,
      );
      let firstStep = StepName.RESUME;
      if (
        roleSubmitted &&
        ((resume && resume.fileId) ||
          (talent.externalProfiles && talent.externalProfiles.linkedin))
      ) {
        if (showSelfDisclosure && !hasSelfDisclosure(talent)) {
          firstStep = StepName.SELFDISCLOSURE;
        } else if (
          jobDetails.questionarieTemplateID &&
          !(talentConnection && talentConnection.reset)
        ) {
          firstStep = StepName.QUESTIONNAIRE;
        } else {
          firstStep = StepName.REVIEW_INFO;
        }
      } else {
        firstStep = StepName.RESUME;
      }
      return firstStep;
    },
    [
      canShowSelfDisclosureStep,
      hasSelfDisclosure,
      jobDetails.questionarieTemplateID,
      jobDetails.selfDisclosureConfig,
    ],
  );

  const prepareStepsForApply = useCallback(
    (
      talentObj: Talent,
      resume: TalentFileMetaData | null,
      talentConnection: any,
    ) => {
      const firstStep = getLandingStep(
        talentObj as Talent,
        resume,
        talentConnection,
      );

      const stepNames: Array<string> = getStepsToShow(
        talentObj as Talent,
        resume as TalentFileMetaData,
        talentConnection,
      );

      const stepMapping = stepNames.reduce(
        (acc, curr, index) => {
          acc[curr] = index + 1;
          return acc;
        },
        {} as Record<string, number>,
      );

      const skipSelfDisclosure = hasSelfDisclosure(talentObj);

      dispatch(
        stepAction({
          firstStep,
          stepNames,
          stepMapping,
          skipSelfDisclosure,
          questionnaireError: stepMapping.questionnaire ? true : false, // by default questionnaire is not filled, so we mark it as error
          showReviewSubmit: false,
        }),
      );
    },
    [getLandingStep, getStepsToShow, hasSelfDisclosure],
  );

  /**
   *  This method checks if a talent can apply for a role, otherwise redirects back to job details page
   *  We should allow talent to submit/resubmit for role under below conditions:
   1. When role is managed role pipeline (or)
   2. When role is corporate and resubmmitted is "yes" / submitted is "no"
   */

  const handleResubmissionRedirect = useCallback(
    (talentConnection: TalentConnection) => {
      if (!talentConnection || !talentConnection.talentID) {
        return;
      }

      const canReSubmit =
        talentConnection.roleLocation ||
        (talentConnection.resubmitted &&
          talentConnection.resubmitted.toLowerCase() === "yes") ||
        (talentConnection.submitted &&
          talentConnection.submitted.toLowerCase() === "no");

      if (!canReSubmit) {
        redirectToPath(
          appBasePathPrefix,
          `${locale}/details/${jobDetails.positionId}`,
        );
      }
    },
    [appBasePathPrefix, jobDetails.positionId, locale],
  );

  useEffect(() => {
    if (!loaded && !initCallMade) {
      if (checkForSelectedLocations()) return;
      setInitCallMade(true);
      Promise.all([
        getTalentInfo(currentUser.talentId as string),
        getTalentFileMetaInfo(currentUser.talentId as string),
        getTalentConnectionInfo(
          currentUser.talentId as string,
          jobDetails.positionId || jobDetails.jobNumber,
        ),
        // We should make translations call for en-us when role is corporate
        isCorporateRole && locale !== DEFAULT_LOCALE
          ? getTranslationPromise({
              headers: { locale: DEFAULT_LOCALE },
            })()
          : Promise.resolve(),
      ])
        .then((results: Array<any>) => {
          const talentObj = prepareTalentState(results[0]);
          const talentFileMeta = prepareTalentFileMeta(results[1]);
          const talentConnection = prepareTalentConnection(results[2]);
          handleResubmissionRedirect(talentConnection);
          // We should display the text only in DEFAULT_LOCALE when job is corporate
          if (isCorporateRole && locale != DEFAULT_LOCALE) {
            setUpdatedTranslations(results[3].data);
            setUpdatedLocale(DEFAULT_LOCALE);
          }
          prepareStepsForApply(
            talentObj as Talent,
            (talentFileMeta && talentFileMeta.resume) || null,
            talentConnection,
          );
          setLoaded(true);
        })
        .catch(() => {
          setLoaded(false);
        });
    }
  }, [
    checkForSelectedLocations,
    currentUser.talentId,
    getLandingStep,
    getStepsToShow,
    getTalentConnectionInfo,
    getTalentFileMetaInfo,
    getTalentInfo,
    handleResubmissionRedirect,
    initCallMade,
    isCorporateRole,
    jobDetails,
    jobDetails.jobNumber,
    jobDetails.jobType,
    jobDetails.positionId,
    jobDetails.questionarieTemplateID,
    loaded,
    locale,
    prepareStepsForApply,
    prepareTalentConnection,
    prepareTalentFileMeta,
    prepareTalentState,
  ]);

  return (
    <div
      {...(isCorporateRole
        ? {
            dir: APP_CONSTANTS.HTML_DIRECTION.LTR,
            lang: APP_CONSTANTS.DEFAULT_HTML_LANG,
          }
        : {})}
    >
      {loaded && (
        <IntlProvider locale={updatedLocale} messages={updatedTranslations}>
          <ApplyContext.Provider value={{ state, dispatch }}>
            {children}
          </ApplyContext.Provider>
        </IntlProvider>
      )}

      {!loaded && (
        <ProgressIndicatorLoader
          showLoading={!loaded}
        ></ProgressIndicatorLoader>
      )}
    </div>
  );
};
