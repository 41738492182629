import APP_CONSTANTS from "../../utilities/appConstants";
import { Filters } from "../contexts/SearchContext";
import { MappedPostLocation, MappedTeams } from "../../shared/types/refData";
// import { SearchFilters } from "@rpe-js/core/dist/types/search/search";

// Declare _satellite on the Window interface
declare global {
  interface Window {
    _satellite?: {
      track: (eventName: string) => void;
    };
    digitalData?: {
      pageInfo?: any;
      category?: any;
      eventData?: {
        searchFilter?: any;
      };
    };
  }
}

interface PageInfo {
  pageName: string;
  locale?: string;
  title?: string;
  isInternal?: boolean;
  jobType?: string;
  jobId?: string;
  category?: any; // Define the actual type if known
  siteType?: string;
  siteCountry?: string;
  appType?: string;
}

interface CategoryInfo {
  primaryCategory: string; // Or a more specific type
  subCategory1?: string;
  subCategory2?: string;
}

interface JobInfo {
  jobType?: string;
  jobID?: string;
}

interface DigitalData {
  page: {
    pageInfo: PageInfo;
    category: CategoryInfo;
  };
  job?: JobInfo;
  eventData?: {
    // Include eventData type
    searchFilter?: Record<any, any>;
  };
}

const FILTERS: Record<string, string> = {
  teams: "teams",
  keywords: "keywords",
  languages: "languageSkills",
  locations: "locations",
  products: "productsAndServices",
  retailRoles: "storeRoles",
  searchTerm: "jobSearchTerm",
  homeOffice: "homeOffice",
};

const useDtm = () => {
  const trackPageLoad = (pageInfo: PageInfo) => {
    try {
      const appType = pageInfo.isInternal
        ? APP_CONSTANTS.ANALYTICS.SITE_TYPE.INTERNAL
        : APP_CONSTANTS.ANALYTICS.SITE_TYPE.EXTERNAL;

      const newDigitalData: DigitalData = {
        page: {
          pageInfo: {
            pageName: pageInfo.pageName,
            siteType: appType,
            siteCountry: pageInfo.locale,
            appType: APP_CONSTANTS.DTM_APP_TYPE,
          },
          category: {
            primaryCategory: APP_CONSTANTS.ANALYTICS.PAGE_NAME.JOBS,
            subCategory1: pageInfo.locale,
            subCategory2: pageInfo.title,
          },
        },
      };

      if (pageInfo.jobType && pageInfo.jobId) {
        newDigitalData.job = {
          jobType: pageInfo.jobType,
          jobID: pageInfo.jobId,
        };
      }
      // Update `digitalData` globally
      if (typeof window !== "undefined") {
        window.digitalData = newDigitalData;
      }
      if (window._satellite && typeof window._satellite.track === "function") {
        window._satellite?.track(APP_CONSTANTS.ANALYTICS.BASE_INFO); // Optional chaining
      }
    } catch (error) {
      return;
    }
  };

  const formatTeamFilter = (value: MappedTeams): string => {
    const teamName = value.team_en_US || value.team || "";
    const subTeam = value.subTeam_en_US || value.subTeam || "";
    return `${teamName}: ${subTeam}`;
  };

  const formatLocationFilter = (value: MappedPostLocation): string => {
    const locationName = value.name_en_US || value.name || "";
    return `${locationName} (${value.code})`;
  };

  const formatNameFilter = (value: {
    name_en_US: string;
    name: string;
  }): string => {
    return value.name_en_US || value.name || "";
  };

  const formatFilterValue = (key: string, value: any): string => {
    if (typeof value === "object") {
      if (key === "teams") {
        return formatTeamFilter(value);
      } else if (key === "locations") {
        return formatLocationFilter(value);
      } else if ("name_en_US" in value || "name" in value) {
        return formatNameFilter(value);
      } else {
        return String(value); // Handle other object types by converting to string
      }
    } else if (typeof value === "boolean") {
      if (key === "homeOffice") {
        return "Home Office";
      } else {
        // Handle boolean values
        return String(value);
      }
    } else {
      return String(value); // Ensure the value is converted to a string for other types
    }
  };

  const trackFilterChange = (
    filters: Filters,
    search: string,
    sort: string,
    page: number,
  ) => {
    try {
      const allFilters = { ...filters, searchTerm: search, sort, page };
      const searchFilter: any = {};

      for (const key in allFilters) {
        const dtmKey = FILTERS[key];
        const filter = allFilters[key as keyof Filters];
        if (filter !== null && filter !== undefined && dtmKey) {
          searchFilter[dtmKey] = (
            Array.isArray(filter) ? filter : [filter]
          ).map((value) => formatFilterValue(key, value));
        }
      }
      // Update digitalData globally, ensuring it exists and has the correct structure
      if (typeof window !== "undefined") {
        window.digitalData = {
          eventData: { searchFilter },
        };
      }

      window._satellite?.track(APP_CONSTANTS.ANALYTICS.SEARCH_FILTER);
    } catch (error) {
      return;
    }
  };

  return { trackPageLoad, trackFilterChange };
};

export default useDtm;
