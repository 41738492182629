import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { I18nValues } from "../../../shared/types";
import AppContext from "../../AppContext";
import IconButton from "../../components/base/IconButton";
import { LabelValue } from "../../components/base/LabelValue";
import { PillButtonListView } from "../../components/base/PillButtonListView";
import {
  CLEAR_FILTERS,
  FILTER_TYPE,
  SET_FILTER,
  UNSET_FILTER,
} from "../../contexts/actionTypes";
import useIdGenerator from "../../hooks/useIdGenerator";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { useSearchContext } from "../../hooks/useSearchContext";
import { idGenerator } from "../../utils/idGenerator";
import CreateSaveSearch from "./CreateSaveSearch";
import { FilterOption } from "./FilterCheckboxGroup";
import FiltersSectionMobileModal from "./FiltersSectionMobileModal";
import Joblist from "./Joblist";
import PaginationControls from "./Pagination";

export function extractValues(
  filters: Record<string, any>,
  t: (id: string, values?: I18nValues) => React.ReactNode,
  isInternal: boolean,
) {
  if (!filters || filters.length == 0) return [];
  const resultList: any = [];
  // order in which filters need to be shown in filters section
  const orderedFilterKeys = [
    FILTER_TYPE.HOME_OFFICE,
    FILTER_TYPE.LOCATIONS,
    FILTER_TYPE.KEYWORD,
    FILTER_TYPE.PRODUCT,
    FILTER_TYPE.LANGUAGES,
    ...(isInternal
      ? [
          FILTER_TYPE.RETAIL_ROLES,
          FILTER_TYPE.HIRING_MANAGER,
          FILTER_TYPE.MINIMUM_HOURS,
          FILTER_TYPE.MAXIMUM_HOURS,
          FILTER_TYPE.JOB_LEVEL,
        ]
      : []),
    FILTER_TYPE.TEAMS,
  ];
  orderedFilterKeys.forEach((key: string) => {
    if (filters[key]) {
      if (key == FILTER_TYPE.HOME_OFFICE) {
        resultList.push({
          filterType: key,
          id: key,
          name: t("jobsite.jobdetails.homeOffice") as string,
        });
      } else if (key == FILTER_TYPE.JOB_LEVEL) {
        resultList.push(
          ...filters[key].map((item: FilterOption) => {
            return { filterType: key, id: item.id, name: item.name };
          }),
        );
      } else if (key == FILTER_TYPE.KEYWORD) {
        resultList.push(
          ...filters[key].map((item: any) => {
            return {
              filterType: key,
              id: item,
              name: item,
            };
          }),
        );
      } else if (key == FILTER_TYPE.MAXIMUM_HOURS) {
        resultList.push({
          filterType: key,
          id: key,
          name: `${t("jobsite.search.maxHours") as string}: ${filters[key]}`,
        });
      } else if (key == FILTER_TYPE.MINIMUM_HOURS) {
        resultList.push({
          filterType: key,
          id: key,
          name: `${t("jobsite.search.minHours") as string}: ${filters[key]}`,
        });
      } else {
        resultList.push(
          ...filters[key].map((item: any) => {
            return { ...item, filterType: key };
          }),
        );
      }
    }
  });
  return resultList;
}

function ResultsSection() {
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { isInternal } = appUIState.appData;
  const { state, dispatch } = useSearchContext();
  const filters = state.filters;
  const searchTerm = state.search || "";
  const { searchResults } = state || [];
  const resultsId = useIdGenerator("search", "results");
  const currentPage = state.page;
  const allFilterArr = extractValues({ ...state.filters }, t, isInternal);
  const [allFilters, setAllFilters] = useState(allFilterArr);
  const isMobile = useIsMobile();
  const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(false);
  const hasFetched = useRef(false); // Added to skip rendering on first load
  const dispatchFilter = useCallback(
    (list: Array<any>, filterName: string) => {
      if (list.length == 0) {
        dispatch({
          type: UNSET_FILTER,
          filterName: filterName,
        });
      } else {
        dispatch({
          type: SET_FILTER,
          filterName: filterName,
          payload: list,
        });
      }
    },
    [dispatch],
  );
  const onRemoveFilter = useCallback(
    (option: any) => {
      if (option.filterType == FILTER_TYPE.LOCATIONS) {
        if (filters.locations && filters.locations.length > 0) {
          const updatedLocations = [...filters.locations].filter(
            (location) => location.id != option.id,
          );
          dispatchFilter(updatedLocations, FILTER_TYPE.LOCATIONS);
        }
      }
      if (option.filterType == FILTER_TYPE.KEYWORD) {
        if (filters.keywords && filters.keywords.length > 0) {
          const updatedKeywords = [...filters.keywords].filter(
            (keyword) => keyword != option.name,
          );
          dispatchFilter(updatedKeywords, FILTER_TYPE.KEYWORD);
        }
      }
      if (option.filterType == FILTER_TYPE.MAXIMUM_HOURS) {
        dispatch({
          type: UNSET_FILTER,
          filterName: FILTER_TYPE.MAXIMUM_HOURS,
        });
      }
      if (option.filterType == FILTER_TYPE.MINIMUM_HOURS) {
        dispatch({
          type: UNSET_FILTER,
          filterName: FILTER_TYPE.MINIMUM_HOURS,
        });
      }
      if (option.filterType == FILTER_TYPE.JOB_LEVEL) {
        if (filters.jobLevel && filters.jobLevel.length > 0) {
          const updatedJobLevels = [...filters.jobLevel].filter(
            (jl) => jl.id != option.id,
          );
          dispatchFilter(updatedJobLevels, FILTER_TYPE.JOB_LEVEL);
        }
      }
      if (option.filterType == FILTER_TYPE.HIRING_MANAGER) {
        if (filters.hiringManagers && filters.hiringManagers.length > 0) {
          const updatedHiringManagers = [...filters.hiringManagers].filter(
            (hm) => hm.id != option.id,
          );
          dispatchFilter(updatedHiringManagers, FILTER_TYPE.HIRING_MANAGER);
        }
      }
      if (option.filterType == FILTER_TYPE.PRODUCT) {
        if (filters.products && filters.products.length > 0) {
          const updatedProducts = [...filters.products].filter(
            (product) => product.id != option.id,
          );
          dispatchFilter(updatedProducts, FILTER_TYPE.PRODUCT);
        }
      }
      if (option.filterType == FILTER_TYPE.LANGUAGES) {
        if (filters.languages && filters.languages.length > 0) {
          const updatedLanguages = [...filters.languages].filter(
            (language) => language.id != option.id,
          );
          dispatchFilter(updatedLanguages, FILTER_TYPE.LANGUAGES);
        }
      }
      if (option.filterType == FILTER_TYPE.RETAIL_ROLES) {
        if (filters.retailRoles && filters.retailRoles.length > 0) {
          const updatedRetailRoles = [...filters.retailRoles].filter(
            (retailRole) => retailRole.id != option.id,
          );
          dispatchFilter(updatedRetailRoles, FILTER_TYPE.RETAIL_ROLES);
        }
      }
      if (option.filterType == FILTER_TYPE.TEAMS) {
        if (filters.teams && filters.teams.length > 0) {
          const updatedTeams = [...filters.teams].filter(
            (team) =>
              !(team.code == option.code && team.teamCode == option.teamCode),
          );
          dispatchFilter(updatedTeams, FILTER_TYPE.TEAMS);
        }
      }
      if (option.filterType == FILTER_TYPE.HOME_OFFICE) {
        dispatch({
          type: UNSET_FILTER,
          filterName: FILTER_TYPE.HOME_OFFICE,
        });
      }
    },
    [
      filters.locations,
      filters.keywords,
      filters.jobLevel,
      filters.hiringManagers,
      filters.products,
      filters.languages,
      filters.retailRoles,
      filters.teams,
      dispatchFilter,
      dispatch,
    ],
  );

  const handleClearFilters = () => {
    dispatch({ type: CLEAR_FILTERS });
  };
  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      return;
    }
    const allFilterArr = extractValues({ ...state.filters }, t, isInternal);
    setAllFilters([...allFilterArr] as any);
  }, [isInternal, state.filters, t]);

  const mobileFilterButtonId = idGenerator(
    "search",
    "mobile-filters-applied",
  ).generateId("button");

  return (
    <section
      className={`search-results-section  pt-20 ${state.filterVisible ? "pl-20" : ""} `}
      id={resultsId}
    >
      {isMobile && isMobileFiltersOpen && (
        <FiltersSectionMobileModal
          elementIdToFocusOnClose={mobileFilterButtonId}
          isMobileFiltersOpen={isMobileFiltersOpen}
          clearFilters={handleClearFilters}
          setIsMobileFiltersOpen={setIsMobileFiltersOpen}
        />
      )}
      {allFilters.length > 0 ? (
        <>
          {isMobile && (
            <div className={`d-flex justify-center mt-10 mb-20`}>
              <IconButton
                onClick={() => setIsMobileFiltersOpen(true)}
                name={"filter-list-blue"}
                size={"xsmall"}
                cursor={"pointer"}
                classes={"cursor-pointer pr-10 link t-body-reduced"}
                iconClasses={"t-body-reduced"}
                label={
                  t("jobsite.common.filterAppliedCount", {
                    count: allFilters.length,
                  }) as string
                }
                id={mobileFilterButtonId}
              />
            </div>
          )}
          {!isMobile && (
            <div
              className={`d-flex row ${isMobile ? "justify-center" : "justify-between"}`}
            >
              <div className="mt-5 t-body-reduced column large-8 label-grey">
                {searchTerm && (
                  <span className="mr-40 d-inline-block">
                    {t("jobsite.search.searchedFor", { searchTerm })}
                  </span>
                )}
                <span
                  className="d-inline-block"
                  role="status"
                  aria-live="polite"
                >
                  {t("jobsite.common.filterAppliedCount", {
                    count: allFilters.length,
                  })}
                </span>
              </div>
              <div className="column large-4 text-right">
                <CreateSaveSearch
                  id={idGenerator("savedsearches", "create").generateId()}
                ></CreateSaveSearch>
              </div>
            </div>
          )}
          {!isMobile && (
            <div className="d-flex selected-options justify-center u-border-bottom pb-30 ">
              <PillButtonListView
                moduleName={"search"}
                showCount={false}
                showClearAll={false}
                showOnePillPerLine={true}
                selectTranslationLabel={""}
                countReplacementKey={""}
                pillBtnList={allFilters}
                itemAriaLabel={t("jobsite.search.filters") as string}
                pillBtnOptions={{
                  getKey: (data: FilterOption) => `${data.id}`,
                  getLabel: (data: FilterOption) =>
                    `${data.displayName || data.name}`,
                  getLabelNode: (data: any) =>
                    data.filterType == FILTER_TYPE.TEAMS ? (
                      <LabelValue
                        label={data.teamName}
                        value={data.name}
                      ></LabelValue>
                    ) : data.filterType == FILTER_TYPE.KEYWORD ? (
                      <LabelValue
                        label={t("jobsite.savedsearch.keyword") as string}
                        value={data.name}
                      ></LabelValue>
                    ) : null,
                }}
                onPillBtnRemove={onRemoveFilter}
                onClearAllBtnClick={() => {}}
              ></PillButtonListView>
            </div>
          )}
        </>
      ) : (
        <>
          {isMobile && (
            <div className={`d-flex justify-center mt-10 mb-20`}>
              <IconButton
                onClick={() => setIsMobileFiltersOpen(true)}
                name={"filter-list-blue"}
                size={"xsmall"}
                cursor={"pointer"}
                classes={"cursor-pointer pr-10 link t-body-reduced"}
                iconClasses={"t-body-reduced"}
                label={t("jobsite.search.filters") as string}
                id={idGenerator("search", "mobile-filters").generateId(
                  "button",
                )}
              />
            </div>
          )}
        </>
      )}
      <Joblist allFiltersLength={allFilters.length} />
      {searchResults.length ? (
        <PaginationControls currentPage={currentPage || 1} />
      ) : null}
    </section>
  );
}

export default ResultsSection;
