import { Overlay } from "@rpe-js/marcom-web-components";
import React, { useState } from "react";
import { Email } from "../../../../../shared/types/emailVerification";
import useIntlMessage from "../../../../hooks/useIntlMessage";
import useIsMobile from "../../../../hooks/useIsMobile";
import {
  EMAIL_REPLACE_BUTTON,
  EMAIL_VERIFICATION_MODAL_ID,
  EMAIL_VERIFICATION_MODAL_TITLE_ID,
} from "../constants";
import { EmailCodeVerificationContent } from "./emailCodeVerificationContent";

type EmailVerificationModalProps = {
  showModal: boolean;
  onAction: () => void;
  emailData: Email;
  onCancel: () => void;
  onClose: () => void;
};

export const EmailCodeVerificationModal = ({
  showModal,
  onAction,
  emailData,
  onCancel,
  onClose,
}: EmailVerificationModalProps) => {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const [isPopoverActive, setIsPopoverActive] = useState<boolean>(false);

  return (
    <>
      {showModal && (
        <Overlay
          id={EMAIL_VERIFICATION_MODAL_ID}
          visible={showModal}
          onClose={onClose}
          noCloseButton={true}
          isFullscreen={isMobile}
          disableClickAway={true}
          disableEsc={isPopoverActive}
          elementIdToFocus={EMAIL_REPLACE_BUTTON}
          ariaLabel={EMAIL_VERIFICATION_MODAL_TITLE_ID}
        >
          <EmailCodeVerificationContent
            t={t}
            emailData={emailData}
            onCancel={onCancel}
            onAction={onAction}
            setIsPopoverActive={setIsPopoverActive}
          />
        </Overlay>
      )}
    </>
  );
};
