import React, { useContext, useEffect, useReducer } from "react";
import { Outlet } from "react-router";
import AppContext from "./AppContext";
import CurrentUserContext, {
  initialUserState,
  reducer,
} from "./CurrentUserContext";
import { getConfig, getCurrentUser } from "./api/fetchClient";
import { useFetchMultipleData } from "./hooks/useFetchMultipleData";

export default function AuthenticatedApp() {
  const { appUIState } = useContext(AppContext);
  const [state, dispatch] = useReducer(reducer, initialUserState);
  const { isSessionAuthenticated } = appUIState.appData;
  const { data, fetchData } = useFetchMultipleData([getCurrentUser, getConfig]);

  if (!isSessionAuthenticated) {
    window.location.reload();
  }

  useEffect(() => {
    if (isSessionAuthenticated) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSessionAuthenticated]);

  useEffect(() => {
    if (data?.length) {
      dispatch({
        type: "SET_CURRENT_USER_AND_CONFIG",
        payload: { currentUser: data[0], config: data[1] },
      });
    }
  }, [data]);

  return (
    <>
      {state.currentUser && state.currentUser.talentId && (
        <CurrentUserContext.Provider
          value={{
            currentUser: state.currentUser,
            config: state.config,
            rolesCount: state.rolesCount,
            dispatch,
          }}
        >
          <main className="main">
            <Outlet />
          </main>
        </CurrentUserContext.Provider>
      )}
    </>
  );
}
