import React, { useContext, useEffect } from "react";
import APP_CONSTANTS from "../../../utilities/appConstants";
import AppContext from "../../AppContext";
import PrivacyPolicy from "../../components/feature/PrivacyPolicy";
import useDtm from "../../hooks/useAdobeAnalytics";
import useSetPageTitle, { PAGE_TITLES } from "../../hooks/useSetPageTitle";

export default function PrivacyPolicyPage() {
  const { appUIState } = useContext(AppContext);
  const { appData } = appUIState;
  const { trackPageLoad } = useDtm();
  const { setPageTitle } = useSetPageTitle();
  useEffect(() => {
    setPageTitle(PAGE_TITLES.PRIVACY_POLICY);
    const pageInfo = {
      pageName: APP_CONSTANTS.ANALYTICS.PAGE_NAME.PRIVACY_POLICY,
      locale: appUIState?.appData?.locale || APP_CONSTANTS.DEFAULT_LOCALE,
      title: APP_CONSTANTS.ANALYTICS.PAGE_NAME.PRIVACY_POLICY,
      isInternal: appUIState?.appData?.isInternal,
    };
    trackPageLoad(pageInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <PrivacyPolicy
      disableFocusOut={true}
      disableCountrySelector={appData.isInternal}
      triggerElementId="" // passing empty Id here as this is not a user triggered action. Once complete the page reloads and follow the focus of the page.
    />
  );
}
