import { Button } from "@rpe-js/marcom-web-components";
import React from "react";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { idGenerator } from "../../../utils/idGenerator";
import { useMyApplicationContext } from "./context/context";

interface ActionContainerProps {
  saveDisabled: boolean;
  onBack: () => void;
  onSave: () => void;
  onSubmit: () => void;
}

export function ActionContainer(props: ActionContainerProps) {
  const { t } = useIntlMessage();
  const { state } = useMyApplicationContext();
  const { currentStep } = state;
  return (
    <ul role="list" className={`d-flex list-nobullet mt-40 mb-40`}>
      {(currentStep === "application" ||
        currentStep === "informationRelease") && (
        <li role="listitem" className="mr-20 d-flex-equal">
          <Button
            id={idGenerator("profileApplication", "step-back").generateId(
              "button",
            )}
            size="base"
            color="secondary"
            blockedVariant={true}
            label={t("jobsite.profile.myApplication.back") as string}
            onClick={props.onBack}
          />
        </li>
      )}
      {currentStep !== "reviewSubmit" && (
        <li role="listitem" className="d-flex-equal">
          <Button
            id={idGenerator(
              "profileApplication",
              "step-save-continue",
            ).generateId("button")}
            disabled={props.saveDisabled}
            size="base"
            color="primary"
            blockedVariant={true}
            label={t("jobsite.profile.myApplication.saveContinue") as string}
            onClick={props.onSave}
          />
        </li>
      )}
      {currentStep === "reviewSubmit" && (
        <li role="listitem" className="d-flex-equal">
          <Button
            //disabled={props.saveDisabled}
            size="base"
            color="primary"
            blockedVariant={true}
            label={t("jobsite.profile.myApplication.submit") as string}
            onClick={props.onSave}
            id={idGenerator(
              "profileApplication",
              "step-review-submit",
            ).generateId("button")}
          />
        </li>
      )}
    </ul>
  );
}
