import {
  DropDownOptionProps,
  ProgressIndicatorLoader,
} from "@rpe-js/marcom-web-components";
import { cloneDeep } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Language } from "../../../../shared/types/talent/talent";
import { updateTalent } from "../../../api/fetchClient";
import { APP_TYPE, PROFILE_SECTIONS } from "../../../app.constants";
import AppContext from "../../../AppContext";
import { Info } from "../../../components/base/Info";
import { Label } from "../../../components/base/Label";
import { SaveCancelAction } from "../../../components/feature/saveAndCancel";
import { useAppAlertContext } from "../../../contexts/AppAlert";
import { useFetchData } from "../../../hooks/useFetchData";
import useFocusFirstErrorField from "../../../hooks/useFocusFirstErrorField";
import useIntlMessage from "../../../hooks/useIntlMessage";
import {
  focusElementWithFallback,
  handleScrollToElement,
} from "../../../utils/focusUtil";
import { idGenerator } from "../../../utils/idGenerator";
import { updateLanguages } from "../context/Actions";
import { useProfileContext } from "../context/ProfileContext";
import { EditSpokenLanguages } from "./editSpokenLanguages";
import { ProfileSection } from "./profileSection";
import { getLanguageForm, LanguageForm } from "./spokenLanguagesFormUtils";

type SpokenLanguagesProps = {
  langaugesData: DropDownOptionProps[] | undefined;
  proficiencyData: DropDownOptionProps[] | undefined;
};

export const SpokenLanguagesSection = ({
  langaugesData,
  proficiencyData,
}: SpokenLanguagesProps) => {
  const [isEdit, setEdit] = useState(false);
  const [showError, setShowError] = useState(false);
  const { state, dispatch } = useProfileContext();
  const { updateAlert, deleteAlert } = useAppAlertContext();
  const mode = "profile";
  const languagesIdGenerator = idGenerator(mode, "languages");
  const { setFocusFirstErrorField } = useFocusFirstErrorField(
    languagesIdGenerator.generateId(),
  );
  const {
    isLoading: isSaveTalentLoading,
    isSuccess: isSaveTalentSuccess,
    fetchData: saveTalent,
    isError: isSaveTalentError,
    resetFetchStatus,
  } = useFetchData(updateTalent);
  const [languages, setLanguages] = useState<LanguageForm[]>([]);
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { locale, appType } = appUIState.appData;

  const validateDuplicateLanguages = useCallback(
    (languagesToValidate: LanguageForm[]) => {
      const languagesFormCopy = cloneDeep(languagesToValidate);
      languagesFormCopy.forEach((language: LanguageForm) => {
        const foundLanguages = languagesToValidate.filter(
          (lang) => language.languageID === lang.languageID,
        );
        if (foundLanguages.length > 1) {
          language.languageError = t("jobsite.information.duplicateMessage1", {
            language: language.languageName,
          }) as string;
        } else {
          language.languageError = "";
        }
      });
      setLanguages([...languagesFormCopy]);
    },
    [t],
  );

  const handleValueChange = useCallback(
    (
      index: number,
      selectedValue: DropDownOptionProps | boolean,
      name: string,
    ) => {
      const languagesCopy: LanguageForm[] = languages;
      if (name === "language") {
        languagesCopy[index].languageID = (
          selectedValue as DropDownOptionProps
        ).value;
        languagesCopy[index].languageName = (
          selectedValue as DropDownOptionProps
        ).label;
        languagesCopy[index].languageError = "";
      } else if (name === "proficiency") {
        languagesCopy[index].proficiencyID = (
          selectedValue as DropDownOptionProps
        ).value;
        languagesCopy[index].proficiencyName = (
          selectedValue as DropDownOptionProps
        ).label;
      } else if (name === "preferredLanguage") {
        if (selectedValue === true) {
          languagesCopy.forEach((lang) => (lang.preferredLanguage = false));
        }
        languagesCopy[index].preferredLanguage = selectedValue as boolean;
      }
      setLanguages([...languagesCopy]);
      if (name === "language") {
        validateDuplicateLanguages(languagesCopy);
      }
    },
    [languages, validateDuplicateLanguages],
  );

  const validateLanguage = useCallback(() => {
    const languagesFormCopy = cloneDeep(languages);
    let isError = false;
    languagesFormCopy.forEach((language: LanguageForm) => {
      if (!language.languageID) {
        language.languageError = t("jobsite.common.errorlanguage") as string;
      }
      if (language.languageError) {
        isError = true;
      }
    });
    if (isError) {
      setLanguages([...languagesFormCopy]);
    }
    return isError;
  }, [languages, t]);

  const addLanguage = useCallback(
    (index: number) => {
      setLanguages([...languages, getLanguageForm()]);
      const newIndex = index + 1;
      focusElementWithFallback(
        idGenerator(mode, "language").generateId(`${newIndex}`),
      );
    },
    [languages],
  );

  const removeLanguage = useCallback(
    (index: number) => {
      const updatedLanguages = languages.filter((_, i) => i !== index);
      setLanguages(updatedLanguages);
      validateDuplicateLanguages(updatedLanguages);
      const newIndex = Math.min(index, updatedLanguages.length - 1);
      const addLanguageButtonId = idGenerator(mode, "add-language").generateId(
        `${newIndex}`,
      );
      const removeLanguageButtonId = idGenerator(
        mode,
        "remove-language",
      ).generateId(`${newIndex}`);
      focusElementWithFallback(addLanguageButtonId, [removeLanguageButtonId]);
    },
    [languages, validateDuplicateLanguages],
  );

  const saveChanges = useCallback(() => {
    deleteAlert();
    setShowError(true);
    if (validateLanguage()) {
      setFocusFirstErrorField(true);
      return;
    }
    saveTalent([
      state?.talentId,
      PROFILE_SECTIONS.LANGUAGES,
      {
        languages: languages.map((language: LanguageForm) => ({
          languageID: language.languageID,
          languageName: language.languageName,
          preferredLanguage: language.preferredLanguage,
          proficiencyID: language.proficiencyID,
          proficiencyName: language.proficiencyName,
        })),
      },
    ]);
  }, [
    deleteAlert,
    languages,
    saveTalent,
    setFocusFirstErrorField,
    state?.talentId,
    validateLanguage,
  ]);

  const cancelChanges = () => {
    setShowError(false);
    setEdit(false);
  };

  const onEdit = useCallback(() => {
    const languages = state?.languages;
    const languagesForm = [];
    if (languages?.length) {
      languages?.forEach((language: Language) => {
        languagesForm.push(getLanguageForm(language));
      });
    } else {
      languagesForm.push(getLanguageForm());
    }
    setLanguages(languagesForm);
    setEdit(true);
  }, [state?.languages]);

  useEffect(() => {
    if (!isSaveTalentLoading && isSaveTalentSuccess) {
      dispatch?.(updateLanguages(languages as Language[]));
      resetFetchStatus();
      setEdit(false);
      handleScrollToElement(languagesIdGenerator.generateId());
    }
    if (!isSaveTalentLoading && isSaveTalentError) {
      updateAlert(false);
      resetFetchStatus();
    }
  }, [
    dispatch,
    isSaveTalentError,
    isSaveTalentLoading,
    isSaveTalentSuccess,
    languages,
    languagesIdGenerator,
    resetFetchStatus,
    t,
    updateAlert,
  ]);

  return (
    <ProfileSection
      isEdit={isEdit}
      i18nTitle="jobsite.common.langSpokenTitle"
      onEdit={onEdit}
      id={languagesIdGenerator.generateId()}
      sectionName="languages"
    >
      {isEdit ? (
        <>
          {isSaveTalentLoading && (
            <ProgressIndicatorLoader showLoading={true} />
          )}
          <div>
            <EditSpokenLanguages
              mode={mode}
              languages={languages}
              addLanguage={addLanguage}
              removeLanguage={removeLanguage}
              langaugesData={langaugesData as DropDownOptionProps[]}
              proficiencyData={proficiencyData as DropDownOptionProps[]}
              handleValueChange={handleValueChange}
              showError={showError}
            />
            <SaveCancelAction
              onCancel={cancelChanges}
              onSave={saveChanges}
              cancelButtonId={languagesIdGenerator.generateId("cancelButton")}
              saveButtonId={languagesIdGenerator.generateId("saveButton")}
              scrollToTopSectionId={languagesIdGenerator.generateId()}
            />
          </div>
        </>
      ) : (
        <>
          {state?.languages?.length ? (
            <>
              {state?.languages?.map((langObj, index) => (
                <div
                  key={index}
                  className={`${index > 0 ? "u-border-top pt-20" : ""}`}
                >
                  <div className="row">
                    <div className="column large-6 small-12 d-flex flex-column mb-10">
                      <Label
                        label={
                          t("jobsite.common.language", { locale }) as string
                        }
                      />
                      <Info value={langObj.languageName} />
                    </div>
                    <div className="column large-6 small-12 d-flex flex-column mb-10">
                      <Label
                        label={
                          t(
                            appType === APP_TYPE.EXTERNAL
                              ? "jobsite.common.preferred"
                              : "jobsite.common.useAsPreferredLang",
                          ) as string
                        }
                      />
                      <Info
                        value={
                          t(
                            langObj.preferredLanguage
                              ? "jobsite.common.yes"
                              : "jobsite.common.no",
                          ) as string
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="column large-6 small-12 d-flex flex-column mb-10">
                      <Label
                        label={
                          t("jobsite.common.proficiency", { locale }) as string
                        }
                      />
                      <Info
                        value={
                          langObj.proficiencyName
                            ? langObj.proficiencyName
                            : (t("jobsite.common.notSpecified") as string)
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              <div className="row">
                <div className="column large-6 small-12 d-flex flex-column mb-10">
                  <Label
                    label={t("jobsite.common.language", { locale }) as string}
                  />
                  <Info value={t("jobsite.common.notSpecified") as string} />
                </div>
                <div className="column large-6 small-12 d-flex flex-column mb-10">
                  <Label
                    label={
                      t(
                        appType === APP_TYPE.EXTERNAL
                          ? "jobsite.common.preferred"
                          : "jobsite.common.useAsPreferredLang",
                      ) as string
                    }
                  />
                  <Info value={t("jobsite.common.notSpecified") as string} />
                </div>
              </div>
              <div className="row">
                <div className="column large-6 small-12 d-flex flex-column mb-10">
                  <Label
                    label={
                      t("jobsite.common.proficiency", { locale }) as string
                    }
                  />
                  <Info value={t("jobsite.common.notSpecified") as string} />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </ProfileSection>
  );
};
