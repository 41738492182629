import { Counter, NavTab } from "@rpe-js/marcom-web-components";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, Outlet, useLocation } from "react-router";
import APP_CONSTANTS from "../../../utilities/appConstants";
import { getTalentRoleCount } from "../../api/fetchClient";
import { PROFILE_ROUTES } from "../../app.constants";
import AppContext from "../../AppContext";
import { PageAlert } from "../../contexts/PageAlert";
import { useCurrentUserContext } from "../../CurrentUserContext";
import useDtm from "../../hooks/useAdobeAnalytics";
import useAutoFocus from "../../hooks/useAutoFocus";
import { useFetchData } from "../../hooks/useFetchData";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { getPreferredName } from "../../util";
import { ProfileProvider } from "./context/ProfileProvider";

interface TabNavAddInfo {}

interface TabNavItem<T = TabNavAddInfo> {
  href: string;
  label: string;
  displayName: string | React.ReactNode;
  additionalInfo?: T;
}

export default function Profile() {
  const { appUIState } = useContext(AppContext);
  const { locale, isExternal, isInternal } = appUIState.appData;
  const { currentUser, rolesCount, dispatch } = useCurrentUserContext();
  const [currentTab, setCurrentTab] = useState<string>("");
  const { t } = useIntlMessage();
  const location = useLocation();
  const isMobile = useIsMobile();
  const { talentId } = currentUser || {};
  const { trackPageLoad } = useDtm();
  const displayNameRef = useAutoFocus<HTMLSpanElement>();

  const {
    data: talentRoleCount,
    isLoading: isTalentRoleCountLoading,
    isSuccess: isTalentRoleCountSuccess,
    isError: isTalentRoleCountError,
    fetchData: fetchTalRoleCount,
    resetFetchStatus,
  } = useFetchData<number>(getTalentRoleCount);

  // useEffect to make getRoles API call on load
  useEffect(() => {
    if (talentId) {
      fetchTalRoleCount([[talentId]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !isTalentRoleCountLoading &&
      isTalentRoleCountSuccess &&
      talentRoleCount !== null
    ) {
      dispatch({
        type: "SET_ROLES_COUNT",
        payload: talentRoleCount as number,
      });
      resetFetchStatus();
    }
    if (!isTalentRoleCountLoading && isTalentRoleCountError) {
      // ToDo errror log to be added
      dispatch({
        type: "SET_ROLES_COUNT",
        payload: 0,
      });
      resetFetchStatus();
    }
  }, [
    dispatch,
    isTalentRoleCountError,
    isTalentRoleCountLoading,
    isTalentRoleCountSuccess,
    resetFetchStatus,
    talentRoleCount,
  ]);
  useEffect(() => {
    let currentTitleName = APP_CONSTANTS.ANALYTICS.PROFILE;
    let currentPageName = APP_CONSTANTS.ANALYTICS.PROFILE;
    if (currentTab === PROFILE_ROUTES.INFO) {
      currentPageName = APP_CONSTANTS.ANALYTICS.INFO;
    } else if (currentTab === PROFILE_ROUTES.YOUR_ROLES) {
      currentPageName = APP_CONSTANTS.ANALYTICS.YOUR_ROLES;
    } else if (currentTab === PROFILE_ROUTES.SAVED_SEARCH) {
      currentPageName = APP_CONSTANTS.ANALYTICS.SAVED_SEARCH;
    } else if (currentTab === PROFILE_ROUTES.YOUR_APPLICATION) {
      currentTitleName = APP_CONSTANTS.ANALYTICS.YOUR_APPLICATION;
      currentPageName = APP_CONSTANTS.ANALYTICS.YOURAPPLICATION_INFO;
    }

    const pageInfo = {
      pageName: currentPageName,
      locale: appUIState?.appData?.locale || APP_CONSTANTS.DEFAULT_LOCALE,
      title: currentTitleName,
      isInternal: appUIState?.appData?.isInternal,
    };
    if (currentTab && currentTab.length > 0) {
      trackPageLoad(pageInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  useEffect(() => {
    const paths = location.pathname.split("/");
    if (paths.includes(PROFILE_ROUTES.INFO)) setCurrentTab(PROFILE_ROUTES.INFO);
    else if (paths.includes(PROFILE_ROUTES.YOUR_ROLES))
      setCurrentTab(PROFILE_ROUTES.YOUR_ROLES);
    else if (paths.includes(PROFILE_ROUTES.SAVED_SEARCH))
      setCurrentTab(PROFILE_ROUTES.SAVED_SEARCH);
    else if (paths.includes(PROFILE_ROUTES.YOUR_APPLICATION))
      setCurrentTab(PROFILE_ROUTES.YOUR_APPLICATION);
  }, [location]);

  const getCurrentTabName = useCallback(() => {
    if (currentTab === PROFILE_ROUTES.INFO) {
      return t("jobsite.common.information");
    } else if (currentTab === PROFILE_ROUTES.YOUR_ROLES) {
      return t("jobsite.common.yourRoles");
    } else if (currentTab === PROFILE_ROUTES.SAVED_SEARCH) {
      return t("jobsite.common.saveSearches");
    } else if (currentTab === PROFILE_ROUTES.YOUR_APPLICATION) {
      return t("jobsite.common.myApplication");
    }
  }, [currentTab, t]);

  const tabItems = useMemo(() => {
    const items = [
      {
        href: `/app/${locale}/profile/info`,
        label: PROFILE_ROUTES.INFO,
        displayName: t("jobsite.common.information"),
        additionalInfo: {},
      },
      {
        href: `/app/${locale}/profile/roles`,
        label: PROFILE_ROUTES.YOUR_ROLES,
        displayName: (
          <span className="d-flex">
            {t("jobsite.common.yourRoles")}
            {rolesCount > 0 && (
              <Counter count={rolesCount} className={`blue`} />
            )}
          </span>
        ),
        additionalInfo: {},
      },
      {
        href: `/app/${locale}/profile/savedsearch`,
        label: PROFILE_ROUTES.SAVED_SEARCH,
        displayName: t("jobsite.common.saveSearches"),
        additionalInfo: {},
      },
    ] as TabNavItem[];

    if (currentUser.enableUSEmploymentApplication) {
      items.push({
        href: `/app/${locale}/profile/myapplication`,
        label: PROFILE_ROUTES.YOUR_APPLICATION,
        displayName: t("jobsite.common.myApplication"),
        additionalInfo: {},
      });
    }
    return items;
  }, [currentUser.enableUSEmploymentApplication, locale, rolesCount, t]);

  const renderLink = useCallback(
    (
      tabItem: TabNavItem<TabNavAddInfo>,
      className: string,
      isActive: boolean,
    ) => {
      return (
        <Link
          to={tabItem.href}
          className={`${className}`}
          data-attr={isActive}
          aria-current={isActive ? "page" : "false"}
        >
          {tabItem.displayName}
        </Link>
      );
    },
    [],
  );

  return (
    <>
      <div className="text-center mt-20 mb-20">
        <span
          ref={displayNameRef}
          tabIndex={0}
          className="t-headline fw-medium"
        >
          {!isMobile
            ? t("jobsite.common.displayName", {
                firstName: getPreferredName(
                  currentUser?.contact?.firstName as string,
                  currentUser?.contact?.preferredName,
                ),
                lastName: currentUser?.contact?.lastName,
              })
            : getCurrentTabName()}
        </span>
        {!isMobile && (
          <NavTab
            items={tabItems}
            onTabChange={setCurrentTab}
            defaultActiveTab={currentTab}
            mode="custom"
            renderLink={renderLink}
          />
        )}
        {currentTab === PROFILE_ROUTES.INFO && isExternal && (
          <>
            <h1 className="a11y">
              {t("jobsite.profile.yourInformationLabel")}
            </h1>
            <p className="mt-20">{t("jobsite.common.profileInfoMessage")}</p>
          </>
        )}
        {currentTab === PROFILE_ROUTES.INFO && isInternal && (
          <>
            <h1 className="a11y">
              {t("jobsite.profile.yourInformationLabel")}
            </h1>
            <p className="mt-20">
              {t("jobsite.common.internalProfileUpdateInfoMessage")}
            </p>
          </>
        )}
        {currentTab === PROFILE_ROUTES.YOUR_ROLES && (
          <>
            <h1 className="a11y">{t("jobsite.common.yourRoles")}</h1>
            <p className="mt-20">{t("jobsite.common.roleMessage1")}</p>
          </>
        )}
        {currentTab === PROFILE_ROUTES.SAVED_SEARCH && (
          <>
            <h1 className={`a11y`}>
              {t("jobsite.profile.yourSavedSearchLabel")}
            </h1>
            <p className="mt-20">{t("jobsite.common.roleMessage2")}</p>
          </>
        )}
        {currentTab === PROFILE_ROUTES.YOUR_APPLICATION && (
          <>
            <h1 className="a11y">{t("jobsite.profile.myApplication")}</h1>
            <p className="mt-20">{t("jobsite.common.myApplicationMessage")}</p>
          </>
        )}
      </div>
      <ProfileProvider>
        <PageAlert>
          <Outlet />
        </PageAlert>
      </ProfileProvider>
    </>
  );
}
