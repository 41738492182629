// TODO: This should call polling API every 10 sec if the profileUpdateSelection is resume and these retries should be only 12 times
// Take data from layout and call method to start polling
// This will need access to appcontext
//
import { useCallback, useContext } from "react";
import AppContext from "../AppContext";
import { getResumelastaction } from "../api/fetchClient";
import {
  MAX_POLLING_RETRY,
  POLLING_FIELD,
  POLLING_TIMEOUT,
  ResumeActionType,
} from "../app.constants";
import { ProfileUpdateSelection, ResumeParsingData } from "../types";

export function useTriggerLinkedInResumePolling() {
  const { dispatch: dispatchToAppContext } = useContext(AppContext);
  const trigger = useCallback(
    (profileUpdateSelection?: ProfileUpdateSelection) => {
      if (profileUpdateSelection && profileUpdateSelection === "linkedin") {
        dispatchToAppContext({
          // Dispatch an event to AppContext to trigger resume polling or linkedin modal
          type: "TRIGGER_LINKEDIN_RESUME_POLLING",
          payload: {
            resumeParsingData: {
              linkedinLastAction: ResumeActionType.REVIEW_LATER,
              resumeLastAction: "",
              profileUpdateSelection: "linkedin",
            },
            startPolling: false,
            showResumeActionsModal: true,
          },
        });
      } else if (
        profileUpdateSelection &&
        profileUpdateSelection === "resume"
      ) {
        dispatchToAppContext({
          type: "TRIGGER_LINKEDIN_RESUME_POLLING",
          payload: {
            resumeParsingData: {
              linkedinLastAction: "",
              resumeLastAction: ResumeActionType.PROGRESS,
              profileUpdateSelection: "resume",
            },
            startPolling: true,
            showResumeActionsModal: false,
          },
        });
      } else {
        dispatchToAppContext({
          type: "TRIGGER_LINKEDIN_RESUME_POLLING",
          payload: null,
        });
      }
    },
    [dispatchToAppContext],
  );
  return trigger;
}

function useResumePolling() {
  const { appUIState, dispatch } = useContext(AppContext);

  const startPolling = useCallback(
    (resumeParsingData: ResumeParsingData) => {
      let pollintInterval: number = 0;
      let pollCount = 0;
      if (
        appUIState.appData?.talentId &&
        resumeParsingData?.profileUpdateSelection === "resume" &&
        pollCount < MAX_POLLING_RETRY
      ) {
        pollintInterval = window.setInterval(() => {
          getResumelastaction(appUIState.appData?.talentId as string)
            .then((response) => {
              ++pollCount;
              if (
                (response && response[POLLING_FIELD]) ||
                pollCount >= MAX_POLLING_RETRY
              ) {
                clearInterval(pollintInterval);
                dispatch({
                  type: "RESUME_PARSING_DATA",
                  payload: {
                    profileUpdateSelection: "resume",
                    resumeLastAction: response?.lastAction,
                    linkedinLastAction: undefined,
                  },
                });
                if (response?.lastAction === ResumeActionType.REVIEW_LATER) {
                  dispatch({
                    type: "RESUME_PARSING_REVIEW_ACTIONS",
                    payload: {
                      showResumeActionsModal: true,
                    },
                  });
                }
              }
            })
            .catch(() => {
              ++pollCount;
              if (pollCount >= MAX_POLLING_RETRY) {
                if (pollintInterval) {
                  clearInterval(pollintInterval);
                }
              }
            });
        }, POLLING_TIMEOUT);
      }
    },
    [appUIState, dispatch],
  );

  return startPolling;
}

export default useResumePolling;
