import { ProgressIndicatorLoader } from "@rpe-js/marcom-web-components";
import { cloneDeep, isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import {
  EducationDegreeRefData,
  GraduationStatusRefData,
  RefData,
} from "../../../../shared/types/refData";
import { Education } from "../../../../shared/types/talent/talent";
import { updateTalent } from "../../../api/fetchClient";
import { PROFILE_SECTIONS } from "../../../app.constants";
import { useFormInputValidator } from "../../../components/feature/form/inputValidators/useFormInputValidator";
import { SaveCancelAction } from "../../../components/feature/saveAndCancel";
import { useAppAlertContext } from "../../../contexts/AppAlert";
import { useFetchData } from "../../../hooks/useFetchData";
import useFocusFirstErrorField from "../../../hooks/useFocusFirstErrorField";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { focusElementWithFallback } from "../../../utils/focusUtil";
import { idGenerator } from "../../../utils/idGenerator";
import {
  EducationDegreeForm,
  getEducationDegreeForm,
  getEducationPayload,
} from "../components/educationSummary/form";
import { updateEducation } from "../context/Actions";
import { useProfileContext } from "../context/ProfileContext";
import { EditEducationSection } from "./editEducationSection";

type EditEducationSummaryProps = {
  degreeOptions: EducationDegreeRefData[];
  gradStatusOptions: GraduationStatusRefData[];
  educationDegree: Education[];
  onSave: () => void;
  onCancel: () => void;
};

export const EditEducationSummary = ({
  degreeOptions,
  gradStatusOptions,
  educationDegree,
  onCancel,
  onSave,
}: EditEducationSummaryProps) => {
  const { t } = useIntlMessage();
  const { updateAlert, deleteAlert } = useAppAlertContext();
  const { state, dispatch } = useProfileContext();
  const { isValidSchoolSkill } = useFormInputValidator();
  const [displayErrors, setDisplayErrors] = useState<boolean>(false);
  const mode = "profile";
  const educationDegreesIdGenerator = idGenerator(mode, "educationDegrees");
  const profileDegreeIdGenerator = idGenerator(mode, "degree");
  const { setFocusFirstErrorField } = useFocusFirstErrorField(
    educationDegreesIdGenerator.generateId(),
  );
  const [educationDegreesForm, setEducationDegreesForm] = useState<
    EducationDegreeForm[]
  >([]);

  const {
    isLoading: isSaveTalentLoading,
    isSuccess: isSaveTalentSuccess,
    fetchData: saveTalent,
    isError: isSaveTalentError,
  } = useFetchData(updateTalent);

  const { talentId } = state;

  useEffect(() => {
    if (educationDegreesForm.length === 0) {
      if (!isEmpty(educationDegree)) {
        const eduDegreeForm: EducationDegreeForm[] = [];
        educationDegree.forEach((ied) => {
          eduDegreeForm.push(getEducationDegreeForm(ied));
        });
        setEducationDegreesForm(eduDegreeForm);
      } else {
        setEducationDegreesForm([getEducationDegreeForm()]);
      }
    }
  }, [educationDegree, educationDegreesForm]);

  const onAddEducationDegree = useCallback(
    (index: number) => {
      const newDegreeForm: EducationDegreeForm = getEducationDegreeForm();
      setEducationDegreesForm([...educationDegreesForm, newDegreeForm]);
      const newIndex = index + 1;
      focusElementWithFallback(
        profileDegreeIdGenerator.generateId(`${newIndex}`),
      );
    },
    [educationDegreesForm, profileDegreeIdGenerator],
  );

  const onRemoveEducationDegree = useCallback(
    (key: number, index: number) => {
      const updatedEducation = educationDegreesForm.filter(
        (educationDegree: EducationDegreeForm) => educationDegree.key !== key,
      );
      setEducationDegreesForm([...updatedEducation]);
      const newIndex = Math.min(index, updatedEducation.length - 1);
      const addEducationDegreeButtonId = idGenerator(
        mode,
        "add-education-degree",
      ).generateId(`${newIndex}`);
      const removeEducationDegreeButtonId = idGenerator(
        mode,
        "remove-education-degree",
      ).generateId(`${newIndex}`);
      focusElementWithFallback(addEducationDegreeButtonId, [
        removeEducationDegreeButtonId,
      ]);
    },
    [educationDegreesForm],
  );

  const updateForm = useCallback(
    (update: boolean, index: number, updatedForm: EducationDegreeForm) => {
      if (update) {
        const copied = [...educationDegreesForm];
        copied.splice(index, 1, updatedForm);
        setEducationDegreesForm(copied);
      }
    },
    [educationDegreesForm],
  );

  const onSchoolOrMajorChange = useCallback(
    (
      index: number,
      option: string | RefData | null,
      type: "school" | "major",
      event: "select" | "blur" | "reset",
    ) => {
      const matchEduDegree = cloneDeep(educationDegreesForm[index]);
      let update = false; // should not update the state on every blur. Update only if option has changed on blur
      if (event === "reset") {
        matchEduDegree[type].value = "";
        matchEduDegree[type].label = "";
        update = true;
        matchEduDegree[type].requiredError = true;
      }

      if (event === "select" && option && !isEmpty(option)) {
        if (typeof option === "object") {
          matchEduDegree[type].value = option.id;
          matchEduDegree[type].id = option.id;
          matchEduDegree[type].label = option.name;
        } else {
          matchEduDegree[type].value = "";
          matchEduDegree[type].id = "";
          matchEduDegree[type].label = option;
        }
        matchEduDegree[type].requiredError = false;
        update = true;
      }

      if (event === "blur" && matchEduDegree[type].label !== option) {
        matchEduDegree[type].label = option ? (option as string) : "";
        if (!option || isEmpty(option)) {
          matchEduDegree[type].value = "";
          matchEduDegree[type].id = "";
        }
        update = true;
      }

      updateForm(update, index, matchEduDegree);
    },
    [educationDegreesForm, updateForm],
  );

  const handleValueChange = useCallback(
    (index: number, option: any, name: "degree" | "gradStatus") => {
      const matchEduDegree = cloneDeep(educationDegreesForm[index]);
      if (name === "degree") {
        matchEduDegree.degree.value = option.value;
        matchEduDegree.degree.label = option.label;
        matchEduDegree.degree.requiredError = false;
      } else if (name === "gradStatus") {
        const gradOption = gradStatusOptions?.filter(
          (opt: GraduationStatusRefData) => opt.id === option.target?.value,
        );
        matchEduDegree.gradStatus.value = option.target?.value;
        if (gradOption?.length) {
          matchEduDegree.gradStatus.label = gradOption[0].name;
        }
        matchEduDegree.gradStatus.requiredError = false;
      }
      const copied = [...educationDegreesForm];
      copied.splice(index, 1, matchEduDegree);
      setEducationDegreesForm(copied);
    },
    [educationDegreesForm, setEducationDegreesForm, gradStatusOptions],
  );

  const validateEducationForm = useCallback(
    (degree: EducationDegreeForm[]): boolean => {
      let isError = false;
      degree.forEach((d) => {
        const objs = [d.degree, d.school, d.major, d.gradStatus];
        objs.forEach((obj) => {
          if (
            (isEmpty(obj.label) && isEmpty(obj.value)) ||
            isEmpty(obj.label)
          ) {
            obj.requiredError = obj.required && true;
            if (!isError) {
              isError = obj.requiredError;
            }
          } else {
            obj.requiredError = false;
          }
        });
        if (!isEmpty(d.school.label)) {
          d.school.invalidError = !isValidSchoolSkill(d.school.label as string);
        }

        if (!isEmpty(d.major.label)) {
          d.major.invalidError = !isValidSchoolSkill(d.major.label as string);
        }
        isError =
          isError || d.school?.invalidError || d.major?.invalidError || false;
      });

      return isError;
    },
    [isValidSchoolSkill],
  );

  const saveEducationSummary = useCallback(() => {
    deleteAlert();
    const educationDegreesFormCopy = cloneDeep(educationDegreesForm);
    const formHasError = validateEducationForm(educationDegreesFormCopy);
    setEducationDegreesForm(educationDegreesFormCopy);
    if (formHasError) {
      setFocusFirstErrorField(true);
      setDisplayErrors(true);
      return;
    }
    saveTalent([
      talentId,
      PROFILE_SECTIONS.EDUCATION,
      {
        educationDegrees: getEducationPayload(educationDegreesFormCopy),
      },
    ]);
  }, [
    deleteAlert,
    educationDegreesForm,
    validateEducationForm,
    saveTalent,
    talentId,
    setFocusFirstErrorField,
  ]);

  useEffect(() => {
    if (!isSaveTalentLoading && isSaveTalentSuccess) {
      dispatch(updateEducation(getEducationPayload(educationDegreesForm)));
      onSave();
    }
    if (!isSaveTalentLoading && isSaveTalentError) {
      updateAlert(false);
    }
  }, [
    dispatch,
    educationDegreesForm,
    isSaveTalentError,
    isSaveTalentLoading,
    isSaveTalentSuccess,
    onSave,
    t,
    updateAlert,
  ]);

  return (
    <>
      {isSaveTalentLoading && <ProgressIndicatorLoader showLoading={true} />}
      <EditEducationSection
        degreeOptions={degreeOptions}
        gradStatusOptions={gradStatusOptions}
        educationDegreesForm={educationDegreesForm}
        mode={mode}
        displayErrors={displayErrors}
        handleValueChange={handleValueChange}
        onSchoolOrMajorChange={onSchoolOrMajorChange}
        onRemoveEducationDegree={onRemoveEducationDegree}
        onAddEducationDegree={onAddEducationDegree}
      />
      {educationDegreesForm.length > 0 && (
        <SaveCancelAction
          onCancel={onCancel}
          onSave={saveEducationSummary}
          cancelButtonId={educationDegreesIdGenerator.generateId(
            "cancelButton",
          )}
          saveButtonId={educationDegreesIdGenerator.generateId("saveButton")}
          scrollToTopSectionId={educationDegreesIdGenerator.generateId()}
        />
      )}
    </>
  );
};
