import React, { useCallback } from "react";
import { MappedRetailRoles } from "../../../shared/types/refData";
import {
  FILTER_TYPE,
  SET_FILTER,
  UNSET_FILTER,
} from "../../contexts/actionTypes";
import { FilterValue, SEARCH_FILTER_TYPE } from "../../contexts/SearchContext";
import useIsMobile from "../../hooks/useIsMobile";
import { useSearchContext } from "../../hooks/useSearchContext";
import { FilterOption } from "./FilterCheckboxGroup";
import { InternalStoreRolesFilter } from "./InternalStoreRolesFilter";

export type MappedRetailRolesOptions = FilterOption<MappedRetailRoles>;

interface InternalStoreRolesFilterWrapperProps {
  rolesData: Array<MappedRetailRolesOptions>;
  retailRolesFilterData: Array<MappedRetailRoles>;
  onMobileFilterChange?: (
    filterType: SEARCH_FILTER_TYPE,
    filterValue: FilterValue,
  ) => void;
}
export function InternalStoreRolesFilterWrapper({
  rolesData,
  retailRolesFilterData,
  onMobileFilterChange,
}: InternalStoreRolesFilterWrapperProps) {
  const { dispatch } = useSearchContext();
  const isMobile = useIsMobile();

  const modifiedRolesData = rolesData.map((role) => {
    if (retailRolesFilterData.find((obj) => obj.id === role.id)) {
      return { ...role, selected: true };
    }
    return role;
  });

  const updateMobileFilters = useCallback(
    (filterType: SEARCH_FILTER_TYPE, filterValue: FilterValue) => {
      if (onMobileFilterChange) onMobileFilterChange(filterType, filterValue);
    },
    [onMobileFilterChange],
  );

  const selectedCount = modifiedRolesData.filter(
    (obj: MappedRetailRolesOptions) => obj.selected,
  ).length;

  // dispatch a filter action in case of desktop / invoke mobileFilterChange callback incase of mobile
  const onUpdateProducts = useCallback(
    (roles: Array<any>) => {
      if (!isMobile)
        dispatch({
          type: SET_FILTER,
          filterName: FILTER_TYPE.RETAIL_ROLES,
          payload: roles,
        });
      else
        updateMobileFilters(
          FILTER_TYPE.RETAIL_ROLES as SEARCH_FILTER_TYPE,
          roles,
        );
    },
    [dispatch, isMobile, updateMobileFilters],
  );

  // dispatch a filter action in case of desktop / invoke mobileFilterChange callback incase of mobile
  const onRemoveProducts = useCallback(() => {
    if (!isMobile)
      dispatch({
        type: UNSET_FILTER,
        filterName: FILTER_TYPE.RETAIL_ROLES,
      });
    else
      updateMobileFilters(FILTER_TYPE.RETAIL_ROLES as SEARCH_FILTER_TYPE, []);
  }, [dispatch, isMobile, updateMobileFilters]);

  return (
    <InternalStoreRolesFilter
      rolesData={modifiedRolesData}
      onUpdateRoles={onUpdateProducts}
      onRemoveRoles={onRemoveProducts}
      isAccordionOpen={retailRolesFilterData.length > 0}
      selectedCount={selectedCount}
    />
  );
}
