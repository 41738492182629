import { isEmpty } from "lodash";
import React from "react";
import { Info } from "../../../components/base/Info";
import { Label } from "../../../components/base/Label";
import useIntlMessage from "../../../hooks/useIntlMessage";

export function LabelInfoView(props: {
  i18nLabel: string;
  value: string | null | undefined | string[];
  i18nDefaultValue?: string; // shown when value is null
}) {
  const { t } = useIntlMessage();
  const { i18nLabel, value, i18nDefaultValue } = props;
  return (
    <>
      <Label label={t(i18nLabel) as string} />
      {!Array.isArray(value) && (
        <Info
          value={
            (!isEmpty(value)
              ? value
              : i18nDefaultValue
                ? (t(i18nDefaultValue) as string)
                : "") as string
          }
        />
      )}
      {Array.isArray(value) &&
        value.map((v, idx) => (
          <Info
            key={`info-${idx}`}
            value={
              (!isEmpty(v)
                ? v
                : i18nDefaultValue
                  ? (t(i18nDefaultValue) as string)
                  : "") as string
            }
          />
        ))}
    </>
  );
}
