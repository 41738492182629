import httpStatus from "@rpe-js/core/dist/config/httpStatusCodes";
import { Button } from "@rpe-js/marcom-web-components";
import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate, useRouteError } from "react-router";
import AppContext from "../../AppContext";
import useIntlMessage from "../../hooks/useIntlMessage";
import { RouteID } from "../../routes";
import { idGenerator } from "../../utils/idGenerator";
import APP_CONSTANTS from "../../../utilities/appConstants";
import useDtm from "../../hooks/useAdobeAnalytics";

export function PageNotFound() {
  const { t } = useIntlMessage();
  const errorData: Error | any = useRouteError();
  const navigate = useNavigate();
  const { appUIState } = useContext(AppContext);
  const { locale } = appUIState.appData;
  const { trackPageLoad } = useDtm();
  const pageLocation = useLocation();

  useEffect(() => {
    const pageInfo = {
      pageName: APP_CONSTANTS.ANALYTICS.PAGE_NAME.NO_JOBS,
      locale: appUIState?.appData?.locale || APP_CONSTANTS.DEFAULT_LOCALE,
      title: APP_CONSTANTS.ANALYTICS.PAGE_NAME.JOB_DETAILS,
      isInternal: appUIState?.appData?.isInternal,
    };
    trackPageLoad(pageInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function noRoleFound() {
    if (errorData.status === httpStatus.NOT_FOUND) {
      if (typeof window !== "undefined") {
        document.title = t("jobsite.jobdetails.noDetailsFound") as string;
      }
      return (
        <>
          <h1 className={"t-eyebrow-elevated"}>
            {t("jobsite.error.noPageFound") as string}
          </h1>
          <div className={"mt-50 mb-50"}>
            <p className={"t-intro"}>
              {t("jobsite.error.noRoleFound") as string}
            </p>
          </div>
        </>
      );
    }
    return (
      <div className={"mt-50 mb-50"}>
        <p className="t-intro">{t("jobsite.general.serviceError")}</p>
      </div>
    );
  }

  function handleRedirectToOpenPositions() {
    if (pageLocation?.state?.search) {
      navigate(`/${locale}/${RouteID.search}?${pageLocation?.state?.search}`);
    } else {
      navigate(`/${locale}/${RouteID.search}`);
    }
  }

  return (
    <div className={"text-center page-not-found-wrapper"}>
      {noRoleFound()}
      <Button
        id={idGenerator("jobdetails", "open-positions").generateId("button")}
        size="medium"
        label={t("jobsite.search.openPositions") as string}
        onClick={handleRedirectToOpenPositions}
      ></Button>
    </div>
  );
}
