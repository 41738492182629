import PropTypes from "prop-types";
import React, { useContext } from "react";
import { LocationPickerContext } from "../../contexts/LocationPickerContext";
import useIntlMessage from "../../hooks/useIntlMessage";

export default function LocationPickerTypeahead(props: any) {
  const {
    typeAheadResults,
    setPopoverOpen,
    setLocationInput,
    highlightedPopoverIndex,
    highlightedDispatch,
    locationInputRef,
  } = props;
  const context = useContext(LocationPickerContext);
  if (!context) {
    throw new Error(
      "LocationPickerHeader must be used within a LocationPickerProvider",
    );
  }
  const { t } = useIntlMessage();
  const { setPostLocation, setSearch } = context;

  function selectedLocation(location: any) {
    setPostLocation(location.id);
    setLocationInput(location?.titleName);
    locationInputRef.current.value = location.titleName;
    setSearch(location.titleName);
    setPopoverOpen(false);
  }

  function onMouseEnter(index: any) {
    highlightedDispatch({ type: "set", payload: index });
  }

  return (
    <div className="popover" tabIndex={-1}>
      <aside>
        <span role="status" aria-live="polite" className="a11y">
          {t("jobsite.search.noSuggestionsUpDown", {
            number: typeAheadResults.length,
          })}
        </span>
        <h2 className="suggestions">
          {t("jobsite.search.suggestions") as string}
        </h2>

        <ul role="listbox" id={"listbox-container"}>
          {typeAheadResults.map(
            (
              location: {
                id: React.Key | null | undefined;
                displayName:
                  | string
                  | number
                  | boolean
                  | React.ReactElement<
                      any,
                      string | React.JSXElementConstructor<any>
                    >
                  | Iterable<React.ReactNode>
                  | React.ReactPortal
                  | null
                  | undefined;
              },
              index: any,
            ) => (
              <li
                key={location.id}
                onMouseEnter={() => {
                  onMouseEnter(index);
                }}
                aria-selected={highlightedPopoverIndex === index}
                id={`suggestion-${index}-button`}
                className={`popover-row ${highlightedPopoverIndex === index ? "highlighted" : ""}`}
                role="option"
                onClick={() => selectedLocation(location)}
              >
                <span>{location.displayName}</span>
              </li>
            ),
          )}
        </ul>
      </aside>
    </div>
  );
}

LocationPickerTypeahead.propTypes = {
  typeAheadResults: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      titleName: PropTypes.string.isRequired,
    }),
  ),
  setPopoverOpen: PropTypes.func,
  setLocationInput: PropTypes.func,
  highlightedPopoverIndex: PropTypes.number,
  highlightedDispatch: PropTypes.func,
  locationInputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};
