import { random } from "lodash";
import { ProfileEmployment } from "../context/ProfileState";

export interface FormField {
  value: string;
  requiredError?: boolean;
  invalidErrorMessage?: string;
}

export type EmploymentForm = {
  employerID: FormField;
  employerName: FormField;
  startMonthID: FormField;
  startMonthName: FormField;
  startYear: FormField;
  endMonthID: FormField;
  endMonthName: FormField;
  endYear: FormField;
  jobTitle: FormField;
  currentEmployer: FormField;
  empAdd: boolean;
  workdayData?: boolean;
  addedSource?: string;
  key: number;
};

export const getEmploymentForm = (employment?: ProfileEmployment) => {
  return {
    employerID: {
      value: employment?.employerID || "",
      requiredError: false,
    },
    employerName: {
      value: employment?.employer || employment?.employerName || "",
    },
    startMonthID: {
      value: employment?.startMonthID || "",
      requiredError: false,
    },
    startMonthName: {
      value: employment?.startMonthName || "",
    },
    startYear: {
      value: employment?.startYear || "",
      requiredError: false,
    },
    endMonthID: {
      value: employment?.endMonthID || "",
    },
    endMonthName: {
      value: employment?.endMonthName || "",
    },
    endYear: {
      value: employment?.endYear || "",
    },
    jobTitle: {
      value: employment?.jobTitle || "",
      requiredError: false,
    },
    currentEmployer: {
      value: employment?.currentEmployer === true ? "Yes" : "No",
      requiredError: false,
    },
    addedSource: employment?.addedSource,
    workdayData: employment?.workdayData,
    empAdd: employment ? Boolean(employment?.empAdd) : true,
    key: random(10000),
  };
};

export const getEmploymentsPayload = (
  employmentsForm: EmploymentForm[],
  includeEmpAdd?: boolean,
): ProfileEmployment[] => {
  const payload = employmentsForm?.map((employment: EmploymentForm) => {
    const emp: ProfileEmployment = {
      currentEmployer:
        employment.currentEmployer.value === "Yes" ? true : false,
      employer: employment.employerName.value,
      employerID: employment.employerID.value,
      jobTitle: employment.jobTitle.value,
      startMonthID: employment.startMonthID.value,
      startMonthName: employment.startMonthName.value,
      startYear: employment.startYear.value,
      endMonthID: employment.endMonthID.value || undefined,
      endMonthName: employment.endMonthName.value || undefined,
      endYear: employment.endYear.value || undefined,
      addedSource: employment.addedSource,
      workdayData: employment.workdayData,
    };
    if (includeEmpAdd) {
      emp.empAdd = employment.empAdd;
    }
    return emp;
  });
  return payload?.length ? payload : [];
};

export const validateDate = (
  startMonth: string,
  startYear: string,
  endMonth: string,
  endYear: string,
  fieldToValidate: "startDate" | "endDate",
  mode?: string,
) => {
  const startDate = new Date();
  const endDate = new Date();
  startDate.setMonth(Number(startMonth) - 1);
  startDate.setFullYear(Number(startYear));
  endDate.setMonth(Number(endMonth) - 1);
  endDate.setFullYear(Number(endYear));
  if (startMonth && startYear && endMonth && endYear && endDate < startDate) {
    if (fieldToValidate === "startDate") {
      return mode === "yourApplication"
        ? "jobsite.profile.myApplication.startyear"
        : "jobsite.common.startyear";
    } else
      return mode === "yourApplication"
        ? "jobsite.profile.myApplication.endyear"
        : "jobsite.common.endyear";
  }
  if (
    startMonth &&
    startYear &&
    fieldToValidate === "startDate" &&
    startDate > new Date()
  ) {
    return "jobsite.common.futureStartDateError";
  }
  if (
    endMonth &&
    endYear &&
    fieldToValidate === "endDate" &&
    endDate > new Date()
  ) {
    return "jobsite.common.futureEndDateError";
  }
  return "";
};

export const validateDateRange = (
  employmentsFormCopy: EmploymentForm[],
  index: number,
  field: "startDate" | "endDate",
) => {
  return validateDate(
    employmentsFormCopy[index].startMonthID.value,
    employmentsFormCopy[index].startYear.value,
    employmentsFormCopy[index].endMonthID.value,
    employmentsFormCopy[index].endYear.value,
    field,
  );
};
