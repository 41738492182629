import {
  Address,
  Contact,
  Disability,
  Education,
  PrivacyPolicy,
  SelfDisclosure,
} from "./talent";

export interface MyApplication {
  id: string;
  talentId: string;
  employeeBackGround: EmployeeBackGround;
  selfDisclosure: SelfDisclosure;
  reset: boolean;
  contact: Contact;
  addresses: Address[];
  disability: Disability;
  references: TalentReference[];
  employmentHasAtLeastOneJob: boolean;
  employments: AppEmploymentDetails[];
  educationDegrees: AppEducationDetails[];
  legal: Legal;
  status: string;
  informationRelease: InformationRelease;
  resetOn: Date;
  version: number;
  privacyPolicy: PrivacyPolicy;
  templateVersion: number;
}

export interface EmployeeBackGround {
  above18: boolean;
  employedAtApple: boolean;
  contractorAtApple: boolean;
}

export interface TalentReference {
  name: string;
  relationship: string;
  company: string;
  jobTitle: string;
  phoneNumber: string;
}

export interface AppEmploymentDetails {
  countryID: string; //iso-country-USA
  country: string; //United States of America
  state?: string;
  city?: string;
  verificationPhone?: string;
  reasonToLeave: string;
  currentEmployer: boolean;
  employer: string;
  employerName?: string;
  employerID?: string;
  endMonthID?: string; //"02"
  endMonthName?: string; //"February"
  endYear?: string; //"2024"
  jobTitle: string;
  startMonthID: string;
  startMonthName: string;
  startYear: string;
}

export interface AppEducationDetails extends Education {
  countryID?: string;
  state?: string;
  country?: string;
}

export interface Legal {
  rightToWork?: boolean;
  felonyConviction?: boolean;
  felonyConvictionInfo?: string;
  felonyConvictionComments?: string;
}

export interface InformationRelease {
  time: Date;
  acknowledge: boolean;
}

export interface MyAppSelfDisclosurePayload
  extends Pick<SelfDisclosure, "ethnicityID" | "genderID" | "veteranStatus"> {
  gender: string;
  ethnicity: string;
}

export interface MyApplicationCreatePayload {
  disability: Pick<Disability, "statusID" | "statusName" | "name"> & {
    completedOn: string;
  };
  selfDisclosure: MyAppSelfDisclosurePayload;
}

export enum AllowedMyApplicationUpdateAction {
  "selfdisclosure" = "selfdisclosure",
  "contact" = "contact",
  "employeebackground" = "employeebackground",
  "education" = "education",
  "experience" = "experience",
  "references" = "references",
  "legal" = "legal",
  "informationRelease" = "informationRelease",
  "submit" = "submit",
}
