import { ModifiedSearchResult } from "@rpe-js/core/dist/types/searchResponseTypes";
import {
  NativeButton,
  ProgressIndicatorLoader,
  SearchAccordionItem,
} from "@rpe-js/marcom-web-components";
import Accordion from "@rpe-js/marcom-web-components/lib/CustomAccordion/CustomAccordion";
import { find } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { expandResults, search as searchJobs } from "../../api/fetchClient";
import {
  defaultSort,
  SEARCH_LIST_MAX_LIMIT,
  sortMapping,
} from "../../app.constants";
import AppContext from "../../AppContext";
import { FILTER_TYPE, SET_FILTER, SET_SORT } from "../../contexts/actionTypes";
import { Filters } from "../../contexts/SearchContext";
import { useFetchData } from "../../hooks/useFetchData";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { useSearchContext } from "../../hooks/useSearchContext";
import { idGenerator } from "../../utils/idGenerator";
import JobContent from "./jobItem/JobContent";
import JobTitle from "./jobItem/JobTitle";
import SortByList from "./SortByList";

interface JoblistProps {
  allFiltersLength: number;
}

const JobList: React.FC<JoblistProps> = ({ allFiltersLength }) => {
  const search = "search";
  const { t } = useIntlMessage();
  const { state, dispatch } = useSearchContext();
  const { appUIState } = useContext(AppContext);
  const { countryCode } = appUIState.appData;
  const { searchResults, sort, totalRecords, dataLoading } = state;
  const { isLoading: isSearchLoading } = useFetchData(searchJobs);
  const isMobile = useIsMobile();
  const jobTitleId = idGenerator(search, "job-title").generateId();
  const jobContentId = idGenerator(search, "job-content").generateId();
  const JOB_LIST_WRAPPER_ID = idGenerator(search, "job-list").generateId();
  const noSearchResultsId = idGenerator(
    search,
    "no-search-results",
  ).generateId();
  const noSearchResultsWidenSearchId = idGenerator(
    search,
    "no-results-widen-search",
  ).generateId();
  const expandResultBtnId = idGenerator(search, "expand-result").generateId();
  const [selectedSort, setSelectedSort] = useState(sort || defaultSort);

  let allLocationCountryLevel = false;

  async function extendLocationFilters(filters: Filters) {
    const queryParams = filters?.locations;
    const locationFilters = [];
    queryParams?.forEach((item) => {
      locationFilters.push(item);
    });
    const locationIdArray = filters.locations?.map((location) => location.id);
    const expandedLocation = await expandResults(locationIdArray, countryCode);
    dispatch({
      type: SET_FILTER,
      filterName: FILTER_TYPE.LOCATIONS,
      payload: expandedLocation,
    });
  }

  function renderExpandNoResults(filters: Filters) {
    return (
      <div id={noSearchResultsWidenSearchId} className="text-center mt-70">
        <h3 className="t-quote-reduced">
          {t("jobsite.search.widenSearchHelpText")}
        </h3>
        <NativeButton
          id={expandResultBtnId}
          className="btn-expand-results t-intro"
          onClick={() => extendLocationFilters(filters)}
          label={t("jobsite.search.widenSearch") as string}
        ></NativeButton>
      </div>
    );
  }

  function renderNoResults() {
    return (
      <div id={noSearchResultsId} className="text-center">
        <h3 className="t-quote-reduced">
          {t("jobsite.search.noResultsMatch")}
        </h3>
        <p>{t("jobsite.search.returnedNoResults")}</p>
      </div>
    );
  }

  function renderRows() {
    if (find(state.filters?.locations, { level: 1 })) {
      allLocationCountryLevel = true;
    } else {
      allLocationCountryLevel = false;
    }
    if (
      !searchResults.length &&
      (allLocationCountryLevel || !state.filters?.locations?.length)
    ) {
      return renderNoResults();
    } else if (!searchResults.length && state.filters?.locations?.length) {
      return renderExpandNoResults(state.filters);
    }
    return (
      <>
        {(isSearchLoading || dataLoading) && (
          <ProgressIndicatorLoader showLoading={true} />
        )}
        {isMobile ? (
          searchResults.map((job: ModifiedSearchResult) => (
            <JobTitle
              key={job.positionId}
              id={`${jobTitleId}-${job.positionId}`}
              job={job}
            ></JobTitle>
          ))
        ) : (
          <Accordion
            role="list"
            compact={true}
            aria-label={t("jobsite.jobdetails.jobOpportunities") as string}
            id={JOB_LIST_WRAPPER_ID}
          >
            {searchResults.map((job: ModifiedSearchResult, index) => (
              <SearchAccordionItem
                titleWrapperTag={React.Fragment} // TODO: Need to check what should be the heading here as this is container of job posting title and other content like favorites icon, applied icon, date, store location etc
                role="listitem"
                key={`job-title-key-${job.positionId}`}
                jobTitle={
                  <JobTitle
                    id={`${jobTitleId}-${job.positionId}`}
                    job={job}
                  ></JobTitle>
                }
                iconAttrs={{
                  "aria-label": t("jobsite.search.roleDescriptionAriaLabel", {
                    jobTitle: job.postingTitle,
                  }) as string,
                  "aria-controls": `content-${JOB_LIST_WRAPPER_ID}-${index}`,
                }}
              >
                <JobContent
                  id={`${jobContentId}-${job.positionId}`}
                  job={job}
                ></JobContent>
              </SearchAccordionItem>
            ))}
          </Accordion>
        )}
      </>
    );
  }

  const confirmSortSearches = useCallback((item: string) => {
    setSelectedSort(item);
    dispatch({
      type: SET_SORT,
      payload: item,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedSort(sort || defaultSort);
  }, [sort]);

  return (
    // Add class pos-rel to add position relative to show the loading spinner on entire joblist
    <section
      id={idGenerator(search, "result-set").generateId()}
      className={`${!isMobile && allFiltersLength > 0 ? "mt-30" : ""} ${isSearchLoading || dataLoading ? "pos-rel" : ""}`}
    >
      <div role="status" aria-live="polite" className="a11y">
        {t("jobsite.search.pageUpdated", {
          recordCount: totalRecords > 0 ? totalRecords : 0,
        })}
      </div>
      <h2 className="a11y">{t("jobsite.common.searchResults")}</h2>
      {totalRecords > 0 && (
        <div
          className={`d-flex justify-content-spacebetween mb-15 ${isMobile ? "flex-column gap-20" : ""}`}
        >
          <div
            role={"status"}
            className={`${isMobile ? "u-border-bottom pb-10 t-body-reduced fw-medium" : "t-eyebrow-reduced"}`}
          >
            {t("jobsite.search.resultCount", {
              number:
                totalRecords > SEARCH_LIST_MAX_LIMIT
                  ? `${SEARCH_LIST_MAX_LIMIT}+`
                  : totalRecords,
            })}
          </div>
          <SortByList
            id={idGenerator(search, "sort-list-popover").generateId()}
            ariaLabel="labelSortList"
            ariaDescription="descSortList"
            sortMapping={sortMapping}
            selectedSort={selectedSort}
            onSort={(item) => confirmSortSearches(item)}
          ></SortByList>
        </div>
      )}
      {renderRows()}
    </section>
  );
};

export default JobList;
