/**
 ** This is a placeholder file with mock implementation
 **/
import React, { useEffect, useState } from "react";
import { useSearchContext } from "../../hooks/useSearchContext";
import { SET_PAGE } from "../../contexts/actionTypes";
import { Pagination } from "@rpe-js/marcom-web-components";
import useIdGenerator from "../../hooks/useIdGenerator";
import { getTotalPages } from "../../util";
import useIntlMessage from "../../hooks/useIntlMessage";

export interface PaginationControlsProps {
  currentPage?: number;
}

const PaginationControls: React.FC<PaginationControlsProps> = ({
  currentPage,
}: PaginationControlsProps) => {
  const { state, dispatch } = useSearchContext();
  const { t } = useIntlMessage();
  const [activePage, setActivePage] = useState(currentPage || 1);

  const totalPages = getTotalPages(state.totalRecords);
  const handlePageChange = (page: number) => {
    if (page !== activePage) {
      dispatch({
        type: SET_PAGE,
        payload: page,
      });
      setActivePage(page);
    }
  };
  //
  useEffect(() => {
    if (state.page !== activePage) {
      setActivePage(state.page);
    }
    // setFilters({
    //   page: state.page,
    // });
  }, [activePage, state.page]);

  return (
    <Pagination
      id={useIdGenerator("search", "pagnation")}
      totalPages={totalPages}
      initialPage={activePage}
      onPageChange={handlePageChange}
      delimiter={t("jobsite.common.of") as string}
      paginationStyle="pageinput"
      paginationAriaLabel={t("jobsite.common.resultPagination") as string}
      paginationLabel={t("jobsite.profile.yourRoles.pagenumber") as string}
      inputId={useIdGenerator("search", "page-number")}
      prevButtonLabel={t("jobsite.profile.yourRoles.prevpage") as string}
      nextButtonLabel={t("jobsite.profile.yourRoles.nextpage") as string}
    />
  );
};

export default PaginationControls;
