import { isEmpty } from "lodash";
import React from "react";
import { Address, Contact } from "../../../../../shared/types/talent/talent";
import { Info } from "../../../../components/base/Info";
import { Label } from "../../../../components/base/Label";
import { PreferredEmail } from "../../../../components/feature/emailVerification/preferredEmail";
import { useCurrentUserContext } from "../../../../CurrentUserContext";
import useIntlMessage from "../../../../hooks/useIntlMessage";
import { LabelInfoView } from "../../components/LabelInfoView";
import { useProfileContext } from "../../context/ProfileContext";
import { NameSection } from "../nameSection";
import { AddressView } from "./address/addressView";

interface ContactViewProps {
  isInternal: boolean;
  locale: string;
}

export function ContactView(props: ContactViewProps) {
  const { isInternal, locale } = props;
  const { state } = useProfileContext();
  const { config } = useCurrentUserContext();
  const {
    contact = {},
    emails,
    addresses = [],
    kanjiIndicator,
    localeNameIndicator,
    displayPronouns,
    genderPronoun,
  } = state;
  const { t } = useIntlMessage();
  return (
    <>
      <NameSection
        displayPronouns={displayPronouns}
        genderPronoun={genderPronoun}
        isEdit={false}
        isInternal={isInternal}
        locale={locale}
        contactInformation={contact as Contact}
        displayKanji={kanjiIndicator === true}
        displayLocal={localeNameIndicator === true}
        workDayLinks={config?.workday}
      />
      <div className="row">
        <PreferredEmail
          isEdit={false}
          preferredEmail={contact?.preferredEmail}
          verifiedStatus={emails?.preferredEmail?.verifiedStatus}
        />
        <div className="column large-6 small-12 d-flex flex-column mb-10">
          <LabelInfoView
            i18nLabel={"jobsite.common.preferredPhone"}
            value={
              !isEmpty(contact?.preferredPhoneExtension)
                ? `${t("jobsite.common.phoneNumberWithExtension", {
                    preferredPhone: contact?.preferredPhone,
                    preferredPhoneExtension: contact?.preferredPhoneExtension,
                  })}`
                : contact?.preferredPhone
            }
            i18nDefaultValue="jobsite.common.notSpecified"
          />
        </div>
      </div>
      {(contact?.secondaryEmail ||
        contact?.otherEmail ||
        contact?.secondaryPhone ||
        contact?.otherPhone) && (
        <div className="row mb-10">
          <div className="column large-6 small-12 d-flex flex-column">
            {(contact?.secondaryEmail || contact?.otherEmail) && (
              <>
                <Label label={t("jobsite.common.otherEmail") as string} />
                <Info value={contact?.secondaryEmail} />
                <Info value={contact?.otherEmail} />
              </>
            )}
          </div>
          {(contact?.secondaryPhone || contact?.otherPhone) && (
            <div className="column large-6 small-12 d-flex flex-column">
              <Label label={t("jobsite.common.otherPhone") as string} />
              <Info
                value={
                  !isEmpty(contact?.preferredPhoneExtension)
                    ? `${t("jobsite.common.phoneNumberWithExtension", {
                        preferredPhone: contact?.secondaryPhone,
                        preferredPhoneExtension:
                          contact?.preferredPhoneExtension,
                      })}`
                    : contact?.secondaryPhone
                }
              />
              <Info
                value={
                  !isEmpty(contact?.preferredPhoneExtension)
                    ? `${t("jobsite.common.phoneNumberWithExtension", {
                        preferredPhone: contact?.otherPhone,
                        preferredPhoneExtension:
                          contact?.preferredPhoneExtension,
                      })}`
                    : contact?.otherPhone
                }
              />
            </div>
          )}
        </div>
      )}
      <div>
        {addresses?.map((address: Address, index: number) => (
          <div
            key={index}
            className={`d-flex flex-column${index !== 0 ? " u-border-top mt-10 pt-10" : ""}`}
          >
            <AddressView address={address} />
          </div>
        ))}
        {!addresses ||
          (addresses.length === 0 && (
            <div className="column large-6 small-12 d-flex flex-column">
              <Label label={t("jobsite.common.preferredAddress") as string} />
              <Info value={t("jobsite.common.notSpecified") as string} />
            </div>
          ))}
      </div>
    </>
  );
}
