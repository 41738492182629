import React, {
  createContext,
  Dispatch,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { useRouteLoaderData } from "react-router";
import { search } from "../api/fetchClient";
import { RouteID } from "../routes";
import { HiringManagerInfo } from "../types/HiringManagerInfo";
import {
  CLEAR_FILTERS,
  FETCH_DATA,
  SET_DATA_LOADING,
  SET_FILTER,
  SET_FILTERS_MOBILE,
  SET_FILTERS_VISIBLE,
  SET_PAGE,
  SET_REF_DATA,
  SET_SAVE_SEARCH_OPTIONS,
  SET_SAVED_SEARCH_DETAILS,
  SET_SAVED_SEARCH_LIST,
  SET_SEARCH,
  SET_SEARCH_RESULTS,
  SET_SORT,
  SET_TALENT_ROLES,
  SET_TOTAL_RECORDS,
  UNSET_FILTER,
  UPDATE_FAVORITE_ROLES,
  UPDATE_UNFAVORITE_ROLES,
} from "./actionTypes";

import { ModifiedSearchResult } from "@rpe-js/core/dist/types";
import {
  getJobLevelsObject,
  mappedFiltersForService,
} from "../../server/utils/searchUtils/mappedFiltersForService";
import { SavedSearchType } from "../../shared/types";
import {
  MappedLanguages,
  MappedPostLocation,
  MappedProductsAndServices,
  MappedRetailRoles,
  MappedTeams,
  OrderedRefData,
} from "../../shared/types/refData";
import { Role } from "../../shared/types/role";
import { getRolesWithUpdatedFlags } from "../../shared/utils/roles";
import useIntlMessage from "../hooks/useIntlMessage";
import { FilterOption } from "../pages/search/FilterCheckboxGroup";

export interface Filters {
  locations?: Array<MappedPostLocation>;
  homeOffice?: boolean;
  teams?: Array<MappedTeams>;
  products?: Array<MappedProductsAndServices>;
  languages?: Array<MappedLanguages>;
  hiringManagers?: Array<HiringManagerInfo>;
  keywords?: Array<string>;
  jobLevel?: Array<FilterOption>;
  retailRoles?: Array<MappedRetailRoles>;
  minimumHours?: string;
  maximumHours?: string;
}

interface State {
  search: string;
  sort: string;
  filters: Filters;
  page: number;
  data: any;
  refData: Record<string, any>;
  searchResults: Array<ModifiedSearchResult>;
  totalRecords: number;
  savedSearchList: Array<SavedSearchType>;
  jobAgentEmailFrequency: OrderedRefData[];
  jobAgentEmailExperience: OrderedRefData[];
  currentSavedSearchData: SavedSearchType | null;
  talentRoles: Array<Role>;
  filterVisible: boolean;
  preventScrollReset?: boolean;
  dataLoading?: boolean;
}

interface Action {
  type: string;
  payload?: any;
  filterName?: string;
}

export type SEARCH_FILTER_TYPE =
  | "homeOffice"
  | "locations"
  | "teams"
  | "products"
  | "languages"
  | "keywords"
  | "hiringManagers"
  | "maximumHours"
  | "minimumHours"
  | "jobLevel"
  | "retailRoles";

export type FilterValue =
  | boolean
  | string
  | string[]
  | FilterOption[]
  | undefined;

const initialState: State = {
  search: "",
  sort: "newest",
  filters: {
    homeOffice: false,
    locations: [],
    teams: [],
    products: [],
    languages: [],
    keywords: [],
    hiringManagers: [],
    maximumHours: undefined,
    minimumHours: undefined,
    jobLevel: [],
    retailRoles: [],
  },
  page: 1,
  data: null,
  refData: {},
  searchResults: [],
  filterVisible: true,
  totalRecords: 0,
  savedSearchList: [],
  jobAgentEmailExperience: [],
  jobAgentEmailFrequency: [],
  currentSavedSearchData: null,
  talentRoles: [],
};
const resetFilters: Partial<State> = {
  filters: {},
  page: 1,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_TALENT_ROLES:
      return { ...state, talentRoles: action.payload };
    case UPDATE_FAVORITE_ROLES:
      if (
        state.talentRoles.some((role) => role.reqId === action.payload.reqId)
      ) {
        return state;
      }
      return {
        ...state,
        talentRoles: [action.payload, ...state.talentRoles],
      };

    case UPDATE_UNFAVORITE_ROLES:
      return {
        ...state,
        talentRoles: state.talentRoles?.filter(
          (talentrole) => talentrole.jobPositionID !== action.payload,
        ),
      };
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload as string,
        preventScrollReset: true,
      };
    case SET_SORT:
      return {
        ...state,
        sort: action.payload as string,
        preventScrollReset: true,
      };
    case SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.filterName as string]: action.payload,
        },
        page: 1,
        preventScrollReset: true,
      };
    case UNSET_FILTER:
      const filters = { ...state.filters };
      if (action.filterName)
        delete filters[action.filterName as SEARCH_FILTER_TYPE];
      return {
        ...state,
        filters: {
          ...filters,
        },
        page: 1,
        preventScrollReset: true,
      };
    case SET_PAGE:
      return { ...state, page: action.payload, preventScrollReset: false };
    case CLEAR_FILTERS:
      return {
        ...state,
        filters: resetFilters.filters as Filters,
        page: resetFilters.page as number,
        preventScrollReset: true,
      };
    case SET_FILTERS_MOBILE:
      return {
        ...state,
        filters: action.payload,
      };
    case FETCH_DATA:
      return {
        ...state,
        searchResults: action.payload.searchResults,
        totalRecords: action.payload.totalRecords,
      };
    case SET_REF_DATA:
      return { ...state, refData: action.payload };
    case SET_SEARCH_RESULTS:
      return { ...state, searchResults: action.payload, dataLoading: false };
    case SET_TOTAL_RECORDS:
      return { ...state, totalRecords: action.payload };
    case SET_SAVED_SEARCH_LIST:
      return { ...state, savedSearchList: action.payload };
    case SET_SAVE_SEARCH_OPTIONS:
      return { ...state, ...action.payload };
    case SET_SAVED_SEARCH_DETAILS:
      return {
        ...state,
        currentSavedSearchData: action.payload,
        ...(action.payload && {
          savedSearchList: [...state.savedSearchList, action.payload],
        }),
      };
    case SET_FILTERS_VISIBLE:
      return {
        ...state,
        filterVisible: action.payload,
      };

    case SET_DATA_LOADING:
      return {
        ...state,
        dataLoading: action.payload,
      };
    default:
      return state;
  }
};

export const SearchContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({ state: initialState, dispatch: () => null });

export const SearchProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const loaderData = useRouteLoaderData(RouteID.search) as any;
  const { t } = useIntlMessage();
  const rootLoaderData = useRouteLoaderData(RouteID.root) as any;
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    searchResults: loaderData?.searchResults,
    refData: loaderData.staticData.refData,
    totalRecords: loaderData?.totalRecords,
    filters: loaderData.filters && {
      ...loaderData?.filters,
      ...(loaderData.filters.jobLevel && {
        jobLevel: getJobLevelsObject(loaderData.filters.jobLevel, t),
      }),
    },
    search: loaderData.search,
    sort: loaderData.sort,
    page: loaderData.page,
    talentRoles: loaderData?.talentRoles || [],
  });
  const hasFetchedRef = useRef(false); // Prevent fetch on initial render
  const fetchData = useCallback(
    async () => {
      const filters = mappedFiltersForService(state.filters);
      dispatch({ type: SET_DATA_LOADING, payload: true });
      const response = await search(
        rootLoaderData.locale,
        filters,
        state.page,
        state.sort,
        state.search,
      );
      const data = await response;
      if (data) {
        let searchResultResponse = data.searchResults;
        if (state.talentRoles.length) {
          searchResultResponse = getRolesWithUpdatedFlags(
            searchResultResponse,
            state.talentRoles,
          );
        }
        dispatch({ type: SET_SEARCH_RESULTS, payload: searchResultResponse });
        dispatch({ type: SET_TOTAL_RECORDS, payload: data.totalRecords });
        if (state.currentSavedSearchData) {
          dispatch({
            type: SET_SAVED_SEARCH_DETAILS,
            payload: null,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      state.search,
      state.sort,
      state.filters,
      state.page,
      rootLoaderData.locale,
    ],
  );
  useEffect(() => {
    if (!hasFetchedRef.current) {
      hasFetchedRef.current = true;
      return;
    }
    fetchData();
  }, [
    state.search,
    state.page,
    state.sort,
    state.filters,
    state.page,
    fetchData,
  ]);

  const contextValue = useMemo(() => ({ state, dispatch }), [state]);
  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  );
};
