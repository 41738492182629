import { RouteObject } from "react-router";
import { APP_TYPE } from "../shared/types";
import AuthenticatedApp from "./AuthenticatedApp";
import { Layout } from "./layout/Layout";
import AppErrorBoundary from "./pages/AppErrorBoundary";
import { Apply } from "./pages/apply/Apply";
import { ERP } from "./pages/erp/Erp";
import JobDetails from "./pages/jobDetails/JobDetails";
import { PageNotFound } from "./pages/jobDetails/PageNotFound";
import LocationPicker from "./pages/locationPicker/LocationPicker";
import InternalUnAuthorized from "./pages/logout/InternalUnauthorized";
import LogoutPage from "./pages/logout/LogoutPage";
import SessionTimeoutPage from "./pages/logout/SessionTimeoutPage";
import PrivacyPolicyPage from "./pages/privacyPolicy";
import Profile from "./pages/profile";
import { YourApplication } from "./pages/profile/myApplication/Application";
import { ProfileInformation } from "./pages/profile/ProfileInformation";
import { SavedSearches } from "./pages/profile/savedSearches/SavedSearches";
import { YourRoles } from "./pages/profile/YourRoles/YourRoles";
import SearchPage from "./pages/search/SearchPage";
import { UserNotFound } from "./pages/userNotFound";
import { VerifyEmail } from "./pages/verifyemail/VerifyEmail";
import { WelcomeTalent } from "./pages/welcometalent/WelcomeTalent";
import RouteNotFound from "./RouteNotFound";

export enum RouteID {
  "root" = "root",
  "search" = "search",
  "jobDetails" = "jobDetails",
  "verifyemail" = "verifyemail",
  "welcometalent" = "welcometalent",
  "profile" = "profile",
  "profileinfo" = "profileinfo",
  "apply" = "apply",
  "yourRoles" = "yourRoles",
  "app" = "app",
  "logout" = "logout",
  "sessionTimeout" = "sessionTimeout",
  "userNotFound" = "userNotFound",
  "privacy" = "privacy",
  "savedSearch" = "savedSearch",
  "myApplication" = "myApplication",
  "internalUnauthorized" = "internalUnauthorized",
  "locationPicker" = "locationPicker",
  "erp" = "erp",
  "getdiscovered" = "getdiscovered",
}

export const findRouteById = (
  routeId: RouteID,
  routes: RouteObject[],
): RouteObject | null => {
  for (const route of routes) {
    // current route matches the routeId
    if (route.id === routeId) {
      return route;
    }

    // recursively search in children
    if (route.children) {
      const childRoute = findRouteById(routeId, route.children);
      if (childRoute) {
        return childRoute;
      }
    }
  }

  // no matching route is found
  return null;
};

export const getRoutes = (appType: APP_TYPE) => {
  const routes: RouteObject[] = [
    {
      id: RouteID.root,
      path: "/",
      Component: Layout,
      ErrorBoundary: AppErrorBoundary,
      children: [
        {
          id: RouteID.search,
          path: ":locale/search",
          Component: SearchPage,
          shouldRevalidate: () => {
            // do not revalidate on query params change
            return false;
          },
        },
        {
          id: RouteID.jobDetails,
          path: ":locale/details/:jobId/:title?",
          Component: JobDetails,
          ErrorBoundary: PageNotFound,
        },
        {
          path: ":locale/details/:jobId/:title?/locationPicker",
          id: RouteID.locationPicker,
          Component: LocationPicker,
          shouldRevalidate: () => {
            // do not revalidate on query params change
            return false;
          },
        },
        {
          id: RouteID.userNotFound,
          path: ":locale/user-not-found",
          Component: UserNotFound,
        },
        {
          id: RouteID.privacy,
          path: ":locale/privacy",
          Component: PrivacyPolicyPage,
        },
        {
          path: "app/:locale/verifyemail", // don't want to fetch current user and config for this route
          id: RouteID.verifyemail,
          Component: VerifyEmail,
        },
        {
          id: RouteID.app,
          path: "app/:locale",
          Component: AuthenticatedApp,
          children: [
            {
              path: "",
              Component: RouteNotFound,
            },
            {
              path: "welcometalent",
              id: RouteID.welcometalent,
              Component: WelcomeTalent,
            },
            {
              path: "profile",
              id: RouteID.profile,
              Component: Profile,
              children: [
                {
                  path: "",
                  Component: RouteNotFound,
                },
                {
                  path: "info",
                  id: RouteID.profileinfo,
                  Component: ProfileInformation,
                },
                {
                  path: "roles",
                  id: RouteID.yourRoles,
                  Component: YourRoles,
                },
                {
                  path: "savedsearch",
                  id: RouteID.savedSearch,
                  Component: SavedSearches,
                },
                {
                  path: "myapplication",
                  id: RouteID.myApplication,
                  Component: YourApplication,
                },
                {
                  path: "*",
                  Component: RouteNotFound,
                },
              ],
            },
            {
              path: "apply/:jobId",
              id: RouteID.apply,
              Component: Apply,
              shouldRevalidate: () => {
                // do not revalidate on query params change
                return false;
              },
            },
            {
              path: "erp",
              id: RouteID.erp,
              Component: ERP,
            },
          ],
        },
      ],
    },
  ];
  if (appType === "internal") {
    routes[0].children?.push({
      id: RouteID.logout,
      path: ":locale/logout-page",
      Component: LogoutPage,
    });
    routes[0].children?.push({
      id: RouteID.sessionTimeout,
      path: ":locale/session-timeout-page",
      Component: SessionTimeoutPage,
    });
    routes[0].children?.push({
      id: RouteID.internalUnauthorized,
      path: ":locale/internal-unauthorized",
      Component: InternalUnAuthorized,
    });
  }
  routes[0].children?.push({
    path: "*",
    Component: RouteNotFound,
  });
  return routes;
};
