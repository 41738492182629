import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  EmployeeBackGround,
  MyApplication,
} from "../../../../../../shared/types/talent/myApplication";
import { NO, YES } from "../../../../../app.constants";
import RadioGroup, {
  RadioOption,
} from "../../../../../components/feature/form/radioGroup";
import { useCurrentUserContext } from "../../../../../CurrentUserContext";
import useFocusFirstErrorField from "../../../../../hooks/useFocusFirstErrorField";
import useIntlMessage from "../../../../../hooks/useIntlMessage";
import { idGenerator } from "../../../../../utils/idGenerator";
import { useMyApplicationContext } from "../../context/context";
import { MyApplicationActionDispatcher } from "../../context/state";
import { ApplicationSection, SectionMode } from "../../context/types";
import { useUpdateApplication } from "../../hooks/createAndUpdateApplicationHooks";
import { SectionHeader } from "../SectionHeader";
import { SectionActions } from "./sectionActions";

interface ErrorState {
  over18: boolean;
  workedAtApple: boolean;
  employedAtApple: boolean;
}
interface SectionProps {
  id: string;
  onSave: (
    name: ApplicationSection,
    mode: SectionMode,
    application: MyApplication,
  ) => void;
  mode: SectionMode;
}

interface EmpBckProps extends SectionProps {}

function useEmpBckIdGenerator() {
  const over18YesId = idGenerator(
    "profileApplication",
    "empbck-over18-yes",
  ).generateId();
  const over18NoId = idGenerator(
    "profileApplication",
    "empbck-over18-no",
  ).generateId();
  const empAppleYesId = idGenerator(
    "profileApplication",
    "empbck-empapple-yes",
  ).generateId();
  const empAppleNoId = idGenerator(
    "profileApplication",
    "empbck-empapple-no",
  ).generateId();

  const wrkAppleYesId = idGenerator(
    "profileApplication",
    "empbck-wrkapple-yes",
  ).generateId();
  const wrkAppleNoId = idGenerator(
    "profileApplication",
    "empbck-wrkapple-no",
  ).generateId();

  return {
    over18YesId,
    over18NoId,
    empAppleYesId,
    empAppleNoId,
    wrkAppleNoId,
    wrkAppleYesId,
  };
}

export function EmployeeBackgroundSection(props: EmpBckProps) {
  const { id, mode, onSave } = props;
  const { state, dispatch } = useMyApplicationContext();
  const { t } = useIntlMessage();
  const { employmentSection, application } = state;
  const { currentUser } = useCurrentUserContext();
  const { title, employeeBackground } = employmentSection;
  const sectionName: ApplicationSection = "employmentBackground";
  const {
    //isUpdateApplicationError,
    isUpdateApplicationLoading,
    isUpdateApplicationSuccess,
    updateApplication,
    resetUpdateApplication,
    updatedApplication,
  } = useUpdateApplication();
  const { setFocusFirstErrorField } = useFocusFirstErrorField(
    `profileApplication-application-${sectionName}`,
  );
  const [over18, setOver18] = useState<boolean | null>(
    employmentSection.employeeBackground
      ? employmentSection.employeeBackground.above18
      : null,
  );
  const [workedAtApple, setWorkedAtApple] = useState<boolean | null>(
    employmentSection.employeeBackground
      ? employmentSection.employeeBackground.contractorAtApple
      : null,
  );

  const [employedAtApple, setEmployedAtApple] = useState<boolean | null>(
    employmentSection.employeeBackground
      ? employmentSection.employeeBackground.employedAtApple
      : null,
  );

  const [errors, setErrors] = useState<ErrorState>({
    over18: false,
    workedAtApple: false,
    employedAtApple: false,
  });

  const {
    over18YesId,
    over18NoId,
    empAppleYesId,
    empAppleNoId,
    wrkAppleNoId,
    wrkAppleYesId,
  } = useEmpBckIdGenerator();

  const radioOptions: {
    over: RadioOption[];
    workedAtApple: RadioOption[];
    empAppleYesId: RadioOption[];
  } = useMemo((): {
    over: RadioOption[];
    workedAtApple: RadioOption[];
    empAppleYesId: RadioOption[];
  } => {
    return {
      over: [
        {
          id: over18YesId,
          label: t("jobsite.profile.myApplication.yes") as string,
          value: YES,
        },
        {
          id: over18NoId,
          label: t("jobsite.profile.myApplication.no") as string,
          value: NO,
        },
      ],
      workedAtApple: [
        {
          id: wrkAppleYesId,
          label: t("jobsite.profile.myApplication.yes") as string,
          value: YES,
        },
        {
          id: wrkAppleNoId,
          label: t("jobsite.profile.myApplication.no") as string,
          value: NO,
        },
      ],
      empAppleYesId: [
        {
          id: empAppleYesId,
          label: t("jobsite.profile.myApplication.yes") as string,
          value: YES,
        },
        {
          id: empAppleNoId,
          label: t("jobsite.profile.myApplication.no") as string,
          value: NO,
        },
      ],
    };
  }, [
    over18YesId,
    over18NoId,
    empAppleNoId,
    empAppleYesId,
    wrkAppleYesId,
    wrkAppleNoId,
    t,
  ]);

  const clearErrors = useCallback(
    (type: string) => {
      setErrors({ ...errors, [type]: false });
    },
    [errors],
  );

  const validate = useCallback(() => {
    const errorState: ErrorState = {
      over18: false,
      workedAtApple: false,
      employedAtApple: false,
    };
    errorState.over18 = over18 === null ? true : false;
    errorState.employedAtApple = employedAtApple === null ? true : false;
    errorState.workedAtApple = workedAtApple === null ? true : false;
    setErrors(errorState);
    return (
      errorState.over18 ||
      errorState.workedAtApple ||
      errorState.employedAtApple
    );
  }, [over18, workedAtApple, employedAtApple]);

  useEffect(() => {
    if (
      !isUpdateApplicationLoading &&
      isUpdateApplicationSuccess &&
      updatedApplication
    ) {
      MyApplicationActionDispatcher.onAppSecEmpBckSave(
        dispatch,
        updatedApplication.employeeBackGround,
      );
      onSave(sectionName, mode, updatedApplication as MyApplication);
      resetUpdateApplication();
    }
  }, [
    dispatch,
    isUpdateApplicationLoading,
    isUpdateApplicationSuccess,
    mode,
    onSave,
    updatedApplication,
    resetUpdateApplication,
  ]);

  const onSectionSave = useCallback(async () => {
    const hasError = validate();
    if (!hasError && application && currentUser.talentId) {
      const employeeBackground: EmployeeBackGround = {
        above18: over18 !== null ? over18 : false,
        contractorAtApple: workedAtApple !== null ? workedAtApple : false,
        employedAtApple: employedAtApple !== null ? employedAtApple : false,
      };
      MyApplicationActionDispatcher.onAppSecEmpBckSave(
        dispatch,
        employeeBackground,
      );
      const requestBody = cloneDeep(application);
      requestBody.employeeBackGround = employeeBackground;
      await updateApplication(
        currentUser.talentId,
        application?.id,
        "employeebackground",
        requestBody as MyApplication,
      );
    } else {
      setFocusFirstErrorField(true);
    }
  }, [
    validate,
    application,
    currentUser.talentId,
    over18,
    workedAtApple,
    employedAtApple,
    dispatch,
    updateApplication,
    setFocusFirstErrorField,
  ]);

  const onCancel = useCallback(() => {
    MyApplicationActionDispatcher.onApplicationSectionCancel(
      dispatch,
      sectionName,
    );
    setOver18(
      employmentSection.employeeBackground
        ? employmentSection.employeeBackground.above18
        : null,
    );
    setWorkedAtApple(
      employmentSection.employeeBackground
        ? employmentSection.employeeBackground.contractorAtApple
        : null,
    );

    setEmployedAtApple(
      employmentSection.employeeBackground
        ? employmentSection.employeeBackground.employedAtApple
        : null,
    );
  }, [dispatch, employmentSection.employeeBackground]);

  const onSectionEdit = useCallback(() => {
    MyApplicationActionDispatcher.onApplicationSectionEdit(
      dispatch,
      sectionName,
    );
  }, [dispatch, sectionName]);

  const onChange = useCallback(
    (type: "over18" | "employedAtApple" | "workedAtApple", evt: any) => {
      clearErrors(type);
      if (evt.target.checked && evt.target.value !== null) {
        const value =
          evt.target.value === YES
            ? true
            : evt.target.value === NO
              ? false
              : null;
        if (type === "over18") {
          setOver18(value);
        }
        if (type === "employedAtApple") {
          setEmployedAtApple(value);
        }
        if (type === "workedAtApple") {
          setWorkedAtApple(value);
        }
      }
    },
    [clearErrors],
  );

  return (
    <SectionHeader id={id} title={title} mode={mode} onEdit={onSectionEdit}>
      {mode === "view" && (
        <div className="mt-10">
          <fieldset>
            <div className="mb-10">
              <p className="label-grey">
                {t("jobsite.profile.myApplication.over18")}
              </p>
              <p className="mt-0">{employeeBackground?.above18 ? YES : NO}</p>
            </div>
            <div className="mb-10">
              <p className="label-grey">
                {t("jobsite.profile.myApplication.employedApple")}
              </p>
              <p className="mt-0">
                {employeeBackground?.employedAtApple ? YES : NO}
              </p>
            </div>
            <div>
              <p className="label-grey">
                {t("jobsite.profile.myApplication.workedApple")}
              </p>
              <p className="mt-0">
                {employeeBackground?.contractorAtApple ? YES : NO}
              </p>
            </div>
          </fieldset>
        </div>
      )}
      {(mode === "edit" || mode === "create") && (
        <form>
          <fieldset>
            <RadioGroup
              required={true}
              name="over18"
              title={t("jobsite.profile.myApplication.over18") as string}
              onChange={(evt) => onChange("over18", evt)}
              options={radioOptions.over}
              selectedValue={over18 !== null ? (over18 ? YES : NO) : null}
              errorMessage={
                errors.over18
                  ? (t("jobsite.profile.myApplication.chooseOption") as string)
                  : undefined
              }
              errorA11y={t("jobsite.common.errorIconLabel") as string}
            />
          </fieldset>
          <fieldset>
            <RadioGroup
              required={true}
              name="employedApple"
              title={t("jobsite.profile.myApplication.employedApple") as string}
              onChange={(evt) => onChange("employedAtApple", evt)}
              options={radioOptions.empAppleYesId}
              selectedValue={
                employedAtApple !== null ? (employedAtApple ? YES : NO) : null
              }
              errorMessage={
                errors.employedAtApple
                  ? (t("jobsite.profile.myApplication.chooseOption") as string)
                  : undefined
              }
              errorA11y={t("jobsite.common.errorIconLabel") as string}
            />
          </fieldset>
          <fieldset>
            <RadioGroup
              required={true}
              name="workedApple"
              title={t("jobsite.profile.myApplication.workedApple") as string}
              onChange={(evt) => onChange("workedAtApple", evt)}
              options={radioOptions.workedAtApple}
              selectedValue={
                workedAtApple !== null ? (workedAtApple ? YES : NO) : null
              }
              errorMessage={
                errors.workedAtApple
                  ? (t("jobsite.profile.myApplication.chooseOption") as string)
                  : undefined
              }
              errorA11y={t("jobsite.common.errorIconLabel") as string}
            />
          </fieldset>
        </form>
      )}
      {mode !== "view" && (
        <SectionActions
          onCancel={onCancel}
          onSave={onSectionSave}
          mode={mode}
        />
      )}
    </SectionHeader>
  );
}
