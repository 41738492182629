import { ModifiedSearchResult } from "@rpe-js/core/dist/types";
import { Role } from "../types/role";
import { isEmpty } from "lodash";

export function isYes(val: string) {
  return !isEmpty(val) && val.toLowerCase() === "yes";
}

export function isNo(val: string) {
  return !isEmpty(val) && val.toLowerCase() === "no";
}

export function getRolesWithUpdatedFlags(
  searchResultResponse: ModifiedSearchResult[],
  talentRoles: Role[],
): ModifiedSearchResult[] {
  return searchResultResponse.map((role) => {
    const positionIndex = talentRoles.findIndex(
      (talentRole: Role) => talentRole.jobPositionID === role.reqId,
    );
    if (positionIndex >= 0) {
      role.isFavorited = isYes(talentRoles[positionIndex]?.favorited);
      role.submitted = isYes(talentRoles[positionIndex]?.submitted);
      role.resubmitted = isYes(talentRoles[positionIndex]?.resubmitted);
    }
    return role;
  });
}
