import { TalentFileMetaData } from "../../shared/types/talent/talentFileMetatdata";
import {
  parsedlinkedinSnapshotPromise,
  parsedResumeSnapshotPromise,
  uploadResume,
  uploadTextResumePromise,
} from "../api/fetchClient";
import {
  FILE_UPLOAD,
  MAX_TEXT_RESUME_COUNT,
  MIN_TEXT_RESUME_COUNT,
  PRIMARY_TYPE,
  RESUME_ACTIONS,
} from "../app.constants";
import { profileUpdateMode } from "../pages/apply/context/ApplyState";
import { FileData } from "../types/File";

export function validateFile(fileData: FileData) {
  const file = fileData;
  const fileType = file.name.split(".").pop() || "";

  if (!FILE_UPLOAD.fileType.includes(fileType)) {
    return {
      error: true,
      errorMsg: "fileTypeError",
    };
  }

  if (file.size && FILE_UPLOAD.fileSize < file.size) {
    return {
      error: true,
      errorMsg: "fileSizeError",
    };
  }

  return { error: false, errorMsg: "" };
}

export function validateResumeText(value: string) {
  return (
    value &&
    value.length >= MIN_TEXT_RESUME_COUNT &&
    value.length <= MAX_TEXT_RESUME_COUNT
  );
}

export function getTalentResume(fileList: Array<TalentFileMetaData>) {
  return fileList.filter(
    (fileObj: TalentFileMetaData) => fileObj.typeId == PRIMARY_TYPE,
  );
}

export function getTalentSupportingFiles(fileList: Array<TalentFileMetaData>) {
  return fileList.filter(
    (fileObj: TalentFileMetaData) => fileObj.typeId != PRIMARY_TYPE,
  );
}

export function resumeMapper(fileMeta: TalentFileMetaData) {
  return {
    name: fileMeta.name as string,
    createdTime: fileMeta.createdTime as string,
    fileId: fileMeta.fileId,
    bucketId: fileMeta.bucketId as string,
    size: fileMeta.size,
    type: fileMeta.type,
    typeId: fileMeta.typeId,
  };
}

export function supportFileMapper(fileMeta: TalentFileMetaData) {
  return {
    category: fileMeta.typeId as string,
    categoryName: fileMeta.type as string,
    fileData: {
      name: fileMeta.name as string,
      createdTime: fileMeta.createdTime as string,
      fileId: fileMeta.fileId,
      bucketId: fileMeta.bucketId as string,
      size: fileMeta.size,
      type: fileMeta.type,
      typeId: fileMeta.typeId,
    },
  };
}

export const uploadResumePromises = (
  talentId: string,
  resume: FileData | null,
  textResume: FileData | null,
  profileUpdateSelection: string | undefined,
  linkedinLastAction: string | undefined,
  resumeLastAction: string | undefined,
) => {
  const filePromises: Array<() => Promise<any>> = [];
  if (profileUpdateSelection == profileUpdateMode.RESUME) {
    if (linkedinLastAction == RESUME_ACTIONS.REVIEW_LATER) {
      filePromises.push(
        parsedlinkedinSnapshotPromise(talentId, RESUME_ACTIONS.DISCARD),
      );
    }
    if (resume && !resume.fileId && resume.content) {
      filePromises.push(uploadResume(talentId, resume, true));
    } else if (textResume && textResume.content) {
      filePromises.push(
        uploadTextResumePromise(talentId, textResume.content, true),
      );
    }
  } else if (profileUpdateSelection == profileUpdateMode.LINKEDIN) {
    if (resumeLastAction == RESUME_ACTIONS.REVIEW_LATER) {
      filePromises.push(
        parsedResumeSnapshotPromise(talentId, RESUME_ACTIONS.DISCARD),
      );
    }

    if (resume && !resume.fileId && resume.content) {
      filePromises.push(uploadResume(talentId, resume, false));
    } else if (textResume && textResume.content) {
      filePromises.push(
        uploadTextResumePromise(talentId, textResume.content, false),
      );
    }
  } else {
    if (linkedinLastAction == RESUME_ACTIONS.REVIEW_LATER) {
      filePromises.push(
        parsedlinkedinSnapshotPromise(talentId, RESUME_ACTIONS.DISCARD),
      );
    }
    if (resumeLastAction == RESUME_ACTIONS.REVIEW_LATER) {
      filePromises.push(
        parsedResumeSnapshotPromise(talentId, RESUME_ACTIONS.DISCARD),
      );
    }

    if (resume && !resume.fileId && resume.content) {
      filePromises.push(uploadResume(talentId, resume, false));
    } else if (textResume && textResume.content) {
      filePromises.push(
        uploadTextResumePromise(talentId, textResume.content, false),
      );
    }
  }
  return filePromises;
};
