import React from "react";
import { TOTAL_TIME } from "../../../app.constants";
import { IntlMessageType } from "../../../types";
import HtmlRenderer from "../../base/HtmlRenderer";
import CountDownTimer from "./CountDownTimer";

interface SessionInactivityProps {
  title?: IntlMessageType;
  titleId?: string;
  sessionInactivityMessage: string;
  linkedInMessage: IntlMessageType;
  handleTimeout: () => void;
}
export default function SessionInactivity({
  title,
  titleId = "",
  sessionInactivityMessage,
  linkedInMessage,
  handleTimeout,
}: SessionInactivityProps) {
  return (
    <div className={"text-center p-50"}>
      {title && (
        <h2 id={titleId} className={"t-headline-reduced fw-regular"}>
          {title}
        </h2>
      )}
      <div className={"pb-20 u-border-bottom"}>
        <CountDownTimer
          totalTime={TOTAL_TIME}
          onTimeout={handleTimeout}
          sessionInactivityMessage={sessionInactivityMessage}
        />
      </div>
      <HtmlRenderer
        initialContent={linkedInMessage as string}
        classes="pt-20 hyperlink-underline"
      />
    </div>
  );
}
