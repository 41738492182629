import {
  Button,
  NativeButton,
  Overlay,
  Popover,
  PopoverAriaRequiredProps,
  ProgressIndicatorLoader,
} from "@rpe-js/marcom-web-components";
import React, { useEffect, useState } from "react";
import { FILTER_LIST_VIEW_MAX_RESULTS } from "../../app.constants";
import HtmlRenderer from "../../components/base/HtmlRenderer";
import Icon from "../../components/base/IconComponent";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { idGenerator } from "../../utils/idGenerator";

export interface FilterItem {
  id: string;
  name: string;
  url: string;
}

export type FiltersListPopoverProps = PopoverAriaRequiredProps & {
  id?: string;
  filterList?: Array<FilterItem>;
  isRemoveOptionAvail?: boolean;
  isLoading?: boolean;
  isDeleteSuccess?: boolean;
  showManageIcon?: boolean;
  showCount?: boolean;
  manageLink?: string;
  manageLabel: string;
  showNoDataLabel: boolean;
  noDataLabel?: string;
  noDataSubLabel?: string;
  deleteLabel?: string;
  triggerElementId: string;
  listAriaLabel?: string;
  onClose: () => void;
  onConfirmDelete?: (id: string) => void;
};

function FiltersListPopover({
  id,
  filterList = [],
  isLoading,
  isRemoveOptionAvail,
  isDeleteSuccess,
  showManageIcon,
  showCount,
  manageLink,
  manageLabel,
  showNoDataLabel,
  noDataLabel,
  noDataSubLabel,
  deleteLabel,
  triggerElementId,
  listAriaLabel,
  onClose,
  onConfirmDelete,
  ...rest
}: FiltersListPopoverProps) {
  const { t } = useIntlMessage();

  const [isFilterRemove, setIsFilterRemove] = useState(false);
  const [itemToRemove, setItemToRemove] = useState<FilterItem | null>(null);

  const isMobile = useIsMobile();
  const [isMobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const toggleDeletePrompt = async (item?: FilterItem) => {
    if (item) {
      setItemToRemove(item);
      setIsFilterRemove(true);
    } else {
      setItemToRemove(null);
      setIsFilterRemove(false);
    }
  };

  const confirmDeleteItemFromList = async () => {
    if (onConfirmDelete) onConfirmDelete(itemToRemove?.id as string);
  };

  const getHeading = (
    <>
      {showManageIcon && (
        <Icon
          classes="mr-5"
          name="cog-grey"
          size="xsmall"
          cursor="pointer"
        ></Icon>
      )}
      <span id={rest["aria-labelledby"]}>
        {t(manageLabel)} {showCount && `(${filterList?.length || 0})`}
      </span>
    </>
  );

  useEffect(() => {
    if (!isLoading) {
      toggleDeletePrompt();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isDeleteSuccess]);

  useEffect(() => {
    if (isMobile) {
      setMobileFiltersOpen(true);
    } else {
      setMobileFiltersOpen(false);
    }
  }, [isMobile]);

  const renderPopoverContent = () => (
    <div className="filter-list">
      {isLoading && <ProgressIndicatorLoader showLoading={true} />}
      {!isLoading && showNoDataLabel && (
        <div className="text-center my-15">
          <h2 id={rest["aria-labelledby"]} className="t-body fw-semibold">
            {t(noDataLabel as string)}
          </h2>
          <p className="no-results" id={rest["aria-describedby"]}>
            {t(noDataSubLabel as string)}
          </p>
        </div>
      )}

      {isRemoveOptionAvail &&
        !showNoDataLabel &&
        !isLoading &&
        isFilterRemove && (
          <div className="text-center my-10">
            <HtmlRenderer
              initialContent={
                t(deleteLabel as string, {
                  savedsearch: `<b>${itemToRemove?.name}</b>`,
                }) as string
              }
              htmlTag="p"
            />
            <div className="d-flex justify-content-center w-100 mt-10">
              <div className={"ml-10 d-inline-block"}>
                <Button
                  id={idGenerator("search", "filter-popover").generateId(
                    "cancel-button",
                  )}
                  size="base"
                  label={t("jobsite.common.cancel") as string}
                  color="secondary"
                  blockedVariant={true}
                  onClick={() => toggleDeletePrompt()}
                ></Button>
              </div>
              <div className={"ml-20 d-inline-block"}>
                <Button
                  id={idGenerator("search", "filter-popover").generateId(
                    "delete-button",
                  )}
                  size="base"
                  label={t("jobsite.common.delete") as string}
                  blockedVariant={true}
                  onClick={confirmDeleteItemFromList}
                ></Button>
              </div>
            </div>
          </div>
        )}

      {!showNoDataLabel && !isLoading && !isFilterRemove && (
        <>
          {manageLink ? (
            <a
              id={`${id}-manage-link`}
              href={manageLink}
              className="manage-list"
              aria-expanded="false"
            >
              {getHeading}
            </a>
          ) : (
            <p
              id={`${id}-manage-link`}
              className="manage-list"
              aria-expanded="false"
            >
              {getHeading}
            </p>
          )}

          <ul role="list" className="list-nobullet" aria-label={listAriaLabel}>
            {filterList?.slice(0, FILTER_LIST_VIEW_MAX_RESULTS)?.map(
              (item, index: number) =>
                index < 10 && (
                  <li
                    key={index}
                    role="listitem"
                    className="u-border-top d-flex justify-between py-15"
                  >
                    <a
                      id={`${id}-item-${index}`}
                      href={item.url}
                      aria-expanded="false"
                    >
                      {item.name}
                    </a>
                    {isRemoveOptionAvail && (
                      <NativeButton
                        id={`${id}-remove-button`}
                        onClick={() => toggleDeletePrompt(item)}
                        aria-label={`${t("jobsite.common.delete")} ${item.name}`}
                      >
                        <Icon
                          classes="mr-5"
                          name="x-circle"
                          size="xsmall"
                          cursor="pointer"
                        ></Icon>
                      </NativeButton>
                    )}
                  </li>
                ),
            )}
          </ul>
        </>
      )}
    </div>
  );

  return (
    <>
      {isMobile ? (
        <Overlay
          id={`${id}-overlay`}
          elementIdToFocus={triggerElementId}
          visible={isMobileFiltersOpen}
          onClose={onClose}
          isFullscreen={true}
          noCloseButton={false}
          disableEsc={false}
          classes={{ content: "px-15 mobile-manage-list" }}
          stickyClose={true}
          closeButtonAttrs={{
            ariaLabel: t("jobsite.common.cancel") as string,
            stickyClose: true,
            alignStart: true,
          }}
        >
          {renderPopoverContent()}
        </Overlay>
      ) : (
        <Popover
          id={id}
          triggerElementId={triggerElementId}
          aria-describedby={rest["aria-describedby"] as string}
          aria-labelledby={rest["aria-labelledby"] as string}
          onClose={onClose}
        >
          {renderPopoverContent()}
        </Popover>
      )}
    </>
  );
}

export default FiltersListPopover;
