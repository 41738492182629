import React, { useContext } from "react";
import { useLocation, useRouteLoaderData } from "react-router";
import { LocationPickerProvider } from "../../contexts/LocationPickerContext";
import { RouteID } from "../../routes";
import { AppData } from "../../types";
import LocationPickerHeader from "./LocationPickerHeader";
import { LocationPickerHero } from "./LocationPickerHero";
import { LocationPickerResults } from "./LocationPickerResults";
import { LocationPickerSearch } from "./LocationPickerSearch";
import { getPageTitleAndMeta } from "../../../utilities/util";
import AppContext from "../../AppContext";
import useIntlMessage from "../../hooks/useIntlMessage";
import { Helmet } from "react-helmet-async";

export default function LocationPicker() {
  const location = useLocation();
  const serverLocation = useLocation();
  const jobDetailsFromLoader: any = useRouteLoaderData(RouteID.locationPicker); // TODO: add type as mappedJobDetails
  const rootLoaderData: AppData = useRouteLoaderData(RouteID.root) as AppData;
  const { appUIState } = useContext(AppContext);
  const { t } = useIntlMessage();
  const { pageTitle, meta } = getPageTitleAndMeta(
    appUIState.appData.isInternal,
    appUIState.appData.baseUrl,
    appUIState.appData.locale,
    t,
    jobDetailsFromLoader.jobsData.postingTitle,
    appUIState.appData.countryCode,
    jobDetailsFromLoader.requestUrl,
    true,
  );

  return (
    <LocationPickerProvider
      jobDetails={jobDetailsFromLoader.jobsData}
      countryCode={rootLoaderData.countryCode}
      location={location}
      serverLocation={serverLocation}
    >
      <Helmet title={pageTitle} meta={meta} />
      <LocationPickerHeader />
      <LocationPickerSearch />
      <LocationPickerResults />
      <LocationPickerHero />
    </LocationPickerProvider>
  );
}
