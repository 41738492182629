/*
 * These constants are reffered to on client side
 * As this needs to be accessable on client, it cannot be pushed to app.config.json.
 * KEEP_ALIVE_INTERVAL_MILI_SECONDS = 28.5 min * 60 sec * 1000 ms
 * IDLE_TIMER_MILI_SECONDS = 28 min * 60 sec * 1000 ms
 * IDLE_COUNT_MILI_SECONDS = 2 min * 60 sec * 1000 ms
 * INTERNAL_REDIRECT_TIMER: 3000 => 3 seconds
 */

const APP_CONSTANTS = {
  DEFAULT_LOCALE: "en-us",
  DEFAULT_DATA_LOCALE: "en_US",
  DEFAULT_IDMS_LOCALE: "US_EN",
  DEFAULT_HTML_LANG: "en-US",
  DEFAULT_COUNTRY_ID: "country-USA",
  DEFAULT_COUNTRY_NAME: "United States",
  IDLE_TIMER_MILI_SECONDS: 1680000,
  IDLE_COUNT_MILI_SECONDS: 120000,
  KEEP_ALIVE_INTERVAL_MILI_SECONDS: 1710000,
  INTERNAL_REDIRECT_TIMER: 3000,
  AUTOCOMPLETE_TYPING_DELAY_TIMER: 1000,
  SET_FOCUS_DELAY_TIMER: 950,
  SHORT_TIMER: 250,
  EXTRA_SHORT_TIMER: 200,
  KEEP_ALIVE_ARIA_MESSAGE_TIMER: 5000,
  SET_SCROLL_DELAY_TIMER: 100,
  SET_SCROLL_ANIMATE_TIMER: 100,
  USER_TYPE_INTERNAL: "INTERNAL",
  DEFAULT_PAGE_TYPE: "default",
  SING_IN_PAGE_ID: "signIn",
  SING_OUT_PAGE_ID: "signOut",
  ENDPOINTS: {
    CSRF: "/app/api/v1/js/CSRFToken",
    ROLES: "/app/api/v1/js/talent/<%=talentId%>/roles",
    UPDATEPARSINGSTATUS:
      "/app/api/v1/js/talent/<%=talentID%>/parsedResumeSnapshot/fail",
    AUTOCOMPLETE: "/api/role/autocomplete/<%=searchTerm%>",
    EXPAND_RESULTS: "/app/api/v1/js/refData/postLocations/expandResults",
    LOCATIONS: "/app/api/v1/ref/locations",
    TEAMS: "/app/api/v1/ref/teams",
    PRODUCTS: "/app/api/v1/ref/products",
    INTERNALJOBS_REDIRECT: "/<%=locale%>/go-to-internal-jobs?path=<%=path%>",
    KEEP_ALIVE: {
      CURRENT_USER: "/app/api/v1/js/user",
      KEEP_ALIVE: "/session/keep-alive",
    },
  },
  CAPTCHA: {
    ERROR_MOBILE: "captcha-error-mobile",
    ERROR_DESKTOP: "captcha-error-desktop",
    ERROR_CODE_INVALID: "-1002",
    ERROR_CODE_EXPIRED: "-1004",
  },
  COLUMN_LAYOUT: {
    TEAM_LAYOUT: "team",
  },
  FILTER_ENTITY: {
    LOCATION: "postlocation",
    LANGUAGE: "language",
    PRODUCTS: "productsAndServices",
    KEYWORD: "keyword",
    TEAMS: "teamsAndSubTeams",
  },
  KEYCODE: {
    UP: 38,
    DOWN: 40,
    TAB: 9,
    ESCAPE: 27,
    ENTER: 13,
    CTRL: 17,
    ALT: 18,
    END: 35,
    LEFT: 37,
    RIGHT: 39,
  },
  SERVICE_ERROR:
    "We were unable to complete your request. Please try again later.",
  INTERNAL_SERVICE_ERROR:
    "Jobsite was unable to process your request. Please try your request again. If the problem continues please contact your local IS&T HelpLine.",
  RECORDS_PER_PAGE: 20,
  TOOLTIP_TIMER: 2000,
  ANALYTICS: {
    BASE_INFO: "baseInfo",
    SEARCH_FILTER: "searchFilter",
    SITE_TYPE: {
      EXTERNAL: "external",
      INTERNAL: "internal",
    },
    PAGE_NAME: {
      SEARCH: "search",
      JOB_DETAILS: "job details",
      PRIVACY_POLICY: "privacy",
      LOGGED_OUT: "logged out",
      TIMED_OUT: "timed out",
      JOBS: "jobs",
      NO_JOBS: "no job details found",
    },
    PROFILE: "profile",
    INFO: "info",
    SAVED_SEARCH: "saved searches",
    YOUR_ROLES: "your roles",
    YOUR_APPLICATION: "your application",
    APPLY: "apply",
    APPLY_RESUME: "apply - resume",
    APPLY_SELF_DISCLOSURE: "apply - self disclosure",
    APPLY_QUESTIONNAIRE: "apply - questionnaire",
    APPLY_REVIEWINFO: "apply - review information",
    APPLY_THANKS: "info - apply thanks",
    GETDISCOVERED: "get discovered",
    GETDISCOVERED_RESUME: "get discovered - resume",
    GETDISCOVERED_REVIEWINFO: "get discovered - review information",
    GETDISCOVERED_TEAMS: "get discovered - teams of interest",
    GETDISCOVERED_LOCATION: "get discovered - role locations",
    GETDISCOVERED_THANKS: "info - get discovered thanks",
    ERP: "erp",
    ERP_CONTACT: "erp - contact information",
    ERP_INTEREST: "erp - corporate - teams of interest",
    ERP_ROLE: "erp - apple store - role",
    ERP_CORP_LOCATION: "erp - corporate - location",
    ERP_STORE_LOCATION: "erp - apple store - location",
    ERP_CONTRACT: "erp - contract",
    ERP_REVIEWINFO: "erp - review information",
    ERP_THANKS: "erp - thanks",
    YOURAPPLICATION_INFO: "your application - information",
    YOURAPPLICATION_THANKS: "your application - thanks",
  },
  SEARCH_SORT_TERMS: [
    "relevance",
    "newest",
    "teamAsc",
    "teamDesc",
    "locationAsc",
    "locationDesc",
  ],
  SORT_MODIFIERS: {
    RELEVANCE: "relevance",
    NEWEST: "newest",
    TEAM_ASC: "teamAsc",
    TEAM_DESC: "teamDesc",
    LOCATION_ASC: "locationAsc",
    LOCATION_DESC: "locationDesc",
  },

  JOB_DETAILS_QUERY_PARAMS: ["team", "s", "z", "d", "m", "l"],
  DTM_APP_TYPE: "jobsite",
  SEARCH_SECTION: "search",
  HEADER_SECTION: "header",
  FOOTER_SECTION: "footer",
  OG_IMAGE_PATH: "/public/images/og",
  JOB_TYPE: {
    RETAIL: "RETAIL",
    CORPORATE: "CORPORATE",
  },
  LOCATION_PICKER_RELATIVE_PATH_NAME: "locationPicker",
  POST_LOCATION_USA: "postLocation-USA",
  LOCATION_PICKER_DISTANCES: [10, 25, 50, 75],
  LOCATION_PICKER_INIITIAL_STATE: "initial",
  LEARN_MORE_RETAIL_URL: "https://www.apple.com/careers/us/retail.html",
  GLOBAL_NAV_ID: "globalnav",
  INTERNAL_FILTER_JOB_LEVELS: {
    MY_LEVEL: "myLevel",
    MY_LEVEL_PLUS_ONE: "myLevelPlusOne",
  },
  SEARCH_QUERY_PARAMS_KEYS: {
    PRODUCT: "product",
    TEAM: "team",
    JOB_LEVEL: "jobLevel",
    STORE_ROLE: "role",
    PRODUCTS: "product",
    KEYWORD: "key",
    PAGE: "page",
    LOCATION: "location",
    HOME_OFFICE: "homeOffice",
    HIRING_MANAGER: "mgr",
    LANGUAGES: "lang",
    SORT: "sort",
    MIN_HRS: "minHours",
    MAX_HRS: "maxHours",
    SEARCH: "search",
  },
  HTML_DIRECTION: {
    LTR: "ltr",
    RTL: "rtl",
  },
};
export default APP_CONSTANTS;
