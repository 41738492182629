import { formatDateByLocale } from "@rpe-js/core/dist/util/dateUtil";
import {
  NativeButton,
  ProgressIndicatorLoader,
} from "@rpe-js/marcom-web-components";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useRouteLoaderData } from "react-router";
import { SelfDisclosures } from "../../../shared/types";
import { BaseRefData, MappedPostLocation } from "../../../shared/types/refData";
import {
  ExternalProfileLinkedRequest,
  PrivacyPolicy as PrivacyPolicyType,
  SelfDisclosure,
} from "../../../shared/types/talent/talent";
import { TalentConnection } from "../../../shared/types/talentConnection";
import APP_CONSTANTS from "../../../utilities/appConstants";
import {
  deleteFilePromises,
  externalProfileLinked,
  getCountryRefData,
  getCountrySpecificPrivacyPolicy,
  postLocations,
  talentSubmit,
  updateFileCategoryPromises,
  uploadSupportingFilePromises,
} from "../../api/fetchClient";
import {
  COUNTRY_CHINA,
  DEFAULT_LOCALE,
  DEFAULT_LOCALE_API_HEADER,
  ROLE_MANAGED,
  ROLE_PIPELINE,
  ROLE_SUBMIT,
  YES,
} from "../../app.constants";
import AppContext from "../../AppContext";
import { ResumeOrLinkedinFileView } from "../../components/base/ResumeOrLinkedinFileView";
import { SupportingFileView } from "../../components/base/SupportingFileView";
import PrivacyPolicy from "../../components/feature/PrivacyPolicy";
import { ContactReviewSection } from "../../components/feature/reviewInfo/ContactReviewSection";
import { QuestionnaireSection } from "../../components/feature/reviewInfo/QuestionnaireSection";
import { ReviewSection } from "../../components/feature/reviewInfo/ReviewSection";
import { SelfDisclosureSection } from "../../components/feature/reviewInfo/SelfDisclosureSection";
import { useSelfDisclosure } from "../../components/feature/selfDisclosure/useSelfDisclosure";
import { GlobalContext } from "../../contexts/GlobalContext";
import { useCurrentUserContext } from "../../CurrentUserContext";
import { translateReplace } from "../../hooks/translateReplace";
import useDtm from "../../hooks/useAdobeAnalytics";
import useCachedGlobalIntl from "../../hooks/useCachedGlobalIntl";
import { useFetchData } from "../../hooks/useFetchData";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { useTriggerLinkedInResumePolling } from "../../hooks/useResumePolling";
import useSetPageTitle, { PAGE_TITLES } from "../../hooks/useSetPageTitle";
import { RouteID } from "../../routes";
import { TalentSupportingFile } from "../../types/SupportingFileWidget";
import { isCorporate, isRetailType } from "../../util";
import { uploadResumePromises } from "../../utils/fileUtil";
import { handleScrollToElement } from "../../utils/focusUtil";
import { idGenerator } from "../../utils/idGenerator";
import { isTriggerPolling } from "../../utils/resumeUtils";
import ApplyActionContainer from "./ApplyActionContainer";
import {
  addressUpdateAction,
  contactUpdateAction,
  pronounsUpdateAction,
  stepAction,
} from "./context/Actions";
import { useApplyContext } from "./context/ApplyContext";
import { profileUpdateMode, StepName, Talent } from "./context/ApplyState";

interface ReviewStepProps {
  selfDisclosuresRefData?: SelfDisclosures | undefined;
  goToStep: (stepName: StepName) => void;
  goPrevious: () => void;
}

export function ReviewStep({
  selfDisclosuresRefData,
  goToStep,
  goPrevious,
}: ReviewStepProps) {
  const jobDetails = useRouteLoaderData(RouteID.apply) as any;
  const { t } = useIntlMessage();
  const { appUIState, dispatch: dispatchAppContext } = useContext(AppContext);
  const globalIntl = useCachedGlobalIntl();
  const { fetchData: callExternalProfileLinked } = useFetchData(
    externalProfileLinked,
  );
  const { locale: appLocale, isInternal, countryCode } = appUIState.appData;
  const locale = isCorporate(jobDetails) ? DEFAULT_LOCALE : appLocale;
  const dataLocale = isCorporate(jobDetails)
    ? DEFAULT_LOCALE_API_HEADER
    : countryCode?.data;
  const { updateGlobalMessage } = useContext(GlobalContext) ?? {};
  const isMobile = useIsMobile();
  const { currentUser, config } = useCurrentUserContext();
  const { getLocalizedSelfDisclosure } = useSelfDisclosure();
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const { state, dispatch } = useApplyContext();
  const pageState = state.page;
  const linkedinSnapshot = state.linkedinSnapshot;
  const { setPageTitle } = useSetPageTitle();
  const [countryData, setCountryData] = useState<BaseRefData[] | null>(null);
  const [postLocationData, setPostLocationData] = useState<
    MappedPostLocation[] | null
  >(null);
  const [canShowError, setCanShowError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const jobId = jobDetails.jobNumber || jobDetails.positionID;
  const talent = state.talent;
  const talentId = currentUser.talentId as string;
  const stepMapping = state.page.stepMapping;
  const navigate = useNavigate();
  const resume = useMemo(
    () => state.resume || { fileId: "", name: "", createdTime: "" },
    [state.resume],
  );
  const supportFiles = useMemo(
    () => state.supportFiles || [],
    [state.supportFiles],
  );
  const textResume = state.textResume;
  const linkedIn =
    talent && talent.externalProfiles && talent.externalProfiles.linkedin;
  const selfDisclosures = talent && talent.selfDisclosures;
  const selfDisclosureConfig = jobDetails.selfDisclosureConfig;
  const countryID =
    jobDetails.selfDisclosureConfig &&
    jobDetails.selfDisclosureConfig.countryID;
  const showQuestionnaire =
    state.page.stepNames.indexOf(StepName.QUESTIONNAIRE) >= 0;
  const retailType = isRetailType(jobDetails);
  const selectedLocations = state.selectedLocations;
  const discardSupportFiles = state.discardedFiles;
  const discardResume = state.discardResume;
  const changedFileCategories = state.updatedFileCategories;
  // maintain the previous country object if dropdown value changes to china and do not accept privacy policy, we have to revert to previous selected country on modal close
  const [previousCountry, setPreviousCountry] = useState<{
    countryID: string;
    countryName: string;
  }>(
    talent.addresses && talent.addresses.length > 0
      ? { ...talent.addresses[0] }
      : { countryID: "", countryName: "" },
  );
  const [countryId, setCountryId] = useState<string>(""); // keeping track of updated countryId
  // to open the privacy policy modal in edit mode if country changed to china
  const [privacyPolicyEditMode, setPrivacyPolicyEditMode] =
    useState<boolean>(false);
  // maintain china privacy policy if country changed to china and accepted privacy policy
  const [privacyPolicyChina, setPrivacyPolicyChina] =
    useState<PrivacyPolicyType>({
      countryID: "",
      acknowledgedDate: 0,
      version: 0,
      dataConsent: talent?.privacyPolicy?.dataConsent,
    });
  const privacyPolicy = talent && talent.privacyPolicy;
  const acknowledgeDate =
    privacyPolicy &&
    (t("jobsite.common.acknowledgedDate", {
      date: formatDateByLocale(
        new Date(privacyPolicy.acknowledgedDate as string).getTime(),
        locale,
      ),
    }) as string);

  const triggerPolling = useTriggerLinkedInResumePolling();
  const idSeqGenerator = idGenerator("apply", "review-step");
  const VIEW_PRIVACY_POLICY_BUTTON_ID = idGenerator(
    "apply",
    "view-privacy-policy",
  ).generateId("button");

  const updateReviewInfo = useCallback(() => {
    dispatch(
      stepAction({
        ...pageState,
        showReviewSubmit: true,
      }),
    );
  }, [dispatch, pageState]);

  const editResume = useCallback(() => {
    updateReviewInfo();
    goToStep(StepName.RESUME);
  }, [goToStep, updateReviewInfo]);

  const editLinkedIn = useCallback(() => {
    updateReviewInfo();
    goToStep(StepName.RESUME);
  }, [goToStep, updateReviewInfo]);

  const editSupportFile = useCallback(() => {
    updateReviewInfo();
    goToStep(StepName.RESUME);
  }, [goToStep, updateReviewInfo]);

  const editSelfDisclosure = useCallback(() => {
    // once the talent lands on self disclosure step from review step through edit option,
    // talent should be able to navigate to self disclosure step through other steps
    dispatch(
      stepAction({
        ...pageState,
        skipSelfDisclosure: false,
        showReviewSubmit: true,
      }),
    );
    goToStep(StepName.SELFDISCLOSURE);
  }, [dispatch, goToStep, pageState]);

  const editQuestionnaire = useCallback(() => {
    updateReviewInfo();
    goToStep(StepName.QUESTIONNAIRE);
  }, [goToStep, updateReviewInfo]);

  const onPrivacyPolicyAccept = useCallback(
    (data: PrivacyPolicyType) => {
      setShowPrivacyModal(false);
      setPrivacyPolicyEditMode(false);
      if (countryId == COUNTRY_CHINA) {
        setPrivacyPolicyChina(data);
      }
    },
    [countryId],
  );

  const onPrivacyModalClose = useCallback(() => {
    if (privacyPolicyEditMode) {
      setPrivacyPolicyEditMode(false);
      if (previousCountry && previousCountry.countryID) {
        setCountryId(previousCountry.countryID);
        dispatch(addressUpdateAction({ ...previousCountry }));
      }
    }
    setShowPrivacyModal(false);
  }, [dispatch, previousCountry, privacyPolicyEditMode]);

  const updatedFileCategoryPromises = useCallback(
    (talentId: string, changedFileCategories: any) => {
      if (talentId && changedFileCategories)
        return updateFileCategoryPromises(talentId, changedFileCategories);
      return [];
    },
    [],
  );

  const getDiscardedFilesToDelete = useCallback(() => {
    let discardedFiles =
      (discardSupportFiles &&
        discardSupportFiles.map(
          (value: TalentSupportingFile) => value.fileData.fileId as string,
        )) ||
      [];

    discardedFiles = discardedFiles.concat(
      (discardResume && discardResume.fileId) || [],
    );
    return discardedFiles;
  }, [discardResume, discardSupportFiles]);

  /**
   * All file related api promises are added to array
   */
  const allFilePromises = useCallback(() => {
    const promises = [
      ...deleteFilePromises(talentId, getDiscardedFilesToDelete()),
      ...updatedFileCategoryPromises(talentId, changedFileCategories),
      ...uploadResumePromises(
        talentId,
        resume,
        textResume,
        talent.profileUpdateSelection,
        talent.linkedinLastAction,
        talent.resumeLastAction,
      ),
      ...uploadSupportingFilePromises(talentId, supportFiles, false),
    ];

    return promises;
  }, [
    changedFileCategories,
    getDiscardedFilesToDelete,
    supportFiles,
    talentId,
    updatedFileCategoryPromises,
    talent,
    textResume,
    resume,
  ]);

  const prepareRolesSubmitPayload = useCallback(() => {
    const connectionData = state.connection || ({} as TalentConnection);
    const locationIndicator =
      (jobDetails.type == ROLE_PIPELINE &&
        jobDetails.pipelineType == ROLE_MANAGED &&
        jobDetails.roleLocationSelection) ||
      jobDetails.managedPipelineRole;
    const selectedRolesForLocation =
      (state.selectedLocations && state.selectedLocations.locationIds) || [];
    const storeLocationsObj = {
      ...(locationIndicator && { resubmitted: YES }),
      ...(locationIndicator && { roleLocation: false }),
      ...(locationIndicator &&
        selectedRolesForLocation.length > 0 && { roleLocation: true }),
      ...(locationIndicator &&
        selectedRolesForLocation.length > 0 && {
          storeLocations: selectedRolesForLocation,
        }),
    };

    return {
      talentID: (connectionData.talentID as string) || currentUser.talentId,
      positionID: connectionData.positionID || jobDetails.id,
      location: jobDetails.location,
      submitted: YES,
      pipelineType: jobDetails.pipelineType,
      ...storeLocationsObj,
    };
  }, [
    currentUser.talentId,
    jobDetails.id,
    jobDetails.location,
    jobDetails.managedPipelineRole,
    jobDetails.pipelineType,
    jobDetails.roleLocationSelection,
    jobDetails.type,
    state.connection,
    state.selectedLocations,
  ]);

  const prepareTalentSubmitPayload = useCallback(
    (talent: Talent) => {
      return {
        talentId: talent.talentId,
        talentDSID: talent.talentDSID,

        // resume step fields
        externalProfiles: talent.externalProfiles,
        profileUpdateSelection:
          talent.profileUpdateSelection || profileUpdateMode.MANUAL,
        links: talent.links,

        // selfdisclosure step fields
        selfDisclosures: talent.selfDisclosures,

        // reviewinfo step fields
        contact: talent.contact,
        addresses: talent.addresses,
        ...(isInternal ? { displayPronouns: talent.displayPronouns } : {}),
        privacyPolicy: talent.privacyPolicy,

        // required for apply flow
        sourceName: talent.sourceName,
        userType: talent.userType,
        submittedFlow: true,
      };
    },
    [isInternal],
  );

  const checkIfUserHasAcceptedChinaPrivacyPolicy = useCallback(() => {
    if (
      countryId == COUNTRY_CHINA &&
      privacyPolicyChina &&
      privacyPolicyChina.countryID == COUNTRY_CHINA
    ) {
      return true;
    }
    return false;
  }, [countryId, privacyPolicyChina]);

  const submitTalentApplication = useCallback(async () => {
    const talentId = talent.talentId as string;
    const requestBody = {
      roles: {
        ...prepareRolesSubmitPayload(),
      },
      talent: {
        ...prepareTalentSubmitPayload(talent),
        ...(checkIfUserHasAcceptedChinaPrivacyPolicy()
          ? {
              privacyPolicy: {
                ...privacyPolicyChina,
              },
            }
          : {}),
      },
      ...(stepMapping &&
        stepMapping[StepName.QUESTIONNAIRE] && {
          questionnaireAnswers: {
            ...state.questionnaireAnswers,
            talentId,
            questionnaireId: jobDetails.questionarieTemplateID,
            reset: false,
            positionId: jobDetails.id,
            ...(state.connection &&
              state.connection.id && { connectionId: state.connection.id }),
          },
        }),
    };
    return await talentSubmit(talentId, requestBody);
  }, [
    checkIfUserHasAcceptedChinaPrivacyPolicy,
    jobDetails.id,
    jobDetails.questionarieTemplateID,
    prepareRolesSubmitPayload,
    prepareTalentSubmitPayload,
    privacyPolicyChina,
    state.connection,
    state.questionnaireAnswers,
    stepMapping,
    talent,
  ]);

  const validateSubmit = useCallback(() => {
    // for internal, all fields will be readonly, no validation is required on talent object
    if (isInternal) {
      return true;
    }
    setCanShowError(true);
    const talentObj = { ...talent };
    if (!talentObj.contact) {
      return false;
    }

    if (!talentObj.contact.firstName) {
      handleScrollToElement("apply-review-firstname", true);
      return false;
    }

    if (!talentObj.contact.lastName) {
      handleScrollToElement("apply-review-lastname", true);
      return false;
    }

    if (!talentObj.contact.preferredPhone) {
      handleScrollToElement("apply-review-phone", true);
      return false;
    }

    if (!talentObj.addresses || talentObj.addresses.length == 0) return false;

    if (!talentObj.addresses[0].countryID) {
      handleScrollToElement("apply-review-country", true);
      return false;
    }

    if (!talentObj.addresses[0].state) {
      handleScrollToElement("apply-review-state", true);
      return false;
    }

    return true;
  }, [isInternal, talent]);

  const getThanksMessage = useCallback(() => {
    let thanksMessage = "";
    if (!talent.educationDegrees && !talent.employments) {
      thanksMessage = globalIntl.formatMessage({
        id: "jobsite.common.applyNoProfileThanksContent",
      });
    }
    if (talent.educationDegrees || talent.employments) {
      thanksMessage = globalIntl.formatMessage({
        id: "jobsite.common.applyParsingOptionsThanksContent",
      });
    }
    const yourRolesText = t("jobsite.common.yourRoles") as string;
    thanksMessage = translateReplace(
      thanksMessage,
      ["{yourroles}", "{firstname}", "{lastname}", "{jobtitle}"],
      [
        `<a href="/app/${appLocale}/profile/roles">${yourRolesText}</a>`,
        talent.contact.firstName as string,
        talent.contact.lastName as string,
        `<span>${jobDetails.postingTitle}</span>`,
      ],
    );
    return `<div class="thanks-message">${thanksMessage}</div>`;
  }, [
    appLocale,
    globalIntl,
    jobDetails.postingTitle,
    t,
    talent.contact.firstName,
    talent.contact.lastName,
    talent.educationDegrees,
    talent.employments,
  ]);

  const linkExternalLinkedInProfile = useCallback(() => {
    if (linkedinSnapshot) {
      const externalProfileLinkedPayload: ExternalProfileLinkedRequest = {
        publicProfileUrl: talent.externalProfiles?.linkedin?.url as string,
        snapshotType: "json",
        talentId: talent.talentId as string,
        source: "linkedin",
        snapshot: linkedinSnapshot,
      };
      const makeApiCall = async () => {
        await callExternalProfileLinked([externalProfileLinkedPayload]);
      };
      makeApiCall();
    }
  }, [
    callExternalProfileLinked,
    linkedinSnapshot,
    talent.externalProfiles?.linkedin?.url,
    talent.talentId,
  ]);
  const { trackPageLoad } = useDtm();

  const submitTalent = useCallback(async () => {
    setShowLoading(true);
    const filePromises = allFilePromises();
    let successCount = 0;
    try {
      for (const filePromise of filePromises) {
        const promiseResult = await filePromise();
        if (promiseResult && promiseResult.success) {
          successCount++;
        } else if (promiseResult && promiseResult.error) {
          dispatchAppContext({
            type: "RESUME_PARSING_DATA",
            payload: {
              profileUpdateSelection: "resume",
              resumeLastAction: "FAILED",
              linkedinLastAction: undefined,
            },
          });
          break;
        } else {
          break;
        }
      }
      // Submit application if no file uploaded attached or all attached files are successfully uploaded
      if (filePromises.length === successCount) {
        const submitResult = await submitTalentApplication();
        if (submitResult) {
          setShowLoading(false);
          const profileUpdateSelection = talent.profileUpdateSelection;
          // if the resume fill mode selected is linkedin, we have to make external linkedin profile call
          if (
            profileUpdateSelection &&
            profileUpdateSelection === profileUpdateMode.LINKEDIN
          ) {
            linkExternalLinkedInProfile();
          }

          // Trigger polling incase the resume, textresume or linkedin data is changed
          if (
            isTriggerPolling(
              resume,
              textResume,
              profileUpdateSelection,
              linkedinSnapshot,
            )
          ) {
            triggerPolling(profileUpdateSelection);
          }

          // show thanks message in profile
          updateGlobalMessage &&
            updateGlobalMessage(`/app/${appLocale}/profile/info`, {
              translatedText: getThanksMessage(),
              messageType: "info",
              recruitics: appUIState.appData.isInternal ? "true" : "false", // enable recruitics pixel for internal users
              recruticsUrl: config.recrutics?.url, // set recrutics url
            });
          sessionStorage.setItem(ROLE_SUBMIT, "true");
          const pageInfo = {
            pageName: APP_CONSTANTS.ANALYTICS.APPLY_THANKS,
            locale: appUIState?.appData?.locale || APP_CONSTANTS.DEFAULT_LOCALE,
            title: APP_CONSTANTS.ANALYTICS.APPLY,
            isInternal: appUIState?.appData?.isInternal,
          };
          if (typeof window !== "undefined") {
            trackPageLoad(pageInfo);
          }
          navigate(`/app/${appLocale}/profile/info`);
        }
      }
      setShowLoading(false);
    } catch (error) {
      // In case the API failed hide loading spinner
      // TODO: We should be showing some banner message informing user the submit application is failed
      setShowLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allFilePromises,
    dispatchAppContext,
    submitTalentApplication,
    talent.profileUpdateSelection,
    resume,
    textResume,
    linkedinSnapshot,
    updateGlobalMessage,
    appLocale,
    getThanksMessage,
    appUIState.appData.isInternal,
    config.recrutics?.url,
    navigate,
    linkExternalLinkedInProfile,
    triggerPolling,
  ]);

  const onContactFieldChange = (option: { key: string; value: string }) => {
    dispatch(contactUpdateAction(option.key, option.value));
  };
  const onPronounsFieldChange = (option: { key: string; value: boolean }) => {
    dispatch(pronounsUpdateAction(option.value));
  };

  const onAddressFieldChange = (option: { key: string; value: any }) => {
    if (option.key == "state")
      dispatch(
        addressUpdateAction({
          state: option.value,
          typeID: "addressType-preferred",
        }),
      );
    else {
      const countryObj = option.value; // current country dropdown value
      setCountryId(countryObj.value);
      // check if we need to show privacy policy modal of china
      if (
        countryObj.value == COUNTRY_CHINA &&
        (!previousCountry || previousCountry.countryID != countryObj.value)
      ) {
        // we have to make an api call to fetch the talent china privacy policy status
        getCountrySpecificPrivacyPolicy(talentId, COUNTRY_CHINA).then((res) => {
          if (!res?.privacyPolicyAccepted) {
            // privacy policy modal can be opened with edit mode / view mode
            setShowPrivacyModal(true);
            // open the modal in edit mode if user has not accepted it (usecase specific to china)
            setPrivacyPolicyEditMode(true);
          }
        });
      } else {
        // set the current country value to previous
        setPreviousCountry({
          countryID: countryObj.value,
          countryName: countryObj.label,
        });
        // reset china privacy policy object
        setPrivacyPolicyChina({
          countryID: "",
          acknowledgedDate: 0,
          version: 0,
          dataConsent: talent?.privacyPolicy?.dataConsent,
        });
      }

      dispatch(
        addressUpdateAction({
          countryID: countryObj.value,
          countryName: countryObj.label,
          typeID: "addressType-preferred",
        }),
      );
    }
  };

  const getCountryRefDataInfo = useCallback(async () => {
    if (isInternal) {
      setCountryData([]);
      return;
    }
    const countryRefData = await getCountryRefData(dataLocale);
    if (countryRefData) {
      setCountryData(countryRefData);
    }
  }, [dataLocale, isInternal]);

  const postLocationsInfo = useCallback(
    async (jobId: string, selectedLocations: Array<string>) => {
      const postLocationsRefData = (await postLocations(
        jobId,
        selectedLocations,
      )) as MappedPostLocation[];
      if (postLocationsRefData) {
        setPostLocationData(postLocationsRefData);
      } else {
        setPostLocationData([]);
      }
    },
    [],
  );

  const getSelfDisclosure = useCallback((): SelfDisclosure | undefined => {
    // For nationality id should code key of atpNationality refData
    if (selfDisclosuresRefData?.atpNationality) {
      selfDisclosuresRefData.atpNationality =
        selfDisclosuresRefData.atpNationality.map((value) => ({
          ...value,
          id: value.code,
        }));
    }
    if (
      selfDisclosures &&
      selfDisclosures[countryID] &&
      selfDisclosuresRefData
    ) {
      return getLocalizedSelfDisclosure(
        selfDisclosures[countryID],
        selfDisclosuresRefData,
      );
    }
    return selfDisclosures && selfDisclosures[countryID];
  }, [
    countryID,
    getLocalizedSelfDisclosure,
    selfDisclosures,
    selfDisclosuresRefData,
  ]);

  useEffect(() => {
    setPageTitle(
      PAGE_TITLES.APPLY.REVIEW,
      state.page.stepMapping?.reviewinfo || 1,
      state.page.stepNames.length,
      jobDetails.postingTitle,
    );
    if (!countryData) getCountryRefDataInfo();
  }, [
    isInternal,
    countryData,
    getCountryRefDataInfo,
    setPageTitle,
    state.page.stepMapping?.reviewinfo,
    state.page.stepNames.length,
    jobDetails.postingTitle,
    pageState,
  ]);

  useEffect(() => {
    if (
      retailType &&
      selectedLocations &&
      selectedLocations.locationIds &&
      !postLocationData
    ) {
      postLocationsInfo(jobId, selectedLocations?.locationIds || []);
    }
  }, [
    jobId,
    postLocationData,
    postLocationsInfo,
    retailType,
    selectedLocations,
  ]);

  return (
    <>
      <h1
        id="review-info-header"
        className="t-headline-reduced mb-40 text-center"
      >
        {t("jobsite.common.reviewInformation")}
      </h1>
      <section
        id="review-info-content"
        className="mtb-auto-0 reviewinfo-container"
      >
        <ContactReviewSection
          module="apply"
          isEdit={!isInternal}
          locale={locale}
          countryData={countryData}
          talent={talent as any}
          onContactFieldChange={onContactFieldChange}
          onAddressFieldChange={onAddressFieldChange}
          onPronounsFieldChange={onPronounsFieldChange}
          canShowError={canShowError}
          showAddressSection={true}
        ></ContactReviewSection>

        <ReviewSection
          headerText={t("jobsite.information.resume") as string}
          onAction={editResume}
          id={idSeqGenerator.generateId("resume")}
        >
          <ResumeOrLinkedinFileView
            resume={resume}
            textResume={textResume}
            linkedIn={linkedIn}
            noResultsLabel={t("jobsite.common.notSpecified") as string}
            locale={locale}
            viewType="resume"
          />
        </ReviewSection>
        <ReviewSection
          headerText={t("jobsite.information.linkedIn") as string}
          onAction={editLinkedIn}
          id={idSeqGenerator.generateId("linkedin")}
        >
          <ResumeOrLinkedinFileView
            resume={resume}
            textResume={textResume}
            linkedIn={linkedIn}
            noResultsLabel={t("jobsite.common.notSpecified") as string}
            locale={locale}
            viewType="linkedin"
          />
        </ReviewSection>
        {supportFiles && (
          <ReviewSection
            headerText={t("jobsite.common.supportFilesLinks") as string}
            onAction={editSupportFile}
            id={idSeqGenerator.generateId("supportfiles")}
          >
            <SupportingFileView
              supportFiles={state.supportFiles}
              supportLinks={state.talent.links}
              noResultsLabel={t("jobsite.common.notSpecified") as string}
            />
          </ReviewSection>
        )}
        {jobDetails.selfDisclosureConfig && (
          <ReviewSection
            headerText={t("jobsite.apply.selfDisclosure") as string}
            onAction={editSelfDisclosure}
            id={idSeqGenerator.generateId("selfdisclosure")}
          >
            <SelfDisclosureSection
              selfDisclosureConfig={selfDisclosureConfig}
              selfDisclosure={getSelfDisclosure()}
            ></SelfDisclosureSection>
          </ReviewSection>
        )}
        {showQuestionnaire && (
          <ReviewSection
            headerText={t("jobsite.profile.yourRoles.questionnaire") as string}
            onAction={editQuestionnaire}
            id={idSeqGenerator.generateId("questionnaire")}
          >
            <QuestionnaireSection
              questions={
                (state.questionnaireAnswers &&
                  state.questionnaireAnswers.questions) ||
                []
              }
            ></QuestionnaireSection>
          </ReviewSection>
        )}
        {retailType && selectedLocations && selectedLocations.locationIds && (
          <ReviewSection
            headerText={t("jobsite.common.locations") as string}
            onAction={() => {}}
            showEdit={false}
            id={idSeqGenerator.generateId("locations")}
          >
            {!postLocationData && (
              <section className="mt-10">
                <span>{t("jobsite.common.notSpecified")}</span>
              </section>
            )}
            {postLocationData &&
              Array.isArray(postLocationData) &&
              postLocationData.length > 0 && (
                <section className="mt-10">
                  {postLocationData.map((postLocation: MappedPostLocation) => {
                    return (
                      <p key={postLocation.id} className="mt-0">
                        {postLocation.city}, {postLocation.titleName}
                      </p>
                    );
                  })}
                </section>
              )}
          </ReviewSection>
        )}
        <ReviewSection
          headerText={t("jobsite.common.candidatePrivacyPolicy") as string}
          showEdit={false}
          onAction={() => {}}
          id={idSeqGenerator.generateId("privacy")}
        >
          <section className="mt-10">
            <p className="t-body">{acknowledgeDate}</p>
            <NativeButton
              id={VIEW_PRIVACY_POLICY_BUTTON_ID}
              className="link more mt-0"
              onClick={() => {
                setShowPrivacyModal(true);
              }}
              label={t("jobsite.common.view") as string}
            ></NativeButton>
          </section>
        </ReviewSection>
      </section>
      <section id="review-info-footer" className="d-flex justify-center">
        <ApplyActionContainer
          cancelLabelName={t("jobsite.common.back") as string}
          saveLabelName={t("jobsite.common.submit") as string}
          reviewLabelName={t("jobsite.common.reviewSubmit") as string}
          showBackButton={true}
          showContinueButton={true}
          showReviewButton={false}
          classes={!isMobile ? "review-actions-btngroup" : ""}
          onContinue={() => {
            if (validateSubmit()) submitTalent();
          }}
          onBack={() => {
            updateReviewInfo();
            goPrevious();
          }}
        ></ApplyActionContainer>
      </section>
      {showLoading && (
        <ProgressIndicatorLoader
          showLoading={showLoading}
          curtain={true}
        ></ProgressIndicatorLoader>
      )}
      {showPrivacyModal && (
        <PrivacyPolicy
          showModal={showPrivacyModal}
          onCloseModal={onPrivacyModalClose}
          onAcceptPrivacyPolicy={onPrivacyPolicyAccept}
          editMode={privacyPolicyEditMode}
          {...(privacyPolicyEditMode
            ? { defaultCountryId: COUNTRY_CHINA }
            : {})}
          disableCountrySelector={true}
          cancelButtonText={t("jobsite.common.cancel") as string}
          triggerElementId={
            privacyPolicyEditMode
              ? idGenerator("apply", "review").generateId("country")
              : VIEW_PRIVACY_POLICY_BUTTON_ID
          }
          disableFocusOut={false}
          dataUsageConsentObj={talent?.privacyPolicy?.dataConsent}
        ></PrivacyPolicy>
      )}
    </>
  );
}
