import { cloneDeep, isEmpty } from "lodash";
import { useCallback } from "react";
import { useFormInputValidator } from "../../../components/feature/form/inputValidators/useFormInputValidator";
import { AddressFormConfig, ContactNameForm } from "../information/types";
import { ProfileFormField } from "../types";

export const useValidateContactForm = () => {
  const { isValidName, isValidStreetCityDistrict, isValidCountryStateZipCode } =
    useFormInputValidator();
  const validateName = useCallback(
    (field: ProfileFormField) => {
      const nameField = {
        ...field,
        error: false,
        errori18nMsgKey: "",
        errori18nMsgParams: {},
      };
      if (isEmpty(nameField.value) && nameField.required) {
        nameField.error = true;
        nameField.errori18nMsgKey = nameField.requiredi18nMsgKey || "";
      }
      if (
        !isEmpty(nameField.value) &&
        !isValidName(nameField.value as string)
      ) {
        nameField.error = true;
        nameField.errori18nMsgKey = "jobsite.invalidCharactersError";
      }
      return nameField;
    },
    [isValidName],
  );

  const validateNameSection = useCallback(
    (
      contactForm: ContactNameForm,
      kanjiIndicator: boolean,
      localeNameIndicator: boolean,
    ) => {
      const updated = { ...contactForm };
      updated.firstName = validateName(updated.firstName);
      updated.lastName = validateName(updated.lastName);
      updated.preferredName = validateName(updated.preferredName);

      if (kanjiIndicator) {
        if (updated.kanjiFirstName) {
          updated.kanjiFirstName = validateName(updated.kanjiFirstName);
        }
        if (updated.kanjiLastName) {
          updated.kanjiLastName = validateName(updated.kanjiLastName);
        }
      }

      if (localeNameIndicator) {
        if (updated.localFirstName) {
          updated.localFirstName = validateName(updated.localFirstName);
        }
        if (updated.localLastName) {
          updated.localLastName = validateName(updated.localLastName);
        }
      }
      return {
        updatedContactForm: updated,
        hasError:
          updated.firstName.error ||
          updated.lastName.error ||
          updated.preferredName.error ||
          updated.kanjiFirstName?.error ||
          updated.kanjiLastName?.error ||
          updated.localFirstName?.error ||
          updated.localLastName?.error ||
          false,
      };
    },
    [validateName],
  );

  const validateAddressForm = useCallback(
    (addressForm: AddressFormConfig[]) => {
      let hasError = false;
      const validatedAddressForm = cloneDeep(addressForm);
      validatedAddressForm.forEach((formConfig) => {
        let fieldHasError = false;
        formConfig.country.error = false;

        fieldHasError = formConfig.country.error = isEmpty(
          formConfig.country.value,
        );
        formConfig.fields.forEach((configs) => {
          configs.forEach((config) => {
            config.error = false;
            config.errori18nMsgKey = "";
            config.errori18nMsgParams = {};

            if (config.optional !== true && isEmpty(config.value)) {
              config.error = true;
              config.errori18nMsgKey = config.requiredi18nMsgKey;
            }
            if (!isEmpty(config.value)) {
              const valueHasError =
                ((config.name === "line1" ||
                  config.name === "line2" ||
                  config.name === "line3" ||
                  config.name === "city" ||
                  config.name === "district") &&
                  !isValidStreetCityDistrict(config.value as string)) ||
                ((config.name === "state" ||
                  config.name === "county" ||
                  config.name === "zip") &&
                  !isValidCountryStateZipCode(config.value as string));
              if (valueHasError) {
                config.error = true;
                config.errori18nMsgKey = "jobsite.invalidCharactersError";
              }
            }
            if (config.error) {
              fieldHasError = true;
            }
          });
        });
        if (!hasError) {
          hasError = fieldHasError;
        }
      });
      return { updatedAddressForm: validatedAddressForm, hasError };
    },
    [isValidCountryStateZipCode, isValidStreetCityDistrict],
  );

  return {
    validateNameSection,
    validateAddressForm,
  };
};
