import React, { useCallback, useContext } from "react";
import { MappedTeams, SubTeamRefData } from "../../../shared/types/refData";
import {
  FILTER_TYPE,
  SET_FILTER,
  UNSET_FILTER,
} from "../../contexts/actionTypes";
import {
  FilterValue,
  SEARCH_FILTER_TYPE,
  SearchContext,
} from "../../contexts/SearchContext";
import useIsMobile from "../../hooks/useIsMobile";
import { FilterOption } from "./FilterCheckboxGroup";
import TeamsFilter from "./TeamsFilter";

type TeamFilterOption = SubTeamRefData & FilterOption;
interface TeamsFilterWrapperProps {
  teamsFilterData: Array<MappedTeams>;
  onMobileFilterChange?: (
    filterType: SEARCH_FILTER_TYPE,
    filterValue: FilterValue,
  ) => void;
}
export default function TeamsFilterWrapper({
  teamsFilterData,
  onMobileFilterChange,
}: TeamsFilterWrapperProps) {
  const { dispatch } = useContext(SearchContext);
  const isMobile = useIsMobile();

  const updateMobileFilters = useCallback(
    (filterType: SEARCH_FILTER_TYPE, filterValue: FilterValue) => {
      if (onMobileFilterChange) onMobileFilterChange(filterType, filterValue);
    },
    [onMobileFilterChange],
  );

  // dispatch a filter action in case of desktop / invoke mobileFilterChange callback incase of mobile
  const onRemoveTeams = useCallback(() => {
    if (!isMobile) {
      dispatch({
        type: UNSET_FILTER,
        filterName: FILTER_TYPE.TEAMS,
      });
    } else {
      updateMobileFilters(FILTER_TYPE.TEAMS as SEARCH_FILTER_TYPE, []);
    }
  }, [dispatch, isMobile, updateMobileFilters]);

  // dispatch a filter action in case of desktop / invoke mobileFilterChange callback incase of mobile
  const onApplyTeams = useCallback(
    (teams: Array<TeamFilterOption>) => {
      if (teams.length > 0) {
        if (!isMobile) {
          dispatch({
            type: SET_FILTER,
            filterName: FILTER_TYPE.TEAMS,
            payload: teams,
          });
        } else {
          updateMobileFilters(FILTER_TYPE.TEAMS as SEARCH_FILTER_TYPE, teams);
        }
      } else {
        if (!isMobile) onRemoveTeams();
        else updateMobileFilters(FILTER_TYPE.TEAMS as SEARCH_FILTER_TYPE, []);
      }
    },
    [dispatch, isMobile, onRemoveTeams, updateMobileFilters],
  );

  // dispatch a filter action in case of desktop / invoke mobileFilterChange callback incase of mobile
  const onUpdateTeams = useCallback(
    (teams: Array<TeamFilterOption>) => {
      if (teams.length > 0) {
        if (!isMobile) {
          dispatch({
            type: SET_FILTER,
            filterName: FILTER_TYPE.TEAMS,
            payload: teams,
          });
        } else {
          updateMobileFilters(FILTER_TYPE.TEAMS as SEARCH_FILTER_TYPE, teams);
        }
      } else {
        if (!isMobile) onRemoveTeams();
        else updateMobileFilters(FILTER_TYPE.TEAMS as SEARCH_FILTER_TYPE, []);
      }
    },
    [dispatch, isMobile, onRemoveTeams, updateMobileFilters],
  );

  return (
    <TeamsFilter
      teams={teamsFilterData}
      onApplyTeams={onApplyTeams}
      onUpdateTeams={onUpdateTeams}
      onRemoveTeams={onRemoveTeams}
    ></TeamsFilter>
  );
}
