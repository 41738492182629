import { Checkbox } from "@rpe-js/marcom-web-components";
import { AccordionItem } from "@rpe-js/marcom-web-components/lib/CustomAccordion/CustomAccordion";
import React, { useCallback, useEffect, useState } from "react";
import { MappedPostLocation } from "../../../shared/types/refData";
import { getPreferredLocationsByInput } from "../../api/fetchClient";
import TypeaheadComponent, {
  typeaheadstandardCssClasses,
} from "../../components/base/Typeahead/TypeaheadComponent";
import useIdGenerator from "../../hooks/useIdGenerator";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { getCheckboxClasses } from "../../util";
import { mergeSelectedIntoAll } from "../../utils/filterUtil";
import { idGenerator } from "../../utils/idGenerator";
import { FilterCheckboxGroup, FilterOption } from "./FilterCheckboxGroup";
import { MappedPostLocationFilterOptions } from "./LocationFilterWrapper";

interface LocationFilterProps {
  locationData: Array<MappedPostLocationFilterOptions>;
  homeOfficeData: boolean;
  selectedCount: number;
  onHomeOfficeChange: (isChecked: boolean) => void;
  onUpdateLocation: (updatedLocations: Array<FilterOption>) => void;
  onRemoveLocation: () => void;
}

const LocationFilter: React.FC<LocationFilterProps> = ({
  locationData,
  homeOfficeData,
  onUpdateLocation,
  onRemoveLocation,
  onHomeOfficeChange,
  selectedCount,
}) => {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const [locations, setLocations] = useState<MappedPostLocationFilterOptions[]>(
    [...locationData],
  );
  const [locationFilterCount, setLocationFilterCount] =
    useState<number>(selectedCount);
  const [homeOffice, setHomeOffice] = useState<boolean>(homeOfficeData);
  const ACCORDION_ID = idGenerator(
    "search",
    "location-filter-accordion",
  ).generateId();

  const updateFilterCount = useCallback(
    (selected: boolean) => {
      if (selected) {
        setLocationFilterCount(locationFilterCount + 1);
      } else {
        setLocationFilterCount(locationFilterCount - 1);
      }
    },
    [locationFilterCount],
  );

  const updateLocation = useCallback(
    (id: string, selected: boolean) => {
      updateFilterCount(selected);
      const updatedLocations = locations.map(
        (location: MappedPostLocationFilterOptions) =>
          location.id === id ? { ...location, selected } : location,
      );
      setLocations(updatedLocations);

      const selectedLocations = updatedLocations.filter(
        (location: MappedPostLocationFilterOptions) => location.selected,
      );
      if (selectedLocations.length == 0) {
        onRemoveLocation();
      } else {
        onUpdateLocation(selectedLocations);
      }
    },
    [locations, onRemoveLocation, onUpdateLocation, updateFilterCount],
  );

  const handleAddLocation = useCallback(
    (newLocation: MappedPostLocationFilterOptions) => {
      updateFilterCount(true);

      const updatedLocations = locations.map(
        (location: MappedPostLocationFilterOptions) =>
          location.id === newLocation.id
            ? { ...location, ...newLocation } // Update existing location
            : location,
      );

      // Check if the location already exists
      const isExisting = locations.some(
        (location: MappedPostLocationFilterOptions) =>
          location.id === newLocation.id,
      );

      // If not existing, add the new location
      if (!isExisting) {
        updatedLocations.push({ ...newLocation });
      }

      // Update state and invoke callback
      setLocations(updatedLocations);
      onUpdateLocation(
        updatedLocations.filter((location) => location.selected),
      );
    },
    [locations, onUpdateLocation, updateFilterCount],
  );

  useEffect(() => {
    const updatedLocations = [...mergeSelectedIntoAll(locations, locationData)];
    setLocations(updatedLocations);
    setLocationFilterCount(locationData.length + (homeOffice ? 1 : 0));
    // setFilters({
    //   location: updatedLocations.filter((location) => location.selected),
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData]); // Add locationData as a dependency to the useEffect hook

  // this effect triggers on change of homeOffice filter change and set the homeOffice query params
  useEffect(() => {
    if (homeOfficeData != homeOffice) {
      updateFilterCount(homeOfficeData === true ? true : false);
      setHomeOffice(homeOfficeData || false);
    }
    // setFilters({ homeOffice: (homeOfficeData || false).toString() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeOfficeData]);

  return (
    <>
      <AccordionItem
        titleWrapperTag={"h3"}
        titleWrapperTagAttrs={{
          className: "d-flex",
        }}
        title={`<span class="d-flex-equal fw-medium">${t("jobsite.common.location")}</span>
                <span class="d-inline-block mr-5 mt-0 ${locationFilterCount > 0 ? "counter" : "d-none"}" aria-hidden="true">${locationFilterCount}</span>
                <span class="a11y">${t("jobsite.common.filterAppliedCount", { count: locationFilterCount })}</span>`}
        clickableTitle={true}
        expandableIconsPlus={true}
        noPadding={true}
        index={0}
        id={ACCORDION_ID}
      >
        <div className="pb-20">
          <Checkbox
            required={false}
            id={useIdGenerator("filter", "location-home-office")}
            key={useIdGenerator("filter", "location-home-office")}
            label={t("jobsite.jobdetails.homeOffice") as string}
            checked={homeOffice}
            showTileView={isMobile}
            onValueChange={(checked) => {
              setHomeOffice(checked);
              updateFilterCount(checked);
              onHomeOfficeChange(checked);
            }}
            classes={getCheckboxClasses(isMobile)}
          />
        </div>

        <TypeaheadComponent
          elementToFocusOnClose="filter-mobile-modal-save-button"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          apiEndpoint={getPreferredLocationsByInput}
          disableSelectedItem={true}
          selectedItems={{ key: "name", items: locations as any }}
          onSelect={(e) => {
            handleAddLocation({
              ...(e as MappedPostLocation),
              selected: true,
            });
          }}
          hideLabel={true}
          removeOnSelect={true}
          getSuggestionLabel={(suggestion) => suggestion?.name}
          minChars={2}
          highlightMatches={true}
          showSearchIcon={!isMobile}
          placeholder={t("jobsite.search.enterLocation") as string}
          suggestionPlaceholder={
            t("jobsite.common.suggestedLocations") as string
          }
          isInputRequired={false}
          classNames={{
            ...typeaheadstandardCssClasses,
            list: "filter-typeahead-list",
            listItem: "filter-typeahead-list-item",
            button: "filter-typeahead-button",
            suggestionText: "filter-typeahead-suggestion-text",
            suggestionPlaceholder: "filter-typeahead-suggestion-placeholder",
            suggestionIcon: "filter-typeahead-suggestion-icon",
            highlightedItem: "filter-typeahead-highlighted-item",
          }}
          id={idGenerator("filter", "location-typeahead").generateId()}
          strict={true}
          resetA11y={t("jobsite.search.clearLocationVO") as string}
        />
        <FilterCheckboxGroup
          id={useIdGenerator("filter", "location")}
          filterList={locations}
          classNames={getCheckboxClasses(isMobile)}
          onFilterChange={updateLocation}
        ></FilterCheckboxGroup>
      </AccordionItem>
    </>
  );
};

export default LocationFilter;
