import React from "react";
import { Info } from "./Info";

type SeparatorComponentProps = {
  separator?: string;
  displayValue: (value: any) => string;
  data: any;
};

const SeparatorComponent = ({
  data,
  separator = ", ",
  displayValue,
}: SeparatorComponentProps) => {
  return (
    <div>
      {data?.map((value: any, index: number) => (
        <>
          <Info value={displayValue(value)} key={index} />
          {(data?.length as number) - 1 !== index && <span>{separator} </span>}
        </>
      ))}
    </div>
  );
};

export default SeparatorComponent;
