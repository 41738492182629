import React from "react";
import HtmlRenderer from "../../components/base/HtmlRenderer";
import useIsMobile from "../../hooks/useIsMobile";
import { idGenerator } from "../../utils/idGenerator";

interface Props {
  id: string;
  title: string;
  content: string;
}

function InternalJobDetails(props: Props) {
  const { content, id, title } = props;
  const isMobile = useIsMobile();
  const idGeneratorContainer = idGenerator("jobdetails", id);

  function renderContent() {
    return (
      <div
        id={idGeneratorContainer.generateId()}
        className={`${isMobile ? "" : "py-40 background-fill-tertiary"} row text-left `}
      >
        {!isMobile ? (
          <>
            <aside className={"column large-3 px-10"}>
              {title && (
                <h2
                  id={idGeneratorContainer.generateId("internalcontenttitle")}
                  className={"t-label word-wrap-break-word fw-medium"}
                >
                  {title}
                </h2>
              )}
            </aside>
            <div className={"column large-9"}>
              <HtmlRenderer
                htmlTag="span"
                initialContent={content}
                classes="t-body white-space-pre-wrap"
                id={idGeneratorContainer.generateId("internalcontent")}
              />
            </div>
          </>
        ) : (
          <div className={`column large-12 u-border-bottom`}>
            <aside
              id={idGeneratorContainer.generateId("title")}
              className={`column small-12 py-15 ${isMobile ? "" : "px-10"} `}
            >
              {title && (
                <h2
                  id={idGeneratorContainer.generateId("titleheading")}
                  className={`t-label word-wrap-break-word fw-medium`}
                >
                  {title}
                </h2>
              )}
            </aside>
            <div className={"column small-12 pb-30"}>
              <HtmlRenderer
                initialContent={content}
                classes="t-body"
                id={idGeneratorContainer.generateId("internalcontent")}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  return content ? renderContent() : null;
}

export default InternalJobDetails;
