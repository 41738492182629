import dateUtil from "@rpe-js/core/dist/util/dateUtil";
import { Button } from "@rpe-js/marcom-web-components";
import React from "react";
import { downloadFile } from "../../api/fetchClient";
import { useCurrentUserContext } from "../../CurrentUserContext";
import useIntlMessage from "../../hooks/useIntlMessage";
import { FileData } from "../../types/File";
import { LinkedInData } from "../../types/LinkedIn";
import { idGenerator } from "../../utils/idGenerator";
import Icon from "./IconComponent";

interface ResumeOrLinkedinFileViewProps {
  resume: FileData | null;
  textResume: FileData | null;
  linkedIn?: LinkedInData | null;
  locale: string;
  noResultsLabel: string;
  viewType: "resume" | "linkedin";
}

export function ResumeOrLinkedinFileView({
  resume,
  textResume,
  linkedIn,
  locale,
  noResultsLabel,
  viewType,
}: ResumeOrLinkedinFileViewProps) {
  const { currentUser } = useCurrentUserContext();
  const { t } = useIntlMessage();

  return (
    <>
      <div className="d-flex align-center">
        {viewType === "resume" && (
          <>
            <span aria-hidden={true}>
              <Icon name={"resume"} size={"xlarge"}></Icon>
            </span>
            {resume && resume.fileId && (
              <>
                <div className="row flex-column ml-10">
                  <p className="saved-resume-file">
                    <Button
                      id={idGenerator("resume", "downloadfile").generateId()}
                      label={resume.name}
                      size="base"
                      onClick={() => {
                        downloadFile(
                          currentUser.talentId as string,
                          resume.fileId as string,
                          resume.name as string,
                          resume.bucketId as string,
                        );
                      }}
                      className="pl-0"
                    ></Button>
                  </p>
                  <p className="mt-0 file-info label-grey">
                    {t("jobsite.common.resumeUploaded", {
                      date: dateUtil.formatDateTimeByLocaleUtil(
                        (resume && resume.createdTime) as string,
                        locale,
                      ),
                    })}
                  </p>
                </div>
              </>
            )}
            {resume && !resume.fileId && resume.name && (
              <div className="row flex-column ml-10">
                <span className="file-info">{resume.name}</span>
                <p className="file-info label-grey">
                  {t("jobsite.common.resumeUploaded", {
                    date: dateUtil.formatDateTimeByLocaleUtil(
                      (resume && resume.createdTime) as string,
                      locale,
                    ),
                  })}
                </p>
              </div>
            )}
            {textResume && textResume.name && (
              <div className="row flex-column ml-10">
                <span className="file-info">{textResume.name}</span>
                <p className="file-info label-grey">
                  {t("jobsite.common.resumeUploaded", {
                    date: dateUtil.formatDateTimeByLocaleUtil(
                      (textResume && textResume.createdTime) as string,
                      locale,
                    ),
                  })}
                </p>
              </div>
            )}
            {!resume && (!textResume || !textResume.name) && (
              <span className="ml-10">{noResultsLabel}</span>
            )}
          </>
        )}

        {viewType === "linkedin" && (
          <>
            <span aria-hidden={true}>
              <Icon width="53" height="53" name={"linkedin-review"}></Icon>
            </span>
            <span className="ml-10">
              {linkedIn && (
                <>
                  <p>
                    <a href={linkedIn.url} target="_blank" rel="noreferrer">
                      {linkedIn.url}
                    </a>
                  </p>
                  <p className="mt-0">
                    {t("jobsite.common.lastUpdatedDate", {
                      date: dateUtil.formatDateTimeByLocaleUtil(
                        linkedIn.addedOn as string,
                        locale,
                      ),
                    })}
                  </p>
                </>
              )}
              {!linkedIn && <span>{noResultsLabel}</span>}
            </span>
          </>
        )}
      </div>
    </>
  );
}
