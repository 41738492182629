import React, { useContext, useEffect } from "react";
import sanitizeHtml from "sanitize-html";
import { sanitizeOptions } from "../../app.constants";
import AppContext from "../../AppContext";

interface HtmlRendererProps {
  initialContent: string;
  classes?: string; //To add classes to the corresponding tag
  htmlTag?: "p" | "span" | "div";
  id?: string;
}

const HtmlRenderer: React.FC<HtmlRendererProps> = ({
  initialContent,
  classes,
  htmlTag,
  id,
}) => {
  const tag = htmlTag || "div";
  const { appUIState } = useContext(AppContext);
  const { disableSanitizeHtml } = appUIState.appData;

  useEffect(() => {
    // Optionally update content with client-side only logic if needed
    // setContent(newContent);
  }, []);

  return (
    <>
      {tag === "div" && (
        <div
          {...(id ? { id } : {})}
          className={classes}
          dangerouslySetInnerHTML={{
            __html: disableSanitizeHtml
              ? initialContent
              : sanitizeHtml(initialContent, sanitizeOptions),
          }}
        />
      )}
      {tag === "p" && (
        <p
          {...(id ? { id } : {})}
          className={classes}
          dangerouslySetInnerHTML={{
            __html: disableSanitizeHtml
              ? initialContent
              : sanitizeHtml(initialContent, sanitizeOptions),
          }}
        />
      )}
      {tag === "span" && (
        <span
          {...(id ? { id } : {})}
          className={classes}
          dangerouslySetInnerHTML={{
            __html: disableSanitizeHtml
              ? initialContent
              : sanitizeHtml(initialContent, sanitizeOptions),
          }}
        />
      )}
    </>
  );
};

export default HtmlRenderer;
