import { Overlay } from "@rpe-js/marcom-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { getVeteranStatusTemplateForm } from "../../../api/fetchClient";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { idGenerator } from "../../../utils/idGenerator";
import HtmlRenderer from "../../base/HtmlRenderer";

interface VeteranStatusModalProps {
  id: string;
  locale: string;
  showModal: boolean;
  closeModal: () => void;
  triggeredButtonId: string;
}
export function VeteranStatusModal({
  id,
  locale,
  showModal,
  closeModal,
  triggeredButtonId,
}: VeteranStatusModalProps) {
  const { t } = useIntlMessage();
  const [veteranStatusContent, setVeteranStatusContent] = useState<string>("");
  const isMobile = useIsMobile();
  const VETERAN_MODAL_TITLE_ID = idGenerator(
    "selfdisclosure",
    "veteran-modal",
  ).generateId("title");

  const getVeteranStatusForm = useCallback(async () => {
    const veteranStatusForm = await getVeteranStatusTemplateForm({
      headers: { locale },
    });
    setVeteranStatusContent(veteranStatusForm?.name || "");
  }, [locale]);

  useEffect(() => {
    if (!veteranStatusContent) getVeteranStatusForm();
  }, [getVeteranStatusForm, veteranStatusContent]);

  return (
    <>
      {veteranStatusContent && (
        <section>
          <Overlay
            id={id}
            wide={true}
            visible={showModal}
            onClose={closeModal}
            isFullscreen={isMobile}
            closeButtonAttrs={{
              ariaLabel: t("jobsite.common.close") as string,
            }}
            elementIdToFocus={triggeredButtonId}
            ariaLabel={VETERAN_MODAL_TITLE_ID}
            disableClickAway={true}
          >
            <div className="modal-content">
              <h2
                className={"t-eyebrow-elevated mb-20 text-center"}
                id={VETERAN_MODAL_TITLE_ID}
              >
                {t("jobsite.common.protectedVeteranHeading")}
              </h2>
              <HtmlRenderer
                initialContent={veteranStatusContent}
              ></HtmlRenderer>
            </div>
          </Overlay>
        </section>
      )}
    </>
  );
}
