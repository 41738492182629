import { Overlay } from "@rpe-js/marcom-web-components";
import React, { useContext, useMemo } from "react";
import AppContext from "../../../AppContext";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { idGenerator } from "../../../utils/idGenerator";
import HtmlRenderer from "../../base/HtmlRenderer";

interface GenderModalProps {
  id: string;
  showModal: boolean;
  isUSAIndicator: boolean;
  closeModal: () => void;
  triggeredButtonId: string;
}
export function GenderModal({
  id,
  showModal,
  isUSAIndicator,
  closeModal,
  triggeredButtonId,
}: GenderModalProps) {
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { isExternal } = appUIState.appData;
  const isMobile = useIsMobile();
  const GENDER_MODAL_TITLE_ID = idGenerator(
    "selfdisclosure",
    "gender-modal",
  ).generateId("title");

  const genderModal = useMemo(() => {
    let genderModalContent = "";
    if (isUSAIndicator) {
      if (isExternal) {
        genderModalContent = t(
          "jobsite.common.genderUSHelpDescription",
        ) as string;
      } else {
        genderModalContent = t(
          "jobsite.common.internalGenderUSHelpDescription",
        ) as string;
      }
    } else {
      if (isExternal) {
        genderModalContent = t(
          "jobsite.common.genderHelpDescription",
        ) as string;
      } else {
        genderModalContent = t(
          "jobsite.common.internalGenderHelpDescription",
        ) as string;
      }
    }
    return genderModalContent;
  }, [isExternal, isUSAIndicator, t]);

  return (
    <section>
      <Overlay
        id={id}
        wide={true}
        visible={showModal}
        onClose={closeModal}
        isFullscreen={isMobile}
        closeButtonAttrs={{
          ariaLabel: t("jobsite.common.close") as string,
        }}
        elementIdToFocus={triggeredButtonId}
        ariaLabel={GENDER_MODAL_TITLE_ID}
        disableClickAway={true}
      >
        <>
          <h2
            className={"t-eyebrow-elevated mb-20 text-center"}
            id={GENDER_MODAL_TITLE_ID}
          >
            {t("jobsite.common.genderHelpHeading")}
          </h2>
          <HtmlRenderer initialContent={genderModal}></HtmlRenderer>
        </>
      </Overlay>
    </section>
  );
}
