import {
  DropDownOptionProps,
  Overlay,
  ProgressIndicatorLoader,
} from "@rpe-js/marcom-web-components";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  TalentQuestion,
  TalentQuestionAnswer,
  TalentQuestionnaire,
  TalentQuestionnaireAnswer,
} from "../../../../shared/types/questionnaire";
import {
  getEditQuestionnaireWithAnswer,
  getQuestionnaireAns,
} from "../../../api/fetchClient";
import AppContext from "../../../AppContext";
import { AlertWrapper } from "../../../components/base/AlertWrapper";
import EditQuestionnaireView, {
  QuestionAnswerResult,
} from "../../../components/feature/EditQuestionnaireView";
import { QuestionnaireSection } from "../../../components/feature/reviewInfo/QuestionnaireSection";
import { useFetchData } from "../../../hooks/useFetchData";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { idGenerator } from "../../../utils/idGenerator";
import { useRolesContext } from "./RolesContext";
import { RoleUIObj } from "./types";

interface QuestionaireModalProps {
  onClose: () => void;
  onSubmit: (payload: any) => void;
  role: RoleUIObj;
  talentId: string;
}

export function QuestionaireModal(props: QuestionaireModalProps) {
  const [questionnaireAns, setQuestionnaireAns] =
    useState<TalentQuestionnaireAnswer | null>(null);
  const [editQuestionnaireAns, setEditQuestionnaireAns] =
    useState<TalentQuestionnaire | null>(null);
  const [questions, setQuestions] = useState<TalentQuestion[] | null>(null);
  const { appUIState } = useContext(AppContext);
  const { countryCode } = appUIState.appData;
  const [alert, setAlert] = useState(false);
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const { dispatch } = useRolesContext();
  const questionnaireEditContentId = idGenerator(
    "yourroles",
    "editquestionnaire",
  ).generateId();
  const questionnaireFooterId = idGenerator(
    "yourroles",
    "editquestionnairefooter",
  ).generateId();
  const {
    fetchData: fetchQuestionnaireAns,
    isLoading: isFetchQuestionnaireAnsLoading,
    isSuccess: isFetchQuestionnaireAnsSuccess,
    data: questionnaireAnsData,
    isError: isFetchQuestionnaireAnsError,
  } = useFetchData(getQuestionnaireAns);

  const {
    fetchData: fetchEditQuestionnaireAns,
    isLoading: isFetchEditQuestionnaireAnsLoading,
    isSuccess: isFetchEditQuestionnaireAnsSuccess,
    data: editQuestionnaireAnsData,
    isError: isFetchEditQuestionnaireAnsError,
  } = useFetchData(getEditQuestionnaireWithAnswer);

  const updateFreeTextAnswer = (result: QuestionAnswerResult) => {
    const questionnaireCopy = editQuestionnaireAns?.questions;
    questionnaireCopy;
    setQuestions((prevQuestions) =>
      (prevQuestions as TalentQuestion[])?.map((question: TalentQuestion) => {
        if (question.questionId === result.questionId) {
          return {
            ...question,
            answeredFreeText: result.answer,
          };
        }
        return question;
      }),
    );
  };

  const updateRankedAnswer = useCallback(
    (result: {
      questionId: string;
      answer: { answerId: string; rank: DropDownOptionProps };
    }) => {
      setQuestions((prevQuestions) =>
        (prevQuestions as TalentQuestion[])?.map((question: TalentQuestion) => {
          if (question.questionId === result.questionId) {
            if (!question.answerOptions) {
              return { ...question, answered: false };
            }
            return {
              ...question,
              answered: true,
              answerOptions: question.answerOptions.map(
                (answer: TalentQuestionAnswer) => {
                  if (
                    !result.answer ||
                    answer.answerId != result.answer.answerId ||
                    !result.answer.rank ||
                    isNaN(parseInt(result.answer.rank.label))
                  ) {
                    return { ...answer };
                  }
                  return {
                    ...answer,
                    answeredRank: parseInt(result.answer.rank.label),
                  };
                },
              ),
            };
          }
          return question;
        }),
      );
    },
    [],
  );

  const updateAnswer = (result: QuestionAnswerResult) => {
    setQuestions((prevQuestions) =>
      (prevQuestions as TalentQuestion[])?.map((question: TalentQuestion) => {
        if (question.questionId === result.questionId) {
          return {
            ...question,
            answerOptions: question.answerOptions?.map(
              (answer: TalentQuestionAnswer) => {
                if (question.possibleAnswers && question.possibleAnswers > 1) {
                  if (answer.answerId == result.answer) {
                    return { ...answer, answeredMultiple: result.answered };
                  } else {
                    return {
                      ...answer,
                      answeredMultiple: answer.answeredMultiple || false,
                    };
                  }
                } else {
                  if (answer.answerId == result.answer) {
                    return { ...answer, answeredMultiple: true };
                  } else {
                    return { ...answer, answeredMultiple: false };
                  }
                }
              },
            ),
          };
        }
        return question;
      }),
    );
  };

  const onSubmit = () => {
    dispatch({
      type: "UPDATE_ROLE_TO_RESET",
      payload: props.role.connectionID,
    });
    const payload = {
      questionnaireId: editQuestionnaireAns?.id,
      talentId: props.talentId,
      positionId: props.role.jobPositionID,
      connectionId: props.role.connectionID,
      locale: countryCode?.data,
      title: editQuestionnaireAns?.title,
      version: editQuestionnaireAns?.version,
      resetFlag: false,
      questions,
    };
    props.onSubmit(payload);
  };

  // useEffect to fetch questionnaire data
  useEffect(() => {
    if (props.role?.reset) {
      fetchEditQuestionnaireAns([
        props.talentId,
        props.role?.connectionID,
        countryCode?.data,
        props.role.questionnaireTemplateID,
      ]);
    } else {
      fetchQuestionnaireAns([
        props.talentId,
        props.role?.connectionID,
        countryCode?.data,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect to set questionnaire data if API call is successful
  useEffect(() => {
    if (!isFetchQuestionnaireAnsLoading && isFetchQuestionnaireAnsSuccess) {
      setQuestionnaireAns(questionnaireAnsData as TalentQuestionnaireAnswer);
    }
    if (
      !isFetchEditQuestionnaireAnsLoading &&
      isFetchEditQuestionnaireAnsSuccess
    ) {
      setEditQuestionnaireAns(editQuestionnaireAnsData as TalentQuestionnaire);
      setQuestions(editQuestionnaireAnsData?.questions as TalentQuestion[]);
    }
    if (
      !isFetchQuestionnaireAnsLoading &&
      !isFetchEditQuestionnaireAnsLoading &&
      (isFetchQuestionnaireAnsError || isFetchEditQuestionnaireAnsError)
    ) {
      setAlert(true);
    }
  }, [
    editQuestionnaireAnsData,
    isFetchEditQuestionnaireAnsError,
    isFetchEditQuestionnaireAnsLoading,
    isFetchEditQuestionnaireAnsSuccess,
    isFetchQuestionnaireAnsError,
    isFetchQuestionnaireAnsLoading,
    isFetchQuestionnaireAnsSuccess,
    questionnaireAnsData,
  ]);

  return (
    <Overlay
      id={idGenerator("yourroles", "questionnairemodal").generateId()}
      visible={true}
      wide={true}
      onClose={props.onClose}
      isFullscreen={isMobile}
      closeButtonAttrs={{
        ariaLabel: t("jobsite.common.close") as string,
      }}
      elementIdToFocus={`js-questionnaire-link-${props.role.positionID}`}
      ariaLabel={idGenerator("questionaire", "modal").generateId("header")}
    >
      <div>
        <h2
          id={idGenerator("questionaire", "modal").generateId("header")}
          className="a11y"
        >
          {t("jobsite.profile.yourRoles.questionnaire")}
        </h2>
        {alert && (
          <AlertWrapper
            id={idGenerator("questionaire", "modal").generateId("alert")}
            message={t("jobsite.common.serviceError") as string}
            remove={{
              onRemove: () => setAlert(false),
              closeBtnAriaLabel: t("jobsite.common.closeAlert") as string,
            }}
            closePosition="right"
            classes="yellow-alert"
          />
        )}
        <div className="text-center mb-40">
          {props.role?.reset ? (
            <>
              <p>{editQuestionnaireAns?.title}</p>
              <div className="t-eyebrow-reduced u-border-bottom pb-20">
                {t("jobsite.profile.yourRoles.questionnaire")}
              </div>
            </>
          ) : (
            <>
              {questionnaireAns?.title && (
                <h2 className="t-eyebrow-reduced fw-medium">
                  {questionnaireAns?.title}
                </h2>
              )}
              <h2 className="t-body">
                {t("jobsite.profile.yourRoles.yourResponse")}
              </h2>
            </>
          )}
        </div>
        {!(
          isFetchQuestionnaireAnsLoading || isFetchEditQuestionnaireAnsLoading
        ) ? (
          <>
            {!props.role?.reset ? (
              <QuestionnaireSection
                questions={questionnaireAns?.questions as TalentQuestion[]}
                rolesSection={true}
              />
            ) : (
              <EditQuestionnaireView
                contentId={questionnaireEditContentId}
                footerId={questionnaireFooterId}
                updateAnswer={updateAnswer}
                updateFreeTextAnswer={updateFreeTextAnswer}
                updateRankedAnswer={updateRankedAnswer}
                questionList={questions as TalentQuestion[]}
                showBackButton={true}
                onCancel={props.onClose}
                onSave={onSubmit}
                cancelLabel="jobsite.common.cancel"
                saveLabel="jobsite.profile.yourRoles.submitNow"
                freeTextErrorMessage="jobsite.apply.enterAnswer"
                singleSelectErrorMessage="jobsite.common.chooseOption"
                multiSelectErrorMessage="jobsite.common.selectOneOption"
                multiSelectPossibleAnswersErrorMessage="jobsite.profile.selectOnlyPossibleAnswers"
                requiredRankErrorMessage="jobsite.common.chooseOption"
                selectCorrectRankErrorMessage="jobsite.profile.yourRoles.selectCorrectRank"
              />
            )}
          </>
        ) : (
          <ProgressIndicatorLoader showLoading={true} curtain={false} />
        )}
      </div>
    </Overlay>
  );
}
