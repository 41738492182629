import React, { createContext, ReactNode, useContext, useEffect } from "react";
import { useLocation } from "react-router";
import { AlertWrapper } from "../components/base/AlertWrapper";
import { useAlertState } from "../hooks/useAlertState";
import { idGenerator } from "../utils/idGenerator";

export interface PageAlertContextType {
  updateAlert: (
    isError?: boolean,
    hideChildren?: boolean,
    message?: string,
  ) => void;
  deleteAlert: () => void;
}

interface PageAlertContextProviderProps {
  children: ReactNode;
}

export const PageAlertContext = createContext<PageAlertContextType>({
  updateAlert: () => {},
  deleteAlert: () => {},
});

export const usePageAlertContext = (): PageAlertContextType => {
  const context = useContext(PageAlertContext);
  if (!context) {
    throw new Error(
      "usePageAlertContext must be used within a PageAlertContextProvider",
    );
  }
  return context;
};

export const PageAlert = ({ children }: PageAlertContextProviderProps) => {
  const { alert, isError, hideChildren, updateAlert, deleteAlert } =
    useAlertState();
  const location = useLocation();

  useEffect(() => {
    deleteAlert();
  }, [deleteAlert, location]);

  return (
    <PageAlertContext.Provider
      value={{
        updateAlert,
        deleteAlert,
      }}
    >
      {alert && (
        <AlertWrapper
          id={idGenerator("page", "alert").generateId()}
          error={isError}
          alignCenter={false}
        >
          <span className="red">{alert}</span>
        </AlertWrapper>
      )}
      {!hideChildren && children}
    </PageAlertContext.Provider>
  );
};
