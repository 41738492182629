import React, { useCallback, useEffect, useState } from "react";
import {
  Config,
  LinkedInProfilePreview,
} from "../../../../shared/types/config";
import {
  AWLIWIDGET,
  AWLIWIDGET_TYPE,
  DATA_ALLOW_SIGN_IN,
  DATA_API_KEY,
  DATA_CALLBACK_METHOD,
  DATA_COMPANY_JOB_CODE,
  DATA_INTEGRATION_CONTEXT,
  DATA_MODE,
  DATA_SIZE,
  SCRIPT,
  SRC,
  TYPE,
} from "../../../app.constants";

interface LinkedInUploadWidgetProps {
  config: Config;
  uploadLinkedIn: (linkedInData: LinkedInProfilePreview) => void;
}

export function LinkedInUploadWidget({
  config,
  uploadLinkedIn,
}: LinkedInUploadWidgetProps) {
  const [scriptsLoaded, setScriptsLoaded] = useState(false);

  const loadAWLIWidget = useCallback(() => {
    window.linkedInCallback = (data: LinkedInProfilePreview) => {
      uploadLinkedIn(data);
    };
    document.getElementById(AWLIWIDGET)?.remove();
    const script = document.createElement(SCRIPT);
    script.id = AWLIWIDGET;
    script.type = AWLIWIDGET_TYPE;
    script.setAttribute(DATA_API_KEY, config.linkedIn.apiKey);
    script.setAttribute(DATA_ALLOW_SIGN_IN, "true");
    script.setAttribute(DATA_COMPANY_JOB_CODE, config.linkedIn.companyJobCode);
    script.setAttribute(
      DATA_INTEGRATION_CONTEXT,
      `urn:li:organization:${config.linkedIn.companyID}`,
    );
    script.setAttribute(DATA_MODE, "BUTTON_DATA");
    script.setAttribute(DATA_SIZE, "large");
    script.setAttribute(DATA_CALLBACK_METHOD, "linkedInCallback");
    if (document.getElementById("linkedInWidget")) {
      document.getElementById("linkedInWidget")?.appendChild(script);
    }

    return script;
  }, [
    config.linkedIn.apiKey,
    config.linkedIn.companyID,
    config.linkedIn.companyJobCode,
    uploadLinkedIn,
  ]);

  const loadLinkedInIntegrationScript = useCallback(() => {
    document.getElementById("linkedInIntegrationScript")?.remove();
    const script = document.createElement("script");
    script.id = "linkedInIntegrationScript";
    script.setAttribute(TYPE, "text/javascript");
    script.setAttribute(SRC, config.linkedIn.url);
    if (document.getElementById("linkedInWidget")) {
      document.getElementById("linkedInWidget")?.appendChild(script);
    }
    return script;
  }, [config.linkedIn.url]);

  const loadWidgets = useCallback(() => {
    setTimeout(() => {
      loadAWLIWidget();
      loadLinkedInIntegrationScript();
      setScriptsLoaded(true);
    }, 100);
  }, [loadAWLIWidget, loadLinkedInIntegrationScript]);

  useEffect(() => {
    if (!scriptsLoaded) {
      loadWidgets();
    }
  }, [
    scriptsLoaded,
    loadAWLIWidget,
    loadLinkedInIntegrationScript,
    loadWidgets,
  ]);

  return (
    <section
      id="linkedInWidget"
      className="linkedin-widget pt-30 pb-30 pl-15 pr-15 text-center"
    ></section>
  );
}
