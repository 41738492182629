import {
  Overlay,
  ProgressIndicatorLoader,
} from "@rpe-js/marcom-web-components";
import React, { useCallback, useEffect } from "react";
import { SavedSearchType } from "../../../../shared/types";
import {
  deleteSavedSearch,
  getJobAgentEmailExperience,
  getJobAgentEmailFrequency,
  getSavedSearches,
} from "../../../api/fetchClient";
import { MessageContainer } from "../../../components/feature/messageContainer/MessageContainer";
import { SaveCancelAction } from "../../../components/feature/saveAndCancel";
import { useAppAlertContext } from "../../../contexts/AppAlert";
import { usePageAlertContext } from "../../../contexts/PageAlert";
import { useCurrentUserContext } from "../../../CurrentUserContext";
import { useFetchData } from "../../../hooks/useFetchData";
import { useFetchMultipleData } from "../../../hooks/useFetchMultipleData";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { idGenerator } from "../../../utils/idGenerator";
import {
  setEmailRefData,
  setRefreshData,
  setSavedSearches,
  updateEmailAlertModalId,
  updateSearchToRemove,
  updateShowEmailAlertModal,
  updateShowRemoveSearchModal,
} from "./context/Actions";
import { useSavedSearchesContext } from "./context/SavedSearchesContext";
import SavedSearchesLeftPanel from "./SavedSearchesLeftPanel";
import SavedSearchesRightPanel from "./SavedSearchesRightPanel";

export function SavedSearchesLayout() {
  const {
    isLoading: isGetSearchesLoading,
    isSuccess: isGetSearchesSuccess,
    fetchData: fetchSavedSearches,
    data: savedSearchesData,
    isError: getSavedSearchesError,
  } = useFetchMultipleData([
    getSavedSearches,
    getJobAgentEmailExperience,
    getJobAgentEmailFrequency,
  ]);
  const {
    isLoading: isDeleteSearchLoading,
    isSuccess: isDeleteSearchSuccess,
    fetchData: deleteSearch,
    isError: isDeleteSearchError,
  } = useFetchData(deleteSavedSearch);
  const {
    isLoading: isRefreshDataLoading,
    isSuccess: isRefreshDataSuccess,
    fetchData: refreshSearchData,
    data: refreshedData,
    isError: isRefreshDataError,
  } = useFetchData(getSavedSearches);
  const { currentUser } = useCurrentUserContext();
  const emailAlertModalId = idGenerator(
    "savedsearches",
    "emailalaertmodal",
  ).generateId();
  const removeSearchModalId = idGenerator(
    "savedsearches",
    "removesearchmodal",
  ).generateId();
  const {
    state: {
      savedSearches,
      showEmailAlertModal,
      showRemoveSearchModal,
      searchToRemove,
      refreshData,
      emailAlertModalForId,
    },
    dispatch,
  } = useSavedSearchesContext();
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const { updateAlert } = usePageAlertContext();
  const { updateAlert: updateAppAlert, deleteAlert: deleteAppAlert } =
    useAppAlertContext();
  const SAVED_SEARCHES_EMAIL_MODAL_TITLE_ID = idGenerator(
    "savedsearches",
    "email-modal",
  ).generateId("title");
  const SAVED_SEARCHES_CONFIRMATION_MODAL_TITLE_ID = idGenerator(
    "savedsearches",
    "confirmation-modal",
  ).generateId("title");

  const onRemoveClick = useCallback(
    (search: SavedSearchType) => {
      dispatch(updateSearchToRemove(search));
      dispatch(updateShowRemoveSearchModal(true));
    },
    [dispatch],
  );

  const onEmailInfoClick = useCallback(
    (searchId: string) => {
      dispatch(updateShowEmailAlertModal(true));
      dispatch(updateEmailAlertModalId(searchId));
    },
    [dispatch],
  );

  const onRemoveModalClose = useCallback(() => {
    dispatch(updateSearchToRemove(null));
    dispatch(updateShowRemoveSearchModal(false));
  }, [dispatch]);

  const onRemoveSearchConfirm = useCallback(() => {
    deleteAppAlert();
    deleteSearch([currentUser?.talentId, searchToRemove?.id]);
    dispatch(updateShowRemoveSearchModal(false));
  }, [
    currentUser?.talentId,
    deleteAppAlert,
    deleteSearch,
    dispatch,
    searchToRemove?.id,
  ]);

  // To fetch saved searches and refData
  useEffect(() => {
    fetchSavedSearches([[currentUser?.talentId]]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.talentId]);

  // To set saved searches and refData once API call is successful
  useEffect(() => {
    if (!isGetSearchesLoading && isGetSearchesSuccess) {
      dispatch(setSavedSearches(savedSearchesData[0] as SavedSearchType[]));
      dispatch(
        setEmailRefData({
          jobAgentEmailExperience: savedSearchesData[1],
          jobAgentEmailFrequency: savedSearchesData[2],
        }),
      );
    }
    if (!isGetSearchesLoading && getSavedSearchesError) {
      updateAlert(true, true, t("jobsite.error.talentNotFound") as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isGetSearchesLoading,
    isGetSearchesSuccess,
    savedSearchesData,
    getSavedSearchesError,
  ]);

  // To dispatch actions once delete saved searches API call is successful
  useEffect(() => {
    if (!isDeleteSearchLoading && isDeleteSearchSuccess) {
      const searchesCopy = savedSearches?.filter(
        (search) => search.id !== searchToRemove?.id,
      );
      dispatch(setSavedSearches(searchesCopy));
      dispatch(updateShowRemoveSearchModal(false));
      dispatch(updateSearchToRemove(null));
    }
    if (!isDeleteSearchLoading && isDeleteSearchError) {
      updateAppAlert(false);
      dispatch(updateSearchToRemove(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteSearchLoading, isDeleteSearchSuccess, isDeleteSearchError]);

  // To refresh saved searches data if refreshData is set to true
  useEffect(() => {
    if (refreshData) {
      refreshSearchData([currentUser?.talentId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  // To set saved searches once data is refreshed
  useEffect(() => {
    if (!isRefreshDataLoading && isRefreshDataSuccess) {
      dispatch(setSavedSearches(refreshedData as SavedSearchType[]));
      dispatch(setRefreshData(false));
    }
    if (!isRefreshDataLoading && isRefreshDataError) {
      updateAppAlert(false);
      dispatch(setRefreshData(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshDataLoading, isRefreshDataSuccess, isRefreshDataError]);

  return (
    <>
      <div className={`mt-60${!isMobile ? " u-border-top" : ""}`}>
        {(isGetSearchesLoading ||
          isRefreshDataLoading ||
          isDeleteSearchLoading) && (
          <ProgressIndicatorLoader showLoading={true} />
        )}
        {savedSearches?.length === 0 &&
          !isGetSearchesLoading &&
          !isRefreshDataLoading && (
            <MessageContainer
              message={
                <h2 className="t-tout">
                  {t("jobsite.savedsearch.content1") as string}
                </h2>
              }
              subText={t("jobsite.savedsearch.content2") as string}
              linkText={t("jobsite.profile.yourRoles.searchRoles") as string}
              link="search"
            />
          )}
        {savedSearches?.length > 0 &&
          !isGetSearchesLoading &&
          savedSearches?.map(
            (search, index: number) =>
              index < 10 && (
                <div
                  className={`row mt-20 pb-20 u-border-bottom${isMobile ? " mb-50" : ""}`}
                  key={index}
                >
                  <div
                    className={`column large-3 small-12 mr-20 d-flex align-items-start ${isMobile ? "flex-row justify-between u-border-bottom mb-20 pb-10" : "flex-column"}`}
                  >
                    <SavedSearchesLeftPanel
                      url={search.url}
                      name={search.searchName}
                      id={search.id}
                      onRemove={() => onRemoveClick(search)}
                    />
                  </div>
                  <div className="column large-8 small-12">
                    <SavedSearchesRightPanel
                      onEmailInfoClick={() => onEmailInfoClick(search.id)}
                      onRemove={() => onRemoveClick(search)}
                      search={search}
                    />
                  </div>
                </div>
              ),
          )}
      </div>
      {showEmailAlertModal && (
        <Overlay
          id={emailAlertModalId}
          visible={showEmailAlertModal}
          wide={true}
          isFullscreen={isMobile}
          onClose={() => dispatch(updateShowEmailAlertModal(false))}
          closeButtonAttrs={{
            ariaLabel: t("jobsite.common.close") as string,
          }}
          elementIdToFocus={idGenerator("savedsearches", "edit").generateId(
            `email-info-button-${emailAlertModalForId}`,
          )}
          ariaLabel={SAVED_SEARCHES_EMAIL_MODAL_TITLE_ID}
        >
          <section>
            <h2
              className="text-center t-intro"
              id={SAVED_SEARCHES_EMAIL_MODAL_TITLE_ID}
            >
              {t("jobsite.savedsearch.emailalerts")}
            </h2>
            <p>{t("jobsite.savedsearch.emailalertText")}</p>
          </section>
        </Overlay>
      )}
      {showRemoveSearchModal && (
        <Overlay
          id={removeSearchModalId}
          visible={showRemoveSearchModal}
          wide={false}
          isFullscreen={isMobile}
          onClose={() => dispatch(updateShowRemoveSearchModal(false))}
          closeButtonAttrs={{
            ariaLabel: t("jobsite.common.close") as string,
          }}
          elementIdToFocus={searchToRemove ? `remove-${searchToRemove.id}` : ""}
          ariaLabel={SAVED_SEARCHES_CONFIRMATION_MODAL_TITLE_ID}
        >
          <section>
            <h2
              className="text-center t-body"
              id={SAVED_SEARCHES_CONFIRMATION_MODAL_TITLE_ID}
            >
              {t("jobsite.savedsearch.deletetext", {
                savedsearch: searchToRemove?.searchName,
              })}
            </h2>
            <div className="d-flex justify-center">
              <SaveCancelAction
                onCancel={onRemoveModalClose}
                onSave={onRemoveSearchConfirm}
                cancelLabelName={t("jobsite.common.cancel") as string}
                saveLabelName={t("jobsite.common.remove") as string}
                cancelButtonId={idGenerator(
                  "savedsearches",
                  "remove-modal",
                ).generateId("cancel-button")}
                saveButtonId={idGenerator(
                  "savedsearches",
                  "remove-modal",
                ).generateId("remove-button")}
              />
            </div>
          </section>
        </Overlay>
      )}
    </>
  );
}
