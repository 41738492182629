import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { IDLE_TIMER_MILLI_SECONDS, LOGOUT } from "../app.constants";
import { AppData } from "../types";
import { redirectToPath } from "../util";

export function useSessionTimer(
  isSessionAuthenticated: boolean,
  appData: AppData,
) {
  const [showSignedOutModal, setShowSignedOutModal] = useState(false);
  const [sessionTimeout, setSessionTimeout] = useState(false);
  const [showSessionInactivityModal, setShowSessionInactivityModal] =
    useState(false);
  const [tabId] = useState(Date.now());

  const onIdle = () => {
    if (isSessionAuthenticated) {
      setShowSessionInactivityModal(true);
      sendMessage("idle");
    }
  };

  const redirectToLogout = () => {
    const { locale, appBasePathPrefix, isExternal } = appData;
    if (isExternal) {
      const routePath = `${locale}/${LOGOUT}?action=${LOGOUT}`;
      redirectToPath(appBasePathPrefix, routePath);
    } else {
      redirectToPath(appBasePathPrefix, `${locale}/logout-page`);
    }
  };

  const onMessage = (message: { status: string; tabId: number }) => {
    if (isSessionAuthenticated && message.status && message.tabId != tabId) {
      if (message.status === "idle") {
        setShowSessionInactivityModal(true);
      } else if (message.status === "active") {
        reset();
        setShowSessionInactivityModal(false); // Close the modal when receiving active
      } else if (message.status === "signOut") {
        redirectToLogout();
      }
    }
  };

  const { start, reset, message } = useIdleTimer({
    onIdle, // Function to call when user is idle
    onMessage, // Function to call when message is has been emitted.
    timeout: IDLE_TIMER_MILLI_SECONDS, // Activity Timeout in milliseconds.
    crossTab: true, // Enable cross tab event replication.
    syncTimers: 100, // Sync the timers across all tabs in ms
    startManually: true,
  });

  const sendMessage = (status: string) => {
    if (isSessionAuthenticated) message({ status, tabId });
  };

  return {
    showSignedOutModal,
    setShowSignedOutModal,
    sessionTimeout,
    setSessionTimeout,
    showSessionInactivityModal,
    setShowSessionInactivityModal,
    sendMessage,
    start,
    redirectToLogout,
  };
}
