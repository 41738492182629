import { NativeButton, Overlay } from "@rpe-js/marcom-web-components";
import React from "react";
import { ALERT_WRAPPER_ID } from "../../../app.constants";
import useAutoFocus from "../../../hooks/useAutoFocus";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { LocationRetailModal } from "../../../pages/erp/Locations/LocationRetailModal";
import { idGenerator, ModuleName } from "../../../utils/idGenerator";
import { AlertWrapper } from "../../base/AlertWrapper";
import { PillButtonListView } from "../../base/PillButtonListView";
import TypeaheadComponent from "../../base/Typeahead/TypeaheadComponent";
import { getTypeaheadTextBoxId } from "../../base/Typeahead/typeaheadUtils";

interface LocationWidgetProps<T> {
  moduleName: ModuleName;
  title: string;
  subTitle: string;
  currentStep: number;
  totalSteps: number;
  errorMessage: string;
  isCorporate: boolean;
  typeAheadPlaceholder: string;
  selectedLocationsCountLabel: string;
  pillButtonData: T[];
  pillButtonOptions: {
    getKey: (data: T) => string;
    getLabel: (data: T) => string;
  };
  apiEndpoint: (query: string) => Promise<T[] | undefined>;
  getSuggestionLabel: (suggestion: T) => string;
  onErrorMessageClose: () => void;
  onClearAllBtnClick: () => void;
  onTypeAheadSelection: (data: T) => void;
  onPillBtnRemove: (data: T) => void;
  showRetailModal?: boolean;
  toggleRetailModal?: (data: boolean) => void;
  countReplacementKey: string;
}

export function LocationWidget<T>({
  moduleName,
  title,
  subTitle,
  currentStep,
  totalSteps,
  errorMessage,
  isCorporate = true,
  pillButtonData = [],
  typeAheadPlaceholder,
  selectedLocationsCountLabel,
  apiEndpoint,
  onErrorMessageClose,
  onClearAllBtnClick,
  onTypeAheadSelection,
  onPillBtnRemove,
  getSuggestionLabel,
  pillButtonOptions,
  showRetailModal = false,
  toggleRetailModal,
  countReplacementKey,
}: LocationWidgetProps<T>) {
  const headingRef = useAutoFocus<HTMLHeadingElement>();
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const locationTypeaheadId = idGenerator(
    moduleName,
    "location-widget",
  ).generateId("typeahead");

  const retailModalButtonId = idGenerator(
    moduleName,
    "location-widget",
  ).generateId("retail-modal-button");

  return (
    <>
      <section className="text-center">
        <h1 ref={headingRef} tabIndex={0} className="erp-heading">
          <span role="text">
            <span>{title}</span>
            <span className="a11y">, </span>
            <span className="a11y">
              {t("jobsite.common.stepof", {
                startValue: currentStep,
                endValue: totalSteps,
              })}
            </span>
          </span>
        </h1>
        <div className="erp-title">{subTitle}</div>
      </section>
      <section className="mt-20 mb-20">
        {errorMessage && (
          <AlertWrapper
            closePosition="right"
            error={true}
            message={errorMessage}
            remove={{
              closeBtnAriaLabel: t("jobsite.common.closeAlert") as string,
              onRemove: onErrorMessageClose,
              idToFocus: getTypeaheadTextBoxId(locationTypeaheadId),
            }}
            id={ALERT_WRAPPER_ID.generateId("location-widget")}
          ></AlertWrapper>
        )}
        <div
          id="locations-interested"
          className="reviewinfo-container mtb-auto-0 mb-15"
        >
          <TypeaheadComponent
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            apiEndpoint={apiEndpoint}
            onSelect={(e) => onTypeAheadSelection(e as T)}
            hideLabel={true}
            removeOnSelect={true}
            getSuggestionLabel={getSuggestionLabel}
            minChars={2}
            highlightMatches={true}
            showSearchIcon={!isMobile}
            placeholder={typeAheadPlaceholder}
            suggestionPlaceholder={
              t("jobsite.common.suggestedLocations") as string
            }
            classNames={{
              container: "typeahead-container",
              inputWrapper: "typeahead-input-wrapper",
              list: "typeahead-list",
              listItem: "typeahead-list-item",
              button: "filter-typeahead-button",
              highlightedItem: "typeahead-highlighted-item",
              suggestionPlaceholder: "filter-typeahead-suggestion-placeholder",
              highlight: "typeahead-highlighted-text",
              suggestionIcon: "filter-typeahead-suggestion-icon",
              suggestionText: "filter-typeahead-suggestion-text",
            }}
            id={locationTypeaheadId}
            elementToFocusOnClose={retailModalButtonId}
            strict={true}
          />
        </div>
        {!isCorporate && (
          <>
            <div className="text-center m-20">
              <NativeButton
                id={retailModalButtonId}
                className="link more"
                onClick={() => {
                  if (toggleRetailModal) {
                    toggleRetailModal(true);
                  }
                }}
                label={
                  t("jobsite.erp.introduceFriend.allstorelocations") as string
                }
              ></NativeButton>
            </div>
          </>
        )}
        <div
          className={`selected-options ${isCorporate ? "u-border-bottom pb-10" : "u-border-top pt-10"}`}
        >
          <PillButtonListView
            showAsAccordion={isMobile}
            moduleName={moduleName}
            selectTranslationLabel={selectedLocationsCountLabel}
            countReplacementKey={countReplacementKey}
            pillBtnList={pillButtonData}
            pillBtnOptions={pillButtonOptions}
            onPillBtnRemove={onPillBtnRemove}
            onClearAllBtnClick={onClearAllBtnClick}
            itemAriaLabel={t("jobsite.search.stores") as string}
          ></PillButtonListView>
        </div>
      </section>
      {/* Below retail location modal is not wrapped with showRetailModal condition as LocationRetailModal has its own state and should not be removed from dom
      And while being in Locations step there is no way to open any other modal, this should not have any impact */}
      <Overlay
        id={idGenerator(moduleName, "retail-location-modal").generateId()}
        contentAttrs={{
          "aria-label": t(
            "jobsite.erp.introduceFriend.modalMobileHeader",
          ) as string,
        }}
        classes={{ root: "custom-overlay-fullscreen" }}
        visible={showRetailModal}
        onClose={() => {
          if (toggleRetailModal) {
            toggleRetailModal(false);
          }
        }}
        isFullscreen={isMobile}
        noPadding={true}
        disableClickAway={true}
        wide={true}
        closeButtonAttrs={{
          ariaLabel: t("jobsite.common.close") as string,
        }}
        elementIdToFocus={retailModalButtonId}
      >
        <LocationRetailModal
          onCancel={() => {
            if (toggleRetailModal) {
              toggleRetailModal(false);
            }
          }}
        />
      </Overlay>
    </>
  );
}
