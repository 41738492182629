import { Overlay } from "@rpe-js/marcom-web-components";
import React from "react";

import { Email } from "../../../../../shared/types/emailVerification";
import useIntlMessage from "../../../../hooks/useIntlMessage";
import useIsMobile from "../../../../hooks/useIsMobile";
import {
  EMAIL_CODE_GENERATION_MODAL_ID,
  EMAIL_CODE_GENERATION_MODAL_TITLE_ID,
  EMAIL_REPLACE_BUTTON,
} from "../constants";
import { EmailCodeGenerationContent } from "./emailCodeGenerationContent";

type EmailReplaceModalProps = {
  showModal: boolean;
  setShowModal: () => void;
  onCancel: () => void;
  onContinue: ({ request, response }: Email) => void;
};

export const EmailCodeGenerationModal = ({
  showModal,
  setShowModal,
  onCancel,
  onContinue,
}: EmailReplaceModalProps) => {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();

  return (
    <>
      {showModal && (
        <Overlay
          id={EMAIL_CODE_GENERATION_MODAL_ID}
          visible={showModal}
          onClose={setShowModal}
          noCloseButton={true}
          isFullscreen={isMobile}
          disableClickAway={true}
          disableEsc={false}
          closeButtonAttrs={{
            ariaLabel: t("jobsite.common.close") as string,
          }}
          elementIdToFocus={EMAIL_REPLACE_BUTTON}
          ariaLabel={EMAIL_CODE_GENERATION_MODAL_TITLE_ID}
        >
          <EmailCodeGenerationContent
            t={t}
            onContinue={onContinue}
            onCancel={onCancel}
          />
        </Overlay>
      )}
    </>
  );
};
