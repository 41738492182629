import { Button } from "@rpe-js/marcom-web-components";
import React, { useMemo } from "react";
import { idGenerator } from "../../utils/idGenerator";

interface ActionContainerProps {
  cancelLabelName: string;
  saveLabelName: string;
  reviewLabelName: string;
  showBackButton?: boolean;
  showContinueButton?: boolean;
  showReviewButton: boolean;
  classes?: string;
  onContinue?: () => void;
  onBack?: () => void;
  onReviewSubmit?: () => void;
}
export default function ApplyActionContainer({
  classes,
  cancelLabelName,
  saveLabelName,
  reviewLabelName,
  showBackButton = true,
  showContinueButton = true,
  showReviewButton = false,
  onContinue,
  onBack,
  onReviewSubmit,
}: ActionContainerProps) {
  const c = classes || "mt-20";
  const totalActions = useMemo(() => {
    let count = 0;
    if (showBackButton) count += 1;
    if (showContinueButton) count += 1;
    if (showReviewButton) count += 1;
    return count;
  }, [showBackButton, showContinueButton, showReviewButton]);

  return (
    <ul
      role="list"
      className={`d-flex list-nobullet mt-40 actions-btngroup-${totalActions} ${c}`}
    >
      {showBackButton && (
        <li role="listitem" className="mr-20 d-flex-equal">
          <Button
            id={idGenerator("apply", "step-back").generateId("button")}
            size="base"
            color="secondary"
            blockedVariant={true}
            label={cancelLabelName}
            {...(onBack ? { onClick: onBack } : { onClick: () => {} })}
          />
        </li>
      )}
      {showContinueButton && (
        <li
          role="listitem"
          className={`${showReviewButton ? "mr-20" : ""} d-flex-equal`}
        >
          <Button
            id={idGenerator("apply", "step-continue").generateId("button")}
            size="base"
            color="primary"
            blockedVariant={true}
            label={saveLabelName}
            {...(onContinue ? { onClick: onContinue } : { onClick: () => {} })}
          />
        </li>
      )}
      {showReviewButton && (
        <li role="listitem" className="d-flex-equal">
          <Button
            id={idGenerator("apply", "step-review-submit").generateId("button")}
            size="base"
            color="primary"
            blockedVariant={true}
            label={reviewLabelName}
            {...(onReviewSubmit
              ? { onClick: onReviewSubmit }
              : { onClick: () => {} })}
          />
        </li>
      )}
    </ul>
  );
}
