import React from "react";
import { Label, LabelProps } from "./Label";

export interface OptionalLabelProps extends Omit<LabelProps, "setAsHtml"> {
  optionalLabel: string;
  htmlFor: string;
}

export const OptionalLabel = ({
  label,
  optionalLabel,
  htmlFor,
  ...rest
}: OptionalLabelProps) => {
  return (
    <>
      <div className="d-flex justify-content-spacebetween">
        <Label
          label={
            label +
            `<span class="d-flex justify-content-end flex-1 align-self-end">${optionalLabel}</span>`
          }
          {...rest}
          htmlFor={htmlFor}
          setAsHtml={true}
          classes={["d-flex", "w-100"]}
        />
      </div>
    </>
  );
};
