import { RefData } from "@rpe-js/core/src/types/refData";
import { DropDownOptionProps } from "@rpe-js/marcom-web-components";
import { cloneDeep, findKey, flatten, random } from "lodash";
import { I18nValues } from "../../../../../shared/types";
import { BaseRefData } from "../../../../../shared/types/refData";
import { Address } from "../../../../../shared/types/talent/talent";
import { COUNTRY_REF_DATA } from "../../../../app.constants";
import { idGenerator } from "../../../../utils/idGenerator";
import { ProfileFormField } from "../../types";
import {
  ADDRESS_FIELD_CONFIG,
  ADDRESS_FIELD_VIEW_CONFIG,
  ADDRESSCONFIG,
  AddressFieldConfigId,
  AddressFormConfig,
} from "../types";

export const getRefData = (
  data: Partial<RefData>[],
  disabledOptionI18nKey?: string,
  code?: boolean,
): DropDownOptionProps[] => {
  const mappedData: DropDownOptionProps[] = data?.map(
    (obj: Partial<RefData>) => ({
      value: (code ? obj.code : obj.id) as string,
      label: obj.name as string,
    }),
  );
  const options = mappedData?.length ? mappedData : [];
  if (disabledOptionI18nKey) {
    return [
      { label: disabledOptionI18nKey, value: "", disabled: true },
      ...options,
    ];
  }
  return options;
};

export const getFieldLabel = (
  label: string,
  optional: boolean,
  t: (id: string, values?: I18nValues) => React.ReactNode,
): string => {
  return `${t(label)}${optional ? " " + t("jobsite.common.optional") : ""}`;
};

export const getFormFieldLabel = (
  field: ProfileFormField,
  t: (id: string, values?: I18nValues) => React.ReactNode,
): string => {
  return getFieldLabel(field?.label as string, field?.optional as boolean, t);
};

export function getAddressConfigId(countryId?: string): AddressFieldConfigId {
  if (!countryId) {
    return "addressOrderDefault";
  }
  const addressConfigKey: AddressFieldConfigId | undefined = findKey(
    ADDRESSCONFIG,
    (countryList: string[]) => (countryList || []).indexOf(countryId) >= 0,
  ) as AddressFieldConfigId;

  return cloneDeep(addressConfigKey ? addressConfigKey : "addressOrderDefault");
}

export function getAddressFormForCountry(
  country?: BaseRefData,
): AddressFormConfig {
  const configId = getAddressConfigId(country?.id);
  const addressFields = cloneDeep(ADDRESS_FIELD_CONFIG[configId]);
  return {
    key: random(10000),
    fields: addressFields,
    country: {
      value: country?.id,
      label: country?.name,
      type: "dropdown",
    },
  };
}

export function getAddressFromConfig(
  addressForms: AddressFormConfig[],
): Address[] {
  const addresses: Array<Partial<Address>> = [];
  addressForms.forEach((addressForm) => {
    const fields = flatten(addressForm.fields);
    const address: Partial<Address> = {
      countryID: addressForm.country.value,
      countryName: addressForm.country.label,
    };
    fields.forEach((field) => {
      const name = field.name;
      switch (name) {
        case "line1": {
          address.line1 = field.value;
          break;
        }
        case "line2": {
          address.line2 = field.value;
          break;
        }
        case "line3": {
          address.line3 = field.value;
          break;
        }
        case "city": {
          address.city = field.value;
          break;
        }
        case "state": {
          address.state = field.value;
          break;
        }
        case "county": {
          address.county = field.value;
          break;
        }
        case "district": {
          address.district = field.value;
          break;
        }
        case "zip": {
          address.zip = field.value;
          break;
        }
        default: {
          break;
        }
      }
    });
    addresses.push(address);
  });
  if (addresses[0]) {
    addresses[0].typeID = "addressType-preferred";
  }

  if (addresses[1]) {
    addresses[1].typeID = "addressType-secondary";
  }
  if (addresses[2]) {
    addresses[2].typeID = "addressType-other";
  }
  return addresses as Address[];
}

export function getConfigFromAddress(
  addresses: Address[],
  mode: "profile" | "myapplication" = "profile",
): AddressFormConfig[] {
  if (!addresses || addresses.length === 0) {
    return [];
  }
  const addressFormConfig: AddressFormConfig[] = [];
  addresses.forEach((address, idx) => {
    const config = getFormConfigFromAddress(address);
    if (mode === "myapplication" && idx === 0) {
      config.fields = [];
    }
    addressFormConfig.push(config);
  });
  return addressFormConfig;
}

function getFormConfigFromAddress(address: Address): AddressFormConfig {
  const formConfig = getAddressFormForCountry({
    id: address.countryID as string,
    name: address.countryName as string,
  });
  formConfig.country.label = address.countryName;
  formConfig.country.value = address.countryID;
  const fields = flatten(formConfig.fields);
  fields.forEach((field) => {
    const name = field.name;
    field.id = `${idGenerator("profile", "field").generateId(name)}`;
    switch (name) {
      case "line1": {
        field.value = address.line1;
        break;
      }
      case "line2": {
        field.value = address.line2;
        break;
      }
      case "line3": {
        field.value = address.line3;
        break;
      }
      case "city": {
        field.value = address.city;
        break;
      }
      case "state": {
        field.value = address.state;
        break;
      }
      case "county": {
        field.value = address.county;
        break;
      }
      case "district": {
        field.value = address.district;
        break;
      }
      case "zip": {
        field.value = address.zip;
        break;
      }
      default: {
        break;
      }
    }
  });
  return formConfig;
}

export function getAddressViewConfig(countryId?: string) {
  const configId = getAddressConfigId(countryId);
  return cloneDeep(ADDRESS_FIELD_VIEW_CONFIG[configId]);
}

export function getISOCountryID(country: string) {
  return COUNTRY_REF_DATA + country;
}
