import { Button } from "@rpe-js/marcom-web-components";
import * as React from "react";
import { useCallback, useContext, useRef } from "react";
import { LocationPickerContext } from "../../contexts/LocationPickerContext";
import useIntlMessage from "../../hooks/useIntlMessage";
import { focusManagementOnRemove } from "../../utils/focusUtil";
import { idGenerator } from "../../utils/idGenerator";

type Props = {
  expanded: boolean;
};

export default function LocationPickerTaglist(props: Props) {
  const { expanded } = props;
  const { t } = useIntlMessage();
  const context = useContext(LocationPickerContext);
  if (!context) {
    throw new Error(
      "LocationPickerHeader must be used within a LocationPickerProvider",
    );
  }
  const { selectedStores, setSelectedStores } = context;
  const sliceSize = expanded ? selectedStores.length : 3;
  const itemsListRef = useRef<HTMLUListElement | null>(null);
  const LOCATION_PICKER_LOCATION_INPUT_ID = idGenerator(
    "locationPicker",
    "search",
  ).generateId("location");

  const removeStoreSelection = useCallback(
    (locationId: string, index: number) => {
      setSelectedStores(
        selectedStores.filter((store) => store.locationId !== locationId),
      );
      focusManagementOnRemove(
        itemsListRef,
        index,
        selectedStores.length,
        LOCATION_PICKER_LOCATION_INPUT_ID,
      );
    },
    [LOCATION_PICKER_LOCATION_INPUT_ID, selectedStores, setSelectedStores],
  );

  return (
    <>
      <ul
        ref={itemsListRef}
        className="store-list"
        role="list"
        aria-live="polite"
        aria-relevant="additions removals"
        aria-label={
          selectedStores.length > 0
            ? (t("jobsite.common.selectedStores") as string)
            : undefined
        }
        key="selectedStores"
      >
        {selectedStores.length > 0 &&
          selectedStores.slice(0, sliceSize).map((store: any, index) => (
            <li role="listitem" key={store.locationId}>
              <Button
                size={"base"}
                color={"secondary-neutral"}
                // label={`${t("jobsite.common.remove")} ${store.storeName}`}
                label={`${store.storeName}`}
                onClick={() => removeStoreSelection(store.locationId, index)}
                aria-label={`${t("jobsite.common.remove")} ${store.storeName}`}
                blockedVariant={false}
                id={idGenerator("locationPicker", "remove-store").generateId(
                  `button-${index}`,
                )}
              >
                <i className="icon icon-resetsolid pointer pl-5" />
              </Button>
            </li>
          ))}
      </ul>
    </>
  );
}
