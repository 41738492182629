import { Button } from "@rpe-js/marcom-web-components";
import React, { useContext } from "react";
import { Link, useRouteLoaderData } from "react-router";
import { SELECTED_LOCATIONS_FOR_ROLE } from "../../app.constants";
import { LocationPickerContext } from "../../contexts/LocationPickerContext";
import useAutoFocus from "../../hooks/useAutoFocus";
import useIntlMessage from "../../hooks/useIntlMessage";
import { RouteID } from "../../routes";
import { idGenerator } from "../../utils/idGenerator";
import { SessionStorage } from "../../utils/sessionStorageUtil";
import LocationPickerTaglist from "./LocationPickerTaglist";

interface SelectedLocationsForRole {
  reqId: string;
  locationIds: string[];
  postalCode?: string;
  miles?: string;
  stateId?: string;
  stateName?: string;
}

export default function LocationPickerHeader() {
  const jobTitleRef = useAutoFocus<HTMLAnchorElement>();
  const context = useContext(LocationPickerContext);
  const { t } = useIntlMessage();
  const rootData = useRouteLoaderData(RouteID.root) as { locale: string };
  if (!context) {
    throw new Error(
      "LocationPickerHeader must be used within a LocationPickerProvider",
    );
  }
  const { jobDetails, selectedStores, zip, distance, postLocation, search } =
    context;
  const [expanded, setExpanded] = React.useState(false);
  const jobDetailsNumberId = jobDetails.positionId || jobDetails.jobNumber;
  const url = `/${rootData.locale}/details/${jobDetailsNumberId}/${jobDetails.transformedPostingTitle}${jobDetails.team && jobDetails.team.teamCode ? `?team=${jobDetails.team.teamCode}` : ""}`;
  const COUNT_SHOWN = 3;
  const hiddenCount =
    selectedStores.length > COUNT_SHOWN
      ? selectedStores.length - COUNT_SHOWN
      : 0;
  const continueUrl = `/app/${rootData.locale}/apply/${jobDetailsNumberId}`;
  const continueButtonDisabled = selectedStores && selectedStores.length === 0;

  /**
   * This method will save selected stores data to session storage so that it can be used in apply job flow
   */
  function setSelectedLocationForRole() {
    if (selectedStores && selectedStores.length) {
      const selectedLocationsForRole: SelectedLocationsForRole = {
        reqId: jobDetailsNumberId,
        locationIds: selectedStores.map(
          (selectedStore) => selectedStore?.locationId,
        ),
      };

      if (zip.length >= 5 && distance) {
        selectedLocationsForRole.postalCode = zip;
        selectedLocationsForRole.miles = distance;
      }

      if (postLocation && search) {
        selectedLocationsForRole.stateId = postLocation;
        selectedLocationsForRole.stateName = search;
      }

      SessionStorage.set(
        SELECTED_LOCATIONS_FOR_ROLE,
        JSON.stringify(selectedLocationsForRole),
      );
    }
  }

  function continueToApply() {
    setSelectedLocationForRole();
  }

  return (
    <section className="header-section text-center">
      <h1 className={"a11y"}>
        {t("jobsite.locationPicker.headerVO", {
          postingTitle: jobDetails.postingTitle,
        })}
      </h1>
      <h2 className={"t-headline fw-medium"}>
        <Link ref={jobTitleRef} to={url}>
          {jobDetails.postingTitle}
        </Link>
      </h2>
      <div className="taglist">
        <LocationPickerTaglist expanded={expanded} />
        {hiddenCount > 0 && (
          <Button
            size={"base"}
            label={
              expanded
                ? (t("jobsite.common.showLess") as string)
                : `${t("jobsite.common.showMore")} (${hiddenCount})`
            }
            onClick={() => setExpanded(!expanded)}
            blockedVariant={false}
            color={"secondary"}
            id={idGenerator("locationPicker", "selected-stores").generateId(
              "expand-button",
            )}
          >
            <span className="a11y">{t("jobsite.common.selectedStores")}</span>
          </Button>
        )}
        <Button
          id={idGenerator("locationPicker", "selected-stores").generateId(
            "continue-button",
          )}
          onClick={continueToApply}
          disabled={continueButtonDisabled}
          aria-disabled={continueButtonDisabled}
          tabIndex={continueButtonDisabled ? -1 : 0}
          size={"base"}
          label={t("jobsite.common.continue") as string}
          aria-label={t("jobsite.locationPicker.continueAriaLabel") as string}
          blockedVariant={false}
          color={"primary"}
          href={continueUrl}
          isLinkButton={true}
        ></Button>
      </div>
    </section>
  );
}
