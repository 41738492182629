import React, { useCallback, useContext } from "react";
import {
  FILTER_TYPE,
  SET_FILTER,
  UNSET_FILTER,
} from "../../contexts/actionTypes";
import {
  FilterValue,
  SEARCH_FILTER_TYPE,
  SearchContext,
} from "../../contexts/SearchContext";
import useIsMobile from "../../hooks/useIsMobile";
import { HiringManagerInfo } from "../../types/HiringManagerInfo";
import { HiringManagerFilter } from "./HiringManagerFilter";

interface HiringManagerFilterWrapperProps {
  hiringManagerFilterData: Array<HiringManagerInfo>;
  onMobileFilterChange?: (
    filterType: SEARCH_FILTER_TYPE,
    filterValue: FilterValue,
  ) => void;
}
export function HiringManagerFilterWrapper({
  hiringManagerFilterData,
  onMobileFilterChange,
}: HiringManagerFilterWrapperProps) {
  const { dispatch } = useContext(SearchContext);
  const isMobile = useIsMobile();

  const updateMobileFilters = useCallback(
    (filterType: SEARCH_FILTER_TYPE, filterValue: FilterValue) => {
      if (onMobileFilterChange) onMobileFilterChange(filterType, filterValue);
    },
    [onMobileFilterChange],
  );

  // dispatch a filter action in case of desktop / invoke mobileFilterChange callback incase of mobile
  const onUpdateHiringManager = useCallback(
    (hiringManagerList: Array<HiringManagerInfo>) => {
      if (!isMobile)
        dispatch({
          type: SET_FILTER,
          filterName: FILTER_TYPE.HIRING_MANAGER,
          payload: hiringManagerList,
        });
      else
        updateMobileFilters(
          FILTER_TYPE.HIRING_MANAGER as SEARCH_FILTER_TYPE,
          hiringManagerList,
        );
    },
    [dispatch, isMobile, updateMobileFilters],
  );

  const onApplyFilter = useCallback(
    (hiringManagerList: Array<HiringManagerInfo>) => {
      onUpdateHiringManager(hiringManagerList);
    },
    [onUpdateHiringManager],
  );

  // dispatch a filter action in case of desktop / invoke mobileFilterChange callback incase of mobile
  const onRemoveHiringManager = useCallback(() => {
    if (!isMobile)
      dispatch({
        type: UNSET_FILTER,
        filterName: FILTER_TYPE.HIRING_MANAGER,
      });
    else
      updateMobileFilters(FILTER_TYPE.HIRING_MANAGER as SEARCH_FILTER_TYPE, []);
  }, [dispatch, isMobile, updateMobileFilters]);

  return (
    <HiringManagerFilter
      selectedManagers={hiringManagerFilterData}
      onApplyFilter={onApplyFilter}
      onUpdateHiringManager={onUpdateHiringManager}
      onRemoveHiringManager={onRemoveHiringManager}
    ></HiringManagerFilter>
  );
}
