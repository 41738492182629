import React, { useEffect } from "react";
import { useSearchParams } from "react-router";
import { StepProgress } from "../../../components/base/StepProgress";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { idGenerator } from "../../../utils/idGenerator";
import { AcknowledgementStep } from "./Acknowledgement";
import { ApplicationStep } from "./application/ApplicationStep";
import { ApplicationHeader } from "./ApplicationHeader";
import { QueryParam_Page_Id } from "./constants";
import { useMyApplicationContext } from "./context/context";
import { ApplicationAllowedSteps } from "./context/types";
import { InformationReleaseStep } from "./InformationReleaseStep";
import { ReviewSubmitStep } from "./review/ReviewSubmitStep";
import { SelfDisclosureStep } from "./SelfDisclosureStep";

export function ApplicationLayout() {
  const { t } = useIntlMessage();
  const { state, dispatch } = useMyApplicationContext();
  const { page, pageError, currentStep, submitted } = state;
  const { firstStep, stepMapping } = page;

  const { applicationDisabled } = pageError;
  const stepProgessDomID = idGenerator(
    "profileApplication",
    "step-progress",
  ).generateId();

  const [searchParams, setSearchParams] = useSearchParams();
  const queryParamPageIdValue = searchParams.get(QueryParam_Page_Id);

  useEffect(() => {
    if (!applicationDisabled) {
      if (!currentStep) {
        const params = new URLSearchParams();
        params.set(QueryParam_Page_Id, `${stepMapping[firstStep]}`);
        setSearchParams(params);
        dispatch({ type: "SET_CURRENT_STEP", payload: "selfDisclosure" });
      }
    }
  }, [
    queryParamPageIdValue,
    currentStep,
    setSearchParams,
    applicationDisabled,
    stepMapping,
    firstStep,
    dispatch,
  ]);

  // const canSave = useCallback(() => {
  //   return true;
  // }, []);

  return (
    <>
      {currentStep && !applicationDisabled && !submitted && (
        <>
          <div className="pt-40 pb-40">
            {t("jobsite.profile.myApplication.pageTitle")}
          </div>
          <div>
            <StepProgress
              id={stepProgessDomID}
              count={page.stepNames.length}
              currStep={
                page.stepMapping[currentStep as ApplicationAllowedSteps]
              }
            ></StepProgress>
          </div>
          <ApplicationHeader />
        </>
      )}

      {currentStep === "selfDisclosure" && !submitted && <SelfDisclosureStep />}
      {currentStep === "application" && !submitted && <ApplicationStep />}
      {currentStep === "informationRelease" && !submitted && (
        <InformationReleaseStep />
      )}
      {currentStep === "reviewSubmit" && !submitted && <ReviewSubmitStep />}
      {currentStep === "selfDisclosure" && submitted && <AcknowledgementStep />}
    </>
  );
}
