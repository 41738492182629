import {
  BaseRefData,
  MappedERPPostLocation,
  TeamsRefData,
} from "../../../../shared/types/refData";
import { FileData } from "../../../types/File";
import {
  TalentSupportingFile,
  TalentSupportingLink,
} from "../../../types/SupportingFileWidget";
import { Allowed_Reference_Data } from "../types";
import { ERPAction } from "./ActionTypes";
import { PageState } from "./ErpState";

export const stepAction = (payload: PageState): ERPAction => ({
  type: "STEP",
  payload,
});

export const updateRoleList = (payload: string[]): ERPAction => ({
  type: "UPDATE_ROLE_LIST",
  payload,
});

export const deleteRoleList = (payload: string[]): ERPAction => ({
  type: "DELETE_ROLE_LIST",
  payload,
});

export const updateContactDetails = (
  key: string,
  value: string,
): ERPAction => ({
  type: "UPDATE_CONTACT_DETAILS",
  payload: { key, value },
});

export const updateCountry = (
  countryID: string,
  countryName: string,
): ERPAction => ({
  type: "UPDATE_ADDRESS",
  payload: { countryID, countryName },
});

export const updateLanguage = (
  languageID: string,
  languageName: string,
): ERPAction => ({
  type: "UPDATE_LANGUAGE",
  payload: { languageID, languageName },
});

export const updateReferralType = (typeID: string): ERPAction => ({
  type: "UPDATE_REFERRAL_TYPE",
  payload: typeID,
});

export const updateNotes = (payload: string): ERPAction => ({
  type: "UPDATE_NOTES",
  payload,
});

export const resumeAction = (payload: FileData): ERPAction => ({
  type: "UPDATE_RESUME",
  payload,
});

export const textResumeAction = (payload: FileData): ERPAction => ({
  type: "UPDATE_TEXT_RESUME",
  payload,
});

export const updateReferenceData = (
  key: Allowed_Reference_Data,
  value: BaseRefData[] | MappedERPPostLocation[] | TeamsRefData[],
): ERPAction => ({
  type: "UPDATE_REFERENECE_DATA",
  payload: { key, value },
});

export const supportingFileAction = (
  data: Array<TalentSupportingFile>,
): ERPAction => ({
  type: "SUPPORTING_FILE",
  payload: data,
});

export const supportingLinkAction = (
  data: Array<TalentSupportingLink>,
): ERPAction => ({
  type: "SUPPORTING_LINK",
  payload: data,
});

export const updateGlobalManagedRoles = (
  payload: BaseRefData[],
): ERPAction => ({
  type: "UPDATE_MANAGED_ROLES",
  payload,
});

export const updateCorporateLocations = (
  payload: MappedERPPostLocation[],
): ERPAction => ({
  type: "UPDATE_CORPORATE_LOCATIONS",
  payload,
});

export const updateRetailLocations = (
  payload: MappedERPPostLocation[],
): ERPAction => ({
  type: "UPDATE_RETAIL_LOCATIONS",
  payload,
});

export const updateTeamsOfInterest = (payload: {
  key: string;
  value: { subTeam: string[]; isSelectAll: boolean };
}): ERPAction => ({
  type: "UPDATE_TEAMS_OF_INTEREST",
  payload,
});

export const toggleReviewSubmitBtn = (payload: boolean): ERPAction => ({
  type: "TOGGLE_REVIEW_SUBMIT_BUTTON",
  payload,
});

export const createTeamsViewDataAndPayload = (): ERPAction => ({
  type: "CREATE_TEAMS_VIEW_DATA_AND_PAYLOAD",
});

export const toggleRetailModalStatus = (payload: boolean): ERPAction => ({
  type: "TOGGLE_RETAIL_MODAL_STATUS",
  payload,
});
