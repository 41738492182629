import { DropDownOptionProps, Textbox } from "@rpe-js/marcom-web-components";
import { cloneDeep, isEmpty } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  EducationDegreeRefData,
  GraduationStatusRefData,
  RefData,
} from "../../../../../../shared/types/refData";
import {
  AppEducationDetails,
  MyApplication,
} from "../../../../../../shared/types/talent/myApplication";
import { RequestOptions } from "../../../../../api/apiService";
import {
  COUNTRY_USA,
  DEFAULT_LOCALE_API_HEADER,
} from "../../../../../app.constants";
import { useFormInputValidator } from "../../../../../components/feature/form/inputValidators/useFormInputValidator";
import { FieldLengthConfiguration } from "../../../../../components/feature/form/maxLengthConfiguration";
import { useCurrentUserContext } from "../../../../../CurrentUserContext";
import useFocusFirstErrorField from "../../../../../hooks/useFocusFirstErrorField";
import useIntlMessage from "../../../../../hooks/useIntlMessage";
import { idGenerator } from "../../../../../utils/idGenerator";
import { CountryDropdownView } from "../../../components/CountryDropdownView";
import { EducationDegree } from "../../../components/educationSummary/EducationDegree";
import { EducationDegreeView } from "../../../components/educationSummary/EducationDegreeView";
import { EducationGradStatus } from "../../../components/educationSummary/EducationGradStatus";
import { EducationMajor } from "../../../components/educationSummary/EducationMajor";
import { EducationSchool } from "../../../components/educationSummary/EducationSchool";
import { EducationSummaryEditActions } from "../../../components/educationSummary/EducationSummaryEditActions";
import {
  AppEducationDegreeForm,
  getAppEducationDegreeForm,
  getAppEducationPayload,
} from "../../../components/educationSummary/form";
import { useMyApplicationContext } from "../../context/context";
import { MyApplicationActionDispatcher } from "../../context/state";
import { ApplicationSection, SectionMode } from "../../context/types";
import { useUpdateApplication } from "../../hooks/createAndUpdateApplicationHooks";
import { SectionHeader } from "../SectionHeader";
import { SectionActions } from "./sectionActions";

interface SectionProps {
  id: string;
  onSave: (
    name: ApplicationSection,
    mode: SectionMode,
    application: MyApplication,
  ) => void;
  mode: SectionMode;
}

interface EduProps extends SectionProps {}

export function EducationSection(props: EduProps) {
  const { id, mode, onSave } = props;
  const { state, dispatch } = useMyApplicationContext();
  const { t } = useIntlMessage();
  const typeaheadApiOptions: RequestOptions = {
    headers: {
      locale: DEFAULT_LOCALE_API_HEADER,
    },
  };
  const { currentUser } = useCurrentUserContext();
  const { educationSection, application, applicationStep, talent } = state;
  const { refData } = applicationStep;
  const { education: degreeRefData, gradStatus: gradStatusRefData } =
    refData?.eduGradStatusRefData || {};
  const { title, educationDegree } = educationSection;
  const sectionName: ApplicationSection = "education";
  const { setFocusFirstErrorField } = useFocusFirstErrorField(
    `profileApplication-application-${sectionName}`,
  );

  const stepEducationInfo = useMemo(() => {
    return (
      educationDegree ||
      application?.educationDegrees ||
      talent?.educationDegrees ||
      []
    );
  }, [
    educationDegree,
    application?.educationDegrees,
    talent?.educationDegrees,
  ]);
  const [educationDegreesForm, setEducationDegreesForm] = useState<
    AppEducationDegreeForm[]
  >([]);

  const profileEducationSchoolId = idGenerator(
    "profileApplication",
    `education-school`,
  ).generateId();

  const profileEducationMajorId = idGenerator(
    "profileApplication",
    `education-major`,
  ).generateId();
  const profileEducationGradStatusId = idGenerator(
    "profileApplication",
    `education-gradstatus`,
  ).generateId();
  const profileEducationDegreeId = idGenerator(
    "profileApplication",
    `degree`,
  ).generateId();

  const profileEducationCountryId = idGenerator(
    "profileApplication",
    `education-country`,
  ).generateId();
  const profileEducationStateId = idGenerator(
    "profileApplication",
    `education-state`,
  ).generateId();

  const {
    //isUpdateApplicationError,
    isUpdateApplicationLoading,
    isUpdateApplicationSuccess,
    updateApplication,
    resetUpdateApplication,
    updatedApplication,
  } = useUpdateApplication();

  const { validateCountryStateZipCode, onPasteValidateCountryStateZipCode } =
    useFormInputValidator();

  const initialiseEduForm = useCallback(() => {
    let forms: AppEducationDegreeForm[] = [];
    if (!isEmpty(stepEducationInfo)) {
      const eduDegreeForm: AppEducationDegreeForm[] = [];
      stepEducationInfo.forEach((ied, idx) => {
        eduDegreeForm.push(getAppEducationDegreeForm(idx !== 0, ied));
      });
      forms = eduDegreeForm;
    } else {
      forms = [getAppEducationDegreeForm()];
    }
    // if first element has no country information , set it to COUNTRY USA
    const firstEntry = forms[0];
    if (
      isEmpty(firstEntry.country.label) &&
      isEmpty(firstEntry.country.value)
    ) {
      const countryRefData = refData?.countryRefData || [];
      const match = countryRefData.find((ref) => ref.id === COUNTRY_USA);
      if (match && match.id && match.name) {
        firstEntry.country.id = firstEntry.country.value = match.id;
        firstEntry.country.label = match.name;
      }
    }
    setEducationDegreesForm(forms);
  }, [refData?.countryRefData, stepEducationInfo]);

  useEffect(() => {
    if (educationDegreesForm.length === 0) {
      initialiseEduForm();
    }
  }, [stepEducationInfo, educationDegreesForm, refData, initialiseEduForm]);

  const onCancel = useCallback(() => {
    MyApplicationActionDispatcher.onApplicationSectionCancel(
      dispatch,
      sectionName,
    );
  }, [dispatch]);

  const onSectionEdit = useCallback(() => {
    MyApplicationActionDispatcher.onApplicationSectionEdit(
      dispatch,
      sectionName,
    );
    initialiseEduForm();
  }, [dispatch, initialiseEduForm]);

  const onAddEducationDegree = useCallback(() => {
    const degreeForm: AppEducationDegreeForm = getAppEducationDegreeForm(true);
    setEducationDegreesForm((prevValue: any) => [...prevValue, degreeForm]);
  }, []);

  const onRemoveEducationDegree = useCallback(
    (key: number) => {
      const updatedEducation = educationDegreesForm.filter(
        (educationDegree: AppEducationDegreeForm) =>
          educationDegree.key !== key,
      );
      setEducationDegreesForm([...updatedEducation]);
    },
    [educationDegreesForm],
  );

  const onSchoolOrMajorChange = useCallback(
    (
      index: number,
      option: string | RefData | null,
      type: "school" | "major",
      event: "select" | "blur" | "reset",
    ) => {
      const matchEduDegree = cloneDeep(educationDegreesForm[index]);
      let update = false; // should not update the state on every blur. Update only if option has changed on blur
      if (event === "reset") {
        matchEduDegree[type].value = "";
        matchEduDegree[type].label = "";
        matchEduDegree[type].requiredError =
          matchEduDegree[type].required && true;
        update = true;
      }

      if (event === "select" && option && !isEmpty(option)) {
        if (typeof option === "object") {
          matchEduDegree[type].value = option.id;
          matchEduDegree[type].id = option.id;
          matchEduDegree[type].label = option.name;
          matchEduDegree[type].requiredError =
            matchEduDegree[type].required && true;
        } else {
          matchEduDegree[type].value = "";
          matchEduDegree[type].id = "";
          matchEduDegree[type].label = option;
          matchEduDegree[type].requiredError = true;
        }
        update = true;
      }

      if (event === "blur" && matchEduDegree[type].label !== option) {
        matchEduDegree[type].label = option ? (option as string) : "";
        if (!option || isEmpty(option)) {
          matchEduDegree[type].value = "";
          matchEduDegree[type].id = "";
        }
        update = true;
      }

      if (update) {
        const copied = [...educationDegreesForm];
        copied.splice(index, 1, matchEduDegree);
        setEducationDegreesForm(copied);
      }
    },
    [educationDegreesForm, setEducationDegreesForm],
  );

  const handleValueChange = useCallback(
    (index: number, option: any, name: string) => {
      const matchEduDegree = cloneDeep(educationDegreesForm[index]);
      if (name === "degree") {
        matchEduDegree.degree.value = option.value;
        matchEduDegree.degree.label = option.label;
        matchEduDegree.degree.requiredError = false;
      } else if (name === "gradStatus") {
        const gradOption = gradStatusRefData?.filter(
          (opt: GraduationStatusRefData) => opt.id === option.target?.value,
        );
        matchEduDegree.gradStatus.value = option.target?.value;
        if (gradOption?.length) {
          matchEduDegree.gradStatus.label = gradOption[0].name;
        }
        matchEduDegree.gradStatus.requiredError = false;
      }
      const copied = [...educationDegreesForm];
      copied.splice(index, 1, matchEduDegree);
      setEducationDegreesForm(copied);
    },
    [educationDegreesForm, setEducationDegreesForm, gradStatusRefData],
  );

  const validateEducationForm = useCallback(
    (degree: AppEducationDegreeForm[]): boolean => {
      let isError = false;
      degree.forEach((d) => {
        const objs = [
          d.degree,
          d.school,
          d.major,
          d.gradStatus,
          d.country,
          d.state,
        ];
        objs.forEach((obj) => {
          if (
            (isEmpty(obj.label) && isEmpty(obj.value)) ||
            isEmpty(obj.label)
          ) {
            obj.requiredError = obj.required && true;
            if (!isError) {
              isError = obj.requiredError;
            }
          } else {
            obj.requiredError = false;
          }
        });
      });
      return isError;
    },
    [],
  );

  const onSectionSave = useCallback(async () => {
    const hasError = validateEducationForm(educationDegreesForm);

    if (hasError) {
      const educationDegreesFormCopy = cloneDeep(educationDegreesForm);
      setEducationDegreesForm(educationDegreesFormCopy);
      setFocusFirstErrorField(true);
    }

    if (!hasError && application && currentUser.talentId) {
      const updated = getAppEducationPayload(educationDegreesForm);
      MyApplicationActionDispatcher.onAppSecEduSave(dispatch, updated);
      const requestBody = cloneDeep(application);
      requestBody.educationDegrees = updated;
      await updateApplication(
        currentUser.talentId,
        application?.id,
        "education",
        requestBody as MyApplication,
      );
    }
  }, [
    validateEducationForm,
    educationDegreesForm,
    application,
    currentUser.talentId,
    setFocusFirstErrorField,
    dispatch,
    updateApplication,
  ]);

  const handleCountryChange = useCallback(
    (
      index: number,
      evt: React.ChangeEvent<HTMLInputElement>,
      selectedOption: DropDownOptionProps,
      selectedRefData: RefData,
    ) => {
      const matchEduDegree = cloneDeep(educationDegreesForm[index]);
      matchEduDegree.country.id = selectedRefData.id;
      matchEduDegree.country.value = selectedRefData.id;
      matchEduDegree.country.label = selectedRefData.name;
      matchEduDegree.country.requiredError = false;
      const copied = [...educationDegreesForm];
      copied.splice(index, 1, matchEduDegree);
      setEducationDegreesForm(copied);
    },
    [educationDegreesForm],
  );

  const handleTextChange = useCallback(
    (index: number, value: string) => {
      const matchEduDegree = cloneDeep(educationDegreesForm[index]);
      matchEduDegree.state.value = value;
      matchEduDegree.state.label = value;
      if (!value) {
        matchEduDegree.state.requiredError = matchEduDegree.state.required;
      } else {
        matchEduDegree.state.requiredError = false;
      }
      const copied = [...educationDegreesForm];
      copied.splice(index, 1, matchEduDegree);
      setEducationDegreesForm(copied);
    },
    [educationDegreesForm],
  );

  useEffect(() => {
    if (
      !isUpdateApplicationLoading &&
      isUpdateApplicationSuccess &&
      updatedApplication
    ) {
      MyApplicationActionDispatcher.onAppSecEduSave(
        dispatch,
        updatedApplication.educationDegrees,
      );
      onSave(sectionName, mode, updatedApplication as MyApplication);
      resetUpdateApplication();
    }
  }, [
    dispatch,
    isUpdateApplicationLoading,
    isUpdateApplicationSuccess,
    mode,
    onSave,
    updatedApplication,
    resetUpdateApplication,
  ]);

  return (
    <SectionHeader id={id} title={title} mode={mode} onEdit={onSectionEdit}>
      {mode === "view" && (
        <div className="mt-10">
          {(stepEducationInfo as AppEducationDetails[])?.length > 0 &&
            stepEducationInfo?.map((education, index) => (
              <fieldset
                className={`${index !== 0 ? "u-border-top pt-10" : ""}`}
                key={index}
              >
                <EducationDegreeView education={education} />
              </fieldset>
            ))}
        </div>
      )}
      {(mode === "edit" || mode === "create") && (
        <form>
          <fieldset>
            {educationDegreesForm.map(
              (educationDegree: AppEducationDegreeForm, index: number) => {
                const removeEducationDegreeId = idGenerator(
                  "profileApplication",
                  `remove-education-degree`,
                ).generateId(`${index}`);

                const addEducationDegreeId = idGenerator(
                  "profileApplication",
                  `add-education-degree`,
                ).generateId(`${index}`);
                return (
                  <div
                    className={index !== 0 ? "u-border-top pt-20" : ""}
                    key={educationDegree.key}
                  >
                    <div className="column large-12 small-12">
                      <EducationDegree
                        id={`${profileEducationDegreeId}-${index}`}
                        refData={degreeRefData as EducationDegreeRefData[]}
                        onChange={(evt, option) =>
                          handleValueChange(index, option, "degree")
                        }
                        showError={
                          educationDegree.degree.requiredError === true
                        }
                        value={educationDegree.degree.value}
                      />
                    </div>
                    <div className="column large-12 small-12">
                      <EducationSchool
                        id={`${profileEducationSchoolId}-${index}`}
                        elementToFocusOnClose={`${profileEducationCountryId}-${index}`} // bring the focus back to country dropdown
                        onSuggestionSelect={(e) =>
                          onSchoolOrMajorChange(index, e, "school", "select")
                        }
                        onBlur={(e: string | null) =>
                          onSchoolOrMajorChange(index, e, "school", "blur")
                        }
                        onReset={() =>
                          onSchoolOrMajorChange(index, null, "school", "reset")
                        }
                        initialValue={educationDegree.school.label}
                        showError={
                          educationDegree.school.requiredError === true
                        }
                      />
                    </div>
                    <div className="column large-12 small-12">
                      <CountryDropdownView
                        refData={refData?.countryRefData || []}
                        id={`${profileEducationCountryId}-${index}`}
                        selectedCountry={
                          educationDegree.country.value as string
                        }
                        isDisabled={false}
                        showError={educationDegree.country.requiredError}
                        onChange={(evt, opt, ref) =>
                          handleCountryChange(index, evt, opt, ref)
                        }
                      />
                    </div>
                    {index !== 0 && (
                      <div>
                        <Textbox
                          required={
                            educationDegree.state.requiredError || false
                          }
                          label={
                            t("jobsite.common.enterStateProvince") as string
                          }
                          value={educationDegree.state.label}
                          id={`${profileEducationStateId}-${index}`}
                          error={
                            educationDegree.state.requiredError === true
                              ? (t(
                                  "jobsite.profile.myApplication.errorState",
                                ) as string)
                              : false
                          }
                          errorA11y={
                            t("jobsite.common.errorIconLabel") as string
                          }
                          maxLength={
                            FieldLengthConfiguration.addressStateMaxLength
                          }
                          onValueChange={(val) => handleTextChange(index, val)}
                          onKeyDown={validateCountryStateZipCode}
                          onPaste={onPasteValidateCountryStateZipCode}
                        />
                      </div>
                    )}
                    <div className="column large-12 small-12">
                      <EducationGradStatus
                        id={`${profileEducationGradStatusId}-${index}`}
                        handleChange={(e) => {
                          handleValueChange(index, e, "gradStatus");
                        }}
                        value={educationDegree.gradStatus.value || ""}
                        showError={
                          educationDegree.gradStatus.requiredError === true
                        }
                        refData={gradStatusRefData as GraduationStatusRefData[]}
                      />
                    </div>

                    <div className="column large-12 small-12">
                      <EducationMajor
                        id={`${profileEducationMajorId}-${index}`}
                        elementToFocusOnClose={addEducationDegreeId}
                        typeaheadApiOptions={typeaheadApiOptions}
                        onSuggestionSelect={(e) =>
                          onSchoolOrMajorChange(index, e, "major", "select")
                        }
                        onBlur={(e: string | null) =>
                          onSchoolOrMajorChange(index, e, "major", "blur")
                        }
                        onReset={() =>
                          onSchoolOrMajorChange(index, null, "major", "reset")
                        }
                        initialValue={educationDegree.major.label}
                        showError={educationDegree.major.requiredError === true}
                      />
                    </div>

                    <EducationSummaryEditActions
                      degreesCount={educationDegreesForm.length}
                      index={index}
                      key={educationDegree.key}
                      onAddEducationDegree={onAddEducationDegree}
                      onRemoveEducationDegree={() =>
                        onRemoveEducationDegree(educationDegree.key)
                      }
                      mode="profileMyApplication"
                      removeButtonId={removeEducationDegreeId}
                      addButtonId={addEducationDegreeId}
                    />
                  </div>
                );
              },
            )}
          </fieldset>
        </form>
      )}
      {mode !== "view" && (
        <SectionActions
          onCancel={onCancel}
          onSave={onSectionSave}
          mode={mode}
        />
      )}
    </SectionHeader>
  );
}
