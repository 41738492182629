import React, { ChangeEvent, useState } from "react";
import { Answer } from "../../../../shared/types/questionnaire";
import useIntlMessage from "../../../hooks/useIntlMessage";
import RadioGroup from "../form/radioGroup";

interface MultiChoiceSingleAnsQuesProps {
  questionId: string;
  question: string;
  answerOptions: Array<Answer>;
  instructions: string;
  errorMessage: string;
  updateAnswer: (answer: any) => void;
}
function getSelectedValue(answerOptions: Array<Answer>) {
  const answeredOption = answerOptions.filter(
    (option: Answer) => option.answeredMultiple,
  );
  if (answeredOption.length > 0) return answeredOption[0].answerId;
  return "";
}

export function MultiChoiceSingleAnsQues({
  questionId,
  question,
  answerOptions,
  instructions,
  errorMessage,
  updateAnswer,
}: MultiChoiceSingleAnsQuesProps) {
  const { t } = useIntlMessage();
  const [selectedValue, setSelectedValue] = useState(
    getSelectedValue(answerOptions),
  );

  const optionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    updateAnswer({ questionId, answer: event.target.value });
  };

  return (
    <>
      <legend className={errorMessage ? "red mb-10" : "mb-10"}>
        {question}
      </legend>
      {instructions && <p className="instructions t-body">{instructions}</p>}
      <RadioGroup
        id={questionId}
        required={true}
        columnClassName="large-12"
        vertical={true}
        title=""
        name={questionId}
        labelClassName="t-body"
        options={answerOptions.map((item) => {
          return {
            label: item.label,
            id: item.answerId,
            value: item.answerId,
          };
        })}
        selectedValue={selectedValue}
        onChange={(evt) => {
          optionChange(evt);
        }}
        errorMessage={errorMessage}
        errorA11y={t("jobsite.common.errorIconLabel") as string}
      ></RadioGroup>
    </>
  );
}
