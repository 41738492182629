import { Overlay } from "@rpe-js/marcom-web-components";
import React, { SetStateAction, useCallback, useMemo } from "react";
import { SaveCancelAction } from "../../components/feature/saveAndCancel";
import { SET_FILTERS_MOBILE } from "../../contexts/actionTypes";
import { FilterValue, SEARCH_FILTER_TYPE } from "../../contexts/SearchContext";
import useIntlMessage from "../../hooks/useIntlMessage";
import { useSearchContext } from "../../hooks/useSearchContext";
import { idGenerator } from "../../utils/idGenerator";
import CreateSaveSearch from "./CreateSaveSearch";
import FiltersSectionAccordion from "./FiltersSectionAccordion";

interface Props {
  elementIdToFocusOnClose: string;
  isMobileFiltersOpen: boolean;
  clearFilters: () => void;
  setIsMobileFiltersOpen: React.Dispatch<SetStateAction<boolean>>;
}

type MobileFilters = Record<SEARCH_FILTER_TYPE, FilterValue>;

function FiltersSectionMobileModal({
  elementIdToFocusOnClose,
  isMobileFiltersOpen,
  clearFilters,
  setIsMobileFiltersOpen,
}: Props) {
  const { t } = useIntlMessage();
  const { state, dispatch } = useSearchContext();
  const mobileFilters: MobileFilters = useMemo(() => {
    return { ...state.filters } as MobileFilters;
  }, [state.filters]);

  // every filter update triggers this method and updates the mobileFilters object
  const onMobileFilterChange = useCallback(
    (filterType: SEARCH_FILTER_TYPE, filterValue: FilterValue) => {
      mobileFilters[filterType] = filterValue;
    },
    [mobileFilters],
  );

  // on apply, dispatch an action to update all the filters state
  const onMobileApplyFilters = useCallback(() => {
    dispatch({ type: SET_FILTERS_MOBILE, payload: mobileFilters });
  }, [dispatch, mobileFilters]);

  return (
    <Overlay
      id={idGenerator("search", "filtersmobile").generateId("overlay")}
      elementIdToFocus={elementIdToFocusOnClose}
      visible={isMobileFiltersOpen}
      onClose={() => setIsMobileFiltersOpen(false)}
      noPadding={true}
      isFullscreen={true}
      noCloseButton={false}
      disableEsc={false}
      classes={{ root: "custom-overlay-fullscreen", content: "flex-column" }}
      footerContent={
        <div className="d-flex justify-center p-20 u-border-top">
          <SaveCancelAction
            classes="small-flex-column"
            onCancel={() => {
              clearFilters();
              setIsMobileFiltersOpen(false);
            }}
            onSave={() => {
              onMobileApplyFilters();
              return setIsMobileFiltersOpen(false);
            }}
            cancelLabelName={t("jobsite.common.clearAll") as string}
            saveLabelName={t("jobsite.search.applyFilters") as string}
            cancelButtonId={idGenerator("filter", "mobile-modal").generateId(
              "cancel-button",
            )}
            saveButtonId={idGenerator("filter", "mobile-modal").generateId(
              "save-button",
            )}
          />
        </div>
      }
      stickyClose={true}
      closeButtonAttrs={{
        ariaLabel: t("jobsite.common.cancel") as string,
        stickyClose: true,
        alignStart: true,
      }}
    >
      <div className="filter-modal pos-rel u-border-top">
        <CreateSaveSearch
          id={idGenerator("filter", "save-search").generateId()}
        />
        <h5 className={"t-body-reduced fw-medium u-border-bottom pb-15"}>
          {t("jobsite.search.refineBy")}
        </h5>
        <FiltersSectionAccordion onMobileFilterChange={onMobileFilterChange} />
      </div>
    </Overlay>
  );
}

export default FiltersSectionMobileModal;
