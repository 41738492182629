import * as React from "react";
import { useContext, useRef, useState } from "react";
import { LocationPickerContext } from "../../contexts/LocationPickerContext";
import useIntlMessage from "../../hooks/useIntlMessage";
import { LocationPickerStoreCard } from "./LocationPickerStoreCard";

export function LocationPickerResults() {
  const context = useContext(LocationPickerContext);
  if (!context) {
    throw new Error(
      "LocationPickerHeader must be used within a LocationPickerProvider",
    );
  }
  const {
    zipResults,
    zipResultsError,
    zipResultsLoading,
    locationResults,
    locationResultsError,
    locationResultsLoading,
    search,
    zip,
    mode,
    postLocation,
  } = context;
  const { t } = useIntlMessage();

  const loadingCards = new Array(6).fill(0);
  const results = mode === "zip" ? zipResults : locationResults;
  const resultsLoading =
    mode === "zip" ? zipResultsLoading : locationResultsLoading;
  const resultsError = mode === "zip" ? zipResultsError : locationResultsError;
  const userInput = !!(zip || postLocation);
  const storeCount = results && results.length > 0 ? results.length : " ";
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const itemRefs = useRef<(HTMLLIElement | null)[]>([]);
  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLLIElement>,
    index: number,
  ) => {
    let newIndex: number = index;

    if (event.key === "ArrowDown") {
      newIndex = (index + 1) % results.length; // Move to next item
    } else if (event.key === "ArrowUp") {
      newIndex = (index - 1 + results.length) % results.length; // Move to previous item
    } else {
      return;
    }

    setActiveIndex(newIndex);
    itemRefs.current[newIndex]?.focus();
  };
  return (
    <section className="results-section">
      <div className="w-100" role="region" aria-live="polite">
        {
          /* loading or results */
          mode === "zip" &&
            (resultsLoading || (results && results.length > 0)) && (
              <h3 className="t-callout text-center">
                {t("jobsite.locationPicker.storesNearZip", {
                  storeCount: storeCount,
                  zip: zip,
                })}
              </h3>
            )
        }
        {
          /** loading or results */
          mode === "location" &&
            (resultsLoading || (results && results.length > 0)) && (
              <h3 className="t-callout text-center">
                {t("jobsite.locationPicker.storesInCity", {
                  storeCount,
                  city: search,
                })}
              </h3>
            )
        }
        {
          /* No Results */
          (!resultsLoading || resultsError) &&
            results &&
            results.length === 0 &&
            userInput && (
              <div className="no-results-section">
                <h3 className="t-callout text-center">
                  {t("jobsite.common.noResultsFound")}
                </h3>
                <p className="no-results-text text-center">
                  {t("jobsite.locationPicker.enterLocation")}
                </p>
              </div>
            )
        }

        {
          /* Loading State */
          resultsLoading && (
            <ul
              className="results-card-container ml-0 u-list-style-none"
              role="list"
            >
              {loadingCards.map((_, index) => (
                <LocationPickerStoreCard loading={resultsLoading} key={index} />
              ))}
            </ul>
          )
        }
      </div>
      {
        /* Results State */
        !resultsLoading && !resultsError && results && results.length > 0 && (
          <ul
            className="results-card-container ml-0 u-list-style-none"
            role="list"
          >
            {results.map((store, index) => (
              <LocationPickerStoreCard
                isActive={activeIndex}
                ref={(el) => (itemRefs.current[index] = el)}
                key={store.locationId}
                city={store.city}
                storeName={store.name}
                hiringNow={store.currentOpening}
                locationId={store.locationId}
                showPayAndBenefits={store.showPayAndBenefits}
                coverImage={""}
                loading={resultsLoading}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            ))}
          </ul>
        )
      }
    </section>
  );
}
