import { PAGE_TITLES } from "../../../hooks/useSetPageTitle";
import {
  ApplicationAllowedSteps,
  ApplicationSection,
  SectionMode,
} from "./context/types";

/**
 * Do not use this for server side unique id generations
 * Should be used only for client component object modal id's
 * @returns a 6 char random id
 */
export function generateUID(): string {
  // I generate the UID from two parts here
  // to ensure the random number provide enough bits.
  const firstPart = (Math.random() * 46656) | 0;
  const secondPart = (Math.random() * 46656) | 0;
  const firstPartStr = ("000" + firstPart.toString(36)).slice(-3);
  const secondPartStr = ("000" + secondPart.toString(36)).slice(-3);
  return firstPartStr + secondPartStr;
}

export function getNextAndPreviousStep(
  currentStep: ApplicationAllowedSteps,
  stepMapping: Record<ApplicationAllowedSteps, number>,
): {
  next: ApplicationAllowedSteps;
  prev: ApplicationAllowedSteps;
} {
  let next: ApplicationAllowedSteps = "selfDisclosure";
  let prev: ApplicationAllowedSteps = "selfDisclosure";

  const steps = Object.values(stepMapping);
  const stepKeys: ApplicationAllowedSteps[] = Object.keys(
    stepMapping,
  ) as ApplicationAllowedSteps[];
  const curr = stepMapping[currentStep];
  const currIdx = steps.indexOf(curr);

  if (currIdx > 0 && currIdx < steps.length - 1) {
    next = stepKeys[currIdx + 1];
    prev = stepKeys[currIdx - 1];
  } else if (currIdx === steps.length - 1) {
    next = stepKeys[0];
    prev = stepKeys[currIdx - 1];
  } else {
    next = stepKeys[1];
    prev = stepKeys[0];
  }
  return { next, prev };
}

export function getNextSection(
  current: ApplicationSection,
): ApplicationSection {
  switch (current) {
    case "contactInformation": {
      return "employmentBackground";
    }
    case "employmentBackground": {
      return "education";
    }
    case "education": {
      return "professionalExperience";
    }
    case "professionalExperience": {
      return "references";
    }
    case "references": {
      return "legal";
    }
    default: {
      return "contactInformation";
    }
  }
}
export function getNextSectionState(current?: SectionMode): SectionMode {
  if (!current || current === "disabled") {
    return "create";
  }

  if (current === "create") {
    return "view";
  }

  if (current === "view") {
    return "edit";
  }

  return "disabled";
}
export function getPageTitle(currentStep: ApplicationSection): string {
  switch (getNextSection(currentStep)) {
    case "employmentBackground": {
      return PAGE_TITLES.YOUR_APPLICATION.BACKGROUND;
    }
    case "education": {
      return PAGE_TITLES.YOUR_APPLICATION.EDUCATION;
    }
    case "professionalExperience": {
      return PAGE_TITLES.YOUR_APPLICATION.EXPERIENCE;
    }
    case "references": {
      return PAGE_TITLES.YOUR_APPLICATION.REFERENCE;
    }
    case "legal": {
      return PAGE_TITLES.YOUR_APPLICATION.LEGAL;
    }
  }
  return PAGE_TITLES.YOUR_APPLICATION.CONTACT_INFO;
}
