import { ProgressIndicatorLoader } from "@rpe-js/marcom-web-components";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { SkillByInputRefData } from "../../../../shared/types/refData";
import { Skill } from "../../../../shared/types/talent/talent";
import { updateTalent } from "../../../api/fetchClient";
import { PROFILE_SECTIONS } from "../../../app.constants";
import { SaveCancelAction } from "../../../components/feature/saveAndCancel";
import { useAppAlertContext } from "../../../contexts/AppAlert";
import { useFetchData } from "../../../hooks/useFetchData";
import useFocusFirstErrorField from "../../../hooks/useFocusFirstErrorField";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { handleScrollToElement } from "../../../utils/focusUtil";
import { idGenerator } from "../../../utils/idGenerator";
import { addSkills } from "../../../utils/skillUtils";
import { updateSkills } from "../context/Actions";
import { ProfileContext } from "../context/ProfileContext";
import { EditSkills } from "./editSkills";
import { ProfileSection } from "./profileSection";

export const SkillsSection = () => {
  const [isEdit, setEdit] = useState<boolean>(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { t } = useIntlMessage();
  const profileContext = useContext(ProfileContext);
  const skillsIdGenerator = idGenerator("profile", "skills");
  const skillsSectionId = skillsIdGenerator.generateId();
  const { setFocusFirstErrorField } = useFocusFirstErrorField(skillsSectionId);
  const [tempSkills, setTempSkills] = useState<Skill[]>(
    profileContext?.state?.skills?.length
      ? [...profileContext?.state?.skills]
      : [],
  );
  const {
    isLoading: isSaveTalentLoading,
    isSuccess: isSaveTalentSuccess,
    fetchData: saveTalent,
    isError: isSaveTalentError,
  } = useFetchData(updateTalent);
  const { updateAlert, deleteAlert } = useAppAlertContext();

  const handleAddSkill = (skillToAdd: SkillByInputRefData) => {
    setTempSkills(addSkills([...tempSkills], skillToAdd));
    setErrorMessage(null);
  };

  const handleRemoveSkill = useCallback(
    (index: number) => {
      const updatedSkills = tempSkills.filter((_, i) => i !== index);
      setTempSkills([...updatedSkills]);
      if (!updatedSkills.length) {
        setErrorMessage(t("jobsite.common.skillserror") as string);
      }
    },
    [t, tempSkills],
  );

  const handleSave = useCallback(() => {
    deleteAlert();
    setSaveClicked(true);
    if (tempSkills.length === 0) {
      setErrorMessage(t("jobsite.common.skillserror") as string);
      setFocusFirstErrorField(true);
      return;
    }
    setErrorMessage(null);
    saveTalent([
      profileContext?.state?.talentId,
      PROFILE_SECTIONS.SKILLS,
      { skills: tempSkills },
    ]);
  }, [
    deleteAlert,
    profileContext?.state?.talentId,
    saveTalent,
    setFocusFirstErrorField,
    t,
    tempSkills,
  ]);

  const handleCancel = useCallback(() => {
    setTempSkills(
      profileContext?.state?.skills?.length
        ? [...profileContext?.state?.skills]
        : [],
    );
    setErrorMessage(null);
    setSaveClicked(false);
    setEdit(false);
  }, [profileContext?.state?.skills]);

  useEffect(() => {
    if (!isSaveTalentLoading && isSaveTalentSuccess) {
      profileContext?.dispatch?.(updateSkills(tempSkills));
      setSaveClicked(false);
      setEdit(false);
      handleScrollToElement(skillsSectionId);
    }
    if (!isSaveTalentLoading && isSaveTalentError) {
      updateAlert(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveTalentLoading, isSaveTalentSuccess, isSaveTalentError]);

  return (
    <ProfileSection
      isEdit={isEdit}
      i18nTitle="jobsite.common.skillsTitle"
      onEdit={() => setEdit(true)}
      id={skillsSectionId}
      sectionName="recruiterManagedSkills"
    >
      {isEdit ? (
        <>
          {isSaveTalentLoading && (
            <ProgressIndicatorLoader showLoading={true} />
          )}
          <div className="row large-8 small-12">
            <EditSkills
              elementToFocusOnClose={skillsIdGenerator.generateId("saveButton")}
              skills={tempSkills}
              mode="profile"
              errorMessage={saveClicked && errorMessage ? errorMessage : ""}
              handleAddSkill={handleAddSkill}
              handleRemoveSkill={handleRemoveSkill}
            />
            <SaveCancelAction
              onSave={handleSave}
              onCancel={handleCancel}
              cancelButtonId={skillsIdGenerator.generateId("cancelButton")}
              saveButtonId={skillsIdGenerator.generateId("saveButton")}
              scrollToTopSectionId={skillsSectionId}
            />
          </div>
        </>
      ) : (
        <div>
          <div className="d-flex flex-wrap gap-8">
            {profileContext?.state?.skills?.length ? (
              profileContext?.state?.skills?.map((skill, index) => (
                <span className="white-space-pre" key={index}>
                  {skill.skillName}
                  {index <
                    (profileContext?.state?.skills?.length as number) - 1 &&
                    ", "}
                </span>
              ))
            ) : (
              <span>{t("jobsite.common.notSpecified") as string}</span>
            )}
          </div>
        </div>
      )}
    </ProfileSection>
  );
};
