import {
  InfoTooltip,
  NativeButton,
  TooltipConfiguration,
} from "@rpe-js/marcom-web-components";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { deleteSavedSearch, getSavedSearches } from "../../api/fetchClient";
import AppContext from "../../AppContext";
import Icon from "../../components/base/IconComponent";
import {
  SET_SAVED_SEARCH_DETAILS,
  SET_SAVED_SEARCH_LIST,
} from "../../contexts/actionTypes";
import { useFetchData } from "../../hooks/useFetchData";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { useSearchContext } from "../../hooks/useSearchContext";
import FiltersListPopover, { FilterItem } from "./FiltersListPopover";
import UnauthenticatedPopover from "./UnauthenticatedPopover";

export interface SavedSearchFilterProps {
  id: string;
}

function SavedSearchFilter({ id }: SavedSearchFilterProps) {
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { isSessionAuthenticated, talentId, locale } = appUIState.appData;
  const {
    state: { savedSearchList, currentSavedSearchData },
    dispatch,
  } = useSearchContext();
  const [showSignInPopover, setShowSignInPopover] = useState(false);
  const [showListPopover, setShowListPopover] = useState(false);

  const [searchIdToRemove, setSearchIdToRemove] = useState("");
  const [hovered, setHovered] = useState(false);
  const isMobile = useIsMobile();

  const {
    isLoading: isListDataLoading,
    isSuccess: isListDataSuccess,
    fetchData: searchData,
    data: savedSearches,
  } = useFetchData(getSavedSearches);

  const {
    isLoading: isDeleteSearchLoading,
    isSuccess: isDeleteSearchSuccess,
    fetchData: deleteSearch,
  } = useFetchData(deleteSavedSearch);

  const onUnAuthenticatedPopupClose = useCallback(() => {
    setShowSignInPopover(false);
  }, []);

  const onSavedSearchFiltersPopupClose = useCallback(() => {
    setShowListPopover(false);
  }, []);

  const savedSearchFiltersHandler = async () => {
    if (!isSessionAuthenticated) {
      setShowSignInPopover(!showSignInPopover);
    } else {
      handleListPopover();
    }
  };

  const handleListPopover = () => {
    if (!showListPopover) {
      searchData([talentId]);
    }
    setShowListPopover(!showListPopover);

    // Update the state only if there is data in currentSavedSearchData
    if (currentSavedSearchData) {
      dispatch({
        type: SET_SAVED_SEARCH_DETAILS,
        payload: null,
      });
    }
  };

  const confirmDeleteSavedSearch = async (id: string) => {
    setSearchIdToRemove(id);
    deleteSearch([talentId, id]);
  };

  const getFilterList = useCallback((): FilterItem[] => {
    return (
      savedSearchList?.map((item) => ({
        id: item.id,
        name: item.searchName,
        url: item.url,
      })) || []
    );
  }, [savedSearchList]);

  const getTooltipConfiguration = () => {
    return {
      overrideDefaultBehaviour: true,
      showOnHover: false,
      show: hovered,
      icon: (
        <Icon
          name={hovered ? "saved-search-blue" : "saved-search-grey"}
          size="medium"
          cursor="pointer"
        ></Icon>
      ),
    } as TooltipConfiguration;
  };

  useEffect(() => {
    if (!isListDataLoading && isListDataSuccess) {
      dispatch({ type: SET_SAVED_SEARCH_LIST, payload: savedSearches });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isListDataLoading, isListDataSuccess]);

  useEffect(() => {
    if (!isDeleteSearchLoading) {
      if (isDeleteSearchSuccess) {
        if (savedSearches && savedSearches.length > 0) {
          const index = savedSearches?.findIndex(
            (item) => item.id === searchIdToRemove,
          );
          if (typeof index === "number" && index >= 0) {
            savedSearches?.splice(index, 1);
          }
        }
        dispatch({ type: SET_SAVED_SEARCH_LIST, payload: savedSearches });
      }
      setSearchIdToRemove("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteSearchLoading, isDeleteSearchSuccess]);

  return (
    <section>
      <NativeButton
        id="saved-search-filters"
        className={"saved-search-filters"}
        onClick={savedSearchFiltersHandler}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        aria-label={t("jobsite.common.saveSearches") as string}
        aria-haspopup
      >
        {!isMobile && (
          <>
            <InfoTooltip
              positionClass="form-tooltip-after ml-0 saved-search-filters-icon"
              tooltipPointer="middle"
              iconLabel={t("jobsite.common.saveSearches") as string}
              setAsHtml={true}
              infoTooltipBody={t("jobsite.common.saveSearches") as string}
              configuration={getTooltipConfiguration()}
            />
            <Icon
              classes="ml-5"
              name={
                showSignInPopover || showListPopover
                  ? "chevron-grey-up"
                  : "chevron-grey-down"
              }
              width="12"
              height="6"
              cursor="pointer"
            />
          </>
        )}

        {isMobile && (
          <Icon name="saved-search-blue" size="medium" cursor="pointer" />
        )}
      </NativeButton>
      {showSignInPopover && !isSessionAuthenticated && (
        <div className="search-unauthenticated">
          <UnauthenticatedPopover
            id={`${id}-unauthenticated-popover`}
            //ariaLabel="labelSavedSearchUnauthenticated"
            //ariaDescription="descSavedSearchUnauthenticated"
            message="jobsite.search.loginSavedSearch"
            triggerElementId="saved-search-filters"
            onClose={onUnAuthenticatedPopupClose}
            view={isMobile ? "overlay" : "popover"}
          ></UnauthenticatedPopover>
        </div>
      )}
      {showListPopover && isSessionAuthenticated && (
        <div className="saved-search-filters-popover">
          <FiltersListPopover
            id="search-list-popover"
            {...{
              "aria-labelledby": "labelSavedSearchFilters",
              "aria-describedby": "descSavedSearchFilters",
            }}
            manageLabel="jobsite.search.manageSearches"
            noDataLabel="jobsite.search.noDataFound"
            noDataSubLabel="jobsite.search.promptMessage"
            deleteLabel="jobsite.search.deleteMessage"
            manageLink={`/app/${locale}/profile/savedsearch`}
            listAriaLabel={t("jobsite.common.saveSearches") as string}
            showManageIcon={true}
            showCount={true}
            showNoDataLabel={!savedSearchList || savedSearchList.length === 0}
            isRemoveOptionAvail={true}
            isLoading={isListDataLoading || isDeleteSearchLoading}
            isDeleteSuccess={isDeleteSearchSuccess}
            filterList={getFilterList()}
            triggerElementId="saved-search-filters"
            onClose={onSavedSearchFiltersPopupClose}
            onConfirmDelete={(id) => confirmDeleteSavedSearch(id)}
          ></FiltersListPopover>
        </div>
      )}
    </section>
  );
}

export default SavedSearchFilter;
