import { getDefaultTitle } from "../../utilities/util";
import {
  MappedLanguages,
  MappedPostLocation,
  MappedProductsAndServices,
  MappedRetailRoles,
  MappedTeams,
} from "../../shared/types/refData";
import { HiringManagerInfo } from "../types/HiringManagerInfo";
import { FilterOption } from "../pages/search/FilterCheckboxGroup";
import { Filters } from "../contexts/SearchContext";
import APP_CONSTANTS from "../../utilities/appConstants";
import { I18nValues } from "../../shared/types";
import React from "react";
import { getPaginationLinks } from "./metaLinksUtil";

const ImageMapping: {
  [locale: string]: { [jobType: string]: string; default: string };
} = {
  "en-us": {
    [APP_CONSTANTS.JOB_TYPE.RETAIL]: "retail.png",
    [APP_CONSTANTS.JOB_TYPE.CORPORATE]: "corporate.png",
    default: "default.png",
  },
};

export function getOGImageURL(
  baseUrl: string,
  locale: string,
  jobType?: string,
) {
  const rootImagePath = `${baseUrl}${APP_CONSTANTS.OG_IMAGE_PATH}`;
  if (!jobType) {
    return ImageMapping[locale] && ImageMapping[locale].default
      ? `${rootImagePath}/${locale}/${ImageMapping[locale].default}`
      : `${rootImagePath}/en-us/${ImageMapping["en-us"].default}`;
  }
  return ImageMapping[locale] && ImageMapping[locale][jobType]
    ? `${rootImagePath}/${locale}/${ImageMapping[locale][jobType]}`
    : `${rootImagePath}/en-us/${ImageMapping["en-us"][jobType]}`;
}

export function getAppliedFilterNames(
  filters: Filters | null | undefined,
): string[] {
  if (!filters) {
    return [];
  }
  const appliedNames: string[] = [];
  for (const key in filters) {
    if (
      filters.hasOwnProperty(key) &&
      filters[key as keyof Filters] !== undefined
    ) {
      const filterValues = filters[key as keyof Filters];
      if (Array.isArray(filterValues)) {
        for (const item of filterValues) {
          let name: string | undefined = undefined;
          if (filters.homeOffice) {
            // Use optional chaining and type guard for name property
            if (
              item &&
              typeof item === "object" &&
              "name" in item &&
              typeof item.name === "string"
            ) {
              name = item.name;
            }
          } else if (isMappedPostLocation(item)) {
            // Check specifically for MappedPostLocation
            name = item.titleName || item.displayName || item.name;
          } else if (isMappedTeams(item)) {
            name = item.name;
          } else if (isMappedProductsAndServicesOrLanguages(item)) {
            // New type guard
            name = item.displayName || item.name; // Use displayName or name, NO titleName
          } else if (isHiringManagerInfo(item)) {
            name = item.fullName || item.name;
          } else if (isFilterOption(item)) {
            name = item.name;
          } else if (isMappedRetailRoles(item)) {
            name = item.name;
          } else if (typeof item === "string") {
            name = item;
          }
          if (name) {
            appliedNames.push(name);
          }
        }
      } else if (typeof filterValues === "string") {
        appliedNames.push(filterValues);
      } else if (key === "homeOffice" && filterValues === true) {
        // Handle homeOffice
        appliedNames.push("Home Office");
      }
    }
  }
  return appliedNames;
}

// Type guard functions
function isMappedProductsAndServicesOrLanguages(
  item: any,
): item is MappedProductsAndServices | MappedLanguages {
  return (
    item &&
    typeof item.id === "string" &&
    typeof item.name === "string" &&
    typeof item.displayName === "string"
  );
}

function isMappedPostLocation(item: any): item is MappedPostLocation {
  return (
    item &&
    (typeof item.displayName === "string" ||
      typeof item.titleName === "string" ||
      typeof item.name === "string")
  );
}

function isMappedTeams(item: any): item is MappedTeams {
  return (
    item &&
    (typeof item.displayName === "string" ||
      typeof item.titleName === "string" ||
      typeof item.name === "string")
  );
}

function isHiringManagerInfo(item: any): item is HiringManagerInfo {
  return (
    item && (typeof item.fullName === "string" || typeof item.name === "string")
  );
}

function isFilterOption(item: any): item is FilterOption {
  return (
    item &&
    typeof item.id === "string" &&
    typeof item.name === "string" &&
    typeof item.selected === "boolean"
  );
}

function isMappedRetailRoles(item: any): item is MappedRetailRoles {
  return item && typeof item.id === "string" && typeof item.name === "string";
}

export function getSearchPageTitle(
  t: (id: string, values?: I18nValues) => React.ReactNode,
  appliedFiltersNames: string[],
  appUIState: any,
  requestUrl: string,
  searchMeta: any,
  totalRecords: number,
  page: number,
) {
  let pageTitle;
  let description;
  const countryCode = appUIState.appData.countryCode;
  const country = countryCode.countryLocaleCode;

  const tranJobsAtApple = getDefaultTitle(
    appUIState.appData.isInternal,
    appUIState.appData.locale,
    t,
  );
  const tranMultipleFiltersApplied = t(
    "jobsite.search.multipleFiltersApplied",
  ) as string;
  const tranGenericMetaDesc = t(
    "jobsite.search.genericMetaDescription",
  ) as string;

  // When there is no filters applied, update title and meta description with
  // generic title and description. "Jobs at Apple".
  if (appliedFiltersNames.length === 0) {
    pageTitle = tranJobsAtApple;
    description = tranGenericMetaDesc;
  } else if (
    appliedFiltersNames.length > 0 &&
    appliedFiltersNames.length <= 2
  ) {
    // When only one filter in any category applied, update tile and meta description
    // with applied filter value. "Software Engineer - Jobs at Apple".
    const name = appliedFiltersNames.join(", ");
    pageTitle = `${name} - ${tranJobsAtApple}`;
    description =
      appliedFiltersNames.length > 1
        ? tranGenericMetaDesc
        : (t("jobsite.search.metaTagDecForFilters", {
            filterName: name,
          }) as string);
  } else {
    // When multiple filters applied, update title with generic title.
    // "Multiple Filters - Jobs at Apple".
    pageTitle = `${tranMultipleFiltersApplied} - ${tranJobsAtApple}`;
    description = tranGenericMetaDesc;
  }

  // Update title with country name.
  if (country && country.length > 0) {
    pageTitle = `${pageTitle} ${country ? `(${country})` : ""}`;
  }
  const imageUrl =
    getOGImageURL(appUIState.appData.baseUrl, appUIState.appData.locale) || "";

  const meta = [
    { name: "description", content: description },
    { property: "og:type", content: "website" },
    { property: "og:title", content: pageTitle },
    { property: "og:url", content: requestUrl },
    { property: "og:description", content: description },
    {
      property: "og:image",
      content: imageUrl,
    },
  ];
  let links = [];
  if (searchMeta.meta && searchMeta.meta.length > 0) {
    meta.push(searchMeta.meta[0]);
  } else {
    links =
      searchMeta.links && searchMeta.links.length > 0 ? searchMeta.links : [];
  }
  const paginationLinks = getPaginationLinks(requestUrl, totalRecords, page);
  if (links) {
    links = [...links, ...paginationLinks];
  } else {
    links = [...paginationLinks];
  }

  return { pageTitle, meta, links };
}
