import {
  Button,
  MenuItemProps,
  MenuList,
  ProgressIndicatorLoader,
  TextButton,
} from "@rpe-js/marcom-web-components";
import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { Role } from "../../../../shared/types/role";
import {
  deleteTalentRole,
  getTalentRoles,
  saveQuestionnaire,
} from "../../../api/fetchClient";
import { Label } from "../../../components/base/Label";
import { useAppAlertContext } from "../../../contexts/AppAlert";
import { usePageAlertContext } from "../../../contexts/PageAlert";
import { useCurrentUserContext } from "../../../CurrentUserContext";
import { useFetchData } from "../../../hooks/useFetchData";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import useSetPageTitle, { PAGE_TITLES } from "../../../hooks/useSetPageTitle";
import { idGenerator } from "../../../utils/idGenerator";
import MobileFiltersModal from "./MobileFiltersModal";
import { QuestionaireModal } from "./QuestionaireModal";
import RemoveAllModal from "./RemoveAllModal";
import { RoleList } from "./RoleList";
import { RolesContext } from "./RolesContext";
import { getRolesInitialState, reducer } from "./rolesReducer";
import { getRoleUiObj } from "./roleUtils";
import { SelectedLocationsModal } from "./SelectedLocationsModal";
import { RoleSectioName, RoleUIObj } from "./types";

export function YourRoles() {
  const { t } = useIntlMessage();
  const { currentUser, dispatch: currentUserDispatch } =
    useCurrentUserContext();
  const { setPageTitle } = useSetPageTitle();
  const { talentId } = currentUser;
  const isMobile = useIsMobile();
  const { updateAlert, deleteAlert } = useAppAlertContext();
  const { updateAlert: updatePageAlert } = usePageAlertContext();
  const mobileFilterButtonId = idGenerator(
    "yourroles",
    "mobilefilterbutton",
  ).generateId();
  const filterRolesOnMobileBtnId = useMemo(
    () => idGenerator("yourroles", "mobile-filter").generateId("button"),
    [],
  );
  const [state, dispatch] = useReducer(
    reducer,
    getRolesInitialState(talentId || ""),
  );
  const {
    selected,
    showRemoveAllUnavailableLink,
    showLocationModal,
    locationModalRole,
    showQuestionaireModal,
    showRemoveAllModal,
    questionaireModalRole,
    showFilterModal,
    mobileFilter,
  } = state;

  const { data, isLoading, isError, fetchData } =
    useFetchData<Role[]>(getTalentRoles);

  const {
    isLoading: isSaveQuestionnaireLoading,
    fetchData: submitQuestionnaire,
    isSuccess: isSaveQuestionnaireSuccess,
  } = useFetchData(saveQuestionnaire);

  useEffect(() => {
    setPageTitle(PAGE_TITLES.PROFILE.ROLES);
  }, [setPageTitle]);

  const talentRolesQueryParams = useCallback(() => {
    return { queryParams: { role: "yourRole" } };
  }, []);

  useEffect(() => {
    if (talentId && selected === "All" && !isLoading && !data) {
      fetchData([[talentId], talentRolesQueryParams()]);
    }
  }, [talentId, fetchData, selected, isLoading, data, talentRolesQueryParams]);

  useEffect(() => {
    if (selected === "All" && !isLoading && data) {
      const transformed = (data || []).map((r) => getRoleUiObj(r, selected));
      dispatch({ type: "SET_ROLES", payload: transformed });
      // set total roles count
      currentUserDispatch({
        type: "SET_ROLES_COUNT",
        payload: data.length,
      });
    }
    if (!isLoading && isError) {
      updatePageAlert(true, true, t("jobsite.error.talentNotFound") as string);
    }
  }, [
    currentUserDispatch,
    data,
    isError,
    isLoading,
    selected,
    t,
    updatePageAlert,
  ]);

  const sectionList: Array<MenuItemProps> = useMemo(() => {
    return [
      {
        text: t("jobsite.profile.yourRoles.all"),
        menuId: "All",
      },
      {
        text: t("jobsite.profile.yourRoles.favorites"),
        menuId: "Favorites",
      },
      {
        text: t("jobsite.profile.yourRoles.submissions"),
        menuId: "Submissions",
      },
    ] as Array<MenuItemProps>;
  }, [t]);

  const highlightMenuIndex = useCallback((selectedMenuIndex: number) => {
    // Get all menu items
    const elements = document.querySelectorAll(
      "#yourroles-type .rc-menu-items li button",
    );
    // remove the existing menu item background
    for (const element of elements) {
      element.classList.remove("background-fill-tertiary");
    }
    // highlight the current menu item
    elements[selectedMenuIndex].classList.add("background-fill-tertiary");
  }, []);

  const onChange = useCallback(
    (item: MenuItemProps) => {
      if (selected !== "All" && item.menuId === "All" && !isLoading) {
        fetchData([[talentId], talentRolesQueryParams()]);
      }
      dispatch({
        type: "SET_SELECTED_FILTER",
        payload: item.menuId as RoleSectioName,
      });
      // find the menu item which needs to be highlighted
      const selectedMenuIndex =
        item.menuId == "All" ? 0 : item.menuId == "Favorites" ? 1 : 2;
      highlightMenuIndex(selectedMenuIndex);
    },
    [
      selected,
      isLoading,
      highlightMenuIndex,
      fetchData,
      talentId,
      talentRolesQueryParams,
    ],
  );

  const onRemoveAllRoles = useCallback(async () => {
    deleteAlert();
    try {
      await deleteTalentRole(talentId as string);
      dispatch({
        type: "REMOVE_ALL_INACTIVE_ROLES",
        payload: null,
      });
      updateAlert(
        false,
        false,
        t("jobsite.profile.yourRoles.removeallsuccess") as string,
      );
    } catch (e) {
      updateAlert(false);
    }
  }, [deleteAlert, t, talentId, updateAlert]);

  const onRemoveAllModalClose = useCallback(() => {
    dispatch({
      type: "SET_SHOW_REMOVE_ALL_MODAL",
      payload: false,
    });
  }, []);

  const onSubmit = useCallback(
    (payload: any) => {
      submitQuestionnaire([
        talentId,
        payload.connectionId,
        payload.locale,
        payload,
      ]);
      dispatch({ type: "CLOSE_QUESTIONAIRE_MODAL", payload: true });
    },
    [submitQuestionnaire, talentId],
  );

  const getSelectedFilterLabel = useCallback(() => {
    if (selected === "All") {
      return t("jobsite.profile.yourRoles.all");
    } else if (selected === "Favorites") {
      return t("jobsite.profile.yourRoles.favorites");
    } else return t("jobsite.profile.yourRoles.submissions");
  }, [selected, t]);

  const onReset = useCallback(() => {
    dispatch({
      type: "SET_SELECTED_FILTER",
      payload: "All",
    });
    dispatch({
      type: "SET_SHOW_FILTER_MODAL",
      payload: false,
    });
    fetchData([[talentId], talentRolesQueryParams()]);
  }, [fetchData, talentId, talentRolesQueryParams]);

  const onMobileFilterClose = useCallback(
    () =>
      dispatch({
        type: "SET_SHOW_FILTER_MODAL",
        payload: false,
      }),
    [],
  );

  const onDone = useCallback(() => {
    if (mobileFilter === "All") {
      fetchData([[talentId], talentRolesQueryParams()]);
    }
    dispatch({
      type: "SET_SELECTED_FILTER",
      payload: mobileFilter as RoleSectioName,
    });
    onMobileFilterClose();
  }, [
    fetchData,
    mobileFilter,
    onMobileFilterClose,
    talentId,
    talentRolesQueryParams,
  ]);

  const onMobileMenuChange = useCallback((item: MenuItemProps) => {
    dispatch({
      type: "UPDATE_MOBILE_FILTER",
      payload: item.menuId as RoleSectioName,
    });
  }, []);

  const openRemoveAllModal = useCallback(() => {
    dispatch({
      type: "SET_SHOW_REMOVE_ALL_MODAL",
      payload: true,
    });
  }, []);

  const onMobileFilterClick = useCallback(() => {
    dispatch({ type: "UPDATE_MOBILE_FILTER", payload: selected });
    dispatch({
      type: "SET_SHOW_FILTER_MODAL",
      payload: true,
    });
  }, [selected]);

  //useEffect to update the role after questionnaire is submitted
  useEffect(() => {
    if (!isSaveQuestionnaireLoading && isSaveQuestionnaireSuccess) {
      dispatch({ type: "UPDATE_RESET" });
    }
  }, [isSaveQuestionnaireLoading, isSaveQuestionnaireSuccess]);

  //useEffect to close mobile filter modal when isMobile changes
  useEffect(() => {
    dispatch({ type: "SET_SHOW_FILTER_MODAL", payload: false });
  }, [isMobile]);

  // useEffect that executes on load only once which highlights "All" menu item
  useEffect(() => {
    highlightMenuIndex(0);
  }, [highlightMenuIndex]);

  return (
    <>
      <RolesContext.Provider value={{ state, dispatch }}>
        <div className="d-flex u-border-top mt-10 roles-container">
          <section className="large-3  small-12 u-border-inline-end pt-30 pr-15 roles-right-section">
            <div id={idGenerator("yourroles", "type").generateId()}>
              <div className="u-border-bottom">
                <h2 className="pl-10 label-grey t-eyebrow-reduced">
                  {t("jobsite.profile.yourRoles.type") as string}
                </h2>
                <MenuList
                  id={idGenerator("yourroles", "menulist").generateId()}
                  items={sectionList}
                  classes={{
                    button: "text-align-start",
                  }}
                  handleChange={onChange}
                  role="list"
                  ariaLabel={t("jobsite.yourroles.menuListHint") as string}
                />
              </div>
              {showRemoveAllUnavailableLink && (
                <div className="pl-10 pt-20">
                  <h3>
                    <Label
                      classes={["t-body"]}
                      label={t("jobsite.profile.yourRoles.actions") as string}
                    />
                  </h3>
                  <TextButton
                    onClick={openRemoveAllModal}
                    classes="t-body-reduced mt-0"
                    id={mobileFilterButtonId}
                    label={
                      t(
                        "jobsite.profile.yourRoles.removeAllUnavailable",
                      ) as string
                    }
                  />
                </div>
              )}
            </div>
          </section>
          <section
            className={`large-9 small-12 ${isMobile ? "pt-20" : "pt-30 pl-15"}`}
          >
            {!isLoading && (
              <div>
                {isMobile && (
                  <Button
                    id={filterRolesOnMobileBtnId}
                    aria-label={`${t("jobsite.profile.yourRoles.filter")}: ${getSelectedFilterLabel()}`}
                    color="secondary"
                    onClick={onMobileFilterClick}
                  >
                    {`${t("jobsite.profile.yourRoles.filter")}: `}
                    <strong>{getSelectedFilterLabel()}</strong>
                  </Button>
                )}
                <RoleList />
              </div>
            )}
            {(isLoading || isSaveQuestionnaireLoading) && (
              <ProgressIndicatorLoader showLoading={true} />
            )}
            {isError && <div> {"Error while fetching Roles"}</div>}
          </section>
        </div>
        {showLocationModal && (
          <SelectedLocationsModal
            role={locationModalRole as RoleUIObj}
            talentId={talentId as string}
            onClose={() =>
              dispatch({ type: "CLOSE_LOCATION_MODAL", payload: true })
            }
          />
        )}
        {showQuestionaireModal && (
          <QuestionaireModal
            role={questionaireModalRole as RoleUIObj}
            talentId={talentId as string}
            onClose={() =>
              dispatch({ type: "CLOSE_QUESTIONAIRE_MODAL", payload: true })
            }
            onSubmit={onSubmit}
          />
        )}
        {showRemoveAllModal && (
          <RemoveAllModal
            elementIdToFocus={mobileFilterButtonId}
            onRemoveAllRoles={onRemoveAllRoles}
            onRemoveAllModalClose={onRemoveAllModalClose}
          />
        )}
        {showFilterModal && isMobile && (
          <MobileFiltersModal
            onModalCloseFocusElementId={filterRolesOnMobileBtnId}
            onReset={onReset}
            onDone={onDone}
            showRemoveAllUnavailableLink={showRemoveAllUnavailableLink}
            menuItems={sectionList}
            onChange={onMobileMenuChange}
            onMobileFilterClose={onMobileFilterClose}
            openRemoveAllModal={openRemoveAllModal}
          />
        )}
      </RolesContext.Provider>
    </>
  );
}
