import { NativeButton, Textbox } from "@rpe-js/marcom-web-components";
import React, { useEffect, useRef, useState } from "react";
import useIsMobile from "../../../hooks/useIsMobile";
import { focusElement } from "../../../utils/focusUtil";
import { idGenerator, ModuleName } from "../../../utils/idGenerator";
import Icon from "../../base/IconComponent";

const IMAGE = "I";
const AUDIO = "A";

const APP_CONSTANTS = {
  CAPTCHA: {
    ERROR_MOBILE: "captcha-error-mobile",
    ERROR_DESKTOP: "captcha-error-desktop",
    ERROR_CODE_INVALID: "-1002",
    ERROR_CODE_EXPIRED: "-1004",
  },
};

interface ShareJobCaptchaProps {
  captchaType: string;
  isMobile?: boolean;
  captchaErrorCode?: string;
  isCaptchaValid?: boolean;
  captchaSource: string;
  emailIsValid: boolean;
  isSubmit: boolean;
  getCaptchaDetails: (captchaType: string) => void;
  t?: (idToTranslate: string) => string;
  setEmail?: (email: string) => void;

  email?: string;
  captchaAnswer?: string;
  setCaptchaType: (type: string) => void;
  setIsCaptchaAnswerValid: (isCaptchaAnswerValid: boolean) => void;
  setCaptchaAnswer: (answer: string) => void;
  isCaptchaAnswerValid?: boolean;
}

export default function ShareJobCaptcha({
  captchaType = IMAGE,
  email,
  captchaAnswer,
  captchaErrorCode,
  isCaptchaValid = true,
  captchaSource,
  isSubmit,
  getCaptchaDetails,
  setEmail,
  setIsCaptchaAnswerValid,
  setCaptchaAnswer,
  setCaptchaType,
  isCaptchaAnswerValid = true,
  emailIsValid = true,

  t = (str1: string) => {
    return str1;
  },
}: ShareJobCaptchaProps) {
  const jobdetails = "job-details" as ModuleName;
  const [innerCaptchaType, setInnerCaptchaType] = useState(captchaType);
  const newCodeButtonRef = useRef(null);
  const typeChangeButtonRef = useRef(null);
  const isMobile = useIsMobile();
  const emailErrorMessage = t("jobsite.common.validemail") as string;
  const [captchaErrorMessage, setCaptchaErrorMessage] = useState(
    t("jobsite.search.errorCaptcha") as string,
  );
  const newChallengeButtonId = idGenerator(
    jobdetails,
    "newchallengebutton",
  ).generateId();

  const handleChangeEmail = (mailId: string) => {
    if (setEmail) {
      //because setEmail is optional since component is reusable
      setEmail(mailId.length > 0 ? mailId.toString() : "");
    }
  };

  const onCaptchaTypeChange = () => {
    // if type was image - change it to audio; else vice versa
    captchaType = captchaType === IMAGE ? AUDIO : IMAGE;

    setCaptchaType(captchaType);
    setInnerCaptchaType(captchaType);
    getCaptchaDetails(captchaType);
    focusElement(captchLabelWrapperId);
  };

  const emailInputId = idGenerator("shareJob", "email").generateId("input");
  const captchCodeId = idGenerator("shareJob", "captcha").generateId("answer");
  const captchLabelId = idGenerator("shareJob", "captcha").generateId("label");
  const captchLabelWrapperId = idGenerator("shareJob", "captcha").generateId(
    "wrapper",
  );
  const captchImageAudioId = idGenerator("shareJob", "captcha").generateId(
    "image",
  );

  const imageChallengeContent = (
    <img
      className={`column small-12 ${isMobile ? "w-100" : ""} `}
      src={captchaSource ? `data:image/jpeg;base64,${captchaSource}` : ""}
      alt={t("jobsite.search.challengeText") as string}
      id={`${captchImageAudioId}`}
    />
  );

  const audioChallengeContent = (
    <audio
      className="w-90"
      controls
      preload="auto"
      id={`${captchImageAudioId}`}
      key={captchaSource ? `data:audio/wav;base64,${captchaSource}` : ""}
    >
      <source
        src={captchaSource ? `data:audio/wav;base64,${captchaSource}` : ""}
        type="audio/x-wav"
      ></source>
      {t("jobsite.search.notSupportAudioMessage") as string}
    </audio>
  );

  //no special characters in captcha answer
  const validateCaptcha = (captcha: any) => {
    const reg = /^[A-Za-z0-9]+$/g;
    return captcha && reg.test(captcha);
  };

  const handleChangeCaptchaAnswer = (answer: string) => {
    const captchaValid = answer.length > 0 ? validateCaptcha(answer) : true;

    setIsCaptchaAnswerValid(captchaValid);
    if (captchaValid) {
      setCaptchaAnswer(answer);
      setCaptchaErrorMessage("");
    } else {
      //else focus and show error
      setCaptchaErrorMessage(t("jobsite.search.errorCaptcha") as string);
    }
  };

  useEffect(() => {
    if (captchaErrorCode === APP_CONSTANTS.CAPTCHA.ERROR_CODE_INVALID) {
      setCaptchaErrorMessage(t("jobsite.jobdetails.validCaptcha") as string);
    } else if (captchaErrorCode === APP_CONSTANTS.CAPTCHA.ERROR_CODE_EXPIRED) {
      setCaptchaErrorMessage(t("jobsite.common.captchaExpired") as string);
    } else if (!isCaptchaValid) {
      setCaptchaErrorMessage(t("jobsite.search.errorCaptcha") as string);
    }
  }, [captchaErrorCode, isCaptchaValid, setCaptchaErrorMessage, t]);

  return (
    <div className={"form-group"}>
      <label
        htmlFor={emailInputId}
        className={`${isMobile ? "t-body-reduced" : "t-body"}`}
      >
        {t("jobsite.search.emailTo")}
      </label>
      <div className="column large-12 small-12  mb-20 align-self-start mt-5">
        <Textbox
          id={emailInputId}
          required={true}
          error={!emailIsValid ? emailErrorMessage : ""} //pass from parent if not valid
          errorA11y={t("jobsite.common.errorIconLabel") as string}
          value={email}
          label={t("jobsite.search.emailToShadow") as string}
          onValueChange={handleChangeEmail}
          aria-required={"true"}
        ></Textbox>
      </div>
      <div className="row align-self-start" id={captchLabelWrapperId}>
        <label
          htmlFor="captcha"
          className={`${isMobile ? "t-body-reduced" : "t-body"}`}
          id={captchLabelId}
        >
          {innerCaptchaType === IMAGE
            ? (t("jobsite.shareRole.typeMessageSee") as string)
            : (t("jobsite.shareRole.typeMessageHear") as string)}
        </label>
      </div>
      <div
        className="row mt-10 u-border-top  u-border-bottom u-border-left u-border-right"
        role="group"
        aria-labelledby={captchLabelId}
      >
        <div className="column large-5 small-12">
          <div className={`column  text-center ${isMobile ? "m-20" : "mt-20"}`}>
            {innerCaptchaType === IMAGE
              ? imageChallengeContent
              : audioChallengeContent}
          </div>
        </div>
        <div className="column align-self-end large-7 small-12 ">
          <div
            className={`column ${isMobile ? "mx-20 mb-20" : "mt-20 mr-20"} 
            ${
              (!isCaptchaAnswerValid || captchaErrorMessage !== "") && isSubmit
                ? "captcha-input is-error"
                : "captcha-input"
            }
          `}
          >
            <Textbox
              required={true}
              id={captchCodeId}
              error={!isCaptchaAnswerValid ? captchaErrorMessage : ""}
              errorA11y={t("jobsite.common.errorIconLabel") as string}
              value={captchaAnswer}
              label={
                innerCaptchaType === IMAGE
                  ? (t("jobsite.search.typeWhatSee") as string)
                  : (t("jobsite.search.typeWhatHear") as string)
              }
              onValueChange={handleChangeCaptchaAnswer}
            ></Textbox>

            <div role="group" aria-labelledby={newChallengeButtonId}>
              <NativeButton
                className="blue row align-self-start mr-20 mb-10 "
                ref={newCodeButtonRef}
                type="button"
                id={newChallengeButtonId}
                onClick={() => {
                  getCaptchaDetails(innerCaptchaType);
                  focusElement(captchImageAudioId);
                }}
              >
                <Icon name={"new-image"} size={"small"}></Icon>
                <span>{t("jobsite.search.newChallengeCode") as string}</span>
              </NativeButton>

              <NativeButton
                className="blue row align-self-start mb-5 mt-5"
                ref={typeChangeButtonRef}
                type="button"
                onClick={onCaptchaTypeChange}
                id={idGenerator(
                  jobdetails,
                  "captchatypechangebutton",
                ).generateId()}
              >
                <Icon
                  name={innerCaptchaType === AUDIO ? "new-text" : "audio"}
                  size={"small"}
                ></Icon>

                <span className="ml-5 blue-text">
                  {innerCaptchaType === IMAGE
                    ? ` ${t("jobsite.search.visionImpaired") as string}`
                    : (t("jobsite.search.textBased") as string)}
                </span>
              </NativeButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
