import { Button, TextArea } from "@rpe-js/marcom-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { MAX_TEXT_RESUME_COUNT } from "../../../app.constants";
import translateReplace from "../../../hooks/translateReplace";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { validateResumeText } from "../../../utils/fileUtil";
import { idGenerator } from "../../../utils/idGenerator";
import HtmlRenderer from "../../base/HtmlRenderer";
import IconButton from "../../base/IconButton";

interface ResumeTextWidgetProps {
  initialText?: string;
  saveResumeText: (resumeText: string) => void;
  closeResumeText: () => void;
}

export function ResumeTextWidget({
  initialText = "",
  saveResumeText,
  closeResumeText,
}: ResumeTextWidgetProps) {
  const { t } = useIntlMessage();
  const [pastedText, setPastedText] = useState(initialText);
  const charCounterTranslated = t("jobsite.common.resumeCharCount", {
    maxCount: MAX_TEXT_RESUME_COUNT.toString(),
  }) as string;
  const [charCountHeader] = useState<string>(
    translateReplace(
      charCounterTranslated,
      ["{maxCount}"],
      [MAX_TEXT_RESUME_COUNT.toString()],
    ),
  );
  const CHARACTER_COUNTER_ID = idGenerator("resume", "text").generateId(
    "character-counter",
  );

  /**
   *
   * @param value
   * This method triggers when text area content changes and updates the character count
   */
  const onTextAreaChange = useCallback((value: string) => {
    setPastedText(value);
    if (document && document.getElementById("counter-1")) {
      // Translation string: "Character count: <span id='counter-1'>0</span> <span>(Min:&nbsp;200 / Max:&nbsp;{{maxCount}})</span>"
      // "counter-1" is the id of element
      (document.getElementById("counter-1") as HTMLElement).textContent =
        value.length.toString();
      if (!validateResumeText(value)) {
        (document.getElementById("counter-1") as HTMLElement).className = "red";
      } else {
        (document.getElementById("counter-1") as HTMLElement).className = "";
      }
    }
  }, []);

  useEffect(() => {
    onTextAreaChange(initialText);
  }, [initialText, onTextAreaChange]);

  return (
    <section className="p-20">
      <div className="text-left pos-rel">
        <h3 id={idGenerator("resume", "text").generateId("title")}>
          {t("jobsite.information.resume")}
        </h3>
        <div className="u-border-bottom">
          <HtmlRenderer
            initialContent={charCountHeader as string}
            htmlTag="div"
            id={CHARACTER_COUNTER_ID}
          />
        </div>
        <span className="pos-abs top-0 right-25">
          <IconButton
            name="x-grey"
            onClick={closeResumeText}
            size="small"
            aria-label={t("jobsite.common.close") as string}
            id={idGenerator("resume", "pastetext").generateId("close-button")}
          ></IconButton>
        </span>
      </div>
      <div className="mt-20">
        <TextArea
          id={idGenerator("resume", "pastetext").generateId()}
          required={false}
          onValueChange={onTextAreaChange}
          placeholder={t("jobsite.common.placeholderCopy") as string}
          value={pastedText}
          classes={{ input: "resume-section-textarea" }}
          aria-describedby={CHARACTER_COUNTER_ID}
          aria-label={t("jobsite.common.placeholderCopy") as string}
          aria-labelledby="" //adding explicitly so VoiceOver announces label, AX requirememnt
        ></TextArea>
      </div>
      <div className="pt-20 pb-20 d-inline-block">
        <Button
          id={idGenerator("resume", "savetext").generateId()}
          label={t("jobsite.common.save") as string}
          size="base"
          blockedVariant={true}
          onClick={() => saveResumeText(pastedText)}
          disabled={!validateResumeText(pastedText)}
        ></Button>
      </div>
    </section>
  );
}
