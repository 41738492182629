import { Overlay, Textbox } from "@rpe-js/marcom-web-components";
import { AccordionItem } from "@rpe-js/marcom-web-components/lib/CustomAccordion/CustomAccordion";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useIdGenerator from "../../hooks/useIdGenerator";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile"; // Assuming this is where your hook is located
import { getCheckboxClasses } from "../../util";
import { mergeSelectedIntoAll } from "../../utils/filterUtil";
import { idGenerator } from "../../utils/idGenerator";
import { FilterCheckboxGroup } from "./FilterCheckboxGroup";
import { MappedKeywordFilterOptions } from "./KeywordFilterWrapper";

interface KeywordFilterProps {
  keywordData: Array<string>;
  onAddUpdate: (keyword: Array<string>) => void;
  onRemoveKeyword: () => void;
}

function KeywordFilter({
  keywordData,
  onRemoveKeyword,
  onAddUpdate,
}: KeywordFilterProps) {
  const [input, setInput] = useState("");
  const [keywords, setKeywords] = useState<MappedKeywordFilterOptions[]>(
    keywordData.map((item) => {
      return Object.assign({}, {
        id: item,
        name: item,
        selected: true,
      } as MappedKeywordFilterOptions);
    }),
  );
  const [count, setCount] = useState(keywords.length);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const { t } = useIntlMessage();
  const ACCORDION_ID = idGenerator(
    "search",
    "keyword-filter-accordion",
  ).generateId();
  const inputRef = useRef<HTMLInputElement>(null); // Ref for the input
  const isMobile = useIsMobile();

  function resetInput() {
    setInput("");
    inputRef.current?.focus();
  }

  function handleAddValue(value: string) {
    setInput(value);
  }

  const handleValueChange = useCallback(
    (e: React.KeyboardEvent) => {
      switch (e.key) {
        case "Enter":
          e.preventDefault();
          const trimmedInput = input.trim();
          if (trimmedInput.length === 0) {
            return;
          }
          const newKeywordObj = {
            id: trimmedInput,
            name: trimmedInput,
            selected: true,
          } as MappedKeywordFilterOptions;
          const exists = keywords.some(
            (keyword) =>
              keyword.name.toLowerCase() === trimmedInput.toLowerCase(),
          );
          if (exists) {
            return;
          }
          const updatedKeywords = [...keywords, newKeywordObj];
          const selectedKeywords = updatedKeywords
            .filter((k) => k.selected)
            .map((k) => k.name);
          setCount(selectedKeywords.length);
          setKeywords(updatedKeywords);
          onAddUpdate(selectedKeywords);
          resetInput();
          setOverlayVisible(false);
          break;
        default:
          break;
      }
    },
    [input, keywords, onAddUpdate],
  );

  function updateKeywordsSelection(id: string, selected: boolean) {
    const updatedKeywords = keywords.map((keyword) => {
      return keyword.id === id ? { ...keyword, selected } : keyword;
    });

    setKeywords(updatedKeywords);
    const selectedKeywords = updatedKeywords.filter(
      (keyword) => keyword.selected,
    );
    if (selectedKeywords.length === 0) {
      onRemoveKeyword();
    } else {
      const arrayOfSelectedKeyword = selectedKeywords.map((k) => k.name);
      onAddUpdate(arrayOfSelectedKeyword);
    }
  }

  useEffect(() => {
    const updatedKeywords = [...mergeSelectedIntoAll(keywords, keywordData)];
    setKeywords(updatedKeywords);
    const selectedKeywords = updatedKeywords.filter(
      (keyword) => keyword.selected,
    );
    setCount(selectedKeywords.length);
    // setFilters({ key: selectedKeywords });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywordData]);

  // Function to render Textbox dynamically
  const keywordFilterTextBoxId = useMemo(() => {
    return idGenerator("filter", "keyword").generateId();
  }, []);

  const renderTextbox = useCallback(
    (
      id: string,
      inputValue: string,
      onInputChange: (value: string) => void,
    ) => {
      return (
        <Textbox
          required={false}
          id={id}
          key={t("jobsite.search.enterKeyword") as string}
          value={inputValue}
          hideLabel={true}
          placeholder={t("jobsite.search.enterKeyword") as string}
          onValueChange={onInputChange}
          onReset={resetInput}
          onKeyDown={handleValueChange}
          resetA11y={t("jobsite.search.clearKeywordVO") as string}
          ref={inputRef}
          onFocus={handleInputFocus}
        />
      );
    },
    [handleValueChange, t],
  );

  // Trigger overlay visibility on textbox click
  const handleInputFocus = () => {
    setOverlayVisible(true);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setOverlayVisible(false);
      }
    };

    const handleKeyDownOutside = (event: KeyboardEvent) => {
      if (event.key === "Escape" || event.key === "Tab") {
        setOverlayVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDownOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDownOutside);
    };
  }, []);

  return (
    <>
      <AccordionItem
        title={`<span class="d-flex-equal fw-medium">${t("jobsite.savedsearch.keyword")}</span>
                <span class=" d-inline-block mr-5 mt-0 ${count > 0 ? "counter" : "d-none"}" aria-hidden="true">${count}</span>
                <span class="a11y">${t("jobsite.common.filterAppliedCount", { count: count })}</span>`}
        titleWrapperTag={"h3"}
        titleWrapperTagAttrs={{
          className: "d-flex",
        }}
        clickableTitle={true}
        expandableIconsPlus={true}
        noPadding={true}
        index={1}
        id={ACCORDION_ID}
      >
        {renderTextbox(keywordFilterTextBoxId, input, handleAddValue)}

        {/* Show Overlay only in mobile view */}
        {isOverlayVisible && isMobile && (
          <Overlay
            id={idGenerator("filter", "keywords").generateId("overlay")}
            elementIdToFocus={"filter-mobile-modal-save-button"}
            visible={isOverlayVisible}
            onClose={() => setOverlayVisible(false)}
            isFullscreen={true}
            noCloseButton={false}
            disableEsc={false}
            classes={{ content: "px-15" }}
            stickyClose={true}
            closeButtonAttrs={{
              ariaLabel: t("jobsite.common.cancel") as string,
              stickyClose: true,
              alignStart: true,
            }}
          >
            <>
              {renderTextbox(
                `${keywordFilterTextBoxId}-overlay`,
                input,
                handleAddValue,
              )}
            </>
          </Overlay>
        )}
        <FilterCheckboxGroup
          id={useIdGenerator("filter", "keyword-checkbox")}
          filterList={keywords}
          onFilterChange={updateKeywordsSelection}
          classNames={getCheckboxClasses(isMobile)}
        />
      </AccordionItem>
    </>
  );
}

export default KeywordFilter;
