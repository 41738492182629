import { formatDateTimeByLocaleUtil } from "@rpe-js/core/dist/util/dateUtil";
import {
  Overlay,
  ProgressIndicatorLoader,
} from "@rpe-js/marcom-web-components";
import { chain, fromPairs, zip } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { TalentConnectionStoreLocation } from "../../../../shared/types/role";
import { getTalentConntectionStoreLocations } from "../../../api/fetchClient";
import AppContext from "../../../AppContext";
import { AlertWrapper } from "../../../components/base/AlertWrapper";
import { useFetchData } from "../../../hooks/useFetchData";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { idGenerator } from "../../../utils/idGenerator";
import { RoleUIObj } from "./types";

interface StoreLocations {
  title: string;
  list: TalentConnectionStoreLocation[];
}

interface SelectedLocationsModalProps {
  onClose: () => void;
  role: RoleUIObj;
  talentId: string;
}

export function SelectedLocationsModal(props: SelectedLocationsModalProps) {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const [alert, setAlert] = useState(false);
  const { appUIState } = useContext(AppContext);
  const { locale } = appUIState.appData;
  const [storeLocations, setStoreLocations] = useState<StoreLocations[] | null>(
    null,
  );
  const {
    fetchData: fetchStoreLocations,
    isLoading: isFetchStoreLocationsLoading,
    isSuccess: isFetchStoreLocationsSuccess,
    data: storeLocationsData,
    isError: isFetchStoreLocationsError,
  } = useFetchData(getTalentConntectionStoreLocations);

  useEffect(() => {
    fetchStoreLocations([props.role?.connectionID]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFetchStoreLocationsLoading && isFetchStoreLocationsSuccess) {
      const locationData = chain(storeLocationsData)
        .groupBy("city")
        .toPairs()
        .map((currentItem) => {
          return fromPairs(zip(["title", "list"], currentItem));
        })
        .value();
      setStoreLocations(locationData as StoreLocations[]);
    }
    if (!isFetchStoreLocationsLoading && isFetchStoreLocationsError) {
      setAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetchStoreLocationsLoading,
    isFetchStoreLocationsSuccess,
    isFetchStoreLocationsError,
  ]);

  return (
    <Overlay
      id={idGenerator("yourroles", "locationmodal").generateId()}
      visible={true}
      isFullscreen={isMobile}
      onClose={props.onClose}
      closeButtonAttrs={{
        ariaLabel: t("jobsite.common.close") as string,
      }}
      elementIdToFocus={`js-location-link-${props.role.positionID}`}
      ariaLabel={idGenerator(
        "yourroles",
        "selectedlocationsheader",
      ).generateId()}
    >
      <section>
        {alert && (
          <AlertWrapper
            id={idGenerator("yourroles", "selectedlocationsmodal").generateId(
              "alert",
            )}
            message={t("jobsite.common.serviceError") as string}
            remove={{
              onRemove: () => setAlert(false),
              closeBtnAriaLabel: t("jobsite.common.closeAlert") as string,
            }}
            closePosition="right"
            classes="yellow-alert"
          />
        )}
        <h2
          className="text-center t-eyebrow-reduced"
          id={idGenerator("yourroles", "selectedlocationsheader").generateId()}
        >
          {t("jobsite.common.locations")}
        </h2>
        <p className="text-center">
          {t("jobsite.profile.yourRoles.locationstoSubmit")}
        </p>
        {isFetchStoreLocationsLoading && (
          <ProgressIndicatorLoader
            showLoading={isFetchStoreLocationsLoading}
            curtain={false}
          />
        )}
        <div role="list">
          {storeLocations &&
            storeLocations.map((location: StoreLocations, index: number) => (
              <div
                className={`pt-15 pb-15${index !== 0 ? " u-border-top" : ""}`}
                key={index}
                role="listitem"
              >
                {location.title && (
                  <p className="label-grey">{location.title}</p>
                )}
                {location.list.map(
                  (
                    store: TalentConnectionStoreLocation,
                    storeIndex: number,
                  ) => (
                    <div className="d-flex justify-between" key={storeIndex}>
                      <p>
                        {store.city && <span>{store.city}, </span>}
                        <span>{store.name}</span>
                      </p>
                      <p className="mt-0">
                        {t("jobsite.common.submittedDate", {
                          date: formatDateTimeByLocaleUtil(
                            props.role.submittedDate,
                            locale,
                            false,
                          ),
                        })}
                      </p>
                    </div>
                  ),
                )}
              </div>
            ))}
        </div>
      </section>
    </Overlay>
  );
}
