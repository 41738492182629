import React from "react";
import { AppEducationDetails } from "../../../../../shared/types/talent/myApplication";
import { Education } from "../../../../../shared/types/talent/talent";
import { Info } from "../../../../components/base/Info";
import { Label } from "../../../../components/base/Label";
import useIntlMessage from "../../../../hooks/useIntlMessage";

export function EducationDegreeView(props: {
  education: Education | AppEducationDetails | null;
}) {
  const { t } = useIntlMessage();
  const { education } = props;
  return (
    <div className="row">
      <div className="column large-6 medium-6 small-12">
        <div className="d-flex flex-column mb-10">
          <Label label={t("jobsite.common.degree") as string} />
          {education?.degreeName ? (
            <Info value={education.degreeName} />
          ) : (
            <Info value={t("jobsite.common.notSpecified") as string} />
          )}
        </div>
        <div className="d-flex flex-column mb-10">
          <Label label={t("jobsite.common.school") as string} />
          {education?.school || education?.schoolName ? (
            <Info value={education.school || education.schoolName} />
          ) : (
            <Info value={t("jobsite.common.notSpecified") as string} />
          )}
          {(education as AppEducationDetails)?.country && (
            <Info value={(education as AppEducationDetails).country} />
          )}
          {(education as AppEducationDetails)?.state && (
            <Info value={(education as AppEducationDetails).state} />
          )}
        </div>
      </div>
      <div className="column large-6 medium-6 small-12">
        <div className="d-flex flex-column mb-10">
          <Label label={t("jobsite.common.graduated") as string} />
          {education?.graduationStatusName ? (
            <Info value={education.graduationStatusName} />
          ) : (
            <Info value={t("jobsite.common.notSpecified") as string} />
          )}
        </div>
        <div className="d-flex flex-column mb-10">
          <Label label={t("jobsite.common.major") as string} />
          {education?.majorField || education?.majorFieldName ? (
            <Info value={education.majorField || education.majorFieldName} />
          ) : (
            <Info value={t("jobsite.common.notSpecified") as string} />
          )}
        </div>
      </div>
    </div>
  );
}
