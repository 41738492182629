import React from "react";

interface InternalJobInformationItemProps {
  hide: boolean;
  label: string;
  id?: string;
}

const InternalJobInformationItem: React.FC<InternalJobInformationItemProps> = ({
  hide,
  label,
  id,
}) => {
  function renderContent() {
    return (
      <li role="listitem" id={id} className={"pb-15 text-align"}>
        <span>{label}</span>
      </li>
    );
  }

  return !hide ? renderContent() : null;
};

export default InternalJobInformationItem;
