import React, { createContext, useContext } from "react";
import { RoleSectioName, RoleUIObj } from "./types";

export type RolesAction =
  | { type: "SHOW_QUESTIONAIRE_MODAL"; payload: RoleUIObj }
  | { type: "CLOSE_QUESTIONAIRE_MODAL"; payload: boolean }
  | { type: "SHOW_LOCATION_MODAL"; payload: RoleUIObj }
  | { type: "CLOSE_LOCATION_MODAL"; payload: boolean }
  | { type: "SET_ROLES"; payload: RoleUIObj[] }
  | { type: "REMOVE_INACTIVE_ROLE"; payload: RoleUIObj }
  | { type: "REMOVE_ALL_INACTIVE_ROLES"; payload: null }
  | { type: "SET_SELECTED_FILTER"; payload: RoleSectioName }
  | { type: "SET_SHOW_REMOVE_ALL_MODAL"; payload: boolean }
  | { type: "UPDATE_FAVORITE_ROLE"; payload: UpdateFavoriteRolePayload }
  | { type: "UPDATE_ROLE_TO_RESET"; payload: string }
  | { type: "UPDATE_RESET" }
  | { type: "SET_SHOW_FILTER_MODAL"; payload: boolean }
  | { type: "UPDATE_MOBILE_FILTER"; payload: RoleSectioName };

type UpdateFavoriteRolePayload = { roleItem: RoleUIObj; favorited: boolean };

export interface RolesState {
  talentId: string;
  roles: RoleUIObj[];
  selected: RoleSectioName;
  filteredRoles: RoleUIObj[];
  showQuestionaireModal: boolean;
  questionaireModalRole: RoleUIObj | null;
  showLocationModal: boolean;
  locationModalRole: RoleUIObj | null;
  showRemoveAllModal: boolean;
  showRemoveAllUnavailableLink: boolean;
  roleToReset: string;
  showFilterModal: boolean;
  mobileFilter: string;
}

// Create a context
export const RolesContext = createContext<
  { state: RolesState; dispatch: React.Dispatch<RolesAction> } | undefined
>(undefined);

export const useRolesContext = (): {
  state: RolesState;
  dispatch: React.Dispatch<RolesAction>;
} => {
  const context = useContext(RolesContext);
  if (!context) {
    throw new Error("useRolesContext must be used within an RolesProvider");
  }
  return context;
};
