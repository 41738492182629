import { SkillByInputRefData } from "../../shared/types/refData";
import { Skill } from "../../shared/types/talent/talent";

// Find the index of skill by skillName from talent skills
function getSkillIndexBySkillName(skills: Skill[], skillName: string): number {
  return skills.findIndex(
    (skill) => skill?.skillName?.toLowerCase() === skillName?.toLowerCase(),
  );
}

export function addSkills(
  skills: Skill[],
  skillToAdd: SkillByInputRefData | string,
): Skill[] {
  if (typeof skillToAdd == "string") {
    // custom skill - free text usecase
    if (getSkillIndexBySkillName(skills, skillToAdd) === -1) {
      return [...skills, { skillID: "", skillName: skillToAdd }];
    }
  } else {
    // Find the skill by name
    const skillIndexBySkillName = getSkillIndexBySkillName(
      skills,
      skillToAdd.name,
    );

    // If the skill is not available on skills array then add the selected skill from typeahead
    if (
      !skills.map((skill: Skill) => skill.skillID)?.includes(skillToAdd.id) &&
      skillIndexBySkillName === -1
    ) {
      return [
        ...skills,
        { skillID: skillToAdd.id, skillName: skillToAdd.name },
      ];
    }

    // If the skill is available on skills array but does not have skillID then add the selected skill skillID to it
    // This can happen when user has a free text as  skill selected and then try to select the same skill from the skill typeahead
    if (skillIndexBySkillName >= 0) {
      skills[skillIndexBySkillName] = {
        ...skills[skillIndexBySkillName],
        skillID: skillToAdd.id,
      };
      return skills;
    }
  }
  return skills;
}
