import * as React from "react";
import { hydrateRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { createBrowserRouter, matchRoutes, RouterProvider } from "react-router";
import ApiServiceHandler from "../app/api/apiService";
import getClientDataLoaderRoutes from "../app/client.routes";
import { AppData } from "../app/types";

if (process.env.NODE_ENV === "development") {
  import("../styles/_main.scss").catch((err) => {
    throw new Error("Error while loading styles", err);
  });
}

function getAppStateFromRootLoader(): AppData {
  const appData = (window as any)?.__staticRouterHydrationData?.loaderData
    ?.root as AppData;
  return appData || {};
}

async function hydrate() {
  const {
    appType,
    appBasePathPrefix,
    baseUrl,
    countryCode,
    talentId,
    isSessionAuthenticated,
  } = getAppStateFromRootLoader();
  ApiServiceHandler.init(
    baseUrl,
    appBasePathPrefix || "",
    countryCode?.data as string,
    countryCode?.locale as string,
  );
  const clientRoutes = getClientDataLoaderRoutes(
    appBasePathPrefix || "",
    appType,
    isSessionAuthenticated,
    talentId,
  );
  const lazyMatches = matchRoutes(clientRoutes, window.location)?.filter(
    (m) => m.route.lazy,
  );

  // Load the lazy matches and update the routes before creating your router
  // so we can hydrate the SSR-rendered content synchronously
  if (lazyMatches && lazyMatches?.length > 0) {
    await Promise.all(
      lazyMatches.map(async (m) => {
        const routeModule = await m.route.lazy!();
        Object.assign(m.route, { ...routeModule, lazy: undefined });
      }),
    );
  }

  const router = createBrowserRouter(clientRoutes, {
    basename: appBasePathPrefix || "",
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
    },
  });
  hydrateRoot(
    document.getElementById("root") as any,
    <React.StrictMode>
      <HelmetProvider>
        <RouterProvider router={router} />
      </HelmetProvider>
    </React.StrictMode>,
  );
}

hydrate();

if (module.hot) {
  module.hot.accept();
}
