import { Dropdown, DropDownOptionProps } from "@rpe-js/marcom-web-components";
import React from "react";
import { OrderedRefData } from "../../../shared/types/refData";
import useIntlMessage from "../../hooks/useIntlMessage";

type SavedSearchesSelectorProps = {
  id?: string;
  options: OrderedRefData[];
  selectedValue?: string;
  name: "emailAlertFreqency" | "emailExpiration";
  onDropdownChange: (option: DropDownOptionProps) => void;
};

const SavedSearchesSelector = ({
  options,
  selectedValue,
  name,
  id,
  onDropdownChange,
}: SavedSearchesSelectorProps) => {
  const { t } = useIntlMessage();
  const optionsData = options?.map((data: OrderedRefData) => ({
    value: data.id,
    label: data.name,
  }));
  return (
    <Dropdown
      required={true}
      id={id as string}
      handleValueSelect={(
        evt: React.ChangeEvent,
        option: DropDownOptionProps,
      ) => onDropdownChange(option)}
      name={
        t(
          name === "emailAlertFreqency"
            ? "jobsite.savedsearch.emailalertfreqency"
            : "jobsite.savedsearch.emailexpiration",
        ) as string
      }
      label={
        t(
          name === "emailAlertFreqency"
            ? "jobsite.savedsearch.emailalertfreqency"
            : "jobsite.savedsearch.emailexpiration",
        ) as string
      }
      options={optionsData || []}
      value={selectedValue}
      errorA11y={t("jobsite.common.errorIconLabel") as string}
    />
  );
};

export default SavedSearchesSelector;
