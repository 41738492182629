import {
  Checkbox,
  ProgressIndicatorLoader,
} from "@rpe-js/marcom-web-components";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { BaseRefData } from "../../../../shared/types/refData";
import { getERPAppleStoreRoles } from "../../../api/fetchClient";
import { ALERT_WRAPPER_ID } from "../../../app.constants";
import AppContext from "../../../AppContext";
import { AlertWrapper } from "../../../components/base/AlertWrapper";
import useAutoFocus from "../../../hooks/useAutoFocus";
import { useFetchData } from "../../../hooks/useFetchData";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { focusElement, handleScrollTop } from "../../../utils/focusUtil";
import {
  toggleReviewSubmitBtn,
  updateGlobalManagedRoles,
  updateReferenceData,
} from "../context/Actions";
import { useERPContext } from "../context/ERPContext";
import { StepName } from "../context/ErpState";
import { FooterSection } from "../FooterSection";

interface InterestRetailStepProps {
  moveNext: () => void;
  goBack: () => void;
  goToStep: (stepName: StepName, id?: string) => void;
}

export function InterestRetailStep({
  moveNext,
  goBack,
  goToStep,
}: InterestRetailStepProps) {
  const headingRef = useAutoFocus<HTMLHeadingElement>();
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const { state, dispatch } = useERPContext();
  const [errorMsg, setErrorMsg] = useState<string>("");
  const { globaleManagedRoleIds, enableReviewSubmitBtn } = state;
  const { isLoading, isSuccess, data, fetchData } = useFetchData(
    getERPAppleStoreRoles,
  );
  const { appUIState } = useContext(AppContext);
  const { isInternal } = appUIState.appData;

  useEffect(() => {
    if (!state.referenceData.appleStoreRoles && !data && !isLoading)
      fetchData();
    if (isSuccess && data) {
      dispatch(updateReferenceData("appleStoreRoles", data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isSuccess]);

  const validateStores = useCallback((): boolean => {
    return globaleManagedRoleIds.length > 0;
  }, [globaleManagedRoleIds]);

  const moveToPreviousStep = useCallback(() => {
    if (!validateStores()) dispatch(toggleReviewSubmitBtn(false));
    goBack();
  }, [validateStores, goBack, dispatch]);

  const moveToNextStep = useCallback(
    (stepName?: StepName) => {
      if (!validateStores()) {
        setErrorMsg(
          t("jobsite.erp.introduceFriend.selectAtLeastOneRole") as string,
        );
        handleScrollTop(isInternal);
        focusElement(ALERT_WRAPPER_ID.generateId("interest-retail"));
        return;
      }
      setErrorMsg("");
      if (stepName) {
        goToStep(stepName);
        return;
      }
      moveNext();
    },
    [moveNext, validateStores, t, goToStep, isInternal],
  );

  function handleValueChange(item: BaseRefData, checked: boolean): void {
    const globalManagedRoles = [...globaleManagedRoleIds];
    if (checked) {
      dispatch(updateGlobalManagedRoles([...globalManagedRoles, { ...item }]));
    } else {
      dispatch(
        updateGlobalManagedRoles([
          ...globalManagedRoles.filter((data) => data.id !== item.id),
        ]),
      );
    }
  }

  return (
    <>
      <section className="text-center">
        <h1 ref={headingRef} tabIndex={0} className="erp-heading">
          <span role="text">
            <span>{t("jobsite.erp.introduceFriend.appleStoreRoles")}</span>
            <span className="a11y">, </span>
            <span className="a11y">
              {t("jobsite.common.stepof", {
                startValue: 2,
                endValue: 4,
              })}
            </span>
          </span>
        </h1>
      </section>
      {isLoading && <ProgressIndicatorLoader showLoading={isLoading} />}
      {!isLoading && (
        <>
          <section className="reviewinfo-container mtb-auto-0">
            <fieldset
              className="mtb-auto-20 u-border-bottom pb-20"
              aria-describedby={ALERT_WRAPPER_ID.generateId("interest-retail")}
            >
              <legend className="text-center erp-title">
                {t("jobsite.erp.introduceFriend.appleStoreRoles.subTitle")}
              </legend>
              {errorMsg && (
                <AlertWrapper
                  closePosition="right"
                  error={true}
                  message={errorMsg}
                  remove={{
                    closeBtnAriaLabel: t("jobsite.common.closeAlert") as string,
                    onRemove: () => setErrorMsg(""),
                    idToFocus: (state.referenceData.appleStoreRoles &&
                      state.referenceData.appleStoreRoles.length &&
                      state.referenceData.appleStoreRoles[0].id) as string,
                  }}
                  id={ALERT_WRAPPER_ID.generateId("interest-retail")}
                ></AlertWrapper>
              )}
              <ul role="list" className="row list m-0 mt-20">
                {state.referenceData.appleStoreRoles?.map((item) => (
                  <li
                    role="listitem"
                    className="large-4 small-6 erp-roles-list"
                    key={item.id}
                  >
                    <Checkbox
                      required={true}
                      id={item.id}
                      key={item.id}
                      label={item.name}
                      showTileView={isMobile}
                      checked={Boolean(
                        state.globaleManagedRoleIds?.find(
                          (managedRole) => managedRole.id === item.id,
                        ),
                      )}
                      onValueChange={(checked) =>
                        handleValueChange(item, checked)
                      }
                    />
                  </li>
                ))}
              </ul>
            </fieldset>
          </section>
          <FooterSection
            module="erp"
            enableReviewSubmitBtn={enableReviewSubmitBtn}
            onBack={() => moveToPreviousStep()}
            onContinue={() => moveToNextStep()}
            onReviewAndSubmit={() => moveToNextStep(StepName.REVIEW_INFO)}
          />
        </>
      )}
    </>
  );
}
