import dateUtil from "@rpe-js/core/dist/util/dateUtil";
import { Alert, NativeButton, Overlay } from "@rpe-js/marcom-web-components";
import React, { useEffect, useState } from "react";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { idGenerator } from "../../../utils/idGenerator";
import HtmlRenderer from "../../base/HtmlRenderer";
import Icon from "../../base/IconComponent";
import TextIcon from "../../base/TextIcon";

interface LinkedInPreviewWidgetProps {
  locale: string;
  linkedInUrl: string;
  addedOn: string | number;
  removeFile: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
export function LinkedInPreviewWidget({
  locale,
  linkedInUrl,
  addedOn,
  removeFile,
}: LinkedInPreviewWidgetProps) {
  const { t } = useIntlMessage();
  const [lastUpdated, setLastUpdated] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const isMobile = useIsMobile();
  const LINKEDIN_CONNECTION_MODAL_BUTTON_ID = idGenerator(
    "linkedin",
    "connection-modal",
  ).generateId("button");
  const LINKEDIN_CONNECTION_MODAL_TITLE_ID = idGenerator(
    "linkedin",
    "connection-modal",
  ).generateId("title");

  useEffect(() => {
    setLastUpdated(dateUtil.formatDateTimeByLocaleUtil(addedOn, locale));
  }, [addedOn, locale]);

  return (
    <>
      <section className="text-center">
        <section className="text-center pt-30 mb-10">
          <Icon width="93" height="21" name={"linkedin-upload"}></Icon>
        </section>
        <section className="attachment-info">
          <section className="m-0">
            <a
              id={idGenerator("linkedin", "url").generateId()}
              href={linkedInUrl}
            >
              {linkedInUrl}
            </a>
          </section>
          {lastUpdated && (
            <section
              className="mt-0"
              id={idGenerator("linkedin", "lastupdateddate").generateId()}
            >
              {t("jobsite.common.lastUpdatedDate", { date: lastUpdated })}
            </section>
          )}
          <section className="mt-10 mb-10">
            <NativeButton
              id={idGenerator("linkedin", "remove").generateId()}
              onClick={removeFile}
              className="link"
              label={t("jobsite.common.remove") as string}
              aria-label={t("jobsite.common.remove") + " " + linkedInUrl}
            ></NativeButton>
          </section>
          <section className="m-10 yellow-alert">
            <Alert>
              <section className="d-flex pos-rel pl-20 pr-20 flex-row orange-alert-text">
                <span className="pos-abs left-0">
                  <TextIcon
                    name="icon-infocircle"
                    onClick={() => setShowModal((prev) => !prev)}
                    ariaLabelForButton={
                      t("jobsite.common.linkedinConnectionBanner") as string
                    }
                    idForButton={LINKEDIN_CONNECTION_MODAL_BUTTON_ID}
                  />
                </span>
                <span className="a11y">
                  {t("jobsite.common.information") as string}
                </span>

                <span
                  className="ml-10 underline"
                  onClick={() => setShowModal((prev) => !prev)}
                >
                  {t("jobsite.common.linkedinConnectionBanner")}
                </span>
              </section>
            </Alert>
          </section>
        </section>
      </section>
      <section>
        {showModal && (
          <section>
            <Overlay
              id="linkedin-alert-modal"
              visible={showModal}
              onClose={() => setShowModal(false)}
              isFullscreen={isMobile}
              closeButtonAttrs={{
                ariaLabel: t("jobsite.common.close") as string,
              }}
              elementIdToFocus={LINKEDIN_CONNECTION_MODAL_BUTTON_ID}
              ariaLabel={LINKEDIN_CONNECTION_MODAL_TITLE_ID}
            >
              <>
                <h2
                  className="t-eyebrow-elevated mb-15 text-center"
                  id={LINKEDIN_CONNECTION_MODAL_TITLE_ID}
                >
                  {t("jobsite.common.linkedinConnectionHeading")}
                </h2>
                <HtmlRenderer
                  classes="hyperlink-underline"
                  initialContent={
                    t("jobsite.common.timeoutLinkedinAlert") as string
                  }
                ></HtmlRenderer>
              </>
            </Overlay>
          </section>
        )}
      </section>
    </>
  );
}
