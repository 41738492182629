import { cloneDeep, isEmpty } from "lodash";
import React, { useCallback } from "react";
import {
  EducationDegreeRefData,
  GraduationStatusRefData,
  RefData,
} from "../../../shared/types/refData";
import { focusElementWithFallback } from "../../utils/focusUtil";
import { idGenerator } from "../../utils/idGenerator";
import {
  EducationDegreeForm,
  getEducationDegreeForm,
} from "../profile/components/educationSummary/form";
import { EditEducationSection } from "../profile/information/editEducationSection";

type ParsedEducationDegreesProps = {
  degreeOptions: EducationDegreeRefData[];
  gradStatusOptions: GraduationStatusRefData[];
  educationDegreesForm: EducationDegreeForm[];
  displayErrors: boolean;
  setEducationDegreesForm: React.Dispatch<
    React.SetStateAction<EducationDegreeForm[]>
  >;
};

export const ParsedEducationDegrees = ({
  degreeOptions,
  gradStatusOptions,
  educationDegreesForm,
  displayErrors,
  setEducationDegreesForm,
}: ParsedEducationDegreesProps) => {
  const mode = "parsedmodal";
  const parsedModalDegreeIdGenerator = idGenerator(mode, "degree");
  const handleValueChange = useCallback(
    (index: number, option: any, name: "degree" | "gradStatus") => {
      const matchEduDegree = cloneDeep(educationDegreesForm[index]);
      if (name === "degree") {
        matchEduDegree.degree.value = option.value;
        matchEduDegree.degree.label = option.label;
        matchEduDegree.degree.requiredError = false;
      } else if (name === "gradStatus") {
        const gradOption = gradStatusOptions?.filter(
          (opt: GraduationStatusRefData) => opt.id === option.target?.value,
        );
        matchEduDegree.gradStatus.value = option.target?.value;
        if (gradOption?.length) {
          matchEduDegree.gradStatus.label = gradOption[0].name;
        }
        matchEduDegree.gradStatus.requiredError = false;
      }
      const copied = [...educationDegreesForm];
      copied.splice(index, 1, matchEduDegree);
      setEducationDegreesForm(copied);
    },
    [educationDegreesForm, setEducationDegreesForm, gradStatusOptions],
  );

  const updateForm = useCallback(
    (update: boolean, index: number, updatedForm: EducationDegreeForm) => {
      if (update) {
        const copied = [...educationDegreesForm];
        copied.splice(index, 1, updatedForm);
        setEducationDegreesForm(copied);
      }
    },
    [educationDegreesForm, setEducationDegreesForm],
  );

  const onSchoolOrMajorChange = useCallback(
    (
      index: number,
      option: string | RefData | null,
      type: "school" | "major",
      event: "select" | "blur" | "reset",
    ) => {
      const matchEduDegree = cloneDeep(educationDegreesForm[index]);
      let update = false; // should not update the state on every blur. Update only if option has changed on blur
      if (event === "reset") {
        matchEduDegree[type].value = "";
        matchEduDegree[type].label = "";
        update = true;
        matchEduDegree[type].requiredError = true;
      }

      if (event === "select" && option && !isEmpty(option)) {
        if (typeof option === "object") {
          matchEduDegree[type].value = option.id;
          matchEduDegree[type].id = option.id;
          matchEduDegree[type].label = option.name;
        } else {
          matchEduDegree[type].value = "";
          matchEduDegree[type].id = "";
          matchEduDegree[type].label = option;
        }
        matchEduDegree[type].requiredError = false;
        update = true;
      }

      if (event === "blur" && matchEduDegree[type].label !== option) {
        matchEduDegree[type].label = option ? (option as string) : "";
        if (!option || isEmpty(option)) {
          matchEduDegree[type].value = "";
          matchEduDegree[type].id = "";
        }
        update = true;
      }

      updateForm(update, index, matchEduDegree);
    },
    [educationDegreesForm, updateForm],
  );

  const onAddEducationDegree = useCallback(
    (index: number) => {
      const newDegreeForm: EducationDegreeForm = getEducationDegreeForm();
      setEducationDegreesForm([...educationDegreesForm, newDegreeForm]);
      // Focus on the new degree's dropdown
      const newIndex = index + 1;
      focusElementWithFallback(
        parsedModalDegreeIdGenerator.generateId(`${newIndex}`),
      );
    },
    [
      educationDegreesForm,
      setEducationDegreesForm,
      parsedModalDegreeIdGenerator,
    ],
  );

  const onRemoveEducationDegree = useCallback(
    (key: number, index: number) => {
      const updatedEducation = educationDegreesForm.filter(
        (educationDegree: EducationDegreeForm) => educationDegree.key !== key,
      );
      setEducationDegreesForm([...updatedEducation]);
      const newIndex = Math.min(index, updatedEducation.length - 1);
      const addEducationDegreeButtonId = idGenerator(
        mode,
        "add-education-degree",
      ).generateId(`${newIndex}`);
      const removeEducationDegreeButtonId = idGenerator(
        mode,
        "remove-education-degree",
      ).generateId(`${newIndex}`);
      focusElementWithFallback(addEducationDegreeButtonId, [
        removeEducationDegreeButtonId,
      ]);
    },
    [educationDegreesForm, setEducationDegreesForm],
  );
  return (
    <EditEducationSection
      degreeOptions={degreeOptions}
      gradStatusOptions={gradStatusOptions}
      educationDegreesForm={educationDegreesForm}
      mode={mode}
      displayErrors={displayErrors}
      handleValueChange={handleValueChange}
      onSchoolOrMajorChange={onSchoolOrMajorChange}
      onRemoveEducationDegree={onRemoveEducationDegree}
      onAddEducationDegree={onAddEducationDegree}
    />
  );
};
