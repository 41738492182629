import { TextButton } from "@rpe-js/marcom-web-components";
import React from "react";
import LocaleAwareLink from "../../../components/feature/LocaleAwareLink";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { focusElement } from "../../../utils/focusUtil";
import { idGenerator } from "../../../utils/idGenerator";
import { updateEditSearch } from "./context/Actions";
import { useSavedSearchesContext } from "./context/SavedSearchesContext";

type SavedSearchesLeftPanelProps = {
  url: string;
  name: string;
  id: string;
  onRemove: () => void;
};

const SavedSearchesLeftPanel = ({
  url,
  name,
  id,
  onRemove,
}: SavedSearchesLeftPanelProps) => {
  const { t } = useIntlMessage();
  const {
    state: { editSavedSearches },
    dispatch,
  } = useSavedSearchesContext();
  const onEdit = () => {
    dispatch(updateEditSearch(id, true));
    focusElement(
      idGenerator("savedsearches", `emailalertfreqency-${id}`).generateId(),
    );
  };
  const isMobile = useIsMobile();

  return (
    <>
      <h2 className="t-eyebrow word-wrap-break-word w-90 small-10">
        <LocaleAwareLink link={`search${url}`} type="standalone" text={name} />
      </h2>
      {!editSavedSearches[id] && (
        <TextButton
          label={t("jobsite.common.edit") as string}
          aria-label={t("jobsite.common.editEntity", { name: name }) as string}
          onClick={onEdit}
          id={`edit-${id}`}
        />
      )}
      <div className={isMobile ? "d-none" : "d-block"}>
        <TextButton
          label={t("jobsite.common.remove") as string}
          aria-label={
            t("jobsite.common.removeEntity", { name: name }) as string
          }
          onClick={onRemove}
          id={`remove-${id}`}
        />
      </div>
    </>
  );
};

export default SavedSearchesLeftPanel;
