import { Checkbox, NativeButton } from "@rpe-js/marcom-web-components";
import React, { useEffect, useRef } from "react";
import { LabelValue } from "../../components/base/LabelValue";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";

export type FilterOption<T = unknown> = T & {
  id: string;
  name: string;
  displayName?: string;
  selected: boolean;
};

interface FilterCheckboxGroupProps<T = unknown> {
  id: string;
  title?: string;
  isTeamsFilter?: boolean;
  filterList: Array<FilterOption<T>>;
  legendLabel?: string; // Label for legend and ul
  showMoreEnabled?: boolean;
  showMore?: boolean;
  classNames?: {
    listWrapper?: string; // Class for wrapper of checkbox to show in column view
    input?: string; // Class for checkbox input to position in a tile
    label?: string; // Class for label to position as a tile
  }; // Custom classNames for checkbox
  setShowMore?: (showMore: boolean) => void;
  minElementsForShowMore?: number;
  onFilterChange: (id: string, value: boolean, teamCode?: string) => void;
}

export function FilterCheckboxGroup({
  id,
  title = "",
  isTeamsFilter,
  filterList,
  onFilterChange,
  legendLabel,
  showMoreEnabled,
  showMore,
  classNames,
  setShowMore,
  minElementsForShowMore,
}: FilterCheckboxGroupProps) {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const checkboxListRef = useRef<HTMLUListElement>(null);

  function renderShowMore() {
    return (
      <>
        <ul
          role="list"
          ref={checkboxListRef}
          id={`${legendLabel} list`}
          className={`unstyled-list ${classNames?.listWrapper}`}
        >
          {(showMore
            ? filterList
            : filterList.slice(0, minElementsForShowMore)
          ).map((item) => (
            <li role="listitem" id={`${id}-${item.name}`} key={item.name}>
              <Checkbox
                required={false}
                id={`checkbox-${item.id}`}
                key={`checkbox-${item.id}`}
                checked={item.selected ?? false}
                label={item.displayName || item.name}
                onValueChange={(isChecked: boolean) =>
                  onFilterChange(item.id as string, isChecked)
                }
                showTileView={isMobile}
                classes={classNames}
                aria-disabled={false}
              />
            </li>
          ))}
        </ul>
        <NativeButton
          id={
            showMore
              ? (t("jobsite.common.less") as string)
              : (t("jobsite.common.more") as string)
          }
          className={"link mb-20"}
          onClick={() => setShowMore && setShowMore(!showMore)}
          label={
            (showMore
              ? t("jobsite.common.less")
              : t("jobsite.common.more")) as string
          }
          aria-label={`${
            showMore ? t("jobsite.common.less") : t("jobsite.common.more")
          } ${title}`}
          aria-expanded={showMore}
          aria-controls={`${id}-list`}
        ></NativeButton>
      </>
    );
  }

  useEffect(() => {
    if (showMore && checkboxListRef.current) {
      const checkboxes = checkboxListRef.current.querySelectorAll(
        "input[type='checkbox']",
      );
      const focusIndex = minElementsForShowMore || 0;
      if (checkboxes.length > focusIndex) {
        (checkboxes[focusIndex] as HTMLElement).focus();
      }
    }
  }, [showMore, minElementsForShowMore]);

  return (
    <>
      {showMoreEnabled ? (
        renderShowMore()
      ) : (
        <ul role="list" className="list-nobullet">
          {filterList.map((filter: any) => {
            const label = filter.displayName || filter.name;
            return (
              <li
                role="listitem"
                key={`${id}-${filter.teamCode ? filter.teamCode : ""}${filter.id}`}
              >
                <Checkbox
                  required={false}
                  id={`${id}-${filter.teamCode ? filter.teamCode : ""}${filter.id}`}
                  label={label}
                  checked={filter.selected ?? false}
                  showTileView={isMobile}
                  classes={classNames}
                  onValueChange={(isChecked: boolean) =>
                    onFilterChange(
                      filter.id as string,
                      isChecked,
                      isTeamsFilter ? filter.teamCode : "",
                    )
                  }
                >
                  {isTeamsFilter ? (
                    <LabelValue
                      label={filter.teamName}
                      value={filter.name}
                    ></LabelValue>
                  ) : id.indexOf("keyword") >= 0 ? (
                    <LabelValue
                      label={t("jobsite.savedsearch.keyword") as string}
                      value={label}
                    ></LabelValue>
                  ) : null}
                </Checkbox>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
}
