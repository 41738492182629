import { useRef } from "react";

/**
 * Examples: (All parent containers can have id's in the below form)
 * [Module]-[Container]
 * [apply]-[resume-widget]
 * [apply]-[resume-upload]
 * [apply]-[resume-preview]
 * [apply]-[supportingfile]
 *
 * Examples:
 * [Module]-[container]-[index]
 * [supporting-widget]-[file]-1
 * [supporting-widget]-[file]-2
 * [supporting-widget]-[file]-3
 * [supporting-widget]-[link]-1
 * [supporting-widget]-[link]-2
 * [supporting-widget]-[link]-3
 */
type ModuleName =
  | "apply"
  | "profile"
  | "filter"
  | "global"
  | "self-disclosure"
  | "privacypolicy"
  | "savedsearches"
  | "yourroles"
  | "profileApplication"
  | "search"
  | "job-details"; // should add types by individual
type ContainerName = "resume-widget" | string; // should add types by individual

export default function useIdGenerator(
  moduleName: ModuleName,
  container: ContainerName,
  includeIndex: boolean = false,
) {
  // A ref object to store a map of moduleName-container -> index
  const indexRef = useRef(new Map());

  // Generate or increment the index for the given moduleName-container pair
  const getIncrementedIndex = () => {
    const key = `${moduleName}-${container}`;

    // If this pair has been encountered before, increment the index
    if (indexRef.current.has(key)) {
      indexRef.current.set(key, indexRef.current.get(key) + 1);
    } else {
      // If it's the first time, initialize the index to 1
      indexRef.current.set(key, 1);
    }

    return indexRef.current.get(key);
  };

  // Create the base ID using moduleName and container
  let id = `${moduleName}-${container}`;

  // Optionally append the index if needed
  if (includeIndex) {
    id = `${id}-${getIncrementedIndex()}`;
  }

  return id;
}
