import React, { useCallback } from "react";
import {
  FILTER_TYPE,
  SET_FILTER,
  UNSET_FILTER,
} from "../../contexts/actionTypes";
import { FilterValue, SEARCH_FILTER_TYPE } from "../../contexts/SearchContext";
import useIsMobile from "../../hooks/useIsMobile";
import { useSearchContext } from "../../hooks/useSearchContext";
import { FilterOption } from "./FilterCheckboxGroup";
import KeywordFilter from "./KeywordFilter";

export type MappedKeywordFilterOptions = FilterOption<Array<string>>;
interface KeywordFilterWrapperProps {
  keywordFilterData: Array<string>;
  onMobileFilterChange?: (
    filterType: SEARCH_FILTER_TYPE,
    filterVlaue: FilterValue,
  ) => void;
}
function KeywordFilterWrapper({
  keywordFilterData,
  onMobileFilterChange,
}: KeywordFilterWrapperProps) {
  const { dispatch } = useSearchContext();
  const isMobile = useIsMobile();
  const updateMobileFilters = useCallback(
    (filterType: SEARCH_FILTER_TYPE, filterValue: FilterValue) => {
      if (onMobileFilterChange) onMobileFilterChange(filterType, filterValue);
    },
    [onMobileFilterChange],
  );

  // dispatch a filter action in case of desktop / invoke mobileFilterChange callback incase of mobile
  function onAddKeyword(keywords: Array<string>) {
    if (!isMobile) {
      dispatch({
        type: SET_FILTER,
        filterName: FILTER_TYPE.KEYWORD,
        payload: keywords,
      });
    } else {
      updateMobileFilters(FILTER_TYPE.KEYWORD as SEARCH_FILTER_TYPE, keywords);
    }
  }

  // dispatch a filter action in case of desktop / invoke mobileFilterChange callback incase of mobile
  function onRemoveKeyword() {
    if (!isMobile) {
      dispatch({
        type: UNSET_FILTER,
        filterName: FILTER_TYPE.KEYWORD,
      });
    } else {
      updateMobileFilters(FILTER_TYPE.KEYWORD as SEARCH_FILTER_TYPE, []);
    }
  }

  return (
    <KeywordFilter
      keywordData={keywordFilterData}
      onAddUpdate={onAddKeyword}
      onRemoveKeyword={onRemoveKeyword}
    ></KeywordFilter>
  );
}

export default KeywordFilterWrapper;
