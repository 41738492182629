import { ProgressIndicatorLoader } from "@rpe-js/marcom-web-components";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { SubTeamRefData, TeamsRefData } from "../../../../shared/types/refData";
import { getERPTeamsOfInterest } from "../../../api/fetchClient";
import { ALERT_WRAPPER_ID } from "../../../app.constants";
import AppContext from "../../../AppContext";
import { AlertWrapper } from "../../../components/base/AlertWrapper";
import { TeamOfInterestWidget } from "../../../components/feature/widgets/teamsOfInterest/teamOfInterestWidget";
import { getFirstAccordionTitleId } from "../../../components/feature/widgets/teamsOfInterest/teamsOfInterestUtils";
import useAutoFocus from "../../../hooks/useAutoFocus";
import { useFetchData } from "../../../hooks/useFetchData";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { focusElement, handleScrollTop } from "../../../utils/focusUtil";
import {
  createTeamsViewDataAndPayload,
  toggleReviewSubmitBtn,
  updateReferenceData,
  updateTeamsOfInterest,
} from "../context/Actions";
import { useERPContext } from "../context/ERPContext";
import { StepName } from "../context/ErpState";
import { FooterSection } from "../FooterSection";

interface InterestCorporateStepProps {
  moveNext: () => void;
  goBack: () => void;
  goToStep: (stepName: StepName, id?: string) => void;
}

export function InterestCorporateStep({
  moveNext,
  goBack,
  goToStep,
}: InterestCorporateStepProps) {
  const headingRef = useAutoFocus<HTMLHeadingElement>();
  const { t } = useIntlMessage();
  const { state, dispatch } = useERPContext();
  const { referenceData, teamsOfInterest, enableReviewSubmitBtn } = state;
  const [errorMsg, setErrorMsg] = useState<string>("");
  const { isLoading, isSuccess, data, fetchData, isError } = useFetchData(
    getERPTeamsOfInterest,
  );
  const { appUIState } = useContext(AppContext);
  const { isInternal } = appUIState.appData;

  useEffect(() => {
    if (!referenceData.teamsOfInterest && !data && !isLoading && !isError) {
      fetchData();
    }
    if (isSuccess && data) {
      dispatch(updateReferenceData("teamsOfInterest", data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isSuccess]);

  const validateTeamsOfInterest = useCallback((): boolean => {
    return (
      Object.keys(teamsOfInterest).length <= 0 ||
      !Object.values(teamsOfInterest).some((item) => item.subTeam.length > 0)
    );
  }, [teamsOfInterest]);

  const moveToPreviousStep = useCallback(() => {
    if (validateTeamsOfInterest()) dispatch(toggleReviewSubmitBtn(false));
    dispatch(createTeamsViewDataAndPayload());
    goBack();
  }, [validateTeamsOfInterest, goBack, dispatch]);

  const moveToNextStep = useCallback(
    (stepName?: StepName) => {
      if (validateTeamsOfInterest()) {
        setErrorMsg(t("jobsite.common.selectAtleastOneTeam") as string);
        handleScrollTop(isInternal);
        focusElement(ALERT_WRAPPER_ID.generateId("interest-corporate"));
        return;
      }
      setErrorMsg("");
      dispatch(createTeamsViewDataAndPayload());
      if (stepName) {
        goToStep(stepName);
        return;
      }
      moveNext();
    },
    [moveNext, t, validateTeamsOfInterest, goToStep, dispatch, isInternal],
  );

  const handleSelectDeselect = useCallback(
    (team: TeamsRefData, checked: boolean) => {
      dispatch(
        updateTeamsOfInterest({
          key: team.id,
          value: {
            subTeam: checked
              ? [...team.teams.map((subTeam) => subTeam.id)]
              : [],
            isSelectAll: checked,
          },
        }),
      );
    },
    [dispatch],
  );

  const handleCheckboxChange = useCallback(
    (team: TeamsRefData, subTeam: SubTeamRefData, checked: boolean) => {
      if (checked) {
        const subTeamValue = [
          ...(teamsOfInterest[team.id] ? teamsOfInterest[team.id].subTeam : []),
          subTeam.id,
        ];
        dispatch(
          updateTeamsOfInterest({
            key: team.id,
            value: {
              subTeam: subTeamValue,
              isSelectAll: team.teams.length === subTeamValue.length,
            },
          }),
        );
      } else {
        dispatch(
          updateTeamsOfInterest({
            key: team.id,
            value: {
              subTeam: [
                ...teamsOfInterest[team.id].subTeam.filter(
                  (subTeamId) => subTeamId !== subTeam.id,
                ),
              ],
              isSelectAll: false,
            },
          }),
        );
      }
    },
    [teamsOfInterest, dispatch],
  );

  return (
    <>
      <section className="text-center">
        <h1 ref={headingRef} tabIndex={0} className="erp-heading">
          <span role="text">
            <span>{t("jobsite.erp.introduceFriend.forappleCorporate")}</span>
            <span className="a11y">, </span>
            <span className="a11y">
              {t("jobsite.common.stepof", {
                startValue: 2,
                endValue: 4,
              })}
            </span>
          </span>
        </h1>
        <div className="erp-title">
          {t("jobsite.erp.introduceFriend.forappleCorporate.subTitle")}
        </div>
      </section>
      {isLoading && <ProgressIndicatorLoader showLoading={isLoading} />}
      {!isLoading && (
        <>
          <section className="reviewinfo-container mtb-auto-0">
            {errorMsg && (
              <AlertWrapper
                closePosition="right"
                error={true}
                message={errorMsg}
                remove={{
                  closeBtnAriaLabel: t("jobsite.common.closeAlert") as string,
                  onRemove: () => setErrorMsg(""),
                  idToFocus: getFirstAccordionTitleId("teamsOfInterest"),
                }}
                id={ALERT_WRAPPER_ID.generateId("interest-corporate")}
              ></AlertWrapper>
            )}
            <div className="mtb-auto-20 pb-20">
              {referenceData.teamsOfInterest && (
                <TeamOfInterestWidget
                  teamsList={referenceData.teamsOfInterest}
                  selectedTeams={teamsOfInterest}
                  enableSelectDeselectAll={true}
                  onChange={handleCheckboxChange}
                  onSelectDeselectClick={handleSelectDeselect}
                />
              )}
            </div>
          </section>
          <FooterSection
            module="erp"
            enableReviewSubmitBtn={enableReviewSubmitBtn}
            onBack={() => moveToPreviousStep()}
            onContinue={() => moveToNextStep()}
            onReviewAndSubmit={() => moveToNextStep(StepName.REVIEW_INFO)}
          />
        </>
      )}
    </>
  );
}
