import React, { useCallback, useContext, useEffect } from "react";
import { useRouteLoaderData } from "react-router";
import {
  SelfDisclosureConfig,
  SelfDisclosures,
} from "../../../shared/types/selfDisclosures";
import { SelfDisclosure } from "../../../shared/types/talent/talent";
import {
  DEFAULT_LOCALE_API_HEADER,
  DEFAULT_TRANSLATION_LOCALE,
  DISABILITY,
  ETHNICITY,
  GENDER,
  NATIONALITY,
  VETERAN_STATUS,
} from "../../app.constants";
import AppContext from "../../AppContext";
import { SelfDisclosureWidget } from "../../components/feature/selfDisclosure/SelfDisclosureWidget";
import useSetPageTitle, { PAGE_TITLES } from "../../hooks/useSetPageTitle";
import { RouteID } from "../../routes";
import { isCorporate } from "../../util";
import {
  selfDisclosureAction,
  selfDisclosureCountryAction,
} from "./context/Actions";
import { useApplyContext } from "./context/ApplyContext";
import { StepName } from "./context/ApplyState";

interface SelfDisclosureStepProps {
  selfDisclosuresRefData?: SelfDisclosures | undefined;
  moveNext: () => void;
  goPrevious: () => void;
  goToStep: (stepName: StepName) => void;
}

export function SelfDisclosureStep({
  selfDisclosuresRefData,
  moveNext,
  goPrevious,
  goToStep,
}: SelfDisclosureStepProps) {
  const jobDetails = useRouteLoaderData(RouteID.apply) as any;
  const { appUIState } = useContext(AppContext);
  const { isInternal, countryCode } = appUIState.appData;
  const locale = isCorporate(jobDetails)
    ? DEFAULT_TRANSLATION_LOCALE
    : countryCode?.htmlLang || DEFAULT_TRANSLATION_LOCALE;
  const dataLocale = isCorporate(jobDetails) ? DEFAULT_LOCALE_API_HEADER : "";
  const { state, dispatch } = useApplyContext();
  const showReviewSubmit = state.page.showReviewSubmit;
  const { setPageTitle } = useSetPageTitle();
  const selfDisclosureConfig = jobDetails.selfDisclosureConfig;
  const countryID =
    jobDetails.selfDisclosureConfig &&
    jobDetails.selfDisclosureConfig.countryID;

  const selectedSelfDisclosure =
    state.talent &&
    state.talent.selfDisclosures &&
    state.talent.selfDisclosures[countryID];

  const onSelfDisclosureFieldChange = (fieldData: any) => {
    let selfDisclosure = {};
    if (
      state.talent.selfDisclosures &&
      state.talent.selfDisclosures[countryID]
    ) {
      selfDisclosure = {
        ...(state.talent.selfDisclosures[countryID] as SelfDisclosure),
      };
    }
    const option = fieldData.option;
    switch (fieldData.type) {
      case GENDER:
        const gender = {
          genderID: option.id as string,
          genderName: option.value as string,
        };
        dispatch(
          selfDisclosureCountryAction(countryID, {
            ...selfDisclosure,
            ...gender,
            updated: true,
          }),
        );
        break;
      case ETHNICITY:
        const ethnicity = {
          ethnicityID: option.id as string,
          ethnicityName: option.value as string,
        };
        dispatch(
          selfDisclosureCountryAction(countryID, {
            ...selfDisclosure,
            ...ethnicity,
            updated: true,
          }),
        );
        break;
      case VETERAN_STATUS:
        const veteranStatus = {
          veteranStatusID: option.id as string,
          veteranStatusName: option.value as string,
        };
        dispatch(
          selfDisclosureCountryAction(countryID, {
            ...selfDisclosure,
            veteranStatus: {
              ...veteranStatus,
            },
            updated: true,
          }),
        );
        break;
      case NATIONALITY:
        const nationality = {
          nationalityID: option.id as string,
          nationalityName: option.value as string,
        };
        dispatch(
          selfDisclosureCountryAction(countryID, {
            ...selfDisclosure,
            ...nationality,
            updated: true,
          }),
        );
        break;
      case DISABILITY:
        dispatch(
          selfDisclosureCountryAction(countryID, {
            ...selfDisclosure,
            disability: {
              ...option,
            },
            updated: true,
          }),
        );
        break;
      default:
    }
  };

  const reviewSubmit = useCallback(() => {
    if (!state.page.questionnaireError) {
      goToStep(StepName.REVIEW_INFO);
    }
  }, [goToStep, state.page.questionnaireError]);

  const moveToNext = (selfDisclosureData: any) => {
    const selfDisclosures: Record<string, SelfDisclosure> =
      state.talent?.selfDisclosures || {};

    selfDisclosures[selfDisclosureConfig.countryID] = selfDisclosureData;

    dispatch(selfDisclosureAction({ ...selfDisclosures }));

    moveNext();
  };

  useEffect(() => {
    setPageTitle(
      PAGE_TITLES.APPLY.SELF_DISCLOSURE,
      state.page.stepMapping?.selfDisclosure || 2,
      state.page.stepNames.length,
      jobDetails.postingTitle,
    );
  }, [
    isInternal,
    jobDetails.postingTitle,
    setPageTitle,
    state.page.stepMapping?.selfDisclosure,
    state.page.stepNames.length,
  ]);

  return (
    <SelfDisclosureWidget
      locale={locale}
      dataLocale={dataLocale}
      showReviewSubmit={showReviewSubmit}
      selfDisclosureConfig={selfDisclosureConfig as SelfDisclosureConfig}
      selectedSelfDisclosure={selectedSelfDisclosure as SelfDisclosure}
      selfDisclosuresRefData={selfDisclosuresRefData}
      onSelfDisclosureFieldChange={onSelfDisclosureFieldChange}
      moveNext={moveToNext}
      goPrevious={goPrevious}
      reviewSubmit={reviewSubmit}
    ></SelfDisclosureWidget>
  );
}
