import FormData from "form-data";
import React, { ChangeEvent } from "react";
import { FileData } from "../../types/File";
import { idGenerator } from "../../utils/idGenerator";

interface FileUploaderProps {
  id: string;
  label: string;
  showPlusIcon?: boolean;
  onFileUpload: (fileData: FileData) => void;
}

export default function FileUploader({
  id,
  label,
  showPlusIcon = true,
  onFileUpload,
}: FileUploaderProps) {
  /**
   *
   * @param evt
   * This method is triggered on file select, prepares the basic file data and onFileUpload callback is executed
   */
  const onSelectFile = (evt: ChangeEvent<HTMLInputElement>) => {
    if (evt.target && evt.target.files && evt.target.files.length > 0) {
      const file = evt.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file, encodeURIComponent(file.name));
        const fileData = {
          name: file.name,
          size: file.size,
          createdTime: Date.now(),
          content: formData,
        };
        // we need to set the value to empty as it needs to detect the change for new file select
        // if talent selects the same file again, there won't be any change in evt data,
        // so we need to update the evt once we have the file selected
        evt.target.value = "";
        onFileUpload(fileData);
      }
    }
  };
  return (
    <span
      id={idGenerator("file", id).generateId()}
      className="p-5 align-middle"
      tabIndex={0}
      role="button"
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault(); // Prevent scrolling when space is pressed
          const fileInput = document.getElementById(`attachfile-${id}`); // Trigger file input
          if (fileInput) fileInput.click();
        }
      }}
    >
      <input
        type="file"
        id={idGenerator("attachfile", id).generateId()}
        className="d-none"
        onChange={onSelectFile}
        aria-hidden="true"
      />
      <label
        className="attach-file-label align-middle"
        htmlFor={idGenerator("attachfile", id).generateId()}
      >
        {showPlusIcon && (
          <span className="mr-5">
            <i className="icon icon-pluscircle"></i>
          </span>
        )}
        <span>{label}</span>
      </label>
    </span>
  );
}
