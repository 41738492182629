import {
  Button,
  NativeButton,
  Popover,
  ProgressIndicatorLoader,
  TextBoxSlot,
} from "@rpe-js/marcom-web-components";
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { I18nValues } from "../../../../../shared/types";
import {
  Email,
  EmailCodeGenerationResponse,
  EmailCodeVerificationRequest,
  EmailCodeVerificationResponse,
} from "../../../../../shared/types/emailVerification";
import {
  generateEmailVerificationCode,
  validateEmailVerificationCode,
} from "../../../../api/fetchClient";
import AppContext from "../../../../AppContext";
import ErrorMessage from "../../../../components/base/ErrorMessage";
import { useFetchData } from "../../../../hooks/useFetchData";
import { focusElement } from "../../../../utils/focusUtil";
import { idGenerator } from "../../../../utils/idGenerator";
import Icon from "../../../base/IconComponent";
import {
  EMAIL_VERIFICATION_MODAL_CANCEL_BUTTON_ID,
  EMAIL_VERIFICATION_MODAL_CONTINUE_BUTTON_ID,
  EMAIL_VERIFICATION_MODAL_SEND_CODE_BUTTON_ID,
  EMAIL_VERIFICATION_MODAL_TITLE_ID,
} from "../constants";

type EmailCodeVerificationContentProps = {
  t: (id: string, values?: I18nValues) => React.ReactNode;
  onCancel: () => void;
  emailData: Email;
  onAction: () => void;
  setIsPopoverActive?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EmailCodeVerificationContent = ({
  t,
  emailData,
  onCancel,
  onAction,
  setIsPopoverActive,
}: EmailCodeVerificationContentProps) => {
  const verificationCodeLength = 6;
  const verificationCodeIdGenerator = idGenerator("emailverification", "code");
  const [showPopOver, setShowPopOver] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [serviceErrorMessage, setServiceErrorMessage] = useState("");
  const [maxLimit, setMaxLimit] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const { appUIState } = useContext(AppContext);
  const { locale } = appUIState.appData;
  const {
    data: generateEmailCodeResponse,
    isLoading: generateEmailCodeLoading,
    isSuccess: generateEmailCodeSuccess,
    isError: generateEmailCodeError,
    fetchData: generateEmailCode,
  } = useFetchData(generateEmailVerificationCode);
  const {
    data: validateEmailCodeResponse,
    isLoading: validateEmailCodeLoading,
    isSuccess: validateEmailCodeSuccess,
    isError: validateEmailCodeError,
    fetchData: validateEmailCode,
  } = useFetchData(validateEmailVerificationCode);
  const didNotGetMailIdGenerator = idGenerator(
    "emailverification",
    "didNotGetMail",
  );

  const sendNewCode = async () => {
    setResetKey((prev) => prev + 1);
    setServiceErrorMessage("");
    generateEmailCode([emailData.request]);
  };

  const onGenerateEmailCodeSuccess = useCallback(
    (response: EmailCodeGenerationResponse) => {
      if (
        typeof response?.canGenerateNewCode !== undefined &&
        !response?.canGenerateNewCode &&
        response?.code === 1009
      ) {
        setMaxLimit(true);
        setServiceErrorMessage(t("jobsite.common.reachedMaxLimit") as string);
        return;
      }
      if (response?.hasError && response?.status?.toLowerCase() === "failure") {
        setServiceErrorMessage(
          response && response?.translationKey
            ? (t(response?.translationKey) as string)
            : (t("jobsite.common.serverError") as string),
        );
        return;
      }
      if (emailData.response?.verificationId) {
        emailData.response.verificationId = response.verificationId;
      }
    },
    [setMaxLimit, setServiceErrorMessage, t, emailData.response],
  );

  const onValidateEmailCodeSuccess = useCallback(
    (response: EmailCodeVerificationResponse) => {
      if (response?.hasError && response?.status?.toLowerCase() === "failure") {
        setServiceErrorMessage(
          response && response?.translationKey
            ? (t(response?.translationKey) as string)
            : (t("jobsite.common.serverError") as string),
        );
        setVerificationCode("");
        setResetKey((prev) => prev + 1);
        return;
      }
      onAction();
    },
    [setServiceErrorMessage, t, setVerificationCode, setResetKey, onAction],
  );

  useEffect(() => {
    if (
      !generateEmailCodeLoading &&
      generateEmailCodeSuccess &&
      generateEmailCodeResponse
    ) {
      onGenerateEmailCodeSuccess(generateEmailCodeResponse);
      focusElement(verificationCodeIdGenerator.generateId("1"));
    }
    if (
      !generateEmailCodeLoading &&
      generateEmailCodeError &&
      !generateEmailCodeSuccess
    ) {
      setServiceErrorMessage(t("jobsite.common.serverError") as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    generateEmailCodeLoading,
    generateEmailCodeSuccess,
    generateEmailCodeResponse,
    generateEmailCodeError,
    onGenerateEmailCodeSuccess,
    t,
  ]);

  useEffect(() => {
    if (
      !validateEmailCodeLoading &&
      validateEmailCodeSuccess &&
      validateEmailCodeResponse
    ) {
      onValidateEmailCodeSuccess(validateEmailCodeResponse);
    }
    if (
      !validateEmailCodeLoading &&
      validateEmailCodeError &&
      !validateEmailCodeSuccess
    ) {
      setVerificationCode("");
      setResetKey((prev) => prev + 1);
      setServiceErrorMessage(t("jobsite.common.serverError") as string);
    }
  }, [
    validateEmailCodeLoading,
    validateEmailCodeSuccess,
    validateEmailCodeResponse,
    validateEmailCodeError,
    onValidateEmailCodeSuccess,
    t,
  ]);

  // Prevents parent overlay from closing on Escape key press when popover is active
  useEffect(() => {
    if (setIsPopoverActive) setIsPopoverActive(showPopOver);
  }, [showPopOver, setIsPopoverActive]);

  const onContinue = async () => {
    setServiceErrorMessage("");
    const data: EmailCodeVerificationRequest = {
      verificationId: emailData.response?.verificationId,
      emailId: emailData.request.emailId,
      userCode: verificationCode,
      locale,
    };
    if (emailData.request?.verifyAccess) {
      data.verifyAccess = emailData.request?.verifyAccess;
    }
    validateEmailCode([data]);
  };

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setServiceErrorMessage("");
    setVerificationCode(event.target.value);
  }

  return (
    <section className="pos-rel">
      {(validateEmailCodeLoading || generateEmailCodeLoading) && (
        <ProgressIndicatorLoader showLoading={true} />
      )}
      <div>
        <div className="text-center">
          <Icon name="apple-logo" size="xlarge"></Icon>
        </div>
        <h2
          className="text-center mt-10 t-eyebrow-reduced"
          id={EMAIL_VERIFICATION_MODAL_TITLE_ID}
        >
          {t("jobsite.common.emailVerificationCode")}
        </h2>
      </div>
      <div className="mt-10 text-center">
        <p>{t("jobsite.common.appleCodeSent")}</p>
        <p>{emailData.request.emailId}</p>
      </div>
      <div className="d-flex justify-content-center mt-10">
        <TextBoxSlot
          length={verificationCodeLength}
          value={verificationCode}
          key={resetKey}
          ariaLabelledbyId={EMAIL_VERIFICATION_MODAL_TITLE_ID}
          onChange={handleChange}
          slotSpecificAttrs={[
            {
              id: verificationCodeIdGenerator.generateId("1"),
              label: t("jobsite.common.digitNumber", { number: 1 }),
              "aria-labelledby":
                verificationCodeIdGenerator.generateId(`1_label`),
            },
            {
              id: verificationCodeIdGenerator.generateId("2"),
              label: t("jobsite.common.digitNumber", { number: 2 }),
              "aria-labelledby":
                verificationCodeIdGenerator.generateId(`2_label`),
            },
            {
              id: verificationCodeIdGenerator.generateId("3"),
              label: t("jobsite.common.digitNumber", { number: 3 }),
              "aria-labelledby":
                verificationCodeIdGenerator.generateId(`3_label`),
            },
            {
              id: verificationCodeIdGenerator.generateId("4"),
              label: t("jobsite.common.digitNumber", { number: 4 }),
              "aria-labelledby":
                verificationCodeIdGenerator.generateId(`4_label`),
            },
            {
              id: verificationCodeIdGenerator.generateId("5"),
              label: t("jobsite.common.digitNumber", { number: 5 }),
              "aria-labelledby":
                verificationCodeIdGenerator.generateId(`5_label`),
            },
            {
              id: verificationCodeIdGenerator.generateId("6"),
              label: t("jobsite.common.digitNumber", { number: 6 }),
              "aria-labelledby":
                verificationCodeIdGenerator.generateId(`6_label`),
            },
          ]}
        />
      </div>
      <div className="d-flex justify-content-center">
        {serviceErrorMessage && (
          <ErrorMessage
            message={serviceErrorMessage}
            errorA11y={t("jobsite.common.errorIconLabel") as string}
          />
        )}
      </div>
      <div className="text-center">
        <NativeButton
          id={didNotGetMailIdGenerator.generateId("button")}
          className={"blue underline"}
          onClick={() => setShowPopOver(!showPopOver)}
          aria-label={t("jobsite.common.didNotGetVerificationCode") as string}
          aria-description={t("jobsite.common.didNotGetEmail") as string}
        >
          {t("jobsite.common.didNotGetVerificationCode")}
        </NativeButton>

        {showPopOver && (
          <Popover
            id={didNotGetMailIdGenerator.generateId("popover")}
            aria-describedby="descDidNotGetEmail"
            aria-labelledby={didNotGetMailIdGenerator.generateId("button")}
            closeOnOutsideClick={true}
            arrowPosition="end"
            triggerElementId={didNotGetMailIdGenerator.generateId("button")}
            onClose={() => setShowPopOver(!showPopOver)}
            onBlur={() => setShowPopOver(false)}
          >
            <span id="descDidNotGetEmail">
              {t("jobsite.common.didNotGetEmail")}
            </span>
          </Popover>
        )}
      </div>
      <div className="mt-30 d-flex">
        <div className={"d-inline-block"}>
          <Button
            id={EMAIL_VERIFICATION_MODAL_SEND_CODE_BUTTON_ID}
            size="base"
            label={t("jobsite.common.sendNewCode") as string}
            color="secondary"
            blockedVariant={true}
            onClick={sendNewCode}
            disabled={maxLimit}
          ></Button>
        </div>
        <div className="d-flex justify-content-end w-100">
          <div className={"ml-10 d-inline-block"}>
            <Button
              id={EMAIL_VERIFICATION_MODAL_CANCEL_BUTTON_ID}
              size="base"
              label={t("jobsite.common.cancel") as string}
              color="secondary"
              blockedVariant={true}
              onClick={onCancel}
            ></Button>
          </div>
          <div className={"ml-20 d-inline-block"}>
            <Button
              id={EMAIL_VERIFICATION_MODAL_CONTINUE_BUTTON_ID}
              size="base"
              label={t("jobsite.common.continue") as string}
              blockedVariant={true}
              onClick={onContinue}
              disabled={verificationCode.length !== verificationCodeLength}
            ></Button>
          </div>
        </div>
      </div>
    </section>
  );
};
