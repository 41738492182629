import React, { useCallback } from "react";
import { MappedProductsAndServices } from "../../../shared/types/refData";
import {
  FILTER_TYPE,
  SET_FILTER,
  UNSET_FILTER,
} from "../../contexts/actionTypes";
import { FilterValue, SEARCH_FILTER_TYPE } from "../../contexts/SearchContext";
import useIsMobile from "../../hooks/useIsMobile";
import { useSearchContext } from "../../hooks/useSearchContext";
import { FilterOption } from "./FilterCheckboxGroup";
import ProductsFilter from "./ProductsFilter";

export type MappedProducsFilterOptions =
  FilterOption<MappedProductsAndServices>;

interface ProductsFilterWrapperProps {
  productFilterData: Array<MappedProductsAndServices>;
  productsData: Array<MappedProducsFilterOptions>;
  onMobileFilterChange?: (
    filterType: SEARCH_FILTER_TYPE,
    filterValue: FilterValue,
  ) => void;
}

export function ProductsFilterWrapper({
  productFilterData,
  productsData,
  onMobileFilterChange,
}: ProductsFilterWrapperProps) {
  const { dispatch } = useSearchContext();
  const isMobile = useIsMobile();
  const modifiedProductData = productsData.map((p) => {
    if (productFilterData.find((obj) => obj.id === p.id)) {
      return { ...p, selected: true };
    }
    return p;
  });

  const updateMobileFilters = useCallback(
    (filterType: SEARCH_FILTER_TYPE, filterValue: FilterValue) => {
      if (onMobileFilterChange) onMobileFilterChange(filterType, filterValue);
    },
    [onMobileFilterChange],
  );

  const selectedCount = modifiedProductData.filter(
    (obj: MappedProducsFilterOptions) => obj.selected,
  ).length;

  // dispatch a filter action in case of desktop / invoke mobileFilterChange callback incase of mobile
  const onUpdateProducts = useCallback(
    (products: Array<any>) => {
      if (!isMobile) {
        dispatch({
          type: SET_FILTER,
          filterName: FILTER_TYPE.PRODUCT,
          payload: products,
        });
      } else {
        updateMobileFilters(
          FILTER_TYPE.PRODUCT as SEARCH_FILTER_TYPE,
          products,
        );
      }
    },
    [dispatch, isMobile, updateMobileFilters],
  );

  // dispatch a filter action in case of desktop / invoke mobileFilterChange callback incase of mobile
  const onRemoveProducts = useCallback(() => {
    if (!isMobile) {
      dispatch({
        type: UNSET_FILTER,
        filterName: FILTER_TYPE.PRODUCT,
      });
    } else {
      updateMobileFilters(FILTER_TYPE.PRODUCT as SEARCH_FILTER_TYPE, []);
    }
  }, [dispatch, isMobile, updateMobileFilters]);
  return (
    <ProductsFilter
      productsData={modifiedProductData}
      onUpdateProducts={onUpdateProducts}
      onRemoveProducts={onRemoveProducts}
      isAccordionOpen={productFilterData.length > 0}
      selectedCount={selectedCount}
    />
  );
}
