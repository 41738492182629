import * as React from "react";
import { ForwardedRef, forwardRef, useContext } from "react";
import { LocationPickerContext } from "../../contexts/LocationPickerContext";
import useIntlMessage from "../../hooks/useIntlMessage";
import LocationPickerStoreBenefitsModal from "./LocationPickerStoreBenefitsModal";

type Props = {
  city?: string;
  storeName?: string;
  hiringNow?: boolean;
  loading: boolean;
  locationId?: string;
  showPayAndBenefits?: boolean;
  coverImage?: string;
  isActive?: number;
  onKeyDown?: (event: React.KeyboardEvent<HTMLLIElement>) => void;
};

const LocationPickerStoreCardComponent = (
  props: Props,
  ref: ForwardedRef<HTMLLIElement>,
) => {
  const context = useContext(LocationPickerContext);
  if (!context) {
    throw new Error(
      "LocationPickerHeader must be used within a LocationPickerProvider",
    );
  }

  const { selectedStores, setSelectedStores } = context;
  const { t } = useIntlMessage();
  const {
    city,
    storeName,
    hiringNow,
    loading,
    locationId,
    showPayAndBenefits,
    isActive,
    onKeyDown,
  } = props;

  function isChecked() {
    return (
      selectedStores.filter((store) => store.locationId === locationId).length >
      0
    );
  }

  function toggleCardSelection() {
    if (isChecked()) {
      // remove
      setSelectedStores(
        selectedStores.filter((store) => store.locationId !== locationId),
      );
    } else {
      // add
      setSelectedStores([
        ...selectedStores,
        {
          locationId,
          storeName,
        },
      ]);
    }
  }

  return (
    <React.Fragment>
      {loading ? (
        <li className="card card-skeleton px-40" role="listitem" />
      ) : (
        <li
          className="card card-container"
          role="listitem"
          ref={ref}
          tabIndex={isActive ? 0 : -1}
          onKeyDown={onKeyDown}
        >
          <label
            htmlFor={`store-selection-${locationId}`}
            className="d-flex column-reverse"
          >
            <h4 className="t-label">{storeName}</h4>
            <label className="t-body">{city}</label>
            {hiringNow ? (
              <span className="hiring-now">
                {t("jobsite.locationPicker.hiringNow")}
              </span>
            ) : (
              <span className="hiring-now future-applications">
                {t("jobsite.locationPicker.acceptingFutureApplications")}
              </span>
            )}
            <div className="card-header">
              <input
                id={`store-selection-${locationId}`}
                type="checkbox"
                checked={isChecked()}
                className="card-checkbox"
                onChange={() => toggleCardSelection()}
                aria-label={storeName}
              />
              {isChecked() ? (
                <i className="icon icon-checksolid card-checkbox-icon" />
              ) : (
                <i className="icon icon-circle card-checkbox-icon" />
              )}
            </div>
          </label>
          {showPayAndBenefits && (
            <div className="card-footer">
              <LocationPickerStoreBenefitsModal
                locationId={locationId}
                storeName={storeName}
                city={city}
              />
            </div>
          )}
        </li>
      )}
    </React.Fragment>
  );
};
export const LocationPickerStoreCard = forwardRef(
  LocationPickerStoreCardComponent,
);
