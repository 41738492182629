import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useRouteLoaderData, useSearchParams } from "react-router";
import { SelfDisclosures } from "../../../shared/types";
import APP_CONSTANTS from "../../../utilities/appConstants";
import { getSelfDisclosureRefData } from "../../api/fetchClient";
import {
  ALERT_WRAPPER_ID,
  DEFAULT_LOCALE_API_HEADER,
  ROLE_SUBMIT,
  SELECTED_LOCATIONS_FOR_ROLE,
  STEP_NAME,
} from "../../app.constants";
import AppContext from "../../AppContext";
import { AlertWrapper } from "../../components/base/AlertWrapper";
import HtmlRenderer from "../../components/base/HtmlRenderer";
import { StepProgress } from "../../components/base/StepProgress";
import translateReplace from "../../hooks/translateReplace";
import useDtm from "../../hooks/useAdobeAnalytics";
import useAutoFocus from "../../hooks/useAutoFocus";
import useIntlMessage from "../../hooks/useIntlMessage";
import { RouteID } from "../../routes";
import { FileData } from "../../types/File";
import { isCorporate, isRetailType } from "../../util";
import { idGenerator } from "../../utils/idGenerator";
import { SessionStorage } from "../../utils/sessionStorageUtil";
import { useApplyContext } from "./context/ApplyContext";
import { StepName, Talent } from "./context/ApplyState";
import { QuestionnaireStep } from "./QuestionnaireStep";
import { ResumeStep } from "./ResumeStep";
import { ReviewStep } from "./ReviewInfoStep";
import { SelfDisclosureStep } from "./SelfDisclosureStep";

export function ApplyStepWrapper() {
  const jobDetails = useRouteLoaderData(RouteID.apply) as any;
  const title = jobDetails.postingTitle;
  const { state } = useApplyContext();
  const [clientParams, setClientParams] = useSearchParams();
  const [step, setStep] = useState<string>(
    state.page.firstStep || StepName.RESUME,
  ); // first step to land on irrespective of url params
  const stepNames = state.page.stepNames as Array<string>; // stepNames required for the given job
  const stepMapping: Record<string, number> = state.page.stepMapping as Record<
    string,
    number
  >; // stepMapping for step -> stepNumber
  const [currStep, setCurrStep] = useState(
    stepMapping[state.page.firstStep as StepName] || 1,
  );
  const skipSelfDisclosure = state.page.skipSelfDisclosure || false;
  const [showEntryMessage, setShowEntryMessage] = useState(true);
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { locale } = appUIState.appData;
  const [entryMessage, setEntryMessage] = useState("");
  const [selfDisclosuresRefData, setSelfDisclosuresRefData] = useState<
    SelfDisclosures | undefined
  >();
  const titleRef = useAutoFocus<HTMLDivElement>([step]);
  const dataLocale = isCorporate(jobDetails) ? DEFAULT_LOCALE_API_HEADER : "";
  const { countryCode } = appUIState.appData;
  const selfDisclosureConfig = jobDetails.selfDisclosureConfig;

  const hasResume = (resume: FileData | null) => {
    return resume && (resume.fileId || resume.content);
  };

  const hasLinks = (talent: Talent) => {
    return talent.links && talent.links.length > 0;
  };

  const hasLinkedIn = (talent: Talent) => {
    return talent.externalProfiles && talent.externalProfiles.linkedin;
  };

  const hasAddress = (talent: Talent) => {
    return talent.addresses && talent.addresses.length > 0;
  };

  const checkForEntryMessage = useCallback(() => {
    if (!showEntryMessage) return;
    const roleSubmit = SessionStorage.get(ROLE_SUBMIT);
    const linkUrl = `/app/${locale}/apply/${jobDetails.jobNumber}?stepName=resume`;
    if (
      !roleSubmit &&
      (hasResume(state.resume) ||
        hasLinks(state.talent) ||
        hasLinkedIn(state.talent) ||
        hasAddress(state.talent))
    ) {
      setShowEntryMessage(true);
      setEntryMessage(t("jobsite.getdiscovered.entryMessage") as string);
    } else if (roleSubmit && step != StepName.RESUME) {
      if (
        hasResume(state.resume) ||
        hasLinks(state.talent) ||
        hasLinkedIn(state.talent)
      ) {
        setShowEntryMessage(true);
        setEntryMessage(
          translateReplace(
            t("jobsite.apply.stepOne") as string,
            ["{linkurl}"],
            [linkUrl],
          ),
        );
      } else {
        setEntryMessage("");
      }
    } else {
      setEntryMessage("");
    }
  }, [
    jobDetails.jobNumber,
    locale,
    showEntryMessage,
    state.resume,
    state.talent,
    step,
    t,
  ]);

  const updateUrlParams = useCallback(
    (queryParam: string, value: string) => {
      const params = new URLSearchParams(clientParams);
      params.set(queryParam, value);
      setClientParams(params, { replace: true });
    },
    [clientParams, setClientParams],
  );

  const nextStep = useCallback(
    (stepName: StepName) => {
      let stepNumber = stepMapping[stepName];
      // we have to skip self disclosure step as the data is already filled
      if (stepNames[stepNumber - 1] == StepName.RESUME && skipSelfDisclosure) {
        stepNumber = stepNumber + 1; // skip next step
      }
      setStep(stepNames[stepNumber]);
      setCurrStep(stepNumber + 1);
      checkForEntryMessage();
    },
    [checkForEntryMessage, skipSelfDisclosure, stepMapping, stepNames],
  );

  const prevStep = useCallback(
    (stepName: StepName) => {
      let stepNumber = stepMapping[stepName];
      // we have to skip self disclosure step as the data is already filled
      if (
        stepNames[stepNumber - 2] == StepName.SELFDISCLOSURE &&
        skipSelfDisclosure
      ) {
        stepNumber = stepNumber - 1; // skip previous step
      }
      setStep(stepNames[stepNumber - 2]);
      setCurrStep(stepNumber - 1);
      checkForEntryMessage();
    },
    [checkForEntryMessage, skipSelfDisclosure, stepMapping, stepNames],
  );

  const goToPreviousStep = useCallback(() => {
    prevStep(step as StepName);
  }, [prevStep, step]);

  const moveToNextStep = useCallback(() => {
    nextStep(step as StepName);
  }, [nextStep, step]);

  const closeEntryMessage = useCallback(() => {
    setShowEntryMessage(false);
  }, []);

  const goToStep = useCallback(
    (stepName: StepName) => {
      setStep(stepName);
      setCurrStep(stepMapping[stepName]);
      checkForEntryMessage();
    },
    [checkForEntryMessage, stepMapping],
  );

  const entryMessageClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if ((event.target as HTMLAnchorElement).tagName === "A") {
        event.preventDefault();
        goToStep(StepName.RESUME);
      }
    },
    [goToStep],
  );

  const StepProgressMemo = memo(function StepProgressMemo({
    stepNamesLength,
    currStep,
  }: {
    stepNamesLength: number;
    currStep: number;
  }) {
    return (
      <StepProgress
        id={idGenerator("apply", "stepprogress").generateId()}
        count={stepNamesLength}
        currStep={currStep}
      ></StepProgress>
    );
  });

  const getSelfDisclosures = useCallback(async () => {
    if (selfDisclosureConfig && selfDisclosureConfig.countryID) {
      const selfDisclosures = await getSelfDisclosureRefData({
        headers: { locale: dataLocale || countryCode?.data },
        queryParams: { countryId: selfDisclosureConfig.countryID as string },
      });
      setSelfDisclosuresRefData(selfDisclosures);
    }
  }, [countryCode?.data, dataLocale, selfDisclosureConfig]);
  const { trackPageLoad } = useDtm();
  useEffect(() => {
    getSelfDisclosures();
  }, [getSelfDisclosures]);
  useEffect(() => {
    const currentStepName = stepNames[currStep - 1];
    let currentPageName = APP_CONSTANTS.ANALYTICS.APPLY;
    if (currentStepName == StepName.RESUME)
      currentPageName = APP_CONSTANTS.ANALYTICS.APPLY_RESUME;
    else if (currentStepName == StepName.SELFDISCLOSURE)
      currentPageName = APP_CONSTANTS.ANALYTICS.APPLY_SELF_DISCLOSURE;
    else if (currentStepName == StepName.QUESTIONNAIRE)
      currentPageName = APP_CONSTANTS.ANALYTICS.APPLY_QUESTIONNAIRE;
    else if (currentStepName == StepName.REVIEW_INFO)
      currentPageName = APP_CONSTANTS.ANALYTICS.APPLY_REVIEWINFO;

    const pageInfo = {
      pageName: currentPageName,
      locale: appUIState?.appData?.locale || APP_CONSTANTS.DEFAULT_LOCALE,
      title: APP_CONSTANTS.ANALYTICS.APPLY,
      isInternal: appUIState?.appData?.isInternal,
    };
    trackPageLoad(pageInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currStep, stepNames]);
  useEffect(() => {
    updateUrlParams(STEP_NAME, stepNames[currStep - 1]);
  }, [currStep, stepNames, updateUrlParams]);

  useEffect(() => {
    checkForEntryMessage();
  }, [checkForEntryMessage]);

  // remove the selected locations from session storage on load
  useEffect(() => {
    if (jobDetails?.managedPipelineRole && isRetailType(jobDetails))
      SessionStorage.remove(SELECTED_LOCATIONS_FOR_ROLE);
  }, [jobDetails]);

  return (
    <>
      {showEntryMessage && entryMessage && (
        <div
          role="group"
          aria-label={t("jobs.apply.profileInformationAlertVO") as string}
        >
          <AlertWrapper
            closePosition="left"
            remove={{
              closeBtnAriaLabel: t("jobsite.common.closeAlert") as string,
              onRemove: closeEntryMessage,
              idToFocus: idGenerator("apply", "jobtitle").generateId(),
            }}
            id={ALERT_WRAPPER_ID.generateId("apply-entrymessage")}
          >
            <span onClick={entryMessageClick}>
              <HtmlRenderer
                initialContent={entryMessage}
                htmlTag="span"
                classes="hyperlink-underline"
              ></HtmlRenderer>
            </span>
          </AlertWrapper>
        </div>
      )}
      <section
        className="pb-40 pt-40"
        id={idGenerator("apply", "container").generateId()}
      >
        <p
          id={idGenerator("apply", "jobtitle").generateId()}
          className="fw-500 text-center t-eyebrow-reduced"
          ref={titleRef}
          tabIndex={-1}
        >
          {title}
        </p>
        <div className="m-20 text-center">
          <StepProgressMemo
            stepNamesLength={stepNames.length}
            currStep={currStep}
          ></StepProgressMemo>
        </div>
        {step == StepName.RESUME && (
          <>
            <section
              className="resume-step-max-width"
              id={idGenerator("apply", "resume").generateId()}
            >
              <ResumeStep
                moveNext={moveToNextStep}
                goToStep={goToStep}
              ></ResumeStep>
            </section>
          </>
        )}
        {step == StepName.SELFDISCLOSURE && (
          <SelfDisclosureStep
            selfDisclosuresRefData={selfDisclosuresRefData}
            goToStep={goToStep}
            moveNext={moveToNextStep}
            goPrevious={goToPreviousStep}
          ></SelfDisclosureStep>
        )}
        {step == StepName.QUESTIONNAIRE && (
          <QuestionnaireStep
            jobId={jobDetails.positionId}
            moveNext={moveToNextStep}
            goPrevius={goToPreviousStep}
          ></QuestionnaireStep>
        )}
        {step == StepName.REVIEW_INFO && (
          <ReviewStep
            selfDisclosuresRefData={selfDisclosuresRefData}
            goToStep={goToStep}
            goPrevious={goToPreviousStep}
          ></ReviewStep>
        )}
      </section>
    </>
  );
}
