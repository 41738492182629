import { Checkbox } from "@rpe-js/marcom-web-components";
import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { MyApplication } from "../../../../shared/types/talent/myApplication";
import { useCurrentUserContext } from "../../../CurrentUserContext";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useSetPageTitle, { PAGE_TITLES } from "../../../hooks/useSetPageTitle";
import { ActionContainer } from "./ActionContainer";
import { useMyApplicationContext } from "./context/context";
import { MyApplicationActionDispatcher } from "./context/state";
import { useUpdateApplication } from "./hooks/createAndUpdateApplicationHooks";
import { useGotoNextStep } from "./hooks/useGotoNextStep";

export function InformationReleaseStep() {
  const { state, dispatch } = useMyApplicationContext();
  const { t } = useIntlMessage();
  const { currentUser } = useCurrentUserContext();
  const { application } = state;
  const { submitted, currentStep, page } = state;
  const { setPageTitle } = useSetPageTitle();
  const goToNextStep = useGotoNextStep();
  const [ack, setAck] = useState<boolean>();
  const [showError, setShowError] = useState<boolean>(false);

  const {
    //isUpdateApplicationError,
    isUpdateApplicationLoading,
    isUpdateApplicationSuccess,
    updateApplication,
    resetUpdateApplication,
    updatedApplication,
  } = useUpdateApplication();

  useEffect(() => {
    setPageTitle(
      PAGE_TITLES.YOUR_APPLICATION.INFO_RELEASE,
      currentStep ? page.stepMapping[currentStep] : "",
      page.stepNames.length,
    );
  }, [currentStep, page.stepMapping, page.stepNames.length, setPageTitle]);

  useEffect(() => {
    if (
      !isUpdateApplicationLoading &&
      isUpdateApplicationSuccess &&
      updatedApplication
    ) {
      MyApplicationActionDispatcher.onInfoReleaseStepSave(
        dispatch,
        updatedApplication,
      );
      resetUpdateApplication();
      goToNextStep("next");
    }
  }, [
    dispatch,
    isUpdateApplicationLoading,
    isUpdateApplicationSuccess,
    updatedApplication,
    resetUpdateApplication,
    goToNextStep,
  ]);

  const onSave = useCallback(async () => {
    if (!ack) {
      setShowError(true);
    } else {
      setShowError(false);
      if (application && currentUser.talentId) {
        const requestBody = cloneDeep(application);
        requestBody.informationRelease = {
          time: new Date().getTime() as any,
          acknowledge: true,
        };
        await updateApplication(
          currentUser.talentId,
          application?.id,
          "informationRelease",
          requestBody as MyApplication,
        );
      }
    }
  }, [application, currentUser, ack, updateApplication]);

  const onChange = useCallback((isChecked: boolean) => {
    setAck(isChecked);
  }, []);

  return (
    <>
      <form className="w-100">
        <fieldset>
          <div className="large-12 small-12">
            <Checkbox
              required={true}
              onValueChange={onChange}
              checked={ack}
              defaultChecked={false}
              label={t("jobsite.profile.myApplication.iAck") as string}
              error={
                showError
                  ? (t("jobsite.profile.myApplication.plsAck") as string)
                  : ""
              }
            />
          </div>
        </fieldset>
      </form>
      {!submitted && (
        <ActionContainer
          saveDisabled={!state.infoReleaseStep.canSave}
          onSave={() => onSave()}
          onBack={() => goToNextStep("prev")}
          onSubmit={() => {}}
        />
      )}
    </>
  );
}
