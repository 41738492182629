import { formatDateByLocale } from "@rpe-js/core/dist/util/dateUtil";
import { Overlay, TextButton } from "@rpe-js/marcom-web-components";
import React, { useState } from "react";
import { MyApplication } from "../../../../../../shared/types/talent/myApplication";
import { DEFAULT_LOCALE } from "../../../../../app.constants";
import { Info } from "../../../../../components/base/Info";
import useIntlMessage from "../../../../../hooks/useIntlMessage";
import useIsMobile from "../../../../../hooks/useIsMobile";
import { useMyApplicationContext } from "../../context/context";
import { ReviewSection, SectionMode } from "../../context/types";
import { SectionHeader } from "../SectionHeader";

interface SectionProps {
  id: string;
  onSave: (
    name: ReviewSection,
    mode: SectionMode,
    application: MyApplication,
  ) => void;
  mode: SectionMode;
}

interface InformationReleaseProps extends SectionProps {}

export function InformationReleaseSection(props: InformationReleaseProps) {
  const { id, mode } = props;
  const { state } = useMyApplicationContext();
  const { infoReleaseStep, application } = state;
  const { title } = infoReleaseStep;
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const [showInfoRelModal, setShowInfoRelModal] = useState<boolean>(false);
  //const sectionName: ReviewSection = "informationRelease";

  const ackTime = new Date(
    application?.informationRelease.time as any,
  ).getTime();

  const viewInfoReleaseBtn = `${id}-view-button`;

  return (
    <SectionHeader
      id={id}
      title={title}
      mode={mode}
      onEdit={() => {}}
      editDisabled={true}
    >
      <div className="d-flex flex-column mt-10">
        <Info
          value={
            t("jobsite.profile.myApplication.acknowledgedDate", {
              date: formatDateByLocale(ackTime, DEFAULT_LOCALE),
            }) as string
          }
        ></Info>
        <TextButton
          id={viewInfoReleaseBtn}
          classes="text-align-start t-body-reduced"
          label={t("jobsite.profile.myApplication.view") as string}
          onClick={() => setShowInfoRelModal(true)}
        />
      </div>
      {showInfoRelModal && (
        <Overlay
          id="inforel-alert-overlay"
          elementIdToFocus={viewInfoReleaseBtn}
          visible={showInfoRelModal}
          onClose={() => setShowInfoRelModal(false)}
          isFullscreen={isMobile}
          closeButtonAttrs={{
            ariaLabel: t("jobsite.common.close") as string,
          }}
        >
          <>
            <h2 className="t-eyebrow-reduced mb-15 text-center">
              {t("jobsite.profile.myApplication.infoRelease")}
            </h2>
            <div className="d-flex flex-column">
              <Info
                classes="mb-20"
                value={
                  t(
                    "jobsite.profile.myApplication.infoReleaseMessage",
                  ) as string
                }
              ></Info>
              <Info
                value={
                  t("jobsite.profile.myApplication.acknowledgedDate", {
                    date: formatDateByLocale(ackTime, DEFAULT_LOCALE),
                  }) as string
                }
              ></Info>
            </div>
          </>
        </Overlay>
      )}
    </SectionHeader>
  );
}
