export const SessionStorage = {
  set: (key: string, value: any) => setDataToSessionStorage(key, value),
  get: (key: string) => getDataFromSessionStorage(key),
  remove: (key: string) => removeDataFromSessionStorage(key),
};

/**
 * This utility will save data on session storage
 * @param key is the property name
 * @param value is the data to be stored in session storage
 */
function setDataToSessionStorage(key: string, value: any) {
  if (window.sessionStorage) {
    window.sessionStorage.setItem(key, value);
  }
}

/**
 * This utility can be used to retrieve data from session storage based on property name
 * @param key is the property to be read from session storage
 */
function getDataFromSessionStorage(key: string) {
  if (window.sessionStorage?.getItem(key)) {
    return window.sessionStorage.getItem(key);
  }
}

/**
 * This utility can be used to remove the data from session storage based on property name
 * @param key is the property to be removed from session storage
 */
function removeDataFromSessionStorage(key: string) {
  if (window.sessionStorage?.getItem(key)) {
    window.sessionStorage.removeItem(key);
  }
}
