import { MonthsRefData } from "@rpe-js/core/src/types/refData";
import {
  SearchFilters,
  TeamsFilter,
} from "@rpe-js/core/src/types/search/search";
import { CountryCode } from "../../server/types";
import { SavedSearchType, SelfDisclosures, Talent } from "../../shared/types";
import { Config } from "../../shared/types/config";
import {
  EmailCodeGenerationRequest,
  EmailCodeGenerationResponse,
  EmailCodeVerificationRequest,
  EmailCodeVerificationResponse,
} from "../../shared/types/emailVerification";
import {
  EmployeeReferral,
  ERP_POSTLOCATIONS_QUERY_PARAMS,
} from "../../shared/types/erp";
import { ShareJobDetails } from "../../shared/types/jobs";
import {
  Questionnaire,
  TalentQuestionnaire,
  TalentQuestionnaireAnswer,
} from "../../shared/types/questionnaire";
import {
  AppleStoresRefData,
  BaseRefData,
  EducationGradStatusRefData,
  HiringManagerDetails,
  MappedERPPostLocation,
  MappedLanguages,
  MappedPostLocation,
  MappedTeams,
  OrderedRefData,
  ProficiencyLanguageRefData,
  RefData,
  RolesAndProductsRefDataForSearch,
  SkillByInputRefData,
  TeamsEmpTypeDisablityRefdata,
  TeamsRefData,
} from "../../shared/types/refData";
import { Role, TalentConnectionStoreLocation } from "../../shared/types/role";
import {
  AllowedMyApplicationUpdateAction,
  MyApplication,
} from "../../shared/types/talent/myApplication";
import {
  AllowedProfileSaveCategories,
  CountrySpecificSelfDisclosureConfig,
  ExternalProfileLinkedRequest,
  PrivacyPolicy,
  PrivacyPolicyUpdateResponse,
  SourceID,
  TalentParsedExternalModel,
  TalentPrivacyPolicyStatus,
  TalentRoleUpdateRequestBody,
} from "../../shared/types/talent/talent";
import { TalentEmailDetails } from "../../shared/types/talent/talentEmailDetails";
import { TalentFileMetaData } from "../../shared/types/talent/talentFileMetatdata";
import { TemplateFormData } from "../../shared/types/talent/template";
import { User } from "../../shared/types/talent/user";
import {
  APPLICATION_OCTET_STREAM,
  CREATE_SOURCE_JS,
  DEFAULT_LOCALE_API_HEADER,
  DEFAULT_POSTING_LOCATION_HEADER,
  PRIMARY_TYPE,
} from "../app.constants";
import { FileData } from "../types/File";
import {
  SupportingFileCategory,
  TalentSupportingFile,
} from "../types/SupportingFileWidget";
import {
  ApiResponse,
  default as ApiServiceHandler,
  RequestOptions,
} from "./apiService";
import { regExpEscapeForTeamsFilter } from "../utils/filterUtil";

/**
 *  @remarks
 *  This file serves as a wrapper on top of the ApiServiceHandler.apiService.
 *
 *  Create wrapper for all the endpoints in the apiDictionary {rpe-jobsite/src/app/api/apiDictionary.ts}
 **/
export default function buildHeaders(code: CountryCode | null) {
  if (code) {
    const {
      appleLocale = DEFAULT_LOCALE_API_HEADER,
      postingLocation = DEFAULT_POSTING_LOCATION_HEADER,
      roverEmailLocaleCode = DEFAULT_LOCALE_API_HEADER,
    } = code;
    return {
      locale: appleLocale,
      countrycode: postingLocation,
      roveremaillocalecode: roverEmailLocaleCode,
    };
  }
}

export async function getJobDetails(jobId: string, locale: string) {
  try {
    const response = await ApiServiceHandler.service.job.details.get({
      pathParams: { jobId: jobId },
      queryParams: { locale: locale },
      headers: { locale: locale },
    });
    if (!response.success) {
      throw response;
    }
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function keepAlive() {
  const response = await ApiServiceHandler.service.app.keepAlive.get({});
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function fetchStoreLocations(
  jobId: string,
  queryParams: Record<string, any>,
  countryCode: any,
) {
  const headers = buildHeaders(countryCode);
  const response = await ApiServiceHandler.service.job.storeLocation.get({
    queryParams: {
      jobId,
      ...queryParams,
    },
    headers: {
      ...headers,
    },
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getStoreBenefits(
  jobId: string,
  postLocation: string,
  locale: string,
) {
  const response = await ApiServiceHandler.service.job.storeBenefits.get({
    queryParams: {
      jobId,
      postLocation,
      locale,
    },
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getPostLocationTypeahead(
  input: string,
  countryId: string,
  locale: string,
) {
  const response =
    await ApiServiceHandler.service.job.postLocationTypeAhead.get({
      queryParams: {
        input,
        countryId,
        locale,
      },
    });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getCaptcha(locale: string, captchaType: string) {
  const response: any = await ApiServiceHandler.service.job.captcha.get({
    queryParams: { locale: locale, captchaType: captchaType },
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function shareJob(data: ShareJobDetails, captchaType: string) {
  const response: any = await ApiServiceHandler.service.job.email.post({
    body: data,
    queryParams: { captchaType: captchaType },
  });
  return response;
}

export async function getRecentlyViewedJobs(jobIds: Array<string>) {
  const response: any =
    await ApiServiceHandler.service.search.recentlyViewedRoles.post({
      body: { jobIds },
    });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getCurrentUser() {
  const response =
    await ApiServiceHandler.service.profile.currentUser.get<User>();
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getConfig() {
  const response = await ApiServiceHandler.service.profile.config.get<Config>();
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getTalent(talentId: string) {
  const response = await ApiServiceHandler.service.talent.getTalent.get<Talent>(
    {
      pathParams: { talentId },
    },
  );
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getResumelastaction(talentId: string) {
  const response =
    await ApiServiceHandler.service.talent.getResumelastaction.get<TalentParsedExternalModel>(
      { pathParams: { talentId } },
    );
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getQuestionnaire(options: RequestOptions) {
  const response =
    await ApiServiceHandler.service.job.questionnaire.get<Questionnaire>(
      options,
    );
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getVeteranStatusTemplateForm(options: RequestOptions) {
  const response =
    await ApiServiceHandler.service.templates.veteranStatusTemplateForm.get<TemplateFormData>(
      options,
    );
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getDisabilityTemplateForm(options: RequestOptions) {
  const response =
    await ApiServiceHandler.service.templates.disabilityTemplateForm.get<TemplateFormData>(
      options,
    );
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getPrivacyPolicyTemplateForm() {
  const response =
    await ApiServiceHandler.service.templates.privacyPolicyTemplateform.get<TemplateFormData>();
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getPrivacyPolicy() {
  const response =
    await ApiServiceHandler.service.talent.getOrUpdatePrivacyPolicy.get<TalentPrivacyPolicyStatus>();
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function updatePrivacyPolicy(
  privacyPolicy: PrivacyPolicy,
  returnUrl?: string,
) {
  let options: RequestOptions<PrivacyPolicy> = {
    body: privacyPolicy,
    queryParams: {},
  };
  if (returnUrl) {
    options = {
      ...options,
      queryParams: { returnUrl },
      handleRedirect: true,
    };
  }
  const response =
    await ApiServiceHandler.service.talent.getOrUpdatePrivacyPolicy.put<
      PrivacyPolicyUpdateResponse,
      PrivacyPolicy
    >(options);

  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getSelfDisclosureRefData(options: RequestOptions) {
  const response =
    await ApiServiceHandler.service.refData.selfDisclosure.get<SelfDisclosures>(
      options,
    );
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getTalentFileMetaData(talentId: string) {
  const response = await ApiServiceHandler.service.talent.getFileMetaData.get<
    Array<TalentFileMetaData>
  >({
    pathParams: { talentId },
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function updateTalent(
  talentId: string,
  section: AllowedProfileSaveCategories,
  payload: Partial<Talent>,
) {
  const response = await ApiServiceHandler.service.talent.updateTalent.put({
    pathParams: { talentId, section },
    body: payload,
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getFileLinkRefData(options: RequestOptions) {
  const response = await ApiServiceHandler.service.refData.linkFileRefData.get<{
    supportingLink: Array<SupportingFileCategory>;
    supportingFile: Array<SupportingFileCategory>;
  }>(options);
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getTalentEmailDetails() {
  const response =
    await ApiServiceHandler.service.talent.getTalentEmailDetails.get<TalentEmailDetails>();
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function generateEmailVerificationCode(
  body: EmailCodeGenerationRequest,
) {
  const response =
    await ApiServiceHandler.service.talent.generateEmailVerificationCode.post<
      EmailCodeGenerationResponse,
      EmailCodeGenerationRequest
    >({ body });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function validateEmailVerificationCode(
  body: EmailCodeVerificationRequest,
) {
  const response =
    await ApiServiceHandler.service.talent.validateEmailVerificationCode.post<
      EmailCodeVerificationResponse,
      EmailCodeVerificationRequest
    >({ body });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function downloadFile(
  talentId: string,
  fileId: string,
  fileName: string,
  bucketId: string,
) {
  const response = await ApiServiceHandler.service.talent.downloadFile.get({
    pathParams: { talentId, fileId, bucketId },
    headers: { responseType: APPLICATION_OCTET_STREAM },
  });

  if (response.success) {
    const blob = await (response as any).data.blob(); // Convert response to Blob
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(url); // Cleanup the URL object
  } else {
    throw new Error(response.error);
  }
}

export async function getTeamsTalentEmpTypDisability() {
  const response =
    await ApiServiceHandler.service.refData.teamsTalentEmpTypDisability.get<TeamsEmpTypeDisablityRefdata>();
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getSkillsByInput(input: string) {
  const response = await ApiServiceHandler.service.refData.skills.get<
    SkillByInputRefData[]
  >({
    queryParams: { input },
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

function deleteFilePromise(talentId: string, fileId: string) {
  return () => {
    return ApiServiceHandler.service.talent.deleteFile.delete({
      pathParams: { talentId, fileId: fileId },
    });
  };
}

export function deleteFilePromises(talentId: string, fileId: Array<string>) {
  const promises = [];
  for (let i = 0; i < fileId.length; i++) {
    promises.push(deleteFilePromise(talentId, fileId[i]));
  }
  return promises;
}

export async function talentSubmit(talentId: string, payload: any) {
  const response = await ApiServiceHandler.service.talent.submit.put({
    pathParams: { talentId },
    body: { ...payload },
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

function updateFileCategoryPromise(
  talentId: string,
  fileId: string,
  type: string,
  typeId: string,
) {
  return () => {
    return ApiServiceHandler.service.talent.updateFileCategory.put({
      pathParams: { talentId, fileId: fileId },
      body: {
        type,
        typeId,
      },
    });
  };
}

export function updateFileCategoryPromises(
  talentId: string,
  fileTypeCategories: Array<any>,
) {
  const promises: Array<() => Promise<any>> = [];

  // key is "{fileId}", value is { type, typeID }
  Object.entries(fileTypeCategories).forEach(([key, value]) => {
    promises.push(
      updateFileCategoryPromise(talentId, key, value.type, value.typeId),
    );
  });

  return promises;
}

export async function getTalentConnectionData(
  talentId: string,
  positionId: string,
) {
  const response =
    await ApiServiceHandler.service.talent.getConnection.get<any>({
      pathParams: { talentId, positionId },
    });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getCountryRefData(locale?: string) {
  const response = await ApiServiceHandler.service.refData.country.get<
    BaseRefData[]
  >({
    ...(locale ? { headers: { locale } } : {}),
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getLanguagesProficiency(locale?: string) {
  const response =
    await ApiServiceHandler.service.refData.talentLanguagesAndProficiency.get<ProficiencyLanguageRefData>(
      {
        ...(locale ? { headers: { locale } } : {}),
      },
    );
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getPreferredLocationsByInput(input: string) {
  const response =
    await ApiServiceHandler.service.refData.preferredLocation.get<
      MappedPostLocation[]
    >({
      queryParams: { input },
    });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getApplicationStepData() {
  const headers = { locale: DEFAULT_LOCALE_API_HEADER };
  const profLangRefDataPromise =
    ApiServiceHandler.service.refData.talentLanguagesAndProficiency.get<ProficiencyLanguageRefData>(
      {
        headers,
      },
    );
  const monthsRefDataPromise = ApiServiceHandler.service.refData.months.get<
    MonthsRefData[]
  >({
    headers,
  });
  const eduGradStatusRefDataPromise =
    ApiServiceHandler.service.refData.educationGradStatus.get<EducationGradStatusRefData>(
      {
        headers,
      },
    );
  const countryRefDataPromise = ApiServiceHandler.service.refData.country.get<
    BaseRefData[]
  >({
    headers,
  });
  const [
    profLangRefDataResponse,
    monthsRefDataResponse,
    eduGradStatusRefDataResponse,
    countryRefDataResponse,
  ] = await Promise.all([
    profLangRefDataPromise,
    monthsRefDataPromise,
    eduGradStatusRefDataPromise,
    countryRefDataPromise,
  ]);

  if (
    profLangRefDataResponse.success &&
    monthsRefDataResponse.success &&
    eduGradStatusRefDataResponse.success &&
    countryRefDataResponse.success
  ) {
    return {
      proficiencyLanguageRefData:
        profLangRefDataResponse.data as ProficiencyLanguageRefData,
      monthsRefData: monthsRefDataResponse.data as MonthsRefData[],
      eduGradStatusRefData:
        eduGradStatusRefDataResponse.data as EducationGradStatusRefData,
      countryRefData: countryRefDataResponse.data as BaseRefData[],
    };
  } else {
    throw new Error("Error while fetching Proficieny, Graduation Refdata");
  }
}

export async function getMonths() {
  const response =
    await ApiServiceHandler.service.refData.months.get<MonthsRefData[]>();
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getEducationGradStatus() {
  const response =
    await ApiServiceHandler.service.refData.educationGradStatus.get<EducationGradStatusRefData>();
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getSchoolByInput(input: string) {
  const response = await ApiServiceHandler.service.refData.school.get<
    RefData[]
  >({
    queryParams: { input },
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getMajorByInput(
  input: string,
  options: RequestOptions = {},
) {
  options = {
    ...options,
    queryParams: { input },
  };
  const response =
    await ApiServiceHandler.service.refData.major.get<RefData[]>(options);
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getTalentCompanyByInput(input: string) {
  const response = await ApiServiceHandler.service.refData.talentCompany.get<
    RefData[]
  >({
    queryParams: { input },
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getTalentRoles(
  talentId: string,
  options?: RequestOptions,
) {
  const requestHeaderOptions = {
    pathParams: {
      talentId,
    },
    ...options,
  };
  const response =
    await ApiServiceHandler.service.roles.talentRoles.get<Role[]>(
      requestHeaderOptions,
    );
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function favoritedRole(body: TalentRoleUpdateRequestBody) {
  const response = await ApiServiceHandler.service.roles.talentRoles.post<
    boolean,
    TalentRoleUpdateRequestBody
  >({
    body,
    pathParams: {
      talentId: body.talentID,
    },
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getTalentRoleCount(talentId: string) {
  const response =
    await ApiServiceHandler.service.roles.talentRolesCount.get<number>({
      pathParams: {
        talentId,
      },
    });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getTalentConntectionStoreLocations(connectionId: string) {
  const response =
    await ApiServiceHandler.service.talent.connectionStoreLocations.get<
      TalentConnectionStoreLocation[]
    >({
      pathParams: {
        connectionId,
      },
    });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function deleteTalentRole(talentId: string, positionId?: string) {
  const options: RequestOptions = {
    pathParams: {
      talentId,
    },
  };
  if (positionId) {
    options.queryParams = { positionID: positionId };
  }
  const response =
    await ApiServiceHandler.service.roles.talentRoles.delete(options);
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function postLocations(
  jobPositionID: string,
  postLocationIds: Array<string>,
) {
  const response = await ApiServiceHandler.service.refData.postLocations.post<
    Array<MappedPostLocation>,
    { jobPositionID: string; idList: Array<string> }
  >({
    body: {
      jobPositionID,
      idList: postLocationIds,
    },
  });
  if (response.success) {
    return response.data;
  } else {
    return response.error;
  }
}

export function uploadFilePromise(
  talentId: string,
  parseRequired: boolean,
  categoryId: string,
  formData: FormData,
  referrerDSID?: string,
  sourceId?: SourceID,
) {
  return () => {
    return ApiServiceHandler.service.talent.uploadFile.post<
      TalentFileMetaData,
      FormData
    >({
      pathParams: { talentId, parseRequired: parseRequired ? "y" : "n" },
      queryParams: {
        categoryId,
        ...(referrerDSID && { referrerDSID }),
        sourceId: sourceId ?? CREATE_SOURCE_JS,
      },
      body: formData,
      headers: {
        //setting this to null as we don't want to send any Content-Type header and want the browser to handle it.
        "Content-Type": null,
      },
    });
  };
}

export function uploadSupportingFilePromises(
  talentId: string,
  files: Array<TalentSupportingFile>,
  parseRequired: boolean,
  referrerDSID?: string,
  sourceId?: SourceID,
) {
  const uploadFilePromises: Array<
    () => Promise<ApiResponse<TalentFileMetaData>>
  > = [];
  for (let i = 0; i < files.length; i++) {
    if (
      files[i] &&
      files[i].fileData &&
      !files[i].fileData.fileId &&
      files[i].fileData.content
    )
      uploadFilePromises.push(
        uploadFilePromise(
          talentId,
          parseRequired,
          files[i].category,
          files[i].fileData.content,
          referrerDSID,
          sourceId,
        ),
      );
  }
  return uploadFilePromises;
}

export function uploadResume(
  talentId: string,
  file: FileData,
  parseRequired: boolean,
  referrerDSID?: string,
  sourceId?: SourceID,
) {
  return uploadFilePromise(
    talentId,
    parseRequired,
    PRIMARY_TYPE,
    file.content,
    referrerDSID,
    sourceId,
  );
}

export function uploadTextResumePromise(
  talentId: string,
  content: string,
  parseRequired: boolean,
  sourceId?: SourceID,
) {
  return () => {
    return ApiServiceHandler.service.talent.uploadTextResume.post<
      TalentFileMetaData,
      unknown
    >({
      pathParams: { talentId, parseRequired: parseRequired ? "y" : "n" },
      body: {
        input: content,
        categoryId: PRIMARY_TYPE,
        sourceId: sourceId ?? CREATE_SOURCE_JS,
      },
    });
  };
}

export async function uploadTextResume(
  talentId: string,
  content: string,
  parseRequired: boolean,
) {
  const response = await uploadTextResumePromise(
    talentId,
    content,
    parseRequired,
  )();
  if (response.success) {
    return response.data;
  } else {
    return response.error;
  }
}

export function parsedResumeSnapshotPromise(talentId: string, action: string) {
  return () => {
    return ApiServiceHandler.service.talent.parsedResumeSnapshot.put({
      pathParams: { talentId },
      queryParams: { action },
    });
  };
}

export async function parsedResumeSnapshot(talentId: string, action: string) {
  const response = await parsedResumeSnapshotPromise(talentId, action)();
  if (response.success) {
    return response.data;
  } else {
    return response.error;
  }
}

export function parsedResumeSnapshotFailPromise(talentId: string) {
  return () => {
    return ApiServiceHandler.service.talent.parsedResumeSnapshotFail.put({
      pathParams: { talentId },
    });
  };
}

export async function parsedResumeSnapshotFail(talentId: string) {
  const response = await parsedResumeSnapshotFailPromise(talentId)();
  if (response.success) {
    return response.data;
  } else {
    return response.error;
  }
}

export function parsedlinkedinSnapshotPromise(
  talentId: string,
  action: string,
) {
  return () => {
    return ApiServiceHandler.service.talent.parsedLinkedinSnapshot.put({
      pathParams: { talentId },
      queryParams: { action },
    });
  };
}

export async function parsedLinkedInSnapshot(talentId: string, action: string) {
  const response = await parsedlinkedinSnapshotPromise(talentId, action)();
  if (response.success) {
    return response.data;
  } else {
    return response.error;
  }
}

export async function getSavedSearches(talentId: string) {
  const response = await ApiServiceHandler.service.talent.savedSearches.get<
    SavedSearchType[]
  >({
    pathParams: { talentId },
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export function getTranslationPromise(options: RequestOptions) {
  return () => {
    return ApiServiceHandler.service.config.translations.get(options);
  };
}

export async function getTranslations(options: RequestOptions) {
  const response = await getTranslationPromise(options)();
  if (response.success) {
    return response.data;
  } else {
    return response.error;
  }
}

interface SearchBody {
  query?: string;
  filters?: SearchFilters;
  page?: number;
  locale?: string;
  sort?: string;
  format?: object;
}

export async function getRefDataById(
  locale: string,
  searchFilters: SearchFilters,
) {
  const response = await ApiServiceHandler.service.search.getRefDataById.post<
    any,
    any
  >({
    body: {
      filters: searchFilters,
    },
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function search(
  locale: string,
  filters?: SearchFilters,
  page?: number,
  sort?: string,
  query?: string,
) {
  const body: SearchBody = {
    query: query ? query : "",
    filters: {
      ...(filters?.keywords && { keywords: filters.keywords }),
      ...(filters?.homeOffice && { homeOffice: filters.homeOffice }),
      ...(filters?.minimumHours && {
        minimumHours: filters.minimumHours.toString(),
      }),
      ...(filters?.maximumHours && {
        maximumHours: filters.maximumHours.toString(),
      }),
      ...(filters?.jobLevel && { jobLevel: filters.jobLevel }),
      ...(filters?.languages && {
        languages: filters.languages as unknown as string[] | undefined,
      }),
      ...(filters?.locations && { locations: filters.locations }),
      ...(filters?.products && {
        products: filters.products as unknown as string[] | undefined,
      }),
      ...(filters?.retailRoles && {
        retailRoles: filters.retailRoles as unknown as string[] | undefined,
      }),
      ...(filters?.teams && {
        teams: filters.teams as TeamsFilter[] | undefined,
      }),
      ...(filters?.hiringManagers && {
        hiringManagers: filters.hiringManagers as unknown as
          | string[]
          | undefined,
      }),
    },
    page: page,
    locale: locale,
    sort: sort,
    format: {
      longDate: "MMMM D, YYYY",
      mediumDate: "MMM D, YYYY",
    },
  };
  const response = await ApiServiceHandler.service.search.search.post<any, any>(
    {
      body: body,
    },
  );
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function deleteSavedSearch(
  talentId: string,
  savedSearchId: string,
) {
  const response =
    await ApiServiceHandler.service.talent.updateOrDeleteSavedSearch.delete({
      pathParams: { talentId, savedSearchId },
    });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function createSavedSearch(
  talentId: string,
  params: SavedSearchType,
) {
  const response = await ApiServiceHandler.service.talent.savedSearches.post({
    pathParams: { talentId },
    body: params,
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getTeamsOfInterest() {
  const response =
    await ApiServiceHandler.service.refData.teamsOfInterest.get<
      Array<TeamsRefData>
    >();
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getHiringManagerRefData(input: string) {
  const response = await ApiServiceHandler.service.refData.hiringManager.get<
    Array<HiringManagerDetails>
  >({
    queryParams: { q: input },
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function updateSavedSearch(
  talentId: string,
  savedSearchId: string,
  payload: any,
) {
  const response =
    await ApiServiceHandler.service.talent.updateOrDeleteSavedSearch.put({
      pathParams: { talentId, savedSearchId },
      body: payload,
    });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getERPLanguageRefData() {
  const response =
    await ApiServiceHandler.service.refData.erpLanguage.get<BaseRefData[]>();
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getJobAgentEmailFrequency() {
  const response =
    await ApiServiceHandler.service.refData.jobAgentEmailFrequency.get<
      OrderedRefData[]
    >();
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getReferralTypeRefData() {
  const response =
    await ApiServiceHandler.service.refData.referralType.get<BaseRefData[]>();
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getERPAppleStoreRoles() {
  const response =
    await ApiServiceHandler.service.erp.appleStoreRoles.get<
      AppleStoresRefData[]
    >();
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getJobAgentEmailExperience() {
  const response =
    await ApiServiceHandler.service.refData.jobAgentEmailExperience.get<
      OrderedRefData[]
    >();
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getERPTeamsOfInterest() {
  const response =
    await ApiServiceHandler.service.erp.teamsOfInterest.get<TeamsRefData[]>();
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getERPPostlocations(
  countryId: string | null,
  countryCode: string | null,
  state: string | null,
  city: string | null,
) {
  const queryParams: ERP_POSTLOCATIONS_QUERY_PARAMS = {};
  if (countryId) queryParams.countryId = countryId;
  if (countryCode) queryParams.countryCode = countryCode;
  if (state) queryParams.state = state;
  if (city) queryParams.city = city;
  const response = await ApiServiceHandler.service.erp.postlocation.get<
    MappedERPPostLocation[]
  >({ queryParams: { ...queryParams } });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getCountrySpecificSelfDisclosureConfig(
  countryID: string,
) {
  const response =
    await ApiServiceHandler.service.refData.countrySpecificSelfDisclosureConfig.get<CountrySpecificSelfDisclosureConfig>(
      {
        pathParams: { countryID },
      },
    );
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getMyApplicaton(talentId: string, applicationId: string) {
  const response =
    await ApiServiceHandler.service.talent.createMyApplicaton.get<MyApplication>(
      {
        pathParams: { talentId, applicationId },
      },
    );
  return handleResponse<MyApplication>(response);
}

export async function updateMyApplicaton(
  talentId: string,
  applicationId: string,
  action: keyof typeof AllowedMyApplicationUpdateAction,
  payload: Partial<MyApplication>,
) {
  const response =
    await ApiServiceHandler.service.talent.updateMyApplication.put<
      MyApplication,
      Partial<MyApplication>
    >({
      pathParams: { talentId, applicationId, action },
      body: payload,
    });
  return handleResponse<MyApplication>(response);
}

export async function createMyApplicaton(
  talentId: string,
  createApplication: Partial<MyApplication>,
) {
  const response =
    await ApiServiceHandler.service.talent.createMyApplicaton.post<
      MyApplication,
      Partial<MyApplication>
    >({
      pathParams: { talentId },
      body: createApplication,
    });
  return handleResponse<MyApplication>(response);
}

function handleResponse<T>(response: ApiResponse<T>) {
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getQuestionnaireAns(
  talentId: string,
  connectionId: string,
  locale: string,
) {
  const response =
    await ApiServiceHandler.service.talent.questionnaireAns.get<TalentQuestionnaireAnswer>(
      {
        pathParams: {
          talentId,
          connectionId,
        },
        queryParams: {
          locale: locale,
        },
      },
    );
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getEditQuestionnaireWithAnswer(
  talentId: string,
  connectionId: string,
  locale: string,
  questionnaireId: string,
) {
  const response =
    await ApiServiceHandler.service.talent.questionnaireWithAnswer.get<TalentQuestionnaire>(
      {
        pathParams: {
          talentId,
          connectionId,
          questionnaireId,
        },
        queryParams: {
          locale: locale,
        },
      },
    );
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function saveQuestionnaire(
  talentId: string,
  connectionId: string,
  locale: string,
  payload: any,
) {
  const response = await ApiServiceHandler.service.talent.questionnaireAns.post(
    {
      pathParams: {
        talentId,
        connectionId,
      },
      queryParams: {
        locale: locale,
      },
      body: payload,
    },
  );
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function createReferral(payload: EmployeeReferral) {
  const response = await ApiServiceHandler.service.erp.createReferral.post<
    unknown,
    EmployeeReferral
  >({ body: payload });
  return handleResponse<unknown>(response);
}

export async function getLanguagesByInput(input: string) {
  const response = await ApiServiceHandler.service.refData.languagesByInput.get<
    MappedLanguages[]
  >({
    queryParams: { input },
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getTeamsByInput(input: string) {
  const response = await ApiServiceHandler.service.refData.teamsByInput.get<
    MappedTeams[]
  >({
    queryParams: { input },
  });
  if (response.success) {
    const inputExpression = new RegExp(regExpEscapeForTeamsFilter(input), "ig");
    return response.data?.filter((team) => {
      if (!team.displayName) {
        return undefined;
      }
      return team.displayName.match(inputExpression);
    });
  } else {
    throw new Error(response.error);
  }
}

export async function getSearchTypeahead(searchTerm: string) {
  const response = await ApiServiceHandler.service.search.quickFind.get<
    string[]
  >({
    pathParams: { searchTerm },
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getRefDataForSearch() {
  const response =
    await ApiServiceHandler.service.refData.refDataForSearch.get<RolesAndProductsRefDataForSearch>();
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function getCountrySpecificPrivacyPolicy(
  talentId: string,
  countryID: string,
) {
  const response =
    await ApiServiceHandler.service.talent.countrySpecificPrivacyPolicy.get<{
      privacyPolicyAccepted: boolean;
    }>({ pathParams: { talentId }, queryParams: { countryID } });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

export async function externalProfileLinked(
  body: ExternalProfileLinkedRequest,
) {
  const response =
    await ApiServiceHandler.service.talent.externalProfileLinked.post<
      unknown,
      ExternalProfileLinkedRequest
    >({ body });
  return handleResponse<unknown>(response);
}

export async function expandResults(
  locationFilter: Array<string> | undefined,
  countryCode: CountryCode | null,
) {
  const headers = buildHeaders(countryCode);
  const response = await ApiServiceHandler.service.search.expandResults.post<
    MappedPostLocation[],
    Array<string>
  >({
    body: locationFilter,
    headers: {
      ...headers,
    },
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}
