import { getYesOrNoValue } from "../../../util";
import { RolesAction, RolesState } from "./RolesContext";
import {
  filterRolesOnSelection,
  getRoleUiObj,
  hasUnAvailableRoles,
} from "./roleUtils";

export function getRolesInitialState(talentId: string): RolesState {
  return {
    talentId,
    roles: [],
    filteredRoles: [],
    locationModalRole: null,
    questionaireModalRole: null,
    selected: "All",
    showLocationModal: false,
    showQuestionaireModal: false,
    showRemoveAllModal: false,
    showRemoveAllUnavailableLink: false,
    roleToReset: "",
    showFilterModal: false,
    mobileFilter: "All",
  };
}

export const reducer = (state: RolesState, action: RolesAction): RolesState => {
  switch (action.type) {
    case "SET_ROLES": {
      const { payload } = action;
      const { selected } = state;
      const transformed = (payload || []).map((r) => getRoleUiObj(r, selected));
      return {
        ...state,
        roles: transformed,
        filteredRoles: filterRolesOnSelection(transformed, selected),
        showRemoveAllUnavailableLink: hasUnAvailableRoles(transformed),
      };
    }
    case "SET_SELECTED_FILTER": {
      const { payload: actionPayload } = action;
      const transformedRoles = (state.roles || []).map((transformedRole) =>
        getRoleUiObj(transformedRole, actionPayload),
      );
      const filteredRoles =
        actionPayload === "All"
          ? [...transformedRoles]
          : filterRolesOnSelection([...transformedRoles], actionPayload);
      return {
        ...state,
        selected: actionPayload,
        filteredRoles: [...filteredRoles],
      };
    }
    case "SHOW_QUESTIONAIRE_MODAL": {
      const { payload } = action;
      return {
        ...state,
        showLocationModal: false,
        locationModalRole: null,
        showQuestionaireModal: true,
        questionaireModalRole: payload,
      };
    }
    case "CLOSE_QUESTIONAIRE_MODAL":
    case "CLOSE_LOCATION_MODAL": {
      return {
        ...state,
        showLocationModal: false,
        locationModalRole: null,
        showQuestionaireModal: false,
        questionaireModalRole: null,
      };
    }
    case "SHOW_LOCATION_MODAL": {
      const { payload } = action;
      return {
        ...state,
        showLocationModal: true,
        locationModalRole: payload,
      };
    }
    case "REMOVE_INACTIVE_ROLE": {
      const { payload } = action;
      const { roles: oldRoles, filteredRoles: oldFilteredRoles } = state;
      const updatedRoles = oldRoles.filter(
        (o) => o.positionID !== payload.positionID,
      );
      return {
        ...state,
        roles: updatedRoles,
        filteredRoles: oldFilteredRoles.filter(
          (o) => o.positionID !== payload.positionID,
        ),
        showRemoveAllUnavailableLink: hasUnAvailableRoles(updatedRoles),
      };
    }
    case "REMOVE_ALL_INACTIVE_ROLES": {
      const { roles: oldRoles, filteredRoles: oldFilteredRoles } = state;
      const updatedRoles = oldRoles.filter((o) => !o.isStatusClosed);
      return {
        ...state,
        roles: updatedRoles,
        filteredRoles: oldFilteredRoles.filter((o) => !o.isStatusClosed),
        showRemoveAllModal: false,
        showRemoveAllUnavailableLink: false,
      };
    }
    case "SET_SHOW_REMOVE_ALL_MODAL":
      return {
        ...state,
        showRemoveAllModal: action.payload,
      };
    case "UPDATE_FAVORITE_ROLE": {
      const transformedRoles = [...state.roles];
      const roleIndex = transformedRoles.findIndex(
        (e) => e.positionID === action.payload.roleItem.positionID,
      );
      if (!action.payload.favorited && !action.payload.roleItem.isSubmitted) {
        transformedRoles.splice(roleIndex, 1);
      } else {
        transformedRoles.splice(roleIndex, 1, {
          ...action.payload.roleItem,
          isFavorite: action.payload.favorited,
          favorited: getYesOrNoValue(action.payload.favorited) as string,
        });
      }
      return {
        ...state,
        roles: transformedRoles,
        filteredRoles: filterRolesOnSelection(transformedRoles, state.selected),
      };
    }
    case "UPDATE_ROLE_TO_RESET": {
      return {
        ...state,
        roleToReset: action.payload,
      };
    }
    case "UPDATE_RESET": {
      const rolesCopy = [...state.roles];
      const roleIndexToUpdate = state.roles.findIndex(
        (e) => e.connectionID === state.roleToReset,
      );
      rolesCopy[roleIndexToUpdate].reset = false;
      return {
        ...state,
        roles: rolesCopy,
        filteredRoles: filterRolesOnSelection(rolesCopy, state.selected),
        roleToReset: "",
      };
    }
    case "SET_SHOW_FILTER_MODAL":
      return {
        ...state,
        showFilterModal: action.payload,
      };
    case "UPDATE_MOBILE_FILTER":
      return {
        ...state,
        mobileFilter: action.payload,
      };
    default:
      return state;
  }
};
