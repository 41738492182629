import { MultiLinDropdownOptionProps } from "@rpe-js/marcom-web-components";
import { useCallback } from "react";
import {
  DISABILITY,
  ETHNICITY,
  GENDER,
  VETERAN_STATUS,
} from "../../../app.constants";
import {
  CountrySpecificSelfDisclosureConfig,
  Disability,
  SelfDisclosure,
  VeteranStatus,
} from "../../../../shared/types/talent/talent";
import { isEmpty, isNull, isUndefined } from "lodash";
import { RefData } from "@rpe-js/core/src/types/refData";
import { SelfDisclosures } from "../../../../shared/types";

export function useSelfDisclosure() {
  // Handle key presses for keyboard navigation and Enter to submit custom input or select suggestion
  const onChange = useCallback(
    (
      field: {
        type: string;
        option: MultiLinDropdownOptionProps | Disability;
      },
      selfDisclosureState: SelfDisclosure,
      updateSelfDisclosureState: (selfDisclosureState: SelfDisclosure) => void,
    ) => {
      const { type, option } = field;
      if (type === GENDER) {
        const opt = option as MultiLinDropdownOptionProps;
        updateSelfDisclosureState({
          ...selfDisclosureState,
          genderID: opt.id,
          genderName: opt.value,
        });
      }
      if (type === ETHNICITY) {
        const opt = option as MultiLinDropdownOptionProps;
        updateSelfDisclosureState({
          ...selfDisclosureState,
          ethnicityID: opt.id,
          ethnicityName: opt.value,
        });
      }

      if (type === DISABILITY) {
        const opt = option as Disability;
        updateSelfDisclosureState({
          ...selfDisclosureState,
          disability: { ...opt },
        });
      }

      if (type === VETERAN_STATUS) {
        const opt = option as MultiLinDropdownOptionProps;
        updateSelfDisclosureState({
          ...selfDisclosureState,
          veteranStatus: {
            veteranStatusID: opt.id,
            veteranStatusName: opt.value,
          },
        });
      }
    },
    [],
  );

  function isNotNullAndNotEmpty(val: string | null | undefined): boolean {
    return !isUndefined(val) && !isNull(val) && !isEmpty(val);
  }

  const validateSelfDisclosureData = useCallback(
    (
      selfDisclosureConfig: CountrySpecificSelfDisclosureConfig | undefined,
      selfDisclosureState: SelfDisclosure,
    ): boolean => {
      let configHasError = false;
      if (selfDisclosureConfig?.gender) {
        configHasError =
          configHasError ||
          !(
            isNotNullAndNotEmpty(selfDisclosureState.genderID) &&
            isNotNullAndNotEmpty(selfDisclosureState.genderName)
          );
      }
      if (selfDisclosureConfig?.ethnicity) {
        configHasError =
          configHasError ||
          !(
            isNotNullAndNotEmpty(selfDisclosureState.ethnicityID) &&
            isNotNullAndNotEmpty(selfDisclosureState.ethnicityName)
          );
      }

      if (selfDisclosureConfig?.veteranStatus) {
        configHasError =
          configHasError ||
          !(
            isNotNullAndNotEmpty(
              selfDisclosureState.veteranStatus?.veteranStatusID,
            ) &&
            isNotNullAndNotEmpty(
              selfDisclosureState.veteranStatus?.veteranStatusName,
            )
          );
      }
      if (selfDisclosureConfig?.disability) {
        configHasError =
          configHasError || isEmpty(selfDisclosureState?.disability);
      }
      return configHasError;
    },
    [],
  );

  /**
   * This callback function is for setting the state with localised dropdown option on self disclosure
   */
  const getSelectedValueBasedOnLocale = useCallback(
    <T extends MultiLinDropdownOptionProps | RefData>(
      refDataOptions: T[],
      idToMatch?: string,
    ): T | null => {
      if (refDataOptions && refDataOptions.length > 1 && idToMatch) {
        const localisedValue = refDataOptions.find(
          (refDataOption) => refDataOption.id === idToMatch,
        );
        if (localisedValue) {
          return { ...localisedValue };
        }
      }
      return null;
    },
    [],
  );

  /**
   * This callback function is for getting the localized self disclosure of talent based on refdata provided as per locale/country
   */
  const getLocalizedSelfDisclosure = useCallback(
    (
      selfDisclosure: SelfDisclosure,
      selfDisclosuresRefData: SelfDisclosures | undefined,
    ): SelfDisclosure => {
      if (selfDisclosure && selfDisclosuresRefData) {
        const localizedSelfDisclosure: SelfDisclosure = {
          ...selfDisclosure,
          genderName: getSelectedValueBasedOnLocale<RefData>(
            selfDisclosuresRefData?.gender,
            selfDisclosure?.genderID,
          )?.name,
          veteranStatus: {
            ...selfDisclosure?.veteranStatus,
            veteranStatusName: getSelectedValueBasedOnLocale<RefData>(
              selfDisclosuresRefData?.protectedVeteranStatus,
              selfDisclosure?.veteranStatus?.veteranStatusID,
            )?.name,
          } as VeteranStatus,
          ethnicityName: getSelectedValueBasedOnLocale<RefData>(
            selfDisclosuresRefData?.atpEthnicClass,
            selfDisclosure?.ethnicityID,
          )?.name,
          nationalityName: getSelectedValueBasedOnLocale<RefData>(
            selfDisclosuresRefData?.atpNationality,
            selfDisclosure?.nationalityID,
          )?.name,
        };
        return localizedSelfDisclosure;
      }
      return selfDisclosure;
    },
    [getSelectedValueBasedOnLocale],
  );

  return {
    onChange,
    validateSelfDisclosureData,
    getSelectedValueBasedOnLocale,
    getLocalizedSelfDisclosure,
  };
}
