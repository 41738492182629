import React, { useCallback } from "react";
import { MappedPostLocation } from "../../../shared/types/refData";
import {
  FILTER_TYPE,
  SET_FILTER,
  UNSET_FILTER,
} from "../../contexts/actionTypes";
import { FilterValue, SEARCH_FILTER_TYPE } from "../../contexts/SearchContext";
import useIsMobile from "../../hooks/useIsMobile";
import { useSearchContext } from "../../hooks/useSearchContext";
import { FilterOption } from "./FilterCheckboxGroup";
import LocationFilter from "./LocationFilter";

interface LocationFilterWrapperProps {
  locationsFilterData: Array<MappedPostLocation>;
  homeOfficeFilterData: boolean;
  onMobileFilterChange?: (
    filterType: SEARCH_FILTER_TYPE,
    filterVlaue: FilterValue,
  ) => void;
}

export type MappedPostLocationFilterOptions = FilterOption<MappedPostLocation>;

export function LocationFilterWrapper({
  locationsFilterData,
  homeOfficeFilterData,
  onMobileFilterChange,
}: LocationFilterWrapperProps) {
  const { dispatch } = useSearchContext();
  const isMobile = useIsMobile();
  const modifiedLocationsData = locationsFilterData.map((item) => {
    return { ...item, selected: true } as MappedPostLocationFilterOptions;
  });
  const selectedCount =
    (modifiedLocationsData?.length || 0) + (homeOfficeFilterData ? +1 : 0);

  const updateMobileFilters = useCallback(
    (filterType: SEARCH_FILTER_TYPE, filterValue: FilterValue) => {
      if (onMobileFilterChange) onMobileFilterChange(filterType, filterValue);
    },
    [onMobileFilterChange],
  );

  // dispatch a filter action in case of desktop / invoke mobileFilterChange callback incase of mobile
  const dispatchHomeOffice = useCallback(
    (isChecked: boolean) => {
      if (!isChecked) {
        if (!isMobile) {
          dispatch({
            type: UNSET_FILTER,
            filterName: FILTER_TYPE.HOME_OFFICE,
          });
        } else {
          updateMobileFilters(
            FILTER_TYPE.HOME_OFFICE as SEARCH_FILTER_TYPE,
            isChecked,
          );
        }
      } else {
        if (!isMobile) {
          dispatch({
            type: SET_FILTER,
            filterName: FILTER_TYPE.HOME_OFFICE,
            payload: true,
          });
        } else {
          updateMobileFilters(
            FILTER_TYPE.HOME_OFFICE as SEARCH_FILTER_TYPE,
            isChecked,
          );
        }
      }
    },
    [dispatch, isMobile, updateMobileFilters],
  );

  // dispatch a filter action in case of desktop / invoke mobileFilterChange callback incase of mobile
  const onUpdateLocation = useCallback(
    (locations: Array<FilterOption>) => {
      if (!isMobile) {
        dispatch({
          type: SET_FILTER,
          filterName: FILTER_TYPE.LOCATIONS,
          payload: locations,
        });
      } else {
        updateMobileFilters(
          FILTER_TYPE.LOCATIONS as SEARCH_FILTER_TYPE,
          locations,
        );
      }
    },
    [dispatch, isMobile, updateMobileFilters],
  );

  // dispatch a filter action in case of desktop / invoke mobileFilterChange callback incase of mobile
  const onRemoveLocation = useCallback(() => {
    if (!isMobile) {
      dispatch({
        type: UNSET_FILTER,
        filterName: FILTER_TYPE.LOCATIONS,
      });
    } else {
      updateMobileFilters(FILTER_TYPE.LOCATIONS as SEARCH_FILTER_TYPE, []);
    }
  }, [dispatch, isMobile, updateMobileFilters]);

  return (
    <LocationFilter
      locationData={modifiedLocationsData} // need to come from state
      homeOfficeData={homeOfficeFilterData} // need to come from state
      onHomeOfficeChange={dispatchHomeOffice}
      onRemoveLocation={onRemoveLocation}
      onUpdateLocation={onUpdateLocation}
      selectedCount={selectedCount}
    ></LocationFilter>
  );
}
