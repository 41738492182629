import {
  DropDownOptionProps,
  ProgressIndicatorLoader,
} from "@rpe-js/marcom-web-components";
import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { EmploymentDetails } from "../../../../shared/types/talent/talent";
import { updateTalent } from "../../../api/fetchClient";
import { APP_TYPE, PROFILE_SECTIONS } from "../../../app.constants";
import AppContext from "../../../AppContext";
import { SaveCancelAction } from "../../../components/feature/saveAndCancel";
import { useAppAlertContext } from "../../../contexts/AppAlert";
import { useFetchData } from "../../../hooks/useFetchData";
import useFocusFirstErrorField from "../../../hooks/useFocusFirstErrorField";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { getPastNYear } from "../../../util";
import { handleScrollToElement } from "../../../utils/focusUtil";
import { idGenerator } from "../../../utils/idGenerator";
import { EmployeeSummaryView } from "../components/employeeSummary/EmployeeSummaryView";
import { updateAtLeastOneJob, updateEmployments } from "../context/Actions";
import { useProfileContext } from "../context/ProfileContext";
import { EditEmployeeSummary } from "./editEmployeeSummary";
import {
  EmploymentForm,
  getEmploymentForm,
  getEmploymentsPayload,
} from "./employmentFormUtils";
import { ProfileSection } from "./profileSection";

type EmployeeSummaryProps = {
  monthsData: DropDownOptionProps[];
};

export const EmployeeSummary = ({ monthsData }: EmployeeSummaryProps) => {
  const [isEdit, setEdit] = useState<boolean>(false);
  const [displayErrors, setDisplayErrors] = useState<boolean>(false);
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { appType, isExternal } = appUIState.appData;
  const { state, dispatch } = useProfileContext();
  const [employmentsForm, setEmploymentsForm] = useState<EmploymentForm[] | []>(
    [],
  );
  const yearData = getPastNYear(t("jobsite.common.year") as string);
  const employmentsIdGenerator = idGenerator("profile", "employments");
  const employmentsSectionId = employmentsIdGenerator.generateId();
  const { updateAlert, deleteAlert } = useAppAlertContext();
  const { setFocusFirstErrorField } =
    useFocusFirstErrorField(employmentsSectionId);
  const {
    isLoading: isSaveTalentLoading,
    isSuccess: isSaveTalentSuccess,
    fetchData: saveTalent,
    isError: isSaveTalentError,
  } = useFetchData(updateTalent);

  const {
    isLoading: isSaveParentFieldLoading,
    isSuccess: isSaveParentFieldSuccess,
    fetchData: saveParentField,
    isError: isSaveParentFieldError,
  } = useFetchData(updateTalent);

  const getEmployerHistory = () => {
    let empHistory;
    if (state?.employments?.length) {
      empHistory = "Yes";
    } else {
      if (!state?.employments?.length) {
        empHistory = null;
      }
      if (isExternal && state.employmentHasAtLeastOneJob === false) {
        empHistory = "No";
      }
    }
    return empHistory;
  };

  const [employmentHistory, setEmploymentHistory] =
    useState(getEmployerHistory());

  const onCancel = () => {
    setDisplayErrors(false);
    setEdit(false);
  };

  const onEdit = () => {
    setEmploymentHistory(getEmployerHistory());
    const employments = state?.employments;
    const employmentFormCopy = [];
    if (employments?.length) {
      employments?.forEach((employment: EmploymentDetails) => {
        employmentFormCopy.push(getEmploymentForm(employment));
      });
    } else {
      employmentFormCopy.push(getEmploymentForm());
    }
    setEmploymentsForm(employmentFormCopy);
    setEdit(true);
  };

  const onSave = () => {
    deleteAlert();
    setDisplayErrors(true);
    if (employmentHistory === "No") {
      setEmploymentsForm([]);
      saveTalent([
        state?.talentId,
        PROFILE_SECTIONS.EMPLOYMENTS,
        {
          employments: [],
        },
      ]);
      return;
    }
    const emplomentsFormCopy = cloneDeep(employmentsForm);
    const validationKeys = [
      "employerName",
      "startMonthID",
      "startYear",
      "jobTitle",
      "currentEmployer",
    ];
    let isError = false;
    emplomentsFormCopy.forEach((employment: EmploymentForm) => {
      if (
        appType === APP_TYPE.INTERNAL &&
        employment.currentEmployer.value === "Yes" &&
        employment.empAdd !== true &&
        !employment.addedSource
      ) {
        return;
      }
      Object.keys(employment).forEach((key) => {
        if (validationKeys.includes(key)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          if (!employment[key].value) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            employment[key].requiredError = true;
            isError = true;
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            employment[key].requiredError = false;
          }
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        if (employment[key]?.invalidErrorMessage) {
          isError = true;
        }
      });
      if (employment.currentEmployer.value === "No") {
        if (!employment.endMonthID.value) {
          employment.endMonthID.requiredError = true;
          isError = true;
        } else {
          employment.endMonthID.requiredError = false;
        }
        if (!employment.endYear.value) {
          employment.endYear.requiredError = true;
          isError = true;
        } else {
          employment.endMonthID.requiredError = false;
        }
      }
    });
    setEmploymentsForm([...emplomentsFormCopy]);
    if (isError) {
      setFocusFirstErrorField(true);
      return;
    }
    saveTalent([
      state?.talentId,
      PROFILE_SECTIONS.EMPLOYMENTS,
      {
        employments: getEmploymentsPayload(employmentsForm),
      },
    ]);
  };

  useEffect(() => {
    if (!isSaveTalentLoading && isSaveTalentSuccess) {
      dispatch(updateEmployments(getEmploymentsPayload(employmentsForm, true)));
      if (employmentHistory === "No") {
        saveParentField([
          state?.talentId,
          PROFILE_SECTIONS.PARENT_FIELDS,
          {
            employmentHasAtLeastOneJob: false,
          },
        ]);
      } else {
        setEdit(false);
        handleScrollToElement(employmentsSectionId);
      }
    }
    if (!isSaveTalentLoading && isSaveTalentError) {
      updateAlert(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveTalentLoading, isSaveTalentSuccess, isSaveTalentError]);

  useEffect(() => {
    if (!isSaveParentFieldLoading && isSaveParentFieldSuccess) {
      dispatch(updateAtLeastOneJob(false));
      setEdit(false);
      handleScrollToElement(employmentsSectionId);
    }
    if (!isSaveParentFieldLoading && isSaveParentFieldError) {
      updateAlert(false);
    }
  }, [
    dispatch,
    employmentsSectionId,
    isSaveParentFieldError,
    isSaveParentFieldLoading,
    isSaveParentFieldSuccess,
    t,
    updateAlert,
  ]);

  return (
    <ProfileSection
      isEdit={isEdit}
      i18nTitle="jobsite.common.empSummaryTitle"
      onEdit={onEdit}
      id={employmentsSectionId}
      sectionName="employments"
    >
      {(isSaveTalentLoading || isSaveParentFieldLoading) && (
        <ProgressIndicatorLoader showLoading={true} curtain={true} />
      )}
      {isEdit && (
        <>
          <EditEmployeeSummary
            employmentsForm={employmentsForm}
            setEmploymentsForm={setEmploymentsForm}
            monthOptions={monthsData}
            yearOptions={yearData}
            employmentHistory={employmentHistory}
            setEmploymentHistory={setEmploymentHistory}
            displayErrors={displayErrors}
          />
          <SaveCancelAction
            onCancel={onCancel}
            onSave={onSave}
            cancelButtonId={employmentsIdGenerator.generateId("cancelButton")}
            saveButtonId={employmentsIdGenerator.generateId("saveButton")}
            scrollToTopSectionId={employmentsSectionId}
          />
        </>
      )}

      {!isEdit && (
        <EmployeeSummaryView
          employments={state?.employments as EmploymentDetails[]}
          employmentHasAtLeastOneJob={
            state.employmentHasAtLeastOneJob as boolean
          }
          monthOptions={monthsData}
        />
      )}
    </ProfileSection>
  );
};
