"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isNotNullOrUndefinedOrEmpty = isNotNullOrUndefinedOrEmpty;
exports.isNullOrUndefinedOrEmpty = isNullOrUndefinedOrEmpty;
function isNullOrUndefinedOrEmpty(input) {
  return !input || input === null || input === undefined || input.length === 0;
}
function isNotNullOrUndefinedOrEmpty(input) {
  return input !== null && input !== undefined && input.length > 0;
}