import { ModifiedSearchResult } from "@rpe-js/core/dist/types";
import { getLocaleDate } from "@rpe-js/core/dist/util/dateUtil";
import {
  InfoTooltip,
  TooltipConfiguration,
} from "@rpe-js/marcom-web-components";
import React, { useContext } from "react";
import { useLocation } from "react-router";
import AppContext from "../../../AppContext";
import Icon from "../../../components/base/IconComponent";
import FavoriteComponent from "../../../components/feature/FavoriteComponent";
import LocaleAwareLink from "../../../components/feature/LocaleAwareLink";
import RoleSubmitButton from "../../../components/feature/RoleSubmitButton";
import ShareJobComponent from "../../../components/feature/shareJob/ShareJobComponent";
import useIdGenerator from "../../../hooks/useIdGenerator";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { useSearchContext } from "../../../hooks/useSearchContext";
import RoleLocation from "./RoleLocation";

interface JobTitleProps {
  id: string;
  job: ModifiedSearchResult;
}

export default function JobTitle({ id, job }: JobTitleProps) {
  const { t } = useIntlMessage();
  const searchLocation = useLocation();
  const { appUIState } = useContext(AppContext);
  const { isInternal } = appUIState.appData;
  const isMobile = useIsMobile();
  const jobDetailsNumberId = job?.positionId || job?.jobNumber;
  const url = `details/${jobDetailsNumberId}/${job.transformedPostingTitle}${job.team && job.team.teamCode ? `?team=${job.team.teamCode}` : ""}`;
  const jobPostedDateId = useIdGenerator("search", "job-posted-date", true);
  const submitRoleId = useIdGenerator("search", `${id}-submit-role`, true);
  const appliedRoleId = useIdGenerator("search", `${id}-applied-role`, true);
  const appliedRoleIconId = useIdGenerator(
    "search",
    `${id}-applied-role-icon`,
    true,
  );
  const { dispatch } = useSearchContext();

  function handleFavoriteChange(isFavorited: boolean) {
    job.isFavorited = isFavorited;
    if (isFavorited) {
      dispatch({
        type: "UPDATE_FAVORITE_ROLES",
        payload: job,
      });
    } else {
      dispatch({
        type: "UPDATE_UNFAVORITE_ROLES",
        payload: job.reqId,
      });
    }
  }

  function renderLocation() {
    const { locations } = job;

    if (locations?.length) {
      const { name, level } = locations[0];
      return <RoleLocation name={name as string} level={level as number} />;
    }
    return null;
  }

  function submitResume() {}

  function getAppliedIconTooltipConfiguration() {
    return {
      overrideDefaultBehaviour: true,
      showOnHover: true,
      icon: (
        <button aria-describedby={`${appliedRoleIconId}-info-tooltip`}>
          <Icon
            id={appliedRoleIconId}
            name="circle-checkmark-green"
            size="xsmall"
          ></Icon>
        </button>
      ),
    } as TooltipConfiguration;
  }

  function getAppliedIcon() {
    return job.submitted ? (
      <>
        <InfoTooltip
          id={`${appliedRoleIconId}-info-tooltip`}
          positionClass="form-tooltip-after"
          tooltipPointer="middle"
          iconLabel={t("jobsite.profile.yourRoles.submittedresume") as string}
          setAsHtml={true}
          infoTooltipBody={
            t("jobsite.profile.yourRoles.submittedresume") as string
          }
          configuration={getAppliedIconTooltipConfiguration()}
          role="tooltip"
        ></InfoTooltip>
      </>
    ) : null;
  }

  function renderPostingDate() {
    const { postDateInGMT } = job;
    let { postingDate } = job;
    const { countryCode } = appUIState.appData;
    const languageCode = countryCode?.htmlLang?.replace("_", "-");

    if (postDateInGMT && typeof window !== "undefined") {
      postingDate = getLocaleDate(languageCode, postDateInGMT);
    }

    return (
      <span className="job-posted-date" id={jobPostedDateId}>
        {postingDate}
      </span>
    );
  }

  let teamName = "";
  if (job.team && job.team.teamName) {
    teamName = job.team.teamName;
  }

  return (
    <div
      id={useIdGenerator("search", id, true)}
      className="d-flex flex-row row large-12 job-title job-list-item"
    >
      <div className="d-flex flex-column column large-6 small-12 text-align-start job-title-link pr-40 w-51">
        <h3>
          <LocaleAwareLink
            text={job.postingTitle}
            link={url}
            classes="t-intro word-wrap-break-word"
            state={searchLocation}
            ariaLabel={`${job.postingTitle} ${job.positionId}`}
          ></LocaleAwareLink>
        </h3>
        <span
          id={useIdGenerator("search", teamName, true)}
          className="team-name mt-0"
        >
          {teamName}
        </span>
        {job.postingDate ? renderPostingDate() : null}
      </div>
      <div
        id={useIdGenerator("search", `location-${id}`, true)}
        className="column large-4 small-12 text-align-start job-title-location"
        aria-label={t("jobsite.common.location") as string}
      >
        {job.locations?.length ? renderLocation() : null}
      </div>
      <div
        className={
          isMobile
            ? "d-flex column large-1 small-12 align-self-center align-center"
            : "column large-1 small-12 align-self-center"
        }
        aria-label={t("jobsite.profile.yourRoles.actions") as string}
      >
        <FavoriteComponent
          iconSize="small"
          positionId={job.reqId}
          isFavorited={job.isFavorited || false}
          handleFavoriteChange={handleFavoriteChange}
          id={useIdGenerator("search", `favorite-role-${id}`, true)}
          postingTitle={job.postingTitle}
        ></FavoriteComponent>

        {!isInternal && isMobile ? (
          <ShareJobComponent
            jobData={job}
            jobId={job.positionId}
            localeCode={appUIState.appData.locale}
            type={"icon"}
            classes="share-job d-flex"
            size="small"
            id={`search-${job.positionId}`}
          ></ShareJobComponent>
        ) : null}

        {isMobile ? (
          <>
            <span className="ml-25">{getAppliedIcon()}</span>
            <RoleSubmitButton
              id={submitRoleId}
              managedPipelineRole={job.managedPipelineRole}
              submitted={job.submitted || false}
              resubmitted={job.resubmitted || false}
              disabled={false}
              jobId={job.positionId}
              handleSubmitResume={submitResume}
              transformedPostingTitle={job.transformedPostingTitle}
              postingTitle={job.postingTitle}
              showLinkOnMobileView={true}
            ></RoleSubmitButton>
          </>
        ) : null}
      </div>
      {!isMobile ? (
        <div className="column large-1 small-12 align-self-center">
          <span className="pr-20" id={appliedRoleId}>
            {getAppliedIcon()}
          </span>
        </div>
      ) : null}
    </div>
  );
}
