import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { useRouteError, useRouteLoaderData } from "react-router";
import GlobalError from "../components/feature/GlobalError";
import { RouteID } from "../routes";
import { AppData } from "../types";

export default function AppErrorBoundary() {
  const errorData: any = useRouteError();
  const appData = useRouteLoaderData(RouteID.root) as AppData;
  const { locale, translations, isInternal, hasTalentError } = appData;
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.error(errorData);
  }, [errorData]);
  return (
    <IntlProvider locale={locale} messages={translations}>
      <GlobalError
        hasError={!hasTalentError && !!errorData}
        isInternal={isInternal}
      ></GlobalError>
    </IntlProvider>
  );
}
