import React from "react";
import { Location } from "react-router";
import LocaleAwareLink from "../../components/feature/LocaleAwareLink";
import { useBackToSearchUrl } from "../../hooks/useBackToSearchUrl";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { idGenerator } from "../../utils/idGenerator";
import JobDetailHeaderActions from "./JobDetailHeaderActions";
import JobDetailHeaderIcons from "./JobDetailHeaderIcons";

interface JobDetailHeaderProps {
  handleFavoriteChange: (isFavorited: boolean) => void;
  handleShareJob: (data: any) => void;
  handleSubmitResume: (data: any) => void;
  jobData: any;
  id?: string;
  previousLocation?: Location | undefined;
}

export default function JobDetailHeader({
  handleFavoriteChange,
  handleShareJob,
  handleSubmitResume,
  jobData,
  id,
  previousLocation,
}: JobDetailHeaderProps) {
  const { t } = useIntlMessage();
  const idGeneratorContainer = idGenerator("jobdetails", id || "");
  const isMobile = useIsMobile();
  const { backToSearchUrl } = useBackToSearchUrl({ previousLocation });

  function getHeaderIcons() {
    return (
      <JobDetailHeaderIcons
        id={idGeneratorContainer.generateId("icons-header")}
        handleShareJob={handleShareJob}
        handleFavoriteChange={handleFavoriteChange}
        jobData={jobData}
      ></JobDetailHeaderIcons>
    );
  }

  return (
    <div>
      {!isMobile ? <div className={"mt-10"}>{getHeaderIcons()}</div> : null}
      <div className={"mt-20"}>
        <JobDetailHeaderActions
          id={idGeneratorContainer.generateId("actions")}
          jobData={jobData}
          handleSubmitResume={handleSubmitResume}
        ></JobDetailHeaderActions>
      </div>
      <div className={isMobile ? "ml-20 mt-20 mr-20" : "m-20"}>
        <LocaleAwareLink
          id={idGeneratorContainer.generateId("backtosearch")}
          link={backToSearchUrl}
          type="less"
          text={t("jobsite.jobdetails.backSearch") as string}
        ></LocaleAwareLink>
      </div>
      {isMobile ? getHeaderIcons() : null}
    </div>
  );
}
