import { Location, useRouteLoaderData } from "react-router";
import { useContext } from "react";
import AppContext from "../AppContext";
import { RouteID } from "../routes";
import { getBackToSearchUrl } from "../utils/jobDetailUtil";

interface Props {
  previousLocation?: Location;
}

export function useBackToSearchUrl({ previousLocation }: Props) {
  const rootLoaderData = useRouteLoaderData(RouteID.root) as any;
  const searchPageUrl = rootLoaderData.searchPageUrl;
  const { appUIState } = useContext(AppContext);
  const backToSearchUrl = getBackToSearchUrl(
    previousLocation,
    appUIState.searchUrl,
    searchPageUrl,
  );
  return { backToSearchUrl };
}
