import { Overlay } from "@rpe-js/marcom-web-components";
import React from "react";
import { SaveCancelAction } from "../../../components/feature/saveAndCancel";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { idGenerator } from "../../../utils/idGenerator";

interface RemoveAllModalProps {
  onRemoveAllRoles: () => void;
  onRemoveAllModalClose: () => void;
  elementIdToFocus: string;
}

const RemoveAllModal = ({
  onRemoveAllRoles,
  onRemoveAllModalClose,
  elementIdToFocus,
}: RemoveAllModalProps) => {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const removeAllModalIdGenerator = idGenerator("yourroles", "removeallmodal");
  return (
    <Overlay
      id={removeAllModalIdGenerator.generateId()}
      visible={true}
      onClose={onRemoveAllModalClose}
      closeButtonAttrs={{
        ariaLabel: t("jobsite.common.close") as string,
      }}
      isFullscreen={isMobile}
      disableClickAway={true}
      elementIdToFocus={elementIdToFocus}
      ariaLabel={removeAllModalIdGenerator.generateId("header")}
    >
      <div>
        <h2
          className="a11y"
          id={removeAllModalIdGenerator.generateId("header")}
        >
          {t("jobsite.profile.yourRoles.removetitle")}
        </h2>
        <p className="text-center">
          {t("jobsite.profile.yourRoles.removeconfirm")}
        </p>
        <div className="d-flex justify-center">
          <SaveCancelAction
            onCancel={onRemoveAllModalClose}
            onSave={onRemoveAllRoles}
            cancelLabelName={t("jobsite.common.cancel") as string}
            saveLabelName={t("jobsite.common.remove") as string}
            cancelButtonId={idGenerator(
              "yourroles",
              "remove-all-modals",
            ).generateId("cancel-button")}
            saveButtonId={idGenerator(
              "yourroles",
              "remove-all-modals",
            ).generateId("remove-button")}
          />
        </div>
      </div>
    </Overlay>
  );
};

export default RemoveAllModal;
