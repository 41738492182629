import {
  Checkbox,
  ProgressIndicatorLoader,
} from "@rpe-js/marcom-web-components";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { EmploymentTypeRefData } from "../../../../shared/types/refData";
import { EmploymentType } from "../../../../shared/types/talent/talent";
import { updateTalent } from "../../../api/fetchClient";
import { PROFILE_SECTIONS } from "../../../app.constants";
import ErrorMessage from "../../../components/base/ErrorMessage";
import { SaveCancelAction } from "../../../components/feature/saveAndCancel";
import { useAppAlertContext } from "../../../contexts/AppAlert";
import { useFetchData } from "../../../hooks/useFetchData";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { handleScrollToElement } from "../../../utils/focusUtil";
import { idGenerator } from "../../../utils/idGenerator";
import { updateEmploymentType } from "../context/Actions";
import { ProfileContext } from "../context/ProfileContext";
import { ProfileSection } from "./profileSection";

type EmploymentTypeSection = {
  employmentTypes: EmploymentTypeRefData[];
};

type EmploymentTypeForm = {
  id: string;
  name: string;
  checked?: boolean;
};

export const EmploymentTypeSection = ({
  employmentTypes,
}: EmploymentTypeSection) => {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const [isEdit, setEdit] = useState<boolean>(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const profileContext = useContext(ProfileContext);
  const [employmentTypesForm, setEmploymentTypesForm] = useState<
    EmploymentTypeForm[]
  >([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { updateAlert, deleteAlert } = useAppAlertContext();
  const { isLoading, isSuccess, fetchData, isError } =
    useFetchData(updateTalent);
  const employmentTypeIdGenerator = idGenerator("profile", "employmentTypes");
  const employmentTypesSectionId = employmentTypeIdGenerator.generateId();
  const handleCheckboxChange = (checked: boolean, index: number) => {
    const employmentTypesCopy = employmentTypesForm;
    employmentTypesCopy[index].checked = checked;
    setEmploymentTypesForm([...employmentTypesCopy]);
    setErrorMessage(null);
  };

  const getCheckedTypes = useCallback(() => {
    return employmentTypesForm
      .filter((type: EmploymentTypeForm) => type.checked)
      ?.map((type: EmploymentTypeForm) => ({
        employmentTypeName: type.name,
        employmentTypeID: type.id,
        checked: type.checked,
      }));
  }, [employmentTypesForm]);

  const handleSave = () => {
    deleteAlert();
    let isError = true;
    setSaveClicked(true);
    employmentTypesForm.forEach((type: EmploymentTypeForm) => {
      if (type.checked) {
        isError = false;
      }
    });
    if (isError) {
      setErrorMessage(t("jobsite.common.selectOneOption") as string);
      return;
    }

    const payload = {
      employmentTypes: getCheckedTypes(),
    };
    fetchData([
      profileContext?.state?.talentId,
      PROFILE_SECTIONS.EMPLOYMENT_TYPES,
      payload,
    ]);
  };

  const handleCancel = () => {
    setErrorMessage(null);
    setSaveClicked(false);
    setEdit(false);
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      profileContext?.dispatch(updateEmploymentType(getCheckedTypes()));
      setSaveClicked(false);
      setEdit(false);
      handleScrollToElement(employmentTypesSectionId);
    }
    if (!isLoading && isError) {
      updateAlert(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isLoading, isSuccess, updateAlert]);

  useEffect(() => {
    if (employmentTypes?.length) {
      const selectedEmploymentTypes =
        profileContext?.state?.employmentTypes?.map(
          (type: EmploymentType) => type.employmentTypeID,
        );
      const employmentTypesCopy = [...employmentTypes];
      employmentTypesCopy.forEach((type: EmploymentTypeForm) => {
        if (selectedEmploymentTypes?.includes(type.id)) {
          type.checked = true;
        } else {
          type.checked = false;
        }
      });
      setEmploymentTypesForm([...employmentTypesCopy]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employmentTypes]);

  return (
    <ProfileSection
      isEdit={isEdit}
      i18nTitle="jobsite.information.empTypeTitle"
      onEdit={() => setEdit(true)}
      id={employmentTypesSectionId}
      sectionName="employmentTypes"
    >
      {isEdit ? (
        <>
          {isLoading && (
            <ProgressIndicatorLoader showLoading={true} curtain={true} />
          )}
          <div className="column">
            <div
              className={`column large-9 small-12 d-flex mb-5 ${isMobile && "checkbox-container"}`}
            >
              {employmentTypesForm.map(
                (empType: EmploymentTypeForm, index: number) => (
                  <Checkbox
                    key={index}
                    classes={{ wrapper: !isMobile ? "mr-40" : "" }}
                    required={true}
                    id={`intern-checkbox_${empType.id}`}
                    label={empType.name}
                    checked={empType.checked}
                    showTileView={isMobile}
                    onValueChange={(checked) =>
                      handleCheckboxChange(checked, index)
                    }
                  />
                ),
              )}
            </div>
            {saveClicked && errorMessage && (
              <ErrorMessage
                message={errorMessage}
                errorA11y={t("jobsite.common.errorIconLabel") as string}
              />
            )}
            <div className="mt-20">
              <SaveCancelAction
                onSave={handleSave}
                onCancel={handleCancel}
                cancelButtonId={employmentTypeIdGenerator.generateId(
                  "cancelButton",
                )}
                saveButtonId={employmentTypeIdGenerator.generateId(
                  "saveButton",
                )}
                scrollToTopSectionId={employmentTypesSectionId}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="row large-9 small-12 d-flex white-space-pre">
          {profileContext?.state?.employmentTypes?.length ? (
            <>
              {profileContext?.state?.employmentTypes?.map(
                (employmentType: EmploymentType, index: number) => (
                  <span key={index}>
                    {employmentType.employmentTypeName}
                    {index <
                      (profileContext?.state?.employmentTypes
                        ?.length as number) -
                        1 && ", "}
                  </span>
                ),
              )}
            </>
          ) : (
            <span>{t("jobsite.common.notSpecified")}</span>
          )}
        </div>
      )}
    </ProfileSection>
  );
};
