import { Button } from "@rpe-js/marcom-web-components";
import React, { useContext } from "react";
import AppContext from "../../AppContext";
import RoleSubmitButton from "../../components/feature/RoleSubmitButton";
import useIntlMessage from "../../hooks/useIntlMessage";
import { idGenerator } from "../../utils/idGenerator";
import { getLocationPickerOrApplyUrl } from "../../utils/jobDetailUtil";

interface JobDetailHeaderProps {
  handleSubmitResume: (data: any) => void;
  jobData: any;
  id?: string;
}

export default function JobDetailHeaderActions({
  handleSubmitResume,
  jobData,
  id,
}: JobDetailHeaderProps) {
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { locale } = appUIState.appData;
  const idGeneratorContainer = idGenerator("jobdetails", id || "");
  const locationUrl = getLocationPickerOrApplyUrl(
    locale,
    jobData.positionId,
    jobData.transformedPostingTitle,
    jobData.managedPipelineRole,
  );
  const referUrl = `/app/${locale}/erp/?roleId=${jobData?.positionId}`;

  const submitResume = () => {
    handleSubmitResume({});
  };

  function getHiringButton() {
    if (jobData?.managedPipelineRole) {
      return (
        <div className={"jd-header-actions-button-container"}>
          <Button
            id={idGeneratorContainer.generateId("wherewearehiring")}
            size="base"
            label={t("jobsite.search.whereWeAreHiring") as string}
            color="secondary"
            blockedVariant={true}
            onClick={() => {}}
            isLinkButton={true}
            href={locationUrl}
          ></Button>
        </div>
      );
    }
  }

  function showReferAFriendButton() {
    if (jobData?.isInternalJobDetails) {
      return (
        <div className={"jd-header-actions-button-container"}>
          <Button
            id={idGeneratorContainer.generateId("referfriend")}
            size="base"
            label={t("jobsite.common.referFriend") as string}
            color="secondary"
            blockedVariant={true}
            onClick={() => {}}
            isLinkButton={true}
            href={referUrl}
          ></Button>
        </div>
      );
    }
  }

  return (
    <div
      className={
        "d-flex flex-row justify-center align-center gap-30 jd-header-actions-container"
      }
    >
      {getHiringButton()}
      <div className={"jd-header-actions-button-container"}>
        <RoleSubmitButton
          id={idGeneratorContainer.generateId("jobdetailssubmitresume")}
          managedPipelineRole={jobData?.managedPipelineRole}
          submitted={jobData?.submitted}
          resubmitted={jobData?.resubmitted}
          handleSubmitResume={submitResume}
          jobId={jobData?.positionId}
          transformedPostingTitle={jobData.transformedPostingTitle}
          postingTitle={jobData.postingTitle}
        ></RoleSubmitButton>
      </div>
      {showReferAFriendButton()}
    </div>
  );
}
