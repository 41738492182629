import { ProgressIndicatorLoader } from "@rpe-js/marcom-web-components";
import React, { useEffect, useRef, useState } from "react";
import { MappedPostLocation } from "../../../../shared/types/refData";
import { PreferredLocations } from "../../../../shared/types/talent/talent";
import {
  getPreferredLocationsByInput,
  updateTalent,
} from "../../../api/fetchClient";
import { PROFILE_SECTIONS } from "../../../app.constants";
import PillButton from "../../../components/base/PillButton";
import TypeaheadComponent, {
  typeaheadstandardCssClasses,
} from "../../../components/base/Typeahead/TypeaheadComponent";
import { getTypeaheadTextBoxId } from "../../../components/base/Typeahead/typeaheadUtils";
import { useFormInputValidator } from "../../../components/feature/form/inputValidators/useFormInputValidator";
import { SaveCancelAction } from "../../../components/feature/saveAndCancel";
import { useAppAlertContext } from "../../../contexts/AppAlert";
import { useFetchData } from "../../../hooks/useFetchData";
import useFocusFirstErrorField from "../../../hooks/useFocusFirstErrorField";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { getLocationName } from "../../../util";
import {
  focusManagementOnRemove,
  handleScrollToElement,
} from "../../../utils/focusUtil";
import { idGenerator } from "../../../utils/idGenerator";
import { updateLocations } from "../context/Actions";
import { useProfileContext } from "../context/ProfileContext";
import { ProfileSection } from "./profileSection";

interface LocationsForm extends PreferredLocations {
  displayName?: string;
}

export const PreferredLocationSection = () => {
  const [isEdit, setEdit] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [saveClicked, setSaveClicked] = useState(false);
  const { t } = useIntlMessage();
  const { state, dispatch } = useProfileContext();
  const { updateAlert, deleteAlert } = useAppAlertContext();
  const preferredLocationIdGenerator = idGenerator(
    "profile",
    "preferredLocations",
  );
  const preferredLocationsSectionId = preferredLocationIdGenerator.generateId();
  const { setFocusFirstErrorField } = useFocusFirstErrorField(
    preferredLocationsSectionId,
  );
  const [tempLocation, setTempLocation] = useState<LocationsForm[]>(
    state?.preferredLocations?.length ? [...state?.preferredLocations] : [],
  );
  const { validateSchoolSkill, onPasteValidateSchoolSkill } =
    useFormInputValidator();
  const {
    isLoading: isSaveTalentLoading,
    isSuccess: isSaveTalentSuccess,
    fetchData: saveTalent,
    isError: isSaveTalentError,
  } = useFetchData(updateTalent);
  const itemsListRef = useRef<HTMLUListElement | null>(null);
  const PREFERRED_LOCATION_TYPEAHEAD_ID = idGenerator(
    "profile",
    "location-typeahead",
  ).generateId();

  const handleAddLocation = (locationToAdd: MappedPostLocation) => {
    if (
      !tempLocation
        .map((location: PreferredLocations) => location.locationID)
        ?.includes(locationToAdd.id)
    ) {
      setTempLocation((prev: any) => [
        ...prev,
        {
          locationID: locationToAdd.id,
          displayName: locationToAdd.name,
        },
      ]);
    }
    setErrorMessage(null);
  };

  const handleRemoveLocation = (
    locationToRemove: PreferredLocations,
    index: number,
  ) => {
    const updatedLocations = tempLocation.filter(
      (location) => location.locationID !== locationToRemove.locationID,
    );
    setTempLocation(updatedLocations);
    focusManagementOnRemove(
      itemsListRef,
      index,
      tempLocation.length,
      getTypeaheadTextBoxId(PREFERRED_LOCATION_TYPEAHEAD_ID),
    );
    if (!updatedLocations.length) {
      setErrorMessage(t("jobsite.common.preferredLocationerror") as string);
    }
  };

  const handleSave = () => {
    deleteAlert();
    setSaveClicked(true);
    if (tempLocation.length === 0) {
      setErrorMessage(t("jobsite.common.preferredLocationerror") as string);
      setFocusFirstErrorField(true);
      return;
    }
    setErrorMessage(null);
    saveTalent([
      state?.talentId,
      PROFILE_SECTIONS.LOCATIONS,
      {
        preferredLocations: tempLocation.map?.((location: LocationsForm) => ({
          locationID: location.locationID,
        })),
      },
    ]);
  };

  const handleCancel = () => {
    setSaveClicked(false);
    setTempLocation(
      state?.preferredLocations?.length ? [...state?.preferredLocations] : [],
    );
    setErrorMessage(null);
    setEdit(false);
  };

  useEffect(() => {
    if (!isSaveTalentLoading && isSaveTalentSuccess) {
      dispatch?.(updateLocations(tempLocation));
      setSaveClicked(false);
      setEdit(false);
      handleScrollToElement(preferredLocationsSectionId);
    }
    if (!isSaveTalentLoading && isSaveTalentError) {
      updateAlert(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveTalentLoading, isSaveTalentSuccess, isSaveTalentError]);

  const cancelButtonId =
    preferredLocationIdGenerator.generateId("cancelButton");
  const saveButtonId = preferredLocationIdGenerator.generateId("saveButton");

  return (
    <ProfileSection
      isEdit={isEdit}
      i18nTitle="jobsite.common.preferredLocationTitle"
      onEdit={() => setEdit(true)}
      id={preferredLocationsSectionId}
      sectionName="preferredLocations"
    >
      {isEdit ? (
        <>
          {isSaveTalentLoading && (
            <ProgressIndicatorLoader showLoading={true} />
          )}
          <div className="row large-8 small-12">
            <TypeaheadComponent
              elementToFocusOnClose={saveButtonId} // bring the focus back to save
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              apiEndpoint={getPreferredLocationsByInput}
              onSelect={(e: any) => {
                handleAddLocation(e);
              }}
              strict={true}
              getSuggestionLabel={(suggestion) => suggestion?.name}
              minChars={1}
              highlightMatches={true}
              showSearchIcon={true}
              label={t("jobsite.common.searchLocationsPlaceholder") as string}
              suggestionPlaceholder={
                t("jobsite.common.suggestedLocations") as string
              }
              removeOnSelect={true}
              classNames={typeaheadstandardCssClasses}
              errorMessage={saveClicked && errorMessage ? errorMessage : ""}
              errorA11y={t("jobsite.common.errorIconLabel") as string}
              id={PREFERRED_LOCATION_TYPEAHEAD_ID}
              validators={{
                onKeyDown: validateSchoolSkill,
                onPaste: onPasteValidateSchoolSkill,
              }}
            />
            <ul
              className="d-flex flex-wrap column large-12 small-12 list-nobullet"
              ref={itemsListRef}
              role="list"
            >
              {tempLocation.map((location, index: number) => (
                <li role="listitem" key={`location_${location.locationID}`}>
                  <PillButton
                    id={idGenerator("profile", "location").generateId(
                      `${index}`,
                    )}
                    key={`location_${index}`}
                    label={
                      location.displayName
                        ? location.displayName
                        : getLocationName(
                            location.city,
                            location.state,
                            location.country,
                          )
                    }
                    buttonSize="small"
                    onRemove={() => handleRemoveLocation(location, index)}
                  />
                </li>
              ))}
            </ul>
            <SaveCancelAction
              onSave={handleSave}
              onCancel={handleCancel}
              cancelButtonId={cancelButtonId}
              saveButtonId={saveButtonId}
              scrollToTopSectionId={preferredLocationsSectionId}
            />
          </div>
        </>
      ) : (
        <div className="d-flex flex-wrap gap-10">
          {state?.preferredLocations?.length ? (
            state?.preferredLocations?.map((location, index) => (
              <span key={index} className="location-spacing">
                {location.displayName
                  ? location.displayName
                  : getLocationName(
                      location.city,
                      location.state,
                      location.country,
                    )}
              </span>
            ))
          ) : (
            <span>{t("jobsite.common.notSpecified") as string}</span>
          )}
        </div>
      )}
    </ProfileSection>
  );
};
