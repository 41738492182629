import {
  AppData,
  ResumeParsingData,
  TriggerLinkedInResumePolling,
} from "./types";

export const RESUME_PARSING_DATA = "RESUME_PARSING_DATA";
export const APP_DATA = "APP_DATA";
export const SET_SEARCH_URL = "SET_SEARCH_URL";

export type AppAction =
  | {
      type: "RESUME_PARSING_REVIEW_ACTIONS";
      payload: { showResumeActionsModal: boolean };
    }
  | {
      type: "TRIGGER_LINKEDIN_RESUME_POLLING";
      payload: TriggerLinkedInResumePolling | null;
    }
  | { type: "RESUME_PARSING_DATA"; payload: ResumeParsingData }
  | { type: "APP_DATA"; payload: AppData }
  | { type: "SET_SEARCH_URL"; payload: string };
