import React from "react";
import { Team } from "../../../shared/types/talent/talent";
import { Label } from "./Label";

interface TeamsViewProps {
  teams: Team[] | null | undefined;
  noResultsLabel: string;
}

export function TeamsView({ teams, noResultsLabel }: TeamsViewProps) {
  return (
    <>
      {teams && (
        <>
          <ul role="list" className="row list-nobullet">
            {teams.map(
              (team) =>
                team?.teamName &&
                team?.subTeams && (
                  <li
                    role="listitem"
                    className="column large-6 small-12 d-flex flex-column mb-10 pr-10"
                    key={team?.teamID}
                  >
                    <Label label={team.teamName} classes={["d-block"]} />
                    <ul role="list">
                      {team.subTeams.map((subTeam) => (
                        <li role="listitem" key={subTeam.subTeamID}>
                          {subTeam.subTeamName}
                        </li>
                      ))}
                    </ul>
                  </li>
                ),
            )}
          </ul>
        </>
      )}
      {!teams?.length && <span>{noResultsLabel}</span>}
    </>
  );
}
