import { isUndefined, omitBy } from "lodash";
import { Filters } from "../../../app/contexts/SearchContext";
import { FilterOption } from "../../../app/pages/search/FilterCheckboxGroup";
import APP_CONSTANTS from "../../../utilities/appConstants";

export function getJobLevelsObject(
  jobLeveleLoaderData: string[],
  t: any,
): FilterOption[] {
  return jobLeveleLoaderData.map((level) => {
    let transalatedName = level;
    if (level === APP_CONSTANTS.INTERNAL_FILTER_JOB_LEVELS.MY_LEVEL) {
      transalatedName = t("jobsite.search.myJobLevel") as string;
    } else if (
      level === APP_CONSTANTS.INTERNAL_FILTER_JOB_LEVELS.MY_LEVEL_PLUS_ONE
    ) {
      transalatedName = t("jobsite.search.myJobLevel1") as string;
    }
    return {
      id: level,
      name: transalatedName,
      selected: true,
    };
  });
}

export function mappedFiltersForService(
  stateFilters: Filters,
  fromLoader?: boolean,
) {
  const mapFilter: any = { ...stateFilters };
  if (stateFilters.products) {
    const mappedItems = stateFilters.products.map((item) => item.id);
    mapFilter.products = mappedItems;
  }
  if (stateFilters.retailRoles) {
    const mappedItems = stateFilters.retailRoles.map((item) => item.id);
    mapFilter.retailRoles = mappedItems;
  }
  if (stateFilters.locations) {
    const mappedItems = stateFilters.locations.map((item: any) => item.id);
    mapFilter.locations = mappedItems;
  }
  if (stateFilters.languages) {
    const mappedItems = stateFilters.languages.map((item) => item.id);
    mapFilter.languages = mappedItems;
  }
  if (stateFilters.hiringManagers) {
    const mappedItems = stateFilters.hiringManagers.map((item) => item.id);
    mapFilter.hiringManagers = mappedItems;
  }
  if (stateFilters.teams) {
    const mappedItems = stateFilters.teams.map((item) => ({
      team: `teamsAndSubTeams-${item.teamCode}`,
      subTeam: `subTeam-${item.code}`,
    }));
    mapFilter.teams = mappedItems;
  }
  if (!fromLoader) {
    if (stateFilters.jobLevel) {
      const mappedItems = stateFilters.jobLevel.map((item) => item.id);
      mapFilter.jobLevel = mappedItems;
    }
  } else {
    if (stateFilters.jobLevel) {
      const mappedItems = stateFilters.jobLevel;
      mapFilter.jobLevel = mappedItems;
    }
  }

  return omitBy(mapFilter, isUndefined);
}
