import * as React from "react";
import { useCallback } from "react";
import {
  FILTER_TYPE,
  SET_FILTER,
  UNSET_FILTER,
} from "../../contexts/actionTypes";
import { FilterValue, SEARCH_FILTER_TYPE } from "../../contexts/SearchContext";
import useIsMobile from "../../hooks/useIsMobile";
import { useSearchContext } from "../../hooks/useSearchContext";
import { FilterOption } from "./FilterCheckboxGroup";
import { InternalJobLevelFilter } from "./InternalJobLevelFilter";

interface InternalJobLevelFilterWrapperProps {
  internalJobLevelFilterData: Array<FilterOption>;
  onMobileFilterChange?: (
    filterType: SEARCH_FILTER_TYPE,
    filterValue: FilterValue,
  ) => void;
}

export function InternalJobLevelFilterWrapper({
  internalJobLevelFilterData,
  onMobileFilterChange,
}: InternalJobLevelFilterWrapperProps) {
  const { dispatch } = useSearchContext();
  const isMobile = useIsMobile();

  const updateMobileFilters = useCallback(
    (filterType: SEARCH_FILTER_TYPE, filterValue: FilterValue) => {
      if (onMobileFilterChange) onMobileFilterChange(filterType, filterValue);
    },
    [onMobileFilterChange],
  );

  // dispatch a filter action in case of desktop / invoke mobileFilterChange callback incase of mobile
  const onSelectLevel = useCallback(
    (levels: Array<FilterOption>) => {
      if (!isMobile)
        dispatch({
          type: SET_FILTER,
          filterName: FILTER_TYPE.JOB_LEVEL,
          payload: levels,
        });
      else
        updateMobileFilters(
          FILTER_TYPE.JOB_LEVEL as SEARCH_FILTER_TYPE,
          levels,
        );
    },
    [dispatch, isMobile, updateMobileFilters],
  );

  // dispatch a filter action in case of desktop / invoke mobileFilterChange callback incase of mobile
  const onRemoveLevel = useCallback(() => {
    if (!isMobile)
      dispatch({
        type: UNSET_FILTER,
        filterName: FILTER_TYPE.JOB_LEVEL,
      });
    else updateMobileFilters(FILTER_TYPE.JOB_LEVEL as SEARCH_FILTER_TYPE, []);
  }, [dispatch, isMobile, updateMobileFilters]);

  return (
    <InternalJobLevelFilter
      levelsData={internalJobLevelFilterData}
      onSelectLevel={onSelectLevel}
      onRemoveLevel={onRemoveLevel}
    />
  );
}
