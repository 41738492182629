import { ResumeActionType } from "../../../../app.constants";

export enum ParsingType {
  LINKEDIN = "linkedin",
  RESUME = "resume",
  MANUAL = "manual",
}

export interface ParsingStatus {
  action: ResumeActionType;
  type: ParsingType;
}

export interface ResumeActionInfo {
  linkedin: string;
  resume: string;
  icon: string;
  class: string;
}

export interface ResumeActions {
  [key: string]: ResumeActionInfo;
}

export const RESUME_REVIEW = {
  discard: "discard",
  reviewLater: "reviewLater",
  reviewNow: "reviewNow",
  reviewLaterLabel: "REVIEW_LATER",
  discardedLabel: "DISCARDED",
  reviewedLabel: "REVIEWED",
  expiredLabel: "EXPIRED",
  failedLabel: "FAILED",
};

export const actions: ResumeActions = {
  REVIEW_LATER: {
    linkedin: "jobsite.common.importLinkedinMsg",
    resume: "jobsite.common.importResumeMsg",
    icon: "icon icon-after icon-checkcircle",
    class: "warning-alert-text",
  },
  FAILED: {
    linkedin: "jobsite.common.linkedinParsingFails",
    resume: "jobsite.common.fileUploaErrordBanner",
    icon: "icon icon-after icon-exclamationcircle",
    class: "error-alert-text",
  },
  PROGRESS: {
    linkedin: "jobsite.common.linkedinUploadBanner",
    resume: "jobsite.common.fileUploadBanner",
    icon: "icon icon-after icon-checkcircle",
    class: "progress-alert-text",
  },
};
