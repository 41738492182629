import { useState, useEffect } from "react";

const useIsMobile = (): boolean => {
  const isClient = typeof window !== "undefined"; // Ensure window is available
  const [isMobile, setIsMobile] = useState<boolean>(
    isClient ? window.innerWidth < 735 : false, // Default to false if not in the browser
  );

  useEffect(() => {
    if (!isClient) return; // Exit if not running in a browser

    const handleResize = () => {
      setIsMobile(window.innerWidth < 735);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isClient]);

  return isMobile;
};

export default useIsMobile;
