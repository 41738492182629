import React from "react";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIdGenerator from "../../../hooks/useIdGenerator";

interface RoleLocationProps {
  name: string;
  level: number;
}

export default function RoleLocation(props: RoleLocationProps) {
  const { t } = useIntlMessage();
  const { name, level } = props;
  const storeNameId = useIdGenerator("search", "store-name", true);
  const storeNameContainerId = useIdGenerator(
    "search",
    "store-name-container",
    true,
  );

  function renderVariousLocations() {
    return (
      <span className="table--advanced-search__location-sub" id={storeNameId}>
        {
          t("jobsite.profile.yourRoles.variousLocationsWithin", {
            locationName: name,
          }) as string
        }
      </span>
    );
  }

  let location = null;

  if (level <= 4 && name) {
    return renderVariousLocations();
  } else {
    location = name;
  }

  return <span id={storeNameContainerId}>{location}</span>;
}
