import React from "react";
import { IntlMessageType } from "../../../types";
import HtmlRenderer from "../../base/HtmlRenderer";

interface SignedOutProps {
  title?: IntlMessageType;
  titleId?: string;
  signoutMessage: IntlMessageType;
  linkedInMessage: IntlMessageType;
}

export default function SignedOut({
  title,
  titleId = "",
  signoutMessage,
  linkedInMessage,
}: SignedOutProps) {
  return (
    <div className={"text-center p-50"}>
      {title && (
        <h2 id={titleId} className={"t-headline-reduced fw-regular"}>
          {title}
        </h2>
      )}
      <div className={"pb-20 u-border-bottom"}>{signoutMessage}</div>
      <HtmlRenderer
        initialContent={linkedInMessage as string}
        classes="pt-20 hyperlink-underline"
        htmlTag="div"
      />
    </div>
  );
}
