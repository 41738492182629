import {
  Education,
  EmploymentDetails,
  EmploymentType,
  Language,
  Link,
  MobilityPreference,
  PreferredLocations,
  SelfDisclosure,
  Skill,
  Talent,
  Team,
} from "../../../../shared/types/talent/talent";
import { TalentFileMetaData } from "../../../../shared/types/talent/talentFileMetatdata";
import { ProfileAction } from "./ActionTypes";

export const setProfileData = (data: Talent): ProfileAction => ({
  type: "SET_PROFILE_DATA",
  payload: data,
});

export const setFileMetaData = (data: TalentFileMetaData[]): ProfileAction => ({
  type: "SET_FILE_META_DATA",
  payload: data,
});

export const updateContactInformation = (
  data: Pick<
    Talent,
    "addresses" | "contact" | "displayPronouns" | "privacyPolicy"
  >,
): ProfileAction => ({
  type: "UPDATE_CONTACT_INFORMATION",
  payload: data,
});

export const updatePreferredEmail = (data: string): ProfileAction => ({
  type: "UPDATE_PREFERRED_EMAIL",
  payload: data,
});

export const updateMobilityPreferences = (
  data: MobilityPreference,
): ProfileAction => ({
  type: "UPDATE_MOBILITY_PREFERENCES",
  payload: data,
});

export const updateEmploymentType = (
  data: EmploymentType[],
): ProfileAction => ({
  type: "UPDATE_EMPLOYMENT_TYPE",
  payload: data,
});

export const updateSkills = (data: Skill[]): ProfileAction => ({
  type: "UPDATE_SKILLS",
  payload: data,
});

export const updateLanguages = (data: Language[]): ProfileAction => ({
  type: "UPDATE_LANGUAGES",
  payload: data,
});

export const updateLocations = (data: PreferredLocations[]): ProfileAction => ({
  type: "UPDATE_LOCATIONS",
  payload: data,
});

export const updateEducation = (data: Education[]): ProfileAction => ({
  type: "UPDATE_EDUCATION",
  payload: data,
});

export const updateSelfDisclosures = (
  data: Record<string, SelfDisclosure>,
): ProfileAction => ({
  type: "UPDATE_SELF_DISCLOSURES",
  payload: data,
});

export const updateLinks = (data: Link[]): ProfileAction => ({
  type: "UPDATE_LINKS",
  payload: data,
});

export const updateEmployments = (
  data: EmploymentDetails[],
): ProfileAction => ({
  type: "UPDATE_EMPLOYMENTS",
  payload: data,
});

export const updateTeams = (data: Team[]): ProfileAction => ({
  type: "UPDATE_TEAMS_INTEREST",
  payload: data,
});

export const updateRecruiterLastModification = (
  data: string[],
): ProfileAction => ({
  type: "UPDATE_RECRUITER_LAST_MODIFICATION",
  payload: data,
});

export const updateAtLeastOneJob = (data: boolean): ProfileAction => ({
  type: "UPDATE_AT_LEAST_ONE_JOB",
  payload: data,
});
