import React, { useContext } from "react";
import { Config } from "../shared/types/config";
import { Contact } from "../shared/types/talent/talent";
import { User } from "../shared/types/talent/user";

type UserAction =
  | {
      type: "SET_CURRENT_USER_AND_CONFIG";
      payload: {
        currentUser: User;
        config: Config;
      };
    }
  | {
      type: "UPDATE_USER_CONTACT_INFO";
      payload: { contact: Contact };
    }
  | {
      type: "SET_ROLES_COUNT";
      payload: number;
    };

interface AuthenticatedUserState {
  currentUser: User;
  config: Config;
  rolesCount: number;
}

export const initialUserState: AuthenticatedUserState = {
  currentUser: {},
  config: {
    linkedIn: {
      url: "",
      apiKey: "",
      companyID: "",
      companyJobCode: "",
      companyToken: "",
    },
    recrutics: {
      url: "",
    },
  },
  rolesCount: 0,
};

export const reducer = (
  state: AuthenticatedUserState,
  action: UserAction,
): AuthenticatedUserState => {
  switch (action.type) {
    case "SET_CURRENT_USER_AND_CONFIG":
      return {
        ...state,
        currentUser: action.payload.currentUser,
        config: action.payload.config,
      };
    case "UPDATE_USER_CONTACT_INFO":
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          contact: action.payload.contact,
        },
      };
    case "SET_ROLES_COUNT":
      return {
        ...state,
        rolesCount: action.payload,
      };
    default:
      return state;
  }
};

const CurrentUserContext = React.createContext<
  | (AuthenticatedUserState & {
      dispatch: React.Dispatch<UserAction>;
    })
  | undefined
>(undefined);

export const useCurrentUserContext = (): AuthenticatedUserState & {
  dispatch: React.Dispatch<UserAction>;
} => {
  const context = useContext(CurrentUserContext);
  if (!context) {
    throw new Error(
      "useMyApplicationContext must be used within an MyApplicationProvider",
    );
  }
  return context;
};

export default CurrentUserContext;
