import { Button, NativeButton, Overlay } from "@rpe-js/marcom-web-components";
import React, { useRef, useState } from "react";
import { getCaptcha, shareJob } from "../../../api/fetchClient";
import useFocusFirstErrorField from "../../../hooks/useFocusFirstErrorField";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { idGenerator, ModuleName } from "../../../utils/idGenerator";
import { validateEmailOnSubmit } from "../../../utils/validatorUtil";
import IconButton from "../../base/IconButton";
import TextIcon from "../../base/TextIcon";
import ShareJobAfterSubmitContent from "./ShareJobAfterSubmitContent";
import ShareJobCaptcha from "./ShareJobCaptcha";
import ShareJobHeader from "./ShareJobHeader";

// default captcha type
const IMAGE = "I";

interface ShareJobComponentProps {
  jobData: any;
  id: string;
  classes?: string;
  size?: "xsmall" | "small" | "medium" | "large" | "xlarge";
  localeCode?: string;
  jobId?: string;
  type: "icon" | "text-icon";
}

export default function ShareJobComponent({
  jobData,
  id,
  classes,
  size,
  localeCode,
  jobId,
  type,
}: ShareJobComponentProps) {
  const shareJobModalId = idGenerator("shareJob", "sharejobmodal").generateId();
  const [showModal, setShowModal] = useState(false);
  const [captchaAnswer, setCaptchaAnswer] = useState("");
  const [captchaErrorCode, setCaptchaErrorCode] = useState("");

  const [emailIsValid, setEmailIsValid] = useState(true);
  const [captchaType, setCaptchaType] = useState(IMAGE);

  const [captchaData, setCaptchaData] = useState({
    captchaSource: "",
    uniqueId: "",
    isCaptchaValid: true,
    captchaToken: "",
  });

  const [mailToEmail, setMailToEmail] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isCaptchaAnswerValid, setIsCaptchaAnswerValid] = useState(true);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const jobPositionID = jobId
    ? jobId
    : jobData && jobData.jobNumber
      ? jobData.jobNumber
      : "";
  const jobReqID = jobData && jobData.reqId ? jobData.reqId : "";
  const locale = //"en-us" is the format for getCaptcha API;
    localeCode
      ? localeCode
      : jobData && jobData.localeInfo && jobData.localeInfo.locale
        ? jobData.localeInfo.locale
        : "";

  //executes once on mount and then on captchaType change
  const getCaptchaDetails = async (captchaType: string) => {
    await getCaptcha(locale, captchaType).then((captchaDetails: any) => {
      const { captchaToken, captchaSource, uniqueId, isValidCaptcha } =
        captchaDetails;

      if (isValidCaptcha === 1) {
        setCaptchaData({
          captchaToken: captchaToken,
          uniqueId: uniqueId,
          captchaSource: captchaSource,
          isCaptchaValid: isValidCaptcha,
        });
      }
    });
  };

  const resetState = () => {
    setCaptchaAnswer("");
    setEmailIsValid(true);
    setCaptchaType(IMAGE);
    setCaptchaData({
      captchaSource: "",
      uniqueId: "",
      isCaptchaValid: true,
      captchaToken: "",
    });
    setIsSubmit(false);
    setMailToEmail("");
    setIsCaptchaAnswerValid(true);
  };

  // on share job modal open
  const shareJobHandler = () => {
    setShowModal(true);
    setCaptchaType(IMAGE);

    getCaptchaDetails(IMAGE);
  };

  const onClose = () => {
    setShowModal(false);
    resetState();
  };

  const onCloseAfterSubmit = () => {
    setShowSuccessModal(false);
  };

  const emailValidation = (email: any) => {
    const validation = validateEmailOnSubmit(email);

    return validation.valid;
  };

  const onSubmit = () => {
    setEmailIsValid(emailValidation(mailToEmail));
    const сaptchaIsNotEmpty = captchaAnswer.trim().length > 0;
    setIsCaptchaAnswerValid(сaptchaIsNotEmpty);

    // if entries are valid, send email request
    if (сaptchaIsNotEmpty && emailValidation(mailToEmail)) {
      shareJob(
        {
          toList: [mailToEmail],
          jobPositionId: jobPositionID,
          jobReqID: jobReqID,
          captchaToken: captchaData.captchaToken,
          answer: captchaAnswer,
          uniqueId: Number(captchaData.uniqueId),
          locale: locale,
          subject: t("jobsite.search.leaveNote") as string,
        },
        captchaType,
      ).then((response) => {
        if (response.success && response.data === "") {
          setEmailSent(true);
          setIsSubmit(true);
          setShowModal(false);
          setCaptchaErrorCode("");
          onClose();
          setShowSuccessModal(true);
        } else if (response.error && response.code !== "") {
          setEmailSent(false);
          setIsSubmit(true);
          setCaptchaErrorCode(response.code);
          setIsCaptchaAnswerValid(false);
          getCaptchaDetails(captchaType);
        } else {
          setEmailSent(false);
          setIsSubmit(true);
          setCaptchaErrorCode("");
          setShowModal(false);
          onClose();
          setShowSuccessModal(true);
        }
      });
    } else {
      // focus on errors to correct before submitting
      setFocusFirstErrorField(true);
    }
  };

  //translation hook
  const { t } = useIntlMessage();
  const translate = (idToTranslate: string) => {
    const translatedString = t(idToTranslate) as string;

    return translatedString;
  };

  const isMobile = useIsMobile();
  const { setFocusFirstErrorField } = useFocusFirstErrorField(shareJobModalId);
  return (
    <>
      {type === "icon" && (
        <IconButton
          aria-label={t("jobsite.search.shareRoleDesc") as string}
          onClick={shareJobHandler}
          classes={classes}
          name={"share-blue"}
          size={size}
          id={idGenerator("shareJob", `${id}`).generateId("button")}
        ></IconButton>
      )}
      {type === "text-icon" && (
        <NativeButton
          id={idGenerator("shareJob", `${id}`).generateId("button")}
          ref={buttonRef}
          onClick={shareJobHandler}
          className={"search-shareJob-textIcon-button"}
        >
          <span className={"link"}>{t("jobsite.search.share") as string}</span>
          <TextIcon name={"icon-share"} id={"test"} textColor={"blue"} />
        </NativeButton>
      )}

      {showModal ? (
        <div>
          <Overlay
            ariaLabel="share-job-header"
            id={shareJobModalId}
            wide={true}
            noCloseButton={false}
            visible={showModal}
            onClose={onClose}
            isFullscreen={isMobile}
            disableEsc={false}
            stickyClose={true}
            closeButtonAttrs={{
              ariaLabel: t("jobsite.common.cancel") as string,
              stickyClose: true,
              alignStart: true,
            }}
            disableClickAway={true}
            elementIdToFocus={idGenerator("shareJob", `${id}`).generateId(
              "button",
            )}
          >
            <div className={!isMobile ? "mr-100 ml-100" : ""}>
              <div className=" text-center">
                <ShareJobHeader></ShareJobHeader>
              </div>

              <ShareJobCaptcha
                isCaptchaAnswerValid={isCaptchaAnswerValid}
                setIsCaptchaAnswerValid={setIsCaptchaAnswerValid}
                emailIsValid={emailIsValid}
                captchaErrorCode={captchaErrorCode}
                setEmail={setMailToEmail}
                captchaType={captchaType}
                isMobile={false}
                isCaptchaValid={captchaData.isCaptchaValid}
                captchaSource={captchaData.captchaSource}
                setCaptchaType={setCaptchaType}
                setCaptchaAnswer={setCaptchaAnswer}
                isSubmit={isSubmit}
                getCaptchaDetails={getCaptchaDetails}
                t={translate}
                captchaAnswer={captchaAnswer}
                email={mailToEmail}
              ></ShareJobCaptcha>
              <div className=" mt-20 text-center">
                <Button
                  id={idGenerator(
                    shareJobModalId as ModuleName,
                    "submitbutton",
                  ).generateId()}
                  size="medium"
                  label={t("jobsite.common.submit") as string}
                  color="primary"
                  blockedVariant={false}
                  onClick={onSubmit}
                ></Button>
              </div>
            </div>
          </Overlay>
        </div>
      ) : (
        showSuccessModal && (
          <Overlay
            id={idGenerator("shareJob", "modal").generateId()}
            wide={false}
            noCloseButton={false}
            visible={showSuccessModal}
            onClose={onCloseAfterSubmit}
            disableEsc={false}
            stickyClose={true}
            ariaLabel="share-job-header"
            disableClickAway={true}
            elementIdToFocus={idGenerator("shareJob", `${id}`).generateId(
              "button",
            )}
          >
            <div>
              <div className="text-center">
                <ShareJobAfterSubmitContent
                  success={emailSent}
                ></ShareJobAfterSubmitContent>
              </div>
            </div>
          </Overlay>
        )
      )}
    </>
  );
}
