import { GLOBAL_HEADER, INTERNAL_HEADER } from "../app.constants";

export const handleScrollToElement = (elementId: string, focus?: boolean) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth", // Enables smooth scroll
      block: "start", // Aligns the element at the top of the viewport
    });
    if (focus) {
      element.focus();
    }
  }
};

export const handleScrollTop = (isInternal: boolean) => {
  if (isInternal) {
    handleScrollToElement(INTERNAL_HEADER);
  } else {
    handleScrollToElement(GLOBAL_HEADER);
  }
};

/**
 * Scrolls the page to the first visible error field within a parent container.
 * The function looks for form fields (input, select, or textarea) within the
 * specified parent container that have the `.is-error` class and are not disabled.
 * @param parentID String that specifies from where code should start checking error fields
 */
export const scrollToFirstErrorField = (parentID: string) => {
  const parentContainer = document.getElementById(parentID);
  if (parentContainer) {
    const errorFields = parentContainer.querySelectorAll(
      ".is-error input, .is-error select, .is-error textarea",
    );
    const enabledErrorFields = Array.from(errorFields).filter((field) => {
      const element = field as
        | HTMLInputElement
        | HTMLSelectElement
        | HTMLTextAreaElement;
      return (
        element?.disabled !== true &&
        [null, "false"].includes(element?.ariaDisabled)
      );
    });
    if (enabledErrorFields.length > 0) {
      const firstErrorField = enabledErrorFields[0]?.id;
      if (firstErrorField) {
        document.getElementById(firstErrorField)?.focus();
      }
    }
  }
};

/**
 *
 * @param elemenId string which needs to be focused
 * @param focusTimer timer in ms after which focus will be triggered
 */
export const focusElement = (elemenId: string, focusTimer = 250) => {
  setTimeout(() => {
    const element = document.getElementById(elemenId);
    if (!element) return; // Returning if element with id doesnt present
    // If the elements tabIndex is < 0 which are not keyboard focusable
    // need to explicitly add tabindex as -1 for to trigger focus
    if (element.tabIndex < 0) element.setAttribute("tabindex", "-1");
    element.focus();
  }, focusTimer);
};

/**
 * @param itemsListRef ref element which points to <ul> element which holds buttons
 * @param indexToBeRemoved index which is going to be removed
 * @param buttonListLength button list length
 * @param elementIdToFocusOnRemoveOfLastButton element id string which used to focus on removal of last button
 * @param focusTimer timer in ms after which focus will be triggered
 */
export const focusManagementOnRemove = (
  itemsListRef: React.MutableRefObject<
    HTMLUListElement | HTMLDivElement | null
  >,
  indexToBeRemoved: number,
  buttonListLength: number,
  elementIdToFocusOnRemoveOfLastButton: string,
  focusTimer = 250,
) => {
  if (itemsListRef.current) {
    const selectedItemsButtons =
      itemsListRef.current.querySelectorAll("button");
    // If index to be removed (index+1) >= the length of the button list
    // Case 1: If index to be removed (index+1) >= the length of the button list is true: indexToFocus is index-1 because we need to focus the previous button
    // Case 2: If index to be removed (index+1) >= the length of the button list is false: indexToFocus is index because we need to focus the next button
    const indexToFocus =
      indexToBeRemoved + 1 >= selectedItemsButtons.length
        ? indexToBeRemoved - 1
        : indexToBeRemoved;
    if (selectedItemsButtons.length === 1) {
      // If only one button is present and if we are removing it, focus the selected count element as suggested by AX
      if (elementIdToFocusOnRemoveOfLastButton) {
        focusElement(elementIdToFocusOnRemoveOfLastButton, focusTimer);
      }
    } else {
      if (indexToFocus >= 0 && indexToFocus < buttonListLength) {
        //Accessing indexToFocus if present
        focusElement(selectedItemsButtons[indexToFocus].id, focusTimer);
      }
    }
  }
};

/**
 * Focus an element using a primary element and fallback elements
 * @param primaryElementId - primary element to focus
 * @param fallbackElementIds - array of elements to try if the primary is not found
 * @param delay - optional delay before attempting to focus
 */
export const focusElementWithFallback = (
  primaryElementId: string,
  fallbackElementIds: string[] = [],
  delay: number = 0,
) => {
  setTimeout(() => {
    // find an element matching the primary element id
    const element = primaryElementId
      ? (document.getElementById(primaryElementId) as HTMLElement | null)
      : null;
    if (element) {
      element.focus();
    } else {
      // if not found, iterate through fallback element ids
      for (const elementId of fallbackElementIds) {
        const fallbackElement = document.getElementById(
          elementId,
        ) as HTMLElement | null;
        if (fallbackElement) {
          fallbackElement.focus();
          break;
        }
      }
    }
  }, delay);
};
