import React, { useContext, useEffect } from "react";
import APP_CONSTANTS from "../../../utilities/appConstants";
import AppContext from "../../AppContext";
import SignedOut from "../../components/feature/signOut/SignedOut";
import useDtm from "../../hooks/useAdobeAnalytics";
import useIntlMessage from "../../hooks/useIntlMessage";
import useSetPageTitle, { PAGE_TITLES } from "../../hooks/useSetPageTitle";
import { redirectToPath } from "../../util";

export default function LogoutPage() {
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { locale, appBasePathPrefix } = appUIState.appData;
  const signIn = () => {
    redirectToPath(appBasePathPrefix, `app/${locale}/profile/info`);
  };
  const { trackPageLoad } = useDtm();
  const { setPageTitle } = useSetPageTitle();
  useEffect(() => {
    setPageTitle(PAGE_TITLES.SIGNED_OUT);
    const pageInfo = {
      pageName: APP_CONSTANTS.ANALYTICS.PAGE_NAME.LOGGED_OUT,
      locale: appUIState?.appData?.locale || APP_CONSTANTS.DEFAULT_LOCALE,
      title: APP_CONSTANTS.ANALYTICS.PAGE_NAME.LOGGED_OUT,
      isInternal: appUIState?.appData?.isInternal,
    };
    trackPageLoad(pageInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <main>
      <SignedOut
        title={t("jobsite.common.signedOut", { locale })}
        signoutMessage={t("jobsite.common.internalSignedOutInformation", {
          locale,
        })}
        linkedInMessage={t("jobsite.common.timeoutLinkedinAlert", { locale })}
      ></SignedOut>
      <div className={"pt-5 text-center"}>
        <a className="button" onClick={signIn}>
          {t("jobsite.search.signin", { locale }) as string}
        </a>
      </div>
    </main>
  );
}
