import { useCallback, useEffect, useRef } from "react";
import { ARIA_MESSAGE_TIMER } from "../app.constants";

export default function useAriaLiveStatus(
  spanElementRef: React.RefObject<HTMLSpanElement>,
  setTimeOutValue: number = ARIA_MESSAGE_TIMER,
) {
  const handlerRef = useRef<number | null>(null);
  const announceAriaMessage = useCallback(
    (message: string) => {
      if (spanElementRef.current) {
        spanElementRef.current.textContent = message;
        handlerRef.current = window.setTimeout(() => {
          if (spanElementRef.current) {
            spanElementRef.current.textContent = "";
          }
        }, setTimeOutValue);
      }
    },
    [spanElementRef, setTimeOutValue],
  );
  useEffect(() => {
    return () => {
      if (handlerRef.current) {
        clearTimeout(handlerRef.current);
      }
    };
  }, []);

  return { announceAriaMessage };
}
