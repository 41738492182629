import React, { Dispatch, SetStateAction, useState } from "react";
import { Email } from "../../../../shared/types/emailVerification";
import { EmailCodeGenerationModal } from "./emailCodeGeneration/emailCodeGenerationModal";
import { EmailCodeVerificationModal } from "./emailCodeVerification/emailCodeVerificationModal";

type EmailVerificationProps = {
  showEmailVerification: boolean;
  setShowEmailVerification: Dispatch<SetStateAction<boolean>>;
  onSuccess?: (emailId: string) => void;
};

export const EmailVerification = ({
  showEmailVerification,
  setShowEmailVerification,
  onSuccess,
}: EmailVerificationProps) => {
  const [showEmailReplaceModal, setShowEmailReplaceModal] = useState(
    showEmailVerification,
  );
  const [showEmailVerificationModal, setShowEmailVerificationModal] =
    useState(false);
  const [emailResponseData, setEmailResponseData] = useState({} as Email);
  const onEmailReplaceModalClose = () => {
    setShowEmailReplaceModal(false);
    setShowEmailVerification(false);
  };
  const onEmailVerificationModalClose = () => {
    setShowEmailVerificationModal(false);
    setShowEmailVerification(false);
  };
  const onEmailReplaceModalContinue = (data: Email) => {
    setEmailResponseData(data);
    if (data?.response?.preferredEmailVerified) {
      setShowEmailReplaceModal(false);
      setShowEmailVerification(false);
      if (onSuccess) {
        onSuccess(data.request.emailId);
      }
      return;
    }
    setShowEmailVerificationModal(true);
    setShowEmailReplaceModal(false);
  };
  const onEmailVerificationModalCancel = () => {
    setShowEmailReplaceModal(true);
    setShowEmailVerificationModal(false);
  };
  const onEmailVerificationModalContinue = () => {
    if (onSuccess) {
      onSuccess(emailResponseData.request.emailId);
    }
    onEmailVerificationModalClose();
  };
  return (
    <>
      <EmailCodeGenerationModal
        showModal={showEmailReplaceModal}
        setShowModal={onEmailReplaceModalClose}
        onContinue={onEmailReplaceModalContinue}
        onCancel={onEmailReplaceModalClose}
      />
      <EmailCodeVerificationModal
        showModal={showEmailVerificationModal}
        emailData={emailResponseData}
        onCancel={onEmailVerificationModalCancel}
        onClose={onEmailVerificationModalClose}
        onAction={onEmailVerificationModalContinue}
      />
    </>
  );
};
