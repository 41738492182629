import { random } from "lodash";
import { AppEducationDetails } from "../../../../../shared/types/talent/myApplication";
import { Education } from "../../../../../shared/types/talent/talent";

export interface FormField {
  id?: string;
  value?: string;
  label?: string;
  required: boolean;
  requiredError?: boolean;
  invalidError?: boolean;
}
export interface EducationDegreeForm {
  degree: FormField; //id maps to degree.degreeID, label maps to degree.degreeName
  school: FormField;
  major: FormField;
  gradStatus: FormField;
  key: number;
}

export interface AppEducationDegreeForm extends EducationDegreeForm {
  country: FormField;
  state: FormField;
}

export function getEducationDegreeForm(
  eduction?: Education,
): EducationDegreeForm {
  return {
    degree: {
      id: (eduction?.degreeID as string) || "",
      value: (eduction?.degreeID as string) || "",
      label: (eduction?.degreeName as string) || "",
      requiredError: false,
      required: true,
    },
    school: {
      id: (eduction?.schoolID as string) || "",
      value: (eduction?.schoolID as string) || "",
      label:
        (eduction?.school as string) || (eduction?.schoolName as string) || "",
      requiredError: false,
      required: true,
    },

    major: {
      id: (eduction?.majorFieldID as string) || "",
      value: (eduction?.majorFieldID as string) || "",
      label:
        (eduction?.majorField as string) ||
        (eduction?.majorFieldName as string) ||
        "",
      requiredError: false,
      required: true,
    },
    gradStatus: {
      id: (eduction?.graduationStatusID as string) || "",
      value: (eduction?.graduationStatusID as string) || "",
      label: (eduction?.graduationStatusName as string) || "",
      requiredError: false,
      required: true,
    },
    key: random(10000),
  };
}

export function getAppEducationDegreeForm(
  displayState?: boolean,
  education?: AppEducationDetails,
) {
  const form = getEducationDegreeForm(education);

  return {
    ...form,
    country: {
      id: (education?.countryID as string) || "",
      value: (education?.countryID as string) || "",
      label: (education?.country as string) || "",
      requiredError: false,
      required: true,
    },
    state: {
      id: (education?.state as string) || "",
      value: (education?.state as string) || "",
      label: (education?.state as string) || "",
      requiredError: false,
      required: displayState === true,
    },
  } as AppEducationDegreeForm;
}

export function getEducationPayload(
  educationDegreesForm: EducationDegreeForm[],
) {
  const payload = educationDegreesForm?.map(
    (education: EducationDegreeForm): Education =>
      getEducationDegree(education),
  );
  return payload?.length ? payload : [];
}

function getEducationDegree(eduForm: EducationDegreeForm) {
  return {
    degreeID: eduForm.degree.value,
    degreeName: eduForm.degree.label,
    graduationStatusID: eduForm.gradStatus.value,
    graduationStatusName: eduForm.gradStatus.label,
    majorFieldID: eduForm.major.value,
    majorFieldName: eduForm.major.label,
    majorField: eduForm.major.label,
    schoolID: eduForm.school.value,
    schoolName: eduForm.school.label,
    school: eduForm.school.label,
  } as Education;
}

export function getAppEducationPayload(
  educationDegreesForm: AppEducationDegreeForm[],
) {
  const payload = educationDegreesForm?.map(
    (education: AppEducationDegreeForm): AppEducationDetails => {
      const obj = getEducationDegree(education);
      return {
        ...obj,
        country: education.country.label,
        countryID: education.country.value,
        state: education.state.value,
      };
    },
  );
  return payload?.length ? payload : [];
}
