import { Questionnaire } from "../../../../shared/types";
import { SelfDisclosure } from "../../../../shared/types/talent/talent";
import { TalentConnection } from "../../../../shared/types/talentConnection";
import { FileData } from "../../../types/File";
import { LinkedInData } from "../../../types/LinkedIn";
import {
  TalentSupportingFile,
  TalentSupportingLink,
} from "../../../types/SupportingFileWidget";
import { ApplyAction } from "./ActionTypes";
import {
  PageState,
  ProfileUpdateSelection,
  SelectedLocations,
  SelfDisclosureConfig,
  Talent,
} from "./ApplyState";

export const stepAction = (data: PageState): ApplyAction => ({
  type: "STEP",
  payload: data,
});

export const locationsAction = (data: SelectedLocations): ApplyAction => ({
  type: "SELECTED_LOCATIONS",
  payload: data,
});

export const talentAction = (data: Talent): ApplyAction => ({
  type: "TALENT",
  payload: data,
});

export const talentConnectionAction = (
  data: TalentConnection,
): ApplyAction => ({
  type: "CONNECTION",
  payload: data,
});

export const discardFileAction = (data: TalentSupportingFile): ApplyAction => ({
  type: "DISCARD_FILE",
  payload: data,
});

export const resumeAction = (data: FileData): ApplyAction => ({
  type: "RESUME",
  payload: data,
});

export const textResumeAction = (data: FileData): ApplyAction => ({
  type: "TEXT_RESUME",
  payload: data,
});

export const discardResumeAction = (data: FileData): ApplyAction => ({
  type: "DISCARD_RESUME",
  payload: data,
});

export const profileUpdateSelectionAction = (
  data: ProfileUpdateSelection,
): ApplyAction => ({
  type: "PROFILE_UPDATE_SELECTION",
  payload: data,
});

export const supportingFileAction = (
  data: Array<TalentSupportingFile>,
): ApplyAction => ({
  type: "SUPPORTING_FILE",
  payload: data,
});

export const supportingFileCategoryAction = (
  key: string,
  value: any,
): ApplyAction => ({
  type: "SUPPORTING_FILE_CATEGORY",
  payload: { key, value },
});

export const supportingLinkAction = (
  data: Array<TalentSupportingLink>,
): ApplyAction => ({
  type: "SUPPORTING_LINK",
  payload: data,
});

export const linkedINAction = (data: LinkedInData): ApplyAction => ({
  type: "LINKEDIN",
  payload: data,
});

export const linkedINDiscardAction = (): ApplyAction => ({
  type: "LINKEDIN_DISCARD",
  payload: {},
});

export const selfDisclosureAction = (
  data: Record<string, SelfDisclosure>,
): ApplyAction => ({
  type: "SELF_DISCLOSURE",
  payload: data,
});

export const selfDisclosureCountryAction = (
  country: string,
  value: any,
): ApplyAction => ({
  type: "SELF_DISCLOSURE_COUNTRY",
  payload: { country, value },
});

export const selfDisclosureConfigAction = (
  data: SelfDisclosureConfig,
): ApplyAction => ({
  type: "SELF_DISCLOSURE_CONFIG",
  payload: data,
});

export const questionnaireAction = (data: Questionnaire): ApplyAction => ({
  type: "QUESTIONNAIRE",
  payload: data,
});

export const contactUpdateAction = (
  key: string,
  value: string,
): ApplyAction => ({
  type: "CONTACT_UPDATE",
  payload: { key, value },
});

export const addressUpdateAction = (data: any): ApplyAction => ({
  type: "ADDRESS_UPDATE",
  payload: data,
});
export const pronounsUpdateAction = (data: boolean): ApplyAction => ({
  type: "PRONOUNS_UPDATE",
  payload: data,
});
