import dateUtil from "@rpe-js/core/dist/util/dateUtil";
import { MultiLinDropdownOptionProps } from "@rpe-js/marcom-web-components";
import { cloneDeep } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { MyApplication } from "../../../../../../shared/types/talent/myApplication";
import {
  CountrySpecificSelfDisclosureConfig,
  Disability,
  SelfDisclosure,
} from "../../../../../../shared/types/talent/talent";
import { DEFAULT_LOCALE_API_HEADER } from "../../../../../app.constants";
import AppContext from "../../../../../AppContext";
import PrivacyPolicy from "../../../../../components/feature/PrivacyPolicy";
import {
  SelfDisclosureDisability,
  SelfDisclosureEthnicity,
  SelfDisclosureGender,
  SelfDisclosurePrivacyPolicy,
  SelfDisclosureVeteranStatus,
} from "../../../../../components/feature/selfDisclosure/SelfDisclosureFields";
import { SelfDisclosureWidget } from "../../../../../components/feature/selfDisclosure/SelfDisclosureWidget";
import { useSelfDisclosure } from "../../../../../components/feature/selfDisclosure/useSelfDisclosure";
import { useCurrentUserContext } from "../../../../../CurrentUserContext";
import useIntlMessage from "../../../../../hooks/useIntlMessage";
import { SelfDisclosureConfig } from "../../../../apply/context/ApplyState";
import { useMyApplicationContext } from "../../context/context";
import { MyApplicationActionDispatcher } from "../../context/state";
import { ReviewSection, SectionMode } from "../../context/types";
import { useUpdateApplication } from "../../hooks/createAndUpdateApplicationHooks";
import { SectionHeader } from "../SectionHeader";
import { SectionActions } from "./sectionActions";

interface SectionProps {
  id: string;
  onSave: (
    name: ReviewSection,
    mode: SectionMode,
    application: MyApplication,
  ) => void;
  mode: SectionMode;
  selfDisclosureConfig?: SelfDisclosureConfig | undefined;
  selfDisclosure: SelfDisclosure | undefined;
}

interface SelfDisclosureSecProps extends SectionProps {}

export function SelfDisclosureSection(props: SelfDisclosureSecProps) {
  const { id, mode, selfDisclosureConfig, selfDisclosure, onSave } = props;
  const { state, dispatch } = useMyApplicationContext();
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { locale } = appUIState.appData;
  const { selfDisclosureStep, application } = state;
  const { title, selfDisclosure: currentSelfDisclosure } = selfDisclosureStep;
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const { currentUser } = useCurrentUserContext();
  const {
    isUpdateApplicationLoading,
    isUpdateApplicationSuccess,
    updateApplication,
    updatedApplication,
  } = useUpdateApplication();
  const sectionName: ReviewSection = "selfDisclosure";
  const [showErrorInWidget, setShowErrorInWidget] = useState<boolean>(false);
  const [sd, setSD] = useState<SelfDisclosure>(
    cloneDeep(currentSelfDisclosure || (selfDisclosure as SelfDisclosure)),
  );
  const { onChange, validateSelfDisclosureData } = useSelfDisclosure();

  const onPrivacyModalClose = () => {
    setShowPrivacyModal(false);
  };

  const validateSelfDisclosure = useCallback(() => {
    return validateSelfDisclosureData(
      selfDisclosureConfig as CountrySpecificSelfDisclosureConfig,
      sd,
    );
  }, [sd, selfDisclosureConfig, validateSelfDisclosureData]);

  useEffect(() => {
    if (
      !isUpdateApplicationLoading &&
      isUpdateApplicationSuccess &&
      updatedApplication
    ) {
      MyApplicationActionDispatcher.onAppSecSelfDisclosureSave(dispatch, {
        selfDisclosure: sd,
        selfDisclosureEncrypted: updatedApplication.selfDisclosure,
      });
      onSave(sectionName, mode, updatedApplication as MyApplication);
    }
  }, [
    dispatch,
    isUpdateApplicationLoading,
    isUpdateApplicationSuccess,
    mode,
    onSave,
    sd,
    updatedApplication,
  ]);

  const onSectionSave = useCallback(async () => {
    if (validateSelfDisclosure()) {
      setShowErrorInWidget(true);
      return;
    }
    if (application && currentUser.talentId) {
      const requestBody = cloneDeep(application);
      requestBody.selfDisclosure = {
        ...sd,
      };
      await updateApplication(
        currentUser.talentId,
        application?.id,
        "selfdisclosure",
        requestBody as MyApplication,
      );
    }
  }, [
    application,
    currentUser.talentId,
    sd,
    updateApplication,
    validateSelfDisclosure,
  ]);

  const onCancel = useCallback(() => {
    MyApplicationActionDispatcher.onApplicationSectionCancel(
      dispatch,
      sectionName,
    );
    // Cancel is shown only if in edit layout.
    // REvert to view layout
  }, [dispatch]);

  const onSectionEdit = useCallback(() => {
    MyApplicationActionDispatcher.onApplicationSectionEdit(
      dispatch,
      sectionName,
    );
  }, [dispatch]);

  const onSelfDisclosureFieldChange = useCallback(
    (field: {
      type: string;
      option: MultiLinDropdownOptionProps | Disability;
    }) => {
      onChange(field, sd, setSD);
    },
    [onChange, sd],
  );

  const selfDisclosurePrivacyPolicy = useCallback(
    (label: string) => {
      return (
        <SelfDisclosurePrivacyPolicy
          label={label}
          value={
            t("jobsite.common.acknowledgedDate", {
              date: dateUtil.formatDateTimeByLocaleUtil(
                state?.talent?.privacyPolicy?.acknowledgedDate as number,
                locale,
                false,
              ),
            }) as string
          }
          openPrivacyModal={() => setShowPrivacyModal(true)}
          buttonId={`${id}-view-privacy-policy-button`}
        ></SelfDisclosurePrivacyPolicy>
      );
    },
    [locale, state?.talent?.privacyPolicy?.acknowledgedDate, t, id],
  );

  return (
    <SectionHeader id={id} title={title} mode={mode} onEdit={onSectionEdit}>
      {mode === "view" && (
        <>
          {selfDisclosureConfig && (
            <>
              <section className="t-body">
                <section className="row">
                  {selfDisclosureConfig.gender && (
                    <SelfDisclosureGender
                      showLabelBold={false}
                      value={sd?.genderName as string}
                    ></SelfDisclosureGender>
                  )}
                  {selfDisclosureConfig.ethnicity && sd?.ethnicityName && (
                    <SelfDisclosureEthnicity
                      showLabelBold={false}
                      value={sd.ethnicityName}
                    ></SelfDisclosureEthnicity>
                  )}
                </section>
                <section className="row">
                  {selfDisclosureConfig.disability && (
                    <SelfDisclosureDisability
                      showLabelBold={false}
                      value={sd?.disability?.statusName as string}
                    ></SelfDisclosureDisability>
                  )}
                  {selfDisclosureConfig.veteranStatus && (
                    <SelfDisclosureVeteranStatus
                      showLabelBold={false}
                      value={sd?.veteranStatus?.veteranStatusName as string}
                    ></SelfDisclosureVeteranStatus>
                  )}
                </section>
                <section className="d-flex">
                  <section className="flex-1"></section>
                  {selfDisclosurePrivacyPolicy(
                    t(
                      "jobsite.profile.myApplication.candidatePrivacyPolicy",
                    ) as string,
                  )}
                </section>
              </section>
            </>
          )}
        </>
      )}
      {showPrivacyModal && (
        <PrivacyPolicy
          showModal={showPrivacyModal}
          onCloseModal={onPrivacyModalClose}
          editMode={false}
          dataUsageConsentObj={state?.talent?.privacyPolicy?.dataConsent}
          triggerElementId={`${id}-view-privacy-policy-button`}
          disableFocusOut={false}
        ></PrivacyPolicy>
      )}
      {(mode === "edit" || mode === "create") && (
        <>
          <SelfDisclosureWidget
            showError={showErrorInWidget}
            locale={locale}
            dataLocale={DEFAULT_LOCALE_API_HEADER}
            noHeader={true}
            noActions={true}
            noLeftPadding={true}
            hideDisabilityCompletedOn={true}
            selfDisclosureConfig={
              selfDisclosureConfig as CountrySpecificSelfDisclosureConfig
            }
            selectedSelfDisclosure={sd}
            onSelfDisclosureFieldChange={onSelfDisclosureFieldChange}
            goPrevious={() => {}}
            moveNext={() => {}}
          ></SelfDisclosureWidget>
          {selfDisclosurePrivacyPolicy(
            t("jobsite.profile.myApplication.talentPrivacy") as string,
          )}
        </>
      )}
      {mode !== "view" && (
        <SectionActions
          onCancel={onCancel}
          onSave={onSectionSave}
          mode={mode}
        />
      )}
    </SectionHeader>
  );
}
