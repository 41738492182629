import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router";
import APP_CONSTANTS from "../../../utilities/appConstants";
import {
  ALERT_WRAPPER_ID,
  REFERRAL_TYPE_CORPORATE,
  STEP_NAME,
} from "../../app.constants";
import AppContext from "../../AppContext";
import { AlertWrapper } from "../../components/base/AlertWrapper";
import { StepProgress } from "../../components/base/StepProgress";
import useDtm from "../../hooks/useAdobeAnalytics";
import useIntlMessage from "../../hooks/useIntlMessage";
import useSetPageTitle from "../../hooks/useSetPageTitle";
import { handleScrollToElement } from "../../utils/focusUtil";
import { idGenerator } from "../../utils/idGenerator";
import { ContactInformationStep } from "./ContactInformationStep";
import { toggleReviewSubmitBtn, updateRoleList } from "./context/Actions";
import { useERPContext } from "./context/ERPContext";
import { ERP_PAGE_TITLES, StepName } from "./context/ErpState";
import { LocationCorporateStep } from "./Locations/LocationCorporateStep";
import { LocationRetailStep } from "./Locations/LocationRetailStep";
import { ReviewInformationStep } from "./ReviewInformationStep";
import { InterestCorporateStep } from "./Roles/InterestCorporateStep";
import { InterestRetailStep } from "./Roles/InterestRetailStep";
import { ThanksPageStep } from "./ThanksPageStep";

export function ErpStepWrapper() {
  const [showEntryMessage, setShowEntryMessage] = useState(false);
  const [entryMessage, setEntryMessage] = useState("");
  const [clientParams, setClientParams] = useSearchParams();
  const { state, dispatch } = useERPContext();
  const [step, setStep] = useState<string>(
    (state.page.firstStep as string) || StepName.CONTACT_INFO,
  );
  const { appUIState } = useContext(AppContext);
  const { trackPageLoad } = useDtm();
  const [currentStep, setCurrentStep] = useState(
    state.page.stepMapping?.[state.page.firstStep as StepName] || 1,
  );
  const { t } = useIntlMessage();
  const roleId = clientParams.get("roleId");
  const { setPageTitle } = useSetPageTitle();
  const ERP_CONTAINER_ID = idGenerator("erp", "container").generateId();
  const ERP_TITLE_ID = idGenerator("erp", "title").generateId();

  const checkForEntryMessage = useCallback(() => {
    if (roleId && step === StepName.CONTACT_INFO) {
      setShowEntryMessage(true);
      setEntryMessage(t("jobsite.erp.roleOfInterestBanner") as string);
    } else {
      setShowEntryMessage(false);
      setEntryMessage("");
    }
  }, [step, roleId, t]);

  const updateUrlParams = useCallback(
    (queryParam: string, value: string) => {
      const params = new URLSearchParams(clientParams);
      params.set(queryParam, value);
      setClientParams(params, { replace: true });
    },
    [clientParams, setClientParams],
  );

  useEffect(() => {
    updateUrlParams(STEP_NAME, state.page.stepNames[currentStep - 1]);
  }, [currentStep, state.page.stepNames, updateUrlParams]);

  useEffect(() => {
    const currentStepName = state.page.stepNames[currentStep - 1];
    let currentPageName = APP_CONSTANTS.ANALYTICS.ERP;
    if (currentStepName == StepName.CONTACT_INFO)
      currentPageName = APP_CONSTANTS.ANALYTICS.ERP_CONTACT;
    else if (currentStepName == StepName.INTEREST) {
      if (state.typeID === REFERRAL_TYPE_CORPORATE) {
        currentPageName = APP_CONSTANTS.ANALYTICS.ERP_INTEREST;
      } else {
        currentPageName = APP_CONSTANTS.ANALYTICS.ERP_ROLE;
      }
    } else if (currentStepName == StepName.LOCATION) {
      if (state.typeID === REFERRAL_TYPE_CORPORATE) {
        currentPageName = APP_CONSTANTS.ANALYTICS.ERP_CORP_LOCATION;
      } else {
        currentPageName = APP_CONSTANTS.ANALYTICS.ERP_STORE_LOCATION;
      }
    } else if (currentStepName == StepName.REVIEW_INFO)
      currentPageName = APP_CONSTANTS.ANALYTICS.ERP_REVIEWINFO;
    else if (currentStepName == StepName.THANKS_PAGE)
      currentPageName = APP_CONSTANTS.ANALYTICS.ERP_THANKS;

    const pageInfo = {
      pageName: currentPageName,
      locale: appUIState?.appData?.locale || APP_CONSTANTS.DEFAULT_LOCALE,
      title: APP_CONSTANTS.ANALYTICS.ERP,
      isInternal: appUIState?.appData?.isInternal,
    };
    if (currentStepName && currentStepName.length > 0) {
      trackPageLoad(pageInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, state.page.stepNames]);
  useEffect(() => {
    if (
      roleId &&
      !state.jobPositionIDs.includes(roleId) &&
      !isNaN(parseInt(roleId))
    ) {
      dispatch(updateRoleList([roleId.substring(0, 12)]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setTitle = useCallback(() => {
    setPageTitle(ERP_PAGE_TITLES[step], currentStep, 4);
  }, [step, setPageTitle, currentStep]);

  useEffect(() => {
    checkForEntryMessage();
    if (step === StepName.REVIEW_INFO) {
      dispatch(toggleReviewSubmitBtn(true));
    }
    setTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const nextStep = useCallback(
    (stepName: StepName) => {
      const stepNumber = state.page.stepMapping?.[stepName] as number;
      setStep(state.page.stepNames[stepNumber]);
      setCurrentStep(stepNumber + 1);
    },
    [state.page.stepMapping, state.page.stepNames],
  );

  const prevStep = useCallback(
    (stepName: StepName) => {
      const stepNumber = state.page.stepMapping?.[stepName] as number;
      setStep(state.page.stepNames[stepNumber - 2]);
      setCurrentStep(stepNumber - 1);
    },
    [state.page.stepMapping, state.page.stepNames],
  );

  const moveToNextStep = useCallback(() => {
    nextStep(step as StepName);
  }, [nextStep, step]);

  const goToPreviousStep = useCallback(() => {
    prevStep(step as StepName);
  }, [prevStep, step]);

  const goToStep = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (stepName: StepName, id?: string) => {
      setStep(stepName);
      setCurrentStep(state.page.stepMapping?.[stepName] as number);
      if (id) {
        setTimeout(() => {
          handleScrollToElement(id, true);
        }, 250);
      }
    },
    [state.page.stepMapping],
  );

  return (
    <>
      {showEntryMessage && (
        <AlertWrapper
          classes="hyperlink-underline"
          message={entryMessage}
          remove={{
            closeBtnAriaLabel: t("jobsite.common.closeAlert") as string,
            onRemove: () => setShowEntryMessage(false),
            idToFocus: ERP_TITLE_ID,
          }}
          id={ALERT_WRAPPER_ID.generateId("erp-entrymessage")}
        ></AlertWrapper>
      )}
      <section className="mt-30 pb-40" id={ERP_CONTAINER_ID}>
        {currentStep < state.page.stepNames.length && (
          <>
            <div className="erp-title text-center" id={ERP_TITLE_ID}>
              {t("jobsite.erp.title")}
            </div>
            <section className="m-20 text-center">
              <StepProgress
                id="erp-stepprogress"
                count={state.page.stepNames.length - 1}
                currStep={currentStep}
              ></StepProgress>
            </section>
          </>
        )}
        {step === StepName.CONTACT_INFO && (
          <ContactInformationStep
            moveNext={moveToNextStep}
            goToStep={goToStep}
          />
        )}
        {step === StepName.INTEREST && (
          <>
            {state.typeID !== REFERRAL_TYPE_CORPORATE && (
              <InterestRetailStep
                moveNext={moveToNextStep}
                goBack={goToPreviousStep}
                goToStep={goToStep}
              />
            )}
            {state.typeID === REFERRAL_TYPE_CORPORATE && (
              <InterestCorporateStep
                moveNext={moveToNextStep}
                goBack={goToPreviousStep}
                goToStep={goToStep}
              />
            )}
          </>
        )}
        {step === StepName.LOCATION && (
          <>
            {state.typeID === REFERRAL_TYPE_CORPORATE && (
              <LocationCorporateStep
                moveNext={moveToNextStep}
                goBack={goToPreviousStep}
                goToStep={goToStep}
              />
            )}
            {state.typeID !== REFERRAL_TYPE_CORPORATE && (
              <LocationRetailStep
                moveNext={moveToNextStep}
                goBack={goToPreviousStep}
                goToStep={goToStep}
              />
            )}
          </>
        )}
        {step === StepName.REVIEW_INFO && (
          <ReviewInformationStep
            goBack={goToPreviousStep}
            goToStep={goToStep}
          />
        )}
        {step === StepName.THANKS_PAGE && <ThanksPageStep />}
      </section>
    </>
  );
}
