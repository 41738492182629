import { NativeButton } from "@rpe-js/marcom-web-components";
import React from "react";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { Label } from "../../base/Label";
import { SelfDisclosureFieldView } from "./SelfDisclosureFieldView";

interface SelfDisclosureFieldProps {
  value: string;
  label?: string;
  showLabelBold?: boolean;
}

export function SelfDisclosureGender({
  value,
  showLabelBold,
}: SelfDisclosureFieldProps) {
  const { t } = useIntlMessage();
  return (
    <SelfDisclosureFieldView
      label={t("jobsite.common.genderHelpHeading") as string}
      value={value}
      showLabelBold={showLabelBold || false}
    ></SelfDisclosureFieldView>
  );
}

export function SelfDisclosureVeteranStatus({
  value,
  showLabelBold,
}: SelfDisclosureFieldProps) {
  const { t } = useIntlMessage();
  return (
    <SelfDisclosureFieldView
      label={t("jobsite.apply.protectedVeteran") as string}
      value={value}
      showLabelBold={showLabelBold || false}
    ></SelfDisclosureFieldView>
  );
}

export function SelfDisclosureEthnicity({
  value,
  showLabelBold,
}: SelfDisclosureFieldProps) {
  const { t } = useIntlMessage();
  return (
    <SelfDisclosureFieldView
      label={t("jobsite.apply.ethnicity") as string}
      value={value}
      showLabelBold={showLabelBold || false}
    ></SelfDisclosureFieldView>
  );
}

export function SelfDisclosureDisability({
  value,
  showLabelBold,
}: SelfDisclosureFieldProps) {
  const { t } = useIntlMessage();
  return (
    <SelfDisclosureFieldView
      label={t("jobsite.common.disabilityStatus") as string}
      value={value}
      showLabelBold={showLabelBold || false}
    ></SelfDisclosureFieldView>
  );
}

export function SelfDisclosureNationality({
  value,
  showLabelBold,
}: SelfDisclosureFieldProps) {
  const { t } = useIntlMessage();
  return (
    <SelfDisclosureFieldView
      label={t("jobsite.apply.nationality") as string}
      value={value}
      showLabelBold={showLabelBold || false}
    ></SelfDisclosureFieldView>
  );
}

interface SelfDisclosurePrivacyPolicyProps {
  label?: string;
  value: string;
  openPrivacyModal: (event: React.MouseEvent<HTMLButtonElement>) => void;
  buttonId: string;
}

export function SelfDisclosurePrivacyPolicy({
  value,
  label,
  openPrivacyModal,
  buttonId,
}: SelfDisclosurePrivacyPolicyProps) {
  const { t } = useIntlMessage();
  return (
    <>
      <section className="column large-6 small-12 pr-15 mb-10">
        <Label label={label as string} />
        <p>{value}</p>
        <div className="mt-0">
          <NativeButton
            id={buttonId}
            className="link more"
            onClick={openPrivacyModal}
            label={t("jobsite.common.view") as string}
          ></NativeButton>
        </div>
      </section>
    </>
  );
}
