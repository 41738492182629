// resumeUtils.ts
import React, { useCallback } from "react";
import { ALERT_WRAPPER_ID } from "../../app.constants";
import useIntlMessage from "../../hooks/useIntlMessage";
import { FileData } from "../../types/File";
import { focusElement } from "../../utils/focusUtil";
import {
  validateSupportFiles,
  validateSupportLinks,
} from "../../utils/validatorUtil";
import {
  discardResumeAction,
  resumeAction,
  textResumeAction,
} from "./context/Actions";
import { useApplyContext } from "./context/ApplyContext";
import { StepName } from "./context/ApplyState";
import { IProfileFillOptions } from "./ResumeStep";

// Hook to handle resume file uploads
export const useResumeFileHandler = (
  updateProfileOptions: (index: number, value: boolean) => void, // Function to update profile options visibility
  validateLinkedIn: () => boolean, // Function to validate LinkedIn profile
  validateTextResume: () => boolean, // Function to validate text resume
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>, // Function to set error messages
) => {
  const { state, dispatch } = useApplyContext(); // Access application context
  const { t } = useIntlMessage(); // Access internationalization function

  const resumeFileHandler = useCallback(
    (fileData: FileData) => {
      if (!fileData.name) {
        // Resume is discarded, store the previous resume state
        dispatch(discardResumeAction(state.resume as FileData));
      }
      // Update resume state with the new file data
      dispatch(resumeAction(fileData));

      // Update profile fill view based on file upload/removal
      if (fileData.fileId) {
        // File uploaded successfully
        setErrorMessage("");
        updateProfileOptions(0, true); // Show profile options
      } else if (fileData.name && !fileData.fileId) {
        // File name exists but no fileId (likely an error)
        setErrorMessage("");
        updateProfileOptions(0, true); // Show profile options (might need review)
      } else {
        // No file uploaded or an error occurred
        updateProfileOptions(0, false); // Hide profile options
      }

      // Check if any resume option is selected (file, LinkedIn, or text resume)
      if (!fileData.name && !validateLinkedIn() && !validateTextResume()) {
        // No resume option selected, show error message
        setErrorMessage(t("jobsite.common.resumeOptionError") as string);
      } else {
        setErrorMessage("");
      }
    },
    [
      dispatch,
      state.resume,
      t,
      updateProfileOptions,
      validateLinkedIn,
      validateTextResume,
      setErrorMessage,
    ],
  );

  return resumeFileHandler;
};

// Hook to update profile fill options visibility
export const useUpdateProfileOptions = (
  profileFillOptions: IProfileFillOptions[], // Current profile fill options
  setProfileFillOptions: React.Dispatch<
    React.SetStateAction<IProfileFillOptions[]>
  >, // Setter for profile fill options
  setCanShowProfileOptions: React.Dispatch<React.SetStateAction<boolean>>, // Setter to control profile options visibility
) => {
  const updateProfileOptions = useCallback(
    (index: number, showOption: boolean) => {
      const profileOptions = [...profileFillOptions];
      profileOptions[index].showOption = showOption;
      setProfileFillOptions([...profileOptions]);
      setCanShowProfileOptions(true);
    },
    [profileFillOptions, setCanShowProfileOptions, setProfileFillOptions],
  );
  return updateProfileOptions;
};

// Hook to validate LinkedIn profile
export const useValidateLinkedIn = () => {
  const { state } = useApplyContext();
  const validateLinkedIn = useCallback(() => {
    return !!(
      state.talent &&
      state.talent.externalProfiles &&
      state.talent.externalProfiles.linkedin
    ); // Returns true if LinkedIn profile exists
  }, [state.talent]);
  return validateLinkedIn;
};

// Hook to validate if a resume file is present
export const useValidateResume = () => {
  const { state } = useApplyContext();
  const validateResume = useCallback(() => {
    return !!(state.resume && state.resume.name); // Returns true if resume file exists
  }, [state.resume]);
  return validateResume;
};

// Hook to validate text resume
export const useValidateTextResume = () => {
  const { state } = useApplyContext();
  const validateTextResume = useCallback(() => {
    return !!(state.textResume && state.textResume.content); // Returns true if text resume content exists
  }, [state.textResume]);
  return validateTextResume;
};

// Hook to validate the entire resume file section (file, LinkedIn, or text resume)
export const useValidateResumeFileSection = () => {
  const validateTextResume = useValidateTextResume();
  const validateLinkedIn = useValidateLinkedIn();
  const validateResume = useValidateResume();
  const validateResumeFileSection = useCallback(() => {
    return validateResume() || validateLinkedIn() || validateTextResume(); // Returns true if any resume option is valid
  }, [validateLinkedIn, validateResume, validateTextResume]);

  return validateResumeFileSection;
};

// Hook to handle text resume input
export const useResumeTextHandler = (
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>, // Setter for error messages
  profileFillOptions: any, // Current profile fill options
  setProfileFillOptions: React.Dispatch<React.SetStateAction<any>>, // Setter for profile fill options
  setCanShowProfileOptions: React.Dispatch<React.SetStateAction<boolean>>, // Setter to control profile options visibility
) => {
  const { dispatch } = useApplyContext();
  const { t } = useIntlMessage();
  const validateLinkedIn = useValidateLinkedIn();
  const updateProfileOptions = useUpdateProfileOptions(
    profileFillOptions,
    setProfileFillOptions,
    setCanShowProfileOptions,
  );

  const resumeTextHandler = useCallback(
    (fileData: FileData) => {
      dispatch(textResumeAction(fileData));
      if (fileData.content) {
        // Text resume content exists
        setErrorMessage("");
        updateProfileOptions(0, true); // Show profile options
      } else {
        // No text resume content
        if (!validateLinkedIn()) {
          // No LinkedIn profile either, show error message
          setErrorMessage(t("jobsite.common.resumeOptionError") as string);
          updateProfileOptions(0, false); // Hide profile options
        }
      }
    },
    [dispatch, t, updateProfileOptions, validateLinkedIn, setErrorMessage],
  );
  return resumeTextHandler;
};

// Hook to handle moving to the next step in the application process
export const useMoveToNextStep = (
  setCanShowError: React.Dispatch<React.SetStateAction<boolean>>, // Setter to control error visibility
  setShowSupportingFileError: React.Dispatch<React.SetStateAction<boolean>>, // Setter to control error visibility for supporting file widget
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>, // Setter for error messages
  setFocusFirstErrorField: React.Dispatch<React.SetStateAction<boolean>>, // Setter to focus on the first error field
  goToStep: (step: StepName) => void, // Function to navigate to a specific step
  moveNext: () => void, // Function to move to the next step
) => {
  const { state } = useApplyContext();
  const { t } = useIntlMessage();
  const validateResumeFileSection = useValidateResumeFileSection();

  const moveToNextStep = useCallback(
    (reviewSubmit?: boolean) => {
      // Optional parameter to indicate review/submit action
      setCanShowError(true);
      setShowSupportingFileError(true);
      if (!validateResumeFileSection()) {
        // No valid resume option selected, show error and focus on the resume section
        setErrorMessage(t("jobsite.common.resumeOptionError") as string);
        focusElement(ALERT_WRAPPER_ID.generateId("file-resume"));
        return;
      }

      // Validate supporting files and links
      if (
        !validateSupportFiles(state.supportFiles) ||
        !validateSupportLinks(state.talent.links)
      ) {
        // Invalid supporting files or links, focus on the first error field
        setFocusFirstErrorField(true);
        return;
      }

      // Handle review/submit action
      if (reviewSubmit) {
        // Navigate to the appropriate step based on questionnaire error status
        if (!state.page.questionnaireError) {
          goToStep(StepName.REVIEW_INFO);
        } else {
          goToStep(StepName.QUESTIONNAIRE);
        }
        return;
      }

      // Move to the next step
      moveNext();
    },
    [
      goToStep,
      moveNext,
      state.page.questionnaireError,
      t,
      validateResumeFileSection,
      state.supportFiles,
      state.talent.links,
      setFocusFirstErrorField,
      setCanShowError,
      setShowSupportingFileError,
      setErrorMessage,
    ],
  );

  return moveToNextStep;
};
