import { DropDownOptionProps } from "@rpe-js/marcom-web-components";
import { cloneDeep } from "lodash";
import React, { useCallback } from "react";
import { ProficiencyLanguageRefData } from "../../../shared/types/refData";
import useIntlMessage from "../../hooks/useIntlMessage";
import { focusElementWithFallback } from "../../utils/focusUtil";
import { idGenerator } from "../../utils/idGenerator";
import { getRefData } from "../profile/information/components/utils";
import { EditSpokenLanguages } from "../profile/information/editSpokenLanguages";
import {
  getLanguageForm,
  LanguageForm,
} from "../profile/information/spokenLanguagesFormUtils";

type ParsedLanguagesProps = {
  languages: LanguageForm[];
  setLanguages: React.Dispatch<React.SetStateAction<LanguageForm[]>>;
  proficiencyLanguageRefData: ProficiencyLanguageRefData;
  showError: boolean;
};

export const ParsedLanguages = ({
  languages,
  setLanguages,
  proficiencyLanguageRefData,
  showError,
}: ParsedLanguagesProps) => {
  const { t } = useIntlMessage();
  const mode = "parsedmodal";
  const parsedModalLanguageIdGenerator = idGenerator(mode, "language");

  const validateDuplicateLanguages = useCallback(
    (languagesToValidate: LanguageForm[]) => {
      const languagesFormCopy = cloneDeep(languagesToValidate);
      languagesFormCopy.forEach((language: LanguageForm) => {
        const foundLanguages = languagesToValidate.filter(
          (lang) => language.languageID === lang.languageID,
        );
        if (foundLanguages.length > 1) {
          language.languageError = t("jobsite.information.duplicateMessage1", {
            language: language.languageName,
          }) as string;
        } else {
          language.languageError = "";
        }
      });
      setLanguages([...languagesFormCopy]);
    },
    [setLanguages, t],
  );

  const addLanguage = useCallback(
    (index: number) => {
      setLanguages([...languages, getLanguageForm()]);
      const newIndex = index + 1;
      focusElementWithFallback(
        parsedModalLanguageIdGenerator.generateId(`${newIndex}`),
      );
    },
    [languages, setLanguages, parsedModalLanguageIdGenerator],
  );

  const removeLanguage = useCallback(
    (index: number) => {
      const updatedLanguages = languages.filter((_, i) => i !== index);
      setLanguages(updatedLanguages);
      validateDuplicateLanguages(updatedLanguages);
      const newIndex = Math.min(index, updatedLanguages.length - 1);
      const addLanguageButtonId = idGenerator(mode, "add-language").generateId(
        `${newIndex}`,
      );
      const removeLanguageButtonId = idGenerator(
        mode,
        "remove-language",
      ).generateId(`${newIndex}`);
      focusElementWithFallback(addLanguageButtonId, [removeLanguageButtonId]);
    },
    [languages, setLanguages, validateDuplicateLanguages],
  );

  const handleValueChange = useCallback(
    (
      index: number,
      selectedValue: DropDownOptionProps | boolean,
      name: string,
    ) => {
      const languagesCopy: LanguageForm[] = languages;
      if (name === "language") {
        languagesCopy[index].languageID = (
          selectedValue as DropDownOptionProps
        ).value;
        languagesCopy[index].languageName = (
          selectedValue as DropDownOptionProps
        ).label;
        languagesCopy[index].languageError = "";
      } else if (name === "proficiency") {
        languagesCopy[index].proficiencyID = (
          selectedValue as DropDownOptionProps
        ).value;
        languagesCopy[index].proficiencyName = (
          selectedValue as DropDownOptionProps
        ).label;
      } else if (name === "preferredLanguage") {
        if (selectedValue === true) {
          languagesCopy.forEach((lang) => (lang.preferredLanguage = false));
        }
        languagesCopy[index].preferredLanguage = selectedValue as boolean;
      }
      setLanguages([...languagesCopy]);
      if (name === "language") {
        validateDuplicateLanguages(languagesCopy);
      }
    },
    [languages, setLanguages, validateDuplicateLanguages],
  );

  return (
    <EditSpokenLanguages
      mode={mode}
      languages={languages}
      addLanguage={addLanguage}
      removeLanguage={removeLanguage}
      langaugesData={getRefData(
        proficiencyLanguageRefData.language,
        t("jobsite.common.chooseLanguage") as string,
      )}
      proficiencyData={getRefData(
        proficiencyLanguageRefData.proficiency,
        t("jobsite.common.selectProficiency") as string,
      )}
      handleValueChange={handleValueChange}
      showError={showError}
    />
  );
};
