import { SavedSearchType } from "../../../../../shared/types/savedSearchTypes";
import { SavedSearchesAction } from "./ActionTypes";
import { SavedSearchesState } from "./SavedSearchesState";

export const reducer = (
  state: SavedSearchesState,
  action: SavedSearchesAction,
): SavedSearchesState => {
  switch (action.type) {
    case "SET_SAVED_SEARCHES": {
      const editSearch: Record<string, boolean> = {};
      action.payload?.forEach((search: SavedSearchType) => {
        editSearch[search.id] = false;
      });
      return {
        ...state,
        savedSearches: [...action.payload],
        editSavedSearches: editSearch,
        refreshData: false,
      };
    }
    case "SET_EMAIL_REF_DATA":
      return {
        ...state,
        ...action.payload,
      };
    case "DELETE_SAVED_SEARCH":
      return {
        ...state,
        savedSearches: [...action.payload],
      };
    case "UPDATE_SHOW_EMAIL_ALERT_MODAL":
      return {
        ...state,
        showEmailAlertModal: action.payload,
      };
    case "UPDATE_SHOW_REMOVE_SEARCH_MODAL":
      return {
        ...state,
        showRemoveSearchModal: action.payload,
      };
    case "UPDATE_SEARCH_TO_REMOVE":
      return {
        ...state,
        searchToRemove: action.payload,
      };
    case "UPDATE_EDIT_SEARCH":
      return {
        ...state,
        editSavedSearches: {
          ...state.editSavedSearches,
          [action.payload.id]: action.payload.edit,
        },
      };
    case "SET_REFRESH_DATA":
      return {
        ...state,
        refreshData: action.payload,
      };
    case "UPDATE_EMAIL_ALERT_MODAL_FOR_ID":
      return {
        ...state,
        emailAlertModalForId: action.payload,
      };
    default:
      return state;
  }
};
