import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import Icon, { IconProps } from "./IconComponent";

export interface RouterLinkProps {
  text: string;
  id?: string;
  link?: string;
  type?: "inline" | "standalone" | "more" | "less";
  classes?: string;
  ariaLabel?: string;
  suffixIcon?: IconProps;
  state?: any; // Default type any as per documentation
}

const RouterLinkComponent = ({
  text,
  link = "#",
  type = "inline",
  classes = "",
  ariaLabel = "",
  suffixIcon,
  id,
  state,
}: RouterLinkProps) => {
  const types = {
    inline: "link-inline",
    standalone: "link-standalone",
    more: "link more",
    less: "icon-before icon-chevronleft",
  };
  let customCssNames = `${types[type]}`;
  if (classes) {
    customCssNames = `${customCssNames} ${classes}`;
  }

  function getSuffixIcon() {
    if (suffixIcon?.name) {
      return <Icon name={suffixIcon.name} size={suffixIcon.size}></Icon>;
    }
    return null;
  }

  return (
    <>
      <Link
        id={id}
        to={link}
        className={customCssNames}
        aria-label={ariaLabel || text}
        state={state}
      >
        {text}
        {getSuffixIcon()}
      </Link>
    </>
  );
};

RouterLinkComponent.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.oneOf(["inline", "standalone", "more", "less"]),
};

export default RouterLinkComponent;
