import React, { useEffect, useRef } from "react";

const HtmlRendererWithScripts = ({
  initialContent,
  nc,
}: {
  initialContent: string;
  nc: string;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      // Find all <script> tags in the injected HTML
      const scripts = container.querySelectorAll("script");

      // Create an array to hold the new scripts
      const newScripts: any = [];

      scripts.forEach((oldScript) => {
        const newScript = document.createElement("script");

        // Copy attributes (e.g., src, type) from the old script
        Array.from(oldScript.attributes).forEach((attr) =>
          newScript.setAttribute(attr.name, attr.value),
        );

        newScript.setAttribute("nonce", nc);

        // Copy inline script content
        if (oldScript.textContent) {
          newScript.textContent = oldScript.textContent;
        }

        // Replace the old script with the new one
        oldScript.parentNode?.replaceChild(newScript, oldScript);

        // Add the new script to the array to clean up later
        newScripts.push(newScript);
      });

      // Cleanup function to remove the added scripts when the component unmounts
      return () => {
        newScripts.forEach((newScript: any) => {
          newScript.remove();
        });
      };
    }
  }, [initialContent, nc]); // Run whenever the HTML content changes
  return (
    <div
      ref={containerRef}
      dangerouslySetInnerHTML={{
        __html: initialContent,
      }}
    />
  );
};

export default HtmlRendererWithScripts;
