import formatUrl from "@rpe-js/core/dist/util/formatUrl";
import React from "react";
import { Location } from "react-router";
import LocaleAwareLink from "../../components/feature/LocaleAwareLink";
import { useBackToSearchUrl } from "../../hooks/useBackToSearchUrl";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { idGenerator } from "../../utils/idGenerator";
import JobDetailHeaderActions from "./JobDetailHeaderActions";
import JobDetailHeaderIcons from "./JobDetailHeaderIcons";

interface JobDetailFooterProps {
  jobData: any;
  handleFavoriteChange: (isFavorited: boolean) => void;
  id?: string;
  handleShareJob: (data: any) => void;
  handleSubmitResume: (data: any) => void;
  previousLocation?: Location;
}

export default function JobDetailFooter({
  handleFavoriteChange,
  handleShareJob,
  handleSubmitResume,
  jobData,
  id,
  previousLocation,
}: JobDetailFooterProps) {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const idGeneratorContainer = idGenerator("jobdetails", id || "");
  const { backToSearchUrl } = useBackToSearchUrl({ previousLocation });
  const renderFindMoreJobs = ({
    urlName,
    name,
    code,
  }: {
    urlName: string;
    name: string;
    code: string;
  }) => {
    const title = urlName || name;
    const locationParam = `${formatUrl(title.trim())}-${code}`;

    return (
      <LocaleAwareLink
        id={idGeneratorContainer.generateId("findmore")}
        link={`search?location=${locationParam}`}
        type="more"
        text={t("jobsite.search.findMore", { location: name }) as string}
      ></LocaleAwareLink>
    );
  };

  const { localeLocation = [] } = jobData;
  const findMoreJobs =
    localeLocation && localeLocation.length
      ? renderFindMoreJobs(localeLocation[0])
      : null;

  return (
    <div className={`${isMobile ? "py-30" : "p-30"} text-center`}>
      <div>
        <JobDetailHeaderActions
          id={idGeneratorContainer.generateId("actions")}
          jobData={jobData}
          handleSubmitResume={handleSubmitResume}
        ></JobDetailHeaderActions>
      </div>
      <div className={"mx-20 mt-20"}>
        <LocaleAwareLink
          id={idGeneratorContainer.generateId("backtosearch")}
          link={backToSearchUrl}
          type="less"
          text={t("jobsite.jobdetails.backSearch") as string}
        ></LocaleAwareLink>
      </div>
      <div className={"p-10"}>
        <JobDetailHeaderIcons
          id={idGeneratorContainer.generateId("icons-footer")}
          handleShareJob={handleShareJob}
          handleFavoriteChange={handleFavoriteChange}
          jobData={jobData}
        ></JobDetailHeaderIcons>
      </div>
      <div className={"mb-30"}>{findMoreJobs}</div>
    </div>
  );
}
