import { formatDateTimeByLocaleUtil } from "@rpe-js/core/dist/util/dateUtil";
import { Button, TextButton } from "@rpe-js/marcom-web-components";
import { cloneDeep } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  HiringManager,
  ProductLine,
  RetailRole,
  SavedSearchLanguage,
  SavedSearchType,
  SubTeam,
} from "../../../../shared/types/savedSearchTypes";
import { updateSavedSearch } from "../../../api/fetchClient";
import AppContext from "../../../AppContext";
import { Info } from "../../../components/base/Info";
import { Label } from "../../../components/base/Label";
import SeparatorComponent from "../../../components/base/SeparatorComponent";
import { SaveCancelAction } from "../../../components/feature/saveAndCancel";
import { useAppAlertContext } from "../../../contexts/AppAlert";
import { useCurrentUserContext } from "../../../CurrentUserContext";
import { useFetchData } from "../../../hooks/useFetchData";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { focusElement } from "../../../utils/focusUtil";
import { idGenerator } from "../../../utils/idGenerator";
import { setRefreshData, updateEditSearch } from "./context/Actions";
import { useSavedSearchesContext } from "./context/SavedSearchesContext";
import EditSavedSearch from "./EditSavedSearch";

type SavedSearchesRightPanelProps = {
  onEmailInfoClick: () => void;
  onRemove: () => void;
  search: SavedSearchType;
};

const SavedSearchesRightPanel = ({
  onEmailInfoClick,
  onRemove,
  search,
}: SavedSearchesRightPanelProps) => {
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { locale } = appUIState.appData;
  const { currentUser } = useCurrentUserContext();
  const isMobile = useIsMobile();
  const { updateAlert, deleteAlert } = useAppAlertContext();
  const [searchData, setSearchData] = useState<SavedSearchType>(
    cloneDeep(search),
  );
  const {
    state: { editSavedSearches },
    dispatch,
  } = useSavedSearchesContext();
  const {
    isLoading: isUpdateSearchLoading,
    isSuccess: isUpdateSearchSuccess,
    fetchData: updateSearch,
    isError: isUpdateSearchError,
  } = useFetchData(updateSavedSearch);

  const onCancel = useCallback(() => {
    setSearchData(cloneDeep(search));
    dispatch(updateEditSearch(search.id, false));
    focusElement(`edit-${search.id}`);
  }, [dispatch, search]);

  const onSave = useCallback(() => {
    deleteAlert();
    const payload = {
      emailAlertFrequency: {
        freqID: searchData.emailAlertFrequency.freqID,
        emailAlertFrequency: searchData.emailAlertFrequency.emailAlertFrequency,
      },
      emailAlertExpiration: {
        expID: searchData.emailAlertExpiration.expID,
        emailAlertExpiration:
          searchData.emailAlertExpiration.emailAlertExpiration,
      },
    };
    updateSearch([currentUser.talentId, search.id, payload]);
  }, [
    currentUser.talentId,
    deleteAlert,
    search.id,
    searchData.emailAlertExpiration.emailAlertExpiration,
    searchData.emailAlertExpiration.expID,
    searchData.emailAlertFrequency.emailAlertFrequency,
    searchData.emailAlertFrequency.freqID,
    updateSearch,
  ]);

  // To dispatch actions once update saved search is successful
  useEffect(() => {
    if (!isUpdateSearchLoading && isUpdateSearchSuccess) {
      dispatch(setRefreshData(true));
      dispatch(updateEditSearch(search.id, false));
      focusElement(`edit-${search.id}`);
    }
    if (!isUpdateSearchLoading && isUpdateSearchError) {
      updateAlert(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateSearchLoading, isUpdateSearchSuccess, isUpdateSearchError]);

  return (
    <>
      {!editSavedSearches[search?.id] && (
        <div
          className={`row mb-10 pb-10${!isMobile ? " u-border-bottom" : ""}`}
        >
          <div className={`column large-6 small-12${isMobile ? " mb-10" : ""}`}>
            <div className="d-flex flex-column">
              <div className="d-flex">
                <Label
                  label={t("jobsite.savedsearch.emailalertfreqency") as string}
                />
                <span className="t-body-reduced align-self-center label-grey">
                  <TextButton
                    id={idGenerator("savedsearches", "edit").generateId(
                      `email-info-button-${search.id}`,
                    )}
                    className="ml-5"
                    icon="icon-infocircle"
                    onClick={onEmailInfoClick}
                    aria-label={`${t("jobsite.savedsearch.emailalertInfo")} ${search.searchName}`}
                  />
                </span>
              </div>
              <Info value={search.emailAlertFrequency?.emailAlertFrequency} />
              <p className="label-grey mt-10">
                {`${t(
                  search.expiryFlag
                    ? "jobsite.savedsearch.expired"
                    : "jobsite.savedsearch.expires",
                )}: ${search.expiryDate && formatDateTimeByLocaleUtil(search.expiryDate.toString(), locale, false)}`}
              </p>
            </div>
          </div>
          <div className="column large-6 small-12">
            <div className="d-flex flex-column">
              <Label
                label={t("jobsite.savedsearch.emailexpiration") as string}
              />
              <Info value={search.emailAlertExpiration?.emailAlertExpiration} />
            </div>
          </div>
        </div>
      )}
      {editSavedSearches[search?.id] && (
        <EditSavedSearch
          searchData={searchData}
          setSearchData={setSearchData}
        />
      )}
      <div className="row row-gap-10">
        {search.searchQuery?.length > 0 && (
          <div className="d-flex flex-column large-6 small-12">
            <Label label={t("jobsite.savedsearch.searchterm") as string} />
            <Info value={search.searchQuery} />
          </div>
        )}
        {(search.teams?.length as number) > 0 && (
          <div className="d-flex flex-column large-6 small-12">
            <Label label={t("jobsite.savedsearch.teams") as string} />
            {search.teams?.map((team, index: number) => (
              <div className="d-flex flex-column mt-5" key={index}>
                <Label
                  label={`${t("jobsite.common.teamName", {
                    teamName: team.teamName,
                  })}`}
                  setAsHtml={true}
                />
                <SeparatorComponent
                  data={team.subTeams}
                  displayValue={(subTeam: SubTeam) => subTeam.subTeamName}
                />
              </div>
            ))}
          </div>
        )}
        {(search.savedSearchLocations?.length as number) > 0 && (
          <div className="d-flex flex-column large-6 small-12">
            <Label label={t("jobsite.common.locations") as string} />
            {search.savedSearchLocations?.map((location, index: number) => (
              <Info value={location.location} key={index} />
            ))}
          </div>
        )}
        {search.homeOffice && (
          <div className="d-flex flex-column large-6 small-12">
            <Label label={t("jobsite.jobdetails.homeOffice") as string} />
            <Info value={t("jobsite.common.yes") as string} />
          </div>
        )}
        {(search.productLines?.length as number) > 0 && (
          <div className="d-flex flex-column large-6 small-12">
            <Label label={t("jobsite.search.productAndServices") as string} />
            <SeparatorComponent
              data={search.productLines}
              displayValue={(product: ProductLine) =>
                product.productLineName as string
              }
            />
          </div>
        )}
        {(search.keywords?.length as number) > 0 && (
          <div className="d-flex flex-column large-6 small-12">
            <Label label={t("jobsite.savedsearch.keyword") as string} />
            <SeparatorComponent
              data={search.keywords}
              displayValue={(value: string) => value}
            />
          </div>
        )}
        {(search.savedSearchLanguages?.length as number) > 0 && (
          <div className="d-flex flex-column large-6 small-12">
            <Label label={t("jobsite.savedsearch.languages") as string} />
            <SeparatorComponent
              data={search.savedSearchLanguages}
              displayValue={(language: SavedSearchLanguage) =>
                language.language
              }
            />
          </div>
        )}
        {search.retailWeeklyHoursMin && (
          <div className="d-flex flex-column large-6 small-12">
            <Label label={t("jobsite.search.retailWeeklyMinHours") as string} />
            <Info value={search.retailWeeklyHoursMin} />
          </div>
        )}
        {search.retailWeeklyHoursMax && (
          <div className="d-flex flex-column large-6 small-12">
            <Label label={t("jobsite.search.retailWeeklyMaxHours") as string} />
            <Info value={search.retailWeeklyHoursMax} />
          </div>
        )}
        {(search.hiringManagers?.length as number) > 0 && (
          <div className="d-flex flex-column large-6 small-12">
            <Label label={t("jobsite.search.hiringManager") as string} />
            <SeparatorComponent
              data={search.hiringManagers}
              displayValue={(hiringManager: HiringManager) =>
                `${hiringManager.name}${hiringManager.costCenter ? ", " + hiringManager.costCenter : ""}${hiringManager.location ? ", " + hiringManager.location : ""}`
              }
            />
          </div>
        )}
        {(search.retailRoles?.length as number) > 0 && (
          <div className="d-flex flex-column large-6 small-12">
            <Label label={t("jobsite.search.retailHrs") as string} />
            <SeparatorComponent
              data={search.retailRoles}
              displayValue={(retailRole: RetailRole) => retailRole.roleName}
            />
          </div>
        )}
        {search.jobLevel && (
          <div className="d-flex flex-column large-6 small-12">
            <Label label={t("jobsite.search.jobLevel") as string} />
            <div>
              {search.jobLevel.myLevel && (
                <Info value={t("jobsite.search.myJobLevel") as string} />
              )}
              {search.jobLevel.myLevel && search.jobLevel.myLevelPlusOne && (
                <span>,&nbsp;</span>
              )}
              {search.jobLevel.myLevelPlusOne && (
                <Info value={t("jobsite.search.myJobLevel1") as string} />
              )}
            </div>
          </div>
        )}
      </div>
      {isMobile && (
        <div className="mt-35">
          <Button
            size="base"
            id={`remove-${search?.id}`}
            color="secondary"
            blockedVariant={true}
            label={t("jobsite.common.remove") as string}
            onClick={onRemove}
          />
        </div>
      )}
      {editSavedSearches[search?.id] && (
        <SaveCancelAction
          onCancel={onCancel}
          onSave={onSave}
          cancelLabelName={t("jobsite.common.cancel") as string}
          saveLabelName={t("jobsite.common.save") as string}
          cancelButtonId={idGenerator("savedsearches", "edit").generateId(
            "cancel-button",
          )}
          saveButtonId={idGenerator("savedsearches", "edit").generateId(
            "save-button",
          )}
        />
      )}
    </>
  );
};

export default SavedSearchesRightPanel;
