import { ProgressIndicatorLoader } from "@rpe-js/marcom-web-components";
import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import {
  Legal,
  MyApplication,
} from "../../../../../shared/types/talent/myApplication";
import { useCurrentUserContext } from "../../../../CurrentUserContext";
import useSetPageTitle, {
  PAGE_TITLES,
} from "../../../../hooks/useSetPageTitle";
import { handleScrollToElement } from "../../../../utils/focusUtil";
import { ActionContainer } from "../ActionContainer";
import { ContactInfoSection } from "../components/sections/contactInfo";
import { EducationSection } from "../components/sections/education";
import { EmployeeBackgroundSection } from "../components/sections/employeeBackground";
import { LegalSection } from "../components/sections/legal";
import { ProfessionalExperienceSection } from "../components/sections/professionalExperience";
import { ReferenceSection } from "../components/sections/references";
import { useMyApplicationContext } from "../context/context";
import { MyApplicationActionDispatcher } from "../context/state";
import { SectionMode } from "../context/types";
import { useUpdateApplication } from "../hooks/createAndUpdateApplicationHooks";
import { useFetchApplicationStepData } from "../hooks/useFetchApplicationStepData";
import { useGotoNextStep } from "../hooks/useGotoNextStep";
import { useSectionIdGenerator } from "../hooks/useSectionIdGenerator";
import { getPageTitle } from "../utils";
// Common
// Open next section with scroll to
// Section shows Save and continue in application
// On Save and continue , next section opens with scroll to.
// Section show save and cancel in review
//  On save , Scroll to same section in view mode.
// Section validation happens on save and save and continue.
// On cancel
// Contact Information
// Section has top border
// Expanded/Collapsed.
// View , Edit, Create
// View -> Edit Action
// Create -> Save & Continue
// Edit -> Save & Cancel
// OnSave & Save&Continue => Validate and show errors or  make api calls.
// Employment Background
// Education
// Professional Experience
// References
// Legal

// function SectionContainer() {
//   <form>
//     <fieldset>
//       <legend></legend>
//       <h2></h2>
//       <div>Section Elements here </div>
//     </fieldset>
//   </form>;
// }

type ApplicationSection =
  | "contactInformation"
  | "employmentBackground"
  | "education"
  | "professionalExperience"
  | "references"
  | "legal";

export function ApplicationStep() {
  const { state, dispatch } = useMyApplicationContext();
  const { currentUser } = useCurrentUserContext();
  const { legalSection, application } = state;
  const goToNextStep = useGotoNextStep();
  const { applicationStep, submitted, currentStep, page } = state;
  const { setPageTitle } = useSetPageTitle();
  const {
    //isUpdateApplicationError,
    isUpdateApplicationLoading,
    isUpdateApplicationSuccess,
    updateApplication,
    resetUpdateApplication,
    updatedApplication,
  } = useUpdateApplication();

  const {
    //applicationStepDataError,
    applicationStepRefData,
    //isApplicationStepDataError,
    isApplicationStepDataLoading,
    isApplicationStepDataSuccess,
    fetchApplicationStepData,
  } = useFetchApplicationStepData();

  useEffect(() => {
    setPageTitle(
      PAGE_TITLES.YOUR_APPLICATION.CONTACT_INFO,
      currentStep ? page.stepMapping[currentStep] : "",
      page.stepNames.length,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isApplicationStepDataLoading && !applicationStepRefData) {
      fetchApplicationStepData();
    }
    if (!applicationStep.refData && applicationStepRefData) {
      MyApplicationActionDispatcher.setAppSecRefData(
        dispatch,
        applicationStepRefData,
      );
    }
  }, [
    fetchApplicationStepData,
    isApplicationStepDataLoading,
    applicationStepRefData,
    applicationStep,
    dispatch,
  ]);

  const {
    contactInfoSectionId,
    eduSectionId,
    empBckSectionId,
    profExpSectionId,
    refSectionId,
    legSectionId,
  } = useSectionIdGenerator("application");

  const sectionMap = useMemo(() => {
    return {
      [contactInfoSectionId]: empBckSectionId,
      [empBckSectionId]: eduSectionId,
      [eduSectionId]: profExpSectionId,
      [profExpSectionId]: refSectionId,
      [refSectionId]: legSectionId,
    };
  }, [
    contactInfoSectionId,
    eduSectionId,
    empBckSectionId,
    profExpSectionId,
    refSectionId,
    legSectionId,
  ]);

  const {
    contactInformation,
    employmentBackground,
    education,
    professionalExperience,
    references,
    legal,
  } = applicationStep;

  const onSectionSave = useCallback(
    (
      sectionName: ApplicationSection,
      mode: SectionMode,
      application: MyApplication,
    ) => {
      MyApplicationActionDispatcher.onApplicationSectionSave(
        dispatch,
        sectionName,
        mode,
        application,
      );
      const pageTitle = getPageTitle(sectionName);
      setTimeout(() => {
        handleScrollToElement(
          sectionMap[`profileApplication-application-${sectionName}`],
        );
      }, 0);
      setPageTitle(
        pageTitle,
        currentStep ? page.stepMapping[currentStep] : "",
        page.stepNames.length,
      );
    },
    [
      currentStep,
      dispatch,
      page.stepMapping,
      page.stepNames.length,
      sectionMap,
      setPageTitle,
    ],
  );

  useEffect(() => {
    if (
      !isUpdateApplicationLoading &&
      isUpdateApplicationSuccess &&
      updatedApplication
    ) {
      MyApplicationActionDispatcher.onApplicationStepSave(
        dispatch,
        updatedApplication,
      );
      resetUpdateApplication();
      goToNextStep("next");
    }
  }, [
    dispatch,
    isUpdateApplicationLoading,
    isUpdateApplicationSuccess,
    updatedApplication,
    resetUpdateApplication,
    goToNextStep,
  ]);

  const onApplicationStepSave = useCallback(async () => {
    if (application && currentUser.talentId) {
      const requestBody = cloneDeep(application);
      requestBody.legal = legalSection.legal as Legal;
      await updateApplication(
        currentUser.talentId,
        application?.id,
        "legal",
        requestBody as MyApplication,
      );
    }
  }, [application, legalSection, currentUser, updateApplication]);

  return (
    <>
      {isApplicationStepDataLoading && (
        <ProgressIndicatorLoader showLoading={true} />
      )}
      {!isApplicationStepDataLoading &&
        isApplicationStepDataSuccess &&
        applicationStep.refData && (
          <>
            <ul role="list" className="list-nobullet w-100 u-border-bottom">
              <li role="listitem">
                <ContactInfoSection
                  id={contactInfoSectionId}
                  mode={contactInformation}
                  onSave={onSectionSave}
                />
              </li>
              <li role="listitem">
                <EmployeeBackgroundSection
                  id={empBckSectionId}
                  mode={employmentBackground}
                  onSave={onSectionSave}
                />
              </li>
              <li role="listitem">
                <EducationSection
                  id={eduSectionId}
                  mode={education}
                  onSave={onSectionSave}
                />
              </li>
              <li role="listitem">
                <ProfessionalExperienceSection
                  id={profExpSectionId}
                  mode={professionalExperience}
                  onSave={onSectionSave}
                />
              </li>
              <li role="listitem">
                <ReferenceSection
                  id={refSectionId}
                  mode={references}
                  onSave={onSectionSave}
                />
              </li>
              <li role="listitem">
                <LegalSection
                  id={legSectionId}
                  mode={legal}
                  onSave={onSectionSave}
                />
              </li>
            </ul>
            {!submitted && (
              <ActionContainer
                saveDisabled={!state.applicationStep.canSave}
                onSave={() => onApplicationStepSave()}
                onBack={() => goToNextStep("prev")}
                onSubmit={() => {}}
              />
            )}
          </>
        )}
    </>
  );
}
