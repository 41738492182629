import React, { useContext } from "react";
import AppContext from "../../AppContext";
import HtmlRenderer from "../../components/base/HtmlRenderer";
import useIntlMessage from "../../hooks/useIntlMessage";

interface SelfDisclosureHeaderProps {
  isUSAIndicator: boolean;
  countryCode: string;
}
export function SelfDisclosureHeader({
  isUSAIndicator,
  countryCode,
}: SelfDisclosureHeaderProps) {
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { locale } = appUIState.appData;
  const translationKey = "jobsite.apply.";
  const countryMessage = t(
    translationKey + "disclosureDesc_" + countryCode,
  ) as string;
  let headerMessage = "";
  if (countryMessage && !countryMessage.startsWith("jobsite.")) {
    headerMessage = countryMessage;
  } else {
    headerMessage = t(translationKey + "disclosureDesc_DEFAULT") as string;
  }
  return (
    <>
      {isUSAIndicator && (
        <h2 className="t-eyebrow-reduced">
          {t("jobsite.apply.disclosureHeading", { locale })}
        </h2>
      )}
      <section className={`t-body-reduced ${isUSAIndicator ? "mt-10" : ""}`}>
        <HtmlRenderer
          classes="hyperlink-underline"
          initialContent={headerMessage}
        ></HtmlRenderer>
      </section>
    </>
  );
}
