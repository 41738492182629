import React, { ReactNode } from "react";
import APP_CONSTANTS from "../../../utilities/appConstants";
import useIntlMessage from "../../hooks/useIntlMessage";

export interface GlobalErrorProps {
  hasError?: boolean;
  children?: ReactNode;
  isInternal: boolean;
}

export default function GlobalError({
  hasError = false,
  children,
  isInternal,
}: GlobalErrorProps) {
  const { t } = useIntlMessage();

  // Function to get error message that needs to show
  const errorMsg = () => {
    let errorMsg = APP_CONSTANTS.SERVICE_ERROR;
    if (t && Object.keys(t).length) {
      errorMsg = (
        isInternal
          ? t("jobsite.general.internalServiceError")
          : t("jobsite.general.serviceError")
      ) as string;
    } else if (isInternal) {
      errorMsg = APP_CONSTANTS.INTERNAL_SERVICE_ERROR;
    }
    return errorMsg;
  };

  return (
    <>
      {hasError ? (
        <div className="d-flex justify-center mt-100 text-center">
          <h1 className="t-eyebrow-super fw-medium w-80">{errorMsg()}</h1>
        </div>
      ) : (
        children
      )}
    </>
  );
}
