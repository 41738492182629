import React, { useCallback, useEffect, useState } from "react";
import { MappedERPPostLocation } from "../../../../shared/types/refData";
import { getERPPostlocations } from "../../../api/fetchClient";
import { ALERT_WRAPPER_ID } from "../../../app.constants";
import { LocationWidget } from "../../../components/feature/widgets/LocationWidget";
import { useFetchData } from "../../../hooks/useFetchData";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { focusElement } from "../../../utils/focusUtil";
import {
  toggleRetailModalStatus,
  toggleReviewSubmitBtn,
  updateReferenceData,
  updateRetailLocations,
} from "../context/Actions";
import { useERPContext } from "../context/ERPContext";
import { StepName } from "../context/ErpState";
import { FooterSection } from "../FooterSection";

interface LocationRetailStepProps {
  moveNext: () => void;
  goBack: () => void;
  goToStep: (stepName: StepName, id?: string) => void;
}

export function LocationRetailStep({
  moveNext,
  goBack,
  goToStep,
}: LocationRetailStepProps) {
  const { t } = useIntlMessage();
  const { state, dispatch } = useERPContext();
  const { retailLocations, enableReviewSubmitBtn, referenceData } = state;
  const [errorMsg, setErrorMsg] = useState<string>("");
  const { isLoading, isSuccess, data, fetchData } =
    useFetchData(getERPPostlocations);

  useEffect(() => {
    if (!referenceData.postLocations && !data && !isLoading) {
      fetchData();
    }
    if (isSuccess && data && !isLoading && !referenceData.postLocations) {
      dispatch(updateReferenceData("postLocations", data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isSuccess]);

  const addRetailLocation = useCallback(
    (item: MappedERPPostLocation) => {
      const locations = [...retailLocations];
      const locationKeys = locations.map((data) => data.postLocationId);
      if (locationKeys.includes(item.postLocationId)) return;
      dispatch(updateRetailLocations([...locations, { ...item }]));
    },
    [retailLocations, dispatch],
  );

  const deleteRetailLocation = useCallback(
    (item: MappedERPPostLocation) => {
      const locations = [...retailLocations];
      dispatch(
        updateRetailLocations(
          locations.filter(
            (data) => data.postLocationId !== item.postLocationId,
          ),
        ),
      );
    },
    [retailLocations, dispatch],
  );

  const validateLocations = useCallback((): boolean => {
    return retailLocations.length > 0;
  }, [retailLocations]);

  const moveToPreviousStep = useCallback(() => {
    if (!validateLocations()) dispatch(toggleReviewSubmitBtn(false));
    goBack();
  }, [validateLocations, goBack, dispatch]);

  const moveToNextStep = useCallback(
    (stepName?: StepName) => {
      if (!validateLocations()) {
        setErrorMsg(t("jobsite.apply.atleastOneLocation") as string);
        focusElement(ALERT_WRAPPER_ID.generateId("location-widget"));
        return;
      }
      setErrorMsg("");
      if (stepName) {
        goToStep(stepName);
        return;
      }
      moveNext();
    },
    [moveNext, validateLocations, t, goToStep],
  );

  const apiWrapper = useCallback((query: string) => {
    return getERPPostlocations(null, null, null, query);
  }, []);

  return (
    <>
      <LocationWidget
        moduleName="erp"
        title={t("jobsite.getDiscovered.roleLocations") as string}
        subTitle={
          t("jobsite.erp.introduceFriend.workLocation.subTitle") as string
        }
        currentStep={3}
        totalSteps={4}
        errorMessage={errorMsg}
        typeAheadPlaceholder={
          t("jobsite.erp.introduceFriend.enterCityOrAppleStore") as string
        }
        selectedLocationsCountLabel="jobsite.common.selectedStoresCount"
        countReplacementKey="storeCount"
        isCorporate={false}
        apiEndpoint={apiWrapper}
        pillButtonData={state.retailLocations}
        onErrorMessageClose={() => setErrorMsg("")}
        onClearAllBtnClick={() => dispatch(updateRetailLocations([]))}
        onTypeAheadSelection={(data) => addRetailLocation(data)}
        onPillBtnRemove={(data) => deleteRetailLocation(data)}
        getSuggestionLabel={(suggestion: MappedERPPostLocation) =>
          `${suggestion.city}, ${suggestion.name}`
        }
        pillButtonOptions={{
          getKey: (data: MappedERPPostLocation) => `loc_${data.postLocationId}`,
          getLabel: (data: MappedERPPostLocation) =>
            `${data.city}, ${data.name}`,
        }}
        showRetailModal={state.showRetailModal}
        toggleRetailModal={(data) => dispatch(toggleRetailModalStatus(data))}
      />
      <FooterSection
        module="erp"
        enableReviewSubmitBtn={enableReviewSubmitBtn}
        onBack={() => moveToPreviousStep()}
        onContinue={() => moveToNextStep()}
        onReviewAndSubmit={() => moveToNextStep(StepName.REVIEW_INFO)}
      />
    </>
  );
}
