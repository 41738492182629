import {
  getCountryRefData,
  getERPLanguageRefData,
  getReferralTypeRefData,
} from "../../api/fetchClient";
import { useFetchDataStatus } from "../../hooks/useFetchData";
import { contactInformationHook } from "./types";

export function useContactInformationHook() {
  const { updateFetchStatus, isError, isLoading, isSuccess, data, error } =
    useFetchDataStatus<contactInformationHook>();

  async function invokeApis() {
    try {
      updateFetchStatus(true, false, false, null, null);
      const result = await Promise.all([
        getCountryRefData(),
        getERPLanguageRefData(),
        getReferralTypeRefData(),
      ]);
      updateFetchStatus(false, true, false, result, null);
    } catch (error: any) {
      updateFetchStatus(false, false, true, null, error);
    }
  }

  return { isLoading, data, isSuccess, error, isError, invokeApis };
}
