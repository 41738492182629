import dateUtil from "@rpe-js/core/dist/util/dateUtil";
import {
  Button,
  NativeButton,
  ProgressIndicatorLoader,
} from "@rpe-js/marcom-web-components";
import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import {
  DisabilityStatusRefData,
  RefData,
} from "../../../../shared/types/refData";
import {
  Disability,
  DisabilityInfo,
} from "../../../../shared/types/talent/talent";
import { updateTalent } from "../../../api/fetchClient";
import {
  COUNTRY_USA,
  DEFAULT_TRANSLATION_LOCALE,
  PROFILE_SECTIONS,
} from "../../../app.constants";
import AppContext from "../../../AppContext";
import { Info } from "../../../components/base/Info";
import { Label } from "../../../components/base/Label";
import PrivacyPolicy from "../../../components/feature/PrivacyPolicy";
import { SaveCancelAction } from "../../../components/feature/saveAndCancel";
import { DisabilityModal } from "../../../components/feature/selfDisclosureModals/DisabilityModal";
import { useFetchData } from "../../../hooks/useFetchData";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { handleScrollToElement } from "../../../utils/focusUtil";
import { idGenerator } from "../../../utils/idGenerator";
import { updateSelfDisclosures } from "../context/Actions";
import { useProfileContext } from "../context/ProfileContext";
import { ProfileSection } from "./profileSection";

type SelfDisclosureSectionProps = {
  disabilityData: DisabilityStatusRefData[] | undefined;
};

export const SelfDisclosureSection = ({
  disabilityData,
}: SelfDisclosureSectionProps) => {
  const [isEdit, setEdit] = useState<boolean>(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showDisabilityStatusModal, setShowDisabilityStatusModal] =
    useState(false);
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const { state, dispatch } = useProfileContext();
  const { appUIState } = useContext(AppContext);
  const { countryCode } = appUIState.appData;
  const locale = countryCode?.htmlLang || DEFAULT_TRANSLATION_LOCALE;
  const selfDisclosureIdGenerator = idGenerator("profile", "selfDisclosure");
  const [disabilityForm, setDisabilityForm] = useState(
    cloneDeep(state?.selfDisclosures) || {},
  );
  const {
    isLoading: isSaveTalentLoading,
    isSuccess: isSaveTalentSuccess,
    fetchData: saveTalent,
  } = useFetchData(updateTalent);

  const onPrivacyModalClose = () => {
    setShowPrivacyModal(false);
  };

  const getDisabilityValue = () => {
    const disabilityStatusID = getSelectedDisabilityStatusData()?.statusID;
    if (!disabilityStatusID) {
      return t("jobsite.common.notSpecified") as string;
    }
    const selectedDisabilityData = disabilityData?.filter(
      (disability: DisabilityStatusRefData) =>
        disability.id === disabilityStatusID,
    );
    return selectedDisabilityData?.length ? selectedDisabilityData[0].name : "";
  };

  const getSelectedDisabilityStatusData = () => {
    return state?.selfDisclosures?.[COUNTRY_USA]?.disability;
  };

  const onDisabilityModalCancel = () => {
    setShowDisabilityStatusModal(false);
  };

  const onDisabilityModalClose = () => {
    setShowDisabilityStatusModal(false);
  };

  const onSave = () => {
    const selfDisclosures = disabilityForm;
    if (!disabilityForm[COUNTRY_USA]?.disability?.statusID) {
      setEdit(false);
      return;
    }
    saveTalent([
      state?.talentId,
      PROFILE_SECTIONS.SELF_DISCLOSURES,
      { selfDisclosures },
    ]);
  };

  const onSubmit = (disability: Disability) => {
    const disabilityFormCopy = disabilityForm;
    (disabilityFormCopy[COUNTRY_USA].disability as Disability).name =
      disability.name;
    // set the current date time in completedOn
    (disabilityFormCopy[COUNTRY_USA].disability as any).completedOn =
      new Date();
    (disabilityFormCopy[COUNTRY_USA].disability as DisabilityInfo).statusID =
      disability.statusID;
    (disabilityFormCopy[COUNTRY_USA].disability as DisabilityInfo).statusName =
      disability.statusName;
    setDisabilityForm(disabilityFormCopy);
    setShowDisabilityStatusModal(false);
  };

  const onCancel = () => {
    setDisabilityForm(cloneDeep(state?.selfDisclosures) || {});
    setEdit(false);
  };

  const isSelfDisclosureAvailable = () => {
    return (
      getSelectedDisabilityStatusData()?.statusID &&
      getSelectedDisabilityStatusData()?.completedOn
    );
  };

  useEffect(() => {
    if (!isSaveTalentLoading && isSaveTalentSuccess) {
      dispatch?.(updateSelfDisclosures(disabilityForm));
      setEdit(false);
      handleScrollToElement(selfDisclosureIdGenerator.generateId());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveTalentLoading, isSaveTalentSuccess]);

  const getDataUsageOptedInLabel = (optIn: boolean) => {
    return optIn ? "jobsite.common.optedInDate" : "jobsite.common.optedOutDate";
  };

  return (
    <ProfileSection
      isEdit={isEdit}
      i18nTitle="jobsite.common.selfDisclosureTitle"
      onEdit={() => setEdit(true)}
      id={selfDisclosureIdGenerator.generateId()}
      sectionName="selfDisclosure"
    >
      <>
        {isSaveTalentLoading && <ProgressIndicatorLoader showLoading={true} />}
        <div className="row mb-10">
          {isEdit && isSelfDisclosureAvailable() && (
            <div className="column large-6 small-12 pr-15 d-flex flex-column">
              <Label label={t("jobsite.common.disabilityStatus") as string} />
              <Info
                value={disabilityForm[COUNTRY_USA]?.disability?.statusName}
              />
              <div className="w-50">
                <Button
                  id={idGenerator("profile", "update-form").generateId(
                    "button",
                  )}
                  size="base"
                  blockedVariant={true}
                  label={
                    t(
                      getSelectedDisabilityStatusData()?.statusID
                        ? "jobsite.common.updateForm"
                        : "jobsite.common.completeForm",
                    ) as string
                  }
                  onClick={() => {
                    setShowDisabilityStatusModal(true);
                  }}
                  color="secondary"
                ></Button>
              </div>
            </div>
          )}

          {!isEdit && isSelfDisclosureAvailable() && (
            <div className="column large-6 small-12 pr-15 d-flex flex-column">
              <Label label={t("jobsite.common.disabilityStatus") as string} />
              <Info value={getDisabilityValue()} />
              <p className="label-grey">
                {t("jobsite.common.completedDate", {
                  date: dateUtil.formatDateTimeByLocaleUtil(
                    getSelectedDisabilityStatusData()?.completedOn?.toString() as string,
                    locale,
                    false,
                  ),
                })}
              </p>
            </div>
          )}
          <div
            className={`column large-6 small-12 d-flex flex-column ${isMobile && !isSelfDisclosureAvailable() && "mt-10"}`}
          >
            <Label
              label={t("jobsite.common.talentPrivacy") as string}
              variant={
                isSelfDisclosureAvailable() || isEdit
                  ? "less-prominent"
                  : "prominent"
              }
              classes={isSelfDisclosureAvailable() ? [] : ["mb-10"]}
            />
            <p className={`${!isSelfDisclosureAvailable() && "mb-10"}`}>
              {t("jobsite.common.acknowledgedDate", {
                date: dateUtil.formatDateTimeByLocaleUtil(
                  state?.privacyPolicy?.acknowledgedDate as number,
                  locale,
                  false,
                ),
              })}
            </p>
            {state.privacyPolicy?.dataConsent?.acknowledgedDate && (
              <>
                <Label label={t("jobsite.common.dataUsagePrivacy") as string} />
                <p>
                  {t(
                    getDataUsageOptedInLabel(
                      state.privacyPolicy?.dataConsent?.optIn || false,
                    ),
                    {
                      date: state?.privacyPolicy?.dataConsent?.acknowledgedDate
                        ? dateUtil.formatDateTimeByLocaleUtil(
                            state?.privacyPolicy?.dataConsent
                              ?.acknowledgedDate as number,
                            locale,
                            false,
                          )
                        : "",
                    },
                  )}
                </p>
              </>
            )}
            <div className={isSelfDisclosureAvailable() ? "mt-15" : "mt-5"}>
              <NativeButton
                id={selfDisclosureIdGenerator.generateId("view-privacy-button")}
                className="link more"
                onClick={() => setShowPrivacyModal(true)}
                label={t("jobsite.common.view") as string}
              ></NativeButton>
            </div>
          </div>
        </div>
        {isEdit && (
          <SaveCancelAction
            onCancel={onCancel}
            onSave={onSave}
            cancelButtonId={selfDisclosureIdGenerator.generateId(
              "cancelButton",
            )}
            saveButtonId={selfDisclosureIdGenerator.generateId("saveButton")}
            scrollToTopSectionId={selfDisclosureIdGenerator.generateId()}
          />
        )}

        {showPrivacyModal && (
          <PrivacyPolicy
            showModal={showPrivacyModal}
            onCloseModal={onPrivacyModalClose}
            editMode={false}
            dataUsageConsentObj={state.privacyPolicy?.dataConsent}
            cancelButtonText={t("jobsite.common.cancel") as string}
            editDataUsageConsent={
              state.privacyPolicy?.dataConsent?.acknowledgedDate ? true : false
            }
            triggerElementId={selfDisclosureIdGenerator.generateId(
              "view-privacy-button",
            )}
            disableFocusOut={false}
            saveButtonText={t("jobsite.common.save") as string}
          ></PrivacyPolicy>
        )}
        {showDisabilityStatusModal && (
          <DisabilityModal
            id={selfDisclosureIdGenerator.generateId("disabilityModal")}
            locale={locale}
            showModal={showDisabilityStatusModal}
            disabilityData={disabilityForm?.[COUNTRY_USA]?.disability || null}
            referenceData={disabilityData as RefData[]}
            onCancel={onDisabilityModalCancel}
            elementIdToFocus={idGenerator("profile", "update-form").generateId(
              "button",
            )}
            onSubmit={(disability: Disability) => {
              onSubmit(disability);
            }}
            onClose={onDisabilityModalClose}
          ></DisabilityModal>
        )}
      </>
    </ProfileSection>
  );
};
