import { HttpMethod } from "../../utilities/httpMethods";

export interface ApiEndpoint {
  url: string;
  method?: HttpMethod;
}

const urlBasePath = `/api/v1`;
const apiDictionary: Record<string, Record<string, ApiEndpoint>> = {
  csrf: {
    token: {
      url: `${urlBasePath}/CSRFToken`,
    },
  },
  search: {
    search: {
      url: `${urlBasePath}/search`,
    },
    getRefDataById: {
      url: `${urlBasePath}/getRefDataById`,
    },
    quickFind: {
      url: `${urlBasePath}/quickfind/:searchTerm`,
    },
    recentlyViewedRoles: {
      url: `${urlBasePath}/rolesearch`,
    },
    expandResults: {
      url: `${urlBasePath}/expandResults`,
    },
  },
  job: {
    details: {
      url: `${urlBasePath}/jobDetails/:jobId`,
    },
    captcha: {
      url: `${urlBasePath}/jobDetails/captcha`,
    },
    email: {
      url: `${urlBasePath}/jobDetails/email`,
    },
    storeLocation: {
      url: `${urlBasePath}/storeLocations`,
    },
    storeBenefits: {
      url: `${urlBasePath}/storeBenefits`,
    },
    postLocationTypeAhead: {
      url: `${urlBasePath}/refData/postLocationTypeAhead`,
    },
    questionnaire: {
      url: `${urlBasePath}/jobDetails/:jobId/questionnaire`,
    },
  },
  talent: {
    getTalent: {
      url: `${urlBasePath}/talent/:talentId`,
    },
    getResumelastaction: {
      url: `${urlBasePath}/talent/:talentId/resumelastaction`,
    },
    getFileMetaData: {
      url: `${urlBasePath}/talent/:talentId/fileMetaData`,
    },
    getTalentEmailDetails: {
      url: `${urlBasePath}/talent/talentEmailDetails`,
    },
    generateEmailVerificationCode: {
      url: `${urlBasePath}/talent/generateEmailVerificationCode`,
    },
    validateEmailVerificationCode: {
      url: `${urlBasePath}/talent/validateEmailVerificationCode`,
    },
    updateTalent: {
      url: `${urlBasePath}/talent/:talentId/:section`,
    },
    getConnection: {
      url: `${urlBasePath}/talent/:talentId/positionID/:positionId`,
    },
    downloadFile: {
      url: `${urlBasePath}/talent/:talentId/file/:fileId/bucket/:bucketId`,
    },
    deleteFile: {
      url: `${urlBasePath}/talent/:talentId/file/:fileId`,
    },
    parsedResumeSnapshot: {
      url: `${urlBasePath}/talent/:talentId/parsedResumeSnapshot/review`,
    },
    parsedResumeSnapshotFail: {
      url: `${urlBasePath}/talent/:talentId/parsedResumeSnapshot/fail`,
    },
    parsedLinkedinSnapshot: {
      url: `${urlBasePath}/talent/:talentId/parsedLinkedinSnapshot/review`,
    },
    parsedLinkedinSnapshotFail: {
      url: `${urlBasePath}/talent/:talentId/parsedLinkedinSnapshot/fail`,
    },
    uploadFile: {
      url: `${urlBasePath}/talent/:talentId/file/parse/:parseRequired`,
    },
    uploadTextResume: {
      url: `${urlBasePath}/talent/:talentId/text/parse/:parseRequired`,
    },
    updateFileCategory: {
      url: `${urlBasePath}/talent/:talentId/file/:fileId/type`,
    },
    submit: {
      url: `${urlBasePath}/talent/:talentId/submit`,
    },
    connectionStoreLocations: {
      url: `${urlBasePath}/talent/connections/:connectionId/storeLocations`,
    },
    savedSearches: {
      url: `${urlBasePath}/talent/:talentId/savedSearches`,
    },
    getOrUpdatePrivacyPolicy: {
      url: `${urlBasePath}/talent/privacyPolicy`,
    },
    updateOrDeleteSavedSearch: {
      url: `${urlBasePath}/talent/:talentId/savedSearches/:savedSearchId`,
    },
    getMyApplicaton: {
      url: `${urlBasePath}/talent/:talentId/application/:applicationId`,
    },
    createMyApplicaton: {
      url: `${urlBasePath}/talent/:talentId/application`,
    },
    updateMyApplication: {
      url: `${urlBasePath}/talent/:talentId/application/:applicationId/:action`,
    },
    questionnaireAns: {
      url: `${urlBasePath}/talent/:talentId/connection/:connectionId/questionAns`,
    },
    questionnaireWithAnswer: {
      url: `${urlBasePath}/talent/:talentId/questionnaires/:questionnaireId/connection/:connectionId`,
    },
    countrySpecificPrivacyPolicy: {
      url: `${urlBasePath}/talent/:talentId/countrySpecificPrivacyPolicy`,
    },
    externalProfileLinked: {
      url: `${urlBasePath}/talent/externalProfileLinked`,
    },
  },
  profile: {
    currentUser: {
      url: `${urlBasePath}/user`,
    },
    config: {
      url: `${urlBasePath}/config`,
    },
  },
  templates: {
    veteranStatusTemplateForm: {
      url: `${urlBasePath}/talent/template/veteranStatus`,
    },
    disabilityTemplateForm: {
      url: `${urlBasePath}/talent/template/disability`,
    },
    privacyPolicyTemplateform: {
      url: `${urlBasePath}/talent/template/privacy`,
    },
  },
  refData: {
    linkFileRefData: {
      url: `${urlBasePath}/refData/linkAndFileData`,
    },
    selfDisclosure: {
      url: `${urlBasePath}/refData/selfDisclosures`,
    },
    teamsTalentEmpTypDisability: {
      url: `${urlBasePath}/refData/teamsTalentEmpTypDisability`,
    },
    skills: {
      url: `${urlBasePath}/refData/skills`,
    },
    country: {
      url: `${urlBasePath}/refData/country`,
    },
    preferredLocation: {
      url: `${urlBasePath}/refData/postlocation`,
    },
    months: {
      url: `${urlBasePath}/refData/months`,
    },
    talentLanguagesAndProficiency: {
      url: `${urlBasePath}/refData/talentLanguagesAndProficiency`,
    },
    educationGradStatus: {
      url: `${urlBasePath}/refData/educationGradStatus`,
    },
    school: {
      url: `${urlBasePath}/refData/school`,
    },
    major: {
      url: `${urlBasePath}/refData/major`,
    },
    talentCompany: {
      url: `${urlBasePath}/refData/talentCompany`,
    },
    postLocations: {
      url: `${urlBasePath}/refData/postLocation/info`,
    },
    jobAgentEmailFrequency: {
      url: `${urlBasePath}/refData/jobAgentEmailFrequency`,
    },
    jobAgentEmailExperience: {
      url: `${urlBasePath}/refData/jobAgentEmailExperience`,
    },
    erpLanguage: {
      url: `${urlBasePath}/refData/erpLanguage`,
    },
    referralType: {
      url: `${urlBasePath}/refData/referralType`,
    },
    countrySpecificSelfDisclosureConfig: {
      url: `${urlBasePath}/refData/selfDisclosureConfig/:countryID`,
    },
    hiringManager: {
      url: `${urlBasePath}/refData/hiringManager`,
    },
    languagesByInput: {
      url: `${urlBasePath}/refData/languagesByInput`,
    },
    teamsByInput: {
      url: `${urlBasePath}/refData/teamsByInput`,
    },
    teamsOfInterest: {
      url: `${urlBasePath}/refData/teamsofinterest`,
    },
    refDataForSearch: {
      url: `${urlBasePath}/refData/search`,
    },
  },
  roles: {
    talentRoles: {
      url: `${urlBasePath}/talent/:talentId/roles`,
    },
    talentRolesCount: {
      url: `${urlBasePath}/talent/:talentId/roles/count`,
    },
  },
  config: {
    translations: {
      url: `${urlBasePath}/translations`,
    },
  },
  erp: {
    appleStoreRoles: {
      url: `${urlBasePath}/erp/applestoreroles`,
    },
    teamsOfInterest: {
      url: `${urlBasePath}/erp/teamsofinterest`,
    },
    postlocation: {
      url: `${urlBasePath}/erp/postlocation`,
    },
    createReferral: {
      url: `${urlBasePath}/erp/createReferral`,
    },
  },
  app: {
    keepAlive: {
      url: `${urlBasePath}/keep-alive`,
    },
  },
};

export default apiDictionary;
