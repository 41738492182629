import React, { useCallback } from "react";
import { SkillByInputRefData } from "../../../shared/types/refData";
import { Skill } from "../../../shared/types/talent/talent";
import { addSkills } from "../../utils/skillUtils";
import { EditSkills } from "../profile/information/editSkills";

type ParsedSkillsSectionProps = {
  elementToFocusOnClose: string; // When in Mobile view we need an element Id to bring back the focus to.
  skills: Skill[];
  setSkills: React.Dispatch<React.SetStateAction<Skill[] | null>>;
  skillsErrorMessage: string;
};

export const ParsedSkills = ({
  skills,
  setSkills,
  skillsErrorMessage,
  elementToFocusOnClose,
}: ParsedSkillsSectionProps) => {
  const handleAddSkill = useCallback(
    (skillToAdd: SkillByInputRefData) => {
      setSkills(addSkills([...skills], skillToAdd));
    },
    [setSkills, skills],
  );
  const handleRemoveSkill = useCallback(
    (index: number) => {
      const updatedSkills = skills.filter((_, i) => i !== index);
      setSkills([...updatedSkills]);
    },
    [setSkills, skills],
  );

  return (
    <EditSkills
      elementToFocusOnClose={elementToFocusOnClose}
      skills={skills}
      mode="parsedmodal"
      errorMessage={skillsErrorMessage}
      handleAddSkill={handleAddSkill}
      handleRemoveSkill={handleRemoveSkill}
    />
  );
};
