import dateUtil from "@rpe-js/core/dist/util/dateUtil";
import { Button, ProgressIndicatorLoader } from "@rpe-js/marcom-web-components";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { LinkedInProfilePreview } from "../../../../shared/types/config";
import {
  AllowedProfileSaveCategories,
  ExternalProfile,
  ExternalProfileLinkedRequest,
  LinkedInProfileData,
} from "../../../../shared/types/talent/talent";
import { TalentFileMetaData } from "../../../../shared/types/talent/talentFileMetatdata";
import { ApiResponse } from "../../../api/apiService";
import {
  deleteFilePromises,
  downloadFile,
  externalProfileLinked,
  parsedLinkedInSnapshot,
  parsedResumeSnapshot,
  updateTalent,
} from "../../../api/fetchClient";
import {
  DEFAULT_TRANSLATION_LOCALE,
  PRIMARY_TYPE,
  PROFILE_SECTIONS,
  RESUME_ACTIONS,
  ResumeActionType,
} from "../../../app.constants";
import AppContext from "../../../AppContext";
import { Info } from "../../../components/base/Info";
import { Label } from "../../../components/base/Label";
import { SaveCancelAction } from "../../../components/feature/saveAndCancel";
import { ResumeWidgetWrapper } from "../../../components/feature/widgets/ResumeWidgetWrapper";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useResumePolling from "../../../hooks/useResumePolling";
import { ResumeParsingData } from "../../../types";
import { FileData } from "../../../types/File";
import { LinkedInData } from "../../../types/LinkedIn";
import { uploadResumePromises } from "../../../utils/fileUtil";
import { idGenerator } from "../../../utils/idGenerator";
import {
  profileUpdateMode,
  ProfileUpdateSelection,
} from "../../apply/context/ApplyState";
import { useProfileContext } from "../context/ProfileContext";
import { ProfileSection } from "./profileSection";

type ResumeSectionProps = {
  isEdit: boolean;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

interface ProfileFillOption {
  id: string;
  label: string;
  value: profileUpdateMode;
  showOption: boolean;
}

export const ResumeSection = ({ isEdit, setEdit }: ResumeSectionProps) => {
  const { appUIState, dispatch: dispatchAppContext } = useContext(AppContext);
  const { countryCode } = appUIState.appData;
  const locale = countryCode?.htmlLang || DEFAULT_TRANSLATION_LOCALE;
  const { state, dispatch } = useProfileContext();
  const { textResume } = state;
  const { t } = useIntlMessage();
  const resumeIdGenerator = idGenerator("profile", "resume");
  const profileUpdate: ProfileUpdateSelection =
    (state.profileUpdateSelection as ProfileUpdateSelection) ||
    profileUpdateMode.MANUAL;
  const [fileUploadLoading, setFileUploadLoading] = useState<boolean>(false);
  const [linkedIn, setLinkedIn] = useState<LinkedInProfileData | null>(null);
  const [linkedInRemoveClick, setLinkedInRemoveClick] =
    useState<boolean>(false);
  const [profileUpdateSelection, setProfileUpdateSelection] =
    useState<ProfileUpdateSelection>(profileUpdateMode.MANUAL);
  const [fileDownloadLoading, setFileDownloadLoading] =
    useState<boolean>(false);
  const getResumeData = useCallback((): TalentFileMetaData | null => {
    const resumeData = state?.fileMetaData?.filter(
      (fileData: TalentFileMetaData) => fileData.typeId === PRIMARY_TYPE,
    );
    return resumeData?.length ? resumeData[0] : null;
  }, [state]);

  const startPolling = useResumePolling();

  const existingResumeData = useMemo(() => getResumeData(), [getResumeData]);

  const resume = useMemo(
    () =>
      state.resume || {
        fileId: existingResumeData?.fileId,
        name: existingResumeData?.name,
        createdTime: existingResumeData?.createdTime,
      },
    [state.resume, existingResumeData],
  );

  const [profileFillOptions, setProfileFillOptions] = useState<
    ProfileFillOption[]
  >([
    {
      id: "profile-resumeOption",
      label: t("jobsite.common.resumeOptionNewResume") as string,
      value: profileUpdateMode.RESUME,
      showOption: false,
    },
    {
      id: "profile-linkedinOption",
      label: t("jobsite.common.resumeOptionNewLinkedin") as string,
      value: profileUpdateMode.LINKEDIN,
      showOption: false,
    },
    {
      id: "profile-manualOption",
      label: t("jobsite.common.resumeOptionAppleProfile") as string,
      value: profileUpdateMode.MANUAL,
      showOption: true,
    },
  ] as ProfileFillOption[]);

  useEffect(() => {
    const educationDegreeOrEmploymentPresent =
      (state.educationDegrees && state.educationDegrees.length !== 0) ||
      state.employments;
    setProfileFillOptions((pendingState) => {
      const profileFillOptionsData = [...pendingState];
      profileFillOptionsData[0].label = educationDegreeOrEmploymentPresent
        ? (t("jobsite.common.resumeOptionNewResume") as string)
        : (t("jobsite.common.resumeOptionResume") as string);
      profileFillOptionsData[1].label = educationDegreeOrEmploymentPresent
        ? (t("jobsite.common.resumeOptionNewLinkedin") as string)
        : (t("jobsite.common.resumeOptionLinkedin") as string);
      return profileFillOptionsData;
    });
  }, [state.educationDegrees, state.employments, t]);

  const validateLinkedIn = useCallback(() => {
    if (state && state.externalProfiles && state.externalProfiles.linkedin)
      return true;
    return false;
  }, [state]);

  const getLinkedinData = useCallback(() => {
    return state?.externalProfiles?.linkedin;
  }, [state?.externalProfiles]);

  const changeProfileOption = useCallback(
    (selection: ProfileUpdateSelection) => {
      setProfileUpdateSelection(selection);
    },
    [],
  );

  const updateProfileOptions = useCallback(
    (index: number, showOption: boolean) => {
      const profileOptions = [...profileFillOptions];
      profileFillOptions[index].showOption = showOption;
      setProfileFillOptions([...profileOptions]);
      dispatch({ type: "TOGGLE_SHOW_PROFILE_OPTIONS", payload: true });
    },
    [profileFillOptions, dispatch],
  );

  const resumeFileHandler = useCallback(
    (fileData: FileData) => {
      if (!fileData.name) {
        dispatch({
          type: "DISCARD_RESUME",
          payload: existingResumeData as FileData,
        });
      }
      dispatch({ type: "UPDATE_RESUME", payload: fileData });
      if (fileData.fileId) {
        updateProfileOptions(0, true);
      } else if (fileData.name && !fileData.fileId) {
        updateProfileOptions(0, true);
      } else {
        updateProfileOptions(0, false);
      }
    },
    [dispatch, existingResumeData, updateProfileOptions],
  );

  const resumeTextHandler = useCallback(
    (fileData: FileData) => {
      dispatch({ type: "UPDATE_TEXT_RESUME", payload: fileData });
      if (fileData.content) {
        updateProfileOptions(0, true);
      } else {
        if (!validateLinkedIn()) {
          updateProfileOptions(0, false);
        }
      }
    },
    [dispatch, updateProfileOptions, validateLinkedIn],
  );

  const addLinkedIn = useCallback(
    (profileData: LinkedInProfilePreview) => {
      const addedLinkedIn = {
        url: profileData.publicProfileUrl,
        addedOn: new Date().getTime(),
      };
      updateProfileOptions(1, true);
      setLinkedIn({ externalProfile: addedLinkedIn, profileData: profileData });
      setLinkedInRemoveClick(false);
    },
    [updateProfileOptions],
  );

  const removeLinkedIn = useCallback(() => {
    updateProfileOptions(1, false);
    setLinkedIn(null);
    setLinkedInRemoveClick(true);
  }, [updateProfileOptions]);

  const cancelResumeSave = useCallback(() => {
    if (state.canShowProfileOptions) {
      dispatch({ type: "TOGGLE_SHOW_PROFILE_OPTIONS", payload: false });
    }
    if (
      state.resume &&
      !(
        existingResumeData?.name === state.resume.name &&
        existingResumeData?.fileId === state.resume.fileId
      )
    ) {
      dispatch({ type: "CLEAR_RESUME" });
    }
    setEdit(false);
    setLinkedInRemoveClick(false);
  }, [
    dispatch,
    existingResumeData,
    state.canShowProfileOptions,
    state.resume,
    setEdit,
  ]);

  const getDiscardedFilesToDelete = useCallback(() => {
    return state.discardResume && state.discardResume.fileId
      ? [state.discardResume.fileId]
      : [];
  }, [state.discardResume]);

  const updateGlobarBannerVisibility = useCallback(() => {
    let resumeParsingPayload: ResumeParsingData = {
      profileUpdateSelection: profileUpdateSelection || "manual",
    };
    if (profileUpdateSelection === profileUpdateMode.RESUME) {
      resumeParsingPayload = {
        profileUpdateSelection: profileUpdateSelection,
        resumeLastAction: ResumeActionType.PROGRESS,
        linkedinLastAction: undefined,
      };
      dispatchAppContext({
        type: "RESUME_PARSING_DATA",
        payload: resumeParsingPayload,
      });
    } else if (profileUpdateSelection === profileUpdateMode.LINKEDIN) {
      resumeParsingPayload = {
        profileUpdateSelection: profileUpdateSelection,
        linkedinLastAction: ResumeActionType.REVIEW_LATER,
        resumeLastAction: undefined,
      };
      dispatchAppContext({
        type: "RESUME_PARSING_DATA",
        payload: resumeParsingPayload,
      });
      dispatchAppContext({
        type: "RESUME_PARSING_REVIEW_ACTIONS",
        payload: {
          showResumeActionsModal: true,
        },
      });
    } else {
      resumeParsingPayload = {
        profileUpdateSelection: "manual",
      };
      dispatchAppContext({
        type: "RESUME_PARSING_DATA",
        payload: resumeParsingPayload,
      });
    }
    return resumeParsingPayload;
  }, [profileUpdateSelection, dispatchAppContext]);

  const updateTalentProfile = useCallback(async () => {
    if (
      profileUpdateSelection &&
      profileUpdateSelection !== state.profileUpdateSelection
    ) {
      await updateTalent(
        state.talentId,
        PROFILE_SECTIONS.PARENT_FIELDS as AllowedProfileSaveCategories,
        {
          profileUpdateSelection: profileUpdateSelection,
        },
      );
    }
  }, [profileUpdateSelection, state.profileUpdateSelection, state.talentId]);

  const discardResumeAction = useCallback(async () => {
    if (state.discardResume && state.discardResume.fileId) {
      await parsedResumeSnapshot(
        state.talentId as string,
        RESUME_ACTIONS.DISCARD as string,
      );
    }
  }, [state.discardResume, state.talentId]);

  const updateExternalProfileData = useCallback(async () => {
    if (linkedInRemoveClick) {
      await updateTalent(
        state.talentId,
        PROFILE_SECTIONS.EXTERNAL_PROFILES as AllowedProfileSaveCategories,
        {
          externalProfiles: {
            ...state.externalProfiles,
            linkedin: null,
          } as Record<string, ExternalProfile | null>,
        },
      );
      await parsedLinkedInSnapshot(
        state.talentId as string,
        RESUME_ACTIONS.DISCARD as string,
      );
      dispatch({
        type: "UPDATE_LINKEDIN",
        payload: null,
      });
    }

    if (linkedIn) {
      await updateTalent(
        state.talentId,
        PROFILE_SECTIONS.EXTERNAL_PROFILES as AllowedProfileSaveCategories,
        {
          externalProfiles: {
            ...state.externalProfiles,
            linkedin: linkedIn?.externalProfile,
          } as Record<string, ExternalProfile>,
        },
      );
      // TODO: Test this call once API is added
      const externalProfileLinkedPayload: ExternalProfileLinkedRequest = {
        snapshotType: "json",
        talentId: state?.talentId,
        source: "linkedin",
        snapshot: JSON.stringify(linkedIn?.profileData),
        publicProfileUrl: linkedIn?.profileData?.publicProfileUrl,
      };
      await externalProfileLinked(externalProfileLinkedPayload);
      dispatch({
        type: "UPDATE_LINKEDIN",
        payload: linkedIn?.externalProfile as LinkedInData,
      });
    }
  }, [
    linkedInRemoveClick,
    linkedIn,
    state.talentId,
    state.externalProfiles,
    dispatch,
  ]);

  const saveResume = useCallback(async () => {
    const filePromises: Array<() => Promise<ApiResponse<TalentFileMetaData>>> =
      [
        ...deleteFilePromises(state.talentId, getDiscardedFilesToDelete()),
        ...uploadResumePromises(
          state.talentId,
          resume as FileData,
          textResume as FileData,
          profileUpdateSelection || profileUpdateMode.MANUAL,
          state.linkedinLastAction,
          state.resumeLastAction,
        ),
      ];
    setFileUploadLoading(true);
    await updateExternalProfileData();
    await updateTalentProfile();
    await discardResumeAction();

    if (filePromises.length > 0) {
      const fileMetaData = state?.fileMetaData || [];
      for (const filePromise of filePromises) {
        const promiseResult = await filePromise();
        if (promiseResult && promiseResult.success) {
          if (promiseResult.data?.typeId === PRIMARY_TYPE) {
            const fileMetaDataIndex = fileMetaData?.findIndex(
              (file) => file.typeId === PRIMARY_TYPE,
            );
            if (fileMetaDataIndex >= 0) {
              fileMetaData[fileMetaDataIndex] = promiseResult.data;
            } else {
              fileMetaData.push(promiseResult.data);
            }
            dispatch({
              type: "SET_FILE_META_DATA",
              payload: fileMetaData,
            });

            dispatch({
              type: "UPDATE_RESUME",
              payload: promiseResult.data as FileData,
            });

            const resumeParsingData = updateGlobarBannerVisibility();
            startPolling(resumeParsingData);
          }
        } else if (promiseResult && promiseResult.error) {
          dispatchAppContext({
            type: "RESUME_PARSING_DATA",
            payload: {
              profileUpdateSelection: "resume",
              resumeLastAction: "FAILED",
              linkedinLastAction: undefined,
            },
          });
          break;
        } else {
          break;
        }
      }

      dispatch({
        type: "UPDATE_PROFILE_UPDATE_SELECTION",
        payload: profileUpdateSelection,
      });

      if (state.discardResume) {
        dispatch({
          type: "SET_FILE_META_DATA",
          payload:
            state.fileMetaData?.filter(
              (file) => file.fileId !== state.discardResume?.fileId,
            ) || [],
        });
        if (
          (!state.textResume || !state.textResume?.name) &&
          (!state.resume || !state.resume?.name)
        ) {
          dispatchAppContext({
            type: "RESUME_PARSING_DATA",
            payload: {
              profileUpdateSelection: profileUpdateSelection,
              resumeLastAction: ResumeActionType.DISCARDED,
            },
          });
        }
      }
    } else if (linkedIn) {
      updateGlobarBannerVisibility();
    }
    setFileUploadLoading(false);
    dispatch({ type: "TOGGLE_SHOW_PROFILE_OPTIONS", payload: false });
    setEdit(false);
    setLinkedIn(null);
  }, [
    state.talentId,
    state.linkedinLastAction,
    state.resumeLastAction,
    state.fileMetaData,
    state.discardResume,
    state.textResume,
    state.resume,
    getDiscardedFilesToDelete,
    resume,
    textResume,
    profileUpdateSelection,
    updateExternalProfileData,
    updateTalentProfile,
    discardResumeAction,
    dispatch,
    setEdit,
    updateGlobarBannerVisibility,
    dispatchAppContext,
    startPolling,
    linkedIn,
  ]);

  const downloadFileData = useCallback(async () => {
    if (existingResumeData?.fileId || existingResumeData?.name) {
      setFileDownloadLoading(true);
      await downloadFile(
        state.talentId,
        existingResumeData?.fileId,
        existingResumeData?.name as string,
        existingResumeData?.bucketId as string,
      );
      setFileDownloadLoading(false);
    }
  }, [existingResumeData, state.talentId]);

  const editResume = useCallback(() => {
    dispatch({ type: "DISCARD_RESUME", payload: null });
    setProfileUpdateSelection(
      state.profileUpdateSelection as ProfileUpdateSelection,
    );
    setLinkedIn(null);
    setLinkedInRemoveClick(false);
    setEdit(true);
  }, [dispatch, setEdit, state.profileUpdateSelection]);

  return (
    <ProfileSection
      isEdit={isEdit}
      i18nTitle="jobsite.information.resumeTitle"
      onEdit={editResume}
      id={resumeIdGenerator.generateId()}
      sectionName="resume"
      classNames="scroll-margin-header"
    >
      {fileUploadLoading && <ProgressIndicatorLoader showLoading={true} />}
      {isEdit && (
        <>
          <ResumeWidgetWrapper
            module="profile"
            locale={locale}
            resumeData={existingResumeData as FileData}
            linkedInData={
              (state.externalProfiles &&
                state?.externalProfiles.linkedin &&
                (state?.externalProfiles.linkedin as LinkedInData)) ||
              null
            }
            profileFillOptions={profileFillOptions}
            profileSelectionMode={profileUpdate}
            canShowProfileOptions={state.canShowProfileOptions || false}
            changeProfileOption={changeProfileOption}
            resumeFileHandler={resumeFileHandler}
            resumeTextHandler={resumeTextHandler}
            addLinkedIn={addLinkedIn}
            removeLinkedIn={removeLinkedIn}
          />
          <SaveCancelAction
            onCancel={cancelResumeSave}
            onSave={saveResume}
            cancelButtonId={resumeIdGenerator.generateId("cancelButton")}
            saveButtonId={resumeIdGenerator.generateId("saveButton")}
            scrollToTopSectionId={resumeIdGenerator.generateId()}
          />
        </>
      )}
      {!isEdit && (
        <div className="row mb-10">
          <div className="column large-6 small-12 d-flex flex-column">
            <Label label={t("jobsite.information.resume") as string} />
            {existingResumeData && (
              <>
                {fileDownloadLoading && (
                  <ProgressIndicatorLoader showLoading={true} />
                )}
                <p className="saved-resume-file">
                  <Button
                    id={resumeIdGenerator.generateId("downloadfile")}
                    label={existingResumeData.name || ""}
                    size="base"
                    onClick={downloadFileData}
                    className="pl-0"
                  ></Button>
                </p>
                <p
                  id={resumeIdGenerator.generateId("resumeUploaded")}
                  className="label-grey my-10"
                >
                  {t("jobsite.common.resumeUploaded", {
                    date: dateUtil.formatDateTimeByLocaleUtil(
                      existingResumeData?.createdTime as string,
                      locale,
                    ),
                  })}
                </p>
              </>
            )}
            {!existingResumeData && (
              <Info value={t("jobsite.common.notSpecified") as string} />
            )}
          </div>
          <div className="column large-6 small-12 d-flex flex-column">
            <Label label={t("jobsite.information.linkedIn") as string} />
            {!getLinkedinData()?.url && (
              <Info value={t("jobsite.common.notSpecified") as string} />
            )}
            {getLinkedinData()?.url && (
              <>
                <a
                  id={resumeIdGenerator.generateId("linkedin")}
                  href={getLinkedinData()?.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {getLinkedinData()?.url as string}
                </a>
                <p
                  id={resumeIdGenerator.generateId("lastUpdatedDate")}
                  className="label-grey"
                >
                  {t("jobsite.common.lastUpdatedDate", {
                    date: dateUtil.formatDateTimeByLocaleUtil(
                      (getLinkedinData()?.addedOn as string | number) ||
                        Date.now(),
                      locale,
                    ),
                  })}
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </ProfileSection>
  );
};
