import React, { createContext, useContext } from "react";
import { ERPAction } from "./ActionTypes";
import { ERPState } from "./ErpState";

export const ERPContext = createContext<
  | {
      state: ERPState;
      dispatch: React.Dispatch<ERPAction>;
    }
  | undefined
>(undefined);

export const useERPContext = (): {
  state: ERPState;
  dispatch: React.Dispatch<ERPAction>;
} => {
  const context = useContext(ERPContext);
  if (!context) {
    throw new Error("useERPContext must be used within an ERPProvider");
  }
  return context;
};
