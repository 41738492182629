import React from "react";
import { Address } from "../../../../../../shared/types/talent/talent";
import { Info } from "../../../../../components/base/Info";
import { Label } from "../../../../../components/base/Label";
import useIntlMessage from "../../../../../hooks/useIntlMessage";
import { ADDRESS_PREFERRED } from "../../types";
import { getAddressViewConfig } from "../utils";

interface AddressViewProps {
  address: Address;
  displayMode?: "application" | "profile";
}

export function AddressView(props: AddressViewProps) {
  const { address, displayMode = "profile" } = props;
  const { t } = useIntlMessage();

  const viewAddressTemplate = getAddressViewConfig(address?.countryID);

  const addressi18nLabel =
    address.typeID === ADDRESS_PREFERRED
      ? "jobsite.common.preferredAddress"
      : "jobsite.common.otherAddress";

  return (
    <>
      <Label label={t(addressi18nLabel) as string} />
      {viewAddressTemplate.map((field, index) => {
        if (
          displayMode === "application" &&
          address.typeID === "addressType-preferred"
        ) {
          return <></>;
        } else if (typeof field === "object" && Array.isArray(field)) {
          const addressArray: string[] = field
            .map((key: string) => (address as any)[key])
            .filter(Boolean);
          return (
            <>
              {displayMode === "profile" && (
                <Info value={addressArray.join(", ")} key={index} />
              )}
              {displayMode === "application" &&
                addressArray.map((address, idx) => (
                  <Info value={address} key={idx} />
                ))}
            </>
          );
        } else {
          return (
            <Info value={(address as any)[field]} key={`${field}_${index}`} />
          );
        }
      })}
    </>
  );
}
