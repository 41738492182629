import {
  NativeButton,
  Overlay,
  TextButton,
} from "@rpe-js/marcom-web-components";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidV4 } from "uuid";
import useAriaLiveStatus from "../../../hooks/useAriaLiveStatus";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { default as Icon } from "../IconComponent";
import { useTypeahead } from "./useTypeahead";

interface TypeaheadProps<T> {
  id: string;
  apiEndpoint: (query: string) => Promise<T[] | undefined>; // Function to fetch suggestions from an API
  onSelect: (selection: T | string) => void; // Function to handle selection of a suggestion or custom input
  getSuggestionLabel: (suggestion: T) => string; // Function to get the display label for a suggestion
  onInputChange?: (inputValue: string) => void; // Callback to notify parent of input changes
  showSearchIcon?: boolean; // Whether to show a search icon before each suggestion
  suggestionPlaceholder?: string; // Placeholder text to display at the top of the dropdown
  strict?: boolean; // Whether to enforce strict mode (only allow selection from the list)
  highlightMatches?: boolean; // Enable or disable text highlighting in suggestions
  minChars?: number; // Minimum characters before showing suggestions
  placeholder?: string; // Placeholder for input
  defaulValue?: string; // Default value for the input field
  onClearInput?: (input: string) => void;
  classNames?: {
    container?: string;
    inputWrapper?: string; // Class for input wrapper to position icons/buttons
    input?: string;
    list?: string;
    listItem?: string;
    button?: string;
    highlightedItem?: string;
    searchIcon?: string; // Class for main search icon
    suggestionIcon?: string; // Class for conditional suggestion icon
    clearButton?: string; // Class for clear button
    suggestionPlaceholder?: string; // Class for suggestion placeholder text
    highlight?: string; // Class for highlighted text in suggestion
  }; // Custom classNames for different parts of the component
}

const SearchTypeahead = <T,>({
  id,
  apiEndpoint,
  onSelect,
  defaulValue = "",
  getSuggestionLabel,
  onInputChange,
  onClearInput,
  showSearchIcon = false, // Default to not showing the icon
  suggestionPlaceholder = "Results", // Default placeholder for suggestions dropdown
  strict = false,
  highlightMatches = false, // Default to no highlighting
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  minChars = 2,
  placeholder = "Type to search...",
  classNames = {},
}: TypeaheadProps<T>) => {
  const isMobile = useIsMobile();
  const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(false);
  const [inputValue, setInputValue] = useState(defaulValue); // Input field value (displayed)
  const [query, setQuery] = useState(""); // Query used solely for fetching suggestions
  const [suggestions, setSuggestions] = useState<T[]>([]); // Suggestions fetched from the API
  const [isOpen, setIsOpen] = useState(false); // Controls whether suggestions dropdown is open
  const [highlightedIndex, setHighlightedIndex] = useState(-1); // Tracks highlighted suggestion
  const [hasSelected, setHasSelected] = useState(false); // Tracks if a suggestion was selected
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null); // Ref for the input
  const containerRef = useRef<HTMLDivElement>(null); // Ref for the whole Typeahead container
  const suggestionsRef = useRef<HTMLOListElement>(null);
  const currentRequestId = useRef<string | null>(null); // Track the current request
  const liveRegionSpanRef = useRef<HTMLSpanElement>(null);
  const { t } = useIntlMessage();
  const { announceAriaMessage } = useAriaLiveStatus(liveRegionSpanRef);

  const { renderSuggestionWithHighlight, handleKeyDown } = useTypeahead<T>();

  // Fetch suggestions based on query, but skip if `hasSelected` is true
  useEffect(() => {
    if (!query || hasSelected) {
      setSuggestions([]);
      setIsOpen(false);
      setHasSelected(false); // Reset selection flag after skipping API call
      return;
    }

    const fetchSuggestions = async () => {
      try {
        if (query.length >= minChars) {
          const requestId = uuidV4(); // Generate a unique request ID
          currentRequestId.current = requestId;
          const fetchedSuggestions = (await apiEndpoint(query)) as T[];
          // Only update suggestions if the request is still the current one
          if (currentRequestId.current === requestId) {
            setSuggestions(fetchedSuggestions);
            announceAriaMessage(
              (fetchedSuggestions.length > 0
                ? t("jobsite.search.noSuggestionsUpDown", {
                    number: fetchedSuggestions.length,
                  })
                : t("jobsite.search.noSuggestions")) as string,
            );
            setIsOpen(fetchedSuggestions.length > 0);
          }
        } else {
          setSuggestions([]);
          setIsOpen(false);
        }
      } catch (error) {
        setSuggestions([]);
        setIsOpen(false);
      }
    };

    const debounceFetch = setTimeout(fetchSuggestions, 0); // Debounce 300ms

    return () => {
      clearTimeout(debounceFetch); // Cleanup debounce on unmount
      currentRequestId.current = null; // Clear request ID on unmount
    };
  }, [
    query,
    apiEndpoint,
    minChars,
    hasSelected,
    setHasSelected,
    announceAriaMessage,
    t,
  ]);

  // Close dropdown when clicking outside or pressing "Tab", "Escape", or other relevant keys
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        setOverlayVisible(false);
      }
    };

    const handleKeyDownOutside = (event: KeyboardEvent) => {
      if (event.key === "Escape" || event.key === "Tab") {
        setIsOpen(false);
        // setOverlayVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDownOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDownOutside);
    };
  }, []);
  // Add or remove the overlay-active class on body
  useEffect(() => {
    if (isOverlayVisible) {
      document.body.classList.add("overlay-active");
    } else {
      document.body.classList.remove("overlay-active");
    }
    // Clean up the class when component unmounts or state changes
    return () => document.body.classList.remove("overlay-active");
  }, [isOverlayVisible]);

  // Handle suggestion selection (via mouse or keyboard)
  const handleSuggestionSelect = (suggestion: T) => {
    const suggestionLabel = getSuggestionLabel(suggestion);
    setInputValue(suggestionLabel); // Update input with selected suggestion
    setQuery(""); // Clear query to stop fetching
    setHasSelected(true); // Mark selection to skip next fetch
    setIsOpen(false); // Close dropdown
    setOverlayVisible(false);
    setIsMobileFiltersOpen(false);
    onSelect(suggestion); // Notify parent of selected suggestion
    inputRef.current?.focus(); // Return focus to input
  };

  // Handle custom input submission
  const handleCustomInputSelect = (input: string) => {
    if (!input.trim()) return; // Ignore empty input
    currentRequestId.current = null;
    setQuery(""); // Clear query to prevent unnecessary fetch
    setIsOpen(false); // Close the dropdown
    setHasSelected(false); // Reset `hasSelected` so future submissions work
    setOverlayVisible(false);
    onSelect(input); // Notify parent with the custom input
    inputRef.current?.focus(); // Return focus to input
  };

  // Handle input change and update query for fetching suggestions
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value; // Allow full input with spaces

    setInputValue(value); // Update displayed input field value

    // Only update query if there are non-whitespace characters
    if (value.trim()) {
      setQuery(value.trim()); // Trim spaces for API call but keep spaces in display
      setHasSelected(false); // Reset `hasSelected` to enable future custom submissions
    } else {
      setQuery(""); // Clear query to prevent empty fetch
      setIsOpen(false); // Close the dropdown on whitespace-only input
    }

    if (onInputChange) {
      onInputChange(value); // Notify parent component of input change
    }
  };

  // Handle blur to enforce strict mode only when enabled
  const handleBlur = () => {
    if (strict) {
      // Check if the inputValue matches any suggestion
      const isValid = suggestions.some(
        (suggestion) => getSuggestionLabel(suggestion) === inputValue,
      );

      // Reset the input only if strict mode is enabled and the input does not match any suggestion
      if (!isValid) {
        setInputValue(""); // Reset input value if no match is found
        setQuery(""); // Reset the query
      }
    }
    setOverlayVisible(false);
  };

  // Handle clearing input field
  const handleClearInput = () => {
    setInputValue(""); // Clear the input value
    setQuery(""); // Clear the query for fetching suggestions
    setIsOpen(false); // Close dropdown
    setSuggestions([]);
    setHighlightedIndex(-1); // Reset highlighted index
    inputRef.current?.focus(); // Return focus to input
    if (onClearInput) {
      onClearInput("");
    } // Notify parent of input clear
  };

  // Open the dropdown again on focus, if there are any suggestions
  const handleInputFocus = () => {
    setOverlayVisible(true); // Reopen dropdown if there are suggestions available

    if (suggestions.length > 0) {
      setIsOpen(true);
    }
  };

  // Helper to render suggestion with highlighted text
  const renderSuggestionsWithHighlight = (suggestion: T) => {
    return renderSuggestionWithHighlight(
      getSuggestionLabel,
      suggestion,
      highlightMatches,
      query,
      classNames,
    );
  };
  const handleMouseOver = (index: number) => {
    setHighlightedIndex(index);
  };

  const searchInput = (isMainInput: boolean) => {
    return (
      <div className={classNames.inputWrapper}>
        {/* Main search icon on the left */}
        <span className={classNames.searchIcon}>
          <Icon name="search-grey" classes={"search-search-icon-background"} />
        </span>

        <input
          ref={inputRef}
          type="text"
          value={inputValue} // Displayed input value
          placeholder={placeholder}
          onChange={handleInputChange}
          onKeyDown={(e) =>
            handleKeyDown({
              e,
              isOpen,
              strict,
              suggestions,
              suggestionsRef,
              inputRef,
              highlightedIndex,
              inputValue,
              query,
              setIsOpen,
              handleCustomInputSelect,
              handleSuggestionSelect,
              setHighlightedIndex,
              setInputValue,
              getSuggestionLabel,
            })
          }
          onFocus={() =>
            isMainInput && isMobile
              ? setIsMobileFiltersOpen(true)
              : handleInputFocus()
          } // Handle focus event to reopen dropdown
          onBlur={handleBlur} // Enforce strict mode on blur if enabled
          className={classNames.input}
          aria-autocomplete="list"
          aria-controls="suggestions-list"
          aria-activedescendant={
            highlightedIndex >= 0 ? `suggestion-${highlightedIndex}` : undefined
          }
          aria-label={placeholder}
          aria-haspopup={"listbox"}
          role={"textbox"}
        />
        {/* Clear button on the right */}
        {inputValue && (
          <TextButton
            onClick={handleClearInput}
            className={classNames?.clearButton}
            aria-label={t("jobsite.common.clearField") as string}
            icon={"icon-resetsolid"}
            id="search-typeahead-clear-button"
          />
        )}
      </div>
    );
  };

  const renderList = () => {
    return (
      <div className={classNames.list}>
        {/* Display the placeholder at the top of the dropdown */}
        {suggestions.length > 0 && (
          <h2 className={classNames.suggestionPlaceholder}>
            {suggestionPlaceholder}
          </h2>
        )}

        <ol
          ref={suggestionsRef}
          id="suggestions-list"
          role="listbox"
          aria-live={"polite"}
          className="u-list-style-none ml-0 mt-0"
          onMouseLeave={() => setHighlightedIndex(-1)} // Reset on mouse leave
        >
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              id={`suggestion-${index}`}
              role="option"
              aria-selected={index === highlightedIndex}
              className={
                index === highlightedIndex
                  ? classNames.highlightedItem
                  : classNames.listItem
              }
            >
              <NativeButton
                id={`suggestion-${index}-button`}
                type="button"
                className={classNames.button}
                onMouseOver={() => handleMouseOver(index)}
                onMouseDown={() => handleSuggestionSelect(suggestion)} // Use onMouseDown to avoid premature closing
              >
                {showSearchIcon && !isMobile && (
                  <span className={classNames.suggestionIcon}>
                    <Icon
                      name="search-grey"
                      classes={"search-icon-background-suggetions"}
                    />
                  </span>
                )}
                <div className={"search-typeahead-list-text"}>
                  {renderSuggestionsWithHighlight(suggestion)}
                </div>
              </NativeButton>
            </li>
          ))}
        </ol>
      </div>
    );
  };
  return (
    <div ref={containerRef} className={classNames.container}>
      {searchInput(true)}
      {isMobile && isMobileFiltersOpen && (
        <Overlay
          id={`${id}-overlay`}
          elementIdToFocus={id}
          visible={isMobileFiltersOpen}
          onClose={() => setIsMobileFiltersOpen(false)}
          isFullscreen={true}
          noCloseButton={false}
          disableEsc={false}
          classes={{ content: "px-15" }}
          stickyClose={true}
          closeButtonAttrs={{
            ariaLabel: t("jobsite.common.cancel") as string,
            stickyClose: true,
            alignStart: true,
          }}
          footerContent={
            <p className={"t-body-reduced search-main-hint"}>
              {t("jobsite.search.mainSearchHint")}
            </p>
          }
        >
          <>
            {searchInput(false)}
            {suggestions.length > 0 && renderList()}
          </>
        </Overlay>
      )}
      <span
        ref={liveRegionSpanRef}
        role="status"
        aria-live="polite"
        className="a11y"
      />
      {isOpen && !isMobile && renderList()}
    </div>
  );
};

export default SearchTypeahead;
