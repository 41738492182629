import { RefData } from "@rpe-js/core/src/types/refData";
import {
  Dropdown,
  DropDownOptionProps,
  TextArea,
  Textbox,
  TextButton,
} from "@rpe-js/marcom-web-components";
import { cloneDeep, isEmpty, random } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  AppEmploymentDetails,
  MyApplication,
} from "../../../../../../shared/types/talent/myApplication";
import { EmploymentDetails } from "../../../../../../shared/types/talent/talent";
import { getTalentCompanyByInput } from "../../../../../api/fetchClient";
import { COUNTRY_USA } from "../../../../../app.constants";
import { Info } from "../../../../../components/base/Info";
import { Label } from "../../../../../components/base/Label";
import TypeaheadComponent, {
  typeaheadstandardCssClasses,
} from "../../../../../components/base/Typeahead/TypeaheadComponent";
import { useFormInputValidator } from "../../../../../components/feature/form/inputValidators/useFormInputValidator";
import RadioGroup, {
  RadioOption,
} from "../../../../../components/feature/form/radioGroup";
import { useCurrentUserContext } from "../../../../../CurrentUserContext";
import useFocusFirstErrorField from "../../../../../hooks/useFocusFirstErrorField";
import useIntlMessage from "../../../../../hooks/useIntlMessage";
import { getPastNYear } from "../../../../../util";
import { handleScrollToElement } from "../../../../../utils/focusUtil";
import { idGenerator } from "../../../../../utils/idGenerator";
import { CountryDropdownView } from "../../../components/CountryDropdownView";
import {
  getFormFieldLabel,
  getRefData,
} from "../../../information/components/utils";
import { validateDate } from "../../../information/employmentFormUtils";
import { ProfileFormField } from "../../../types";
import { useMyApplicationContext } from "../../context/context";
import { MyApplicationActionDispatcher } from "../../context/state";
import { ApplicationSection, SectionMode } from "../../context/types";
import { useUpdateApplication } from "../../hooks/createAndUpdateApplicationHooks";
import { SectionHeader } from "../SectionHeader";
import { SectionActions } from "./sectionActions";

interface SectionProps {
  id: string;
  onSave: (
    name: ApplicationSection,
    mode: SectionMode,
    application: MyApplication,
  ) => void;
  mode: SectionMode;
}

interface ProfExpProps extends SectionProps {}

interface AppEmpForm {
  key: number;
  country: ProfileFormField; //United States of America
  state?: ProfileFormField;
  city?: ProfileFormField;
  verificationPhone?: ProfileFormField;
  reasonToLeave: ProfileFormField;
  currentEmployer: ProfileFormField;
  employer: ProfileFormField;
  endMonth?: ProfileFormField; //"02"
  endYear?: ProfileFormField; //"2024"
  jobTitle: ProfileFormField;
  startMonth: ProfileFormField;
  startYear: ProfileFormField;
}

function getMonthId(monthId: string): string {
  // in Talent Profile we get `01` and in application we get `month-1`
  if (!monthId || !monthId.startsWith("month-")) {
    return monthId;
  }
  const map: Record<string, string> = {
    "month-1": "01",
    "month-2": "02",
    "month-3": "03",
    "month-4": "04",
    "month-5": "05",
    "month-6": "06",
    "month-7": "07",
    "month-8": "08",
    "month-9": "09",
    "month-10": "10",
    "month-11": "11",
    "month-12": "12",
  };
  return map[monthId];
}

function getAppEmpForm(
  id: string,
  index: number,
  emp?: AppEmploymentDetails,
  isValidJobTitle?: (value: string) => boolean,
  isValidReasonForLeavingAndRelation?: (value: string) => boolean,
): AppEmpForm {
  const form: AppEmpForm = {
    key: random(10000),
    country: {
      key: random(10000),
      id: `${id}-country-${index}`,
      value: emp?.countryID,
      label: emp?.country,
      type: "dropdown",
      required: true,
    },
    employer: {
      key: random(10000),
      id: `${id}-employer-${index}`,
      value: (emp?.employerID as string) || "",
      label: emp?.employer || emp?.employerName || "",
      required: true,
      requiredi18nMsgKey: "jobsite.profile.myApplication.errorEmployer",
      maxLength: 100,
    },
    jobTitle: {
      key: random(10000),
      id: `${id}-jobTitle-${index}`,
      value: (emp?.jobTitle as string) || "",
      label: "jobsite.profile.myApplication.enterJobTitle",
      required: true,
      requiredi18nMsgKey: "jobsite.profile.myApplication.errorJobTitle",
      contentInvalidi18nMsgKey: "jobsite.invalidCharactersError",
      contentValidatorFn: isValidJobTitle,
      maxLength: 60,
    },
    verificationPhone: {
      key: random(10000),
      id: `${id}-verificationPhone-${index}`,
      value: (emp?.verificationPhone as string) || "",
      label: "jobsite.profile.myApplication.verificationPhone",
      required: false,
      optional: true,
      maxLength: 20,
    },
    currentEmployer: {
      key: random(10000),
      id: `${id}-currentEmployer-${index}`,
      value: emp?.currentEmployer === true ? "true" : "false",
      label: "jobsite.profile.myApplication.currentEmployer",
      required: true,
      requiredi18nMsgKey: "jobsite.profile.myApplication.chooseOption",
    },
    startMonth: {
      key: random(10000),
      id: `${id}-startMonth-${index}`,
      value: getMonthId(emp?.startMonthID as string) || "",
      label: (emp?.startMonthName as string) || "",
      required: true,
      requiredi18nMsgKey: "jobsite.profile.myApplication.errormonth",
    },
    startYear: {
      key: random(10000),
      id: `${id}-startYear-${index}`,
      value: (emp?.startYear as string) || "",
      label: (emp?.startYear as string) || "",
      required: true,
      requiredi18nMsgKey: "jobsite.profile.myApplication.erroryear",
    },
    endMonth: {
      key: random(10000),
      id: `${id}-endMonth-${index}`,
      value: getMonthId(emp?.endMonthID as string) || "",
      label: (emp?.endMonthName as string) || "",
      required: emp?.currentEmployer === false,
      requiredi18nMsgKey: "jobsite.profile.myApplication.errormonth",
    },
    endYear: {
      key: random(10000),
      id: `${id}-endYear-${index}`,
      value: (emp?.endYear as string) || "",
      label: (emp?.endYear as string) || "",
      required: emp?.currentEmployer === false,
      requiredi18nMsgKey: "jobsite.profile.myApplication.erroryear",
    },
    reasonToLeave: {
      key: random(10000),
      id: `${id}-reasonToLeave-${index}`,
      value: (emp?.reasonToLeave as string) || "",
      label: "jobsite.profile.myApplication.reasonForLeaving",
      required: emp?.currentEmployer === false,
      requiredi18nMsgKey: "jobsite.profile.myApplication.enterReasonForLeaving",
      maxLength: 300,
      contentInvalidi18nMsgKey: "jobsite.invalidCharactersError",
      contentValidatorFn: isValidReasonForLeavingAndRelation,
    },
  };
  if (index > 0) {
    form.state = {
      key: random(10000),
      id: `${id}-state-${index}`,
      value: (emp?.state as string) || "",
      label: "jobsite.profile.myApplication.enterStateProvince",
      required: true,
      requiredi18nMsgKey: "jobsite.profile.myApplication.educationStateError",
      maxLength: 100,
    };
    form.city = {
      key: random(10000),
      id: `${id}-city-${index}`,
      value: (emp?.city as string) || "",
      label: "jobsite.profile.myApplication.entercity",
      required: true,
      requiredi18nMsgKey: "jobsite.profile.myApplication.profCityError",
      maxLength: 100,
    };
  }
  return form;
}

function getAppEmpFormConfig(
  id: string,
  appEmps?: AppEmploymentDetails[],
  talentEmps?: EmploymentDetails[],
  isValidJobTitle?: (value: string) => boolean,
  isValidReasonForLeavingAndRelation?: (value: string) => boolean,
): AppEmpForm[] {
  if (appEmps && !isEmpty(appEmps)) {
    return (appEmps || []).map((a, idx) =>
      getAppEmpForm(
        id,
        idx,
        a,
        isValidJobTitle,
        isValidReasonForLeavingAndRelation,
      ),
    );
  }

  if (talentEmps && !isEmpty(talentEmps)) {
    return (talentEmps || []).map((a, idx) =>
      getAppEmpForm(
        id,
        idx,
        a as AppEmploymentDetails,
        isValidJobTitle,
        isValidReasonForLeavingAndRelation,
      ),
    );
  }
  return [
    getAppEmpForm(
      id,
      0,
      undefined,
      isValidJobTitle,
      isValidReasonForLeavingAndRelation,
    ),
  ];
}

function getAppEmpsFromForm(forms: AppEmpForm[]): AppEmploymentDetails[] {
  return forms.map((f) => {
    const obj: AppEmploymentDetails = {
      country: f.country.label as string,
      countryID: f.country.value as string,
      currentEmployer:
        f.currentEmployer.value === "true"
          ? true
          : f.currentEmployer.value === "false"
            ? false
            : false,
      employer: f.employer.label as string,
      employerID: f.employer.value || "",
      state: f.state?.value,
      city: f.city?.value,
      startMonthID: f.startMonth.value as string,
      startMonthName: f.startMonth.label as string,
      startYear: f.startYear.value as string,
      jobTitle: f.jobTitle.value as string,
      reasonToLeave: f.reasonToLeave?.value as string,
      verificationPhone: f.verificationPhone?.value,
    };
    if (!obj.currentEmployer && f.endMonth && f.endYear) {
      obj.endMonthID = f.endMonth?.value;
      obj.endMonthName = f.endMonth?.label;
      obj.endYear = f.endYear?.value;
    }
    return obj;
  });
}

export function ProfessionalExperienceSection(props: ProfExpProps) {
  const { id, mode, onSave } = props;
  const { state, dispatch } = useMyApplicationContext();
  const { currentUser } = useCurrentUserContext();
  const { t } = useIntlMessage();
  const { professionSection, application, talent, applicationStep } = state;
  const { refData } = applicationStep;
  const sectionName: ApplicationSection = "professionalExperience";

  const { isValidJobTitle, isValidReasonForLeavingAndRelation } =
    useFormInputValidator();
  const { setFocusFirstErrorField } = useFocusFirstErrorField(
    "profileApplication-application-professionalExperience",
  );

  const {
    //isUpdateApplicationError,
    isUpdateApplicationLoading,
    isUpdateApplicationSuccess,
    updateApplication,
    resetUpdateApplication,
    updatedApplication,
  } = useUpdateApplication();

  const [empAtleastOneJob, setEmpAtleastOneJob] = useState<boolean | undefined>(
    application?.employmentHasAtLeastOneJob ||
      talent?.employmentHasAtLeastOneJob ||
      undefined,
  );
  const [formIndex, setFormIndex] = useState<number>(0);
  const [emplsForm, setEmplsForm] = useState<AppEmpForm[]>([]);

  useEffect(() => {
    if (mode === "create" || mode === "edit") {
      const forms = getAppEmpFormConfig(
        id,
        application?.employments,
        talent?.employments,
        isValidJobTitle,
        isValidReasonForLeavingAndRelation,
      );
      // if first element has no country information , set it to COUNTRY USA
      const firstEntry = forms[0];
      if (
        isEmpty(firstEntry.country.label) &&
        isEmpty(firstEntry.country.value)
      ) {
        const countryRefData = refData?.countryRefData || [];
        const match = countryRefData.find((ref) => ref.id === COUNTRY_USA);
        if (match && match.id && match.name) {
          firstEntry.country.id = firstEntry.country.value = match.id;
          firstEntry.country.label = match.name;
        }
      }
      setEmplsForm(forms);
      setFormIndex(forms.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, application, talent, mode]);

  const empAtleastOneJobForm = useMemo(() => {
    return empAtleastOneJob === true
      ? "true"
      : empAtleastOneJob === false
        ? "false"
        : null;
  }, [empAtleastOneJob]);

  const [showError, setShowError] = useState<boolean>(false);
  const [displayErrors, setDisplayErrors] = useState(false);

  const { title } = professionSection;
  const validateForm = useCallback(() => {
    let errors = false;
    const dateFields = ["startMonth", "startYear", "endMonth", "endYear"];
    const empFormCopy = cloneDeep(emplsForm);
    empFormCopy.forEach((emp: AppEmpForm) => {
      Object.keys(emp).forEach((key) => {
        const fieldToValidate = emp[
          key as keyof AppEmpForm
        ] as ProfileFormField;
        if (fieldToValidate?.required) {
          if (!fieldToValidate?.value) {
            if (
              key !== "employer" ||
              (key === "employer" && !fieldToValidate?.label)
            ) {
              fieldToValidate.error = true;
              (
                emp[key as keyof AppEmpForm] as ProfileFormField
              ).errori18nMsgKey = (
                emp[key as keyof AppEmpForm] as ProfileFormField
              ).requiredi18nMsgKey;
              errors = true;
            }
          } else if (!dateFields.includes(key)) {
            fieldToValidate.errori18nMsgKey = "";
            fieldToValidate.error = false;
          }
        }
        if (fieldToValidate?.value && fieldToValidate.contentValidatorFn) {
          const validator = fieldToValidate.contentValidatorFn;
          if (validator && !validator(fieldToValidate?.value as string)) {
            fieldToValidate.error = true;
            fieldToValidate.errori18nMsgKey = (
              emp[key as keyof AppEmpForm] as ProfileFormField
            ).contentInvalidi18nMsgKey;
            errors = true;
          } else {
            fieldToValidate.error = false;
            fieldToValidate.errori18nMsgKey = "";
          }
        }
        if (fieldToValidate.error === true) {
          errors = true;
        }
      });
    });
    setEmplsForm(empFormCopy);
    return errors;
  }, [emplsForm]);

  const onSectionSave = useCallback(async () => {
    setDisplayErrors(true);
    if (empAtleastOneJobForm === null) {
      setShowError(true);
      return;
    }

    if (empAtleastOneJobForm === "true" && validateForm()) {
      setFocusFirstErrorField(true);
      return;
    }

    if (!showError) {
      if (application && currentUser.talentId) {
        const requestBody = cloneDeep(application);
        requestBody.employmentHasAtLeastOneJob = empAtleastOneJob || false;
        requestBody.employments =
          empAtleastOneJob && emplsForm.length > 0
            ? getAppEmpsFromForm(emplsForm)
            : [];
        await updateApplication(
          currentUser.talentId,
          application?.id,
          "experience",
          requestBody as MyApplication,
        );
      }
    }
  }, [
    empAtleastOneJobForm,
    validateForm,
    showError,
    setFocusFirstErrorField,
    application,
    currentUser.talentId,
    empAtleastOneJob,
    emplsForm,
    updateApplication,
  ]);

  useEffect(() => {
    if (
      !isUpdateApplicationLoading &&
      isUpdateApplicationSuccess &&
      updatedApplication
    ) {
      MyApplicationActionDispatcher.onAppSecProfExpSave(dispatch, {
        employmentHasAtLeastOneJob:
          updatedApplication.employmentHasAtLeastOneJob,
        employments: updatedApplication.employments,
      });
      onSave(sectionName, mode, updatedApplication as MyApplication);
      resetUpdateApplication();
    }
  }, [
    dispatch,
    isUpdateApplicationLoading,
    isUpdateApplicationSuccess,
    mode,
    onSave,
    updatedApplication,
    resetUpdateApplication,
  ]);

  const onCancel = useCallback(() => {
    MyApplicationActionDispatcher.onApplicationSectionCancel(
      dispatch,
      sectionName,
    );
    setEmpAtleastOneJob(application?.employmentHasAtLeastOneJob || false);
  }, [application?.employmentHasAtLeastOneJob, dispatch]);

  const onSectionEdit = useCallback(() => {
    MyApplicationActionDispatcher.onApplicationSectionEdit(
      dispatch,
      sectionName,
    );
  }, [dispatch, sectionName]);

  const options: RadioOption[] = useMemo((): RadioOption[] => {
    return [
      "jobsite.profile.myApplication.yes",
      "jobsite.profile.myApplication.no",
    ].map(
      (rf, idx) =>
        ({
          id: `${id}-empOne=${idx}`,
          label: t(rf),
          value: idx === 0 ? "true" : "false",
        }) as RadioOption,
    );
  }, [t, id]);

  const handleEmpOneJobChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setShowError(false);
      if (event.target.value === "true") {
        setEmpAtleastOneJob(true);
      }
      if (event.target.value === "false") {
        setEmpAtleastOneJob(false);
      }
    },
    [],
  );

  const onAdd = useCallback(() => {
    const toAdd = getAppEmpForm(
      id,
      formIndex + 1,
      undefined,
      isValidJobTitle,
      isValidReasonForLeavingAndRelation,
    );
    setFormIndex(formIndex + 1);
    setEmplsForm([...emplsForm, toAdd]);
  }, [
    emplsForm,
    formIndex,
    id,
    isValidJobTitle,
    isValidReasonForLeavingAndRelation,
  ]);

  const onRemove = useCallback(
    (remove: AppEmpForm) => {
      const removeIdx = emplsForm.findIndex((e) => e.key === remove.key);
      const updated = [...emplsForm].toSpliced(removeIdx, 1);
      setEmplsForm(updated);
      // (removeIdx - 1) will always work as remove button index will be always greater than 0
      // because there wont be remove button for first employer
      // when removed, scroll to previous section
      handleScrollToElement(
        emplsForm[removeIdx - 1]?.currentEmployer.id as string,
      );
    },
    [emplsForm],
  );

  const onCityChange = useCallback(
    (cityUpdatedForm: AppEmpForm, value: string) => {
      const updated = [...emplsForm];
      const updateIdx = emplsForm.findIndex(
        (e) => e.key === cityUpdatedForm.key,
      );
      updated[updateIdx] = {
        ...cityUpdatedForm,
      };
      const updatedCityField = cloneDeep(cityUpdatedForm.city);
      (updatedCityField as ProfileFormField).value = value;
      (updatedCityField as ProfileFormField).error = value ? false : true;
      updated[updateIdx].city = updatedCityField;
      setEmplsForm([...updated]);
    },
    [emplsForm],
  );

  const onStateChange = useCallback(
    (stateUpdatedForm: AppEmpForm, value: string) => {
      const updated = [...emplsForm];
      const updateIdx = emplsForm.findIndex(
        (e) => e.key === stateUpdatedForm.key,
      );
      updated[updateIdx] = {
        ...stateUpdatedForm,
      };
      const updatedStateField = cloneDeep(stateUpdatedForm.state);
      (updatedStateField as ProfileFormField).value = value;
      (updatedStateField as ProfileFormField).error = value ? false : true;
      updated[updateIdx].state = updatedStateField;
      setEmplsForm([...updated]);
    },
    [emplsForm],
  );

  const onVerificationPhoneChange = useCallback(
    (verifPhoneUpdatedForm: AppEmpForm, value: string) => {
      const updated = [...emplsForm];
      const updateIdx = emplsForm.findIndex(
        (e) => e.key === verifPhoneUpdatedForm.key,
      );
      updated[updateIdx] = {
        ...verifPhoneUpdatedForm,
      };
      const updatedVeriPhoneField = cloneDeep(
        verifPhoneUpdatedForm.verificationPhone,
      );
      (updatedVeriPhoneField as ProfileFormField).value = value;
      updated[updateIdx].verificationPhone = updatedVeriPhoneField;
      setEmplsForm([...updated]);
    },
    [emplsForm],
  );

  const onReasonToLeaveChange = useCallback(
    (reasonToLeaveUpdatedForm: AppEmpForm, value: string) => {
      const updated = [...emplsForm];
      const updateIdx = emplsForm.findIndex(
        (e) => e.key === reasonToLeaveUpdatedForm.key,
      );
      updated[updateIdx] = {
        ...reasonToLeaveUpdatedForm,
      };
      const updatedReaLeaveField = cloneDeep(
        reasonToLeaveUpdatedForm.reasonToLeave,
      );
      updatedReaLeaveField.value = value;
      updatedReaLeaveField.value = value;
      if (updatedReaLeaveField.required) {
        updatedReaLeaveField.error = value ? false : true;
      }
      updated[updateIdx].reasonToLeave = updatedReaLeaveField;
      setEmplsForm([...updated]);
    },
    [emplsForm],
  );

  const onJobTitleChange = useCallback(
    (jobTitleUpdatedForm: AppEmpForm, value: string) => {
      const updated = [...emplsForm];
      const updateIdx = emplsForm.findIndex(
        (e) => e.key === jobTitleUpdatedForm.key,
      );
      updated[updateIdx] = {
        ...jobTitleUpdatedForm,
      };
      const updatedJobTitleField = cloneDeep(jobTitleUpdatedForm.jobTitle);
      (updatedJobTitleField as ProfileFormField).value = value;
      updatedJobTitleField.error = value ? false : true;
      updated[updateIdx].jobTitle = updatedJobTitleField;
      setEmplsForm([...updated]);
    },
    [emplsForm],
  );

  const validateEmpDateRange = useCallback(
    (empForm: AppEmpForm, field: "startDate" | "endDate") => {
      return validateDate(
        empForm.startMonth.value as string,
        empForm.startYear.value as string,
        empForm.endMonth?.value as string,
        empForm.endYear?.value as string,
        field,
        "yourApplication",
      );
    },
    [],
  );

  const validateDates = useCallback(
    (
      emp: AppEmpForm,
      field: "startMonth" | "startYear" | "endMonth" | "endYear",
    ) => {
      const validateStartDateRangei18nStr = validateEmpDateRange(
        emp,
        "startDate",
      );
      const validateEndDateRangei18nStr = validateEmpDateRange(emp, "endDate");

      if (validateStartDateRangei18nStr) {
        if (field === "startMonth") {
          emp.startMonth.error = true;
          emp.startMonth.errori18nMsgKey = validateStartDateRangei18nStr;
        } else if (field === "startYear") {
          emp.startYear.error = true;
          emp.startYear.errori18nMsgKey = validateStartDateRangei18nStr;
        }
      } else {
        if (field === "startMonth") {
          emp.startMonth.error = false;
          emp.startMonth.errori18nMsgKey = "";
          if (emp.startYear.value) {
            emp.startYear.error = false;
            emp.startYear.errori18nMsgKey = "";
          }
        } else if (field === "startYear") {
          emp.startYear.error = false;
          emp.startYear.errori18nMsgKey = "";
          if (emp.startMonth.value) {
            emp.startMonth.error = false;
            emp.startMonth.errori18nMsgKey = "";
          }
        } else if (field === "endMonth" || field === "endYear") {
          if (emp.startYear.value) {
            emp.startYear.error = false;
            emp.startYear.errori18nMsgKey = "";
          }
          if (emp.startMonth.value) {
            emp.startMonth.error = false;
            emp.startMonth.errori18nMsgKey = "";
          }
        }
      }
      if (validateEndDateRangei18nStr) {
        if (field === "endMonth") {
          (emp.endMonth as ProfileFormField).error = true;
          (emp.endMonth as ProfileFormField).errori18nMsgKey =
            validateEndDateRangei18nStr;
        }
        if (field === "endYear") {
          (emp.endYear as ProfileFormField).error = true;
          (emp.endYear as ProfileFormField).errori18nMsgKey =
            validateEndDateRangei18nStr;
        }
      } else {
        if (field === "startYear" || field === "startMonth") {
          if (emp.endYear?.value) {
            emp.endYear.error = false;
            emp.endYear.errori18nMsgKey = "";
          }
          if (emp.endMonth?.value) {
            emp.endMonth.error = false;
            emp.endMonth.errori18nMsgKey = "";
          }
        } else if (field === "endMonth") {
          (emp.endMonth as ProfileFormField).error = false;
          (emp.endMonth as ProfileFormField).errori18nMsgKey = "";
          if (emp.endYear?.value) {
            emp.endYear.error = false;
            emp.endYear.errori18nMsgKey = "";
          }
        } else if (field === "endYear") {
          (emp.endYear as ProfileFormField).error = false;
          (emp.endYear as ProfileFormField).errori18nMsgKey = "";
          if (emp.endMonth?.value) {
            emp.endMonth.error = false;
            emp.endMonth.errori18nMsgKey = "";
          }
        }
      }
    },
    [validateEmpDateRange],
  );

  const onStartMonthChange = useCallback(
    (startMonthUpdate: AppEmpForm, monthOption: DropDownOptionProps) => {
      const updated = [...emplsForm];
      const updateIdx = emplsForm.findIndex(
        (e) => e.key === startMonthUpdate.key,
      );
      updated[updateIdx] = {
        ...startMonthUpdate,
      };
      const updatedStartMonthField = cloneDeep(startMonthUpdate.startMonth);
      (updatedStartMonthField as ProfileFormField).value = monthOption.value;
      (updatedStartMonthField as ProfileFormField).label = monthOption.label;
      (updatedStartMonthField as ProfileFormField).error = false;
      updated[updateIdx].startMonth = updatedStartMonthField;
      validateDates(updated[updateIdx], "startMonth");
      setEmplsForm([...updated]);
    },
    [emplsForm, validateDates],
  );

  const onStartYearChange = useCallback(
    (startYearUpdate: AppEmpForm, yearOption: DropDownOptionProps) => {
      const updated = [...emplsForm];
      const updateIdx = emplsForm.findIndex(
        (e) => e.key === startYearUpdate.key,
      );
      updated[updateIdx] = {
        ...startYearUpdate,
      };
      const updatedStartYearField = cloneDeep(startYearUpdate.startYear);
      (updatedStartYearField as ProfileFormField).value = yearOption.value;
      (updatedStartYearField as ProfileFormField).label = yearOption.label;
      (updatedStartYearField as ProfileFormField).error = false;
      updated[updateIdx].startYear = updatedStartYearField;
      validateDates(updated[updateIdx], "startYear");
      setEmplsForm([...updated]);
    },
    [emplsForm, validateDates],
  );

  const onEndMonthChange = useCallback(
    (endMonthUpdate: AppEmpForm, monthOption: DropDownOptionProps) => {
      const updated = [...emplsForm];
      const updateIdx = emplsForm.findIndex(
        (e) => e.key === endMonthUpdate.key,
      );
      updated[updateIdx] = {
        ...endMonthUpdate,
      };
      if (endMonthUpdate.endMonth) {
        const updatedEndMonthField = cloneDeep(endMonthUpdate.endMonth);
        (updatedEndMonthField as ProfileFormField).value = monthOption.value;
        (updatedEndMonthField as ProfileFormField).label = monthOption.label;
        (updatedEndMonthField as ProfileFormField).error = false;
        updated[updateIdx].endMonth = updatedEndMonthField;
        validateDates(updated[updateIdx], "endMonth");
        setEmplsForm([...updated]);
      }
    },
    [emplsForm, validateDates],
  );

  const onEndYearChange = useCallback(
    (endYearUpdate: AppEmpForm, yearOption: DropDownOptionProps) => {
      const updated = [...emplsForm];
      const updateIdx = emplsForm.findIndex((e) => e.key === endYearUpdate.key);
      updated[updateIdx] = {
        ...endYearUpdate,
      };
      if (endYearUpdate.endYear) {
        const updatedEndYearField = cloneDeep(endYearUpdate.endYear);
        (updatedEndYearField as ProfileFormField).value = yearOption.value;
        (updatedEndYearField as ProfileFormField).label = yearOption.label;
        (updatedEndYearField as ProfileFormField).error = false;
        updated[updateIdx].endYear = updatedEndYearField;
        validateDates(updated[updateIdx], "endYear");
        setEmplsForm([...updated]);
      }
    },
    [emplsForm, validateDates],
  );

  const onEmpChange = useCallback(
    (empUpdatedForm: AppEmpForm, selection: string | RefData) => {
      const updated = [...emplsForm];
      const updateIdx = emplsForm.findIndex(
        (e) => e.key === empUpdatedForm.key,
      );
      if ((selection as RefData)?.id) {
        updated[updateIdx].employer.value = (selection as RefData)?.id;
        updated[updateIdx].employer.label = (selection as RefData)?.name;
      } else if (updated[updateIdx].employer.label !== selection) {
        updated[updateIdx].employer.value = "";
        updated[updateIdx].employer.label = selection as string;
      }
      updated[updateIdx].employer.error =
        updated[updateIdx].employer.value || updated[updateIdx].employer.label
          ? false
          : true;
      setEmplsForm([...updated]);
    },
    [emplsForm],
  );

  const onCurrentEmployerChange = useCallback(
    (
      currEmpUpdatedForm: AppEmpForm,
      evt: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const value = evt.target.value;
      const updated = [...emplsForm];
      const updateIdx = emplsForm.findIndex(
        (e) => e.key === currEmpUpdatedForm.key,
      );
      updated[updateIdx] = {
        ...currEmpUpdatedForm,
      };
      const updatedCurrEmpField = cloneDeep(currEmpUpdatedForm.currentEmployer);
      (updatedCurrEmpField as ProfileFormField).value = value;
      updatedCurrEmpField.error = false;
      updated[updateIdx].currentEmployer = updatedCurrEmpField;
      (updated[updateIdx].endMonth as ProfileFormField).error = false;
      (updated[updateIdx].endMonth as ProfileFormField).value = "";
      (updated[updateIdx].endYear as ProfileFormField).error = false;
      (updated[updateIdx].endYear as ProfileFormField).value = "";
      if (value === "false") {
        updated[updateIdx].reasonToLeave.required = true;
        updated[updateIdx].reasonToLeave.error = updated[updateIdx]
          .reasonToLeave.value
          ? false
          : true;
        (updated[updateIdx].endMonth as ProfileFormField).required = true;

        (updated[updateIdx].endYear as ProfileFormField).required = true;
      } else {
        updated[updateIdx].reasonToLeave.required = false;
        updated[updateIdx].reasonToLeave.error = false;
        (updated[updateIdx].endMonth as ProfileFormField).required = false;
        (updated[updateIdx].endYear as ProfileFormField).required = false;
        if (updated[updateIdx].startMonth.value) {
          updated[updateIdx].startMonth.error = false;
        }
        if (updated[updateIdx].startYear.value) {
          updated[updateIdx].startYear.error = false;
        }
        const validateStartDateRangei18nStr = validateEmpDateRange(
          updated[updateIdx],
          "startDate",
        );
        if (validateStartDateRangei18nStr) {
          updated[updateIdx].startMonth.error = true;
          updated[updateIdx].startYear.error = true;
          updated[updateIdx].startMonth.errori18nMsgKey =
            validateStartDateRangei18nStr;
          updated[updateIdx].startYear.errori18nMsgKey =
            validateStartDateRangei18nStr;
        }
      }

      setEmplsForm([...updated]);
    },
    [emplsForm, validateEmpDateRange],
  );

  const onCountryChange = useCallback(
    (countryUpdatedForm: AppEmpForm, selected: RefData) => {
      const updated = [...emplsForm];
      const updateIdx = emplsForm.findIndex(
        (e) => e.key === countryUpdatedForm.key,
      );
      updated[updateIdx] = {
        ...countryUpdatedForm,
      };

      const updatedCountryField = cloneDeep(countryUpdatedForm.country);
      updatedCountryField.value = selected.id;
      updatedCountryField.label = selected.name;
      updatedCountryField.error = false;
      updated[updateIdx].country = updatedCountryField;
      setEmplsForm([...updated]);
    },
    [emplsForm],
  );

  return (
    <SectionHeader id={id} title={title} mode={mode} onEdit={onSectionEdit}>
      {mode === "view" && (
        <ProfExpView
          empAtleastOneJob={empAtleastOneJob || false}
          employments={application?.employments}
        />
      )}
      {(mode === "edit" || mode === "create") && (
        <>
          <fieldset>
            <RadioGroup
              required={true}
              id={idGenerator(
                "profileApplication",
                "professionalexperience",
              ).generateId("empsummaryhistory")}
              classes={{
                root: "mt-0",
              }}
              title={
                t("jobsite.profile.myApplication.empSummaryHistory") as string
              }
              name={`empSummaryHistory`}
              options={options}
              selectedValue={empAtleastOneJobForm}
              onChange={handleEmpOneJobChange}
              errorMessage={
                showError
                  ? (t("jobsite.profile.myApplication.chooseOption") as string)
                  : undefined
              }
              errorA11y={t("jobsite.common.errorIconLabel") as string}
            />
            {empAtleastOneJob &&
              emplsForm.length > 0 &&
              emplsForm.map((emplForm, idx) => (
                <>
                  <EmpForm
                    id={id}
                    form={emplForm}
                    key={emplForm.key}
                    canRemove={idx > 0}
                    displayErrors={displayErrors}
                    showAddMore={idx === emplsForm.length - 1}
                    countryDWRefData={
                      (refData?.countryRefData || []) as RefData[]
                    }
                    monthsRefData={(refData?.monthsRefData || []) as RefData[]}
                    onAdd={onAdd}
                    onCityChange={(value: string) =>
                      onCityChange(emplForm, value)
                    }
                    onCountryChange={(selected: RefData) =>
                      onCountryChange(emplForm, selected)
                    }
                    onCurrentEmployerChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ) => onCurrentEmployerChange(emplForm, evt)}
                    onEmpChange={(selection: string | RefData) =>
                      onEmpChange(emplForm, selection)
                    }
                    onJobTitleChange={(value: string) =>
                      onJobTitleChange(emplForm, value)
                    }
                    onReasonToLeaveChange={(value: string) =>
                      onReasonToLeaveChange(emplForm, value)
                    }
                    onRemove={() => onRemove(emplForm)}
                    onStartMonthChange={(evt, option) =>
                      onStartMonthChange(emplForm, option)
                    }
                    onStartYearChange={(evt, option) =>
                      onStartYearChange(emplForm, option)
                    }
                    onEndMonthChange={(evt, option) =>
                      onEndMonthChange(emplForm, option)
                    }
                    onEndYearChange={(evt, option) =>
                      onEndYearChange(emplForm, option)
                    }
                    onStateChange={(value: string) =>
                      onStateChange(emplForm, value)
                    }
                    onVerificationPhoneChange={(value: string) =>
                      onVerificationPhoneChange(emplForm, value)
                    }
                  />
                </>
              ))}
          </fieldset>
        </>
      )}
      {mode !== "view" && (
        <SectionActions
          onCancel={onCancel}
          onSave={onSectionSave}
          mode={mode}
        />
      )}
    </SectionHeader>
  );
}

interface EmpFormProps {
  id: string;
  form: AppEmpForm;
  countryDWRefData: RefData[];
  monthsRefData: RefData[];
  displayErrors: boolean;
  onEmpChange: (selection: string | RefData) => void;
  onJobTitleChange: (
    value: any,
    evt: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  onCountryChange: (selected: RefData) => void;
  onStateChange: (value: any, evt: React.ChangeEvent<HTMLInputElement>) => void;
  onCityChange: (value: any, evt: React.ChangeEvent<HTMLInputElement>) => void;
  onVerificationPhoneChange: (
    value: any,
    evt: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  onCurrentEmployerChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  onStartMonthChange: (
    evt: React.ChangeEvent,
    option: DropDownOptionProps,
  ) => void;
  onStartYearChange: (
    evt: React.ChangeEvent,
    option: DropDownOptionProps,
  ) => void;
  onEndMonthChange: (
    evt: React.ChangeEvent,
    option: DropDownOptionProps,
  ) => void;
  onEndYearChange: (
    evt: React.ChangeEvent,
    option: DropDownOptionProps,
  ) => void;
  onReasonToLeaveChange: (val: string) => void;
  showAddMore: boolean;
  canRemove: boolean;
  onRemove: () => void;
  onAdd: () => void;
}

function EmpForm(props: EmpFormProps) {
  const { t } = useIntlMessage();
  const {
    form,
    countryDWRefData,
    monthsRefData,
    canRemove,
    showAddMore,
    onStateChange,
    onCityChange,
    onVerificationPhoneChange,
    onCountryChange,
    onEmpChange,
    onJobTitleChange,
    onCurrentEmployerChange,
    onStartMonthChange,
    onStartYearChange,
    onEndMonthChange,
    onEndYearChange,
    onReasonToLeaveChange,
    onAdd,
    onRemove,
    displayErrors,
  } = props;

  const {
    validateCountryStateZipCode,
    onPasteValidateCountryStateZipCode,
    validateStreetCityDistrict,
    onPasteValidateStreetCityDistrict,
    isValidPhoneNumberEntered,
    isValidPhoneNumberPasted,
    validateReasonForLeavingAndRelation,
    onPasteValidateReasonForLeavingAndRelation,
    onPasteValidateJobTitle,
    validateJobTitle,
  } = useFormInputValidator();

  const yearOptions = useMemo(() => {
    return getPastNYear(t("jobsite.profile.myApplication.year") as string);
  }, [t]);

  const monthOptions = useMemo(() => {
    const options = getRefData(
      monthsRefData,
      t("jobsite.profile.myApplication.month") as string,
      false,
    );
    return options;
  }, [monthsRefData, t]);

  const options: RadioOption[] = useMemo((): RadioOption[] => {
    return [
      "jobsite.profile.myApplication.yes",
      "jobsite.profile.myApplication.no",
    ].map(
      (rf, idx) =>
        ({
          id: `${form.currentEmployer?.id}-${idx}`,
          label: t(rf),
          value: idx === 0 ? "true" : "false",
        }) as RadioOption,
    );
  }, [t, form]);

  return (
    <div
      id={form.currentEmployer?.id}
      className={`scroll-margin-header pt-20 pb-20 ${showAddMore ? "" : "u-border-bottom"}`}
    >
      <TypeaheadComponent
        elementToFocusOnClose={`${form.currentEmployer?.id}-0`} // Bring the focus back to Yes
        strict={false}
        apiEndpoint={getTalentCompanyByInput}
        label={t("jobsite.common.enterEmployerName") as string}
        onSelect={onEmpChange}
        onBlur={(e) => onEmpChange(e as string | RefData)}
        onReset={() => onEmpChange("")}
        highlightMatches={true}
        showSearchIcon={true}
        suggestionPlaceholder={
          t("jobsite.profile.myApplication.empTitle") as string
        }
        getSuggestionLabel={(suggestion: any) => suggestion?.name}
        minChars={1}
        initialValue={form.employer?.label}
        classNames={typeaheadstandardCssClasses}
        errorMessage={
          displayErrors && form.employer?.error
            ? (t(form.employer?.errori18nMsgKey as string, {
                ...(form.employer?.errori18nMsgParams || {}),
              }) as string)
            : null
        }
        errorA11y={t("jobsite.common.errorIconLabel") as string}
        id={idGenerator("profileApplication", "employer-typeahead").generateId(
          form.currentEmployer?.id,
        )}
      />
      {form?.jobTitle && (
        <Textbox
          label={t(form?.jobTitle?.label as string) as string}
          value={form?.jobTitle?.value}
          id={`${form?.jobTitle?.id}`}
          required={form.jobTitle.required || false}
          error={
            displayErrors && form.jobTitle?.error
              ? (t(form.jobTitle?.errori18nMsgKey as string, {
                  ...(form.jobTitle?.errori18nMsgParams || {}),
                }) as string)
              : false
          }
          errorA11y={t("jobsite.common.errorIconLabel") as string}
          maxLength={form.jobTitle?.maxLength}
          onValueChange={onJobTitleChange}
          onKeyDown={validateJobTitle}
          onPaste={onPasteValidateJobTitle}
        />
      )}
      <CountryDropdownView
        refData={countryDWRefData}
        selectedCountry={form?.country?.value || null}
        onChange={(e, option, refData) => onCountryChange(refData)}
        showError={displayErrors && form.country.error}
        id={`${form?.country.id}`}
      />
      {form.city && (
        <Textbox
          label={t(form?.city?.label as string) as string}
          value={form?.city?.value}
          id={`${form?.city?.id}`}
          required={form.city.required || false}
          error={
            displayErrors && form.city?.error
              ? (t(form.city?.errori18nMsgKey as string, {
                  ...(form.city?.errori18nMsgParams || {}),
                }) as string)
              : false
          }
          errorA11y={t("jobsite.common.errorIconLabel") as string}
          maxLength={form.city?.maxLength}
          onValueChange={onCityChange}
          onKeyDown={validateStreetCityDistrict}
          onPaste={onPasteValidateStreetCityDistrict}
        />
      )}
      {form.state && (
        <Textbox
          label={t(form?.state?.label as string) as string}
          value={form?.state?.value}
          id={`${form?.state?.id}`}
          required={form.state.required || false}
          error={
            displayErrors && form.state?.error
              ? (t(form.state?.errori18nMsgKey as string, {
                  ...(form.state?.errori18nMsgParams || {}),
                }) as string)
              : false
          }
          errorA11y={t("jobsite.common.errorIconLabel") as string}
          maxLength={form.state?.maxLength}
          onValueChange={onStateChange}
          onKeyDown={validateCountryStateZipCode}
          onPaste={onPasteValidateCountryStateZipCode}
        />
      )}

      <Textbox
        label={getFormFieldLabel(
          form?.verificationPhone as ProfileFormField,
          t,
        )}
        required={form.verificationPhone?.required || false}
        value={form?.verificationPhone?.value}
        id={`${form?.verificationPhone?.id}`}
        error={
          form.verificationPhone?.error
            ? (t(form.verificationPhone?.errori18nMsgKey as string, {
                ...(form.verificationPhone?.errori18nMsgParams || {}),
              }) as string)
            : false
        }
        errorA11y={t("jobsite.common.errorIconLabel") as string}
        maxLength={form.verificationPhone?.maxLength}
        onValueChange={onVerificationPhoneChange}
        onKeyDown={isValidPhoneNumberEntered}
        onPaste={isValidPhoneNumberPasted}
      />
      <Info
        value={t("jobsite.profile.myApplication.noOnlyUSedFor") as string}
        classes="t-body-reduced"
      ></Info>

      <RadioGroup
        required={true}
        id={`${form?.currentEmployer?.id}`}
        title={t(form?.currentEmployer?.label as string) as string}
        name={`${form?.currentEmployer?.id}`}
        options={options}
        selectedValue={form?.currentEmployer?.value as string}
        onChange={onCurrentEmployerChange}
        errorMessage={
          displayErrors && form.currentEmployer?.error
            ? (t(form.currentEmployer?.errori18nMsgKey as string, {
                ...(form.currentEmployer?.errori18nMsgParams || {}),
              }) as string)
            : undefined
        }
        errorA11y={t("jobsite.common.errorIconLabel") as string}
      />
      <div className="d-flex flex-column">
        <Info
          value={t("jobsite.profile.myApplication.startDate") as string}
          classes="mb-10"
        />
        <div className="d-flex">
          <Dropdown
            classes={{ root: "d-flex-equal mr-20" }}
            id={form.startMonth.id as string}
            required={true}
            options={monthOptions}
            value={form.startMonth?.value}
            handleValueSelect={onStartMonthChange}
            name={t("jobsite.profile.myApplication.month") as string}
            label={t("jobsite.profile.myApplication.month") as string}
            error={
              displayErrors && form.startMonth.error
                ? (t(form.startMonth.errori18nMsgKey as string) as string)
                : false
            }
            errorA11y={t("jobsite.common.errorIconLabel") as string}
          />
          <Dropdown
            classes={{ root: "d-flex-equal" }}
            id={form.startYear.id as string}
            required={true}
            options={yearOptions}
            value={form.startYear?.value}
            handleValueSelect={onStartYearChange}
            name={t("jobsite.profile.myApplication.year") as string}
            label={t("jobsite.profile.myApplication.year") as string}
            error={
              displayErrors && form.startYear.error
                ? (t(form.startYear.errori18nMsgKey as string) as string)
                : false
            }
            errorA11y={t("jobsite.common.errorIconLabel") as string}
          />
        </div>
        {form?.currentEmployer.value === "false" && (
          <div className="d-flex flex-column">
            <Info
              value={t("jobsite.profile.myApplication.endDate") as string}
              classes="mb-10"
            />
            <div className="d-flex">
              <Dropdown
                classes={{ root: "d-flex-equal mr-20" }}
                required={true}
                id={form.endMonth?.id as string}
                options={monthOptions}
                value={form.endMonth?.value}
                handleValueSelect={onEndMonthChange}
                name={t("jobsite.profile.myApplication.month") as string}
                label={t("jobsite.profile.myApplication.month") as string}
                error={
                  displayErrors && form.endMonth?.error
                    ? (t(form.endMonth?.errori18nMsgKey as string) as string)
                    : false
                }
                errorA11y={t("jobsite.common.errorIconLabel") as string}
              />
              <Dropdown
                classes={{ root: "d-flex-equal" }}
                required={true}
                id={form.endYear?.id as string}
                options={yearOptions}
                value={form.endYear?.value}
                handleValueSelect={onEndYearChange}
                name={t("jobsite.profile.myApplication.year") as string}
                label={t("jobsite.profile.myApplication.year") as string}
                error={
                  displayErrors && form.endYear?.error
                    ? (t(form.endYear.errori18nMsgKey as string) as string)
                    : false
                }
                errorA11y={t("jobsite.common.errorIconLabel") as string}
              />
            </div>
          </div>
        )}
      </div>
      <div className="mt-20">
        <Info
          value={t("jobsite.profile.myApplication.reasonForLeaving") as string}
          classes="mb-10"
        />
        <TextArea
          id={form.reasonToLeave?.id as string}
          required={true}
          onValueChange={onReasonToLeaveChange}
          onKeyDown={validateReasonForLeavingAndRelation}
          onPaste={onPasteValidateReasonForLeavingAndRelation}
          characterCounter={form.reasonToLeave?.value?.length}
          value={form.reasonToLeave?.value}
          maxLength={form.reasonToLeave?.maxLength}
          error={
            displayErrors && form.reasonToLeave?.error
              ? (t(form.reasonToLeave.errori18nMsgKey as string) as string)
              : false
          }
          errorA11y={t("jobsite.common.errorIconLabel") as string}
          aria-label={
            t("jobsite.profile.myApplication.reasonForLeaving") as string
          }
        ></TextArea>
      </div>
      {canRemove && (
        <span className="mr-20">
          <TextButton
            label={t("jobsite.profile.myApplication.remove") as string}
            onClick={onRemove}
            icon="icon-minuscircle"
          />
        </span>
      )}
      {showAddMore && (
        <TextButton
          label={
            t("jobsite.profile.myApplication.addAnotherEmployer") as string
          }
          onClick={onAdd}
          icon="icon-pluscircle"
        />
      )}
    </div>
  );
}

const ProfExpView = (props: {
  empAtleastOneJob: boolean;
  employments?: AppEmploymentDetails[];
}) => {
  const { empAtleastOneJob, employments } = props;
  const { t } = useIntlMessage();
  return (
    <>
      {!empAtleastOneJob && (
        <div className="d-flex flex-column mt-10">
          <Label
            label={
              t("jobsite.profile.myApplication.empSummaryHistory") as string
            }
          ></Label>
          <Info
            value={
              empAtleastOneJob
                ? (t("jobsite.profile.myApplication.yes") as string)
                : (t("jobsite.profile.myApplication.no") as string)
            }
          ></Info>
        </div>
      )}
      {empAtleastOneJob &&
        employments &&
        (employments || []).map((emp, idx) => (
          <div
            key={idx}
            className={`large-12 small-12 mt-10 ${idx !== employments.length - 1 ? "u-border-bottom" : ""}`}
          >
            <div className="d-flex flex-column mb-10">
              <Label
                label={
                  t("jobsite.profile.myApplication.employerJobTitle") as string
                }
              />
              <Info value={emp.employer} />
              <Info value={emp.jobTitle} />
            </div>
            <div className="d-flex mb-10">
              <div className="d-flex-equal">
                <div>
                  <Label
                    label={
                      t(
                        "jobsite.profile.myApplication.contactInfoLabel",
                      ) as string
                    }
                  />
                </div>
                <div className="d-flex flex-column">
                  <Info value={emp.country} />
                  {emp.city && <Info value={emp.city} />}
                  {emp.state && <Info value={emp.state} />}
                  {emp.verificationPhone && (
                    <Info value={emp.verificationPhone} />
                  )}
                </div>
              </div>
              <div className="d-flex-equal">
                <div>
                  <Label
                    label={
                      t("jobsite.profile.myApplication.statusComp") as string
                    }
                  />
                </div>
                <div className="d-flex flex-column">
                  {emp.currentEmployer && (
                    <>
                      <Info
                        value={
                          t(
                            "jobsite.profile.myApplication.currentEmployer",
                          ) as string
                        }
                      />
                      <Info
                        value={`${t("jobsite.profile.myApplication.from") as string} ${emp.startMonthName}, ${emp.startYear}`}
                      />
                    </>
                  )}
                  {!emp.currentEmployer && (
                    <>
                      <Info
                        value={
                          t(
                            "jobsite.profile.myApplication.prevEmployer",
                          ) as string
                        }
                      />
                      <Info
                        value={`${emp.startMonthName}, ${emp.startYear} - ${emp.endMonthName}, ${emp.endYear}`}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex flex-column">
              <Label
                label={
                  t("jobsite.profile.myApplication.reasonForLeaving") as string
                }
              />
              <Info value={emp.reasonToLeave} />
            </div>
          </div>
        ))}
    </>
  );
};
