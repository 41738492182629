import { Button, Overlay } from "@rpe-js/marcom-web-components";
import React, { useContext, useState } from "react";
import { LocationPickerContext } from "../../contexts/LocationPickerContext";
import useIntlMessage from "../../hooks/useIntlMessage";
import { idGenerator } from "../../utils/idGenerator";
import LocationPickerStoreBenefitsModalContent from "./LocationPickerStoreBenefitsModalContent";

type Props = {
  locationId: string | undefined;
  storeName: string | undefined;
  city: string | undefined;
};

function LocationPickerStoreBenefitsModal(props: Props) {
  const context = useContext(LocationPickerContext);
  if (!context) {
    throw new Error(
      "LocationPickerHeader must be used within a LocationPickerProvider",
    );
  }
  const locationId = props.locationId as string;
  const storeName = props.storeName as string;
  const { t } = useIntlMessage();
  const { jobDetails, countryCode } = context;
  const [showModal, setShowModal] = useState(false);
  const PAY_AND_BENEFITS_BTN_ID = idGenerator(
    "locationPicker",
    "pay-and-benefits",
  ).generateId(`${locationId}-button`);
  const payAndBenefitsAriaLabel = `${t("jobsite.locationPicker.payAndBenefits") as string}, ${props.city} (${props.storeName})`;

  const onClose = () => {
    setShowModal(false);
  };

  async function handleClick() {
    setShowModal(true);
  }

  return (
    <div>
      <Button
        onClick={handleClick}
        color={"secondary-neutral"}
        size={"base"}
        label={t("jobsite.locationPicker.payAndBenefits") as string}
        aria-label={payAndBenefitsAriaLabel}
        id={PAY_AND_BENEFITS_BTN_ID}
      ></Button>
      {showModal && (
        <Overlay
          id={locationId}
          wide={false}
          visible={showModal}
          onClose={onClose}
          elementIdToFocus={PAY_AND_BENEFITS_BTN_ID}
          ariaLabel={idGenerator(
            "locationPicker",
            "pay-benefits-modal",
          ).generateId("title")}
          closeButtonAttrs={{
            ariaLabel: t("jobsite.common.close") as string,
          }}
        >
          <LocationPickerStoreBenefitsModalContent
            jobId={jobDetails.id}
            locationId={locationId}
            locale={countryCode.locale}
            storeName={storeName}
            city={props.city}
          ></LocationPickerStoreBenefitsModalContent>
        </Overlay>
      )}
    </div>
  );
}

export default LocationPickerStoreBenefitsModal;
