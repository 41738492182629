import React from "react";
import { LinkedInProfilePreview } from "../../../../shared/types/config";
import {
  profileUpdateMode,
  ProfileUpdateSelection,
} from "../../../pages/apply/context/ApplyState";
import { ChoiceInput } from "../../../types/ChoiceInput";
import { FileData } from "../../../types/File";
import { LinkedInData } from "../../../types/LinkedIn";
import { LinkedInWidget } from "./LinkedInWidget";
import { ProfileFillOption } from "./ProfileFillOption";
import { ResumeWidget } from "./ResumeWidget";

interface ResumeWidgetWrapperProps {
  module?: string;
  resumeData: FileData | null;
  linkedInData: LinkedInData | null;
  profileFillOptions: Array<ChoiceInput>;
  profileSelectionMode?: ProfileUpdateSelection;
  canShowProfileOptions?: boolean;
  errorMessage?: string;
  locale: string;
  removeErrorMessage?: () => void;
  changeProfileOption: (selection: ProfileUpdateSelection) => void;
  resumeFileHandler: (data: FileData) => void;
  resumeTextHandler: (data: FileData) => void;
  removeLinkedIn: () => void;
  addLinkedIn: (profileData: LinkedInProfilePreview) => void;
}

export function ResumeWidgetWrapper({
  module = "",
  resumeData,
  linkedInData,
  locale,
  profileFillOptions,
  profileSelectionMode = profileUpdateMode.MANUAL,
  canShowProfileOptions = true,
  errorMessage = "",
  removeErrorMessage,
  changeProfileOption,
  resumeFileHandler,
  resumeTextHandler,
  addLinkedIn,
  removeLinkedIn,
}: ResumeWidgetWrapperProps) {
  return (
    <>
      <ResumeWidget
        locale={locale}
        errorMessage={errorMessage}
        removeErrorMessage={removeErrorMessage}
        data={resumeData}
        resumeFileHandler={resumeFileHandler}
        resumeTextHandler={resumeTextHandler}
      ></ResumeWidget>
      <LinkedInWidget
        locale={locale}
        url={linkedInData ? linkedInData.url : ""}
        addedOn={linkedInData ? linkedInData.addedOn : new Date().getTime()}
        addLinkedIn={addLinkedIn}
        removeLinkedIn={removeLinkedIn}
      ></LinkedInWidget>
      {profileFillOptions &&
        profileFillOptions.length > 1 &&
        canShowProfileOptions &&
        (profileFillOptions[0].showOption ||
          profileFillOptions[1].showOption) && (
          <ProfileFillOption
            module={module}
            options={profileFillOptions}
            selection={profileSelectionMode}
            changeProfileOption={changeProfileOption}
          ></ProfileFillOption>
        )}
    </>
  );
}
