import { Button } from "@rpe-js/marcom-web-components";
import React, { useCallback } from "react";
import useIntlMessage from "../../hooks/useIntlMessage";
import { handleScrollToElement } from "../../utils/focusUtil";

export const SaveCancelAction = (props: {
  cancelLabelName?: string;
  saveLabelName?: string;
  onCancel: () => void;
  onSave: () => void;
  classes?: string;
  cancelButtonId: string;
  saveButtonId: string;
  saveDisabled?: boolean;
  cancelDisabled?: boolean;
  scrollToTopSectionId?: string;
}) => {
  const { t } = useIntlMessage();
  const c = props.classes || "width-200 mt-20";
  const onCancelClick = useCallback(() => {
    props.onCancel();
    if (props.scrollToTopSectionId) {
      handleScrollToElement(props.scrollToTopSectionId);
    }
  }, [props]);

  return (
    <>
      <div className={`d-flex save-cancel-btngroup ${c}`}>
        <span className="mr-10 d-flex-equal">
          <Button
            id={props.cancelButtonId}
            size="base"
            color="secondary"
            blockedVariant={true}
            label={
              props.cancelLabelName || (t("jobsite.common.cancel") as string)
            }
            onClick={onCancelClick}
            disabled={props.cancelDisabled ?? false}
          />
        </span>
        <span className="d-flex-equal">
          <Button
            id={props.saveButtonId}
            size="base"
            color="primary"
            blockedVariant={true}
            label={props.saveLabelName || (t("jobsite.common.save") as string)}
            onClick={props.onSave}
            disabled={props.saveDisabled ?? false}
          />
        </span>
      </div>
    </>
  );
};
