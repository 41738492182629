import React, { useEffect, useState } from "react";
import { COUNT_DOWN_INTERVAL } from "../../../app.constants";
import translateReplace from "../../../hooks/translateReplace";
import HtmlRenderer from "../../base/HtmlRenderer";

interface CountDownTimerProps {
  totalTime: number; // in milliseconds
  sessionInactivityMessage: string;
  onTimeout: () => void; // callback to signal that the time has run out
}

const CountDownTimer: React.FC<CountDownTimerProps> = ({
  totalTime,
  sessionInactivityMessage,
  onTimeout,
}) => {
  const [timeRemaining, setTimeRemaining] = useState(totalTime);
  const [startTime, setStartTime] = useState<number | null>(null);

  const getFormattedTime = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  useEffect(() => {
    if (timeRemaining <= 0) {
      onTimeout();
      return;
    }

    const intervalId = setInterval(() => {
      const currentTime = Date.now();
      if (startTime === null) {
        setStartTime(currentTime);
      } else {
        const elapsedTime = currentTime - startTime;
        setTimeRemaining(() => Math.max(totalTime - elapsedTime, 0));
      }
    }, COUNT_DOWN_INTERVAL);

    return () => clearInterval(intervalId);
  }, [timeRemaining, onTimeout, totalTime, startTime]);

  return (
    <div>
      <HtmlRenderer
        initialContent={translateReplace(
          sessionInactivityMessage,
          ["{minutes}"],
          [getFormattedTime(timeRemaining)],
        )}
        classes="p-lead custom-word-break"
        id="timeout-header-msg"
        htmlTag="p"
      />
    </div>
  );
};

export default CountDownTimer;
