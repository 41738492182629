import {
  Button,
  DropDownOptionProps,
  NativeButton,
  Popover,
  ProgressIndicatorLoader,
  Textbox,
} from "@rpe-js/marcom-web-components";
import Accordion, {
  AccordionItem,
} from "@rpe-js/marcom-web-components/lib/CustomAccordion/CustomAccordion";
import { cloneDeep } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { SavedSearchType } from "../../../shared/types";
import { Team } from "../../../shared/types/savedSearchTypes";
import {
  createSavedSearch,
  getJobAgentEmailExperience,
  getJobAgentEmailFrequency,
  getSavedSearches,
} from "../../api/fetchClient";
import { SAVED_SEARCH_MAX_LIMIT } from "../../app.constants";
import AppContext from "../../AppContext";
import Icon from "../../components/base/IconComponent";
import SavedSearchesSelector from "../../components/feature/savedSearchesSelector";
import {
  SET_SAVE_SEARCH_OPTIONS,
  SET_SAVED_SEARCH_DETAILS,
  SET_SAVED_SEARCH_LIST,
} from "../../contexts/actionTypes";
import { useFetchData } from "../../hooks/useFetchData";
import { useFetchMultipleData } from "../../hooks/useFetchMultipleData";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { useSearchContext } from "../../hooks/useSearchContext";
import { idGenerator } from "../../utils/idGenerator";
import UnauthenticatedPopover from "./UnauthenticatedPopover";

export interface SaveSearchProps {
  id: string;
}

function CreateSaveSearch({ id }: SaveSearchProps) {
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { isSessionAuthenticated, talentId, isInternal } = appUIState.appData;
  const {
    state: {
      filters,
      jobAgentEmailFrequency,
      jobAgentEmailExperience,
      currentSavedSearchData,
      savedSearchList,
      search,
    },
    dispatch,
  } = useSearchContext();

  const isMobile = useIsMobile();
  const [showSignInPopover, setShowSignInPopover] = useState(false);
  const [showSaveSearchPopover, setShowSaveSearchPopover] = useState(false);
  const [searchData, setSearchData] = useState<SavedSearchType>(
    {} as SavedSearchType,
  );
  const [nameError, setNameError] = useState("");

  const {
    isLoading: isSaveOptionsLoading,
    isSuccess: isSaveOptionsSuccess,
    fetchData: fetchSaveOptions,
    data: saveOptions,
  } = useFetchMultipleData([
    getJobAgentEmailExperience,
    getJobAgentEmailFrequency,
  ]);

  const {
    isLoading: isSearchListDataLoading,
    isSuccess: isSearchListDataSuccess,
    fetchData: fetchSearchData,
    data: savedSearches,
  } = useFetchData(getSavedSearches);

  const {
    isLoading: isSaveSearchLoading,
    isSuccess: isSaveSearchSuccess,
    fetchData: saveSearch,
    data: savedSearchData,
  } = useFetchData(createSavedSearch);

  const onUnAuthenticatedPopupClose = useCallback(() => {
    setShowSignInPopover(false);
  }, []);

  const onSaveSearchPopupClose = useCallback(() => {
    setShowSaveSearchPopover(false);
  }, []);
  const handleSaveSearchPopover = useCallback(() => {
    if (
      !showSaveSearchPopover &&
      savedSearchList?.length !== SAVED_SEARCH_MAX_LIMIT
    ) {
      if (!jobAgentEmailExperience.length && !jobAgentEmailFrequency.length) {
        // If the refdata for jobAgentEmailExperience and jobAgentEmailFrequency not available then fetch the refdata
        fetchSaveOptions([[talentId]]);
      }
      // We have validations on save search name. Fetching the talents saved search list to validate the save search name
      fetchSearchData([talentId]);
    }
    setNameError("");
    setShowSaveSearchPopover(!showSaveSearchPopover);
  }, [
    fetchSaveOptions,
    fetchSearchData,
    jobAgentEmailExperience.length,
    jobAgentEmailFrequency.length,
    savedSearchList?.length,
    showSaveSearchPopover,
    talentId,
  ]);

  useEffect(() => {
    if (!isSearchListDataLoading && isSearchListDataSuccess) {
      dispatch({ type: SET_SAVED_SEARCH_LIST, payload: savedSearches });
    }
  }, [
    dispatch,
    isSearchListDataLoading,
    isSearchListDataSuccess,
    savedSearches,
  ]);

  const saveSearchPopupHandler = useCallback(async () => {
    if (!isSessionAuthenticated) {
      setShowSignInPopover(!showSignInPopover);
    } else {
      handleSaveSearchPopover();
    }
  }, [handleSaveSearchPopover, isSessionAuthenticated, showSignInPopover]);

  const onDropdownChange = useCallback(
    (option: DropDownOptionProps, name: string) => {
      const searchCopy = cloneDeep(searchData);
      if (name === "emailAlertFreqency") {
        searchCopy.emailAlertFrequency = {
          freqID: option.value,
          emailAlertFrequency: option.label,
        };
      } else if (name === "emailExpiration") {
        searchCopy.emailAlertExpiration = {
          expID: option.value,
          emailAlertExpiration: option.label,
        };
      }
      setSearchData(searchCopy);
    },
    [searchData],
  );

  const searchNameChange = useCallback(
    (value: string) => {
      const searchCopy = cloneDeep(searchData);
      searchCopy.searchName = value;
      setSearchData(searchCopy);
    },
    [searchData],
  );

  const checkError = useCallback(() => {
    const searchedName = searchData?.searchName?.trim();
    if (!searchedName) {
      setNameError(t("jobsite.search.searchNameRequired") as string);
    } else if (
      savedSearchList.findIndex(
        (item) =>
          item.searchName.toLocaleLowerCase() ===
          searchData.searchName.toLocaleLowerCase(),
      ) >= 0
    ) {
      setNameError(t("jobsite.search.searchNameExists") as string);
    } else if (
      searchData.searchName.match(
        new RegExp(/[~`!#@$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g),
      )
    ) {
      setNameError(t("jobsite.search.searchNameInvalid") as string);
    } else {
      setNameError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData]);

  const getTeamsPayload = useCallback(() => {
    const teams: Team[] = [];
    if (searchData.teams?.length) {
      searchData.teams.forEach((team: Team) => {
        const selectedTeams = teams?.map((t) => t.teamID);
        if (!selectedTeams.includes(team.teamID)) {
          teams.push(team);
        } else {
          const teamToUpdateIdx = teams.findIndex(
            (t) => t.teamID === team.teamID,
          );
          teams[teamToUpdateIdx].subTeams.push(...team.subTeams);
        }
      });
    }
    return teams;
  }, [searchData.teams]);

  const saveHandler = useCallback(async () => {
    if (!searchData.searchName && !nameError) {
      checkError();
      return;
    }
    if (nameError) {
      return;
    }
    const payload = cloneDeep(searchData);
    const teamsPayload = getTeamsPayload();
    if (teamsPayload.length) {
      payload.teams = teamsPayload;
    }
    saveSearch([talentId, payload]);
  }, [
    checkError,
    getTeamsPayload,
    nameError,
    saveSearch,
    searchData,
    talentId,
  ]);

  useEffect(() => {
    if (!isSaveOptionsLoading && isSaveOptionsSuccess) {
      dispatch({
        type: SET_SAVE_SEARCH_OPTIONS,
        payload: {
          jobAgentEmailExperience: saveOptions[0],
          jobAgentEmailFrequency: saveOptions[1],
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveOptionsLoading, isSaveOptionsSuccess, saveOptions]);

  useEffect(() => {
    if (!isSaveSearchLoading && isSaveSearchSuccess) {
      setShowSaveSearchPopover(!showSaveSearchPopover);
      dispatch({
        type: SET_SAVED_SEARCH_DETAILS,
        payload: savedSearchData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveSearchLoading, isSaveSearchSuccess, savedSearchData]);

  useEffect(() => {
    setSearchData({
      id,
      searchName: "",
      searchQuery: search || "",
      emailAlertExpiration: {
        emailAlertExpiration: jobAgentEmailExperience?.length
          ? jobAgentEmailExperience[0].name
          : "",
        expID: jobAgentEmailExperience?.length
          ? jobAgentEmailExperience[0].id
          : "",
      },
      emailAlertFrequency: {
        emailAlertFrequency: jobAgentEmailFrequency?.length
          ? jobAgentEmailFrequency[0].name
          : "",
        freqID: jobAgentEmailFrequency?.length
          ? jobAgentEmailFrequency[0].id
          : "",
      },
      keywords: filters.keywords,
      productLines:
        filters.products?.map((item) => ({
          productLineName: item.name,
          productLineID: item.id,
        })) || [],
      savedSearchLanguages:
        filters.languages?.map((item) => ({
          language: item.name,
          languageID: item.id,
        })) || [],
      savedSearchLocations:
        filters.locations?.map((item) => ({
          location: item.name,
          locationID: item.id,
        })) || [],
      teams:
        filters.teams?.map((item) => ({
          subTeams: [{ subTeamID: item.id, subTeamName: item.subTeam }],
          teamName: item.name,
          teamID: item.teamID,
        })) || [],
      ...(isInternal && {
        retailRoles:
          filters.retailRoles?.map((item) => ({
            roleID: item.id,
            roleName: item.name,
          })) || [],
        hiringManagers:
          filters.hiringManagers?.map((item) => ({
            managerID: item.id,
            name: item.name,
            location: item.location || "",
            costCenter: item.department || "",
          })) || [],
        retailWeeklyHoursMin: filters.minimumHours || "",
        retailWeeklyHoursMax: filters.maximumHours || "",
        jobLevel:
          filters.jobLevel && filters.jobLevel.length > 0
            ? {
                myLevel: filters.jobLevel[0]?.selected || false,
                myLevelPlusOne: filters.jobLevel[1]?.selected || false,
              }
            : undefined,
      }),
      url: new URL(window.location.href).search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, jobAgentEmailExperience, jobAgentEmailFrequency, isInternal]);

  const saveSearchButtonId = idGenerator("search", "save-search").generateId(
    "button",
  );

  const renderCreateSaveSearch = useCallback(() => {
    return (
      <>
        {(isSaveOptionsLoading ||
          isSaveSearchLoading ||
          isSearchListDataLoading) && (
          <ProgressIndicatorLoader showLoading={true} />
        )}

        {savedSearchList?.length === SAVED_SEARCH_MAX_LIMIT ? (
          <p className="max-limit-error">
            {t("jobsite.search.searchLimitError")}
          </p>
        ) : (
          searchData &&
          !(
            isSaveOptionsLoading ||
            isSaveSearchLoading ||
            isSearchListDataLoading
          ) && (
            <div className={!isMobile ? "row px-5 py-10" : ""}>
              <Textbox
                id={`${id}-saveSearchName`}
                required={true}
                key={t("jobsite.search.savedSearchName") as string}
                hideLabel={true}
                placeholder={t("jobsite.search.savedSearchName") as string}
                onValueChange={searchNameChange}
                onKeyUp={checkError}
                classes={{
                  error: "text-left",
                }}
                error={nameError}
                errorA11y={t("jobsite.common.errorIconLabel") as string}
              />
              <SavedSearchesSelector
                id={`${id}-emailAlertFreqency`}
                name="emailAlertFreqency"
                options={jobAgentEmailFrequency}
                selectedValue={searchData.emailAlertFrequency?.freqID}
                onDropdownChange={(option) =>
                  onDropdownChange(option, "emailAlertFreqency")
                }
              />
              <SavedSearchesSelector
                id={`${id}-emailExpiration`}
                name="emailExpiration"
                options={jobAgentEmailExperience}
                selectedValue={searchData.emailAlertExpiration?.expID}
                onDropdownChange={(option) =>
                  onDropdownChange(option, "emailExpiration")
                }
              />
              <p id="descSaveSearchFilters" className="text-left option-text">
                {t("jobsite.search.alertEmailMessage")}
              </p>
              <Button
                id={saveSearchButtonId}
                size="base"
                label={t("jobsite.search.saveSearch") as string}
                color="primary"
                blockedVariant={true}
                onClick={saveHandler}
              ></Button>
            </div>
          )
        )}
      </>
    );
  }, [
    checkError,
    id,
    isSearchListDataLoading,
    isMobile,
    isSaveOptionsLoading,
    isSaveSearchLoading,
    jobAgentEmailExperience,
    jobAgentEmailFrequency,
    nameError,
    onDropdownChange,
    saveHandler,
    saveSearchButtonId,
    savedSearchList?.length,
    searchData,
    searchNameChange,
    t,
  ]);

  const renderUnauthenticatedContent = useCallback(() => {
    return (
      <UnauthenticatedPopover
        view={isMobile ? "content" : "popover"}
        id={`${id}-unauthenticated-popover`}
        message="jobsite.search.signinMessage"
        triggerElementId={`${id}-button`}
        arrowPosition="end"
        onClose={onUnAuthenticatedPopupClose}
      />
    );
  }, [id, isMobile, onUnAuthenticatedPopupClose]);

  const renderSaveSearchButton = useCallback(() => {
    return (
      <NativeButton
        id={`${id}-button`}
        className={`create-save-search-button ${!currentSavedSearchData && "blue"}`}
        onClick={saveSearchPopupHandler}
        disabled={!!currentSavedSearchData}
      >
        <Icon
          name={
            !!currentSavedSearchData ? "saved-search-grey" : "saved-search-blue"
          }
          size="medium"
          cursor="pointer"
        ></Icon>
        {!!currentSavedSearchData
          ? t("jobsite.search.searchSaved")
          : t("jobsite.search.saveSearch")}
      </NativeButton>
    );
  }, [currentSavedSearchData, id, saveSearchPopupHandler, t]);
  return (
    <section className={isMobile ? "pos-rel bottom-20" : ""}>
      {(!isMobile || (isMobile && isSaveSearchSuccess)) &&
        renderSaveSearchButton()}
      {isMobile && !isSaveSearchSuccess && (
        <>
          <Accordion
            title={t("jobsite.search.unauthenticatedMessage") as string}
          >
            <AccordionItem
              titleWrapperTag={React.Fragment} // This needs no heading
              title={renderSaveSearchButton()}
              showNoIcon={true}
              classes={{
                button: showSignInPopover || showSaveSearchPopover ? "" : "p-0",
                root:
                  showSignInPopover || showSaveSearchPopover
                    ? ""
                    : "selected-options-item",
              }}
            >
              {showSignInPopover &&
                !isSessionAuthenticated &&
                renderUnauthenticatedContent()}
              {showSaveSearchPopover &&
                isSessionAuthenticated &&
                renderCreateSaveSearch()}
            </AccordionItem>
          </Accordion>
        </>
      )}
      {!isMobile && showSignInPopover && !isSessionAuthenticated && (
        <>
          <div className="save-search-unauthenticated">
            {renderUnauthenticatedContent()}
          </div>
        </>
      )}
      {!isMobile && showSaveSearchPopover && isSessionAuthenticated && (
        <>
          <div className="save-search-popover">
            <Popover
              id={`${id}-search-popover`}
              aria-describedby="descSaveSearchFilters"
              aria-label={t("jobsite.search.saveSearch") as string}
              arrowPosition="end"
              triggerElementId={`${id}-button`}
              onClose={onSaveSearchPopupClose}
            >
              {renderCreateSaveSearch()}
            </Popover>
          </div>
        </>
      )}
    </section>
  );
}

export default CreateSaveSearch;
