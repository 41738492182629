import React from "react";
import { LabelInfoView } from "../../pages/profile/components/LabelInfoView";

export function NameView({
  labelKey,
  value,
}: {
  labelKey: string;
  value: string | undefined;
}) {
  const nameI18Map: Record<string, string> = {
    firstName: "jobsite.common.firstName",
    lastName: "jobsite.common.lastName",
    preferredName: "jobsite.common.preferredName",
    preferredPhone: "jobsite.common.preferredPhone",
    kanjiLastName: "jobsite.common.kanjiLastName",
    kanjiFirstName: "jobsite.common.kanjiFirstName",
    localLastName: "jobsite.common.localLastName",
    localFirstName: "jobsite.common.localFirstName",
    pronouns: "jobsite.common.pronouns",
  };
  return (
    <LabelInfoView
      i18nLabel={nameI18Map[labelKey]}
      value={value}
    ></LabelInfoView>
  );
}
