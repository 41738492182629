import React from "react";
import { convertTextToList } from "../../../utilities/util";
import HtmlRenderer from "../../components/base/HtmlRenderer";
import { idGenerator } from "../../utils/idGenerator";

export interface JobDetailsRowContentType {
  id: string;
  content: string;
  type: "list" | "text";
  whiteSpacePreWrap?: boolean;
  // isInternal: any;
  // isMain: any;
  // itemProp: any;
}

export default function JobDetailsRowContent({
  id,
  content,
  type,
  whiteSpacePreWrap = false,
}: JobDetailsRowContentType) {
  const idGeneratorContainer = idGenerator("jobdetails", id);

  function renderList(id: string) {
    const removeDots = id === "key-qualifications";
    const dataArray: string[] = convertTextToList(content, removeDots);
    let list: { listClass: string; listText: string }[] = dataArray
      .filter((listVal) => listVal !== "")
      .map((listText) => ({ listText, listClass: "pb-10" }));
    if (id === "additional-requirements") {
      const liRegex = /<li>|<\/li>/gi;
      list = list.map((item) => {
        item.listClass = item.listText.includes("<li>") ? "list__text" : "";
        item.listText = item.listText.replace(liRegex, "").trim();
        return item;
      });
    }
    return (
      <ul role="list" className="disc-list">
        {list.map((item, index) => (
          <li key={index} role="listitem" className={`${item.listClass}`}>
            <HtmlRenderer
              classes="hyperlink-underline"
              initialContent={item.listText}
              htmlTag="span"
            />
          </li>
        ))}
      </ul>
    );
  }

  return content && content.length ? (
    <div
      id={idGeneratorContainer.generateId("row")}
      className={`t-body ${whiteSpacePreWrap ? "white-space-pre-wrap" : ""}`}
    >
      {type === "list" ? (
        renderList(id)
      ) : (
        <HtmlRenderer initialContent={content} htmlTag="span" />
      )}
    </div>
  ) : null;
}
