import { profileUpdateMode } from "../pages/apply/context/ApplyState";
import { ProfileUpdateSelection } from "../types";
import { FileData } from "../types/File";

export function isTriggerPolling(
  resume: FileData | null,
  textResume: FileData | null,
  profileUpdateSelection: ProfileUpdateSelection | undefined,
  linkedinSnapshot: string,
) {
  return (
    ((!resume?.fileId || (textResume && !textResume.fileId)) &&
      profileUpdateSelection === profileUpdateMode.RESUME) ||
    (profileUpdateSelection === profileUpdateMode.LINKEDIN && linkedinSnapshot)
  );
}
