import { Overlay } from "@rpe-js/marcom-web-components";
import React, { useContext } from "react";
import AppContext from "../../../AppContext";
import { LOGOUT, TIMEOUT } from "../../../app.constants";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { redirectToPath } from "../../../util";
import { idGenerator } from "../../../utils/idGenerator";
import { SaveCancelAction } from "../saveAndCancel";
import SessionInactivity from "./SessionInactivity";

interface SessionInactivityComponentProps {
  showModal: boolean;
  continueSession: () => void;
  signOut: () => void;
}

export default function SessionInactivityModal({
  showModal,
  continueSession,
  signOut,
}: SessionInactivityComponentProps) {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const { appUIState } = useContext(AppContext);
  const { locale, appBasePathPrefix, isExternal } = appUIState.appData;
  const idSeqGenerator = idGenerator("sessioninactivity", "modal");
  const titleId = idSeqGenerator.generateId("title");
  const sessionInactivityMessage = t(
    "jobsite.common.sessionInactivityDescription",
  ) as string;

  const handleTimeout = () => {
    if (isExternal) {
      redirectToPath(
        appBasePathPrefix,
        `${locale}/${LOGOUT}?action=${TIMEOUT}`,
      );
      return;
    }
    redirectToPath(appBasePathPrefix, `${locale}/session-timeout-page`);
  };

  return (
    <Overlay
      id={idSeqGenerator.generateId("")}
      ariaLabel={titleId}
      elementIdToFocus="" // Capture the active element in a util (getActiveElementId) and send its element id to the overlay. When
      wide={false}
      noCloseButton={true}
      visible={showModal}
      onClose={() => {}}
      isFullscreen={isMobile}
      footerContent={
        <div className="d-flex justify-center mb-40">
          <SaveCancelAction
            cancelLabelName={t("jobsite.common.signOut") as string}
            saveLabelName={t("jobsite.common.staySignedIn") as string}
            onCancel={signOut} // OnCancel should gain the focus back to the document active element.
            onSave={continueSession} // Cancel should gain the focus back to the document active element.
            cancelButtonId={idGenerator(
              "sessioninactivity",
              "modal",
            ).generateId("signout-button")}
            saveButtonId={idGenerator("sessioninactivity", "modal").generateId(
              "staysignedin-button",
            )}
          ></SaveCancelAction>
        </div>
      }
    >
      <SessionInactivity
        title={t("jobsite.common.sessionInactivity") as string}
        titleId={titleId}
        sessionInactivityMessage={sessionInactivityMessage}
        linkedInMessage={t("jobsite.common.timeoutLinkedinAlert")}
        handleTimeout={handleTimeout}
      ></SessionInactivity>
    </Overlay>
  );
}
