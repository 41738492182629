import { ProgressIndicatorLoader } from "@rpe-js/marcom-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "../../../../shared/types/talent/talent";
import { TalentFileMetaData } from "../../../../shared/types/talent/talentFileMetatdata";
import {
  deleteFilePromises,
  downloadFile,
  getTalentFileMetaData,
  updateFileCategoryPromises,
  updateTalent,
  uploadSupportingFilePromises,
} from "../../../api/fetchClient";
import { PRIMARY_TYPE, PROFILE_SECTIONS } from "../../../app.constants";
import HtmlRenderer from "../../../components/base/HtmlRenderer";
import { Info } from "../../../components/base/Info";
import { Label } from "../../../components/base/Label";
import { SaveCancelAction } from "../../../components/feature/saveAndCancel";
import { SupportingFileWidget } from "../../../components/feature/widgets/SupportingFileWidget";
import { useFetchData } from "../../../hooks/useFetchData";
import useFocusFirstErrorField from "../../../hooks/useFocusFirstErrorField";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import {
  TalentSupportingFile,
  TalentSupportingLink,
} from "../../../types/SupportingFileWidget";
import { supportFileMapper } from "../../../utils/fileUtil";
import { idGenerator } from "../../../utils/idGenerator";
import {
  linkFilter,
  validateSupportFiles,
  validateSupportLinks,
} from "../../../utils/validatorUtil";
import { setFileMetaData, updateLinks } from "../context/Actions";
import { useProfileContext } from "../context/ProfileContext";
import { ProfileSection } from "./profileSection";

export const SupportLinkSection = () => {
  const [isEdit, setEdit] = useState<boolean>(false);
  const { state, dispatch } = useProfileContext();
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const [supportLinksForm, setSupportLinksForm] = useState(state?.links);
  const supportLinksIdGenerator = idGenerator("profile", "links");
  const {
    isLoading: isSaveTalentLoading,
    isSuccess: isSaveTalentSuccess,
    fetchData: saveTalent,
  } = useFetchData(updateTalent);
  const {
    isLoading: isGetFileMetaDataLoading,
    isSuccess: isGetFileMetaDataSuccess,
    fetchData: getFileMetaData,
    data: fileMetaData,
  } = useFetchData(getTalentFileMetaData);
  const [discardFileData, setDiscardFileData] = useState<
    Array<TalentSupportingFile>
  >([]);
  const [canShowError, setCanShowError] = useState(false);
  const getSupportingFiles = useCallback((): TalentFileMetaData[] => {
    const supportingFiles = state?.fileMetaData?.filter(
      (fileData: TalentFileMetaData) => fileData.typeId !== PRIMARY_TYPE,
    );
    return supportingFiles?.length ? supportingFiles : [];
  }, [state?.fileMetaData]);
  const [fileData, setFileData] = useState<Array<TalentSupportingFile>>(
    getSupportingFiles()?.map(supportFileMapper),
  );
  const { setFocusFirstErrorField } = useFocusFirstErrorField(
    supportLinksIdGenerator.generateId(),
  );

  const onSupportFileChange = useCallback(
    (supportFileData: Array<TalentSupportingFile>) => {
      setFileData(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        supportFileData.map(({ id, ...rest }: TalentSupportingFile) => rest),
      );
    },
    [],
  );

  const onSupportLinkChange = (e: any) => {
    setSupportLinksForm([...e]);
  };

  const getLinksPayload = useCallback(() => {
    return (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (supportLinksForm?.map(({ id, ...rest }: any) => rest) as Link[]) || []
    );
  }, [supportLinksForm]);

  const onRemovesupportFile = useCallback(
    (removedFile: TalentSupportingFile) => {
      if (removedFile.fileData && removedFile.fileData.fileId) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, ...rest } = removedFile;
        setDiscardFileData([...discardFileData, { ...rest }]);
      }
    },
    [discardFileData],
  );

  const getDiscardedFilesToDelete = useCallback(() => {
    return (
      (discardFileData &&
        discardFileData.map(
          (value: TalentSupportingFile) => value.fileData.fileId as string,
        )) ||
      []
    );
  }, [discardFileData]);

  const updatedFileCategoryPromises = useCallback(() => {
    const unChangedFiles = fileData?.filter((f) => f.fileData?.fileId);
    if (unChangedFiles?.length) {
      const changedFilesMap: any = {};
      unChangedFiles.forEach((f) => {
        if (f.fileData?.fileId) {
          changedFilesMap[f.fileData?.fileId] = {
            typeId: f.category,
          };
        }
      });
      return updateFileCategoryPromises(state.talentId, changedFilesMap);
    }
    return [];
  }, [fileData, state.talentId]);

  const onEdit = useCallback(() => {
    setCanShowError(false);
    setDiscardFileData([]);
    setSupportLinksForm(state?.links);
    setFileData(getSupportingFiles()?.map(supportFileMapper));
    setEdit(true);
  }, [getSupportingFiles, state?.links]);

  const onSave = useCallback(async () => {
    setCanShowError(true);
    if (
      !validateSupportFiles(fileData) ||
      !validateSupportLinks(supportLinksForm)
    ) {
      setFocusFirstErrorField(true);
      return;
    }

    const allPromises = [
      ...uploadSupportingFilePromises(state.talentId, fileData, false),
      ...deleteFilePromises(state.talentId, getDiscardedFilesToDelete()),
      ...updatedFileCategoryPromises(),
    ];
    for (const promise of allPromises) {
      await promise();
    }
    saveTalent([
      state?.talentId,
      PROFILE_SECTIONS.LINKS,
      { links: getLinksPayload() },
    ]);
    getFileMetaData([state?.talentId]);
  }, [
    fileData,
    getDiscardedFilesToDelete,
    getFileMetaData,
    getLinksPayload,
    saveTalent,
    state.talentId,
    supportLinksForm,
    updatedFileCategoryPromises,
    setFocusFirstErrorField,
  ]);

  useEffect(() => {
    if (!isSaveTalentLoading && isSaveTalentSuccess) {
      dispatch?.(updateLinks(getLinksPayload()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveTalentLoading, isSaveTalentSuccess]);

  useEffect(() => {
    if (!isGetFileMetaDataLoading && isGetFileMetaDataSuccess) {
      dispatch?.(setFileMetaData(fileMetaData as TalentFileMetaData[]));
      setEdit(false);
    }
  }, [
    dispatch,
    fileMetaData,
    isGetFileMetaDataLoading,
    isGetFileMetaDataSuccess,
  ]);

  return (
    <ProfileSection
      isEdit={isEdit}
      i18nTitle="jobsite.information.supportLinks"
      onEdit={onEdit}
      id={supportLinksIdGenerator.generateId()}
      sectionName="links"
    >
      {isEdit && (
        <>
          {(isSaveTalentLoading || isGetFileMetaDataLoading) && (
            <ProgressIndicatorLoader showLoading={true} />
          )}
          <SupportingFileWidget
            onSupportingFileChange={onSupportFileChange}
            onSupportingLinkChange={onSupportLinkChange}
            talentSupportFileData={fileData}
            talentSupportLinkData={state?.links as TalentSupportingLink[]}
            onRemoveSupportingFile={onRemovesupportFile}
            onSupportingFileCategoryChange={() => {}}
            align={isMobile ? "center" : "left"}
            canShowError={canShowError}
            setCanShowError={setCanShowError}
          />
          <SaveCancelAction
            onCancel={() => {
              setCanShowError(false);
              setEdit(false);
            }}
            onSave={onSave}
            cancelButtonId={supportLinksIdGenerator.generateId("cancelButton")}
            saveButtonId={supportLinksIdGenerator.generateId("saveButton")}
            scrollToTopSectionId={supportLinksIdGenerator.generateId()}
          />
        </>
      )}
      {!isEdit && (
        <div className="row mb-10">
          <div className="column large-6 small-12 d-flex flex-column">
            <Label label={t("jobsite.common.supportingFiles") as string} />
            {getSupportingFiles()?.length !== 0 &&
              getSupportingFiles()?.map(
                (fileData: TalentFileMetaData, index: number) => (
                  <div key={index} className="mb-10">
                    <HtmlRenderer
                      initialContent={`${t("jobsite.common.fileData", {
                        fileData: fileData.type,
                      })}`}
                    ></HtmlRenderer>
                    <a
                      href="#"
                      className="attach-title"
                      onClick={(event) => {
                        event.preventDefault();
                        downloadFile(
                          state.talentId,
                          fileData.fileId,
                          fileData.name as string,
                          fileData.bucketId as string,
                        );
                      }}
                    >
                      {fileData.name}
                    </a>
                  </div>
                ),
              )}
            {!getSupportingFiles()?.length && (
              <Info value={t("jobsite.common.notSpecified") as string} />
            )}
          </div>
          <div className="column large-6 small-12 d-flex flex-column">
            <Label label={t("jobsite.common.supportingLinks") as string} />
            {state?.links?.length !== 0 &&
              state?.links?.map((linkData: Link, index: number) => (
                <div key={index} className="mb-10">
                  <HtmlRenderer
                    initialContent={`${t("jobsite.common.linkData", {
                      linkData: linkData.categoryName,
                    })}`}
                  />
                  <a
                    className="supporting-link"
                    href={linkFilter(linkData.link as string)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {linkData.link}
                  </a>
                </div>
              ))}
            {!state?.links?.length && (
              <Info value={t("jobsite.common.notSpecified") as string} />
            )}
          </div>
        </div>
      )}
    </ProfileSection>
  );
};
