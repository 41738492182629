import React, { ReactNode, useEffect, useReducer } from "react";
import { ADDRESS_PREFERRED } from "../../../app.constants";
import { stepAction } from "./Actions";
import { ERPContext } from "./ERPContext";
import { reducer } from "./ERPReducer";
import { ERPState, StepName } from "./ErpState";

interface ERProviderProps {
  children: ReactNode;
  initialState?: ERPState;
}

export const ERPProvider: React.FC<ERProviderProps> = ({
  children,
  initialState = {
    page: {
      stepNames: [],
    },
    jobPositionIDs: [],
    contact: {
      firstName: "",
      lastName: "",
      preferredEmail: "",
      preferredPhone: "",
    },
    address: {
      countryID: "",
      countryName: "",
      typeID: ADDRESS_PREFERRED,
    },
    language: {
      languageID: "",
      languageName: "",
    },
    typeID: "",
    notes: "",
    resume: null,
    textResume: null,
    referenceData: {
      country: null,
      language: null,
      referralType: null,
      appleStoreRoles: null,
      postLocations: null,
      teamsOfInterest: null,
    },
    supportFiles: null,
    links: null,
    globaleManagedRoleIds: [],
    corporateLocations: [],
    retailLocations: [],
    teamsOfInterest: {},
    enableReviewSubmitBtn: false,
    teamsViewData: [],
    teamsPayload: null,
    showRetailModal: false,
  },
}: ERProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const stepNames = [
    StepName.CONTACT_INFO,
    StepName.INTEREST,
    StepName.LOCATION,
    StepName.REVIEW_INFO,
    StepName.THANKS_PAGE,
  ];
  const stepMapping = stepNames.reduce(
    (acc, curr, index) => {
      acc[curr] = index + 1;
      return acc;
    },
    {} as Record<string, number>,
  );

  useEffect(() => {
    dispatch(
      stepAction({
        firstStep: StepName.CONTACT_INFO,
        stepNames,
        stepMapping,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ERPContext.Provider value={{ state, dispatch }}>
        {children}
      </ERPContext.Provider>
    </>
  );
};
