import React from "react";
import { Navigate } from "react-router";
import { useLocaleAwareLink } from "./components/feature/LocaleAwareLink";

const RouteNotFound = () => {
  const getLink = useLocaleAwareLink();
  const redirectPath = getLink(true, "profile/info");
  return <Navigate to={redirectPath} replace />;
};

export default RouteNotFound;
