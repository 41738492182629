import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router";
import { ALERT_WRAPPER_ID } from "../../app.constants";
import AppContext from "../../AppContext";
import { GlobalContext, GlobalContextType } from "../../contexts/GlobalContext";
import useIntlMessage from "../../hooks/useIntlMessage";
import useResumePolling from "../../hooks/useResumePolling";
import { RouteID } from "../../routes";
import { AlertWrapper } from "../base/AlertWrapper";

export function GlobalAlert() {
  const { globalMessages, deleteMessage } =
    useContext<GlobalContextType | undefined>(GlobalContext) ?? {};
  const [currentMessage, setCurrentMessage] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [recruitics, setRecruitics] = useState<boolean>(false);
  const [recruiticsUrl, setRecruiticsUrl] = useState<string>("");
  const location = useLocation();
  const prevPathRef = useRef(location.pathname); // Store the previous pathname

  const { appUIState, dispatch } = useContext(AppContext);
  const { triggerLinkedInResumePolling } = appUIState;
  const { t } = useIntlMessage();

  const startPolling = useResumePolling();
  useEffect(() => {
    if (
      triggerLinkedInResumePolling &&
      triggerLinkedInResumePolling.resumeParsingData &&
      !(
        location.pathname.includes(RouteID.apply) ||
        location.pathname.includes(RouteID.erp)
      )
    ) {
      const {
        showResumeActionsModal,
        startPolling: startResumePolling,
        resumeParsingData,
      } = triggerLinkedInResumePolling;
      dispatch({ type: "RESUME_PARSING_DATA", payload: resumeParsingData });
      if (showResumeActionsModal) {
        dispatch({
          type: "RESUME_PARSING_REVIEW_ACTIONS",
          payload: { showResumeActionsModal },
        });
      } else if (startResumePolling) {
        startPolling(resumeParsingData);
      }
      dispatch({ type: "TRIGGER_LINKEDIN_RESUME_POLLING", payload: null });
    }
  }, [triggerLinkedInResumePolling, startPolling, dispatch, location]);

  /**
   * Clear the alert on click of close icon on laert
   */
  const removeMessage = () => {
    setCurrentMessage("");
    setError(false);
  };

  /**
   * Identify the alert to be shown on route change
   */
  const matchRouteAndShowAlert = useCallback(
    (route: string) => {
      if (globalMessages && globalMessages[route]) {
        setCurrentMessage(globalMessages[route].translatedText);
        if (globalMessages[route].messageType == "error") setError(true);
        if (globalMessages[route].recruitics == "true") {
          // Recruitics pixel flag and url are set for internal talents on successful application submission
          setRecruitics(true);
          setRecruiticsUrl(globalMessages[route].recruticsUrl);
        }
        deleteMessage && deleteMessage(location.pathname);
      }
    },
    [deleteMessage, globalMessages, location.pathname],
  );

  /**
   * on change of route, this will be executed and executes the route matching, show alerts
   */
  useEffect(() => {
    if (location.pathname && prevPathRef.current !== location.pathname) {
      setCurrentMessage("");
      setError(false);
      matchRouteAndShowAlert(location.pathname);
      prevPathRef.current = location.pathname;
    }
  }, [location, matchRouteAndShowAlert]);

  return (
    <>
      {currentMessage && (
        <section id="global-alert" className="mtb-auto-0">
          <AlertWrapper
            message={currentMessage}
            remove={{
              closeBtnAriaLabel: t("jobsite.common.closeAlert") as string,
              onRemove: removeMessage,
            }}
            error={error}
            id={ALERT_WRAPPER_ID.generateId("global-alert")}
          ></AlertWrapper>
          {recruitics && (
            <img
              src={`${recruiticsUrl}?${new Date().getTime()}`}
              className="d-none"
            />
          )}
        </section>
      )}
    </>
  );
}
