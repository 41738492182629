import React, { useCallback, useEffect, useState } from "react";
import { downloadFile } from "../../../api/fetchClient";
import { ALERT_WRAPPER_ID } from "../../../app.constants";
import { useCurrentUserContext } from "../../../CurrentUserContext";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { FileData } from "../../../types/File";
import { validateFile } from "../../../utils/fileUtil";
import { focusElement } from "../../../utils/focusUtil";
import { idGenerator } from "../../../utils/idGenerator";
import { AlertWrapper } from "../../base/AlertWrapper";
import { ResumePreviewWidget } from "./ResumePreviewWidget";
import { ResumeTextWidget } from "./ResumeTextWidget";
import { ResumeUploadWidget } from "./ResumeUploadWidget";

interface ResumeWidgetProps {
  errorMessage?: string;
  removeErrorMessage?: () => void;
  data: FileData | null;
  locale: string;
  resumeFileHandler: (fileData: FileData) => void;
  resumeTextHandler: (fileData: FileData) => void;
}

export function ResumeWidget({
  data,
  errorMessage = "",
  removeErrorMessage,
  locale,
  resumeFileHandler,
  resumeTextHandler,
}: ResumeWidgetProps) {
  const { t } = useIntlMessage();
  const { currentUser } = useCurrentUserContext();
  const [fileData, setFileData] = useState<FileData | null>(data);
  const [resumeUpload, setResumeUpload] = useState(
    (!data || !data.name) && true,
  );
  const [resumeTextSection, setResumeTextSection] = useState<boolean>(false);
  const textResumeLabel = t("jobsite.common.textResume");
  const [resumeText, setResumeText] = useState(
    data && data.name == textResumeLabel ? data?.content : "",
  );
  const [errorMsg, setErrorMsg] = useState<string>(errorMessage);
  const RESUME_ERROR_WRAPPER_ID = ALERT_WRAPPER_ID.generateId("file-resume");
  const ATTACH_FILE_ID = idGenerator("file", "resume").generateId("fileupload");
  const PASTE_TEXT_ID = idGenerator("resume", "textupload").generateId();
  const RESUME_FILE_NAME_ID = idGenerator("resume", "filename").generateId();
  const PASTE_TEXT_TITLE_ID = idGenerator("resume", "text").generateId("title");

  const attachFile = useCallback(
    (fileData: FileData) => {
      const errorObj = validateFile(fileData);

      if (errorObj.error) {
        setErrorMsg(t(`jobsite.common.${errorObj.errorMsg}`) as string);
        focusElement(RESUME_ERROR_WRAPPER_ID);
        return;
      }
      setFileData(fileData);
      setResumeUpload(false);
      resumeFileHandler(fileData);
      focusElement(RESUME_FILE_NAME_ID);
      setErrorMsg("");
    },
    [resumeFileHandler, t, RESUME_ERROR_WRAPPER_ID, RESUME_FILE_NAME_ID],
  );

  /**
   *
   * @param event
   * This method triggers when the user clicks on removeFile Button in resume widget, removes the file
   *  and switches to upload view
   */
  const removeFile = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, isTextResume: boolean) => {
      event.preventDefault();
      setResumeUpload(true);
      setResumeText("");
      setFileData({ name: "", createdTime: "" });
      resumeFileHandler({ name: "" });
      resumeTextHandler({ name: "", content: "" });
      focusElement(isTextResume ? PASTE_TEXT_ID : ATTACH_FILE_ID);
    },
    [resumeFileHandler, resumeTextHandler, PASTE_TEXT_ID, ATTACH_FILE_ID],
  );

  /**
   * This method triggers when the user clicks on paste text Button in resume widget, it will switch to
   * resume paste area section
   */
  const pasteText = useCallback(() => {
    setResumeTextSection(true);
    focusElement(PASTE_TEXT_TITLE_ID);
  }, [PASTE_TEXT_TITLE_ID]);

  /**
   * This method triggers on click of save button in paste text section and saves resume text
   */
  const saveResumeText = useCallback(
    (resumeText: string) => {
      const newData = {
        name: textResumeLabel as string,
        createdTime: Date.now(),
        content: resumeText,
      };
      setFileData(newData);
      setResumeUpload(false);
      setResumeTextSection(false);
      setResumeText(resumeText);
      resumeTextHandler(newData);
    },
    [resumeTextHandler, textResumeLabel],
  );

  /**
   * This method triggers on click of close button in paste text section
   */
  const closeResumeText = useCallback(() => {
    setResumeTextSection(false);
  }, []);

  /**
   *
   * @param event
   * This method triggers on edit button click and paste text section will be shown
   */
  const editResumeText = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      setResumeTextSection(true);
    },
    [],
  );

  /**
   * This method is executed on click of already saved file name and downloads the file
   */
  const downloadFileData = useCallback(
    async (
      talentId: string,
      fileId: string,
      fileName: string,
      bucketId: string,
    ) => {
      await downloadFile(talentId, fileId, fileName, bucketId);
    },
    [],
  );

  useEffect(() => {
    setErrorMsg(errorMessage);
  }, [errorMessage]);

  return (
    <>
      {errorMsg && (
        <AlertWrapper
          closePosition="right"
          error={true}
          message={errorMsg}
          remove={{
            closeBtnAriaLabel: t(
              "jobsite.apply.closeFileTypeAlertVO",
            ) as string,
            onRemove: () => {
              if (removeErrorMessage) {
                removeErrorMessage();
              }
              setErrorMsg("");
            },
            idToFocus: resumeTextSection ? PASTE_TEXT_TITLE_ID : ATTACH_FILE_ID,
          }}
          id={RESUME_ERROR_WRAPPER_ID}
          role="group"
          aria-label={t("jobsite.apply.fileTypeAlertVO") as string}
        ></AlertWrapper>
      )}
      <section className="resume-attachment-container text-center">
        {resumeUpload && !resumeTextSection && (
          <ResumeUploadWidget
            attachFile={attachFile}
            pasteText={pasteText}
          ></ResumeUploadWidget>
        )}
        {!resumeUpload && !resumeTextSection && (
          <ResumePreviewWidget
            locale={locale}
            isTextResume={fileData && fileData.name == textResumeLabel}
            editResumeText={editResumeText}
            removeFile={removeFile}
            downloadFile={(fileId: string, bucketId: string) =>
              downloadFileData(
                currentUser.talentId as string,
                fileId,
                fileData?.name || "",
                bucketId,
              )
            }
            fileData={fileData}
          ></ResumePreviewWidget>
        )}
        {resumeTextSection && (
          <ResumeTextWidget
            initialText={resumeText}
            saveResumeText={saveResumeText}
            closeResumeText={closeResumeText}
          ></ResumeTextWidget>
        )}
      </section>
    </>
  );
}
