import { SavedSearchType } from "../../../../../shared/types";
import { OrderedRefData } from "../../../../../shared/types/refData";
import { SavedSearchesAction } from "./ActionTypes";

export const setSavedSearches = (
  data: SavedSearchType[],
): SavedSearchesAction => ({
  type: "SET_SAVED_SEARCHES",
  payload: data,
});

export const setEmailRefData = (data: {
  jobAgentEmailFrequency: OrderedRefData[];
  jobAgentEmailExperience: OrderedRefData[];
}): SavedSearchesAction => ({
  type: "SET_EMAIL_REF_DATA",
  payload: data,
});

export const deleteSavedSearch = (
  data: SavedSearchType[],
): SavedSearchesAction => ({
  type: "DELETE_SAVED_SEARCH",
  payload: data,
});

export const updateShowEmailAlertModal = (
  data: boolean,
): SavedSearchesAction => ({
  type: "UPDATE_SHOW_EMAIL_ALERT_MODAL",
  payload: data,
});

export const updateShowRemoveSearchModal = (
  data: boolean,
): SavedSearchesAction => ({
  type: "UPDATE_SHOW_REMOVE_SEARCH_MODAL",
  payload: data,
});

export const updateSearchToRemove = (
  data: SavedSearchType | null,
): SavedSearchesAction => ({
  type: "UPDATE_SEARCH_TO_REMOVE",
  payload: data,
});

export const updateEditSearch = (
  id: string,
  edit: boolean,
): SavedSearchesAction => ({
  type: "UPDATE_EDIT_SEARCH",
  payload: { id, edit },
});

export const setRefreshData = (data: boolean): SavedSearchesAction => ({
  type: "SET_REFRESH_DATA",
  payload: data,
});

export const updateEmailAlertModalId = (data: string): SavedSearchesAction => ({
  type: "UPDATE_EMAIL_ALERT_MODAL_FOR_ID",
  payload: data,
});
