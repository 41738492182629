import React from "react";
import { MonthsRefData } from "../../../shared/types/refData";
import useIntlMessage from "../../hooks/useIntlMessage";
import { getPastNYear } from "../../util";
import { focusElementWithFallback } from "../../utils/focusUtil";
import { idGenerator } from "../../utils/idGenerator";
import { getRefData } from "../profile/information/components/utils";
import { EditEmployeeSection } from "../profile/information/editEmployeeSection";
import {
  EmploymentForm,
  getEmploymentForm,
  validateDateRange,
} from "../profile/information/employmentFormUtils";

type ParsedEmploymentsProps = {
  employmentsForm: EmploymentForm[];
  setEmploymentsForm: React.Dispatch<React.SetStateAction<EmploymentForm[]>>;
  monthsRefData: MonthsRefData[];
  displayErrors: boolean;
};

export const ParsedEmployments = ({
  employmentsForm,
  monthsRefData,
  setEmploymentsForm,
  displayErrors,
}: ParsedEmploymentsProps) => {
  const { t } = useIntlMessage();
  const mode = "parsedmodal";
  const yearData = getPastNYear(t("jobsite.common.year") as string);
  const parsedModalEmployerIdGenerator = idGenerator(mode, "employer");

  const addEmployer = (index: number) => {
    setEmploymentsForm((prevValue: EmploymentForm[]) => [
      ...prevValue,
      getEmploymentForm(),
    ]);
    const newIndex = index + 1;
    focusElementWithFallback(
      parsedModalEmployerIdGenerator.generateId(
        `${newIndex}-suggestion-textbox`,
      ),
    );
  };

  const removeEmployer = (key: number, index: number) => {
    const updatedEmploymentsForm = employmentsForm.filter(
      (employment: EmploymentForm) => employment.key !== key,
    );
    setEmploymentsForm([...updatedEmploymentsForm]);
    const newIndex = Math.min(index, updatedEmploymentsForm.length - 1);
    const addEmploymentButtonId = idGenerator(
      mode,
      "add-employment",
    ).generateId(`${newIndex}`);
    const removeEmploymentButtonId = idGenerator(
      mode,
      "remove-employment",
    ).generateId(`${newIndex}`);
    focusElementWithFallback(addEmploymentButtonId, [removeEmploymentButtonId]);
  };

  const handleValueChange = (index: number, option: any, name: string) => {
    const employmentsFormCopy = employmentsForm;
    if (name === "employer") {
      if (option.id) {
        employmentsFormCopy[index].employerID.value = option.id;
        employmentsFormCopy[index].employerName.value = option.name;
        employmentsFormCopy[index].employerName.requiredError = false;
      } else if (employmentsFormCopy[index].employerName.value !== option) {
        employmentsFormCopy[index].employerID.value = "";
        employmentsFormCopy[index].employerName.value = option;
        if (option) {
          employmentsFormCopy[index].employerName.requiredError = false;
        }
      }
    } else if (name === "jobTitle") {
      employmentsFormCopy[index].jobTitle.value = option;
      if (option) {
        employmentsFormCopy[index].jobTitle.requiredError = false;
      }
    } else if (name === "currentEmployer") {
      employmentsFormCopy[index].currentEmployer.value = option;
      if (option) {
        employmentsFormCopy[index].currentEmployer.requiredError = false;
      }
      if (option === "Yes") {
        employmentsFormCopy[index].endMonthName.value = "";
        employmentsFormCopy[index].endMonthID.value = "";
        employmentsFormCopy[index].endYear.value = "";
        employmentsFormCopy[index].endMonthID.invalidErrorMessage = "";
      }
      employmentsFormCopy[index].startMonthID.invalidErrorMessage =
        validateDateRange(employmentsFormCopy, index, "startDate");
    } else if (name === "startMonth") {
      employmentsFormCopy[index].startMonthID.value = option.value;
      employmentsFormCopy[index].startMonthName.value = option.label;
      if (option.value) {
        employmentsFormCopy[index].startMonthID.requiredError = false;
        employmentsFormCopy[index].startMonthID.invalidErrorMessage =
          validateDateRange(employmentsFormCopy, index, "startDate");
        if (employmentsFormCopy[index].endMonthID.invalidErrorMessage) {
          employmentsFormCopy[index].endMonthID.invalidErrorMessage =
            validateDateRange(employmentsFormCopy, index, "endDate");
        }
      }
    } else if (name === "startYear") {
      employmentsFormCopy[index].startYear.value = option.value;
      if (option.value) {
        employmentsFormCopy[index].startYear.requiredError = false;
        employmentsFormCopy[index].startMonthID.invalidErrorMessage =
          validateDateRange(employmentsFormCopy, index, "startDate");
        if (employmentsFormCopy[index].endMonthID.invalidErrorMessage) {
          employmentsFormCopy[index].endMonthID.invalidErrorMessage =
            validateDateRange(employmentsFormCopy, index, "endDate");
        }
      }
    } else if (name === "endMonth") {
      employmentsFormCopy[index].endMonthID.value = option.value;
      employmentsFormCopy[index].endMonthName.value = option.label;
      if (option.value) {
        employmentsFormCopy[index].endMonthID.requiredError = false;
        employmentsFormCopy[index].endMonthID.invalidErrorMessage =
          validateDateRange(employmentsFormCopy, index, "endDate");
        if (employmentsFormCopy[index].startMonthID.invalidErrorMessage) {
          employmentsFormCopy[index].startMonthID.invalidErrorMessage =
            validateDateRange(employmentsFormCopy, index, "startDate");
        }
      }
    } else if (name === "endYear") {
      employmentsFormCopy[index].endYear.value = option.value;
      if (option.value) {
        employmentsFormCopy[index].endYear.requiredError = false;
        employmentsFormCopy[index].endMonthID.invalidErrorMessage =
          validateDateRange(employmentsFormCopy, index, "endDate");
        if (employmentsFormCopy[index].startMonthID.invalidErrorMessage) {
          employmentsFormCopy[index].startMonthID.invalidErrorMessage =
            validateDateRange(employmentsFormCopy, index, "startDate");
        }
      }
    }
    setEmploymentsForm([...employmentsFormCopy]);
  };

  return (
    <EditEmployeeSection
      employmentsForm={employmentsForm}
      displayErrors={displayErrors}
      mode={mode}
      monthOptions={getRefData(
        monthsRefData,
        t("jobsite.common.month") as string,
      )}
      yearOptions={yearData}
      addEmployer={addEmployer}
      removeEmployer={removeEmployer}
      handleValueChange={handleValueChange}
    />
  );
};
