export const ALL_FILTERS = "SET_FILTERS";
export const SET_SEARCH = "SET_SEARCH";
export const SET_SORT = "SET_SORT";
export const SET_FILTER = "SET_FILTER";
export const UNSET_FILTER = "UNSET_FILTER";
export const SET_PAGE = "SET_PAGE";
export const SET_FILTERS_MOBILE = "SET_FILTERS_MOBILE";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const FETCH_DATA = "FETCH_DATA";
export const SET_REF_DATA = "SET_REF_DATA";
export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";
export const SET_TOTAL_RECORDS = "SET_TOTAL_RECORDS";
export const SET_SAVED_SEARCH_LIST = "SET_SAVED_SEARCH_LIST";
export const SET_SAVE_SEARCH_OPTIONS = "SET_SAVE_SEARCH_OPTIONS";
export const SET_SAVED_SEARCH_DETAILS = "SET_SAVED_SEARCH_DETAILS";
export const SET_TALENT_ROLES = "SET_TALENT_ROLES";
export const UPDATE_FAVORITE_ROLES = "UPDATE_FAVORITE_ROLES";
export const UPDATE_UNFAVORITE_ROLES = "UPDATE_UNFAVORITE_ROLES";
export const SET_FILTERS_VISIBLE = "SET_FILTERS_VISIBLE";
export const SET_DATA_LOADING = "SET_DATA_LOADING";
export const FILTER_TYPE = {
  LOCATIONS: "locations",
  PRODUCT: "products",
  KEYWORD: "keywords",
  LANGUAGES: "languages",
  HOME_OFFICE: "homeOffice",
  TEAMS: "teams",
  HIRING_MANAGER: "hiringManagers",
  JOB_LEVEL: "jobLevel",
  RETAIL_ROLES: "retailRoles",
  MINIMUM_HOURS: "minimumHours",
  MAXIMUM_HOURS: "maximumHours",
};
