import React, { useContext } from "react";
import AppContext from "../../AppContext";
import TextIcon from "../../components/base/TextIcon";
import LocaleAwareLink from "../../components/feature/LocaleAwareLink";
import { useCurrentUserContext } from "../../CurrentUserContext";
import useIntlMessage from "../../hooks/useIntlMessage";
import { getPreferredName } from "../../util";

export function ThanksPageStep({}) {
  const { currentUser } = useCurrentUserContext();
  const { appUIState } = useContext(AppContext);
  const { locale } = appUIState.appData;
  const { t } = useIntlMessage();

  return (
    <>
      <section className="text-center">
        <span className="t-headline">
          <TextIcon name="icon-apple" />
        </span>
        <h1 className="t-headline m-20">
          {t("jobsite.erp.thanks", {
            firstName: getPreferredName(
              currentUser?.contact?.firstName as string,
              currentUser?.contact?.preferredName,
            ),
            lastName: currentUser?.contact?.lastName,
          })}
        </h1>
        <div className="erp-refersuccess-messages mtb-auto-10">
          <p>{t("jobsite.erp.introduceFriend.referSuccessMessage")}</p>
          <p>{t("jobsite.erp.introduceFriend.referSuccessMessage1")}</p>
          <p>{t("jobsite.erp.introduceFriend.referSuccessMessage2")}</p>
        </div>
        <div className="d-flex flex-column row-gap-10">
          <a
            href={t("jobsite.common.peopleSiteURl") as string}
            className="link more"
          >
            {t("jobsite.erp.introduceFriend.moreInformation")}
          </a>
          <a href={`/app/${locale}/erp/`} className="link more">
            {t("jobsite.erp.introduceFriend.submitAnotherReferral")}
          </a>
          <LocaleAwareLink
            link="profile/info"
            type="more"
            text={t("jobsite.erp.introduceFriend.viewYourProfile") as string}
            addAppPrefix={true}
          />
        </div>
      </section>
    </>
  );
}
