import React, { createContext, useContext } from "react";

import { MyApplicationAction } from "./actionTypes";
import { MyApplicationState } from "./state";

// Create a context
export const MyApplicationContext = createContext<
  | {
      state: MyApplicationState;
      dispatch: React.Dispatch<MyApplicationAction>;
    }
  | undefined
>(undefined);

// Custom hook to use the ApplyContext
export const useMyApplicationContext = (): {
  state: MyApplicationState;
  dispatch: React.Dispatch<MyApplicationAction>;
} => {
  const context = useContext(MyApplicationContext);
  if (!context) {
    throw new Error(
      "useMyApplicationContext must be used within an MyApplicationProvider",
    );
  }
  return context;
};
