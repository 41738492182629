import { Textbox } from "@rpe-js/marcom-web-components";
import { cloneDeep, isEmpty, random } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { I18nValues } from "../../../../../../shared/types";
import {
  MyApplication,
  TalentReference,
} from "../../../../../../shared/types/talent/myApplication";
import { Info } from "../../../../../components/base/Info";
import { Label } from "../../../../../components/base/Label";
import { useFormInputValidator } from "../../../../../components/feature/form/inputValidators/useFormInputValidator";
import { useCurrentUserContext } from "../../../../../CurrentUserContext";
import useFocusFirstErrorField from "../../../../../hooks/useFocusFirstErrorField";
import useIntlMessage from "../../../../../hooks/useIntlMessage";
import { idGenerator } from "../../../../../utils/idGenerator";
import { getFormFieldLabel } from "../../../information/components/utils";
import { ProfileFormField } from "../../../types";
import { useMyApplicationContext } from "../../context/context";
import { MyApplicationActionDispatcher } from "../../context/state";
import { ApplicationSection, SectionMode } from "../../context/types";
import { useUpdateApplication } from "../../hooks/createAndUpdateApplicationHooks";
import { SectionHeader } from "../SectionHeader";
import { SectionActions } from "./sectionActions";

interface SectionProps {
  id: string;
  onSave: (
    name: ApplicationSection,
    mode: SectionMode,
    application: MyApplication,
  ) => void;
  mode: SectionMode;
}

interface TalentReferenceForm {
  key: number;
  name: ProfileFormField;
  relationship: ProfileFormField;
  company: ProfileFormField;
  jobTitle: ProfileFormField;
  phoneNumber: ProfileFormField;
}

const getEmptyTalentReference = (): TalentReference => {
  return {
    name: "",
    relationship: "",
    company: "",
    jobTitle: "",
    phoneNumber: "",
  };
};

function getTalentReferenceForm(
  refs?: TalentReference[],
): TalentReferenceForm[] {
  const idGeneratorFn = idGenerator("profileApplication", "references");
  return (refs || [1, 2, 3].map(() => getEmptyTalentReference())).map(
    (ref) => ({
      key: random(10000),
      name: {
        id: idGeneratorFn.generateId("name", true),
        key: random(10000),
        label: "jobsite.profile.myApplication.name",
        name: "name",
        type: "text",
        error: false,
        value: ref?.name || "",
        requiredi18nMsgKey: "jobsite.profile.myApplication.enterName",
        required: true,
        maxLength: 50,
      },
      relationship: {
        id: idGeneratorFn.generateId("relationship", true),
        key: random(10000),
        label: "jobsite.profile.myApplication.relationship",
        name: "relationship",
        type: "text",
        error: false,
        value: ref?.relationship || "",
        requiredi18nMsgKey: "jobsite.profile.myApplication.enterRelationship",
        required: true,
        maxLength: 90,
      },
      company: {
        id: idGeneratorFn.generateId("company", true),
        key: random(10000),
        label: "jobsite.profile.myApplication.company",
        name: "company",
        type: "text",
        error: false,
        value: ref?.company || "",
        requiredi18nMsgKey: "jobsite.profile.myApplication.enterCompany",
        required: true,
        maxLength: 90,
      },
      jobTitle: {
        id: idGeneratorFn.generateId("jobtitle", true),
        key: random(10000),
        label: "jobsite.profile.myApplication.jobTitle",
        name: "jobTitle",
        type: "text",
        error: false,
        value: ref?.jobTitle || "",
        requiredi18nMsgKey: "jobsite.profile.myApplication.errorajobtitle",
        required: true,
        maxLength: 90,
      },
      phoneNumber: {
        id: idGeneratorFn.generateId("phoneno", true),
        key: random(10000),
        label: "jobsite.profile.myApplication.phoneNo",
        name: "phoneNumber",
        type: "text",
        error: false,
        value: ref?.jobTitle || "",
        requiredi18nMsgKey: "jobsite.profile.myApplication.errorphone", //"jobsite.profile.myApplication.enterValidPhoneNumber",
        required: true,
        maxLength: 20,
      },
    }),
  );
}

function getTalentReference(forms: TalentReferenceForm[]): TalentReference[] {
  return forms.map((f) => ({
    name: f.name.value as string,
    relationship: f.relationship.value as string,
    company: f.company.value as string,
    jobTitle: f.jobTitle.value as string,
    phoneNumber: f.phoneNumber.value as string,
  }));
}

interface ReferenceProps extends SectionProps {}

export function ReferenceSection(props: ReferenceProps) {
  const { id, mode, onSave } = props;
  const { currentUser } = useCurrentUserContext();
  const { state, dispatch } = useMyApplicationContext();
  const { referencesSection, application } = state;
  const { title } = referencesSection;
  const sectionName: ApplicationSection = "references";
  const { setFocusFirstErrorField } = useFocusFirstErrorField(
    `profileApplication-application-${sectionName}`,
  );
  const {
    validateNameEntered,
    onPasteValidateName,
    isValidName,
    isValidPhoneNumber,
    validateReasonForLeavingAndRelation,
    onPasteValidateReasonForLeavingAndRelation,
    isValidReasonForLeavingAndRelation,
  } = useFormInputValidator();
  const { t } = useIntlMessage();

  const { references: storedReferences } = application as MyApplication;

  const [forms, setForms] = useState<TalentReferenceForm[]>([]);
  const [saveClicked, setSaveClicked] = useState(false);

  const {
    //isUpdateApplicationError,
    isUpdateApplicationLoading,
    isUpdateApplicationSuccess,
    updateApplication,
    resetUpdateApplication,
    updatedApplication,
  } = useUpdateApplication();

  useEffect(() => {
    setForms(getTalentReferenceForm(storedReferences));
  }, [storedReferences]);

  const validateField = useCallback(
    (field: ProfileFormField, type: "phone" | "text") => {
      const updatedField = {
        ...field,
        error: false,
        errori18nMsgKey: "",
        errori18nMsgParams: {},
      };
      if (isEmpty(updatedField.value) && updatedField.required) {
        updatedField.error = true;
        updatedField.errori18nMsgKey = updatedField.requiredi18nMsgKey || "";
      }
      if (
        type === "text" &&
        updatedField.name !== "relationship" &&
        updatedField.name !== "company" &&
        !isEmpty(updatedField.value) &&
        !isValidName(updatedField.value as string)
      ) {
        updatedField.error = true;
        updatedField.errori18nMsgKey = "jobsite.invalidCharactersError";
      }

      if (
        type === "text" &&
        (updatedField.name === "relationship" ||
          updatedField.name === "company") &&
        !isEmpty(updatedField.value) &&
        !isValidReasonForLeavingAndRelation(updatedField.value as string)
      ) {
        updatedField.error = true;
        updatedField.errori18nMsgKey = "jobsite.invalidCharactersError";
      }

      if (
        type === "phone" &&
        !isEmpty(updatedField.value) &&
        !isValidPhoneNumber(updatedField.value as string)
      ) {
        updatedField.error = true;
        updatedField.errori18nMsgKey = "jobsite.common.enterValidPhoneNumber";
      }
      return updatedField;
    },
    [isValidName, isValidPhoneNumber, isValidReasonForLeavingAndRelation],
  );

  const validate = useCallback((): boolean => {
    const updated = cloneDeep(forms);
    let hasError: boolean = false;
    updated.forEach((f) => {
      f.name = validateField(f.name, "text");
      f.relationship = validateField(f.relationship, "text");
      f.company = validateField(f.company, "text");
      f.jobTitle = validateField(f.jobTitle, "text");
      f.phoneNumber = validateField(f.phoneNumber, "phone");

      hasError =
        hasError ||
        ((f.name.error ||
          f.company.error ||
          f.jobTitle.error ||
          f.phoneNumber.error ||
          f.relationship.error) as boolean);
    });
    setForms(updated);
    return hasError;
  }, [forms, validateField]);

  useEffect(() => {
    if (
      !isUpdateApplicationLoading &&
      isUpdateApplicationSuccess &&
      updatedApplication
    ) {
      MyApplicationActionDispatcher.onAppSecReferenceSave(
        dispatch,
        updatedApplication.references,
      );
      onSave(sectionName, mode, updatedApplication as MyApplication);
      resetUpdateApplication();
    }
  }, [
    dispatch,
    isUpdateApplicationLoading,
    isUpdateApplicationSuccess,
    mode,
    onSave,
    updatedApplication,
    resetUpdateApplication,
  ]);

  const onSectionSave = useCallback(async () => {
    const hasError = validate();
    setSaveClicked(true);
    if (!hasError && application && currentUser.talentId) {
      const updated = getTalentReference(forms);
      const requestBody = cloneDeep(application);
      requestBody.references = updated;
      await updateApplication(
        currentUser.talentId,
        application?.id,
        "references",
        requestBody as MyApplication,
      );
    } else {
      setFocusFirstErrorField(true);
    }
  }, [
    validate,
    application,
    currentUser.talentId,
    forms,
    updateApplication,
    setFocusFirstErrorField,
  ]);

  const onCancel = useCallback(() => {
    MyApplicationActionDispatcher.onApplicationSectionCancel(
      dispatch,
      sectionName,
    );
    setForms(getTalentReferenceForm(storedReferences));
  }, [dispatch, storedReferences]);

  const onSectionEdit = useCallback(() => {
    MyApplicationActionDispatcher.onApplicationSectionEdit(
      dispatch,
      sectionName,
    );
  }, [dispatch, sectionName]);

  const onChange = useCallback(
    (key: number, field: ProfileFormField, value: string) => {
      const updated = [...forms];
      const idx = updated.findIndex((f) => f.key === key);
      updated[idx] = { ...updated[idx] };
      const referenceForm = updated[idx];
      if (field.value) {
        field.error = false;
      } else if (field.required) {
        field.error = true;
      }
      if (field.name === "name") {
        referenceForm.name = validateField({ ...field, value: value }, "text");
      }
      if (field.name === "relationship") {
        referenceForm.relationship = validateField(
          { ...field, value: value },
          "text",
        );
      }
      if (field.name === "company") {
        referenceForm.company = validateField(
          { ...field, value: value },
          "text",
        );
      }

      if (field.name === "jobTitle") {
        referenceForm.jobTitle = validateField(
          { ...field, value: value },
          "text",
        );
      }

      if (field.name === "phoneNumber") {
        referenceForm.phoneNumber = validateField(
          { ...field, value: value },
          "phone",
        );
      }
      setForms(updated);
    },
    [forms, validateField],
  );

  const renderNameTextBox = useCallback(
    (
      key: number,
      profileFormField: ProfileFormField,
      t: (id: string, values?: I18nValues) => React.ReactNode,
    ) => {
      return (
        <Textbox
          id={profileFormField.id as string}
          required={profileFormField.required || false}
          label={getFormFieldLabel(profileFormField as ProfileFormField, t)}
          maxLength={profileFormField.maxLength}
          error={
            saveClicked && profileFormField.error
              ? (t(profileFormField.errori18nMsgKey as string, {
                  ...(profileFormField.errori18nMsgParams || {}),
                }) as string)
              : false
          }
          errorA11y={t("jobsite.common.errorIconLabel") as string}
          onKeyDown={
            profileFormField.name === "relationship" ||
            profileFormField.name === "company"
              ? validateReasonForLeavingAndRelation
              : validateNameEntered
          }
          onPaste={
            profileFormField.name === "relationship" ||
            profileFormField.name === "company"
              ? onPasteValidateReasonForLeavingAndRelation
              : onPasteValidateName
          }
          value={profileFormField.value}
          aria-required={profileFormField.required}
          onValueChange={(value) => {
            onChange(key, profileFormField as ProfileFormField, value);
          }}
        />
      );
    },
    [
      saveClicked,
      onChange,
      onPasteValidateName,
      onPasteValidateReasonForLeavingAndRelation,
      validateNameEntered,
      validateReasonForLeavingAndRelation,
    ],
  );

  return (
    <SectionHeader id={id} title={title} mode={mode} onEdit={onSectionEdit}>
      {mode === "view" && (
        <ReferenceView refs={storedReferences}></ReferenceView>
      )}
      {(mode === "edit" || mode === "create") && (
        <form>
          <legend className="pt-20">
            {t("jobsite.profile.myApplication.threeReference")}
          </legend>
          {forms.map((f, idx) => (
            <fieldset key={`form-fieldset-${f.key}-${idx}`}>
              <div
                key={f.key}
                className={`pb-10 pt-20 ${idx !== forms.length - 1 ? "u-border-bottom" : ""}`}
              >
                <div key={f.name.key} className="pb-10">
                  {renderNameTextBox(f.key, f.name, t)}
                </div>
                <div key={f.relationship.key} className="pb-10">
                  {renderNameTextBox(f.key, f.relationship, t)}
                </div>
                <div key={f.company.key} className="pb-10">
                  {renderNameTextBox(f.key, f.company, t)}
                </div>
                <div key={f.jobTitle.key} className="pb-10">
                  {renderNameTextBox(f.key, f.jobTitle, t)}
                </div>
                <div key={f.phoneNumber.key}>
                  {renderNameTextBox(f.key, f.phoneNumber, t)}
                </div>
              </div>
            </fieldset>
          ))}
        </form>
      )}
      {mode !== "view" && (
        <SectionActions
          onCancel={onCancel}
          onSave={onSectionSave}
          mode={mode}
        />
      )}
    </SectionHeader>
  );
}

function ReferenceView(props: { refs: TalentReference[] }) {
  const { t } = useIntlMessage();
  const { refs } = props;
  return (
    <div>
      <ul role="list" className="ml-0">
        {refs.map((ref, idx) => (
          <li
            role="listitem"
            key={`talent-reference-${idx}`}
            className={`no-bullet ${idx != 0 ? "pt-20" : "mt-10"} pb-20 ${idx !== refs.length - 1 ? "u-border-bottom" : ""}`}
          >
            <div className="large-12 small-12">
              <div>
                <Label
                  label={
                    t(
                      "jobsite.profile.myApplication.nameRelationship",
                    ) as string
                  }
                />
              </div>
              <div>
                <Info value={ref.name}></Info>
              </div>
              <div>
                <Info value={ref.relationship}></Info>
              </div>
            </div>
            <div className="row large-12 small-12">
              <div className="large-6 small-12">
                <div>
                  <Label
                    label={
                      t(
                        "jobsite.profile.myApplication.companyJobTitle",
                      ) as string
                    }
                  />
                </div>
                <div>
                  <Info value={ref.company}></Info>
                </div>
                <div>
                  <Info value={ref.jobTitle}></Info>
                </div>
              </div>
              <div className="large-6 small-12">
                <div>
                  <Label
                    label={t("jobsite.profile.myApplication.phoneNo") as string}
                  />
                </div>
                <div>
                  <Info value={ref.phoneNumber}></Info>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
