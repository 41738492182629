import { pickBy } from "lodash";
import { teamsTypeMapper } from "../../../util";
import { ERPAction } from "./ActionTypes";
import { ERPState } from "./ErpState";

export const reducer = (state: ERPState, action: ERPAction): ERPState => {
  switch (action.type) {
    case "STEP":
      return {
        ...state,
        page: {
          firstStep: action.payload.firstStep,
          stepNames: action.payload.stepNames,
          stepMapping: action.payload.stepMapping,
        },
      };
    case "UPDATE_ROLE_LIST":
      return {
        ...state,
        jobPositionIDs: [...state.jobPositionIDs, ...action.payload],
      };
    case "DELETE_ROLE_LIST":
      return {
        ...state,
        jobPositionIDs: [...action.payload],
      };
    case "UPDATE_CONTACT_DETAILS":
      return {
        ...state,
        contact: {
          ...state.contact,
          [action.payload.key]: action.payload.value,
        },
      };
    case "UPDATE_ADDRESS":
      return {
        ...state,
        address: {
          ...state.address,
          countryID: action.payload.countryID,
          countryName: action.payload.countryName,
        },
      };
    case "UPDATE_LANGUAGE":
      return {
        ...state,
        language: {
          ...state.language,
          languageID: action.payload.languageID,
          languageName: action.payload.languageName,
        },
      };
    case "UPDATE_REFERRAL_TYPE":
      return {
        ...state,
        typeID: action.payload,
      };
    case "UPDATE_NOTES":
      return {
        ...state,
        notes: action.payload,
      };
    case "UPDATE_RESUME":
      return {
        ...state,
        resume: action.payload,
        textResume: null,
      };

    case "UPDATE_TEXT_RESUME":
      return {
        ...state,
        textResume: action.payload,
        resume: null,
      };
    case "UPDATE_REFERENECE_DATA":
      return {
        ...state,
        referenceData: {
          ...state.referenceData,
          [action.payload.key]: action.payload.value,
        },
      };
    case "SUPPORTING_FILE":
      return {
        ...state,
        supportFiles: action.payload,
      };
    case "SUPPORTING_LINK":
      return {
        ...state,
        links: action.payload,
      };
    case "UPDATE_MANAGED_ROLES":
      return {
        ...state,
        globaleManagedRoleIds: action.payload,
      };
    case "UPDATE_CORPORATE_LOCATIONS":
      return {
        ...state,
        corporateLocations: action.payload,
      };
    case "UPDATE_RETAIL_LOCATIONS":
      return {
        ...state,
        retailLocations: action.payload,
      };
    case "UPDATE_TEAMS_OF_INTEREST": {
      return {
        ...state,
        teamsOfInterest: {
          ...state.teamsOfInterest,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case "TOGGLE_REVIEW_SUBMIT_BUTTON": {
      return {
        ...state,
        enableReviewSubmitBtn: action.payload,
      };
    }
    case "CREATE_TEAMS_VIEW_DATA_AND_PAYLOAD": {
      const existingTeamsOfInterest = { ...state.teamsOfInterest };
      pickBy(existingTeamsOfInterest, (value) => {
        return value.subTeam.length > 0;
      });
      const teamsViewData = teamsTypeMapper(
        existingTeamsOfInterest,
        state.referenceData.teamsOfInterest ?? [],
      );
      return {
        ...state,
        teamsViewData: teamsViewData,
        teamsPayload: teamsViewData.map((team) => ({
          teamID: team.teamID,
          subTeams:
            team.subTeams?.map((subTeam) => ({
              subTeamID: subTeam.subTeamID,
            })) ?? [],
        })),
      };
    }
    case "TOGGLE_RETAIL_MODAL_STATUS": {
      return {
        ...state,
        showRetailModal: action.payload,
      };
    }
    default:
      return state;
  }
};
