import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router";
import { SET_SEARCH_URL } from "../../AppActionTypes";
import AppContext from "../../AppContext";
import { SearchProvider } from "../../contexts/SearchContext";
import useAutoFocus from "../../hooks/useAutoFocus";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import FiltersSection from "./FiltersSection";
import ManageFilters from "./ManageFilters";
import ResultsSection from "./ResultsSection";
import SearchInput from "./SearchInput";

const SearchPage: React.FC = () => {
  const headingRef = useAutoFocus<HTMLHeadingElement>();
  const { t } = useIntlMessage();
  const location = useLocation();
  const { dispatch: dispatchAppContext } = useContext(AppContext);
  const isMobile = useIsMobile();

  useEffect(() => {
    const searchPageURL = `${location.pathname}${location.search}`;
    dispatchAppContext({
      type: SET_SEARCH_URL,
      payload: searchPageURL,
    });
  }, [dispatchAppContext, location]);

  return (
    <main className={"mt-40 p-20"}>
      <SearchProvider>
        <section className={"search-input-container"}>
          <h1
            ref={headingRef}
            tabIndex={0}
            className={"t-callout text-center mb-20"}
          >
            {t("jobsite.search.alwaysShown")}
          </h1>
          {isMobile && <ManageFilters />}
          <SearchInput />
          <p className={"t-body-reduced text-center search-main-hint"}>
            {t("jobsite.search.mainSearchHint")}
          </p>
        </section>
        {!isMobile && <ManageFilters />}
        <div className="filter-results-container mb-50">
          <FiltersSection />
          <ResultsSection />
        </div>
      </SearchProvider>
    </main>
  );
};

export default SearchPage;
