import React, { useCallback, useContext, useEffect } from "react";
import { NavLink, useLocation } from "react-router";
import { HeaderLink, SubHeaderLink } from "../../../shared/types";
import { DEFAULT_LOCALE } from "../../app.constants";
import AppContext from "../../AppContext";
import useIntlMessage from "../../hooks/useIntlMessage";
import { RouteID } from "../../routes";
//@ts-ignore no definition provided yet.
import { userInputType } from "@aos/as-utilities"; // TODO: Import this from marcom right now when we are doing there is an app startup issue
import HtmlRenderer from "../base/HtmlRenderer";

export default function Header() {
  const { appUIState } = useContext(AppContext);
  const {
    headerItems,
    appBasePathPrefix,
    isSessionAuthenticated,
    isInternal,
    locale,
    enableUSEmploymentApplication,
  } = appUIState.appData;
  const { t } = useIntlMessage();
  const location = useLocation();
  const SIGN_IN = "signIn";
  const SIGN_OUT = "signOut";
  const headerObj = (headerItems || []).sort((obj1, obj2) => {
    return obj1.position - obj2.position;
  });
  const isClient = typeof window !== "undefined";
  const enableSticky =
    isInternal || !location.pathname.includes(RouteID.search); // Enable sticky behaviour for internal, but for external except search page

  useEffect(() => {
    if (!isClient) return; //If not browser return
    userInputType.setup();
    /**
     * When local nav dropdown is showed Profile link should not be highlighted. Local nav Dropdown will be shown below 833px.
     * If the first token doesn't exist, replace() returns false immediately, without adding the new token to the token list.
     */
    const handleResize = () => {
      const profileAnchorElementClassList =
        document.getElementById("localnav-Profile")?.children[0]?.classList;
      if (window.innerWidth < 834) {
        if (profileAnchorElementClassList) {
          profileAnchorElementClassList.replace("current", "current-hide");
          profileAnchorElementClassList.replace("fw-bold", "normal");
        }
      } else {
        if (profileAnchorElementClassList) {
          profileAnchorElementClassList.replace("current-hide", "current");
          profileAnchorElementClassList.replace("normal", "fw-bold");
        }
      }
    };

    const instatiateLocalNav = async () => {
      if (isClient) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const Localnav = require("@marcom/ac-localnav/Localnav");
        new Localnav(document.getElementById("localnav"));

        window.addEventListener("resize", handleResize);
      }
    };

    instatiateLocalNav();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isClient, enableSticky]);

  const highlightCurrentPath = useCallback(
    (headerlink: string) => {
      const path = location.pathname.split("/");
      if (path.indexOf(locale) + 1 < path.length)
        return headerlink.includes(path[path.indexOf(locale) + 1]);
    },
    [location, locale],
  );

  const subPathPrefix = appBasePathPrefix || "";

  const createHeaderLink = useCallback(
    (header: HeaderLink) => {
      return (
        <li
          id={`localnav-${header.name}`}
          key={`localNav-${header.name}`}
          className="localnav-menu-item"
          role="listitem"
        >
          <a
            className={`localnav-menu-link ${highlightCurrentPath(header.link) ? "current fw-bold" : ""}`}
            href={`${subPathPrefix}${header.link}`}
          >
            {header.name}
          </a>
        </li>
      );
    },
    [subPathPrefix, highlightCurrentPath],
  );

  const createSubHeaderLinks = useCallback(
    (subHeaders: SubHeaderLink[]) => {
      const subLinks: React.JSX.Element[] = [];

      subHeaders.map((subHeader) => {
        const enableEmploymentApplication =
          subHeader.enableEmploymentApplication;
        if (
          !enableEmploymentApplication ||
          (enableEmploymentApplication &&
            enableEmploymentApplication === enableUSEmploymentApplication)
        ) {
          subLinks.push(
            <li
              id={`localnav-${t(subHeader.key)}`}
              key={`localNav-${t(subHeader.key)}`}
              className={`localnav-menu-item pl-15 ${subHeader.mobileView ? "hide-subheaders" : ""}`}
              role="listitem"
            >
              <NavLink
                to={`${subPathPrefix}${subHeader.link}`}
                className={({ isActive }) =>
                  `localnav-menu-link ${isActive ? "current fw-bold" : ""}`
                }
              >
                {t(subHeader.key)}
              </NavLink>
            </li>,
          );
        }
      });
      return subLinks;
    },
    [enableUSEmploymentApplication, t, subPathPrefix],
  );

  let searchObj: any = {};
  const headerMap = headerObj
    .map((header) => {
      if (!header.listType) {
        if (
          (isSessionAuthenticated && header.id == SIGN_IN) ||
          (!isSessionAuthenticated && header.id == SIGN_OUT)
        ) {
          return null;
        }
        if (header?.subHeaders && isSessionAuthenticated) {
          return [
            createHeaderLink(header),
            createSubHeaderLinks(header.subHeaders),
          ];
        } else {
          return createHeaderLink(header);
        }
      }
      searchObj = header;
      return null;
    })
    .filter(Boolean);

  const searchAnchorEle =
    searchObj && searchObj.name && searchObj.link
      ? `<a class="localnav-button button button-reduced ${isInternal ? "internal" : "external"}" href=${`${subPathPrefix}${searchObj.link || ""}`}>
      <span>${searchObj.name}</span>
    </a>`
      : "";
  const careersAtApple = t("jobsite.common.careersAtApple", { locale });
  const jobsAtApple = t("jobsite.common.jobsAtApple", { locale });
  if (!headerItems || headerItems.length == 0) {
    return <></>;
  }
  return (
    <>
      <input
        type="checkbox"
        id="localnav-menustate"
        className="localnav-menustate"
      />
      <nav
        id="localnav"
        className="localnav"
        {...(enableSticky && { "data-sticky": true })}
      >
        <div className="localnav-wrapper">
          <div className="localnav-background" />
          <div className="localnav-content">
            <div className="localnav-title mt-5">
              {
                // eslint-disable-next-line no-nested-ternary
                isInternal || locale.toLowerCase() === DEFAULT_LOCALE ? (
                  <a id="js-jobs-at-apple" href={`/${locale}/search`}>
                    {careersAtApple}
                  </a>
                ) : (
                  <a id="js-jobs-at-apple" href={`/${locale}/search`}>
                    {jobsAtApple}
                  </a>
                )
              }
            </div>
            <div className="localnav-menu">
              <a
                href="#localnav-menustate"
                className="localnav-menucta-anchor localnav-menucta-anchor-open"
                id="localnav-menustate-open"
              >
                <span className="localnav-menucta-anchor-label">
                  {t("jobsite.common.openMenu")}
                </span>
              </a>
              <a
                href="#"
                className="localnav-menucta-anchor localnav-menucta-anchor-close"
                id="localnav-menustate-close"
              >
                <span className="localnav-menucta-anchor-label">
                  {t("jobsite.common.closeMenu")}
                </span>
              </a>
              <div className="localnav-menu-tray">
                <ul
                  role="list"
                  className="localnav-menu-items"
                  id="js-menu-items"
                >
                  {headerMap}
                </ul>
              </div>
              <div className={`localnav-actions localnav-actions-center`}>
                <div className="localnav-action localnav-action-menucta">
                  <label
                    htmlFor="localnav-menustate"
                    className="localnav-menucta"
                  >
                    <span className="localnav-menucta-chevron" />
                  </label>
                </div>
                <HtmlRenderer
                  initialContent={searchAnchorEle}
                  classes="localnav-action localnav-action-button"
                  id="js-search"
                />
              </div>
            </div>
          </div>
        </div>
      </nav>
      <label id="localnav-curtain" htmlFor="localnav-menustate"></label>
    </>
  );
}
