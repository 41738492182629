import { Textbox } from "@rpe-js/marcom-web-components";
import { AccordionItem } from "@rpe-js/marcom-web-components/lib/CustomAccordion/CustomAccordion";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FILTER_TYPE } from "../../contexts/actionTypes";
import useIdGenerator from "../../hooks/useIdGenerator";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { idGenerator } from "../../utils/idGenerator";

type Props = {
  defaultMinHours?: string;
  defaultMaxHours?: string;
  onAddHours: (filterType: string, hours: string) => void;
  onRemoveHours: (filterType: string) => void;
};

export function InternalWeeklyHoursFilter({
  defaultMinHours,
  defaultMaxHours,
  onAddHours,
  onRemoveHours,
}: Props) {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const [minHoursInput, setMinHoursInput] = useState(defaultMinHours);
  const [maxHoursInput, setMaxHoursInput] = useState(defaultMaxHours);
  const minHoursLabel = t("jobsite.search.minHours") as string;
  const maxHoursLabel = t("jobsite.search.maxHours") as string;
  const ACCORDION_ID = idGenerator(
    "search",
    "weeklyHours-filter-accordion",
  ).generateId();

  const regex = useMemo(() => {
    return /^\d*[.,]?\d*$/;
  }, []);

  const handleAddValue = useCallback(
    (
      value: string,
      setValue: React.Dispatch<React.SetStateAction<string | undefined>>,
      id: string,
    ) => {
      const trimmedValue = value.trim();
      if (trimmedValue === "") {
        setValue(undefined);
        // if it is mobile, we are saving the value of input, we don't wait for enter key
        if (isMobile) {
          if (id == `search-${minHoursLabel.replace(" ", "-")}`) {
            onAddHours(FILTER_TYPE.MINIMUM_HOURS, "");
          } else {
            onAddHours(FILTER_TYPE.MAXIMUM_HOURS, "");
          }
        }
      } else {
        if (regex.test(value)) {
          setValue(trimmedValue);
          // if it is mobile, we are saving the value of input, we don't wait for enter key
          if (isMobile) {
            if (id == `search-${minHoursLabel.replace(" ", "-")}`) {
              onAddHours(FILTER_TYPE.MINIMUM_HOURS, trimmedValue);
            } else {
              onAddHours(FILTER_TYPE.MAXIMUM_HOURS, trimmedValue);
            }
          }
        }
      }
    },
    [isMobile, minHoursLabel, onAddHours, regex],
  );

  const handleValueSubmit = useCallback(
    (e: React.KeyboardEvent) => {
      switch (e.key) {
        case "Enter":
          e.preventDefault();
          if (minHoursInput) {
            onAddHours(FILTER_TYPE.MINIMUM_HOURS, minHoursInput);
            setMinHoursInput(minHoursInput);
          } else {
            onRemoveHours(FILTER_TYPE.MINIMUM_HOURS);
            setMinHoursInput(minHoursInput);
          }
          if (maxHoursInput) {
            onAddHours(FILTER_TYPE.MAXIMUM_HOURS, maxHoursInput);
            setMaxHoursInput(maxHoursInput);
          } else {
            onRemoveHours(FILTER_TYPE.MAXIMUM_HOURS);
            setMaxHoursInput(maxHoursInput);
          }
          break;
        default:
          break;
      }
    },
    [maxHoursInput, minHoursInput, onAddHours, onRemoveHours],
  );

  const filterCount = useMemo(() => {
    let count = 0;
    if (defaultMinHours) {
      count++;
    }
    if (defaultMaxHours) {
      count++;
    }
    return count;
  }, [defaultMinHours, defaultMaxHours]);

  useEffect(() => {
    setMinHoursInput(defaultMinHours);
    setMaxHoursInput(defaultMaxHours);
  }, [defaultMinHours, defaultMaxHours]);

  const enterMinHoursLabel = t("jobsite.search.enterMinHrs") as string;
  const enterMaxHoursLabel = t("jobsite.search.enterMaxHrs") as string;

  const renderHoursInput = (
    id: string,
    label: string,
    placeholder: string,
    value: string | undefined,
    onValueChange: {
      (
        value: string,
        setValue: React.Dispatch<React.SetStateAction<string | undefined>>,
        id: string,
      ): void;
    },
    setValue: React.Dispatch<React.SetStateAction<string | undefined>>,
  ) => (
    <div>
      <h4 className={"t-body"}>{label}</h4>
      <Textbox
        required={false}
        id={id}
        key={label}
        value={value}
        hideLabel={true}
        placeholder={placeholder}
        onValueChange={(value) => onValueChange(value, setValue, id)}
        onKeyDown={handleValueSubmit}
        inputMode="numeric"
        pattern="[0-9]*"
        labelA11y={placeholder}
      />
    </div>
  );

  return (
    <>
      <AccordionItem
        title={`<span class="d-flex-equal fw-medium">${t("jobsite.search.retailHours")}</span>
                <span class="d-inline-block mr-5 mt-0 ${filterCount > 0 ? "counter" : "d-none"}" aria-hidden="true">${filterCount}</span>
                <span class="a11y">${t("jobsite.common.filterAppliedCount", { count: filterCount })}</span>`}
        titleWrapperTag={"h3"}
        titleWrapperTagAttrs={{
          className: "d-flex",
        }}
        clickableTitle={true}
        expandableIconsPlus={true}
        noPadding={true}
        index={8}
        id={ACCORDION_ID}
      >
        <form>
          {renderHoursInput(
            useIdGenerator("search", minHoursLabel.replace(" ", "-")),
            minHoursLabel,
            enterMinHoursLabel,
            minHoursInput,
            handleAddValue,
            setMinHoursInput,
          )}
          {renderHoursInput(
            useIdGenerator("search", maxHoursLabel.replace(" ", "-")),
            maxHoursLabel,
            enterMaxHoursLabel,
            maxHoursInput,
            handleAddValue,
            setMaxHoursInput,
          )}
        </form>
      </AccordionItem>
    </>
  );
}
