import {
  Button,
  MenuItemProps,
  MenuList,
  Overlay,
  TextButton,
} from "@rpe-js/marcom-web-components";
import React from "react";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { idGenerator } from "../../../utils/idGenerator";

type MobileFiltersModalProps = {
  onModalCloseFocusElementId: string;
  onReset: () => void;
  onDone: () => void;
  showRemoveAllUnavailableLink: boolean;
  menuItems: Array<MenuItemProps>;
  onChange: (item: MenuItemProps) => void;
  onMobileFilterClose: () => void;
  openRemoveAllModal: () => void;
};

const MobileFiltersModal = ({
  onModalCloseFocusElementId,
  onReset,
  onDone,
  showRemoveAllUnavailableLink,
  menuItems,
  onChange,
  onMobileFilterClose,
  openRemoveAllModal,
}: MobileFiltersModalProps) => {
  const { t } = useIntlMessage();
  const removeAllRolesMobileId = idGenerator(
    "yourroles",
    "removeallrolesmobile",
  ).generateId();
  return (
    <Overlay
      id={idGenerator("yourroles", "mobilefilters").generateId("overlay")}
      elementIdToFocus={onModalCloseFocusElementId}
      visible={true}
      noCloseButton={true}
      isFullscreen={true}
      noPadding={true}
      classes={{ root: "custom-overlay-fullscreen" }}
      onClose={onMobileFilterClose}
      footerContent={
        <div className="px-30 py-20">
          <Button
            id={idGenerator("yourroles", "mobilefilterdone").generateId()}
            blockedVariant={true}
            size="base"
            label={t("jobsite.profile.yourRoles.done") as string}
            onClick={onDone}
          />
        </div>
      }
    >
      <>
        <div className="d-flex justify-content-end u-border-bottom px-30 py-10">
          <TextButton
            id={idGenerator("yourroles", "resetmobilefilter").generateId()}
            label={t("jobsite.profile.yourRoles.reset") as string}
            onClick={onReset}
          />
        </div>
        <div className="mobile-roles-filter">
          <div className="u-border-bottom mx-30 my-10">
            <h2 className="t-eyebrow-reduced">
              {t("jobsite.profile.yourRoles.type")}
            </h2>
            <MenuList
              id={idGenerator("yourroles", "mobilemenulist").generateId()}
              items={menuItems}
              classes={{
                button: "text-align-start",
              }}
              handleChange={onChange}
              role="list"
              ariaLabel={t("jobsite.yourroles.menuListHint") as string}
            />
          </div>
          {showRemoveAllUnavailableLink && (
            <div className="mx-30 my-20">
              <h2 className="t-eyebrow-reduced">
                {t("jobsite.profile.yourRoles.actions")}
              </h2>
              <TextButton
                onClick={openRemoveAllModal}
                classes="t-body-reduced"
                id={removeAllRolesMobileId}
                label={
                  t("jobsite.profile.yourRoles.removeAllUnavailable") as string
                }
              />
            </div>
          )}
        </div>
      </>
    </Overlay>
  );
};

export default MobileFiltersModal;
