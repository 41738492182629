import { DropDownOptionProps } from "@rpe-js/marcom-web-components";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useRouteLoaderData } from "react-router";
import {
  Answer,
  Question,
  Questionnaire,
} from "../../../shared/types/questionnaire";
import { getQuestionnaire } from "../../api/fetchClient";
import { DEFAULT_LOCALE_API_HEADER } from "../../app.constants";
import AppContext from "../../AppContext";
import EditQuestionnaireView from "../../components/feature/EditQuestionnaireView";
import useIntlMessage from "../../hooks/useIntlMessage";
import useSetPageTitle, { PAGE_TITLES } from "../../hooks/useSetPageTitle";
import { RouteID } from "../../routes";
import { isCorporate } from "../../util";
import { idGenerator } from "../../utils/idGenerator";
import { questionnaireAction, stepAction } from "./context/Actions";
import { useApplyContext } from "./context/ApplyContext";

interface QuestionAnswerResult {
  questionId?: string;
  answer?: string;
  answered?: boolean;
}

interface QuestionnaireStepProps {
  jobId: string;
  moveNext: () => void;
  goPrevius: () => void;
}

export function QuestionnaireStep({
  jobId,
  moveNext,
  goPrevius,
}: QuestionnaireStepProps) {
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { countryCode, isInternal } = appUIState.appData;
  const jobDetails = useRouteLoaderData(RouteID.apply) as any;
  const dataLocale = isCorporate(jobDetails)
    ? DEFAULT_LOCALE_API_HEADER
    : countryCode?.data;
  const idSeqGenerator = idGenerator("apply", "questionnaire");
  const { state, dispatch } = useApplyContext();
  const showReviewSubmit = state.page.showReviewSubmit;
  const pageState = state.page;
  const { setPageTitle } = useSetPageTitle();
  const [questionList, setQuestionList] = useState<Array<Question>>(
    (state.questionnaireAnswers && state.questionnaireAnswers.questions) || [],
  );

  const updateFreeTextAnswer = useCallback((result: QuestionAnswerResult) => {
    setQuestionList((prevQuestions) =>
      prevQuestions.map((question: Question) => {
        if (question.questionId == result.questionId) {
          return {
            ...question,
            answered: result.answer && true,
            answeredFreeText: result.answer,
          };
        }
        return question;
      }),
    );
  }, []);

  const updateRankedAnswer = useCallback(
    (result: {
      questionId: string;
      answer: { answerId: string; rank: DropDownOptionProps };
    }) => {
      setQuestionList((prevQuestions) =>
        prevQuestions.map((question: Question) => {
          if (question.questionId === result.questionId) {
            if (!question.answerOptions) {
              return { ...question, answered: false };
            }
            return {
              ...question,
              answered: true,
              answerOptions: question.answerOptions.map((answer: Answer) => {
                if (
                  !result.answer ||
                  answer.answerId != result.answer.answerId ||
                  !result.answer.rank ||
                  isNaN(parseInt(result.answer.rank.label))
                ) {
                  return { ...answer };
                }
                return {
                  ...answer,
                  answeredRank: parseInt(result.answer.rank.label),
                };
              }),
            };
          }
          return question;
        }),
      );
    },
    [],
  );

  const updateAnswer = useCallback((result: QuestionAnswerResult) => {
    setQuestionList((prevQuestions) =>
      prevQuestions.map((question: Question) => {
        if (question.questionId === result.questionId) {
          if (!question.answerOptions) {
            return { ...question, answered: false };
          }
          return {
            ...question,
            answered: true,
            answerOptions: question.answerOptions.map((answer: Answer) => {
              // checkbox validation
              if (question.possibleAnswers && question.possibleAnswers > 1) {
                if (answer.answerId == result.answer) {
                  return { ...answer, answeredMultiple: result.answered };
                } else {
                  return {
                    ...answer,
                    answeredMultiple: answer.answeredMultiple || false,
                  };
                }
              } else {
                if (answer.answerId == result.answer) {
                  return { ...answer, answeredMultiple: true };
                } else {
                  return { ...answer, answeredMultiple: false };
                }
              }
            }),
          };
        }
        return question;
      }),
    );
  }, []);

  const getQuestionnaireInfo = useCallback(
    async (jobId: string) => {
      const questionnaireData: Questionnaire | undefined =
        await getQuestionnaire({
          pathParams: { jobId },
          headers: { locale: dataLocale },
        });
      if (
        questionnaireData?.questions &&
        questionnaireData.questions.length > 0
      ) {
        setQuestionList(questionnaireData.questions || []);
        dispatch(questionnaireAction(questionnaireData));
      }
    },
    [dataLocale, dispatch],
  );

  const goToPreviousStep = useCallback(
    (error?: boolean) => {
      dispatch(
        questionnaireAction({
          ...(state.questionnaireAnswers as Questionnaire),
          questions: questionList,
        }),
      );
      // when we navigate back, we are saving the questionnaire error status in questionnaireError state,
      // this is useful to navigate to questionnaire step when talent clicks on review and submit button
      dispatch(stepAction({ ...pageState, questionnaireError: error }));
      goPrevius();
    },
    [dispatch, goPrevius, pageState, questionList, state.questionnaireAnswers],
  );

  const moveToNextStep = useCallback(() => {
    dispatch(
      questionnaireAction({
        ...(state.questionnaireAnswers as Questionnaire),
        questions: questionList,
      }),
    );
    // this is useful to navigate to questionnaire step when talent clicks on review and submit button
    dispatch(stepAction({ ...pageState, questionnaireError: false }));
    moveNext();
  }, [dispatch, moveNext, pageState, questionList, state.questionnaireAnswers]);

  useEffect(() => {
    setPageTitle(
      PAGE_TITLES.APPLY.QUESTIONNAIRE,
      state.page.stepMapping?.questionnaire || 3,
      state.page.stepNames.length,
      jobDetails.postingTitle,
    );
  }, [
    isInternal,
    jobDetails.postingTitle,
    setPageTitle,
    state.page.stepMapping?.questionnaire,
    state.page.stepNames.length,
  ]);

  useEffect(() => {
    if (!questionList || questionList.length == 0) getQuestionnaireInfo(jobId);
  }, [getQuestionnaireInfo, jobId, questionList]);

  return (
    <section
      id={idSeqGenerator.generateId()}
      className="mtb-auto-40 questionnaire-container"
    >
      <h1
        id={idSeqGenerator.generateId("header")}
        className="t-headline-reduced mb-40 text-center"
      >
        {t("jobsite.profile.yourRoles.questionnaire")}
      </h1>
      <EditQuestionnaireView
        contentId={idSeqGenerator.generateId("form")}
        footerId={idSeqGenerator.generateId("footer")}
        updateAnswer={updateAnswer}
        updateFreeTextAnswer={updateFreeTextAnswer}
        questionList={questionList}
        onCancel={goToPreviousStep}
        onSave={moveToNextStep}
        showBackButton={true}
        showReviewSubmit={showReviewSubmit}
        cancelLabel="jobsite.common.back"
        saveLabel="jobsite.common.continue"
        reviewSubmitLabel="jobsite.common.reviewSubmit"
        updateRankedAnswer={updateRankedAnswer}
        freeTextErrorMessage="jobsite.apply.enterAnswer"
        singleSelectErrorMessage="jobsite.common.selectOptionAbove"
        multiSelectErrorMessage="jobsite.common.selectOneOption"
        multiSelectPossibleAnswersErrorMessage="jobsite.profile.selectOnlyPossibleAnswers"
        requiredRankErrorMessage="jobsite.common.selectOptionAbove"
        selectCorrectRankErrorMessage="jobsite.profile.yourRoles.selectCorrectRank"
      />
    </section>
  );
}
