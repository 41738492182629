import { useCallback, useContext } from "react";
import { useLocation, useNavigate } from "react-router";

import APP_CONSTANTS from "../../utilities/appConstants";
import AppContext from "../AppContext";
import { encodeUrl } from "../utils/encodeUrl";

/* Order of params
    1. Search
    2. Sort
    3. Key
    4. Lang
    5. Location
    6. Product
    7. Team
    8. HomeOffice
    9. Page
* */

export interface ISetFilters {
  search: string;
  sort: string;
  key: Array<any>;
  lang: Array<any>;
  location: Array<any>;
  product: Array<any>;
  hiringManager?: Array<string>;
  team: Array<any>;
  homeOffice: string;
  page: number;
  jobLevel: Array<string>;
  role: Array<any>;
  minHours: string;
  maxHours: string;
}

const useSearchPageQueryParams = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { appUIState } = useContext(AppContext);
  const { isInternal } = appUIState.appData;
  const searchParams = new URLSearchParams(location.search);
  const homeOffice = searchParams.get("homeOffice");
  const locations = searchParams.get("locations");
  const teams = searchParams.get("teams");
  const hiringManager = searchParams.get("hiringManager");
  const product = searchParams.get("product");
  const lang = searchParams.get("lang");
  const search = searchParams.get("search");
  const sort = searchParams.get("sort") || "newest";
  const page = parseInt(searchParams.get("page") || "1", 10);
  const customOrder = [
    APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.SEARCH,
    APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.SORT,
    APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.LOCATION,
    APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.PRODUCT,
    APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.STORE_ROLE,
    APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.MIN_HRS,
    APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.MAX_HRS,
    APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.JOB_LEVEL,
    APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.PAGE,
  ];

  function sortedQueryParams(
    urlSearchParams: URLSearchParams,
    customOrder: string[],
  ): URLSearchParams {
    // Create a map of custom order priorities for O(1) lookups
    const customOrderMap: Record<string, number> = customOrder.reduce(
      (acc, key, index) => {
        acc[key] = index;
        return acc;
      },
      {} as Record<string, number>,
    );

    // Convert URLSearchParams to an array, sort directly, and collect results
    const sortedParamsArray = Array.from(urlSearchParams.entries()).sort(
      ([keyA], [keyB]) => {
        const priorityA = customOrderMap[keyA] ?? Infinity; // Fallback to Infinity if not in custom order
        const priorityB = customOrderMap[keyB] ?? Infinity;
        return priorityA - priorityB || keyA.localeCompare(keyB);
      },
    );

    // Create a new URLSearchParams object from sorted array
    const sortedParams = new URLSearchParams();
    sortedParamsArray.forEach(([key, value]) => {
      sortedParams.append(key, value);
    });

    return sortedParams;
  }

  // TODO: Generalize implementation and combine similar filters
  const setFilters = useCallback(
    (filters: Partial<ISetFilters>, preventScrollReset?: boolean) => {
      const params = new URLSearchParams(location.search);
      const encodedParams = encodeUrl(params, filters, isInternal);
      const sortedParams = sortedQueryParams(encodedParams, customOrder);
      navigate(
        { search: sortedParams.toString().replace(/%2B/g, "+") },
        {
          replace: true,
          ...(preventScrollReset && { preventScrollReset }), // preventScrollReset: true should be added to prevent scroll from reset on change of sort, filter and search.
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, location.search, isInternal],
  );

  return {
    homeOffice,
    locations,
    teams,
    product,
    hiringManager,
    lang,
    search,
    sort,
    page,
    setFilters,
  };
};
export default useSearchPageQueryParams;
