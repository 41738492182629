import {
  BaseRefData,
  MappedERPPostLocation,
  TeamsRefData,
} from "../../../../shared/types/refData";
import { Team } from "../../../../shared/types/talent/talent";
import { PAGE_TITLES } from "../../../hooks/useSetPageTitle";
import { FileData } from "../../../types/File";
import {
  TalentSupportingFile,
  TalentSupportingLink,
} from "../../../types/SupportingFileWidget";

export enum StepName {
  CONTACT_INFO = "contactinfo",
  INTEREST = "interest",
  LOCATION = "location",
  REVIEW_INFO = "reviewinfo",
  THANKS_PAGE = "thanksPage",
}

export const ERP_PAGE_TITLES: Record<string, string> = {
  contactinfo: PAGE_TITLES.ERP.CONTACT_INFO,
  interest: PAGE_TITLES.ERP.INTEREST,
  location: PAGE_TITLES.ERP.LOCATION,
  reviewinfo: PAGE_TITLES.ERP.REVIEW_INFO,
  thanksPage: PAGE_TITLES.ERP.THANKS_PAGE,
};

export interface PageState {
  stepNames: Array<string>;
  stepMapping?: Record<string, number>;
  firstStep?: StepName;
}

export interface ReferenceData {
  country: BaseRefData[] | null;
  language: BaseRefData[] | null;
  referralType: BaseRefData[] | null;
  appleStoreRoles: BaseRefData[] | null;
  postLocations: MappedERPPostLocation[] | null;
  teamsOfInterest: TeamsRefData[] | null;
}

export interface ERPState {
  page: PageState;
  jobPositionIDs: string[];
  contact: {
    firstName: string;
    lastName: string;
    preferredEmail: string;
    preferredPhone?: string;
  };
  address: {
    countryID: string;
    countryName: string;
    typeID?: string;
  };
  language: {
    languageID: string;
    languageName: string;
  };
  typeID: string;
  notes?: string;
  resume: FileData | null;
  textResume: FileData | null;
  supportFiles: Array<TalentSupportingFile> | null;
  links: Array<TalentSupportingLink> | null;
  referenceData: ReferenceData;
  globaleManagedRoleIds: BaseRefData[];
  corporateLocations: MappedERPPostLocation[];
  retailLocations: MappedERPPostLocation[];
  teamsOfInterest: Record<string, { subTeam: string[]; isSelectAll: boolean }>;
  enableReviewSubmitBtn: boolean;
  teamsViewData: Team[];
  teamsPayload: Team[] | null;
  showRetailModal: boolean;
}
