import { Pagination } from "@rpe-js/marcom-web-components";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import AppContext from "../../../AppContext";
import { Label } from "../../../components/base/Label";
import LocaleAwareLink from "../../../components/feature/LocaleAwareLink";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { handleScrollToElement } from "../../../utils/focusUtil";
import { idGenerator } from "../../../utils/idGenerator";
import { RoleCard } from "./RoleCard";
import { useRolesContext } from "./RolesContext";
import { RoleSectioName, RoleUIObj } from "./types";

export interface RoleItem {
  title: string;
}

export interface RoleInformationProps {
  roles: RoleUIObj[];
  sectionName: RoleSectioName;
}

interface RoleZeroStateProps {
  sectionName: RoleSectioName;
}

interface MessageContainerProps {
  message: string;
  subText: string;
  linkText: string;
  link: string;
}

function MessageContainer(props: MessageContainerProps) {
  const { message, subText, linkText, link } = props;
  return (
    <div className="message-container">
      <h2 className="t-tout">{message}</h2>
      <div className="d-flex flex-column">
        <div className="message-subtext">
          <Label label={subText} />
        </div>
        <div>
          <LocaleAwareLink link={link} type="more" text={linkText} />
        </div>
      </div>
    </div>
  );
}

function RoleZeroState(props: RoleZeroStateProps) {
  const { sectionName } = props;
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const searchUrl = appUIState.appData.searchPageUrl || "search";

  const i18nMessageKey: string = useMemo(() => {
    let i18nKey = "jobsite.profile.yourRoles.favoriteNotSubmit";
    if (sectionName === "Favorites") {
      i18nKey = "jobsite.profile.yourRoles.noFavorites";
    } else if (sectionName === "Submissions") {
      i18nKey = "jobsite.profile.yourRoles.noRoles";
    } else {
      i18nKey = "jobsite.profile.yourRoles.favoriteNotSubmit";
    }
    return i18nKey;
  }, [sectionName]);

  const subText = t("jobsite.profile.yourRoles.discoverCurrentOpportunities");
  const linkText = t("jobsite.profile.yourRoles.searchRoles");

  return (
    <MessageContainer
      message={t(i18nMessageKey) as string}
      subText={subText as string}
      linkText={linkText as string}
      link={searchUrl}
    />
  );
}

export function RoleList() {
  const { state } = useRolesContext();
  const { filteredRoles, selected: sectionName } = state;
  const rolesPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const paginationId = idGenerator("profile", "roles-pagination").generateId();
  const roleListId = idGenerator("profile", "role-list").generateId();
  const totalPages = useMemo(() => {
    return Math.ceil(filteredRoles.length / rolesPerPage);
  }, [filteredRoles.length, rolesPerPage]);
  const { t } = useIntlMessage();

  const currentPageRoles = useMemo(() => {
    return filteredRoles.slice(
      (currentPage - 1) * rolesPerPage,
      currentPage * rolesPerPage,
    );
  }, [filteredRoles, currentPage, rolesPerPage]);

  const handlePageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      handleScrollToElement(roleListId);
    },
    [roleListId],
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [sectionName]);

  useEffect(() => {
    if (
      (!currentPageRoles || currentPageRoles.length === 0) &&
      currentPage > 1
    ) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPageRoles, currentPage]);

  return (
    <>
      {filteredRoles.length > 0 && (
        <h2 className="a11y">{t("jobsite.profile.yourRoles.joblisttitle")}</h2>
      )}
      <div id={roleListId} role="presentation">
        {filteredRoles.length === 0 && (
          <RoleZeroState sectionName={sectionName} />
        )}
        {filteredRoles.length > 0 && (
          <>
            <div role="list">
              {currentPageRoles.map((roleItem, idx) => (
                <RoleCard
                  roleItem={roleItem}
                  key={`section-${sectionName}-${idx}-role-${roleItem.postingTitle}`}
                />
              ))}
            </div>

            {filteredRoles.length > rolesPerPage && (
              <Pagination
                id={paginationId}
                totalPages={totalPages}
                initialPage={currentPage}
                onPageChange={handlePageChange}
                delimiter={t("jobsite.common.of") as string}
                paginationStyle="pageinput"
                paginationAriaLabel={
                  t("jobsite.common.resultPagination") as string
                }
                paginationLabel={
                  t("jobsite.profile.yourRoles.pagenumber") as string
                }
                prevButtonLabel={
                  t("jobsite.profile.yourRoles.prevpage") as string
                }
                nextButtonLabel={
                  t("jobsite.profile.yourRoles.nextpage") as string
                }
              />
            )}
          </>
        )}
      </div>
    </>
  );
}
