import {
  Button,
  Dropdown,
  DropDownOptionProps,
  TextArea,
  Textbox,
} from "@rpe-js/marcom-web-components";
import { cloneDeep } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ALERT_WRAPPER_ID, REFERRAL_TYPE_CORPORATE } from "../../app.constants";
import AppContext from "../../AppContext";
import HtmlRenderer from "../../components/base/HtmlRenderer";
import { Label } from "../../components/base/Label";
import { OptionalLabel } from "../../components/base/OptionalLabel";
import PillButton from "../../components/base/PillButton";
import { useFormInputValidator } from "../../components/feature/form/inputValidators/useFormInputValidator";
import RadioGroup from "../../components/feature/form/radioGroup";
import { ResumeWidget } from "../../components/feature/widgets/ResumeWidget";
import { SupportingFileWidget } from "../../components/feature/widgets/SupportingFileWidget";
import { useCurrentUserContext } from "../../CurrentUserContext";
import useAutoFocus from "../../hooks/useAutoFocus";
import useFocusFirstErrorField from "../../hooks/useFocusFirstErrorField";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { FileData } from "../../types/File";
import {
  TalentSupportingFile,
  TalentSupportingLink,
} from "../../types/SupportingFileWidget";
import { getPreferredName } from "../../util";
import { focusElement, focusManagementOnRemove } from "../../utils/focusUtil";
import { idGenerator } from "../../utils/idGenerator";
import {
  INTERNAL_EMAIL_PATTERN,
  validateEmailOnSubmit,
  validateSupportFiles,
  validateSupportLinks,
} from "../../utils/validatorUtil";
import { CountryDropdownView } from "../profile/components/CountryDropdownView";
import {
  deleteRoleList,
  resumeAction,
  supportingFileAction,
  supportingLinkAction,
  textResumeAction,
  toggleReviewSubmitBtn,
  updateContactDetails,
  updateCountry,
  updateLanguage,
  updateNotes,
  updateReferenceData,
  updateReferralType,
  updateRoleList,
} from "./context/Actions";
import { useERPContext } from "./context/ERPContext";
import { StepName } from "./context/ErpState";
import { useContactInformationHook } from "./useContactInformationHook";

interface ContactInformationStepProps {
  moveNext: () => void;
  goToStep: (stepName: StepName, id?: string) => void;
}

export function ContactInformationStep({
  moveNext,
  goToStep,
}: ContactInformationStepProps) {
  const headingRef = useAutoFocus<HTMLHeadingElement>();
  const { t } = useIntlMessage();
  const [canShowError, setCanShowError] = useState(false);
  const [showSupportingFileError, setShowSupportingFileError] = useState(false);
  const [roleInput, setRoleInput] = useState("");
  const { appUIState } = useContext(AppContext);
  const { locale, disableERPEmailValidation } = appUIState.appData;
  const [errorMessage, setErrorMessage] = useState("");
  const idSeqGenerator = idGenerator("erp", "contactinfo");
  const ERP_CONTAINER_ID = idGenerator("erp", "container").generateId();
  const CONTACT_INFO_HEADING_ID = idSeqGenerator.generateId("heading");
  const CONTACT_INFO_CONTAINER_ID = idSeqGenerator.generateId(
    "friend-contactinfo-container",
  );
  const CONTACT_INFO_FIRSTNAME_ID = idSeqGenerator.generateId("firstName");
  const CONTACT_INFO_LASTNAME_ID = idSeqGenerator.generateId("lastName");
  const CONTACT_INFO_EMAIL_ID = idSeqGenerator.generateId("email");
  const CONTACT_INFO_PHONE_ID = idSeqGenerator.generateId("phone");
  const CONTACT_INFO_COUNTRY_ID = idSeqGenerator.generateId("country");
  const CONTACT_INFO_RESUME_CONTAINER_ID = idSeqGenerator.generateId(
    "friend-resume-container",
  );
  const CONTACT_INFO_SUPP_FILES_ID = idSeqGenerator.generateId(
    "supportingfilewrapper",
  );
  const CONTACT_INFO_LANGUAGE_SECTION_ID =
    idSeqGenerator.generateId("language-section");
  const CONTACT_INFO_LANGUAGE_ID = idSeqGenerator.generateId("language");
  const CONTACT_INFO_REFERRAL_SECTION_ID = idSeqGenerator.generateId(
    "referraltype-section",
  );
  const CONTACT_INFO_REFERRAL_TYPE_ID =
    idSeqGenerator.generateId("referralTypeId");
  const CONTACT_INFO_ROI_SECTION_ID = idSeqGenerator.generateId(
    "roleOfInterest-section",
  );
  const CONTACT_INFO_ROI_ID = idSeqGenerator.generateId("roleOfInterest");
  const CONTACT_INFO_NOTES_ID = idSeqGenerator.generateId("note-textarea");
  const CONTACT_INFO_FOOTER_ID = idSeqGenerator.generateId("footer");
  const CONTACT_INFO_FOOTER_CONTINUE_ID =
    idSeqGenerator.generateId("footer-continuebtn");
  const CONTACT_INFO_FOOTER_REVIEW_ID =
    idSeqGenerator.generateId("footer-reviewbtn");
  const CONTACT_INFO_LANGUAGES_LABEL_ID =
    idSeqGenerator.generateId("roleOfInterest");

  const { currentUser } = useCurrentUserContext();
  const { state, dispatch } = useERPContext();
  const { isLoading, isSuccess, data, isError, invokeApis } =
    useContactInformationHook();
  const {
    validateNameEntered,
    onPasteValidateName,
    isValidPhoneNumberEntered,
    isValidPhoneNumberPasted,
    isValidEmailAddressEntered,
    isValidEmailAddressPasted,
  } = useFormInputValidator();
  const isMobile = useIsMobile();
  const { setFocusFirstErrorField } = useFocusFirstErrorField(ERP_CONTAINER_ID);
  const NOTES_TEXT_AREA_MAX_LENGTH = 30000;
  const itemsListRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    if (
      !data &&
      !isLoading &&
      !state.referenceData.country &&
      !state.referenceData.language &&
      !state.referenceData.referralType &&
      !isError
    )
      invokeApis();
    if (isSuccess && data) {
      if (data[0]) dispatch(updateReferenceData("country", data[0]));
      if (data[1]) dispatch(updateReferenceData("language", data[1]));
      if (data[2]) dispatch(updateReferenceData("referralType", data[2]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isSuccess]);

  const languageDropdownOptions: DropDownOptionProps[] = useMemo(() => {
    if (state.referenceData.language) {
      const opts: DropDownOptionProps[] = [
        {
          label: t("jobsite.erp.introduceFriend.selectLanguage") as string,
          value: "",
          disabled: true,
        },
      ];
      state.referenceData.language.forEach((crd) => {
        opts.push({
          label: crd.name,
          value: crd.id,
        });
      });
      return opts;
    } else {
      return [];
    }
  }, [state.referenceData.language, t]);

  const validatePreferredEmail = useCallback(
    (preferredEmail: string): string => {
      const result = validateEmailOnSubmit(preferredEmail);
      if (!result.valid) {
        return t(result.message) as string;
      } else if (
        !disableERPEmailValidation &&
        INTERNAL_EMAIL_PATTERN().test(preferredEmail)
      ) {
        return t("jobsite.erp.introduceFriend.internalTalentEmail") as string;
      }
      return "";
    },
    [disableERPEmailValidation, t],
  );

  const isCorporateReferralType = useCallback(() => {
    return state.typeID === REFERRAL_TYPE_CORPORATE;
  }, [state.typeID]);

  const validateRole = useCallback(() => {
    let isValid = true;
    if (isCorporateReferralType() && state.jobPositionIDs.length <= 0)
      isValid = false;
    if (roleInput.length > 0 && roleInput.length < 7) isValid = false;
    return isValid;
  }, [roleInput, state.jobPositionIDs, isCorporateReferralType]);

  const validateResumeSection = useCallback(
    (onSave: boolean) => {
      const isNotValid = !(
        (state.resume && state.resume.name) ||
        (state.textResume && state.textResume.content)
      );
      if (isNotValid && onSave) {
        focusElement(ALERT_WRAPPER_ID.generateId("file-resume"));
      }
      if (isNotValid) {
        setErrorMessage(
          t("jobsite.erp.introduceFriend.resumeUploadError") as string,
        );
      } else {
        setErrorMessage("");
      }
      return isNotValid;
    },
    [state.resume, state.textResume, t],
  );

  const moveToNextStep = useCallback(
    (stepName?: StepName) => {
      setCanShowError(true);
      setShowSupportingFileError(true);
      setErrorMessage(
        !(
          (state.resume && state.resume.name) ||
          (state.textResume && state.textResume.content)
        )
          ? (t("jobsite.erp.introduceFriend.resumeUploadError") as string)
          : "",
      );
      if (
        !state.contact.firstName ||
        !state.contact.lastName ||
        validatePreferredEmail(state.contact.preferredEmail) !== "" ||
        !state.address.countryID
      ) {
        setFocusFirstErrorField(true);
        return;
      }
      if (validateResumeSection(true)) {
        return;
      }
      if (
        !validateSupportFiles(state.supportFiles) ||
        !validateSupportLinks(state.links) ||
        !state.language.languageID ||
        !state.typeID ||
        !validateRole()
      ) {
        setFocusFirstErrorField(true);
        return;
      }
      if (stepName) {
        goToStep(stepName);
        return;
      }
      moveNext();
    },
    [
      moveNext,
      state,
      goToStep,
      validatePreferredEmail,
      validateResumeSection,
      validateRole,
      t,
      setFocusFirstErrorField,
    ],
  );

  function onContactFieldChange(data: { key: string; value: string }) {
    dispatch(updateContactDetails(data.key, data.value));
  }

  const updateRoles = useCallback(() => {
    const tempArray = cloneDeep(state.jobPositionIDs);
    roleInput.split(",").forEach((role) => {
      if (
        tempArray.includes(role.substring(0, 12)) ||
        role.trim() === "" ||
        role.length < 7
      ) {
        return;
      }
      if (tempArray.length < 10) {
        dispatch(updateRoleList([role.substring(0, 12)]));
        tempArray.push(role);
      }
    });
    if (tempArray.length > 0) {
      setRoleInput("");
    }
  }, [state, dispatch, roleInput, setRoleInput]);

  const deleteRoleID = useCallback(
    (index: number) => {
      const roleIds = cloneDeep(state.jobPositionIDs);
      roleIds.splice(index, 1);
      dispatch(deleteRoleList([...roleIds]));
      focusManagementOnRemove(
        itemsListRef,
        index,
        state.jobPositionIDs.length,
        CONTACT_INFO_ROI_ID,
      );
    },
    [state.jobPositionIDs, dispatch, CONTACT_INFO_ROI_ID],
  );

  const onSupportingFileChange = useCallback(
    (supportFileData: Array<TalentSupportingFile>) => {
      dispatch(
        supportingFileAction(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          supportFileData.map(({ id, ...rest }: TalentSupportingFile) => rest),
        ),
      );
    },
    [dispatch],
  );

  const onSupportingLinkChange = useCallback(
    (supportLinkData: Array<TalentSupportingLink>) => {
      dispatch(
        supportingLinkAction(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          supportLinkData.map(({ id, ...rest }: TalentSupportingLink) => rest),
        ),
      );
    },
    [dispatch],
  );

  const fileHandler = useCallback(
    (data: FileData, type: "resume" | "textResume") => {
      if (type === "resume") dispatch(resumeAction(data));
      else dispatch(textResumeAction(data));
      setErrorMessage(
        !((data && data.name) || (data && data.content))
          ? (t("jobsite.erp.introduceFriend.resumeUploadError") as string)
          : "",
      );
    },
    [t, dispatch],
  );

  return (
    <>
      <section className="text-center mb-40" id={CONTACT_INFO_HEADING_ID}>
        <h1 ref={headingRef} tabIndex={0} className="erp-heading">
          <span role="text">
            <div>
              {t("jobsite.common.displayName", {
                firstName: getPreferredName(
                  currentUser?.contact?.firstName as string,
                  currentUser?.contact?.preferredName,
                ),
                lastName: currentUser?.contact?.lastName,
              })}
            </div>
            <span>{t("jobsite.erp.introduceFriend.title")}</span>
            <span className="a11y">, </span>
            <span className="a11y">
              {t("jobsite.common.stepof", {
                startValue: 1,
                endValue: 4,
              })}
            </span>
          </span>
        </h1>
        <div className="erp-title">
          {t("jobsite.erp.introduceFriend.subTitle")}
        </div>
        <div className="erp-title">
          {t("jobsite.erp.introduceFriend.subTitle1")}
        </div>
      </section>
      <section
        className="reviewinfo-container mtb-auto-0"
        id={CONTACT_INFO_CONTAINER_ID}
      >
        <div className="mb-20">
          <div className="row large-justify-content-spacebetween">
            <div
              className={`column large-6 small-12 ${isMobile ? "p-0" : "pr-15"}`}
            >
              <Textbox
                id={CONTACT_INFO_FIRSTNAME_ID}
                required={true}
                label={t("jobsite.common.firstName") as string}
                value={state.contact?.firstName}
                onValueChange={(value: string) => {
                  onContactFieldChange({ key: "firstName", value });
                }}
                error={
                  canShowError &&
                  (!state.contact?.firstName
                    ? (t("jobsite.erp.errorfirstname") as string)
                    : "")
                }
                errorA11y={t("jobsite.common.errorIconLabel") as string}
                maxLength={32}
                onKeyDown={validateNameEntered}
                onPaste={onPasteValidateName}
              />
            </div>
            <div className="column large-6 small-12">
              <Textbox
                id={CONTACT_INFO_LASTNAME_ID}
                required={true}
                label={t("jobsite.common.lastName") as string}
                value={state.contact.lastName}
                onValueChange={(value: string) => {
                  onContactFieldChange({ key: "lastName", value });
                }}
                error={
                  canShowError && !state.contact?.lastName
                    ? (t("jobsite.erp.errorlastname") as string)
                    : ""
                }
                errorA11y={t("jobsite.common.errorIconLabel") as string}
                maxLength={32}
                onKeyDown={validateNameEntered}
                onPaste={onPasteValidateName}
              />
            </div>
          </div>
          <div className="row">
            <div className="column large-12">
              <Textbox
                id={CONTACT_INFO_EMAIL_ID}
                required={true}
                label={t("jobsite.common.preferredEmail") as string}
                value={state.contact.preferredEmail}
                onValueChange={(value: string) => {
                  onContactFieldChange({ key: "preferredEmail", value });
                }}
                error={
                  canShowError &&
                  validatePreferredEmail(state.contact.preferredEmail)
                }
                errorA11y={t("jobsite.common.errorIconLabel") as string}
                maxLength={50}
                onKeyDown={isValidEmailAddressEntered}
                onPaste={isValidEmailAddressPasted}
              />
            </div>
          </div>
          <div className="row">
            <div className="column large-12">
              <Textbox
                id={CONTACT_INFO_PHONE_ID}
                label={
                  t("jobsite.erp.introduceFriend.Phone") +
                  " " +
                  t("jobsite.common.optional")
                }
                value={state.contact.preferredPhone}
                onValueChange={(value: string) => {
                  onContactFieldChange({ key: "preferredPhone", value });
                }}
                maxLength={20}
                required={false}
                onKeyDown={isValidPhoneNumberEntered}
                onPaste={isValidPhoneNumberPasted}
              />
            </div>
          </div>
          <div className="row mb-10">
            <div className="column large-12">
              <CountryDropdownView
                refData={state.referenceData.country || []}
                selectedCountry={state.address.countryID}
                onChange={(e, option) =>
                  dispatch(updateCountry(option.value, option.label))
                }
                showError={canShowError && !state.address.countryID}
                id={CONTACT_INFO_COUNTRY_ID}
              ></CountryDropdownView>
            </div>
          </div>
        </div>
      </section>
      <section
        className="reviewinfo-container mtb-auto-0 scroll-margin-header"
        id={CONTACT_INFO_RESUME_CONTAINER_ID}
      >
        <div className="auto-margin text-center">
          <h2 className="erp-title">
            {t("jobsite.erp.introduceFriend.updateResume")}
          </h2>
          <p className="mb-10">
            {t("jobsite.erp.introduceFriend.ResumeFormat")}
          </p>
        </div>
        <section className="auto-margin">
          <ResumeWidget
            locale={locale}
            errorMessage={canShowError ? errorMessage : ""}
            data={state.resume || state.textResume}
            resumeFileHandler={(data) => fileHandler(data, "resume")}
            resumeTextHandler={(data) => fileHandler(data, "textResume")}
            removeErrorMessage={() => setErrorMessage("")}
          ></ResumeWidget>
        </section>
        <section
          className="mt-20 py-30 mb-20 additional-files-container scroll-margin-header"
          id={CONTACT_INFO_SUPP_FILES_ID}
        >
          <SupportingFileWidget
            talentSupportFileData={state.supportFiles}
            talentSupportLinkData={state.links}
            onSupportingFileChange={onSupportingFileChange}
            onSupportingLinkChange={onSupportingLinkChange}
            onRemoveSupportingFile={() => {}}
            onSupportingFileCategoryChange={() => {}}
            canShowError={showSupportingFileError}
            setCanShowError={setShowSupportingFileError}
          ></SupportingFileWidget>
        </section>
        <div
          className="scroll-margin-header"
          id={CONTACT_INFO_LANGUAGE_SECTION_ID}
        >
          <Label
            id={CONTACT_INFO_LANGUAGES_LABEL_ID}
            label={t("jobsite.erp.introduceFriend.languagetocontact") as string}
            variant="prominent"
            classes={[canShowError && !state.language.languageID ? "red" : ""]}
            htmlFor={CONTACT_INFO_LANGUAGE_ID}
          />
          <div className="row mb-10 mt-10">
            <div className="column large-12">
              <Dropdown
                aria-describedby={CONTACT_INFO_LANGUAGES_LABEL_ID}
                required={true}
                id={CONTACT_INFO_LANGUAGE_ID}
                options={languageDropdownOptions}
                value={state.language.languageID}
                label={
                  t("jobsite.erp.introduceFriend.selectLanguage") as string
                }
                name={t("jobsite.erp.introduceFriend.selectLanguage") as string}
                handleValueSelect={(e: any, option) => {
                  dispatch(updateLanguage(option.value, option.label));
                }}
                error={
                  canShowError && !state.language.languageID
                    ? (t("jobsite.common.errorlanguage") as string)
                    : ""
                }
                errorA11y={t("jobsite.common.errorIconLabel") as string}
              />
            </div>
          </div>
        </div>
        <div
          className="mb-10 scroll-margin-header"
          id={CONTACT_INFO_REFERRAL_SECTION_ID}
        >
          <RadioGroup
            required={true}
            id={CONTACT_INFO_REFERRAL_TYPE_ID}
            classes={{
              root: "mt-0",
            }}
            title={t("jobsite.erp.introduceFriend.referralType") as string}
            name="referralType"
            options={
              state.referenceData.referralType
                ? state.referenceData.referralType.map((data) => {
                    return {
                      label: data.name,
                      id: data.id,
                      value: data.id,
                    };
                  })
                : []
            }
            selectedValue={state.typeID}
            onChange={(event) => {
              dispatch(updateReferralType(event.target.value));
              dispatch(toggleReviewSubmitBtn(false));
            }}
            errorMessage={
              canShowError && !state.typeID
                ? (t("jobsite.common.chooseOption") as string)
                : ""
            }
            errorA11y={t("jobsite.common.errorIconLabel") as string}
          />
        </div>
        <div
          className="mb-10 scroll-margin-header"
          id={CONTACT_INFO_ROI_SECTION_ID}
        >
          <Textbox
            id={CONTACT_INFO_ROI_ID}
            required={isCorporateReferralType()}
            placeholder={
              t(
                "jobsite.erp.introduceFriend.roleOfInterest.placeholder",
              ) as string
            }
            label={
              (t("jobsite.erp.introduceFriend.roleOfInterest") +
                (!isCorporateReferralType()
                  ? " " + t("jobsite.common.optional")
                  : "")) as string
            }
            value={roleInput}
            onValueChange={(value: string) => {
              setRoleInput(value.replace(/[^\d,]/gm, ""));
            }}
            onBlur={updateRoles}
            onKeyUp={(event) => {
              event.preventDefault();
              if (event.key === "Enter") updateRoles();
            }}
            error={
              canShowError && !validateRole()
                ? (t("jobsite.erp.roleError") as string)
                : ""
            }
            errorA11y={t("jobsite.common.errorIconLabel") as string}
          />
          {state.jobPositionIDs.length > 0 && (
            <>
              <span role="status" aria-live="polite">
                <span className="label-grey">
                  {t("jobsite.erp.introduceFriend.roleList")} &nbsp;
                  <strong>{state.jobPositionIDs.length}</strong>&nbsp; (
                  <HtmlRenderer
                    initialContent={t("jobsite.common.maxVal") as string}
                    htmlTag="span"
                  />
                  10)
                </span>
              </span>
              <ul
                role="list"
                className="d-flex flex-wrap list-nobullet"
                ref={itemsListRef}
              >
                {state.jobPositionIDs.map((data, index) => (
                  <li role="listitem" key={`role_${data}`}>
                    <PillButton
                      id={idSeqGenerator.generateId(`${index}`)}
                      label={data}
                      buttonSize="small"
                      onRemove={() => deleteRoleID(index)}
                    />
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
        <div className="mb-20 scroll-margin-header">
          <OptionalLabel
            label={t("jobsite.erp.introduceFriend.addYourNote") as string}
            variant="prominent"
            optionalLabel={t("jobsite.common.optional") as string}
            htmlFor={CONTACT_INFO_NOTES_ID}
          />
          <p className="mt-5" id="character-count">
            <span>
              <span>{t("jobsite.common.CommentcharacterCount")} </span>
              <span>{state.notes?.length ?? 0} </span>
              <span>
                {t("jobsite.comments.maxCount", {
                  maxCount: NOTES_TEXT_AREA_MAX_LENGTH,
                })}
              </span>
            </span>
          </p>

          <TextArea
            id={CONTACT_INFO_NOTES_ID}
            required={false}
            onValueChange={(value: string) => dispatch(updateNotes(value))}
            placeholder={
              t("jobsite.erp.introduceFriend.enterNoteCharCount") as string
            }
            value={state.notes}
            maxLength={NOTES_TEXT_AREA_MAX_LENGTH}
            classes={{ input: "resume-section-textarea" }}
            aria-describedby="character-count"
            aria-labelledby="" //adding explicitly so VoiceOver announces label, AX requirememnt
          ></TextArea>
        </div>
      </section>
      <section
        id={CONTACT_INFO_FOOTER_ID}
        className="reviewinfo-container mtb-auto-0 d-flex justify-center pt-20 save-cancel-btngroup"
      >
        <div className="d-flex w-25 save-cancel-btngroup">
          <span className="mr-10 d-flex-equal">
            <Button
              size="base"
              color="primary"
              blockedVariant={true}
              label={t("jobsite.common.continue") as string}
              onClick={() => moveToNextStep()}
              id={CONTACT_INFO_FOOTER_CONTINUE_ID}
            />
          </span>
        </div>
        {state.enableReviewSubmitBtn && (
          <>
            <div className="d-flex w-25 save-cancel-btngroup">
              <span className="d-flex-equal">
                <Button
                  size="base"
                  color="primary"
                  blockedVariant={true}
                  label={t("jobsite.common.reviewSubmit") as string}
                  onClick={() => moveToNextStep(StepName.REVIEW_INFO)}
                  id={CONTACT_INFO_FOOTER_REVIEW_ID}
                />
              </span>
            </div>
          </>
        )}
      </section>
    </>
  );
}
