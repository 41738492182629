import { formatDateTimeByLocaleUtil } from "@rpe-js/core/dist/util/dateUtil";
import { DropDownOptionProps } from "@rpe-js/marcom-web-components";
import { cloneDeep } from "lodash";
import React, { useContext } from "react";
import { SavedSearchType } from "../../../../shared/types/savedSearchTypes";
import AppContext from "../../../AppContext";
import SavedSearchesSelector from "../../../components/feature/savedSearchesSelector";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { idGenerator } from "../../../utils/idGenerator";
import { useSavedSearchesContext } from "./context/SavedSearchesContext";

type EditSavedSearchProps = {
  searchData: SavedSearchType;
  setSearchData: (search: SavedSearchType) => void;
};

const EditSavedSearch = ({
  searchData,
  setSearchData,
}: EditSavedSearchProps) => {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const { appUIState } = useContext(AppContext);
  const { locale } = appUIState.appData;
  const {
    state: { jobAgentEmailFrequency, jobAgentEmailExperience },
  } = useSavedSearchesContext();

  const onDropdownChange = (option: DropDownOptionProps, name: string) => {
    const searchCopy = cloneDeep(searchData);
    if (name === "emailAlertFreqency") {
      searchCopy.emailAlertFrequency.freqID = option.value;
      searchCopy.emailAlertFrequency.emailAlertFrequency = option.label;
    } else if (name === "emailExpiration") {
      searchCopy.emailAlertExpiration.expID = option.value;
      searchCopy.emailAlertExpiration.emailAlertExpiration = option.label;
    }
    setSearchData(searchCopy);
  };
  return (
    <div className="row mb-10 pb-10 u-border-bottom">
      <div
        className={`column large-6 small-12 ${isMobile ? "mb-10" : "pr-10"}`}
      >
        <SavedSearchesSelector
          id={idGenerator(
            "savedsearches",
            `emailalertfreqency-${searchData.id}`,
          ).generateId()}
          name="emailAlertFreqency"
          options={jobAgentEmailFrequency}
          selectedValue={searchData.emailAlertFrequency.freqID}
          onDropdownChange={(option) =>
            onDropdownChange(option, "emailAlertFreqency")
          }
        />
        <p className="label-grey mt-10">
          {`${t(
            searchData.expiryFlag
              ? "jobsite.savedsearch.expired"
              : "jobsite.savedsearch.expires",
          )}: ${searchData.expiryDate && formatDateTimeByLocaleUtil(searchData.expiryDate.toString(), locale, false)}`}
        </p>
      </div>
      <div className="column large-6 small-12">
        <SavedSearchesSelector
          id={idGenerator(
            "savedsearches",
            `emailexpiration-${searchData.id}`,
          ).generateId()}
          name="emailExpiration"
          options={jobAgentEmailExperience}
          selectedValue={searchData.emailAlertExpiration.expID}
          onDropdownChange={(option) =>
            onDropdownChange(option, "emailExpiration")
          }
        />
      </div>
    </div>
  );
};

export default EditSavedSearch;
