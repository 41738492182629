import React, { useCallback, useContext, useEffect, useState } from "react";
import { useRouteLoaderData } from "react-router";
import { LinkedInProfilePreview } from "../../../shared/types/config";
import {
  DEFAULT_LOCALE_API_HEADER,
  DEFAULT_TRANSLATION_LOCALE,
} from "../../app.constants";
import AppContext from "../../AppContext";
import { ResumeWidgetWrapper } from "../../components/feature/widgets/ResumeWidgetWrapper";
import { SupportingFileWidget } from "../../components/feature/widgets/SupportingFileWidget";
import useFocusFirstErrorField from "../../hooks/useFocusFirstErrorField";
import useIntlMessage from "../../hooks/useIntlMessage";
import useSetPageTitle, { PAGE_TITLES } from "../../hooks/useSetPageTitle";
import { RouteID } from "../../routes";
import { FileData } from "../../types/File";
import { LinkedInData } from "../../types/LinkedIn";
import {
  TalentSupportingFile,
  TalentSupportingLink,
} from "../../types/SupportingFileWidget";
import { isCorporate } from "../../util";
import { idGenerator } from "../../utils/idGenerator";
import ApplyActionContainer from "./ApplyActionContainer";
import {
  discardFileAction,
  linkedINAction,
  linkedINDiscardAction,
  profileUpdateSelectionAction,
  supportingFileAction,
  supportingFileCategoryAction,
  supportingLinkAction,
} from "./context/Actions";
import { useApplyContext } from "./context/ApplyContext";
import {
  ApplyState,
  profileUpdateMode,
  ProfileUpdateSelection,
  StepName,
} from "./context/ApplyState";
import {
  useMoveToNextStep,
  useResumeFileHandler,
  useResumeTextHandler,
  useValidateLinkedIn,
  useValidateResume,
  useValidateTextResume,
} from "./useResume";

interface ResumeStepProps {
  moveNext: () => void;
  goToStep: (stepName: StepName) => void;
}

export interface IProfileFillOptions {
  id: string;
  label: string;
  value: string;
  showOption: boolean;
}

function getInitialResumeData(state: ApplyState) {
  // file resume data
  if (state.resume && state.resume.name) {
    return state.resume;
  }
  // text resume data
  if (state.textResume && state.textResume.name) {
    return state.textResume;
  }
  return null;
}

export function ResumeStep({ moveNext, goToStep }: ResumeStepProps) {
  const { t } = useIntlMessage();
  const jobDetails = useRouteLoaderData(RouteID.apply) as any;
  const { appUIState } = useContext(AppContext);
  const idSeqGenerator = idGenerator("apply", "resume");
  const { isInternal, countryCode } = appUIState.appData;
  const corporateRole = isCorporate(jobDetails);
  const locale = corporateRole
    ? DEFAULT_TRANSLATION_LOCALE
    : countryCode?.htmlLang || DEFAULT_TRANSLATION_LOCALE;
  const dataLocale = corporateRole ? DEFAULT_LOCALE_API_HEADER : "";
  const { state, dispatch } = useApplyContext();
  const talent = state.talent;
  const showReviewSubmit = state.page.showReviewSubmit;
  const { setPageTitle } = useSetPageTitle();
  const [errorMessage, setErrorMessage] = useState("");
  const [linkedInData] = useState<LinkedInData | null>(
    (talent.externalProfiles && talent.externalProfiles.linkedin) || null,
  );
  const initialResumeData = getInitialResumeData(state);
  const [resumeData] = useState<FileData | null>(initialResumeData);
  const [supportingFileData] = useState<Array<TalentSupportingFile> | null>(
    state.supportFiles,
  );
  const [supportingLinkData] = useState<Array<TalentSupportingLink> | null>(
    state.talent?.links || [],
  );
  const [canShowError, setCanShowError] = useState(false);
  const [showSupportingFileError, setShowSupportingFileError] = useState(false);
  const APPLY_CONTAINER_ID = idGenerator("apply", "container").generateId();
  const { setFocusFirstErrorField } =
    useFocusFirstErrorField(APPLY_CONTAINER_ID);
  const [canShowProfileOptions, setCanShowProfileOptions] = useState(false);
  const [profileFillOptions, setProfileFillOptions] = useState<
    IProfileFillOptions[]
  >([
    {
      id: "apply-flow-resumeOption",
      label: t("jobsite.common.resumeOptionResume") as string,
      value: profileUpdateMode.RESUME,
      showOption: initialResumeData && initialResumeData.name ? true : false, // default option for resume option
    },
    {
      id: "apply-flow-linkedinOption",
      label: t("jobsite.common.resumeOptionLinkedin") as string,
      value: profileUpdateMode.LINKEDIN,
      showOption:
        talent.externalProfiles && talent.externalProfiles.linkedin
          ? true
          : false, // default option for linkedin option
    },
    {
      id: "apply-flow-manualOption",
      label: t("jobsite.common.resumeOptionManual") as string,
      value: profileUpdateMode.MANUAL,
      showOption: true,
    },
  ]);

  const [profileSelectionMode, setProfileSelectionMode] = useState(
    state.talent.profileUpdateSelection || profileUpdateMode.MANUAL,
  );

  const removeErrorMessage = useCallback(() => {
    setErrorMessage("");
  }, []);
  const validateResume = useValidateResume();
  const validateLinkedIn = useValidateLinkedIn();
  const validateTextResume = useValidateTextResume();
  const updateProfileOptions = useCallback(
    (index: number, showOption: boolean) => {
      const profileOptions = [...profileFillOptions];
      profileFillOptions[index].showOption = showOption;
      setProfileFillOptions([...profileOptions]);
      setCanShowProfileOptions(true);
    },
    [profileFillOptions],
  );

  const resumeFileHandler = useResumeFileHandler(
    updateProfileOptions,
    validateLinkedIn,
    validateTextResume,
    setErrorMessage,
  );

  const resumeTextHandler = useResumeTextHandler(
    setErrorMessage,
    profileFillOptions,
    setProfileFillOptions,
    setCanShowProfileOptions,
  );

  const changeProfileOption = useCallback(
    (selection: ProfileUpdateSelection) => {
      dispatch(profileUpdateSelectionAction(selection));
      setProfileSelectionMode(selection);
    },
    [dispatch],
  );

  const moveToNextStep = useMoveToNextStep(
    setCanShowError,
    setShowSupportingFileError,
    setErrorMessage,
    setFocusFirstErrorField,
    goToStep,
    moveNext,
  );

  const onSupportingFileChange = useCallback(
    (supportFileData: Array<TalentSupportingFile>) => {
      dispatch(
        supportingFileAction(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          supportFileData.map(({ id, ...rest }: TalentSupportingFile) => rest),
        ),
      );
    },
    [dispatch],
  );

  const onSupportingLinkChange = useCallback(
    (supportLinkData: Array<TalentSupportingLink>) => {
      dispatch(
        supportingLinkAction(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          supportLinkData.map(({ id, ...rest }: TalentSupportingLink) => rest),
        ),
      );
    },
    [dispatch],
  );

  const onSupportingFileCategoryChange = useCallback(
    (supportFileData: TalentSupportingFile) => {
      if (supportFileData.fileData && supportFileData.fileData.fileId)
        dispatch(
          supportingFileCategoryAction(supportFileData.fileData.fileId, {
            typeId: supportFileData.category,
            type: supportFileData.categoryName,
          }),
        );
    },
    [dispatch],
  );

  const onRemoveSupportingFile = useCallback(
    (removedFile: TalentSupportingFile) => {
      if (removedFile.fileData && removedFile.fileData.fileId) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, ...rest } = removedFile;
        dispatch(discardFileAction(rest));
      }
    },
    [dispatch],
  );

  const addLinkedIn = (profileData: LinkedInProfilePreview) => {
    dispatch(
      linkedINAction({
        url: profileData?.publicProfileUrl || "",
        addedOn: new Date().getTime(),
      }),
    );
    dispatch({
      type: "UPDATE_LINKEDIN_SNAPSHOT",
      payload: JSON.stringify(profileData),
    });
    // if linkedin is added/removed , then we should make changes to profile fill view
    updateProfileOptions(1, true);
    setErrorMessage("");
  };

  const removeLinkedIn = () => {
    dispatch(linkedINDiscardAction());
    updateProfileOptions(1, false);
    if (!validateTextResume() && !validateResume()) {
      setErrorMessage(t("jobsite.common.resumeOptionError") as string);
    }
  };

  useEffect(() => {
    setPageTitle(
      PAGE_TITLES.APPLY.RESUME,
      state.page.stepMapping?.resume || 1,
      state.page.stepNames.length,
      jobDetails.postingTitle,
    );
  }, [
    isInternal,
    jobDetails.postingTitle,
    setPageTitle,
    state.page.stepMapping?.resume,
    state.page.stepNames.length,
  ]);

  return (
    <>
      <section
        id={idSeqGenerator.generateId("header")}
        className="auto-margin text-center"
      >
        <section className="resume-information-section mtb-auto-40">
          <h1
            className="t-callout"
            id={idSeqGenerator.generateId("header-resumetext")}
          >
            {t("jobsite.common.yourResume")}
          </h1>
          <p>{t("jobsite.common.resumeDescription")}</p>
        </section>
      </section>
      <section
        id={idSeqGenerator.generateId("widgetwrapper")}
        className="auto-margin"
      >
        <h2 className="a11y">
          {t("jobsite.information.attachResumeorLinkedin")}
        </h2>
        <ResumeWidgetWrapper
          locale={locale}
          resumeData={resumeData}
          linkedInData={linkedInData}
          profileFillOptions={profileFillOptions}
          profileSelectionMode={profileSelectionMode}
          canShowProfileOptions={canShowProfileOptions}
          errorMessage={canShowError ? errorMessage : ""}
          removeErrorMessage={removeErrorMessage}
          changeProfileOption={changeProfileOption}
          resumeFileHandler={resumeFileHandler}
          resumeTextHandler={resumeTextHandler}
          addLinkedIn={addLinkedIn}
          removeLinkedIn={removeLinkedIn}
        ></ResumeWidgetWrapper>
      </section>
      <section
        id={idSeqGenerator.generateId("supportingfilewrapper")}
        className="mt-20 py-30 mb-20 additional-files-container "
      >
        <SupportingFileWidget
          dataLocale={dataLocale}
          talentSupportFileData={supportingFileData}
          talentSupportLinkData={supportingLinkData}
          onSupportingFileChange={onSupportingFileChange}
          onSupportingLinkChange={onSupportingLinkChange}
          onRemoveSupportingFile={onRemoveSupportingFile}
          onSupportingFileCategoryChange={onSupportingFileCategoryChange}
          canShowError={showSupportingFileError}
          setCanShowError={setShowSupportingFileError}
        ></SupportingFileWidget>
      </section>
      <section
        id={idSeqGenerator.generateId("footer")}
        className="d-flex justify-center"
      >
        <ApplyActionContainer
          saveLabelName={t("jobsite.common.continue") as string}
          reviewLabelName={t("jobsite.common.reviewSubmit") as string}
          showBackButton={false}
          showReviewButton={showReviewSubmit || false}
          onContinue={() => moveToNextStep(false)}
          onReviewSubmit={() => moveToNextStep(true)}
          cancelLabelName={t("jobsite.common.back") as string}
        ></ApplyActionContainer>
      </section>
    </>
  );
}
