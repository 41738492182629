import { useCallback } from "react";
import {
  AllowedMyApplicationUpdateAction,
  MyApplication,
} from "../../../../../shared/types/talent/myApplication";
import {
  createMyApplicaton,
  updateMyApplicaton,
} from "../../../../api/fetchClient";
import { useFetchDataStatus } from "../../../../hooks/useFetchData";

export function useCreateOrUpdateApplication() {
  const { isError, isLoading, isSuccess, updateFetchStatus, error, data } =
    useFetchDataStatus<MyApplication>();

  const createOrUpdate = useCallback(
    async (
      talentId: string,
      createPayload: Partial<MyApplication>,
      applicationId?: string,
    ) => {
      try {
        updateFetchStatus(true, false, false, null, null);
        const application = applicationId
          ? await updateMyApplicaton(
              talentId,
              applicationId,
              "selfdisclosure",
              createPayload,
            )
          : await createMyApplicaton(talentId, createPayload);
        updateFetchStatus(false, true, false, application, null);
      } catch (e) {
        updateFetchStatus(false, false, true, null, e);
      }
    },
    [updateFetchStatus],
  );

  return {
    isCrtOrUpdApplicationError: isError,
    isCrtOrUpdApplicationLoading: isLoading,
    isCrtOrUpdApplicationSuccess: isSuccess,
    crtOrUpdApplicationError: error,
    crtOrUpdApplication: data,
    createOrUpdate,
  };
}

export function useUpdateApplication() {
  const { isError, isLoading, isSuccess, updateFetchStatus, error, data } =
    useFetchDataStatus<MyApplication>();

  const updateApplication = useCallback(
    async (
      talentId: string,
      applicationId: string,
      action: keyof typeof AllowedMyApplicationUpdateAction,
      updatePayload: Partial<MyApplication>,
    ) => {
      try {
        updateFetchStatus(true, false, false, null, null);
        const application = await updateMyApplicaton(
          talentId,
          applicationId,
          action,
          updatePayload,
        );
        updateFetchStatus(false, true, false, application, null);
      } catch (e) {
        updateFetchStatus(false, false, true, null, e);
      }
    },
    [updateFetchStatus],
  );

  const resetUpdateApplication = useCallback(() => {
    updateFetchStatus(true, false, false, null, null);
  }, [updateFetchStatus]);

  return {
    isUpdateApplicationError: isError,
    isUpdateApplicationLoading: isLoading,
    isUpdateApplicationSuccess: isSuccess,
    updateApplicationError: error,
    updatedApplication: data,
    updateApplication,
    resetUpdateApplication,
  };
}
