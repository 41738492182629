import { NativeButton, TextButton } from "@rpe-js/marcom-web-components";
import React, { useEffect } from "react";
import useIntlMessage from "../../../../hooks/useIntlMessage";
import { handleScrollToElement } from "../../../../utils/focusUtil";
import { SectionMode } from "../context/types";

export interface SectionHeaderProps {
  id: string;
  title: string;
  mode: SectionMode;
  children: React.ReactNode;
  onEdit: () => void;
  editDisabled?: boolean;
}

export function SectionHeader(props: SectionHeaderProps) {
  const { id, title, mode, children, onEdit, editDisabled = false } = props;
  const { t } = useIntlMessage();

  // this useeffect executes on load for every section of application and
  // scrolls to a particular section which needs to be edited
  useEffect(() => {
    if (mode == "create" || mode == "edit") {
      handleScrollToElement(id);
    }
  }, [id, mode]);

  return (
    <li className="pt-15 pb-15 u-border-top scroll-margin-header" id={id}>
      {mode !== "disabled" && (
        <div className="d-flex">
          <h3 className="t-eyebrow-reduced d-flex-equal">{t(title)}</h3>
          {mode === "view" && !editDisabled && (
            <TextButton
              id={`${id}-edit-button`}
              label={t("jobsite.profile.myApplication.edit") as string}
              classes="t-body-reduced"
              onClick={onEdit}
              aria-label={`${t("jobsite.profile.myApplication.edit")} ${t(title)}`}
            />
          )}
        </div>
      )}
      {mode === "disabled" && (
        <NativeButton
          id={`${id}-button`}
          className="t-body"
          aria-expanded={true}
          disabled
          label={t(title) as string}
        ></NativeButton>
      )}
      {mode !== "disabled" && children}
    </li>
  );
}
