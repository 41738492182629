import dateUtil from "@rpe-js/core/dist/util/dateUtil";
import { Button, NativeButton } from "@rpe-js/marcom-web-components";
import React, { useEffect, useState } from "react";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { FileData } from "../../../types/File";
import { idGenerator } from "../../../utils/idGenerator";
import Icon from "../../base/IconComponent";

interface ResumePreviewWidgetProps {
  isTextResume: boolean | null;
  locale: string;
  editResumeText?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  fileData: FileData | null;
  removeFile: (
    event: React.MouseEvent<HTMLButtonElement>,
    isTextResume: boolean,
  ) => void;
  downloadFile: (fileId: string, bucketId: string) => void;
}

export function ResumePreviewWidget({
  isTextResume = false,
  locale,
  editResumeText,
  removeFile,
  downloadFile,
  fileData,
}: ResumePreviewWidgetProps) {
  const { t } = useIntlMessage();
  const [uploadedDate, setUploadedDate] = useState<string>("");
  useEffect(() => {
    if (fileData && fileData.createdTime) {
      setUploadedDate(
        t("jobsite.common.resumeUploaded", {
          date: dateUtil.formatDateTimeByLocaleUtil(
            fileData.createdTime,
            locale,
          ),
        }) as string,
      );
    }
  }, [fileData, locale, t]);

  return (
    <section className="pt-30 pt-15 pb-30 pl-15">
      <div className="text-center" aria-hidden="true">
        <Icon name={"resume"} size={"xlarge"}></Icon>
      </div>
      <div className="attachment-info">
        {fileData && typeof fileData.createdTime == "number" && (
          <p
            className="m-0 file-info t-body-reduced"
            id={idGenerator("resume", "filename").generateId()}
          >
            {fileData.name}
          </p>
        )}
        {fileData && typeof fileData.createdTime == "string" && (
          <p className="m-0 t-body saved-resume-file">
            <Button
              id={idGenerator("resume", "downloadfile").generateId()}
              label={fileData.name}
              onClick={() => {
                downloadFile(
                  fileData.fileId as string,
                  fileData.bucketId as string,
                );
              }}
            ></Button>
          </p>
        )}
        <p className="mt-0">{uploadedDate}</p>
        <p className="mt-10 text-center">
          {isTextResume && (
            <a
              id={idGenerator("resume", "textedit").generateId()}
              href="#"
              onClick={editResumeText}
            >
              {t("jobsite.common.edit")}
            </a>
          )}
          <NativeButton
            id={idGenerator("resume", "remove").generateId()}
            className="ml-10 link"
            onClick={(event) => removeFile(event, isTextResume ?? false)}
            label={t("jobsite.common.remove") as string}
            aria-label={
              t("jobsite.common.remove") + " " + (fileData?.name || "")
            }
          ></NativeButton>
        </p>
      </div>
    </section>
  );
}
