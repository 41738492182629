/* eslint-disable */

import { AccordionItem } from "@rpe-js/marcom-web-components/lib/CustomAccordion/CustomAccordion";
import * as React from "react";
import { useEffect, useState } from "react";
import APP_CONSTANTS from "../../../utilities/appConstants";
import useIdGenerator from "../../hooks/useIdGenerator";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import useSearchPageQueryParams from "../../hooks/useSearchPageQueryParams";
import { getCheckboxClasses } from "../../util";
import { mergeSelectedIntoAll } from "../../utils/filterUtil";
import { idGenerator } from "../../utils/idGenerator";
import { FilterCheckboxGroup, FilterOption } from "./FilterCheckboxGroup";

type Props = {
  levelsData: Array<FilterOption>;
  onSelectLevel: (levels: Array<FilterOption>) => void;
  onRemoveLevel: () => void;
};

export function InternalJobLevelFilter({
  levelsData,
  onSelectLevel,
  onRemoveLevel,
}: Props) {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const { setFilters } = useSearchPageQueryParams();
  const ACCORDION_ID = idGenerator(
    "search",
    "jobLevel-filter-accordion",
  ).generateId();
  const LEVELS_OBJECT: FilterOption[] = [
    {
      id: APP_CONSTANTS.INTERNAL_FILTER_JOB_LEVELS.MY_LEVEL,
      name: t("jobsite.search.myJobLevel") as string,
      selected: false,
    },
    {
      id: APP_CONSTANTS.INTERNAL_FILTER_JOB_LEVELS.MY_LEVEL_PLUS_ONE,
      name: t("jobsite.search.myJobLevel1") as string,
      selected: false,
    },
  ];
  const [levels, setLevels] = useState(() => {
    return LEVELS_OBJECT.map((item) => ({
      ...item,
      selected: true,
    }));
  });
  const [count, setCount] = useState(levelsData.length);
  const handleLevelSelect = (id: string, selected: boolean) => {
    const updatedLevels = levels.map((level) => {
      return level.id == id ? { ...level, selected } : level;
    });
    setLevels(updatedLevels);
    const selectedLevels = updatedLevels.filter((level) => level.selected);
    setCount(selectedLevels.length);
    if (selectedLevels.length === 0) {
      onRemoveLevel();
    } else {
      const arrayOfSelectedLevels = selectedLevels.filter(
        (level) => level.selected,
      );
      onSelectLevel(arrayOfSelectedLevels);
    }
  };

  useEffect(() => {
    const updatedLevels = [...mergeSelectedIntoAll(levels, levelsData)];
    setLevels(updatedLevels);
    const selectedArray = updatedLevels
      .filter((item) => item.selected)
      .map((item) => item.id);
    setCount(selectedArray.length);
    // setFilters({ jobLevel: selectedArray });
  }, [levelsData]);

  return (
    <>
      <AccordionItem
        clickableTitle={true}
        noPadding={true}
        title={`<span class="d-flex-equal fw-medium">${t("jobsite.search.jobLevel")}</span>
                <span class="d-inline-block mr-5 mt-0 ${count > 0 ? "counter" : "d-none"}" aria-hidden="true">${count}</span>
                <span class="a11y">${t("jobsite.common.filterAppliedCount", { count: count })}</span>`}
        titleWrapperTag={"h3"}
        titleWrapperTagAttrs={{
          className: "d-flex",
        }}
        expandableIconsPlus={true}
        index={6}
        id={ACCORDION_ID}
      >
        <FilterCheckboxGroup
          id={useIdGenerator("filter", "jobLevel")}
          filterList={levels}
          onFilterChange={handleLevelSelect}
          classNames={getCheckboxClasses(isMobile)}
        />
      </AccordionItem>
    </>
  );
}
