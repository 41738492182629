import { ButtonAriaRequiredProps } from "@rpe-js/marcom-web-components";
import React from "react";
import Icon, { IconProps } from "./IconComponent";

interface Props extends IconProps, React.HTMLAttributes<Element> {
  textColor?: "blue";
  onClick: () => void;
  iconClasses?: string;
  id: string;
  iconButtonRef?: React.Ref<HTMLButtonElement>;
}

type IconButtonProps = Props & ButtonAriaRequiredProps;

const IconButton = ({
  name,
  size,
  onClick,
  label,
  textColor,
  cursor,
  classes,
  iconClasses,
  iconButtonRef,
  ...rest
}: IconButtonProps) => {
  const colorClasses = { blue: "blue" };

  return (
    <button ref={iconButtonRef} onClick={onClick} className={classes} {...rest}>
      <Icon
        name={name}
        size={size}
        classes={iconClasses}
        cursor={cursor}
      ></Icon>
      {label && (
        <span className={`ml-10 ${textColor ? colorClasses[textColor] : ""}`}>
          {label}
        </span>
      )}
    </button>
  );
};

export default IconButton;
