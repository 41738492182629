import { idGenerator } from "../../../utils/idGenerator";

export const EMAIL_CODE_GENERATION_MODAL_ID = idGenerator(
  "email",
  "replace-modal",
).generateId();

export const EMAIL_CODE_GENERATION_MODAL_TITLE_ID = idGenerator(
  "email",
  "replace-modal",
).generateId("title");

export const EMAIL_VERIFICATION_MODAL_ID = idGenerator(
  "email",
  "verification-modal",
).generateId();

export const EMAIL_VERIFICATION_MODAL_TITLE_ID = idGenerator(
  "email",
  "verification-modal",
).generateId("title");

export const EMAIL_REPLACE_BUTTON = idGenerator(
  "email",
  "replace-button",
).generateId();

export const EMAIL_CODE_GENERATION_CANCEL_BUTTON_ID = idGenerator(
  "email",
  "replace-modal",
).generateId("cancel-button");

export const EMAIL_CODE_GENERATION_CONTINUE_BUTTON_ID = idGenerator(
  "email",
  "replace-modal",
).generateId("continue-button");

export const EMAIL_VERIFICATION_MODAL_SEND_CODE_BUTTON_ID = idGenerator(
  "email",
  "verification-modal",
).generateId("send-code-button");

export const EMAIL_VERIFICATION_MODAL_CANCEL_BUTTON_ID = idGenerator(
  "email",
  "verification-modal",
).generateId("cancel-button");

export const EMAIL_VERIFICATION_MODAL_CONTINUE_BUTTON_ID = idGenerator(
  "email",
  "verification-modal",
).generateId("continue-button");
