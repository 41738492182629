import { Overlay } from "@rpe-js/marcom-web-components";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  EducationGradStatusRefData,
  MonthsRefData,
  ProficiencyLanguageRefData,
} from "../../../shared/types/refData";
import { Skill } from "../../../shared/types/talent/talent";
import AppContext from "../../AppContext";
import { SaveCancelAction } from "../../components/feature/saveAndCancel";
import useIntlMessage from "../../hooks/useIntlMessage";
import { focusElementWithFallback } from "../../utils/focusUtil";
import { EducationDegreeForm } from "../profile/components/educationSummary/form";
import { EmploymentForm } from "../profile/information/employmentFormUtils";
import { LanguageForm } from "../profile/information/spokenLanguagesFormUtils";
import { ParsedEducationDegrees } from "./ParsedEducationDegrees";
import { ParsedEmployments } from "./ParsedEmployments";
import { ParsedLanguages } from "./ParsedLanguages";
import { ParsedSection } from "./ParsedSection";
import { ParsedSkills } from "./ParsedSkills";
import {
  PARSED_MODAL_CONTAINER_ID,
  PARSED_MODAL_DISCARD_ALL_BUTTON_ID,
  PARSED_MODAL_EDUCATION_TITLE_ID,
  PARSED_MODAL_EMPLOYMENTS_TITLE_ID,
  PARSED_MODAL_LANGUAGES_TITLE_ID,
  PARSED_MODAL_OVERLAY_ID,
  PARSED_MODAL_SAVE_ALL_BUTTON_ID,
  PARSED_MODAL_SECTION_EDUCATION,
  PARSED_MODAL_SECTION_EDUCATION_ID,
  PARSED_MODAL_SECTION_EMPLOYMENTS,
  PARSED_MODAL_SECTION_EMPLOYMENTS_ID,
  PARSED_MODAL_SECTION_LANGUAGES,
  PARSED_MODAL_SECTION_LANGUAGES_ID,
  PARSED_MODAL_SECTION_SKILLS,
  PARSED_MODAL_SECTION_SKILLS_ID,
  PARSED_MODAL_SKILLS_TITLE_ID,
} from "./constants";

import { useRemoveOverlayTabIndex } from "../../hooks/useRemoveOverlayTabIndex";

type ParsedModalProps = {
  onClose: () => void;
  onSave: () => void;
  onCancel: () => void;
  showErrors: boolean;
  skills: Skill[];
  setSkills: React.Dispatch<React.SetStateAction<Skill[] | null>>;
  languages: LanguageForm[];
  setLanguages: React.Dispatch<React.SetStateAction<LanguageForm[]>>;
  educationDegrees: EducationDegreeForm[];
  setEducationDegrees: React.Dispatch<
    React.SetStateAction<EducationDegreeForm[]>
  >;
  skillsErrorMessage: string;
  employmentsForm: EmploymentForm[];
  setEmploymentsForm: React.Dispatch<React.SetStateAction<EmploymentForm[]>>;
  monthsRefData: MonthsRefData[];
  proficiencyLanguageRefData: ProficiencyLanguageRefData;
  educationGradStatusRefData: EducationGradStatusRefData;
};

export const ParsedModal = ({
  showErrors,
  onClose,
  onSave,
  onCancel,
  skills,
  setSkills,
  languages,
  setLanguages,
  skillsErrorMessage,
  educationDegrees,
  setEducationDegrees,
  employmentsForm,
  setEmploymentsForm,
  monthsRefData,
  proficiencyLanguageRefData,
  educationGradStatusRefData,
}: ParsedModalProps) => {
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { resumeParsingData } = appUIState;
  const { isExternal } = appUIState.appData;
  const headingRef = useRef<HTMLHeadingElement | null>(null);
  const sectionMapping: Record<
    string,
    {
      discard: () => void;
      titleId: string;
    }
  > = useMemo(
    () => ({
      [PARSED_MODAL_SECTION_EDUCATION]: {
        discard: () => setEducationDegrees([]),
        titleId: PARSED_MODAL_EDUCATION_TITLE_ID,
      },
      [PARSED_MODAL_SECTION_EMPLOYMENTS]: {
        discard: () => setEmploymentsForm([]),
        titleId: PARSED_MODAL_EMPLOYMENTS_TITLE_ID,
      },
      [PARSED_MODAL_SECTION_SKILLS]: {
        discard: () => setSkills(null),
        titleId: PARSED_MODAL_SKILLS_TITLE_ID,
      },
      [PARSED_MODAL_SECTION_LANGUAGES]: {
        discard: () => setLanguages([]),
        titleId: PARSED_MODAL_LANGUAGES_TITLE_ID,
      },
    }),
    [setEducationDegrees, setEmploymentsForm, setSkills, setLanguages],
  );
  const onDiscardSection = useCallback(
    (sectionName: string) => {
      const focusOrder = [
        PARSED_MODAL_EDUCATION_TITLE_ID,
        PARSED_MODAL_EMPLOYMENTS_TITLE_ID,
        PARSED_MODAL_SKILLS_TITLE_ID,
        PARSED_MODAL_LANGUAGES_TITLE_ID,
        PARSED_MODAL_SAVE_ALL_BUTTON_ID,
      ];
      const currentSection = sectionMapping[sectionName];
      if (currentSection) {
        currentSection.discard();
        const currentIndex = focusOrder.indexOf(currentSection.titleId);
        const nextFocusTarget = focusOrder[currentIndex + 1];
        if (nextFocusTarget) {
          focusElementWithFallback(
            nextFocusTarget,
            focusOrder.slice(currentIndex + 2),
          );
        }
      }
    },
    [sectionMapping],
  );
  const displayDiscardButton = useCallback(
    (section: string) => {
      switch (section) {
        case PARSED_MODAL_SECTION_SKILLS: {
          return (
            languages?.length > 0 ||
            educationDegrees?.length > 0 ||
            employmentsForm?.length > 0
          );
        }
        case PARSED_MODAL_SECTION_LANGUAGES: {
          return (
            Boolean(skills) ||
            educationDegrees?.length > 0 ||
            employmentsForm?.length > 0
          );
        }
        case PARSED_MODAL_SECTION_EDUCATION: {
          return (
            Boolean(skills) ||
            languages?.length > 0 ||
            employmentsForm?.length > 0
          );
        }
        case PARSED_MODAL_SECTION_EMPLOYMENTS: {
          return (
            Boolean(skills) ||
            languages?.length > 0 ||
            educationDegrees?.length > 0
          );
        }
        default:
          return false;
      }
    },
    [
      educationDegrees?.length,
      employmentsForm?.length,
      languages?.length,
      skills,
    ],
  );

  const handleFocusOnHeading = () => {
    headingRef?.current?.focus();
  };

  useEffect(() => {
    setTimeout(() => {
      handleFocusOnHeading();
    }, 0);
  }, []);

  useRemoveOverlayTabIndex();

  return (
    <Overlay
      id={PARSED_MODAL_OVERLAY_ID}
      elementIdToFocus="" // TODO:// Focus on close/Cancel should be on Review Now , Focus onSave Action should be the next focusible element , refer to current behavior in UT
      visible={true}
      onClose={onClose}
      wide={true}
      disableClickAway={true}
      onEntered={handleFocusOnHeading}
      footerContent={
        <div className="d-flex justify-center u-border-top mt-40 mb-30 ml-25 mr-25">
          <SaveCancelAction
            onCancel={onCancel}
            onSave={onSave}
            cancelLabelName={t("jobsite.common.discardall") as string}
            saveLabelName={t("jobsite.common.saveall") as string}
            cancelButtonId={PARSED_MODAL_DISCARD_ALL_BUTTON_ID}
            saveButtonId={PARSED_MODAL_SAVE_ALL_BUTTON_ID}
          />
        </div>
      }
      closeButtonAttrs={{
        ariaLabel: t("jobsite.common.close") as string,
      }}
      ariaLabel={"parsed-modal-dialog-heading"}
      appear={false}
      preventBackgroundScroll={true}
    >
      <div id={PARSED_MODAL_CONTAINER_ID}>
        <div className="text-center">
          <h2
            className="t-headline-reduced"
            id="parsed-modal-dialog-heading"
            ref={headingRef}
            tabIndex={-1}
          >
            {t("jobsite.common.linkedpopup")}
          </h2>
          {resumeParsingData?.profileUpdateSelection === "resume" && (
            <p>{t("jobsite.common.linkedpopupText")}</p>
          )}
          {resumeParsingData?.profileUpdateSelection === "linkedin" && (
            <p>{t("jobsite.common.linkedinReviewText")}</p>
          )}
        </div>
        {educationDegrees?.length > 0 && (
          <ParsedSection
            sectionId={PARSED_MODAL_SECTION_EDUCATION_ID}
            sectionName={PARSED_MODAL_SECTION_EDUCATION}
            sectionTitle={t("jobsite.common.educationSummaryTitle") as string}
            onDiscard={onDiscardSection}
            displayDiscardButton={displayDiscardButton(
              PARSED_MODAL_SECTION_EDUCATION,
            )}
          >
            <ParsedEducationDegrees
              degreeOptions={educationGradStatusRefData.education}
              gradStatusOptions={educationGradStatusRefData.gradStatus}
              educationDegreesForm={educationDegrees}
              setEducationDegreesForm={setEducationDegrees}
              displayErrors={showErrors}
            />
          </ParsedSection>
        )}
        {employmentsForm?.length > 0 && (
          <ParsedSection
            sectionId={PARSED_MODAL_SECTION_EMPLOYMENTS_ID}
            sectionName={PARSED_MODAL_SECTION_EMPLOYMENTS}
            sectionTitle={t("jobsite.common.empSummaryTitle") as string}
            onDiscard={onDiscardSection}
            displayDiscardButton={displayDiscardButton(
              PARSED_MODAL_SECTION_EMPLOYMENTS,
            )}
          >
            <ParsedEmployments
              employmentsForm={employmentsForm}
              setEmploymentsForm={setEmploymentsForm}
              monthsRefData={monthsRefData}
              displayErrors={showErrors}
            />
          </ParsedSection>
        )}
        {skills && (
          <ParsedSection
            sectionId={PARSED_MODAL_SECTION_SKILLS_ID}
            sectionName={PARSED_MODAL_SECTION_SKILLS}
            sectionTitle={t("jobsite.common.skillsTitle") as string}
            onDiscard={onDiscardSection}
            displayDiscardButton={displayDiscardButton(
              PARSED_MODAL_SECTION_SKILLS,
            )}
          >
            <ParsedSkills
              elementToFocusOnClose={PARSED_MODAL_DISCARD_ALL_BUTTON_ID}
              skills={skills}
              setSkills={setSkills}
              skillsErrorMessage={skillsErrorMessage}
            />
          </ParsedSection>
        )}
        {languages?.length > 0 && isExternal && (
          <ParsedSection
            sectionId={PARSED_MODAL_SECTION_LANGUAGES_ID}
            sectionName={PARSED_MODAL_SECTION_LANGUAGES}
            sectionTitle={t("jobsite.common.langSpokenTitle") as string}
            onDiscard={onDiscardSection}
            displayDiscardButton={displayDiscardButton(
              PARSED_MODAL_SECTION_LANGUAGES,
            )}
          >
            <ParsedLanguages
              languages={languages}
              setLanguages={setLanguages}
              proficiencyLanguageRefData={proficiencyLanguageRefData}
              showError={showErrors}
            />
          </ParsedSection>
        )}
      </div>
    </Overlay>
  );
};
