import { Talent } from "../../../../../shared/types";
import { getTalent } from "../../../../api/fetchClient";
import { useFetchData } from "../../../../hooks/useFetchData";

export function useFetchTalent(talentId: string) {
  const { data, error, isError, isLoading, isSuccess, fetchData } =
    useFetchData<Talent>(getTalent);

  return {
    talent: data,
    isFetchTalentError: isError,
    isFetchTalentSuccess: isSuccess,
    isFetchTalentLoading: isLoading,
    fetchTalentError: error,
    fetchTalent: () => fetchData([talentId]),
  };
}
