import React, { createContext, ReactNode, useMemo, useReducer } from "react";
import { useRouteLoaderData } from "react-router";
import { DEFAULT_LOCALE, ResumeActionType } from "./app.constants";
import {
  APP_DATA,
  AppAction,
  RESUME_PARSING_DATA,
  SET_SEARCH_URL,
} from "./AppActionTypes";
import { RouteID } from "./routes";
import {
  AppData,
  ProfileUpdateSelection,
  ResumeParsingData,
  TriggerLinkedInResumePolling,
} from "./types";

interface AppUIState {
  appData: AppData;
  resumeParsingData?: ResumeParsingData | null;
  showResumeActionsModal?: boolean;
  triggerLinkedInResumePolling?: TriggerLinkedInResumePolling | null;
  searchUrl: string;
}

const appStateDefaultValues: AppData = {
  appType: "external",
  isSessionAuthenticated: false,
  locale: DEFAULT_LOCALE,
  headerItems: [],
  translations: {},
  isExternal: true,
  isInternal: false,
  appBasePathPrefix: "",
  countryCode: null,
  baseUrl: "",
  searchPageUrl: "",
  disableERPEmailValidation: false,
  disableSanitizeHtml: false,
  jbNc: "",
};

const initialAppUIState: AppUIState = {
  appData: appStateDefaultValues,
  resumeParsingData: null,
  showResumeActionsModal: false,
  searchUrl: "",
};

const reducer = (appUIState: AppUIState, action: AppAction): AppUIState => {
  switch (action.type) {
    case "TRIGGER_LINKEDIN_RESUME_POLLING":
      return {
        ...appUIState,
        triggerLinkedInResumePolling: action.payload,
      };
    case "RESUME_PARSING_REVIEW_ACTIONS":
      return {
        ...appUIState,
        showResumeActionsModal: action.payload.showResumeActionsModal,
      };
    case RESUME_PARSING_DATA:
      return { ...appUIState, resumeParsingData: { ...action.payload } };
    case SET_SEARCH_URL:
      return { ...appUIState, searchUrl: action.payload };
    case APP_DATA:
      return { ...appUIState, appData: action.payload };
    // TODO: Try object.freez on appData to restrict updates in appData
    default:
      return appUIState;
  }
};

// Create a context
export const AppContext = createContext<{
  appUIState: AppUIState;
  dispatch: React.Dispatch<AppAction>;
}>({ appUIState: initialAppUIState, dispatch: () => null });

export const AppContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const appData = useRouteLoaderData(RouteID.root) as AppData;
  const [appUIState, dispatch] = useReducer(reducer, {
    ...initialAppUIState,
    appData: appData,
    resumeParsingData: {
      profileUpdateSelection: appData?.currentUser?.profileUpdateSelection,
      resumeLastAction:
        appData?.currentUser?.profileUpdateSelection === "resume" &&
        !appData?.currentUser?.resumeLastAction
          ? ResumeActionType.PROGRESS
          : appData?.currentUser?.resumeLastAction,
      linkedinLastAction: appData?.currentUser?.linkedinLastAction,
    },
    triggerLinkedInResumePolling: {
      resumeParsingData: {
        profileUpdateSelection: appData?.currentUser
          ?.profileUpdateSelection as ProfileUpdateSelection,
        resumeLastAction:
          appData?.currentUser?.profileUpdateSelection === "resume" &&
          !appData?.currentUser?.resumeLastAction
            ? ResumeActionType.PROGRESS
            : appData?.currentUser?.resumeLastAction,
        linkedinLastAction: appData?.currentUser?.linkedinLastAction,
      },
      startPolling:
        appData?.currentUser?.profileUpdateSelection === "resume" &&
        !appData?.currentUser?.resumeLastAction,
      showResumeActionsModal: false, // Value is false as this will be executed on page load
    },
  });
  const contextValue = useMemo(() => ({ appUIState, dispatch }), [appUIState]);
  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export default AppContext;
