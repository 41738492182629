import { isEmpty } from "lodash";
import { useCallback, useState } from "react";
import { Contact } from "../../../../shared/types/talent/talent";
import { useFormInputValidator } from "../../../components/feature/form/inputValidators/useFormInputValidator";
import {
  getEmailFormConfigFromContact,
  getOtherEmailField,
  getOtherPhoneField,
} from "../information/nameFormUtils";
import { EmailPhoneForm } from "../information/types";
import { ProfileFormField } from "../types";

export function useEmailPhone(
  contact: Contact,
  preferredPhoneRequired: boolean,
  mode: "profile" | "myapplication" = "profile",
) {
  const [emailPhoneForm, setEmailPhoneForm] = useState<EmailPhoneForm>(
    getEmailFormConfigFromContact(
      contact as Contact,
      preferredPhoneRequired,
      mode,
    ),
  );

  const { isValidPhoneNumber, isValidEmail } = useFormInputValidator();

  const resetEmailPhoneForm = useCallback(() => {
    setEmailPhoneForm(
      getEmailFormConfigFromContact(contact as Contact, preferredPhoneRequired),
    );
  }, [contact, preferredPhoneRequired]);

  const onOtherPhoneOrEmailAdd = useCallback(
    (fieldType: "phone" | "email") => {
      const updated: EmailPhoneForm = { ...emailPhoneForm };
      if (
        fieldType === "email" &&
        (emailPhoneForm.otherEmail || []).length < 2
      ) {
        updated.otherEmail = [...(updated.otherEmail || [])];
        updated.otherEmail.push(getOtherEmailField());
        setEmailPhoneForm(updated);
      }

      if (
        fieldType === "phone" &&
        (emailPhoneForm.otherPhone || []).length < 2
      ) {
        updated.otherPhone = [...(updated.otherPhone || [])];
        updated.otherPhone.push(getOtherPhoneField());
        setEmailPhoneForm(updated);
      }
    },
    [emailPhoneForm, setEmailPhoneForm],
  );

  const onOtherPhoneOrEmailDelete = useCallback(
    (fieldType: "phone" | "email", field: ProfileFormField) => {
      const updated: EmailPhoneForm = { ...emailPhoneForm };
      if (fieldType === "email") {
        updated.otherEmail = [...(updated.otherEmail || [])];
        const idxToRemove = updated.otherEmail.findIndex(
          (f) => f.key === field.key,
        );
        updated.otherEmail.splice(idxToRemove, 1);
        setEmailPhoneForm(updated);
      }
      if (fieldType === "phone") {
        updated.otherPhone = [...(updated.otherPhone || [])];
        const idxToRemove = updated.otherPhone.findIndex(
          (f) => f.key === field.key,
        );
        updated.otherPhone.splice(idxToRemove, 1);
        setEmailPhoneForm(updated);
      }
    },
    [emailPhoneForm],
  );

  const validatePhoneField = useCallback(
    (
      field: ProfileFormField,
    ): { field: ProfileFormField; hasError: boolean } => {
      let hasError = false;
      const updated = {
        ...field,
        error: false,
        errori18nMsgKey: "",
      } as ProfileFormField;
      if (isEmpty(field.value) && field.required) {
        updated.error = true;
        updated.errori18nMsgKey = field.requiredi18nMsgKey;
        hasError = true;
      }
      if (!isEmpty(field.value) && !isValidPhoneNumber(field.value as string)) {
        updated.error = true;
        updated.errori18nMsgKey =
          mode === "profile"
            ? "jobsite.common.enterValidPhoneNumber"
            : "jobsite.profile.myApplication.enterValidPhoneNumber";
        hasError = true;
      }
      return {
        field: updated,
        hasError,
      };
    },
    [isValidPhoneNumber, mode],
  );

  const validateEmailField = useCallback(
    (
      field: ProfileFormField,
    ): { field: ProfileFormField; hasError: boolean } => {
      let hasError = false;
      const updated = {
        ...field,
        error: false,
        errori18nMsgKey: "",
      } as ProfileFormField;

      if (isEmpty(field.value) && field.required) {
        updated.error = true;
        updated.errori18nMsgKey = field.requiredi18nMsgKey;
        hasError = true;
      }
      if (!isEmpty(field.value) && !isValidEmail(field.value as string)) {
        updated.error = true;
        updated.errori18nMsgKey =
          mode === "profile"
            ? "jobsite.common.validemail"
            : "jobsite.profile.myApplication.validemail";
        hasError = true;
      }
      return {
        field: updated,
        hasError,
      };
    },
    [isValidEmail, mode],
  );

  const checkForDuplicatesInEmailOrPhoneField = useCallback(
    (
      type: "phone" | "email",
      prefField: ProfileFormField,
      otherField: ProfileFormField[],
    ): boolean => {
      let hasDuplicates = false;
      const reversed = Array(...[prefField, ...(otherField || [])]).reverse();
      reversed.forEach((f, idx) => {
        if (mode === "profile") {
          if (
            idx < reversed.length - 1 &&
            f.value === reversed[idx + 1].value
          ) {
            f.error = true;
            f.errori18nMsgKey =
              type === "phone"
                ? "jobsite.information.duplicatePhoneInForm"
                : "jobsite.information.duplicateEmailInForm";
            f.errori18nMsgParams =
              type === "phone"
                ? { phone: reversed[idx + 1].value as string }
                : { email: reversed[idx + 1].value as string };
            hasDuplicates = true;
          }
        } else {
          const filteredValues = reversed.filter(
            (field) => field.value === f.value,
          );
          if (filteredValues.length > 1) {
            f.error = true;
            f.errori18nMsgKey =
              type === "phone"
                ? "jobsite.profile.myApplication.duplicatePhone"
                : "jobsite.profile.myApplication.duplicateEmail";
            f.errori18nMsgParams =
              type === "phone"
                ? { phone: f.value as string }
                : { email: f.value as string };
            hasDuplicates = true;
          }
        }
      });
      return hasDuplicates;
    },
    [mode],
  );

  const validateEmailPhone = useCallback(
    (form?: EmailPhoneForm): boolean => {
      const updated = form ? form : { ...emailPhoneForm };
      let phoneFieldHasError = false;
      const { field: updatedPreferredPhone, hasError: prefPhoneHasError } =
        validatePhoneField(updated.preferredPhone as ProfileFormField);
      updated.preferredPhone = updatedPreferredPhone;
      phoneFieldHasError = prefPhoneHasError;
      (updated.otherPhone || []).forEach((field, idx) => {
        const { field: updatedField, hasError } = validatePhoneField(field);
        (updated.otherPhone as ProfileFormField[])[idx] = updatedField;
        phoneFieldHasError = !phoneFieldHasError
          ? hasError
          : phoneFieldHasError;
      });
      if (!phoneFieldHasError) {
        const hasDuplicates = checkForDuplicatesInEmailOrPhoneField(
          "phone",
          updated.preferredPhone,
          updated.otherPhone || [],
        );
        phoneFieldHasError = !phoneFieldHasError
          ? hasDuplicates
          : phoneFieldHasError;
      }

      let emailFieldHasError = false;
      const { field: updatedPreferredEmail, hasError: prefEmailHasError } =
        validateEmailField(updated.preferredEmail as ProfileFormField);
      updated.preferredEmail = updatedPreferredEmail;
      emailFieldHasError = prefEmailHasError;

      (updated.otherEmail || []).forEach((field, idx) => {
        const { field: updatedField, hasError } = validateEmailField(field);
        (updated.otherEmail as ProfileFormField[])[idx] = updatedField;
        emailFieldHasError = !emailFieldHasError
          ? hasError
          : emailFieldHasError;
      });

      if (!emailFieldHasError) {
        const hasDuplicates = checkForDuplicatesInEmailOrPhoneField(
          "email",
          updated.preferredEmail,
          updated.otherEmail || [],
        );
        emailFieldHasError = !emailFieldHasError
          ? hasDuplicates
          : emailFieldHasError;
      }
      setEmailPhoneForm(updated);
      return phoneFieldHasError || emailFieldHasError;
    },
    [
      emailPhoneForm,
      validatePhoneField,
      validateEmailField,
      checkForDuplicatesInEmailOrPhoneField,
    ],
  );

  const onPreferredEmailUpdate = useCallback(
    (field: ProfileFormField, email: string) => {
      const updated: EmailPhoneForm = { ...emailPhoneForm };
      updated.preferredEmail = { ...field, value: email };
      setEmailPhoneForm(updated);
      validateEmailPhone(updated);
    },
    [emailPhoneForm, validateEmailPhone],
  );

  const onOtherPhoneOrEmailUpdate = useCallback(
    (fieldType: "phone" | "email", field: ProfileFormField, val: string) => {
      const updated: EmailPhoneForm = { ...emailPhoneForm };
      if (fieldType === "email") {
        updated.otherEmail = [...(updated.otherEmail || [])];
        const findIndex = updated.otherEmail?.findIndex(
          (f) => f.key === field.key,
        );
        const updatedEmail = {
          ...updated.otherEmail[findIndex as number],
          value: val,
        };
        updated.otherEmail?.splice(findIndex as number, 1, updatedEmail);
        setEmailPhoneForm(updated);
        validateEmailPhone(updated);
      }
      if (fieldType === "phone") {
        updated.otherPhone = [...(updated.otherPhone || [])];
        const findIndex = updated.otherPhone?.findIndex(
          (f) => f.key === field.key,
        );
        const updatedPhone = {
          ...updated.otherPhone[findIndex as number],
          value: val,
        };
        updated.otherPhone?.splice(findIndex as number, 1, updatedPhone);
        setEmailPhoneForm(updated);
        validateEmailPhone(updated);
      }
    },
    [emailPhoneForm, validateEmailPhone],
  );

  const onPreferredPhoneUpdate = useCallback(
    (field: ProfileFormField, val: string) => {
      const updated: EmailPhoneForm = { ...emailPhoneForm };
      updated.preferredPhone = { ...field, value: val };
      setEmailPhoneForm(updated);
      validateEmailPhone(updated);
    },
    [emailPhoneForm, validateEmailPhone],
  );

  return {
    emailPhoneForm,
    onOtherPhoneOrEmailAdd,
    onOtherPhoneOrEmailDelete,
    onOtherPhoneOrEmailUpdate,
    onPreferredEmailUpdate,
    onPreferredPhoneUpdate,
    validatePhoneField,
    validateEmailField,
    checkForDuplicatesInEmailOrPhoneField,
    validateEmailPhone,
    resetEmailPhoneForm,
  };
}
