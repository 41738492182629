import React from "react";
import useIntlMessage from "../../hooks/useIntlMessage";

export function ContractorStep() {
  const { t } = useIntlMessage();
  return (
    <div className="text-center mt-20">
      <h1 className="t-eyebrow-reduced">
        {t("jobsite.erp.introduceFriend.contractormessage")}
      </h1>
      <p>{t("jobsite.erp.introduceFriend.contractormessage.subTitle")}</p>
    </div>
  );
}
