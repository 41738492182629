import { InfoTooltip, TextButton, Toggle } from "@rpe-js/marcom-web-components";
import React from "react";
import { GenderPronoun } from "../../../shared/types/talent/talent";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { getDisplayPronoun } from "../../pages/profile/information/nameFormUtils";
import { Label } from "../base/Label";
import { NameView } from "./NameView";

export function PronounsView({
  displayPronouns,
  genderPronoun,
  onTogglePronouns, // Added handler for the toggle
}: {
  displayPronouns: boolean;
  genderPronoun?: GenderPronoun;
  onTogglePronouns: (event: React.ChangeEvent<HTMLInputElement>) => void; // Added prop for handling the toggle change
}) {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const pronounsInformationId = "pronouns-information";
  return (
    <>
      <div className="row">
        <div className="d-flex-equal">
          <div className="d-flex">
            <Label label={t("jobsite.common.displayPronouns") as string} />
            <InfoTooltip
              configuration={{
                overrideDefaultBehaviour: true,
                showOnHover: true,
                icon: (
                  <>
                    <TextButton
                      id="pronouns-information-button"
                      icon="icon-questionsolid"
                      onClick={() => {}}
                      classes="link"
                      aria-label={t("jobsite.common.pronouns") as string}
                      aria-describedby={pronounsInformationId}
                    />
                  </>
                ),
              }}
              iconLabel={
                t("jobsite.common.pronounsInformationIconVO") as string
              }
              positionClass="form-tooltip-after d-flex align-center"
              tooltipPointer={isMobile ? "middle" : "left"}
              setAsHtml={true}
              isClickable={true}
              infoTooltipBody={
                t("jobsite.information.pronounsInformation") as string
              }
            />
          </div>
          <div className="d-flex mt-8">
            <Toggle
              name={t("jobsite.common.displayPronouns") as string}
              ariaLabel={t("jobsite.common.displayPronouns") as string}
              label={
                t(
                  `${displayPronouns ? "jobsite.common.yes" : "jobsite.common.no"}`,
                ) as string
              }
              checked={displayPronouns}
              onChange={onTogglePronouns}
            />
          </div>
        </div>

        {/* Display pronouns if enabled */}
        {displayPronouns && (
          <div className="d-flex flex-column">
            <NameView
              labelKey="pronouns"
              value={getDisplayPronoun(genderPronoun)}
            />
          </div>
        )}
      </div>
    </>
  );
}
