import React from "react";
import HtmlRenderer from "../../../components/base/HtmlRenderer";
import { useLocaleAwareLink } from "../../../components/feature/LocaleAwareLink";
import translateReplace from "../../../hooks/translateReplace";
import useIntlMessage from "../../../hooks/useIntlMessage";

export function AcknowledgementStep() {
  const { t } = useIntlMessage();
  const getLink = useLocaleAwareLink();
  return (
    <div className="w-100 text-center pt-40 pb-40 u-border-bottom">
      <h2>{t("jobsite.profile.myApplication.thankYou")}</h2>
      <p className="t-intro">
        {t("jobsite.profile.myApplication.successSubmit")}
      </p>
      <HtmlRenderer
        classes="hyperlink-underline"
        initialContent={translateReplace(
          t("jobsite.profile.myApplication.yourInformationTrack") as string,
          ["{yourRolesUrl}"],
          [getLink(true, "profile/roles")],
        )}
        htmlTag="p"
      />
      <HtmlRenderer
        initialContent={translateReplace(
          t(
            "jobsite.profile.myApplication.yourInformationWithinInfo",
          ) as string,
          ["{informationUrl}"],
          [getLink(true, "profile/info")],
        )}
        htmlTag="p"
      />
    </div>
  );
}
