import { TextButton } from "@rpe-js/marcom-web-components";
import { cloneDeep } from "lodash";
import React, { useCallback, useEffect } from "react";
import { updateTalent } from "../../../api/fetchClient";
import { ALERT_WRAPPER_ID, PROFILE_SECTIONS } from "../../../app.constants";
import { AlertWrapper } from "../../../components/base/AlertWrapper";
import HtmlRenderer from "../../../components/base/HtmlRenderer";
import { useFetchData } from "../../../hooks/useFetchData";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { focusElement, handleScrollToElement } from "../../../utils/focusUtil";
import { updateRecruiterLastModification } from "../context/Actions";
import { useProfileContext } from "../context/ProfileContext";

const subDocsMap = {
  contact: ["contact", "addresses"],
  educationDegrees: ["educationDegrees"],
  resume: ["resume"],
  links: ["links", "files"],
  employments: ["employments"],
  preferredLocations: ["preferredLocations"],
  mobilityPreferences: ["mobilityPreferences"],
  selfDisclosure: ["selfDisclosure", "disability"],
  recruiterManagedSkills: ["recruiterManagedSkills"],
  languages: ["languages"],
  teamsOfInterest: ["teamsOfInterest"],
  employmentTypes: ["employmentTypes"],
};

export const ProfileSection = (props: {
  i18nTitle: string;
  isEdit: boolean;
  onEdit: () => void;
  children: React.ReactNode;
  classNames?: string;
  id: string;
  sectionName:
    | "contact"
    | "educationDegrees"
    | "resume"
    | "links"
    | "employments"
    | "preferredLocations"
    | "mobilityPreferences"
    | "selfDisclosure"
    | "recruiterManagedSkills"
    | "languages"
    | "teamsOfInterest"
    | "employmentTypes";
}) => {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const { state, dispatch } = useProfileContext();
  const {
    isLoading,
    isSuccess,
    fetchData: updateRecLastModification,
    resetFetchStatus,
  } = useFetchData(updateTalent);
  const PROFILE_SECTION_TITLE_ID = `${props.id}-section-title`;

  const displayInfoUpdateAlert = useCallback(() => {
    const subDocs = state.recruiterLastModification?.subDocs;
    if (subDocs?.includes(props.sectionName as string)) {
      return true;
    } else {
      let result = false;
      subDocsMap[props.sectionName]?.forEach((section: string) => {
        if (subDocs?.includes(section)) {
          result = true;
        }
      });
      return result;
    }
  }, [props.sectionName, state.recruiterLastModification?.subDocs]);

  const getPayload = useCallback(() => {
    const payload = cloneDeep(state.recruiterLastModification);
    if (payload?.subDocs) {
      payload.subDocs = state.recruiterLastModification?.subDocs?.filter(
        (subDoc: string) => !subDocsMap[props.sectionName].includes(subDoc),
      );
    }
    return payload;
  }, [props.sectionName, state.recruiterLastModification]);

  const onInfoAlertRemove = useCallback(() => {
    updateRecLastModification([
      state?.talentId,
      PROFILE_SECTIONS.RECRUITER_LAST_MODIFICATION,
      { recruiterLastModification: getPayload() },
    ]);
  }, [getPayload, state?.talentId, updateRecLastModification]);

  const onEditClick = useCallback(() => {
    props.onEdit();
    handleScrollToElement(props.id);
  }, [props]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(
        updateRecruiterLastModification(getPayload()?.subDocs as string[]),
      );
      resetFetchStatus();
      focusElement(PROFILE_SECTION_TITLE_ID);
    }
  }, [
    PROFILE_SECTION_TITLE_ID,
    dispatch,
    getPayload,
    isLoading,
    isSuccess,
    resetFetchStatus,
  ]);

  return (
    <section
      className={`pos-rel mt-20 pb-30 scroll-margin-header ${props.classNames ? props.classNames : ""} ${!isMobile ? " u-border-bottom" : ""}`}
      id={props.id}
    >
      {displayInfoUpdateAlert() && !isMobile && (
        <AlertWrapper
          alignCenter={false}
          message={t("jobsite.common.infoUpdateByApple") as string}
          closePosition="right"
          classes="yellow-alert mb-30"
          remove={{
            closeBtnAriaLabel: t("jobsite.common.closeAlert") as string,
            onRemove: onInfoAlertRemove,
          }}
          id={ALERT_WRAPPER_ID.generateId("info-update")}
        />
      )}
      <div className="row">
        <div
          className={`column large-3 medium-12 small-12 ${isMobile ? "d-flex justify-content-spacebetween mb-20 pb-10 u-border-bottom" : "mb-30 pr-45"}`}
        >
          <h2 className="w-90">
            <HtmlRenderer
              classes="t-eyebrow-reduced fw-medium"
              initialContent={t(props.i18nTitle) as string}
              htmlTag="span"
              id={PROFILE_SECTION_TITLE_ID}
            />
          </h2>
          {!props.isEdit && (
            <TextButton
              id={`${props.id}-editButton`}
              label={t("jobsite.common.edit") as string}
              onClick={onEditClick}
              classes="t-body-reduced"
              aria-describedby={PROFILE_SECTION_TITLE_ID}
            />
          )}
        </div>
        {displayInfoUpdateAlert() && isMobile && (
          <AlertWrapper
            alignCenter={false}
            message={t("jobsite.common.infoUpdateByApple") as string}
            closePosition="right"
            classes="yellow-alert mb-30 w-100"
            remove={{
              closeBtnAriaLabel: t("jobsite.common.closeAlert") as string,
              onRemove: onInfoAlertRemove,
            }}
            id={ALERT_WRAPPER_ID.generateId("info-update")}
          />
        )}
        <div className="column large-9 medium-12 small-12">
          {props.children}
        </div>
      </div>
    </section>
  );
};
