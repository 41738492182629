import { Button } from "@rpe-js/marcom-web-components";
import * as React from "react";
import { ReactNode } from "react";

type PillButtonProps = {
  id: string;
  label: string;
  labelNode?: ReactNode | null;
  showBorder?: boolean;
  onRemove: () => void;
  buttonSize?: "small" | "medium" | "base";
  className?: string;
};

const PillButton: React.FC<PillButtonProps> = ({
  id,
  label,
  labelNode = null,
  showBorder = false,
  onRemove,
  buttonSize,
  className = "",
}) => {
  return (
    <div
      className={`pill-button ${showBorder ? "grey-border" : ""} ${className}`}
    >
      <Button
        id={id}
        size={buttonSize}
        color={undefined}
        onClick={onRemove}
        aria-label={`Remove ${label}`}
        blockedVariant={false}
      >
        <span className="pill-text">{labelNode ? labelNode : label}</span>
        <i className="icon icon-resetsolid pointer" />
      </Button>
    </div>
  );
};

export default PillButton;
