import { useState } from "react";

// TODO
/**
 *
 * @param fnArray @deprecated Do not Use this
 * @returns
 */
export function useFetchMultipleData(fnArray: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState<any>();

  const fetchData = async (args?: Array<Array<any>>) => {
    try {
      setIsLoading(true);
      setIsSuccess(false);
      setIsError(false);
      setError(null);
      const promiseArray: any = [];
      fnArray.map((fn: any, index: number) =>
        promiseArray.push(
          args?.length && args[index] ? fn(...args[index]) : fn(),
        ),
      );
      const result = await Promise.all([...promiseArray]);
      setData(result);
      setIsLoading(false);
      setIsSuccess(true);
    } catch (e: any) {
      setIsLoading(false);
      setIsError(true);
      setError(e);
    }
  };

  return { isLoading, data, fetchData, isSuccess, error, isError };
}
