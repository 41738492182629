import {
  NativeButton,
  ProgressIndicatorLoader,
} from "@rpe-js/marcom-web-components";
import React, { useContext, useMemo, useState } from "react";
import { parsedResumeSnapshotFail } from "../../../api/fetchClient";
import { ALERT_WRAPPER_ID, ResumeActionType } from "../../../app.constants";
import AppContext from "../../../AppContext";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { idGenerator } from "../../../utils/idGenerator";
import { AlertWrapper } from "../../base/AlertWrapper";
import { actions, ResumeActionInfo } from "./types/ResumeParsing";

const lastActions = ["REVIEW_LATER", "FAILED", "PROGRESS"];

export default function ParsingBanner(props: {
  setOpenParsedModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const parsingBannerIdGenerator = idGenerator("global", "parsingbanner");
  const { appUIState, dispatch: dispatchAppContext } = useContext(AppContext);
  const { talentId } = appUIState.appData;
  const { resumeParsingData } = appUIState;
  const { profileUpdateSelection = "manual" } = resumeParsingData || {};
  const { t } = useIntlMessage();
  const [loading, setLoading] = useState<boolean>(false);

  const lastAction = useMemo((): ResumeActionInfo | null => {
    if (
      profileUpdateSelection === "linkedin" &&
      (resumeParsingData?.linkedinLastAction as string)
    ) {
      return actions[resumeParsingData?.linkedinLastAction as string];
    }

    return profileUpdateSelection === "resume"
      ? !resumeParsingData?.resumeLastAction
        ? actions.PROGRESS
        : actions[resumeParsingData?.resumeLastAction as string]
      : null;
  }, [profileUpdateSelection, resumeParsingData]);

  const hideBanner = useMemo(() => {
    return (
      !talentId ||
      resumeParsingData === null ||
      !resumeParsingData?.profileUpdateSelection ||
      !lastActions ||
      (lastActions.indexOf(resumeParsingData?.resumeLastAction as string) ===
        -1 &&
        lastActions.indexOf(resumeParsingData?.linkedinLastAction as string) ===
          -1)
    );
  }, [talentId, resumeParsingData]);

  async function onReviewNow() {
    props.setOpenParsedModal(true);
  }

  const closeButton =
    resumeParsingData?.resumeLastAction === ResumeActionType.FAILED ||
    resumeParsingData?.linkedinLastAction === ResumeActionType.FAILED ? (
      <span className="mt-0 mr-5 error-dismiss">
        {/* TODO: use ID generator here */}
        <NativeButton
          id={parsingBannerIdGenerator.generateId("alertClose")}
          type="button"
          onClick={handleClose}
        >
          <span className="a11y">{t("jobsite.common.closeAlert")}</span>
          <span
            className="icon icon-after icon-close align-right"
            aria-hidden="true"
          />
        </NativeButton>
      </span>
    ) : null;

  const reviewLink =
    (resumeParsingData?.profileUpdateSelection === "resume" &&
      resumeParsingData?.resumeLastAction === ResumeActionType.REVIEW_LATER) ||
    (resumeParsingData?.profileUpdateSelection === "linkedin" &&
      resumeParsingData?.linkedinLastAction ===
        ResumeActionType.REVIEW_LATER) ? (
      <span>
        &nbsp;
        <NativeButton
          id={parsingBannerIdGenerator.generateId("review-now")}
          className="review-now"
          onClick={onReviewNow}
          label={t("jobsite.common.clickhere") as string}
        >
          <span className="a11y">{t("jobsite.apply.yourResume")}</span>
        </NativeButton>
      </span>
    ) : null;

  async function handleClose() {
    setLoading(true);
    await parsedResumeSnapshotFail(talentId as string);
    dispatchAppContext({
      type: "RESUME_PARSING_DATA",
      payload: {
        resumeLastAction: ResumeActionType.EXPIRED,
      },
    });
    setLoading(false);
  }

  return (
    <>
      {!hideBanner &&
        resumeParsingData?.profileUpdateSelection &&
        resumeParsingData?.profileUpdateSelection !== "manual" &&
        lastAction && (
          <>
            {loading && <ProgressIndicatorLoader showLoading={true} />}
            <AlertWrapper //TODO: Add role, aria-labelledby
              classes={`alert-wrapper mt-25 mb-0 ${lastAction.class}`}
              id={ALERT_WRAPPER_ID.generateId("parsing-banner")}
            >
              <>
                <span className="d-flex">
                  <span className={lastAction?.icon} />
                  <p
                    id={parsingBannerIdGenerator.generateId("alert-message")}
                    className="ml-20 mr-15 pl-10 text-align-start"
                  >
                    {t(
                      (lastAction as any)[profileUpdateSelection], // extract display i18n key here
                    )}
                    {reviewLink}
                  </p>
                  {closeButton}
                </span>
              </>
            </AlertWrapper>
          </>
        )}
    </>
  );
}
