import { TextButton } from "@rpe-js/marcom-web-components";
import React from "react";

interface ReviewHeaderSectionProps {
  headerText: string;
  buttonText: string;
  showEdit?: boolean;
  onAction?: () => void;
  id: string;
}
export function ReviewHeaderSection({
  headerText,
  buttonText,
  showEdit = true,
  onAction,
  id,
}: ReviewHeaderSectionProps) {
  return (
    <section className="d-flex u-border-bottom">
      <h2
        className="flex-column t-eyebrow-reduced large-10"
        id={`review-header-${id}`}
      >
        {headerText}
      </h2>
      {showEdit && (
        <section className="flex-column large-2 align-end">
          <TextButton
            role="link"
            label={buttonText}
            classes="t-body-reduced"
            onClick={() => {
              if (onAction) onAction();
            }}
            aria-label={`${buttonText} ${headerText}`}
            id={`${buttonText}-${id}-button`}
          />
        </section>
      )}
    </section>
  );
}
