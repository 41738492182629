import {
  Button,
  ProgressIndicatorLoader,
  Radio,
  Textbox,
} from "@rpe-js/marcom-web-components";
import { includes, isEmpty } from "lodash";
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { I18nValues } from "../../../../../shared/types";
import {
  Email,
  EmailCodeGenerationRequest,
  EmailCodeGenerationResponse,
} from "../../../../../shared/types/emailVerification";
import { TalentEmailDetails } from "../../../../../shared/types/talent/talentEmailDetails";
import AppContext from "../../../../AppContext";
import {
  generateEmailVerificationCode,
  getTalentEmailDetails,
} from "../../../../api/fetchClient";
import { NEW_EMAIL_ADDRESS } from "../../../../app.constants";
import ErrorMessage from "../../../../components/base/ErrorMessage";
import TextIcon from "../../../../components/base/TextIcon";
import { useFetchData } from "../../../../hooks/useFetchData";
import { idGenerator } from "../../../../utils/idGenerator";
import { validateEmailOnSubmit } from "../../../../utils/validatorUtil";
import { useFormInputValidator } from "../../form/inputValidators/useFormInputValidator";
import {
  EMAIL_CODE_GENERATION_CANCEL_BUTTON_ID,
  EMAIL_CODE_GENERATION_CONTINUE_BUTTON_ID,
  EMAIL_CODE_GENERATION_MODAL_TITLE_ID,
} from "../constants";

type EmailCodeGenerationContentProps = {
  t: (id: string, values?: I18nValues) => React.ReactNode;
  onCancel: () => void;
  onContinue: (emailResponseData: Email) => void;
  canceli18nLabel?: string;
};

export const EmailCodeGenerationContent = ({
  t,
  onCancel,
  onContinue,
  canceli18nLabel = "jobsite.common.cancel",
}: EmailCodeGenerationContentProps) => {
  const [selectedEmail, setSelectedEmail] = useState(""); //State variable to know the selected email from the list of emails(Verified, Unverified, New Email)
  const [newEmailAddress, setNewEmailAddress] = useState(""); //State variable which will have the new email entered
  const [errorMessage, setErrorMessage] = useState(""); //State variable which stores client side validation errors
  const [serviceErrorMessage, setServiceErrorMessage] = useState(""); //State variable which stores service side validation errors
  const [maxLimit, setMaxLimit] = useState(false); //State variable which is used to disable the continue button when the max limit of attempts to send verification code is reached
  const [submitted, setSubmitted] = useState(false); //State variable to track if user clicked continue button
  const [emailList, setEmailList] = useState({} as TalentEmailDetails); //State variable which stores the getTalentEmailDetails api response
  const { appUIState } = useContext(AppContext);
  const { locale } = appUIState.appData;
  const [allEmails, setAllEmails] = useState<string[]>([]); //State variable which stores the emails(Verified, Unverified) from the emailList state variable required for duplicate check
  const {
    data: talentEmailList,
    isLoading: isTalentEmailDetailsLoading,
    isSuccess: isTalentEmailDetailsSuccess,
    fetchData: fetchTalentEmailDetails,
  } = useFetchData(getTalentEmailDetails);
  const {
    data: generateEmailCodeResponse,
    isLoading: generateEmailCodeLoading,
    isSuccess: generateEmailCodeSuccess,
    isError: generateEmailCodeError,
    fetchData: generateEmailCode,
  } = useFetchData(generateEmailVerificationCode);
  const {
    isValidEmailAddressEntered: validateEmail,
    isValidEmailAddressPasted: onPasteValidateEmail,
  } = useFormInputValidator();
  const emailTextboxRef = useRef<HTMLInputElement>(null);

  const populateEmailList = useCallback(
    (talentEmailList: TalentEmailDetails) => {
      if (talentEmailList) {
        setEmailList(talentEmailList);
        if (talentEmailList.verifiedEmails)
          setAllEmails((prev) => [
            ...prev,
            ...(talentEmailList.verifiedEmails as string[]),
          ]);
        if (talentEmailList.unVerifiedEmails)
          setAllEmails((prev) => [
            ...prev,
            ...(talentEmailList.unVerifiedEmails as string[]),
          ]);
      }
    },
    [setEmailList, setAllEmails],
  );

  const getPayload = useCallback(() => {
    const emailObj: EmailCodeGenerationRequest = {
      emailId:
        selectedEmail === NEW_EMAIL_ADDRESS ? newEmailAddress : selectedEmail,
      locale,
    };
    return emailObj;
  }, [selectedEmail, newEmailAddress, locale]);

  const onGenerateEmailCodeSuccess = useCallback(
    (response: EmailCodeGenerationResponse) => {
      if (
        typeof response?.canGenerateNewCode !== undefined &&
        !response?.canGenerateNewCode &&
        response?.code === 1009
      ) {
        setMaxLimit(true);
        setServiceErrorMessage(t("jobsite.common.reachedMaxLimit") as string);
        return;
      }
      if (response?.hasError && response?.status?.toLowerCase() === "failure") {
        setServiceErrorMessage(
          response && response?.translationKey
            ? (t(response?.translationKey) as string)
            : (t("jobsite.common.serverError") as string),
        );
        return;
      }
      onContinue({ request: getPayload(), response: response });
    },
    [setMaxLimit, t, setServiceErrorMessage, getPayload, onContinue],
  );

  useEffect(() => {
    if (
      !talentEmailList &&
      !isTalentEmailDetailsSuccess &&
      !isTalentEmailDetailsLoading
    ) {
      fetchTalentEmailDetails();
    }
    if (
      !isTalentEmailDetailsLoading &&
      isTalentEmailDetailsSuccess &&
      talentEmailList &&
      !allEmails.length
    ) {
      populateEmailList(talentEmailList);
    }
  }, [
    talentEmailList,
    isTalentEmailDetailsLoading,
    isTalentEmailDetailsSuccess,
    fetchTalentEmailDetails,
    populateEmailList,
    allEmails,
  ]);

  useEffect(() => {
    if (
      !generateEmailCodeLoading &&
      generateEmailCodeSuccess &&
      generateEmailCodeResponse
    ) {
      onGenerateEmailCodeSuccess(generateEmailCodeResponse);
    }
    if (
      !generateEmailCodeLoading &&
      generateEmailCodeError &&
      !generateEmailCodeSuccess
    ) {
      setServiceErrorMessage(t("jobsite.common.serverError") as string);
    }
  }, [
    generateEmailCodeLoading,
    generateEmailCodeSuccess,
    generateEmailCodeResponse,
    generateEmailCodeError,
    onGenerateEmailCodeSuccess,
    t,
  ]);

  const radioSelectionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedEmail(event.target.value);
    setServiceErrorMessage("");
    if (event.target.value !== NEW_EMAIL_ADDRESS) {
      setNewEmailAddress("");
      setErrorMessage("");
    } else {
      setErrorMessage(t("jobsite.common.erroremail") as string);
    }
  };

  const handleEmailInputChange = (value: string) => {
    setNewEmailAddress(value);
    value
      ? setErrorMessage("")
      : setErrorMessage(t("jobsite.common.erroremail") as string);
    if (includes(allEmails, value)) {
      setErrorMessage(
        t("jobsite.information.duplicateEmailInForm", {
          email: value,
        }) as string,
      );
    }
  };

  const triggerEmail = async () => {
    setSubmitted(true);
    const emailObj = getPayload();
    if (!emailObj.emailId) {
      setErrorMessage(
        selectedEmail === NEW_EMAIL_ADDRESS
          ? (t("jobsite.common.erroremail") as string)
          : (t("jobsite.common.selectEmail") as string),
      );
      if (selectedEmail === NEW_EMAIL_ADDRESS) emailTextboxRef.current?.focus();
      return;
    }
    const { message, valid } = validateEmailOnSubmit(emailObj.emailId);
    if (!valid) {
      setErrorMessage(t(message) as string);
      emailTextboxRef.current?.focus();
      return;
    }
    if (errorMessage) {
      if (selectedEmail === NEW_EMAIL_ADDRESS) emailTextboxRef.current?.focus();
      return;
    }
    generateEmailCode([emailObj]);
  };

  function handleDisable(): boolean {
    if (maxLimit) {
      return !emailList?.verifiedEmails?.includes(selectedEmail);
    }
    return false;
  }

  return (
    <section className="pos-rel">
      <div>
        <h2
          className="text-left t-eyebrow-reduced"
          id={EMAIL_CODE_GENERATION_MODAL_TITLE_ID}
        >
          {t("jobsite.common.emailVerification") as string}
        </h2>
        <p className="text-left">
          {t("jobsite.common.applePreferredEmailRequiredDesc")}
        </p>
      </div>
      <div
        className="mt-20 pr-20"
        role="radiogroup"
        aria-label={t("jobsite.common.emailVerification") as string}
      >
        {(isTalentEmailDetailsLoading || generateEmailCodeLoading) && (
          <ProgressIndicatorLoader showLoading={true} />
        )}
        {emailList?.verifiedEmails?.map((verifiedEmail, index) => (
          <div className="row" key={`verifiedEmail${index}`}>
            <div className="column large-8 small-8 d-flex text-left word-break-break-word">
              <Radio
                id={`verifiedEmail${index}`}
                required={true}
                name="selectEmail"
                label={verifiedEmail}
                value={verifiedEmail}
                checked={selectedEmail === verifiedEmail}
                onValueChange={radioSelectionChange}
              />
            </div>
            <div
              className="column large-4 small-4 d-flex"
              key={`verifiedEmailIcon${index}`}
            >
              <TextIcon
                name="icon-checksolid"
                label={t("jobsite.common.verified") as string}
                textColor="green"
              />
            </div>
          </div>
        ))}
        {emailList?.unVerifiedEmails?.map((unVerifiedEmail, index) => (
          <div className="row" key={`unVerifiedEmail${index}`}>
            <div className="column large-12 small-12 d-flex">
              <Radio
                required={true}
                id={`unVerifiedEmail${index}`}
                name="selectEmail"
                label={unVerifiedEmail}
                value={unVerifiedEmail}
                checked={selectedEmail === unVerifiedEmail}
                onValueChange={radioSelectionChange}
              />
            </div>
          </div>
        ))}
        <div className="row">
          <div className="column large-12 small-12 d-flex">
            <Radio
              required={true}
              name="selectEmail"
              value={NEW_EMAIL_ADDRESS}
              id="new-email"
              onValueChange={radioSelectionChange}
              checked={selectedEmail === NEW_EMAIL_ADDRESS}
              label={t("jobsite.common.newEmailAddress") as string}
            />
          </div>
          {selectedEmail === NEW_EMAIL_ADDRESS && (
            <div className="column large-6 small-12 ml-25">
              <Textbox
                id={idGenerator("email", "new").generateId("text")}
                required={selectedEmail === NEW_EMAIL_ADDRESS}
                label={t("jobsite.common.enterEmailAddress") as string}
                value={newEmailAddress}
                maxLength={50}
                onKeyDown={validateEmail}
                onPaste={onPasteValidateEmail}
                onValueChange={handleEmailInputChange}
                error={(submitted || !isEmpty(newEmailAddress)) && errorMessage}
                errorA11y={t("jobsite.common.errorIconLabel") as string}
                ref={emailTextboxRef}
              />
            </div>
          )}
        </div>
        <div className="text-left pl-20">
          {(submitted || !isEmpty(newEmailAddress)) &&
            selectedEmail !== NEW_EMAIL_ADDRESS &&
            errorMessage && (
              <ErrorMessage
                message={errorMessage}
                errorA11y={t("jobsite.common.errorIconLabel") as string}
              />
            )}
          {serviceErrorMessage && (
            <ErrorMessage
              message={serviceErrorMessage}
              errorA11y={t("jobsite.common.errorIconLabel") as string}
            />
          )}
        </div>
      </div>
      <div className="mt-30 d-flex justify-content-end w-100">
        <div className="d-inline-block">
          <Button
            id={EMAIL_CODE_GENERATION_CANCEL_BUTTON_ID}
            size="base"
            label={t(canceli18nLabel) as string}
            color="secondary"
            blockedVariant={true}
            onClick={onCancel}
          ></Button>
        </div>
        <div className="ml-20 d-inline-block">
          <Button
            id={EMAIL_CODE_GENERATION_CONTINUE_BUTTON_ID}
            size="base"
            label={t("jobsite.common.continue") as string}
            color="primary"
            blockedVariant={true}
            onClick={triggerEmail}
            disabled={handleDisable()}
          ></Button>
        </div>
      </div>
    </section>
  );
};
