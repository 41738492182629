import { Location } from "react-router";
import APP_CONSTANTS from "../../utilities/appConstants";

interface JobDetailTranslations {
  [key: string]: string;
}

export function getTranslatedContentHeading(
  jobDetailTranslations: JobDetailTranslations,
  translationKey: string,
  t: any,
): string {
  if (jobDetailTranslations && jobDetailTranslations[translationKey]) {
    return jobDetailTranslations[translationKey];
  }
  return t(translationKey) as string;
}

export function getLocationPickerOrApplyUrl(
  locale: string,
  jobId: string,
  transformedPostingTitle: string,
  managedPipelineRole: boolean,
): string {
  if (managedPipelineRole) {
    return `/${locale}/details/${jobId}/${transformedPostingTitle}/${APP_CONSTANTS.LOCATION_PICKER_RELATIVE_PATH_NAME}`;
  }
  return `/app/${locale}/apply/${jobId}`;
}

function extractSearchPath(path: string) {
  const match = path.match(/\/search(.*)/);
  return match ? `search${match[1]}` : ""; // Append the rest after "search"
}

// Function to determine the back-to-search URL based on the provided location
export function getBackToSearchUrl(
  location: Location | undefined,
  searchPageUrl: string,
  fallbackUrl: string,
): string {
  const searchUrl = extractSearchPath(searchPageUrl);
  // Check if the location state is available
  if (!location?.state) {
    // If state is not available, return the default search page URL
    return searchUrl ? searchUrl : fallbackUrl;
  }

  // Extract the search query from the location state
  const searchQuery = location.state.search;

  // Construct the back-to-search URL by combining "search" and the search query
  return `search${searchQuery}`;
}
