import { ProgressIndicatorLoader } from "@rpe-js/marcom-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { getStoreBenefits } from "../../api/fetchClient";
import HtmlRenderer from "../../components/base/HtmlRenderer";
import { idGenerator } from "../../utils/idGenerator";

type Props = {
  locationId: string | undefined;
  locale: string | undefined;
  jobId: string | undefined;
  storeName: string | undefined;
  city: string | undefined;
};

function LocationPickerStoreBenefitsModalContent(props: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [apiResponse, setApiResponse] = useState<any>(null);

  const fetchStoreBenefits = useCallback(async () => {
    if (props.jobId && props.locationId && props.locale) {
      setLoading(true);
      const response = await getStoreBenefits(
        props.jobId as string,
        props.locationId as string,
        props.locale as string,
      );
      setApiResponse(response);
      setLoading(false);
    }
  }, [props.jobId, props.locale, props.locationId]);

  useEffect(() => {
    fetchStoreBenefits();
  }, [fetchStoreBenefits]);

  return (
    <div>
      <div className="u-border-bottom d-flex flex-column align-items-center justify-content-center gap-10 pb-15">
        <img
          className="pay-and-benefits-dialog-img"
          src={"./../../../../public/images/modal-image.png"}
          alt=""
        />
        <span className="t-body-reduced-tight">{props.city}</span>
        <h2
          className="t-tout"
          id={idGenerator("locationPicker", "pay-benefits-modal").generateId(
            "title",
          )}
        >
          {props.storeName}
        </h2>
      </div>
      {!loading && apiResponse && (
        <HtmlRenderer
          initialContent={apiResponse.payAndBenefitsText}
          htmlTag="p"
          classes="pt-15 pay-and-benefits-text hyperlink-underline"
        />
      )}
      {loading && (
        <div className="d-flex pos-rel">
          <ProgressIndicatorLoader showLoading={true}></ProgressIndicatorLoader>
        </div>
      )}
    </div>
  );
}

export default LocationPickerStoreBenefitsModalContent;
