import sanitizeHtml from "sanitize-html";
import { idGenerator } from "./utils/idGenerator";

export const COUNT_DOWN_INTERVAL = 1000;

export const COUNTRY_CHINA = "iso-country-CHN";

export const COUNTRY_REF_DATA = "iso-country-";

export const PRIVACY_POLICY_ELEMENT_SELECTORS = {
  GLOBAL_CHECKBOX: "global-policy-checkbox",
  REGIONAL_CHECKBOX: "regional-policy-checkbox",
  REGIONAL_ACCEPT_ERROR: "privacyAcceptError",
  PAL_PRIVACY_POLICY_CHECKBOX: "pal-privacy-policy-checkbox",
  CONTENT_PRIVACY: "content-privacy",
};
export const TOTAL_TIME = 120000;
export const IDLE_TIMER_MILLI_SECONDS = 1680000;
export const KEEP_ALIVE_INTERVAL_MILLI_SECONDS = 1710000;
export const DEFAULT_LOCALE = "en-us";
export const DEFAULT_LOCALE_API_HEADER = "en_US";
export const DEFAULT_TRANSLATION_LOCALE = "en-US";
export const DEFAULT_POSTING_LOCATION_HEADER = "USA";
export const LOGOUT = "logout";
export const TIMEOUT = "timeout";
export const APP_TYPE = {
  INTERNAL: "internal",
  EXTERNAL: "external",
};
export const PROFILE_ROUTES = {
  INFO: "info",
  YOUR_ROLES: "roles",
  SAVED_SEARCH: "savedsearch",
  YOUR_APPLICATION: "myapplication",
};
export const APAC_REGIONS = [
  "zh-tw",
  "ja-jp",
  "zh-mo",
  "ko-kr",
  "zh-cn",
  "zh-hk",
];
export const APAC_LOCALES = [
  "zh_TW",
  "ja_JP",
  "zh_MO",
  "en_HK",
  "ko_KR",
  "zh_CN",
  "zh_HK",
  "ar_SA",
  "en_SA",
];
export const JAPAN_LOCALE = "ja_JP";
export const MIN_TEXT_RESUME_COUNT = 200;
export const MAX_TEXT_RESUME_COUNT = 30000;
export const FILE_UPLOAD = {
  fileType: ["pdf", "doc", "docx", "pages", "txt", "rtf"],
  fileSize: 2097152,
  supportLinkCount: 10,
  supportFileCount: 3,
  fileReferenceCode: "pr",
  supportedFileTypes:
    "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/x-iwork-pages-sffpages,.txt,text/plain,.rtf,text/rtf,text/x-rtf",
};
export const RECENTLY_VIEWED_ROLES = "recentlyViewedRoles";
export const SELECTED_LOCATIONS_FOR_ROLE = "selectedLocationsForRole";
export const ROLE_SUBMIT = "roleSubmit";
export const PRIMARY_TYPE = "fileType-Pr";
export const COUNTRY_USA = "iso-country-USA";
export const NEW_EMAIL_ADDRESS = "newEmailAddress";
export const DISABILITY_DATEFORMAT = "MM/DD/YYYY";

export const ADDRESS_PREFERRED = "addressType-preferred";
export const ROLE_PIPELINE = "PIPE";
export const ROLE_MANAGED = "managed";

export const PROFILE_SECTIONS = {
  MOBILITY_PREFERENCES: "mobilityPreferences",
  EMPLOYMENT_TYPES: "employmentTypes",
  SKILLS: "skills",
  LANGUAGES: "languages",
  LOCATIONS: "preferredLocations",
  EDUCATION: "educationDegrees",
  SELF_DISCLOSURES: "selfDisclosures",
  CONTACT_INFO: "contactInfo",
  LINKS: "links",
  EMPLOYMENTS: "employments",
  TEAMS: "teamsOfInterest",
  RECRUITER_LAST_MODIFICATION: "recruiterLastModification",
  PARENT_FIELDS: "parentFields",
  EXTERNAL_PROFILES: "externalProfiles",
};
export const POLLING_TIMEOUT = 10000; // 10 seconds
export const MAX_POLLING_RETRY = 12;
export const POLLING_FIELD = "lastAction";
export const POLLING_SUCCESS = "REVIEW_LATER";
export const POLLING_FAILED = "FAILED";
export const YES = "Yes"; // TODO: find all usages of Yes as a string and replace with constant
export const NO = "No"; // TODO: find all usages of No as a string and replace with constant
export const AWLIWIDGET = "awliwidget";
export const AWLIWIDGET_TYPE = "IN/AwliWidget";
export const DATA_API_KEY = "data-api-key";
export const DATA_ALLOW_SIGN_IN = "data-allow-sign-in";
export const DATA_COMPANY_JOB_CODE = "data-company-job-code";
export const DATA_INTEGRATION_CONTEXT = "data-integration-context";
export const SCRIPT = "script";
export const DATA_MODE = "data-mode";
export const DATA_SIZE = "data-size";
export const DATA_CALLBACK_METHOD = "data-callback-method";
export const TYPE = "type";
export const SRC = "src";
export const STEP_NAME = "stepName";
export const GENDER = "gender";
export const ETHNICITY = "ethnicity";
export const VETERAN_STATUS = "veteranStatus";
export const NATIONALITY = "nationality";
export const DISABILITY = "disability";
export const GLOBAL_HEADER = "globalheader";
export const APPLICATION_JSON = "application/json";
export const APPLICATION_OCTET_STREAM = "application/octet-stream";
export const RESUME_ACTIONS = {
  DISCARD: "discard",
  REVIEW_LATER: "reviewLater",
  REVIEW_NOW: "reviewNow",
};
export const CONTENT_PLAIN_TEXT = "text/plain";
export const TYPE_BLOB = "blob";
export const REFERRAL_TYPE_CORPORATE = "referralType-CPR";
export const ERP_TALENTID = "-11";
export const CREATE_SOURCE_ERP = "talentCreateSource-erp";
export const CREATE_SOURCE_JS = "talentCreateSource-js";
export const INTERNAL_HEADER = "internalheader";
export const USER_TYPE = {
  USER_EXTERNAL: "EXTERNAL",
  USER_INTERNAL: "INTERNAL",
  USER_OTHERS: "OTHERS",
};
export const FILTER_LIST_VIEW_MAX_RESULTS = 5;
export const SAVED_SEARCH_MAX_LIMIT = 10;

export const SEARCH_LIST_MAX_LIMIT = 600;
//Start of - TODO: need to be imported from sortOrders.ts
const DESC_ORDER = "desc";
const ASC_ORDER = "asc";

export type SortingMapType = {
  sort: string | null;
  tranKey: string;
  order: typeof DESC_ORDER | typeof ASC_ORDER;
};
const sortingMap = (
  sort: string | null,
  tranKey: string,
  isDescending?: boolean,
): SortingMapType => ({
  sort,
  tranKey,
  order: isDescending ? DESC_ORDER : ASC_ORDER,
});

export enum ResumeActionType {
  REVIEW_LATER = "REVIEW_LATER",
  FAILED = "FAILED",
  PROGRESS = "PROGRESS",
  REVIEWED = "REVIEWED",
  DISCARDED = "DISCARDED",
  EXPIRED = "EXPIRED",
}

export const sortMapping: Record<string, SortingMapType> = {
  relevance: sortingMap(null, "jobsite.search.relevance"),
  newest: sortingMap("postingDate", "jobsite.search.newest", true),
  teamAsc: sortingMap("teams", "jobsite.search.teamAscending"),
  teamDesc: sortingMap("teams", "jobsite.search.teamDescending", true),
  locationAsc: sortingMap(
    "postToLocationsNameNi",
    "jobsite.search.locationAscending",
  ),
  locationDesc: sortingMap(
    "postToLocationsNameNi",
    "jobsite.search.locationDescending",
    true,
  ),
};
export const defaultSort = "newest";
//End of - TODO: need to be imported from sortOrders.ts
export const ARIA_MESSAGE_TIMER = 750;
export const ALERT_WRAPPER_ID = idGenerator("alert", "wrapper");
export const sanitizeOptions = {
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(["input"]),
  allowedAttributes: {
    "*": ["*"], // Allow all attributes for all tags
  },
  textFilter: function (text: string) {
    return text.replace(/&amp;/g, "&"); // Reverts '&amp;' back to '&'
  },
};
export const DATA_USAGE_CONSENT_POLICY = "pal-privacy";
