import { useCallback } from "react";
import { Talent } from "../../../../../shared/types";
import { updateTalent } from "../../../../api/fetchClient";
import { useFetchData } from "../../../../hooks/useFetchData";

export enum ProfileSaveCategory {
  mobilityPreferences = "mobilityPreferences",
  employmentTypes = "employmentTypes",
  skills = "skills",
  languages = "languages",
  preferredLocations = "preferredLocations",
  educationDegrees = "educationDegrees",
  selfDisclosures = "selfDisclosures",
  contactInfo = "contactInfo",
  links = "links",
  employments = "employments",
  teamsOfInterest = "teamsOfInterest",
}

export function useUpdateTalentProfile(talentId: string) {
  const {
    data: talentUpdateResponse,
    error: talentUpdateError,
    fetchData: talentUpdate,
    isError: isTalentUpdateError,
    isLoading: isTalentUpdateInProgress,
    isSuccess: isTalentUpdateSuccess,
    resetFetchStatus: resetTalentUpdate,
  } = useFetchData(updateTalent);

  const updateContactSection = useCallback(
    (contactSection: Pick<Talent, "contact" | "addresses">) => {
      talentUpdate([talentId, ProfileSaveCategory.contactInfo, contactSection]);
    },
    [talentId, talentUpdate],
  );

  return {
    talentUpdateResponse,
    talentUpdateError,
    isTalentUpdateError,
    isTalentUpdateInProgress,
    isTalentUpdateSuccess,
    resetTalentUpdate,
    updateContactSection,
  };
}
