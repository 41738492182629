import { Button, Overlay, Popover } from "@rpe-js/marcom-web-components";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../AppContext";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { redirectToPath } from "../../util";

export interface UnauthenticatedPopoverProps {
  id?: string;
  arrowPosition?: "start" | "middle" | "end";
  triggerElementId: string;
  message: string;
  positionId?: string;
  onClose: () => void;
  view?: "overlay" | "popover" | "content";
}

const UnauthenticatedPopover = ({
  id,
  arrowPosition,
  triggerElementId,
  message,
  positionId,
  onClose,
  view = "popover",
}: UnauthenticatedPopoverProps) => {
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { locale, appBasePathPrefix } = appUIState.appData;
  const isMobile = useIsMobile();

  const [isOverlayVisible, setOverlayVisible] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setOverlayVisible(true);
    } else {
      setOverlayVisible(false);
    }
  }, [isMobile]);

  const renderPopoverContent = () => (
    <div id={`${id}-unauthenticated-login`}>
      <div id="descFavorites" className="p-lead text-center">
        {t(message)}
      </div>
      <Button
        id={`${id}-sign-in-button`}
        label={t("jobsite.search.signin") as string}
        blockedVariant={true}
        size="medium"
        onClick={signInHandler}
      />
      <div className="save-search-signin__actions text-center">
        <p>{t("jobsite.search.dontHaveAppleId")}</p>
        <ul role="list" className="list">
          <li role="listitem">
            <a
              className="underline-link"
              id={`${id}-create-apple-id-link`}
              aria-label={t("jobsite.search.createAppleId") as string}
              href={`${appBasePathPrefix}/${locale}/createAccount`}
            >
              {t("jobsite.search.createAppleId")}
            </a>
          </li>
          <li role="listitem">
            <a
              className="underline-link"
              id={`${id}-forgot-password-link`}
              aria-label={t("jobsite.search.forgotPassword") as string}
              href={`${appBasePathPrefix}/${locale}/forgotPassword`}
            >
              {t("jobsite.search.forgotPassword")}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );

  const signInHandler = () => {
    redirectToPath(
      appBasePathPrefix,
      `${locale}/login${positionId ? "?favId=" + positionId : ""}`,
    );
  };

  return (
    <>
      {view === "overlay" && isOverlayVisible && (
        <Overlay
          id={`${id}-overlay`}
          elementIdToFocus={triggerElementId}
          visible={isOverlayVisible}
          onClose={() => setOverlayVisible(false)}
          isFullscreen={true}
          noCloseButton={false}
          disableEsc={false}
          classes={{ content: "px-15" }}
          stickyClose={true}
          closeButtonAttrs={{
            ariaLabel: t("jobsite.common.cancel") as string,
            stickyClose: true,
            alignStart: true,
          }}
        >
          {renderPopoverContent()}
        </Overlay>
      )}
      {view === "popover" && (
        <Popover
          id={id}
          aria-describedby="descFavorites"
          aria-labelledby={`${id}-sign-in-button`}
          triggerElementId={triggerElementId}
          arrowPosition={isMobile ? "start" : arrowPosition}
          onClose={onClose}
        >
          {renderPopoverContent()}
        </Popover>
      )}
      {view === "content" && renderPopoverContent()}
    </>
  );
};
export default UnauthenticatedPopover;
