import React from "react";
import { Label } from "../../base/Label";

interface SelfDisclosureFieldViewProps {
  label: string;
  value: string;
  showLabelBold?: boolean;
}
export function SelfDisclosureFieldView({
  value,
  label,
  showLabelBold = true,
}: SelfDisclosureFieldViewProps) {
  return (
    <>
      <section className="column large-6 small-12 pr-15 mb-10">
        {showLabelBold ? (
          <p className="fw-semibold">{label}</p>
        ) : (
          <Label label={label} />
        )}
        <p className="mt-0">{value}</p>
      </section>
    </>
  );
}
