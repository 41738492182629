import {
  Checkbox,
  Dropdown,
  DropDownOptionProps,
  ProgressIndicatorLoader,
  Textbox,
} from "@rpe-js/marcom-web-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MappedERPPostLocation } from "../../../../shared/types/refData";
import { getERPPostlocations } from "../../../api/fetchClient";
import { PillButtonListView } from "../../../components/base/PillButtonListView";
import { SaveCancelAction } from "../../../components/feature/saveAndCancel";
import { useFetchData } from "../../../hooks/useFetchData";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { idGenerator } from "../../../utils/idGenerator";
import { updateRetailLocations } from "../context/Actions";
import { useERPContext } from "../context/ERPContext";

interface LocationRetailModalProps {
  onCancel: () => void;
}

export function LocationRetailModal({ onCancel }: LocationRetailModalProps) {
  const isMobile = useIsMobile();
  const { t } = useIntlMessage();
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedState, setSelectedState] = useState<string>("");
  const [stateOptions, setStateOptions] = useState<DropDownOptionProps[]>([]);
  const [cityOptions, setCityOptions] = useState<MappedERPPostLocation[]>([]);
  const [filterCityOptions, setFilterCityOptions] = useState<
    MappedERPPostLocation[]
  >([]);
  const [filterValue, setFilterValue] = useState<string>("");
  const { state, dispatch } = useERPContext();
  const [selectedCities, setSelectedCities] = useState<MappedERPPostLocation[]>(
    state.retailLocations,
  );
  const {
    data: stateListData,
    isLoading: isStateListLoading,
    isSuccess: isStateListSuccess,
    fetchData: fetchStateList,
    resetFetchStatus: stateListReset,
  } = useFetchData(getERPPostlocations);
  const {
    data: cityListData,
    isLoading: isCityListLoading,
    isSuccess: isCityListSuccess,
    fetchData: fetchCityList,
    resetFetchStatus: cityListReset,
  } = useFetchData(getERPPostlocations);

  useEffect(() => {
    if (isStateListSuccess && stateListData && !isStateListLoading) {
      if (stateListData.length > 0) {
        const fetchedStateOptions = [
          ...stateListData.map((crd) => ({
            label: crd.state as string,
            value: crd.stateProvinceCode as string,
          })),
        ];
        setStateOptions((prev) => [...prev, ...fetchedStateOptions]);
      }
      stateListReset();
    }
  }, [
    stateListData,
    isStateListLoading,
    isStateListSuccess,
    setStateOptions,
    stateListReset,
  ]);
  useEffect(() => {
    if (isCityListSuccess && cityListData && !isCityListLoading) {
      if (cityListData.length > 0) {
        setCityOptions(cityListData);
        setFilterCityOptions(cityListData);
      }
      cityListReset();
    }
  }, [
    cityListData,
    isCityListLoading,
    isCityListSuccess,
    setCityOptions,
    cityListReset,
    setFilterCityOptions,
  ]);

  useEffect(() => {
    setSelectedCities(state.retailLocations);
  }, [state.retailLocations, state.showRetailModal]);

  const countryDropdownOptions: DropDownOptionProps[] = React.useMemo(() => {
    if (state.referenceData.postLocations) {
      return [
        {
          label: t("jobsite.common.selectCountry") as string,
          value: "",
          disabled: true,
        },
        ...state.referenceData.postLocations.map((crd) => ({
          label: crd.countryName,
          value: crd.postLocationId as string,
        })),
      ];
    }
    return [];
  }, [state.referenceData.postLocations, t]);

  const handleCountrySelect = useCallback(
    (countryId: string) => {
      setSelectedCountry(countryId);
      setSelectedState("");
      setCityOptions([]);
      setFilterCityOptions([]);
      setStateOptions([
        {
          label: t("jobsite.common.selectcountryorregion") as string,
          value: "",
          disabled: true,
        },
      ]);
      if (countryId) {
        fetchStateList([countryId, null, null, null]);
      }
    },
    [t, fetchStateList],
  );

  const handleStateSelect = useCallback(
    (stateId: string) => {
      setSelectedState(stateId);
      setCityOptions([]);
      setFilterCityOptions([]);
      if (selectedCountry && stateId) {
        fetchCityList([selectedCountry, null, stateId, null]);
      }
    },
    [selectedCountry, fetchCityList],
  );

  const handleCitySelect = useCallback(
    (city: MappedERPPostLocation, isChecked: boolean) => {
      setSelectedCities((prev) =>
        isChecked
          ? [...prev, city]
          : prev.filter((c) => c.postLocationId !== city.postLocationId),
      );
    },
    [],
  );

  const groupedCities = useMemo(() => {
    const groupedCities: { [key: string]: MappedERPPostLocation[] } = {};

    filterCityOptions.forEach((option) => {
      const cityName = option.city ?? "";
      if (!groupedCities[cityName]) {
        groupedCities[cityName] = [];
      }
      groupedCities[cityName].push(option);
    });
    const sortedGroupedCities = Object.keys(groupedCities)
      .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }))
      .reduce(
        (acc, key) => {
          acc[key] = groupedCities[key];
          return acc;
        },
        {} as { [key: string]: MappedERPPostLocation[] },
      );
    return sortedGroupedCities;
  }, [filterCityOptions]);

  const handleFilterChange = useCallback(
    (value: string) => {
      setFilterValue(value);
      if (value) {
        const filteredCities = cityOptions.filter((cities) =>
          cities.city?.toLowerCase().includes(value.toLowerCase()),
        );
        setFilterCityOptions(filteredCities);
      } else {
        setFilterCityOptions(cityOptions);
      }
    },
    [cityOptions],
  );

  const isCitySelected = useCallback(
    (city: string) => {
      return selectedCities.map((city) => city.name).includes(city);
    },
    [selectedCities],
  );

  const getSelectedOptions = () => {
    return (
      <div className={`selected-options ${isMobile && "pb-10"}`}>
        <PillButtonListView
          moduleName="erp"
          showAsAccordion={isMobile}
          selectTranslationLabel="jobsite.common.selectedStoresCount"
          countReplacementKey={"storeCount"}
          pillBtnList={selectedCities}
          itemAriaLabel={t("jobsite.search.stores") as string}
          onClearAllBtnClick={() => {
            dispatch(updateRetailLocations([]));
          }}
          onPillBtnRemove={(item) => {
            setSelectedCities((prev) =>
              prev.filter(
                (data) => data.postLocationId !== item.postLocationId,
              ),
            );
          }}
          pillBtnOptions={{
            getKey: (data: MappedERPPostLocation) =>
              `loc_${data.postLocationId}`,
            getLabel: (data: MappedERPPostLocation) =>
              data.city ? `${data.city}, ${data.name}` : `${data.name}`,
          }}
        />
      </div>
    );
  };

  const cityFilter = () => {
    return (
      <Textbox
        id="cityFilterTextbox"
        required={true}
        label={t("jobsite.common.filterByCity") as string}
        value={filterValue}
        onValueChange={handleFilterChange}
        errorA11y={t("jobsite.common.errorIconLabel") as string}
      />
    );
  };
  return (
    <>
      {isMobile && (
        <h2 className="mobile-modal-header t-eyebrow-reduced">
          {t("jobsite.erp.introduceFriend.modalMobileHeader")}
        </h2>
      )}
      {!isMobile && (
        <h2 className="a11y">
          {t("jobsite.erp.introduceFriend.modalMobileHeader")}
        </h2>
      )}
      <div className="location-retail-modal-container d-flex gap-20">
        {isMobile && <div className="px-10">{getSelectedOptions()}</div>}
        <div className="location-retail-modal-left pt-15">
          <div>
            <Dropdown
              required={true}
              name={t("jobsite.common.selectCountry") as string}
              label={t("jobsite.common.selectCountry") as string}
              options={countryDropdownOptions}
              id={idGenerator("erp", "country").generateId()}
              value={selectedCountry}
              handleValueSelect={(e: any, option) => {
                handleCountrySelect(option.value);
              }}
            />
          </div>
          {selectedCountry && (
            <div>
              <Dropdown
                required={true}
                name={t("jobsite.common.selectcountryorregion") as string}
                label={t("jobsite.common.selectcountryorregion") as string}
                options={stateOptions}
                id={idGenerator("erp", "state").generateId()}
                value={selectedState}
                handleValueSelect={(e: any, option) => {
                  handleStateSelect(option.value);
                }}
                errorA11y={t("jobsite.common.errorIconLabel") as string}
              />
            </div>
          )}
          {selectedState && isMobile && cityFilter()}
        </div>

        <div className="location-retail-modal-right flex-1 pt-10 px-20 pos-rel">
          {(isCityListLoading || isStateListLoading) && (
            <ProgressIndicatorLoader showLoading={true} />
          )}
          {!isMobile && (!selectedCountry || !selectedState) && (
            <p>
              {t("jobsite.erp.introduceFriend.storeModalMessage") as string}
            </p>
          )}
          {!isMobile && selectedState && (
            <>
              <div className="row">
                {cityOptions.length > 0 && (
                  <h3 className="column small-12 fw-regular t-body">
                    <span>
                      {cityOptions.length > 1
                        ? t("jobsite.common.selectStores", {
                            count: cityOptions.length,
                          })
                        : t("jobsite.common.store", {
                            count: cityOptions.length,
                          })}
                    </span>
                    {selectedCountry && selectedState && (
                      <span className="label-grey">
                        {` ${cityOptions[0].countryName}, ${cityOptions[0].state}`}
                      </span>
                    )}
                  </h3>
                )}
              </div>
              {cityFilter()}
            </>
          )}
          <ul className="city-list mt-10 u-list-style-none ml-0" role="list">
            {Object.keys(groupedCities)?.map((cityName, index) => (
              <li key={cityName} className="city-container" role="listitem">
                <div className="city-name fw-bold">{cityName}</div>
                <ul
                  className="checkbox-container list-nobullet"
                  role="list"
                  key={index}
                >
                  {groupedCities[cityName].map((cityOption) => (
                    <>
                      <li role="listitem" key={cityOption.postLocationId}>
                        <Checkbox
                          required={false}
                          key={cityOption.postLocationId}
                          id={idGenerator(
                            "erp",
                            `location-checkbox-${cityOption.postLocationId}`,
                          ).generateId()}
                          label={cityOption.name as string}
                          checked={isCitySelected(cityOption.name ?? "")}
                          showTileView={isMobile}
                          onValueChange={(isChecked) =>
                            handleCitySelect(cityOption, isChecked)
                          }
                          className="d-block"
                        />
                      </li>
                    </>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {!isMobile && (
        <div className="u-border-top px-25">{getSelectedOptions()}</div>
      )}

      <section
        id="updateStore"
        className={`d-flex justify-center px-20 pb-20 ${isMobile && "u-border-top"}`}
      >
        <SaveCancelAction
          cancelLabelName={t("jobsite.common.cancel") as string}
          saveLabelName={
            t("jobsite.erp.introduceFriend.updatestores") as string
          }
          onCancel={onCancel}
          onSave={() => {
            dispatch(updateRetailLocations(selectedCities));
            onCancel();
          }}
          saveDisabled={selectedCities.length === 0}
          cancelButtonId={idGenerator("erp", "retail-modal").generateId(
            "cancel-button",
          )}
          saveButtonId={idGenerator("erp", "retail-modal").generateId(
            "update-stores-button",
          )}
        />
      </section>
    </>
  );
}
