import APP_CONSTANTS from "../../utilities/appConstants";

const keyMapper = {
  product: "productsAndServices",
  locations: "postLocation",
  teams: "subTeam",
  teamsID: "teamsAndSubTeams",
  languages: "language",
  sortModifier: "sortModifier",
  keywords: "keywords",
  retailRoles: "globalManagedRole",
  homeOffice: "homeOffice",
  jobLevel: "jobLevel",
};

// TODO: Complete this implementation for other filters
export function decodeUrl(
  queryParamsObj: { [key: string]: string },
  isInternal: boolean,
) {
  // TODO : Fix types
  const refDataSearchFilters: any = {};
  const dynamicFilters: any = {};
  const weeklyHoursRegex = /^\d*[.,]?\d*$/;
  let searchQuery = "";
  let sort = "";
  let page = 1;
  if (queryParamsObj.search) {
    const res = queryParamsObj.search;
    searchQuery = res;
  }
  if (queryParamsObj.sort) {
    const res = queryParamsObj.sort;
    if (APP_CONSTANTS.SEARCH_SORT_TERMS.includes(res)) {
      sort = res;
    }
  }
  if (queryParamsObj.page) {
    const res = queryParamsObj.page;
    page = Number(res);
  }
  if (queryParamsObj.lang) {
    const array = queryParamsObj.lang.split(" ");
    const result = array.map((value) => {
      const dashIndex = value.lastIndexOf("-");
      if (dashIndex !== -1) {
        return keyMapper.languages + value.substring(dashIndex);
      }
      return value;
    });
    refDataSearchFilters.languages = result;
  }
  if (queryParamsObj.jobLevel && isInternal) {
    const array = queryParamsObj.jobLevel.split(" ");
    dynamicFilters.jobLevel = array;
  }
  if (queryParamsObj.minHours && isInternal) {
    const res = queryParamsObj.minHours;
    if (weeklyHoursRegex.test(res)) {
      dynamicFilters.minimumHours = res;
    }
  }
  if (queryParamsObj.maxHours && isInternal) {
    const res = queryParamsObj.maxHours;
    if (weeklyHoursRegex.test(res)) {
      dynamicFilters.maximumHours = res;
    }
  }
  if (queryParamsObj.homeOffice) {
    const res = queryParamsObj.homeOffice;
    dynamicFilters.homeOffice = Boolean(res);
  }
  if (queryParamsObj.key) {
    const array = queryParamsObj.key.split(" ");
    const transformedArray = array.map((str) => str.replace(/-/g, " "));
    dynamicFilters.keywords = transformedArray;
  }
  if (queryParamsObj.product) {
    const array = queryParamsObj.product.split(" ");
    const result = array.map((value) => {
      const dashIndex = value.lastIndexOf("-");
      if (dashIndex !== -1) {
        return keyMapper.product + value.substring(dashIndex);
      }
      return value;
    });
    refDataSearchFilters.products = result;
  }
  if (queryParamsObj.location) {
    const array = queryParamsObj.location.split(" ");
    const result = array.map((value) => {
      const dashIndex = value.lastIndexOf("-");
      if (dashIndex !== -1) {
        return keyMapper.locations + value.substring(dashIndex);
      }
      return value;
    });
    refDataSearchFilters.locations = result;
  }
  if (queryParamsObj.team) {
    const array = queryParamsObj.team.split(" ");
    const result = array.map((value) => {
      const parts = value.split("-");
      const [team, subTeam] = parts.slice(-2);
      return { team, subTeam };
    });
    refDataSearchFilters.teams = result;
  }
  if (queryParamsObj.mgr && isInternal) {
    const array = queryParamsObj.mgr.split(" ");
    refDataSearchFilters.hiringManagers = array;
  }
  if (queryParamsObj.role && isInternal) {
    const array = queryParamsObj.role.split(" ");
    const result = array.map((value) => {
      const dashIndex = value.lastIndexOf("-");
      if (dashIndex !== -1) {
        return keyMapper.retailRoles + value.substring(dashIndex);
      }
      return value;
    });
    refDataSearchFilters.retailRoles = result;
  }
  return { refDataSearchFilters, dynamicFilters, searchQuery, sort, page };
}

// //  TODO : Remove after refDataforID api integration
// export function refDataByID(filtersByID: any, refData: any) {
//   const filterObj: Filters = { ...filtersByID };
//   if (filtersByID.product && filtersByID.product.length > 0) {
//     const productFilters = refData.products.filter((item: { id: any }) =>
//       filtersByID.product.includes(item.id),
//     );
//     filterObj.product = productFilters;
//   }
//   if (filtersByID.role && filtersByID.role.length > 0) {
//     const roleFilters = refData.roles.filter((item: { id: any }) =>
//       filtersByID.role.includes(item.id),
//     );
//     filterObj.retailRoles = roleFilters;
//   }
//   if (filtersByID.lang && filtersByID.lang.length > 0) {
//     filterObj.lang = [
//       {
//         id: "language-en_US",
//         displayName: "English - US",
//         name: "English - US",
//         uniqueKey: "language-en_US",
//         code: "en_US",
//         name_en_US: "English - US",
//       },
//     ];
//   }
//
//   return filterObj;
// }
