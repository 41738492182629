import React, { useCallback, useState } from "react";
import { MappedERPPostLocation } from "../../../../shared/types/refData";
import { getERPPostlocations } from "../../../api/fetchClient";
import { ALERT_WRAPPER_ID } from "../../../app.constants";
import { LocationWidget } from "../../../components/feature/widgets/LocationWidget";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { focusElement } from "../../../utils/focusUtil";
import {
  toggleReviewSubmitBtn,
  updateCorporateLocations,
} from "../context/Actions";
import { useERPContext } from "../context/ERPContext";
import { StepName } from "../context/ErpState";
import { FooterSection } from "../FooterSection";

interface LocationCorporateStepProps {
  moveNext: () => void;
  goBack: () => void;
  goToStep: (stepName: StepName, id?: string) => void;
}

export function LocationCorporateStep({
  moveNext,
  goBack,
  goToStep,
}: LocationCorporateStepProps) {
  const { t } = useIntlMessage();
  const { state, dispatch } = useERPContext();
  const { corporateLocations, enableReviewSubmitBtn } = state;
  const [errorMsg, setErrorMsg] = useState<string>("");

  const addCorporateLocation = useCallback(
    (item: MappedERPPostLocation) => {
      const locations = [...corporateLocations];
      const locationKeys = locations.map((data) => data.postLocationId);
      if (locationKeys.includes(item.postLocationId)) return;
      dispatch(updateCorporateLocations([...locations, { ...item }]));
    },
    [corporateLocations, dispatch],
  );

  const deleteCorporateLocation = useCallback(
    (item: MappedERPPostLocation) => {
      const locations = [...corporateLocations];
      dispatch(
        updateCorporateLocations(
          locations.filter(
            (data) => data.postLocationId !== item.postLocationId,
          ),
        ),
      );
    },
    [corporateLocations, dispatch],
  );

  const validateLocations = useCallback((): boolean => {
    return corporateLocations.length > 0;
  }, [corporateLocations]);

  const moveToPreviousStep = useCallback(() => {
    if (!validateLocations()) dispatch(toggleReviewSubmitBtn(false));
    goBack();
  }, [validateLocations, goBack, dispatch]);

  const moveToNextStep = useCallback(
    (stepName?: StepName) => {
      if (!validateLocations()) {
        setErrorMsg(t("jobsite.apply.atleastOneLocation") as string);
        focusElement(ALERT_WRAPPER_ID.generateId("location-widget"));
        return;
      }
      setErrorMsg("");
      if (stepName) {
        goToStep(stepName);
        return;
      }
      moveNext();
    },
    [moveNext, validateLocations, t, goToStep],
  );

  const apiWrapper = useCallback((query: string) => {
    return getERPPostlocations(null, query, null, null);
  }, []);

  return (
    <>
      <LocationWidget
        moduleName="erp"
        title={t("jobsite.getDiscovered.roleLocations") as string}
        subTitle={
          t("jobsite.erp.introduceFriend.workLocation.subTitle") as string
        }
        currentStep={3}
        totalSteps={4}
        errorMessage={errorMsg}
        typeAheadPlaceholder={t("jobsite.getDiscovered.enterCountry") as string}
        selectedLocationsCountLabel="jobsite.erp.selectedCountries"
        countReplacementKey="count"
        isCorporate={true}
        apiEndpoint={apiWrapper}
        getSuggestionLabel={(suggestion: MappedERPPostLocation) =>
          suggestion.countryName
        }
        pillButtonOptions={{
          getKey: (data: MappedERPPostLocation) => `loc_${data.postLocationId}`,
          getLabel: (data: MappedERPPostLocation) => data.countryName,
        }}
        pillButtonData={state.corporateLocations}
        onErrorMessageClose={() => setErrorMsg("")}
        onClearAllBtnClick={() => dispatch(updateCorporateLocations([]))}
        onTypeAheadSelection={(data) => addCorporateLocation(data)}
        onPillBtnRemove={(data) => deleteCorporateLocation(data)}
      />
      <FooterSection
        module="erp"
        enableReviewSubmitBtn={enableReviewSubmitBtn}
        onBack={() => moveToPreviousStep()}
        onContinue={() => moveToNextStep()}
        onReviewAndSubmit={() => moveToNextStep(StepName.REVIEW_INFO)}
      />
    </>
  );
}
