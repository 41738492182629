import { filter, startsWith } from "lodash";
import { Link } from "../../shared/types/talent/talent";
import {
  TalentSupportingFile,
  TalentSupportingLink,
} from "../types/SupportingFileWidget";

const VALID_EMAIL_REGEX_PATTERN = () =>
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+)*))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gim;

export const INTERNAL_EMAIL_PATTERN = () => /@apple.com\s*$/;

export const URL_REGEX = () =>
  /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

export function validateEmailOnSubmit(email: string): {
  message: string;
  valid: boolean;
} {
  if (!email) {
    return { message: "jobsite.common.erroremail", valid: false };
  } else if (!VALID_EMAIL_REGEX_PATTERN().test(email)) {
    return { message: "jobsite.common.validemail", valid: false };
  } else {
    return { message: "", valid: true };
  }
}

export const linkFilter = (url: string) => {
  const httpUrl = "http://";
  const httpsUrl = "https://";
  if (startsWith(url, httpUrl) || startsWith(url, httpsUrl)) {
    return url;
  } else {
    return httpUrl + url;
  }
};

export function validateSupportFiles(
  supportFiles: TalentSupportingFile[] | null,
): boolean {
  if (supportFiles) {
    const errorItems = supportFiles.filter(
      (item: TalentSupportingFile) => !item.category,
    );
    if (errorItems.length > 0) return false;
    let isValid = true;
    supportFiles?.forEach((file) => {
      const duplicateFiles = filter(
        supportFiles,
        (item) =>
          item.fileData?.name === file.fileData?.name &&
          item.category === file.category,
      );
      if (duplicateFiles.length > 1) {
        isValid = false;
        return;
      }
    });
    return isValid;
  }
  return true;
}

export function validateSupportLinks(
  links: TalentSupportingLink[] | Link[] | null | undefined,
) {
  if (links) {
    const errorItems = links.filter(
      (item: TalentSupportingLink | Link) =>
        !item.categoryID || !URL_REGEX().test(item?.link as string),
    );
    if (errorItems.length > 0) return false;
    // using object, check if there is duplicate link and category
    const linksObj: Record<string, boolean> = {};
    for (let i = 0; i < links.length; i++) {
      const key = links[i].categoryID + (links[i].link ?? "");
      // duplicate link and category check
      if (linksObj[key]) {
        return false;
      }
      linksObj[key] = true;
    }
  }
  return true;
}
