import React from "react";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { ReviewHeaderSection } from "./ReviewHeaderSection";

interface ReviewSectionProps {
  headerText: string;
  children?: React.ReactNode;
  onAction: () => void;
  showEdit?: boolean;
  id: string;
}
export function ReviewSection({
  headerText,
  onAction,
  children,
  showEdit = true,
  id,
}: ReviewSectionProps) {
  const { t } = useIntlMessage();
  return (
    <section
      className="mb-40"
      role="group"
      aria-labelledby={`review-header-${id}`}
    >
      <ReviewHeaderSection
        headerText={headerText}
        buttonText={t("jobsite.common.edit") as string}
        onAction={onAction}
        showEdit={showEdit}
        id={id}
      ></ReviewHeaderSection>
      <section className="mt-10">{children}</section>
    </section>
  );
}
