import APP_CONSTANTS from "../../utilities/appConstants";
import { transformUrlParam } from "../util";
import { ISetFilters } from "../hooks/useSearchPageQueryParams";

export function encodeUrl(
  params: URLSearchParams,
  filters: Partial<ISetFilters>,
  isInternal: boolean,
) {
  filters.search && filters.search.length > 0
    ? params.set(APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.SEARCH, filters.search)
    : params.delete(APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.SEARCH);
  if (filters.sort) {
    filters.sort.length > 0 &&
    APP_CONSTANTS.SEARCH_SORT_TERMS.includes(filters.sort)
      ? params.set(APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.SORT, filters.sort)
      : params.delete(APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.SORT);
  }
  if (filters.location) {
    if (filters.location.length > 0) {
      const paramStringArray = filters.location.map((item) => {
        return `${transformUrlParam(item.name_en_US || item.name)}-${item.code || item.id.split("-")[1]}`;
      });
      params.set(
        APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.LOCATION,
        paramStringArray.join("+"),
      );
    } else {
      params.delete(APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.LOCATION);
    }
  }
  if (filters.hiringManager && isInternal) {
    filters.hiringManager.length > 0
      ? params.set(
          APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.HIRING_MANAGER,
          filters.hiringManager.join("+"),
        )
      : params.delete(APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.HIRING_MANAGER);
  }

  if (filters.sort)
    params.set(APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.SORT, filters.sort);
  if (filters.key) {
    const keywordList = filters.key.map((k) => transformUrlParam(k));
    const paramsString = keywordList.join("+");

    paramsString.length > 0
      ? params.set(APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.KEYWORD, paramsString)
      : params.delete(APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.KEYWORD);
  }
  if (filters.lang) {
    const paramStringArray = filters.lang.map((item) => {
      return `${transformUrlParam(item.name_en_US || item.name)}-${item.code || item.id.split("-")[1]}`;
    });
    const paramString = paramStringArray.join("+");
    paramString.length > 0
      ? params.set(
          APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.LANGUAGES,
          paramString,
        )
      : params.delete(APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.LANGUAGES);
  }
  if (filters.product) {
    const paramStringArray = filters.product.map((p) => {
      return `${transformUrlParam(p.name_en_US || p.name)}-${p.code || p.id.split("-")[1]}`;
    });
    const paramString = paramStringArray.join("+");
    paramString.length > 0
      ? params.set(APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.PRODUCT, paramString)
      : params.delete(APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.PRODUCT);
  }

  if (filters.team) {
    const paramStringArray = filters.team.map((p) => {
      return `${transformUrlParam(p.name).replace(" ", "-")}-${p.teamCode}-${p.code}`;
    });
    const paramString = paramStringArray.join("+");
    paramString && paramString.length > 0
      ? params.set(APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.TEAM, paramString)
      : params.delete(APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.TEAM);
  }

  if (filters.homeOffice) {
    filters.homeOffice === "true"
      ? params.set(
          APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.HOME_OFFICE,
          filters.homeOffice,
        )
      : params.delete(APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.HOME_OFFICE);
  }
  if (filters.page) {
    filters.page > 1
      ? params.set(
          APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.PAGE,
          filters.page.toString(),
        )
      : params.delete(APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.PAGE);
  }
  if (filters.jobLevel && isInternal) {
    const paramString = filters.jobLevel.join("+");
    paramString.length > 0
      ? params.set(
          APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.JOB_LEVEL,
          paramString,
        )
      : params.delete(APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.JOB_LEVEL);
  }
  if (filters.role && isInternal) {
    const paramStringArray = filters.role.map((item) => {
      return `${transformUrlParam(item.name_en_US || item.name)}-${item.id.split("-")[1]}`;
    });
    const paramString = paramStringArray.join("+");
    paramString.length > 0
      ? params.set(
          APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.STORE_ROLE,
          paramString,
        )
      : params.delete(APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.STORE_ROLE);
  }
  if (isInternal) {
    filters.minHours
      ? params.set(
          APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.MIN_HRS,
          filters.minHours,
        )
      : params.delete(APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.MIN_HRS);

    filters.maxHours
      ? params.set(
          APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.MAX_HRS,
          filters.maxHours,
        )
      : params.delete(APP_CONSTANTS.SEARCH_QUERY_PARAMS_KEYS.MAX_HRS);
  }

  return params;
}
