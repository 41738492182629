/**@deprecated
 * Do not use this method
 */
export const translateReplace = (
  message: string,
  placeholders: string[],
  values: string[],
): string => {
  let formattedMessage = message;

  placeholders.forEach((placeholder, index) => {
    const value = values[index] || "";
    formattedMessage = formattedMessage.replace(
      new RegExp(placeholder, "g"),
      value,
    );
  });

  return formattedMessage;
};
export default translateReplace;
