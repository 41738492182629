import {
  InfoTooltip,
  NativeButton,
  Overlay,
  Popover,
  TooltipConfiguration,
} from "@rpe-js/marcom-web-components";
import React, { useCallback, useContext, useState } from "react";
import AppContext from "../../AppContext";
import Icon from "../../components/base/IconComponent";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile"; // Hook to check if on mobile

interface SavedRolesFilterProps {
  id: string;
  recentViewedRoles: Array<any>;
}

export function SavedRolesFilter({
  id,
  recentViewedRoles,
}: SavedRolesFilterProps) {
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { locale } = appUIState.appData;
  const [showListPopover, setShowListPopover] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [savedRoles, setSavedRoles] = useState(recentViewedRoles || []);
  const isMobile = useIsMobile();
  const [isMobileOverlayOpen, setMobileOverlayOpen] = useState(false);

  const onSavedRolesFiltersPopupClose = () => setShowListPopover(false);

  const handleListPopover = useCallback(() => {
    setSavedRoles(recentViewedRoles);
    if (isMobile) {
      setMobileOverlayOpen(true); // Open overlay on mobile
    } else {
      setShowListPopover(!showListPopover); // Toggle popover on desktop
    }
  }, [isMobile, recentViewedRoles, showListPopover]);

  const getTooltipConfiguration = useCallback(() => {
    return {
      overrideDefaultBehaviour: true,
      showOnHover: false,
      show: hovered,
      icon: (
        <Icon
          name={hovered ? "recently-viewed-blue" : "recently-viewed-grey"}
          size="medium"
          cursor="pointer"
        ></Icon>
      ),
    } as TooltipConfiguration;
  }, [hovered]);

  const renderSavedRolesList = () => (
    <div className="filter-list">
      <div>
        <h2
          className={
            isMobile
              ? "pb-10 text-center t-eyebrow-reduced"
              : "u-border-bottom pb-10 text-center t-body-reduced"
          }
          id={"labelSavedRolesFilters"}
        >
          {t("jobsite.search.recentlyRoles")}
        </h2>
        {savedRoles.length > 0 ? (
          <ul
            className="list-nobullet"
            role="list"
            aria-label={`${t("jobsite.search.recentlyRoles")}`}
          >
            {savedRoles?.map(
              (item: any, index: number) =>
                index < 10 && (
                  <li
                    key={index}
                    role="listitem"
                    className={`${
                      index !== savedRoles.length - 1 ? "u-border-bottom" : ""
                    } pt-10 pb-10`}
                  >
                    <a
                      id={`${id}-item-${index}`}
                      href={`/${locale}/details/${item.reqId}/${item.postingTitle}`}
                      aria-expanded="false"
                    >
                      {item.postingTitle}
                    </a>
                  </li>
                ),
            )}
          </ul>
        ) : (
          <p className="no-results" id={"descSavedRolesFilters"}>
            {t("jobsite.search.noRecentlyRoles")}
          </p>
        )}
      </div>
    </div>
  );

  const elementToFocusBack = `${id}-saved-roles-button`;
  return (
    <div className="pos-rel">
      <NativeButton
        id={`${id}-saved-roles-button`}
        className={"saved-roles"}
        onClick={handleListPopover}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        aria-label={t("jobsite.search.recentlyRoles") as string}
        aria-haspopup
      >
        {!isMobile && (
          <>
            <InfoTooltip
              positionClass="form-tooltip-after ml-0 saved-roles-icon"
              tooltipPointer="middle"
              iconLabel={t("jobsite.search.recentlyRoles") as string}
              setAsHtml={true}
              infoTooltipBody={t("jobsite.search.recentlyRoles") as string}
              configuration={getTooltipConfiguration()}
            ></InfoTooltip>
            <Icon
              classes="ml-5"
              name={showListPopover ? "chevron-grey-up" : "chevron-grey-down"}
              width="12"
              height="6"
              cursor="pointer"
            ></Icon>
          </>
        )}
        {isMobile && (
          <Icon name="recently-viewed-blue" size="medium" cursor="pointer" />
        )}
      </NativeButton>

      {isMobileOverlayOpen ? (
        <Overlay
          id={`${id}`}
          elementIdToFocus={elementToFocusBack}
          visible={isMobileOverlayOpen}
          onClose={() => setMobileOverlayOpen(false)}
          isFullscreen={true}
          noCloseButton={false}
          disableEsc={false}
          closeButtonAttrs={{
            ariaLabel: t("jobsite.common.cancel") as string,
            stickyClose: true,
            alignStart: true,
          }}
          classes={{ content: "px-15" }}
        >
          {renderSavedRolesList()}
        </Overlay>
      ) : (
        showListPopover && (
          <div className="saved-roles-popover">
            <Popover
              id={id}
              aria-describedby={
                !savedRoles || savedRoles.length === 0
                  ? "descSavedRolesFilters"
                  : "labelSavedRolesFilters"
              }
              aria-labelledby="labelSavedRolesFilters"
              triggerElementId={elementToFocusBack}
              arrowPosition="end"
              onClose={onSavedRolesFiltersPopupClose}
            >
              {renderSavedRolesList()}
            </Popover>
          </div>
        )
      )}
    </div>
  );
}
