import {
  Checkbox,
  Dropdown,
  DropDownOptionProps,
  TextButton,
} from "@rpe-js/marcom-web-components";
import React from "react";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { getCheckboxClasses } from "../../../util";
import { idGenerator } from "../../../utils/idGenerator";
import { LanguageForm } from "./spokenLanguagesFormUtils";

type EditSpokenLanguagesProps = {
  languages: LanguageForm[];
  langaugesData: DropDownOptionProps[];
  proficiencyData: DropDownOptionProps[];
  handleValueChange: (
    index: number,
    option: DropDownOptionProps | boolean,
    field: string,
  ) => void;
  removeLanguage: (index: number) => void;
  addLanguage: (index: number) => void;
  showError: boolean;
  mode: "profile" | "parsedmodal";
};

export const EditSpokenLanguages = ({
  languages,
  langaugesData,
  proficiencyData,
  handleValueChange,
  removeLanguage,
  addLanguage,
  showError,
  mode,
}: EditSpokenLanguagesProps) => {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();

  const preferredCheckbox = (
    langObj: LanguageForm,
    index: number,
    showAsTile?: boolean,
  ) => {
    return (
      <Checkbox
        id={`preferred-lang-${index}`}
        required={false}
        label={
          t(
            mode === "parsedmodal"
              ? "jobsite.common.preferred"
              : "jobsite.common.useAsPreferredLang",
          ) as string
        }
        showTileView={showAsTile}
        classes={getCheckboxClasses(showAsTile)}
        checked={langObj.preferredLanguage}
        onValueChange={(isChecked: boolean) =>
          handleValueChange(index, isChecked, "preferredLanguage")
        }
      />
    );
  };
  return (
    <div>
      {languages.map((langObj: LanguageForm, index: number) => (
        <div
          key={langObj.key}
          className={`${index > 0 ? "u-border-top pt-20" : ""}`}
        >
          <div className="row">
            <div
              className={`column large-6 small-12 mb-10 ${!isMobile ? "pr-15" : ""}`}
            >
              <Dropdown
                id={idGenerator(mode, "language").generateId(`${index}`)}
                required={true}
                options={
                  langaugesData?.length
                    ? (langaugesData as DropDownOptionProps[])
                    : []
                }
                name={t("jobsite.common.chooseLanguage") as string}
                value={langObj.languageID}
                label={t("jobsite.common.chooseLanguage") as string}
                handleValueSelect={(
                  evt: React.ChangeEvent,
                  option: DropDownOptionProps,
                ) => handleValueChange(index, option, "language")}
                error={
                  showError && langObj.languageError
                    ? langObj.languageError
                    : undefined
                }
                errorA11y={t("jobsite.common.errorIconLabel") as string}
              />
            </div>

            {!isMobile && (
              <div className="column large-6 small-12 mt-7">
                {preferredCheckbox(langObj, index)}
              </div>
            )}
          </div>

          <div className="row">
            <div
              className={`column large-6 small-12 mb-10 ${!isMobile ? "pr-15" : ""}`}
            >
              <Dropdown
                id={idGenerator(mode, "spokenlanguages").generateId(`${index}`)}
                required={true}
                options={
                  proficiencyData?.length
                    ? (proficiencyData as DropDownOptionProps[])
                    : []
                }
                value={langObj.proficiencyID}
                label={t("jobsite.common.selectProficiency") as string}
                handleValueSelect={(
                  evt: React.ChangeEvent,
                  option: DropDownOptionProps,
                ) => handleValueChange(index, option, "proficiency")}
                name={t("jobsite.common.selectProficiency") as string}
                errorA11y={t("jobsite.common.errorIconLabel") as string}
              />
            </div>
            {isMobile && (
              <div className="column large-6 small-12 mt-7">
                {preferredCheckbox(langObj, index, true)}
              </div>
            )}
          </div>

          <div className="d-flex mb-30">
            {languages.length > 1 && (
              <span className="mr-20">
                <TextButton
                  id={idGenerator(mode, "remove-language").generateId(
                    `${index}`,
                  )}
                  label={t("jobsite.common.remove") as string}
                  onClick={() => removeLanguage(index)}
                  icon="icon-minuscircle"
                  aria-label={`${t("jobsite.common.removeEntity", { name: `${t("jobsite.common.langSpokenTitle")} ${index + 1}` })}`}
                />
              </span>
            )}
            {index === languages.length - 1 && (
              <TextButton
                id={idGenerator(mode, "add-language").generateId(`${index}`)}
                label={t("jobsite.common.addAnotherLanguage") as string}
                onClick={() => addLanguage(index)}
                icon="icon-pluscircle"
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
