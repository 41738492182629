import React, { createContext, useContext } from "react";
import { SavedSearchesAction } from "./ActionTypes";
import { SavedSearchesState } from "./SavedSearchesState";

// Create a context
export const SavedSearchesContext = createContext<
  | { state: SavedSearchesState; dispatch: React.Dispatch<SavedSearchesAction> }
  | undefined
>(undefined);

// Custom hook to use the SavedSearchesContext
export const useSavedSearchesContext = (): {
  state: SavedSearchesState;
  dispatch: React.Dispatch<SavedSearchesAction>;
} => {
  const context = useContext(SavedSearchesContext);
  if (!context) {
    throw new Error(
      "useSavedSearchesContext must be used within an savedSearchesProvider",
    );
  }
  return context;
};
