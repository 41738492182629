import React from "react";
import { downloadFile } from "../../api/fetchClient";
import { useCurrentUserContext } from "../../CurrentUserContext";
import {
  TalentSupportingFile,
  TalentSupportingLink,
} from "../../types/SupportingFileWidget";
import { linkFilter } from "../../utils/validatorUtil";
import Icon from "./IconComponent";
import { Info } from "./Info";

interface SupportingFileViewProps {
  supportFiles?: TalentSupportingFile[] | null;
  supportLinks?: TalentSupportingLink[] | null;
  noResultsLabel: string;
}

export function SupportingFileView({
  supportFiles,
  supportLinks,
  noResultsLabel,
}: SupportingFileViewProps) {
  const { currentUser } = useCurrentUserContext();
  const supportFilesLength = supportFiles ? supportFiles.length : 0;
  const supportLinksLength = supportLinks ? supportLinks.length : 0;

  return (
    <>
      {(supportFilesLength > 0 || supportLinksLength > 0) && (
        <ul role="list" className="list-nobullet">
          {supportFiles &&
            supportFiles.map((supportFile: TalentSupportingFile, index) => (
              <li key={index} role="listitem">
                <>
                  <section className="d-flex align-center mt-10">
                    <span className="mt-10" aria-hidden={true}>
                      <Icon
                        width="18"
                        height="18"
                        name={"attachment-grey"}
                      ></Icon>
                    </span>
                    <span className="ml-10">{supportFile.categoryName}</span>
                  </section>
                  <section>
                    {supportFile.fileData && supportFile.fileData.fileId && (
                      <span className="ml-30">
                        <a
                          href="#"
                          className="attach-title"
                          onClick={(event) => {
                            event.preventDefault();
                            downloadFile(
                              currentUser.talentId as string,
                              supportFile.fileData.fileId as string,
                              supportFile.fileData.name as string,
                              supportFile.fileData.bucketId as string,
                            );
                          }}
                        >
                          {supportFile.fileData.name}
                        </a>
                      </span>
                    )}
                    {supportFile.fileData && !supportFile.fileData.fileId && (
                      <span className="file-info ml-30">
                        {supportFile.fileData?.name}
                      </span>
                    )}
                  </section>
                </>
              </li>
            ))}
          {supportLinks &&
            supportLinks.map((supportLink: TalentSupportingLink, index) => (
              <li key={index} role="listitem">
                <>
                  <section className="d-flex align-center mt-10">
                    <span className="mt-10" aria-hidden={true}>
                      <Icon width="18" height="18" name={"link-grey"}></Icon>
                    </span>
                    <span className="ml-10">{supportLink.categoryName}</span>
                  </section>
                  <section>
                    <a
                      className="ml-30 supporting-link"
                      href={linkFilter(supportLink.link)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {supportLink.link}
                    </a>
                  </section>
                </>
              </li>
            ))}
        </ul>
      )}
      {supportFilesLength === 0 && supportLinksLength === 0 && (
        <Info value={noResultsLabel} />
      )}
    </>
  );
}
