import { getCountryRefData } from "../../../../api/fetchClient";
import { useFetchData } from "../../../../hooks/useFetchData";

export function useFetchCountryRefData() {
  const {
    data,
    error,
    fetchData,
    resetFetchStatus,
    isError,
    isLoading,
    isSuccess,
  } = useFetchData(getCountryRefData);
  return {
    countryRefData: data,
    countryRefDataError: error,
    isCountryRefDataError: isError,
    iscountryRefDataSuccess: isSuccess,
    isCountryRefDataLoading: isLoading,
    fetchCountryRefData: fetchData,
    resetCountryFetchStatus: resetFetchStatus,
  };
}
