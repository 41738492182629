import {
  NativeButton,
  Overlay,
  Textbox,
  TextBoxAriaRequiredProps,
} from "@rpe-js/marcom-web-components";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { RequestOptions } from "../../../api/apiService";
import useAriaLiveStatus from "../../../hooks/useAriaLiveStatus";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { idGenerator, ModuleName } from "../../../utils/idGenerator";
import Icon from "../IconComponent";
import { getTypeaheadTextBoxId } from "./typeaheadUtils";
import { TypeaheadCssClasses } from "./types";
import { useTypeahead } from "./useTypeahead";

/**
 * Typeahead Component
 *
 * This component is used to render a typeahead input field with suggestions
 *
 * Properties for the Typeahead component.
 *
 * @template T The type of data representing a suggestion.
 *
 * @property {string} id Unique identifier for the component.
 * @property {(query: string, options?: RequestOptions) => Promise} apiEndpoint Function to fetch suggestions from an API.  Takes a query string and optional RequestOptions. Returns a promise resolving to an array of suggestions or undefined.
 * @property {(selection: T | string) => void} onSelect Callback function invoked when a suggestion is selected. Receives the selected suggestion object or the custom input string.
 * @property {(suggestion: T) => string} getSuggestionLabel Function to extract the display label from a suggestion object.
 * @property {(inputValue: string | null) => void} [onInputChange] Callback function invoked when the input value changes. Receives the current input value or null.
 * @property {(inputValue: string | null) => void} [onBlur] Callback function invoked when the input loses focus. Receives the current input value or null.
 * @property {() => void} [onReset] Callback function invoked when the component is reset.
 * @property {boolean} [disableSelectedItem] Whether to disable the selected item in the dropdown.
 * @property {{ key: string; items: Array> }} [selectedItems]  An object containing the key and an array of selected items.  Each item is a record with string keys and values.
 * @property {boolean} [showSearchIcon] Whether to display a search icon before each suggestion.
 * @property {string} [suggestionPlaceholder] Placeholder text displayed at the top of the suggestion dropdown.
 * @property {boolean} [strict] Enforces strict mode, only allowing selections from the provided suggestions.
 * @property {boolean} [highlightMatches] Enables highlighting of matching text within suggestions.
 * @property {number} [minChars] Minimum number of characters before triggering suggestions.
 * @property {string} [label] Label for the input text box. Choose between `label` or `placeholder`.
 * @property {string} [placeholder] Placeholder text for the input field. Choose between `label` or `placeholder`.
 * @property {string} [initialValue] Initial value for the input field.
 * @property {boolean} [hideLabel] Whether to hide the label.
 * @property {boolean} [removeOnSelect] Whether to remove the selected item from the dropdown after selection.
 * @property {TypeaheadCssClasses} [classNames] Custom CSS class names for styling the component.
 * @property {string | null} [errorMessage] Error message to display.
 * @property {string} [errorA11y] Accessible error message for screen readers.
 * @property {RequestOptions} [apiOptions] Options for the API request.
 * @property {boolean} [isInputRequired] Whether the input is required. Default is set to true.
 * @property {{ onKeyDown?: (evt: React.KeyboardEvent, preventDefault?: boolean) => boolean; onPaste?: (evt: React.ClipboardEvent, preventDefault?: boolean) => boolean; }} [validators]  Validation functions for keydown and paste events.  These functions should return `true` if the input is valid, and `false` otherwise.  They can optionally take a `preventDefault` argument which, when set to `true`, will prevent the default browser behavior for the event.
 * @property {string} [elementToFocusOnClose] If mobile , element to restore the focus back to when overlay closes.
 */

type TypeaheadProps<T> = TextBoxAriaRequiredProps & {
  id: string;
  elementToFocusOnClose: string;
  apiEndpoint: (
    query: string,
    options?: RequestOptions,
  ) => Promise<T[] | undefined>; // Function to fetch suggestions from an API
  onSelect: (selection: T | string) => void; // Function to handle selection of a suggestion or custom input
  getSuggestionLabel: (suggestion: T) => string; // Function to get the display label for a suggestion
  onInputChange?: (inputValue: string | null) => void; // Callback to notify parent of text input changes
  onBlur?: (inputValue: string | null) => void;
  onReset?: () => void;
  disableSelectedItem?: boolean;
  selectedItems?: { key: string; items: Array<Record<string, string>> };
  showSearchIcon?: boolean; // Whether to show a search icon before each suggestion
  suggestionPlaceholder?: string; // Placeholder text to display at the top of the dropdown
  strict?: boolean; // Whether to enforce strict mode (only allow selection from the list)
  highlightMatches?: boolean; // Enable or disable text highlighting in suggestions
  minChars?: number; // Minimum characters before showing suggestions
  label?: string; // Label string for input text box , choose between label or placeholder
  placeholder?: string; // Placeholder for input
  initialValue?: string;
  hideLabel?: boolean;
  removeOnSelect?: boolean;
  classNames?: TypeaheadCssClasses; // Custom classNames for different parts of the component
  errorMessage?: string | null;
  errorA11y?: string;
  apiOptions?: RequestOptions;
  isInputRequired?: boolean;
  moduleName?: ModuleName;
  validators?: {
    onKeyDown?: (
      // Validate Text Input on Keydown
      evt: React.KeyboardEvent<HTMLInputElement>,
      preventDefault?: boolean,
    ) => boolean;
    onPaste?: (
      // Validate Text Input on paste
      evt: React.ClipboardEvent<HTMLInputElement>,
      preventDefault?: boolean,
    ) => boolean;
  };
  resetA11y?: string;
};

export const typeaheadstandardCssClasses: TypeaheadCssClasses = {
  container: "typeahead-container",
  inputWrapper: "typeahead-input-wrapper",
  list: "typeahead-list",
  listItem: "typeahead-list-item",
  button: "typeahead-button",
  highlightedItem: "typeahead-highlighted-item",
  suggestionPlaceholder: "typeahead-suggestion-placeholder",
  suggestionText: "typeahead-suggestion-text",
  highlight: "typeahead-highlighted-text",
  suggestionIcon: "suggestion-icon",
};

const TypeaheadComponent = <T,>({
  apiEndpoint,
  onSelect,
  getSuggestionLabel,
  onInputChange,
  onBlur,
  onReset,
  initialValue,
  disableSelectedItem = false,
  selectedItems,
  showSearchIcon = false, // Default to not showing the icon
  suggestionPlaceholder = "Results", // Default placeholder for suggestions dropdown
  strict = true,
  hideLabel = false,
  removeOnSelect = false,
  highlightMatches = false, // Default to no highlighting
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  minChars = 2,
  label,
  placeholder,
  classNames = {},
  errorMessage = "",
  errorA11y,
  id = "typeahead",
  validators,
  apiOptions,
  isInputRequired = true, // Default is true
  moduleName,
  elementToFocusOnClose,
  resetA11y,
}: TypeaheadProps<T>) => {
  const [inputValue, setInputValue] = useState(
    initialValue ? initialValue : "",
  ); // Input field value (displayed)
  const [query, setQuery] = useState(""); // Query used solely for fetching suggestions
  const [suggestions, setSuggestions] = useState<T[]>([]); // Suggestions fetched from the API
  const [isOpen, setIsOpen] = useState(false); // Controls whether suggestions dropdown is open
  const [highlightedIndex, setHighlightedIndex] = useState(-1); // Tracks highlighted suggestion
  const [hasSelected, setHasSelected] = useState(false); // Tracks if a suggestion was selected
  const inputRef = useRef<HTMLInputElement>(null); // Ref for the input
  const containerRef = useRef<HTMLDivElement>(null); // Ref for the whole Typeahead container
  const { renderSuggestionWithHighlight, handleKeyDown } = useTypeahead<T>();
  const suggestionsRef = useRef<HTMLUListElement>(null);
  const liveRegionSpanRef = useRef<HTMLSpanElement>(null);
  const isMobile = useIsMobile();
  const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(false);
  const { t } = useIntlMessage();
  const { announceAriaMessage } = useAriaLiveStatus(liveRegionSpanRef);
  const queryRef = useRef<string | null>(null);
  const inputAllyId = moduleName
    ? idGenerator(moduleName, "instructions").generateId()
    : "typeahead-instructions";
  // Fetch suggestions based on query, but skip if `hasSelected` is true
  useEffect(() => {
    if (!query || hasSelected) {
      setIsOpen(false);
      setHasSelected(false); // Reset selection flag after skipping API call
      return;
    }

    const fetchSuggestions = async () => {
      try {
        if (query.length >= minChars) {
          const fetchedSuggestions = (await apiEndpoint(
            query,
            apiOptions,
          )) as T[];
          // query holds old value so we need to use queryRef which will hold the latest updated value
          if (queryRef.current) {
            setSuggestions(fetchedSuggestions);

            announceAriaMessage(
              (fetchedSuggestions.length > 0
                ? t("jobsite.search.noSuggestionsUpDown", {
                    number: fetchedSuggestions.length,
                  })
                : t("jobsite.search.noSuggestions")) as string,
            );
            setIsOpen(fetchedSuggestions.length > 0);
          }
        } else {
          setSuggestions([]);
          setIsOpen(false);
        }
      } catch (error) {
        setSuggestions([]);
        setIsOpen(false);
      }
    };

    const debounceFetch = setTimeout(fetchSuggestions, 300); // Debounce 300ms

    return () => clearTimeout(debounceFetch); // Cleanup debounce on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, apiEndpoint]);

  // Close dropdown when clicking outside or pressing "Tab", "Escape", or other relevant keys
  useEffect(() => {
    const container = containerRef?.current;
    const handleClickOutside = (event: MouseEvent) => {
      if (container && !container.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    // Close dropdown when voiceover virtual focus moves out of the container
    const handleFocusOut = () => {
      // setTimeout delay to let voiceover finish its focus transition
      setTimeout(() => {
        const activeEl = document.activeElement;
        // if activeEl is outside container close the dropdown
        if (container && !container.contains(activeEl)) {
          setIsOpen(false);
        }
      }, 0);
    };

    document.addEventListener("mousedown", handleClickOutside);

    container?.addEventListener("focusout", handleFocusOut);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      container?.removeEventListener("focusout", handleFocusOut);
    };
  }, []);

  // Handle clearing input field
  const handleClearInput = useCallback(() => {
    setInputValue(""); // Clear the input value
    setQuery(""); // Clear the query for fetching suggestions
    queryRef.current = null;
    setHighlightedIndex(-1); // Reset highlighted index
    setSuggestions([]);
    if (onReset) {
      onReset();
    }
    handleInputReFocusAndCloseSuggestions();
  }, [onReset]);

  const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    if (validators && validators.onPaste && !validators.onPaste(e, false)) {
      return;
    }
  };

  // Handle suggestion selection (via mouse or keyboard)
  const handleSuggestionSelect = useCallback(
    (suggestion: T) => {
      const suggestionLabel = getSuggestionLabel(suggestion);
      if (removeOnSelect) {
        setInputValue("");
        setSuggestions([]);
      } else {
        setInputValue(suggestionLabel); // Update displayed input field value
      }
      setQuery(""); // Clear query to stop fetching
      queryRef.current = null;
      setHasSelected(true); // Mark selection to skip next fetch
      setIsMobileFiltersOpen(false);
      onSelect(suggestion); // Notify parent of selected suggestion

      handleInputReFocusAndCloseSuggestions();
    },
    [removeOnSelect, onSelect, getSuggestionLabel],
  );

  // Handle custom input submission
  const handleCustomInputSelect = useCallback(
    (input: string) => {
      if (!input.trim()) return; // Ignore empty input

      onSelect(input); // Notify parent with the custom input
      setHasSelected(false); // Reset `hasSelected` so future submissions work
      handleClearInput();
      handleInputReFocusAndCloseSuggestions();
    },
    [handleClearInput, onSelect],
  );

  // Handle input change and update query for fetching suggestions
  const handleInputChange = (value: string) => {
    setInputValue(value); // Update displayed input field value
    const valueWithNoTrailingSpaces = value.trim();
    // Only update query if there are non-whitespace characters
    if (valueWithNoTrailingSpaces) {
      setQuery(valueWithNoTrailingSpaces); // Trim spaces for API call but keep spaces in display
      queryRef.current = valueWithNoTrailingSpaces;
      setHasSelected(false); // Reset `hasSelected` to enable future custom submissions
    } else {
      setQuery(""); // Clear query to prevent empty fetch
      queryRef.current = null;
      setIsOpen(false); // Close the dropdown on whitespace-only input
    }

    if (onInputChange) {
      onInputChange(value); // Notify parent component of input change
    }
  };

  // Handle blur to enforce strict mode only when enabled
  const handleBlur = () => {
    // Currently there is no usage of this behaviour for strict mode but keeping this code to evaluate further if its required
    // if (strict) {
    //   // Check if the inputValue matches any suggestion
    //   const isValid = suggestions.some(
    //     (suggestion) => getSuggestionLabel(suggestion) === inputValue,
    //   );

    //   // Reset the input only if strict mode is enabled and the input does not match any suggestion
    //   if (!isValid) {
    //     setInputValue(""); // Reset input value if no match is found
    //     setQuery(""); // Reset the query
    //   }
    // }
    if (onBlur) {
      onBlur(inputValue);
    }
  };

  // Open the dropdown again on focus, if there are any suggestions (only for the initial focus)
  const handleInputFocus = () => {
    if (suggestions.length > 0) {
      setIsOpen(true);
    }
  };

  // Refocus on the dropdown, and hide the suggestions (after selection, custom-input, and clear)
  const handleInputReFocusAndCloseSuggestions = () => {
    // this is extra step prevents flickering during VoiceOver navigation
    // If this step is not done Voiceover focus navigation moves out of the next logical element
    inputRef.current?.focus();
    setIsOpen(false);
    setTimeout(() => {
      inputRef.current?.focus();
      setIsOpen(false);
    }, 0);
  };

  // Helper to render suggestion with highlighted text
  const renderSuggestionsWithHighlight = (suggestion: T) => {
    return renderSuggestionWithHighlight(
      getSuggestionLabel,
      suggestion,
      highlightMatches,
      query,
      classNames,
    );
  };

  // Handle key presses for keyboard navigation and Enter to submit custom input or select suggestion
  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        validators &&
        validators.onKeyDown &&
        !validators.onKeyDown(e, true)
      ) {
        return;
      }
      handleKeyDown({
        e,
        isOpen,
        strict,
        suggestions,
        suggestionsRef,
        inputRef,
        highlightedIndex,
        inputValue,
        query,
        setIsOpen,
        handleCustomInputSelect,
        handleSuggestionSelect,
        setHighlightedIndex,
        setInputValue,
        getSuggestionLabel,
      });
    },
    [
      validators,
      strict,
      suggestions,
      isOpen,
      inputValue,
      query,
      highlightedIndex,
      handleCustomInputSelect,
      handleKeyDown,
      handleSuggestionSelect,
      getSuggestionLabel,
    ],
  );
  const searchTextInputId = getTypeaheadTextBoxId(id);

  const searchInput = (isMainInput: boolean) => {
    return (
      <>
        <Textbox
          id={searchTextInputId}
          required={isInputRequired}
          ref={inputRef}
          search={true} // TODO make this dynamic
          value={inputValue} // Displayed input value
          hideLabel={hideLabel}
          label={label as string} // OneOf label or placeHolder needs to be passed.
          placeholder={placeholder as string}
          onValueChange={handleInputChange}
          onKeyDown={onKeyDown}
          onPaste={handleOnPaste}
          onFocus={() =>
            isMainInput && isMobile
              ? setIsMobileFiltersOpen(true)
              : handleInputFocus()
          } // Handle focus event to reopen dropdown
          onBlur={handleBlur} // Enforce strict mode on blur if enabled
          aria-autocomplete="list"
          onReset={handleClearInput}
          aria-controls={`${id}-suggestion-list`}
          aria-activedescendant={
            highlightedIndex >= 0
              ? `${id}-suggestion-${highlightedIndex}`
              : undefined
          }
          error={errorMessage && !isEmpty(errorMessage) ? errorMessage : false}
          errorA11y={errorA11y}
          resetA11y={
            resetA11y ? resetA11y : (t("jobsite.common.clearField") as string)
          }
          aria-haspopup="listbox"
          role="textbox"
          aria-describedby={`${id}-${inputAllyId}`}
        />
        <span className="a11y" id={`${id}-${inputAllyId}`} aria-hidden="true">
          {t("jobsite.search.typeaheadHelpText") as string}
        </span>
      </>
    );
  };

  const renderList = () => {
    return (
      <ul
        id={`${id}-suggestion-list`}
        role="listbox"
        className={classNames.list}
        onMouseLeave={() => setHighlightedIndex(-1)}
        ref={suggestionsRef}
      >
        {/* Display the placeholder at the top of the dropdown */}
        <li className={classNames.suggestionPlaceholder} role="presentation">
          {suggestionPlaceholder}
        </li>
        {suggestions.map((suggestion, index) => {
          let disabledClass = "";
          if (disableSelectedItem) {
            const key = selectedItems?.key;
            const items = selectedItems?.items;
            if (items && items.length > 0 && key && suggestion) {
              for (let i = 0; i < items.length; i++) {
                if (
                  items[i].selected &&
                  items[i][key] == (suggestion as any)[key]
                ) {
                  disabledClass = "label-grey";
                  break;
                }
              }
            }
          }
          return (
            <li
              key={index}
              id={`${id}-suggestion-${index}`}
              role="option"
              aria-selected={index === highlightedIndex}
              className={
                index === highlightedIndex
                  ? classNames.highlightedItem
                  : classNames.listItem
              }
              onMouseOver={() => setHighlightedIndex(index)}
              onMouseDown={() => {
                handleSuggestionSelect(suggestion);
              }} // Use onMouseDown to avoid premature closing
            >
              <NativeButton
                id={`${id}-suggestion-${index}-button`}
                type="button"
                className={classNames.button}
              >
                {showSearchIcon && (
                  <span className={classNames.suggestionIcon}>
                    <Icon
                      name="search-grey"
                      classes={"search-icon-background-suggetions"}
                    />
                  </span>
                )}
                <span
                  className={`${classNames.suggestionText} ${disabledClass}`}
                >
                  {renderSuggestionsWithHighlight(suggestion)}
                </span>
              </NativeButton>
            </li>
          );
        })}
      </ul>
    );
  };
  return (
    <>
      <div ref={containerRef} className={classNames.container} id={id}>
        {searchInput(true)}
        {isMobile && isMobileFiltersOpen && (
          <Overlay
            id={`${id}-overlay`}
            elementIdToFocus={elementToFocusOnClose}
            visible={isMobileFiltersOpen}
            onClose={() => setIsMobileFiltersOpen(false)}
            isFullscreen={true}
            noCloseButton={false}
            disableEsc={false}
            classes={{ content: "px-15" }}
            stickyClose={true}
            closeButtonAttrs={{
              ariaLabel: t("jobsite.common.cancel") as string,
              stickyClose: true,
              alignStart: true,
            }}
          >
            <>
              {searchInput(false)}
              {suggestions.length > 0 && isOpen && renderList()}
            </>
          </Overlay>
        )}
        <div>{isOpen && !isMobile && renderList()}</div>
      </div>
      <span
        ref={liveRegionSpanRef}
        role="status"
        aria-live="polite"
        className="a11y"
      />
    </>
  );
};

export default TypeaheadComponent;
