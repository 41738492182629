import React from "react";
import HtmlRenderer from "../../../components/base/HtmlRenderer";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { useMyApplicationContext } from "./context/context";
import { ApplicationAllowedSteps } from "./context/types";

interface I18nStrConfig {
  header: string;
  description?: string;
  subDescription?: string;
}
function geti18nStringForStep(step: ApplicationAllowedSteps): I18nStrConfig {
  const obj: I18nStrConfig = {
    header: "",
  };

  if (step === "selfDisclosure") {
    obj.header = "jobsite.profile.myApplication.selfDisclosure";
    obj.description = "jobsite.profile.myApplication.forUsResidents";
    obj.subDescription = "jobsite.profile.myApplication.subDesc";
  }
  if (step === "application") {
    obj.header = "jobsite.profile.myApplication.application";
  }

  if (step === "informationRelease") {
    obj.header = "jobsite.profile.myApplication.infoRelease";
    obj.description = "jobsite.profile.myApplication.readCarefully";
    obj.subDescription = "jobsite.profile.myApplication.infoReleaseMessage";
  }

  if (step === "reviewSubmit") {
    obj.header = "jobsite.profile.myApplication.reviewInfoTitle";
  }

  return obj;
}

export function ApplicationHeader() {
  const { state } = useMyApplicationContext();
  const { page, currentStep } = state;
  const { stepNames, stepMapping } = page;
  const { t } = useIntlMessage();
  const i18nStr = geti18nStringForStep(currentStep as ApplicationAllowedSteps);
  const start = stepMapping[currentStep as ApplicationAllowedSteps],
    end = stepNames.length;
  return (
    <div className="mt-20 mb-20 text-center">
      <h2 className="t-eyebrow-super mb-40">
        {t(i18nStr.header)}
        <span className="a11y">
          {t("jobsite.profile.myApplication.stepof", {
            startValue: start,
            endValue: end,
          })}
        </span>
      </h2>
      {i18nStr.description && (
        <h3 className="t-eyebrow-reduced">{t(i18nStr.description)}</h3>
      )}
      {i18nStr.subDescription && (
        <HtmlRenderer
          initialContent={t(i18nStr.subDescription) as string}
          htmlTag="p"
          classes="t-body-reduced  text-align-start mt-10"
        />
      )}
    </div>
  );
}
