import React, { createContext, ReactNode, useCallback, useState } from "react";

export interface GlobalContextType {
  globalMessages: GlobalMessage;
  updateGlobalMessage: (key: string, value: any) => void;
  deleteMessage: (key: string) => void;
}

export const GlobalContext = createContext<GlobalContextType | undefined>(
  undefined,
);

interface GlobalMessage {
  [key: string]: {
    [key: string]: string;
  };
}

interface GlobalContextProviderProps {
  children: ReactNode;
}

export const GlobalContextProvider = ({
  children,
}: GlobalContextProviderProps) => {
  const [globalMessages, setGlobalMessages] = useState<GlobalMessage>({});
  const updateGlobalMessage = useCallback(
    (key: string, value: any) => {
      delete globalMessages[key];
      setGlobalMessages({
        ...globalMessages,
        [key]: { ...value },
      });
    },
    [globalMessages],
  );

  const deleteMessage = useCallback(
    (key: string) => {
      delete globalMessages[key];
    },
    [globalMessages],
  );

  return (
    <GlobalContext.Provider
      value={{ globalMessages, updateGlobalMessage, deleteMessage }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
