import React, { useContext } from "react";
import { USER_TYPE } from "../../app.constants";
import AppContext from "../../AppContext";
import { useCurrentUserContext } from "../../CurrentUserContext";
import { redirectToPath } from "../../util";
import { ERPProvider } from "./context/ERPProvider";
import { ContractorStep } from "./ContractorStep";
import { ErpStepWrapper } from "./ErpStepWrapper";

export function ERP() {
  const { appUIState } = useContext(AppContext);
  const { locale, appBasePathPrefix } = appUIState.appData;
  const { currentUser } = useCurrentUserContext();

  if (currentUser.userType === USER_TYPE.USER_OTHERS) {
    redirectToPath(appBasePathPrefix, `app/${locale}/profile/info`);
  }

  return (
    <>
      {currentUser.userType === USER_TYPE.USER_INTERNAL ? (
        <ERPProvider>
          <ErpStepWrapper></ErpStepWrapper>
        </ERPProvider>
      ) : (
        <ContractorStep />
      )}
    </>
  );
}
