import React, { createContext, useContext } from "react";
import { ProfileAction } from "./ActionTypes";
import { ProfileState } from "./ProfileState";

// Create a context
export const ProfileContext = createContext<
  | {
      state: ProfileState;
      dispatch: React.Dispatch<ProfileAction>;
    }
  | undefined
>(undefined);

// Custom hook to use the ProfileContext
export const useProfileContext = (): {
  state: ProfileState;
  dispatch: React.Dispatch<ProfileAction>;
} => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error("useProfileContext must be used within an ProfileProvider");
  }
  return context;
};
