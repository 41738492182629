import { ProgressIndicatorLoader, Toggle } from "@rpe-js/marcom-web-components";
import React, { useCallback, useContext, useEffect, useState } from "react";
import AppContext from "../../../AppContext";

import { find } from "lodash";
import { BaseRefData } from "../../../../shared/types/refData";
import {
  Contact,
  PrivacyPolicy as PrivacyPolicyType,
  Talent,
} from "../../../../shared/types/talent/talent";
import { getCountrySpecificPrivacyPolicy } from "../../../api/fetchClient";
import { ADDRESS_PREFERRED, COUNTRY_CHINA } from "../../../app.constants";
import HtmlRenderer from "../../../components/base/HtmlRenderer";
import { Info } from "../../../components/base/Info";
import { Label } from "../../../components/base/Label";
import PrivacyPolicy from "../../../components/feature/PrivacyPolicy";
import { SaveCancelAction } from "../../../components/feature/saveAndCancel";
import { useAppAlertContext } from "../../../contexts/AppAlert";
import { useCurrentUserContext } from "../../../CurrentUserContext";
import useFocusFirstErrorField from "../../../hooks/useFocusFirstErrorField";
import useIntlMessage from "../../../hooks/useIntlMessage";
import { idGenerator } from "../../../utils/idGenerator";
import {
  updateContactInformation,
  updatePreferredEmail,
} from "../context/Actions";
import { useProfileContext } from "../context/ProfileContext";
import { useEmailPhone } from "../hooks/useEmailPhone";
import { useValidateContactForm } from "../hooks/useValidateContactForm";
import { ProfileFormField } from "../types";
import { ContactView } from "./components/contactView";
import {
  getAddressFormForCountry,
  getAddressFromConfig,
  getConfigFromAddress,
  getISOCountryID,
} from "./components/utils";
import { EditAddress } from "./editAddress";
import { EditEmailAndPhoneNumber } from "./editEmailAndPhoneNumber";
import { useFetchCountryRefData } from "./hooks/useFetchCountryRefData";
import { useUpdateTalentProfile } from "./hooks/useUpdateTalentProfile";
import {
  getContactFromConfig,
  getDisplayPronoun,
  getFormConfigFromContact,
  getPreferredEmailPhoneFromConfig as updatePreferredEmailPhoneFromConfig,
} from "./nameFormUtils";
import { NameSection } from "./nameSection";
import { ProfileSection } from "./profileSection";
import { AddressFormConfig, ContactNameForm } from "./types";

export const ContactInformationSection = () => {
  const [isEdit, setEdit] = useState<boolean>(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const profileContext = useProfileContext();
  const { state, dispatch } = profileContext;
  const { config, dispatch: dispatchToCurrentUserContext } =
    useCurrentUserContext();
  const { workday: workDayLinks } = config;
  const { updateAlert, deleteAlert } = useAppAlertContext();
  const profileIdGenerator = idGenerator("profile", "contact");
  const { setFocusFirstErrorField } = useFocusFirstErrorField(
    profileIdGenerator.generateId(),
  );
  const {
    addresses = [],
    contact,
    kanjiIndicator,
    localeNameIndicator,
    displayPronouns,
    genderPronoun,
    privacyPolicy,
  } = state;
  const { appUIState } = useContext(AppContext);
  const { locale, isInternal, isExternal, talentId, countryCode } =
    appUIState.appData;
  const [displayPrivacyPolicy, setDisplayPrivacyPolicy] = useState(false);
  const [privacyPolicyPayload, setPrivacyPolicyPayload] =
    useState<PrivacyPolicyType | null>(null);

  const { validateNameSection, validateAddressForm } = useValidateContactForm();

  const [localDisplayPronoun, setLocalDisplayPronoun] = useState<boolean>(
    displayPronouns || false,
  );

  const {
    updateContactSection,
    resetTalentUpdate,
    isTalentUpdateInProgress,
    isTalentUpdateSuccess,
    isTalentUpdateError,
  } = useUpdateTalentProfile(talentId as string);

  const [addressForm, setAddressForm] = useState<AddressFormConfig[]>(
    getConfigFromAddress(addresses),
  );

  const [contactNameForm, setContactNameForm] = useState<ContactNameForm>(
    getFormConfigFromContact(
      "profile",
      contact,
      kanjiIndicator,
      localeNameIndicator,
    ),
  );

  const {
    emailPhoneForm,
    onOtherPhoneOrEmailAdd,
    onOtherPhoneOrEmailDelete,
    onOtherPhoneOrEmailUpdate,
    onPreferredEmailUpdate,
    onPreferredPhoneUpdate,
    validateEmailPhone,
    resetEmailPhoneForm,
  } = useEmailPhone(contact as Contact, isExternal);

  const { t } = useIntlMessage();

  const {
    countryRefData,
    isCountryRefDataLoading,
    iscountryRefDataSuccess,
    isCountryRefDataError,
    fetchCountryRefData,
  } = useFetchCountryRefData();

  useEffect(() => {
    if (
      !countryRefData &&
      !isCountryRefDataLoading &&
      !iscountryRefDataSuccess &&
      !isCountryRefDataError
    ) {
      fetchCountryRefData();
    }
  }, [
    countryRefData,
    isCountryRefDataLoading,
    iscountryRefDataSuccess,
    fetchCountryRefData,
    isCountryRefDataError,
  ]);

  const setUpdatedAddressForm = useCallback(
    (index: number, refData: BaseRefData) => {
      const configForm = getAddressFormForCountry(refData);
      const updated = [...addressForm];
      updated[index] = configForm;
      setAddressForm(updated);
    },
    [addressForm],
  );

  const onCountryChange = useCallback(
    (key: number, refData: BaseRefData) => {
      const index = addressForm.findIndex((p) => p.key === key);
      if (index === 0 && refData.id === COUNTRY_CHINA) {
        getCountrySpecificPrivacyPolicy(talentId as string, COUNTRY_CHINA).then(
          (res) => {
            if (!res?.privacyPolicyAccepted) {
              setDisplayPrivacyPolicy(true);
            } else {
              setUpdatedAddressForm(index, refData);
            }
          },
        );
        return;
      }
      setUpdatedAddressForm(index, refData);
    },
    [addressForm, setUpdatedAddressForm, talentId],
  );

  const onAddressUpdate = useCallback(
    (
      addressIndex: number,
      rowIndex: number,
      columnIndex: number,
      value: string,
    ) => {
      const addressFormField =
        addressForm[addressIndex].fields[rowIndex][columnIndex];
      const updated = [...addressForm];
      updated[addressIndex].fields[rowIndex][columnIndex] = {
        ...addressFormField,
        value: value,
      };
      if (!updated[addressIndex].fields[rowIndex][columnIndex]?.optional) {
        updated[addressIndex].fields[rowIndex][columnIndex].error = value
          ? false
          : true;
      }
      setAddressForm(updated);
    },
    [addressForm],
  );

  const onAddAddress = useCallback(() => {
    setAddressForm((prevValue: AddressFormConfig[]) => [
      ...prevValue,
      getAddressFormForCountry(),
    ]);
  }, []);

  const onRemoveAddress = useCallback((key: number) => {
    setAddressForm(function (prevState: AddressFormConfig[]) {
      const updated = prevState.filter((p) => p.key !== key);
      return [...updated];
    });
  }, []);

  const onCancel = useCallback(() => {
    setSaveClicked(false);
    setEdit(false);
    setContactNameForm(
      getFormConfigFromContact(
        "profile",
        contact,
        kanjiIndicator,
        localeNameIndicator,
      ),
    );
    setAddressForm(getConfigFromAddress(addresses));
    resetEmailPhoneForm();
  }, [
    addresses,
    contact,
    resetEmailPhoneForm,
    kanjiIndicator,
    localeNameIndicator,
  ]);

  const onNameFormFieldUpdate = useCallback(
    (formField: ProfileFormField, value: string) => {
      const updated = { ...contactNameForm };
      if (formField.name === "firstName") {
        updated.firstName = { ...formField, value: value };
        if (updated.firstName.required) {
          updated.firstName.error = !value ? true : false;
          updated.firstName.errori18nMsgKey = !value
            ? updated.firstName.requiredi18nMsgKey
            : "";
        }
      }

      if (formField.name === "lastName") {
        updated.lastName = { ...formField, value: value };
        if (updated.lastName.required) {
          updated.lastName.error = !value ? true : false;
          updated.lastName.errori18nMsgKey = !value
            ? updated.lastName.requiredi18nMsgKey
            : "";
        }
      }

      if (formField.name === "preferredName") {
        updated.preferredName = { ...formField, value: value };
        if (updated.preferredName.required) {
          updated.preferredName.error = !value ? true : false;
          updated.preferredName.errori18nMsgKey = !value
            ? updated.preferredName.requiredi18nMsgKey
            : "";
        }
      }

      if (formField.name === "kanjiFirstName") {
        updated.kanjiFirstName = { ...formField, value: value };
        if (updated.kanjiFirstName.required) {
          updated.kanjiFirstName.error = !value ? true : false;
          updated.kanjiFirstName.errori18nMsgKey = !value
            ? updated.kanjiFirstName.requiredi18nMsgKey
            : "";
        }
      }

      if (formField.name === "kanjiLastName") {
        updated.kanjiLastName = { ...formField, value: value };
        if (updated.kanjiLastName.required) {
          updated.kanjiLastName.error = !value ? true : false;
          updated.kanjiLastName.errori18nMsgKey = !value
            ? updated.kanjiLastName.requiredi18nMsgKey
            : "";
        }
      }

      if (formField.name === "localFirstName") {
        updated.localFirstName = { ...formField, value: value };
        if (updated.localFirstName.required) {
          updated.localFirstName.error = !value ? true : false;
          updated.localFirstName.errori18nMsgKey = !value
            ? updated.localFirstName.requiredi18nMsgKey
            : "";
        }
      }

      if (formField.name === "localLastName") {
        updated.localLastName = { ...formField, value: value };
        if (updated.localLastName.required) {
          updated.localLastName.error = !value ? true : false;
          updated.localLastName.errori18nMsgKey = !value
            ? updated.localLastName.requiredi18nMsgKey
            : "";
        }
      }
      setContactNameForm(updated);
    },
    [contactNameForm],
  );

  const validateContactInfo = useCallback((): boolean => {
    const validatedContactForm = validateNameSection(
      contactNameForm,
      kanjiIndicator as boolean,
      localeNameIndicator as boolean,
    );
    const validatedAddressForm = validateAddressForm(addressForm);
    if (isExternal) {
      setContactNameForm(validatedContactForm.updatedContactForm);
      setAddressForm(validatedAddressForm.updatedAddressForm);
    }

    const validationHasError = validateEmailPhone();
    return isExternal
      ? validatedContactForm.hasError ||
          validatedAddressForm.hasError ||
          validationHasError
      : validationHasError;
  }, [
    addressForm,
    contactNameForm,
    isExternal,
    kanjiIndicator,
    localeNameIndicator,
    validateAddressForm,
    validateEmailPhone,
    validateNameSection,
  ]);

  const getUpdatedTalent = useCallback((): Partial<Talent> => {
    const addresses = getAddressFromConfig(addressForm);
    const preferredAddressIndex = addresses.findIndex(
      (data) => data.typeID === ADDRESS_PREFERRED,
    );
    if (
      !isInternal &&
      preferredAddressIndex > -1 &&
      !addresses[preferredAddressIndex].countryName
    ) {
      addresses[preferredAddressIndex].countryName = find(countryRefData, {
        id: addresses[preferredAddressIndex].countryID,
      })?.name;
    }
    const updatedContact: Contact = getContactFromConfig(
      contactNameForm,
      isInternal,
      kanjiIndicator === true,
      localeNameIndicator === true,
      contact,
    );
    updatePreferredEmailPhoneFromConfig(
      emailPhoneForm,
      isInternal,
      updatedContact,
    );

    const partialTalent: Partial<Talent> = {
      contact: updatedContact,
      addresses: addresses,
    };
    if (isInternal) {
      partialTalent.displayPronouns = localDisplayPronoun;
    }
    return partialTalent;
  }, [
    addressForm,
    contact,
    contactNameForm,
    isInternal,
    emailPhoneForm,
    kanjiIndicator,
    localeNameIndicator,
    localDisplayPronoun,
    countryRefData,
  ]);

  const onPreferredEmailUpdateSuccess = (
    preferredEmail: ProfileFormField,
    emailId: string,
  ) => {
    onPreferredEmailUpdate(preferredEmail, emailId);
    dispatch(updatePreferredEmail(emailId));
  };

  const onPrivacyPolicyAccept = (data: PrivacyPolicyType) => {
    setPrivacyPolicyPayload(data);
    setDisplayPrivacyPolicy(false);
    const chinaRefData =
      countryRefData?.filter((r) => r.id === COUNTRY_CHINA) || [];
    setUpdatedAddressForm(0, chinaRefData[0]);
  };

  const onClosePrivacyPolicyModal = useCallback(() => {
    setDisplayPrivacyPolicy(false);
  }, []);

  const onSave = useCallback(() => {
    deleteAlert();
    setSaveClicked(true);
    const hasError = validateContactInfo();
    if (hasError) {
      setFocusFirstErrorField(true);
    }
    if (!hasError) {
      const partialTalent = getUpdatedTalent();
      if (privacyPolicyPayload) {
        partialTalent.privacyPolicy = privacyPolicyPayload;
      }
      updateContactSection(partialTalent);
    }
  }, [
    deleteAlert,
    validateContactInfo,
    getUpdatedTalent,
    privacyPolicyPayload,
    updateContactSection,
    setFocusFirstErrorField,
  ]);

  useEffect(() => {
    if (!isTalentUpdateInProgress && isTalentUpdateSuccess) {
      const partialTalent = getUpdatedTalent();
      dispatch(
        updateContactInformation({
          contact: partialTalent.contact,
          addresses: partialTalent.addresses?.filter(
            (address) => address.countryID,
          ),
          displayPronouns: partialTalent.displayPronouns,
          privacyPolicy: privacyPolicyPayload,
        }),
      );
      dispatchToCurrentUserContext({
        type: "UPDATE_USER_CONTACT_INFO",
        payload: { contact: partialTalent.contact as Contact },
      });
      setPrivacyPolicyPayload(null);
      resetTalentUpdate();
      setSaveClicked(false);
      setEdit(false);
    }
    if (!isTalentUpdateInProgress && isTalentUpdateError) {
      updateAlert(false);
      resetTalentUpdate();
    }
  }, [
    getUpdatedTalent,
    dispatch,
    dispatchToCurrentUserContext,
    resetTalentUpdate,
    isTalentUpdateInProgress,
    isTalentUpdateSuccess,
    privacyPolicyPayload,
    isTalentUpdateError,
    updateAlert,
    t,
  ]);

  useEffect(() => {
    if (!addressForm || addressForm.length === 0) {
      const form: AddressFormConfig = getAddressFormForCountry();
      if (isExternal) {
        form.country.value = getISOCountryID(countryCode?.country || "USA");
      }
      setAddressForm([form]);
    }
  }, [addressForm, countryCode?.country, isExternal]);

  return (
    <ProfileSection
      isEdit={isEdit}
      i18nTitle="jobsite.common.contactInfo"
      onEdit={() => setEdit(true)}
      id={profileIdGenerator.generateId()}
      sectionName="contact"
    >
      {(isCountryRefDataLoading || isTalentUpdateInProgress) && (
        <ProgressIndicatorLoader showLoading={true} />
      )}
      {/* {isCountryRefDataError && (
        <AlertWrapper
          message="Error while processing request, try again later."
          onRemove={() => {}}
        />
      )} */}
      {!isCountryRefDataLoading && countryRefData && isEdit && (
        <div className="column">
          <NameSection
            workDayLinks={config?.workday}
            isEdit={isEdit}
            isInternal={isInternal}
            locale={locale}
            contactInformation={profileContext?.state?.contact}
            contactForm={contactNameForm}
            displayKanji={profileContext?.state?.kanjiIndicator === true}
            displayLocal={profileContext?.state?.localeNameIndicator === true}
            onFormFieldChange={onNameFormFieldUpdate}
            displayPronouns={displayPronouns}
            genderPronoun={genderPronoun}
            showErrors={saveClicked}
          />
          {isExternal && (
            <>
              <EditEmailAndPhoneNumber
                form={emailPhoneForm}
                showErrors={saveClicked}
                onOtherEmailAdd={() => onOtherPhoneOrEmailAdd("email")}
                onOtherEmailDelete={(field) =>
                  onOtherPhoneOrEmailDelete("email", field)
                }
                onOtherEmailUpdate={(field, val) =>
                  onOtherPhoneOrEmailUpdate("email", field, val)
                }
                onOtherPhoneAdd={() => onOtherPhoneOrEmailAdd("phone")}
                onOtherPhoneDelete={(field) =>
                  onOtherPhoneOrEmailDelete("phone", field)
                }
                onOtherPhoneUpdate={(field, val) =>
                  onOtherPhoneOrEmailUpdate("phone", field, val)
                }
                onPreferredEmailUpdate={onPreferredEmailUpdateSuccess}
                onPreferredPhoneUpdate={onPreferredPhoneUpdate}
              />
              <EditAddress
                isInternal={false}
                workDayLinks={workDayLinks}
                form={addressForm}
                dropdownData={(countryRefData as BaseRefData[]) || []}
                onCountryChange={onCountryChange}
                onAddAddress={onAddAddress}
                onAddressUpdate={onAddressUpdate}
                onRemoveAddress={onRemoveAddress}
              />
            </>
          )}
          {isInternal && (
            <>
              <EditEmailAndPhoneNumber
                form={emailPhoneForm}
                onOtherEmailAdd={() => onOtherPhoneOrEmailAdd("email")}
                onOtherEmailDelete={(field) =>
                  onOtherPhoneOrEmailDelete("email", field)
                }
                onOtherEmailUpdate={(field, val) =>
                  onOtherPhoneOrEmailUpdate("email", field, val)
                }
                onOtherPhoneAdd={() => onOtherPhoneOrEmailAdd("phone")}
                onOtherPhoneDelete={(field) =>
                  onOtherPhoneOrEmailDelete("phone", field)
                }
                onOtherPhoneUpdate={(field, val) =>
                  onOtherPhoneOrEmailUpdate("phone", field, val)
                }
                onPreferredEmailUpdate={onPreferredEmailUpdate}
                onPreferredPhoneUpdate={onPreferredPhoneUpdate}
                showErrors={saveClicked}
              />
              <div className="u-border-top pt-25 mt-15">
                <HtmlRenderer
                  classes="hyperlink-underline"
                  initialContent={
                    t("jobsite.information.pronounsInformation") as string
                  }
                  htmlTag="p"
                />
                <div className="row mb-10">
                  <div className="column large-6 small-12 d-flex flex-column">
                    <Label
                      label={t("jobsite.common.displayPronouns") as string}
                      variant="prominent"
                    />
                    <span className="mt-8">
                      <Toggle
                        name={t("jobsite.common.displayPronouns") as string}
                        ariaLabel={
                          t("jobsite.common.displayPronouns") as string
                        }
                        label={
                          t(
                            `${localDisplayPronoun ? "jobsite.common.yes" : "jobsite.common.no"}`,
                          ) as string
                        }
                        checked={localDisplayPronoun}
                        onChange={(evt) =>
                          setLocalDisplayPronoun(evt.target.checked)
                        }
                      />
                    </span>
                  </div>
                  {localDisplayPronoun && (
                    <div className="column large-6 small-12 d-flex flex-column">
                      <Label
                        label={t("jobsite.common.pronouns") as string}
                        variant="prominent"
                      />
                      <Info value={getDisplayPronoun(genderPronoun)} />
                    </div>
                  )}
                </div>
              </div>
              <div className="u-border-top pt-25 mt-15">
                <EditAddress
                  isInternal={isInternal}
                  workDayLinks={workDayLinks}
                  form={addressForm}
                  dropdownData={(countryRefData as BaseRefData[]) || []}
                  onCountryChange={onCountryChange}
                  onAddAddress={onAddAddress}
                  onAddressUpdate={onAddressUpdate}
                  onRemoveAddress={onRemoveAddress}
                />
              </div>
            </>
          )}
          <SaveCancelAction
            onCancel={onCancel}
            onSave={onSave}
            cancelButtonId={profileIdGenerator.generateId("cancelButton")}
            saveButtonId={profileIdGenerator.generateId("saveButton")}
            scrollToTopSectionId={profileIdGenerator.generateId()}
          />
        </div>
      )}
      {!isEdit && <ContactView isInternal={isInternal} locale={locale} />}
      {displayPrivacyPolicy && (
        <PrivacyPolicy
          editMode={true}
          cancelButtonText={t("jobsite.common.cancel") as string}
          onAcceptPrivacyPolicy={onPrivacyPolicyAccept}
          defaultCountryId={COUNTRY_CHINA}
          disableCountrySelector={true}
          dataUsageConsentObj={privacyPolicy?.dataConsent}
          onCloseModal={onClosePrivacyPolicyModal}
          disableFocusOut={false}
          triggerElementId="address-section-country-dropdown-0"
        />
      )}
    </ProfileSection>
  );
};
