import {
  Dropdown,
  DropDownOptionProps,
  NativeButton,
  Popover,
} from "@rpe-js/marcom-web-components";
import React, { useCallback, useMemo, useState } from "react";
import { SortingMapType } from "../../app.constants";
import Icon from "../../components/base/IconComponent";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { idGenerator } from "../../utils/idGenerator";

export interface SortByListProps {
  id: string;
  ariaLabel?: string;
  ariaDescription?: string;
  sortMapping?: Record<string, SortingMapType>;
  selectedSort: string;
  onSort: (item: string) => void;
}

function SortByList({
  id,
  ariaLabel,
  ariaDescription,
  sortMapping = {},
  selectedSort,
  onSort,
}: SortByListProps) {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const menuListId = idGenerator("search", "menu-list").generateId();
  const menuButtonId = idGenerator("search", "menu-button").generateId();
  const [showSortPopover, setShowSortPopover] = useState(false);

  const selectedSortItem = useMemo(
    () => sortMapping[selectedSort],
    [sortMapping, selectedSort],
  );

  const options: DropDownOptionProps[] = useMemo(
    () =>
      Object.entries(sortMapping).map(([key, value]) => ({
        label: t(value.tranKey) as string,
        value: key,
      })),
    [sortMapping, t],
  );

  const onSortPopupClose = useCallback(() => {
    setShowSortPopover(false);
  }, []);

  const onOpenSortPopover = useCallback(() => {
    setShowSortPopover(!showSortPopover);
  }, [showSortPopover]);

  const confirmSort = useCallback(
    (key: string) => {
      setShowSortPopover(false);
      onSort(key);
    },
    [onSort],
  );

  return (
    <div className={`sort-job-list ${isMobile ? "u-border-bottom pb-10" : ""}`}>
      {isMobile && (
        <>
          <label className="sort-dropdown-label" htmlFor={`${id}-button`}>
            {t("jobsite.search.sortBy")}
          </label>
          <Dropdown
            id={`${id}-button`}
            required={true}
            classes={{ select: "t-body" }}
            label=""
            name={t("jobsite.search.sortBy") as string}
            value={selectedSort}
            options={options}
            handleValueSelect={(_evt, option) => confirmSort(option.value)}
          ></Dropdown>
        </>
      )}
      {!isMobile && (
        <>
          <NativeButton
            className={"sort-job-list-button"}
            id={`${id}-button`}
            onClick={onOpenSortPopover}
            aria-haspopup="listbox"
            aria-expanded={showSortPopover}
            aria-controls={`${id}-menu-list`}
          >
            <span className="sort-job-list-button__label">
              {t("jobsite.search.sortBy") as string}{" "}
            </span>
            {t(selectedSortItem.tranKey)}
            <Icon
              classes="ml-5"
              name={showSortPopover ? "chevron-grey-up" : "chevron-grey-down"}
              width="12"
              height="6"
              cursor="pointer"
            ></Icon>
          </NativeButton>
          {showSortPopover && (
            <Popover
              id={id}
              triggerElementId={`${id}-button`}
              aria-describedby={ariaDescription as string}
              aria-labelledby={ariaLabel as string}
              arrowPosition={isMobile ? "middle" : "end"}
              onClose={onSortPopupClose}
              role=""
            >
              <ul
                className="sort-popover-list"
                role="listbox"
                id={menuListId}
                aria-labelledby={menuButtonId}
              >
                {sortMapping &&
                  Object.entries(sortMapping)?.map(([key, value], index) => (
                    <li
                      className={`${index > 0 ? "u-border-top" : ""} py-10`}
                      key={key}
                      role="option"
                      tabIndex={-1}
                      aria-selected={key === selectedSort}
                    >
                      <NativeButton
                        id={`${id}-${index}`}
                        onClick={() => confirmSort(key)}
                        disabled={key === selectedSort}
                        label={t(value.tranKey) as string}
                      />
                    </li>
                  ))}
              </ul>
            </Popover>
          )}
        </>
      )}
    </div>
  );
}

export default SortByList;
