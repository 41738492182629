import { cloneDeep, isEmpty, random } from "lodash";
import { Contact, GenderPronoun } from "../../../../shared/types/talent/talent";
import { idGenerator } from "../../../utils/idGenerator";
import { ProfileFormField } from "../types";
import {
  ContactNameForm,
  EmailPhoneForm,
  MyApplicationContactNameForm,
} from "./types";

export function getEmailFormConfigFromContact(
  contact: Contact,
  preferredPhoneRequired: boolean,
  mode: "profile" | "myapplication" = "profile",
) {
  const emailPhoneForm: EmailPhoneForm = {
    preferredEmail: {
      label: "jobsite.common.preferredEmail",
      optional: false,
      required: true,
      type: "text",
      value: contact?.preferredEmail || "",
      error: false,
      id: "profile-cntct-pref-email",
      maxLength: 50,
      key: random(10000),
      name: "preferredEmail",
      requiredi18nMsgKey:
        mode === "profile"
          ? "jobsite.common.erroremail"
          : "jobsite.profile.myApplication.erroremail",
    },
    preferredPhone: {
      label: "jobsite.common.preferredPhone",
      maxLength: 20,
      optional: !preferredPhoneRequired,
      required: preferredPhoneRequired,
      type: "text",
      value: contact?.preferredPhone || "",
      error: false,
      id: "profile-cntct-pref-phone",
      key: random(10000),
      name: "preferredPhone",
      requiredi18nMsgKey:
        mode === "profile"
          ? "jobsite.common.errorphone"
          : "jobsite.profile.myApplication.errorphone",
    },
  };

  emailPhoneForm.otherEmail = [];
  emailPhoneForm.otherPhone = [];

  if (!isEmpty(contact?.secondaryPhone)) {
    emailPhoneForm.otherPhone.push(getOtherPhoneField(contact?.secondaryPhone));
  }
  if (!isEmpty(contact?.otherPhone)) {
    emailPhoneForm.otherPhone?.push(getOtherPhoneField(contact?.otherPhone));
  }
  if (!isEmpty(contact?.secondaryEmail)) {
    emailPhoneForm.otherEmail.push(getOtherEmailField(contact?.secondaryEmail));
  }

  if (!isEmpty(contact?.otherEmail)) {
    emailPhoneForm.otherEmail?.push(getOtherEmailField(contact?.otherEmail));
  }

  return emailPhoneForm;
}

export function getOtherEmailField(val?: string): ProfileFormField {
  return {
    maxLength: 50,
    optional: true,
    required: false,
    type: "text",
    value: val || "",
    error: false,
    id: `profile-cntct-oth-email`,
    key: random(10000),
    name: "otherEmail",
    label: "jobsite.common.otherEmail",
  };
}

export function getOtherPhoneField(value?: string): ProfileFormField {
  return {
    maxLength: 20,
    optional: true,
    required: false,
    type: "text",
    value: value || "",
    error: false,
    id: `profile-cntct-oth-phone`,
    key: random(10000),
    name: "otherPhone",
    label: "jobsite.common.otherPhone",
  };
}

export function getMyApplicationContactNameForm(contact?: Contact) {
  const form: MyApplicationContactNameForm = {
    firstName: {
      label: "jobsite.profile.myApplication.firstName",
      name: "firstName",
      type: "text",
      error: false,
      value: contact?.firstName,
      requiredi18nMsgKey: "jobsite.profile.myApplication.errorfirstname",
      required: true,
      maxLength: 32,
    },
    lastName: {
      name: "lastName",
      label:
        "jobsite.profile.myApplication.lastNamejobsite.profile.myApplication.errorlastname",
      type: "text",
      value: contact?.lastName,
      required: true,
      error: false,
      requiredi18nMsgKey: "jobsite.common.errorlastname",
      maxLength: 32,
    },
  };

  return form;
}

export function getFormConfigFromContact(
  mode: "profile" | "myapplication" = "profile",
  contact?: Contact,
  kanji?: boolean,
  locale?: boolean,
): ContactNameForm {
  const idModuleName =
    mode === "myapplication" ? "profileApplication" : "profile";
  const form: ContactNameForm = {
    firstName: {
      id: idGenerator(idModuleName, "firstname").generateId(),
      label: "jobsite.common.firstName",
      name: "firstName",
      type: "text",
      error: false,
      value: contact?.firstName,
      requiredi18nMsgKey:
        mode === "profile"
          ? "jobsite.common.errorfirstname"
          : "jobsite.profile.myApplication.errorfirstname",
      required: true,
      maxLength: 32,
    },
    lastName: {
      id: idGenerator(idModuleName, "lastname").generateId(),
      name: "lastName",
      label: "jobsite.common.lastName",
      type: "text",
      value: contact?.lastName,
      required: true,
      error: false,
      requiredi18nMsgKey:
        mode === "profile"
          ? "jobsite.common.errorlastname"
          : "jobsite.profile.myApplication.errorlastname",
      maxLength: 32,
    },
  } as ContactNameForm;

  if (mode === "profile") {
    form.preferredName = {
      id: idGenerator(idModuleName, "preferredname").generateId(),
      required: false,
      name: "preferredName",
      label: "jobsite.common.preferredName",
      type: "text",
      value: contact?.preferredName || "",
      optional: true,
      error: false,
      maxLength: 21,
    };

    if (kanji) {
      form.kanjiFirstName = {
        id: idGenerator(idModuleName, "kanjifirstName").generateId(),
        required: false,
        name: "kanjiFirstName",
        label: "jobsite.common.kanjiFirstName",
        optional: true,
        error: false,
        maxLength: 30,
        type: "text",
        value: contact?.kanji?.firstName || "",
      };
      form.kanjiLastName = {
        id: idGenerator(idModuleName, "kanjilastName").generateId(),
        required: false,
        name: "kanjiLastName",
        label: "jobsite.common.kanjiLastName",
        optional: true,
        maxLength: 30,
        error: false,
        type: "text",
        value: contact?.kanji?.lastName || "",
      };
    }

    if (locale) {
      form.localFirstName = {
        id: idGenerator(idModuleName, "localfirstName").generateId(),
        required: false,
        name: "localFirstName",
        label: "jobsite.common.localFirstName",
        optional: true,
        maxLength: 50,
        error: false,
        type: "text",
        value: contact?.local?.firstName || "",
      };
      form.localLastName = {
        id: idGenerator(idModuleName, "locallastName").generateId(),
        required: false,
        name: "localLastName",
        label: "jobsite.common.localLastName",
        optional: true,
        maxLength: 50,
        type: "text",
        error: false,
        value: contact?.local?.lastName || "",
      };
    }
  }

  return form;
}

export function getMyAppContactFormConfig(
  contactNameForm: ContactNameForm,
  existingContact: Contact,
) {
  const updated = existingContact ? cloneDeep(existingContact) : {};
  updated.firstName = contactNameForm.firstName.value;
  updated.lastName = contactNameForm.lastName.value;
  return updated;
}

export function getContactFromConfig(
  contactNameForm: ContactNameForm,
  isInternal: boolean,
  displayKanji: boolean,
  displayLocal: boolean,
  existingContact?: Contact,
): Contact {
  const updatedContact: Contact = {
    firstName: isInternal
      ? existingContact?.firstName
      : contactNameForm?.firstName.value,
    lastName: isInternal
      ? existingContact?.lastName
      : contactNameForm?.lastName.value,
    preferredName: isInternal
      ? existingContact?.preferredName
      : contactNameForm?.preferredName.value,
    preferredEmail: existingContact?.preferredEmail,
  };

  if (
    displayKanji &&
    (!isEmpty(contactNameForm.kanjiFirstName?.value) ||
      !isEmpty(contactNameForm.kanjiLastName?.value))
  ) {
    updatedContact.kanji = updatedContact.kanji || {};
    updatedContact.kanji.firstName = contactNameForm.kanjiFirstName?.value;
    updatedContact.kanji.lastName = contactNameForm.kanjiLastName?.value;
  }

  if (
    displayLocal &&
    (!isEmpty(contactNameForm.localFirstName?.value) ||
      !isEmpty(contactNameForm.localLastName?.value))
  ) {
    updatedContact.local = updatedContact.local || {};
    updatedContact.local.firstName = contactNameForm.localFirstName?.value;
    updatedContact.local.lastName = contactNameForm.localLastName?.value;
  }

  return updatedContact;
}

export function getPreferredEmailPhoneFromConfig(
  config: EmailPhoneForm,
  isInternal: boolean,
  contact: Contact,
) {
  if (!isInternal) {
    contact.preferredEmail = config.preferredEmail?.value;
    contact.preferredPhone = config.preferredPhone?.value;
  }

  const otherEmail = config.otherEmail as ProfileFormField[];
  contact.secondaryEmail =
    otherEmail.length && otherEmail[0].value ? otherEmail[0].value : "";
  contact.otherEmail =
    otherEmail.length > 1 && otherEmail[1].value ? otherEmail[1].value : "";

  const otherPhone = config.otherPhone as ProfileFormField[];
  contact.secondaryPhone =
    otherPhone.length && otherPhone[0].value ? otherPhone[0].value : "";
  contact.otherPhone =
    otherPhone.length > 1 && otherPhone[1].value ? otherPhone[1].value : "";
}

export function getDisplayPronoun(genderPronoun?: GenderPronoun) {
  if (genderPronoun) {
    const pronounList = [
      genderPronoun.subjective,
      genderPronoun.objective,
      genderPronoun.possessive,
    ];
    return pronounList.filter(Boolean).join(" • ");
  }
  return "";
}
