import { AddressCategory } from "../../../../shared/types/talent/talent";
import { ProfileFormField } from "../types";

export const ADDRESSCONFIG: Record<AddressFieldConfigId, string[]> = {
  addressOrderDefault: [],
  addressOrder1: [
    "iso-country-USA",
    "iso-country-CAN",
    "iso-country-AUS",
    "iso-country-IND",
    "iso-country-ISR",
    "iso-country-HUN",
    "iso-country-TUR",
    "iso-country-ARE",
    "iso-country-MAC",
    "iso-country-CHL",
    "iso-country-COL",
  ],
  addressOrder2: [
    "iso-country-ITA",
    "iso-country-FRA",
    "iso-country-DEU",
    "iso-country-ESP",
    "iso-country-PRT",
    "iso-country-CHE",
    "iso-country-NLD",
    "iso-country-NZL",
    "iso-country-BEL",
    "iso-country-DNK",
    "iso-country-SWE",
    "iso-country-FIN",
    "iso-country-AUT",
    "iso-country-NOR",
    "iso-country-LUX",
  ],
  addressOrder3: ["iso-country-SGP", "iso-country-TWN"],
  addressOrder4: ["iso-country-VNM", "iso-country-MYS"],
  addressOrder5: ["iso-country-PHL", "iso-country-IDN"],
  addressOrder6: ["iso-country-BRA"],
  addressOrder7: ["iso-country-CHN"],
  addressOrder8: ["iso-country-HKG"],
  addressOrder9: ["iso-country-IRL"],
  addressOrder10: ["iso-country-JPN"],
  addressOrder11: ["iso-country-MEX"],
  addressOrder12: ["iso-country-KOR"],
  addressOrder13: ["iso-country-THA"],
  addressOrder14: ["iso-country-GBR"],
};

export interface AddressFormConfig {
  key: number; // temporary unique id
  country: ProfileFormField;
  fields: Array<ProfileFormField[]>;
}

export type AddressAllowedFields =
  | "countryName"
  | "line1"
  | "line2"
  | "line3"
  | "city"
  | "district"
  | "county"
  | "state"
  | "zip";

function getAddressFieldConfig(
  fieldName: AddressAllowedFields,
  displayOrder: number,
): ProfileFormField {
  if (fieldName === "line1") {
    return {
      name: "line1",
      label: "jobsite.common.streetName1",
      type: "text",
      optional: true,
      required: false,
      maxLength: 100,
      displayOrder: displayOrder,
    } as ProfileFormField;
  }

  if (fieldName === "line2") {
    return {
      name: "line2",
      label: "jobsite.common.streetName2",
      type: "text",
      optional: true,
      required: false,
      maxLength: 100,
      displayOrder,
    } as ProfileFormField;
  }

  if (fieldName === "line3") {
    return {
      name: "line3",
      label: "jobsite.common.streetName3",
      type: "text",
      optional: true,
      required: false,
      maxLength: 100,
      displayOrder,
    } as ProfileFormField;
  }

  if (fieldName === "city") {
    return {
      name: "city",
      label: "jobsite.common.city",
      type: "text",
      required: true,
      requiredi18nMsgKey: "jobsite.common.errorCityTown",
      maxLength: 30,
      displayOrder,
    } as ProfileFormField;
  }

  if (fieldName === "district") {
    return {
      name: "district",
      label: "jobsite.common.district",
      type: "text",
      optional: true,
      required: false,
      maxLength: 30,
      displayOrder,
    };
  }

  if (fieldName === "county") {
    return {
      name: "county",
      label: "jobsite.common.county",
      type: "text",
      optional: true,
      required: false,
      maxLength: 50,
      displayOrder,
    };
  }

  if (fieldName === "state") {
    return {
      name: "state",
      label: "jobsite.common.state",
      type: "text",
      requiredi18nMsgKey: "jobsite.common.errorState",
      required: true,
      maxLength: 50,
    };
  }

  if (fieldName === "zip") {
    return {
      name: "zip",
      label: "jobsite.common.postalCode",
      type: "text",
      required: true,
      requiredi18nMsgKey: "jobsite.common.errorPostalCode",
      maxLength: 20,
      displayOrder,
    };
  }
  return {} as ProfileFormField;
}

export const ADDRESS_FIELD_CONFIG: Record<
  AddressFieldConfigId,
  Array<ProfileFormField[]>
> = {
  addressOrderDefault: [
    [getAddressFieldConfig("line1", 1), getAddressFieldConfig("line2", 2)],
    [getAddressFieldConfig("line3", 3)],
    [getAddressFieldConfig("city", 4), getAddressFieldConfig("district", 5)],
    [getAddressFieldConfig("county", 6), getAddressFieldConfig("state", 7)],
    [getAddressFieldConfig("zip", 8)],
  ],
  addressOrder1: [
    [getAddressFieldConfig("line1", 1), getAddressFieldConfig("line2", 2)],
    [getAddressFieldConfig("city", 3), getAddressFieldConfig("state", 4)],
    [getAddressFieldConfig("zip", 5)],
  ],
  addressOrder2: [
    [getAddressFieldConfig("line1", 1), getAddressFieldConfig("line2", 2)],
    [getAddressFieldConfig("line3", 3)],
    [getAddressFieldConfig("city", 4), getAddressFieldConfig("zip", 5)],
  ],
  addressOrder3: [
    [getAddressFieldConfig("line1", 1), getAddressFieldConfig("line2", 2)],
    [getAddressFieldConfig("zip", 3)],
  ],
  addressOrder4: [
    [getAddressFieldConfig("line1", 1), getAddressFieldConfig("line2", 2)],
    [getAddressFieldConfig("line3", 3)],
    [getAddressFieldConfig("zip", 4), getAddressFieldConfig("city", 5)],
    [getAddressFieldConfig("state", 6)],
  ],
  addressOrder5: [
    [getAddressFieldConfig("line1", 1), getAddressFieldConfig("line2", 2)],
    [getAddressFieldConfig("line3", 3)],
    [getAddressFieldConfig("zip", 4), getAddressFieldConfig("district", 5)],
    [getAddressFieldConfig("city", 6), getAddressFieldConfig("state", 7)],
  ],
  addressOrder6: [
    [getAddressFieldConfig("line1", 1), getAddressFieldConfig("line2", 2)],
    [getAddressFieldConfig("line3", 3)],
    [getAddressFieldConfig("city", 4), getAddressFieldConfig("state", 5)],
  ],
  addressOrder7: [
    [getAddressFieldConfig("state", 1), getAddressFieldConfig("city", 2)],
    [getAddressFieldConfig("district", 3)],
    [getAddressFieldConfig("line1", 4), getAddressFieldConfig("line2", 5)],
    [getAddressFieldConfig("zip", 6)],
  ],
  addressOrder8: [
    [getAddressFieldConfig("line1", 1), getAddressFieldConfig("line2", 2)],
  ],
  addressOrder9: [
    [getAddressFieldConfig("line1", 1), getAddressFieldConfig("line2", 2)],
    [getAddressFieldConfig("line3", 3)],
    [getAddressFieldConfig("city", 4), getAddressFieldConfig("county", 5)],
  ],
  addressOrder10: [
    [getAddressFieldConfig("zip", 1), getAddressFieldConfig("state", 2)],
    [getAddressFieldConfig("city", 3)],
    [getAddressFieldConfig("line1", 4), getAddressFieldConfig("line2", 5)],
  ],
  addressOrder11: [
    [getAddressFieldConfig("line1", 1), getAddressFieldConfig("line2", 2)],
    [getAddressFieldConfig("line3", 3)],
    [getAddressFieldConfig("city", 4), getAddressFieldConfig("state", 5)],
    [getAddressFieldConfig("zip", 6)],
  ],
  addressOrder12: [
    [getAddressFieldConfig("city", 1)],
    [getAddressFieldConfig("line1", 2), getAddressFieldConfig("line2", 3)],
    [getAddressFieldConfig("zip", 4)],
  ],
  addressOrder13: [
    [getAddressFieldConfig("line1", 1), getAddressFieldConfig("line2", 2)],
    [getAddressFieldConfig("line3", 3)],
    [getAddressFieldConfig("zip", 4), getAddressFieldConfig("city", 5)],
    [getAddressFieldConfig("district", 6), getAddressFieldConfig("state", 7)],
  ],
  addressOrder14: [
    [getAddressFieldConfig("line1", 1), getAddressFieldConfig("line2", 2)],
    [getAddressFieldConfig("line3", 3)],
    [getAddressFieldConfig("city", 4), getAddressFieldConfig("county", 5)],
    [getAddressFieldConfig("zip", 6)],
  ],
};

// If fields have to be displayed as comma separated string, add them as an array
export const ADDRESS_FIELD_VIEW_CONFIG: Record<
  AddressFieldConfigId,
  Array<AddressAllowedFields | AddressAllowedFields[]>
> = {
  addressOrderDefault: [
    "countryName",
    "line1",
    "line2",
    "line3",
    "city",
    "district",
    "county",
    "state",
    "zip",
  ],
  addressOrder1: ["countryName", "line1", ["line2", "city"], "state", "zip"],
  addressOrder2: ["countryName", "line1", "line2", "line3", "city", "zip"],
  addressOrder3: ["countryName", "line1", "line2", "zip"],
  addressOrder4: [
    "countryName",
    "line1",
    "line2",
    "line3",
    "zip",
    "city",
    "state",
  ],
  addressOrder5: [
    "countryName",
    "line1",
    "line2",
    "line3",
    "zip",
    "district",
    "city",
    "state",
  ],
  addressOrder6: ["countryName", "line1", "line2", "line3", "city", "state"],
  addressOrder7: [
    "countryName",
    "state",
    "city",
    "district",
    "line1",
    "line2",
    "zip",
  ],
  addressOrder8: ["countryName", "line1", "line2"],
  addressOrder9: ["countryName", "line1", "line2", "line3", "city", "county"],
  addressOrder10: ["countryName", "zip", "state", "city", "line1", "line2"],
  addressOrder11: [
    "countryName",
    "line1",
    "line2",
    "line3",
    "city",
    "state",
    "zip",
  ],
  addressOrder12: ["countryName", "city", "line1", "line2", "zip"],
  addressOrder13: [
    "countryName",
    "line1",
    "line2",
    "line3",
    "zip",
    "city",
    "district",
    "state",
  ],
  addressOrder14: [
    "countryName",
    "line1",
    "line2",
    "line3",
    "city",
    "county",
    "zip",
  ],
};

export type AddressFieldConfigId =
  | "addressOrderDefault"
  | "addressOrder1"
  | "addressOrder2"
  | "addressOrder3"
  | "addressOrder4"
  | "addressOrder5"
  | "addressOrder6"
  | "addressOrder7"
  | "addressOrder8"
  | "addressOrder9"
  | "addressOrder10"
  | "addressOrder11"
  | "addressOrder12"
  | "addressOrder13"
  | "addressOrder14";

export const ADDRESS_PREFERRED = AddressCategory["addressType-preferred"];

export interface ContactNameForm {
  firstName: ProfileFormField;
  lastName: ProfileFormField;
  preferredName: ProfileFormField;
  kanjiFirstName?: ProfileFormField;
  kanjiLastName?: ProfileFormField;
  localFirstName?: ProfileFormField;
  localLastName?: ProfileFormField;
}

export interface MyApplicationContactNameForm {
  firstName: ProfileFormField;
  lastName: ProfileFormField;
}

export interface MyApplicationEmailPhoneForm {
  preferredEmail?: ProfileFormField;
  preferredPhone?: ProfileFormField;
  otherEmail?: ProfileFormField[];
  otherPhone?: ProfileFormField[];
}

export interface EmailPhoneForm {
  preferredEmail?: ProfileFormField;
  preferredPhone?: ProfileFormField;
  otherEmail?: ProfileFormField[];
  otherPhone?: ProfileFormField[];
}
