import {
  MultiLinDropdownOptionProps,
  ProgressIndicatorLoader,
} from "@rpe-js/marcom-web-components";
import { cloneDeep, isEmpty, isNull, isUndefined } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MyApplication } from "../../../../shared/types/talent/myApplication";
import {
  CountrySpecificSelfDisclosureConfig,
  Disability,
  SelfDisclosure,
} from "../../../../shared/types/talent/talent";
import { getCountrySpecificSelfDisclosureConfig } from "../../../api/fetchClient";
import {
  DEFAULT_LOCALE,
  DEFAULT_LOCALE_API_HEADER,
  DISABILITY,
  ETHNICITY,
  GENDER,
  VETERAN_STATUS,
} from "../../../app.constants";
import { SelfDisclosureWidget } from "../../../components/feature/selfDisclosure/SelfDisclosureWidget";
import { useCurrentUserContext } from "../../../CurrentUserContext";
import { useFetchData } from "../../../hooks/useFetchData";
import useSetPageTitle, { PAGE_TITLES } from "../../../hooks/useSetPageTitle";
import { ActionContainer } from "./ActionContainer";
import { useMyApplicationContext } from "./context/context";
import { useCreateOrUpdateApplication } from "./hooks/createAndUpdateApplicationHooks";
import { useGotoNextStep } from "./hooks/useGotoNextStep";

const ISO_COUNTRY_USA = "iso-country-USA";

function isNotNullAndNotEmpty(val: string | null | undefined): boolean {
  return !isUndefined(val) && !isNull(val) && !isEmpty(val);
}

export function SelfDisclosureStep() {
  const [canSubmit, setCanSubmit] = useState<boolean>(true);
  const { currentUser } = useCurrentUserContext();
  const { state, dispatch } = useMyApplicationContext();
  const {
    submitted,
    talent,
    selfDisclosureConfig,
    application,
    selfDisclosureStep,
    page,
    currentStep,
  } = state;
  const { selfDisclosures = {} } = talent || {};
  const { selfDisclosure: currentSelfDisclosure } = selfDisclosureStep;
  const defaultDisabilityInfo = useMemo(
    () => ({
      statusID: null,
      statusName: null,
      name: talent?.contact?.firstName,
      status: null,
      completedDate: null,
    }),
    [talent?.contact?.firstName],
  );
  const [sd, setSD] = useState<SelfDisclosure>(
    cloneDeep(
      currentSelfDisclosure ||
        selfDisclosures[ISO_COUNTRY_USA] || {
          disability: defaultDisabilityInfo, // If the talent does not have any self disclosure data then default the disability form with talent firstname
        },
    ),
  );
  const [showErrorInWidget, setShowErrorInWidget] = useState<boolean>(false);

  const goToNextStep = useGotoNextStep();
  const { setPageTitle } = useSetPageTitle();

  const { isLoading, isSuccess, data, fetchData } = useFetchData(
    getCountrySpecificSelfDisclosureConfig,
  );

  const {
    createOrUpdate,
    crtOrUpdApplication,
    //crtOrUpdApplicationError,
    //isCrtOrUpdApplicationError,
    isCrtOrUpdApplicationLoading,
    isCrtOrUpdApplicationSuccess,
  } = useCreateOrUpdateApplication();

  useEffect(() => {
    setPageTitle(
      PAGE_TITLES.YOUR_APPLICATION.SELF_DISCLOSURE,
      currentStep ? page.stepMapping[currentStep] : "",
      page.stepNames.length,
    );
  }, [currentStep, page.stepMapping, page.stepNames.length, setPageTitle]);

  useEffect(() => {
    if (
      !isCrtOrUpdApplicationLoading &&
      isCrtOrUpdApplicationSuccess &&
      crtOrUpdApplication &&
      canSubmit
    ) {
      setCanSubmit(false);
      dispatch({ type: "SET_MY_APPLICATION", payload: crtOrUpdApplication });
      goToNextStep("next");
    }
  }, [
    dispatch,
    isCrtOrUpdApplicationLoading,
    isCrtOrUpdApplicationSuccess,
    crtOrUpdApplication,
    canSubmit,
    goToNextStep,
  ]);

  useEffect(() => {
    if (!isLoading && isSuccess && data && !selfDisclosureConfig) {
      dispatch({
        type: "SET_COUNTRY_SPECIFIC_SELFDISCLOSURE_CONFIG",
        payload: data,
      });
    }

    if (!selfDisclosureConfig && !data && !isLoading) {
      fetchData([ISO_COUNTRY_USA]);
    }
  }, [data, isLoading, isSuccess, dispatch, fetchData, selfDisclosureConfig]);

  const onChange = useCallback(
    (field: {
      type: string;
      option: MultiLinDropdownOptionProps | Disability;
    }) => {
      const { type, option } = field;
      if (type === GENDER) {
        const opt = option as MultiLinDropdownOptionProps;
        setSD({ ...sd, genderID: opt.id, genderName: opt.value });
      }
      if (type === ETHNICITY) {
        const opt = option as MultiLinDropdownOptionProps;
        setSD({ ...sd, ethnicityID: opt.id, ethnicityName: opt.value });
      }

      if (type === DISABILITY) {
        const opt = option as Disability;
        setSD({ ...sd, disability: { ...opt } });
      }

      if (type === VETERAN_STATUS) {
        const opt = option as MultiLinDropdownOptionProps;
        setSD({
          ...sd,
          veteranStatus: {
            veteranStatusID: opt.id,
            veteranStatusName: opt.value,
          },
        });
      }
    },
    [sd],
  );

  const validateSelfDisclosureData = useCallback((): boolean => {
    let configHasError = false;
    if (selfDisclosureConfig?.gender) {
      configHasError =
        configHasError ||
        !(
          isNotNullAndNotEmpty(sd.genderID) &&
          isNotNullAndNotEmpty(sd.genderName)
        );
    }
    if (selfDisclosureConfig?.ethnicity) {
      configHasError =
        configHasError ||
        !(
          isNotNullAndNotEmpty(sd.ethnicityID) &&
          isNotNullAndNotEmpty(sd.ethnicityName)
        );
    }

    if (selfDisclosureConfig?.veteranStatus) {
      configHasError =
        configHasError ||
        !(
          isNotNullAndNotEmpty(sd.veteranStatus?.veteranStatusID) &&
          isNotNullAndNotEmpty(sd.veteranStatus?.veteranStatusName)
        );
    }
    if (selfDisclosureConfig?.disability) {
      configHasError = configHasError || isEmpty(sd?.disability);
    }
    return configHasError;
  }, [selfDisclosureConfig, sd]);

  const onSave = useCallback(() => {
    if (!validateSelfDisclosureData() && currentUser.talentId) {
      const createPayload: Partial<MyApplication> = {
        ...application,
        disability: {
          statusID: sd.disability?.statusID as string,
          statusName: sd.disability?.statusName,
          name: sd.disability?.name,
          completedOn: sd.disability?.completedOn, // Todo fix any typing here, Disability modal has completedOn typed as Date. This needs to be fixed as response Objects cannot have Date as type.
        },
        selfDisclosure: {
          genderID: sd.genderID,
          ethnicityID: sd.ethnicityID,
          veteranStatus: sd.veteranStatus,
        },
      };
      dispatch({ type: "SET_MY_APP_USER_SELF_DISCLOSURE", payload: sd });
      createOrUpdate(
        currentUser.talentId as string,
        createPayload,
        application?.id,
      );
    } else {
      setShowErrorInWidget(true);
    }
  }, [
    setShowErrorInWidget,
    validateSelfDisclosureData,
    sd,
    createOrUpdate,
    currentUser,
    application,
    dispatch,
  ]);

  return (
    <>
      {selfDisclosureConfig && (
        <>
          <SelfDisclosureWidget
            showError={showErrorInWidget}
            locale={DEFAULT_LOCALE}
            dataLocale={DEFAULT_LOCALE_API_HEADER}
            noHeader={true}
            noActions={true}
            onSelfDisclosureFieldChange={onChange}
            selectedSelfDisclosure={sd}
            selfDisclosureConfig={
              selfDisclosureConfig as CountrySpecificSelfDisclosureConfig
            }
            goPrevious={() => {}}
            moveNext={() => {}}
          />
          {!submitted && (
            <ActionContainer
              saveDisabled={!state.selfDisclosureStep.canSave}
              onSave={onSave}
              onBack={() => goToNextStep("prev")}
              onSubmit={() => {}}
            />
          )}
        </>
      )}
      {(isLoading || isCrtOrUpdApplicationLoading) && (
        <ProgressIndicatorLoader showLoading={true} />
      )}
    </>
  );
}
