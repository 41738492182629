import React from "react";
import useIsMobile from "../../hooks/useIsMobile";
import { idGenerator } from "../../utils/idGenerator";
import JobDetailsRowContent, {
  JobDetailsRowContentType,
} from "./JobDetailsRowContent";

export interface JobDetailsRowContainer extends JobDetailsRowContentType {
  title: string;
  titleContent?: React.JSX.Element;
}

export default function JobDetailsRowContainer({
  id,
  content,
  type,
  title,
  titleContent,
}: JobDetailsRowContainer) {
  const isMobile = useIsMobile();
  const idGeneratorContainer = idGenerator("jobdetails", id);

  const jobDetailsRowContent = (
    <JobDetailsRowContent
      id={idGeneratorContainer.generateId("content")}
      content={content}
      type={type}
      whiteSpacePreWrap={true}
    />
  );

  function renderContent() {
    return (
      <div
        role="group"
        aria-labelledby={idGeneratorContainer.generateId("titleheading")}
        id={idGeneratorContainer.generateId()}
        className={`${isMobile ? "" : "py-40 u-border-bottom"} row text-left`}
      >
        {!isMobile ? (
          <>
            <aside
              id={idGeneratorContainer.generateId("title")}
              className={"column large-3 px-10"}
            >
              {title && (
                <h2
                  id={idGeneratorContainer.generateId("titleheading")}
                  className={"t-label word-wrap-break-word fw-medium"}
                >
                  {title}
                </h2>
              )}
              {titleContent ? titleContent : ""}
            </aside>
            <div className={"column large-9"}>{jobDetailsRowContent}</div>
          </>
        ) : (
          <>
            {id == "jobsummary" ? (
              <div className={`column large-12 u-border-bottom`}>
                <aside
                  id={idGeneratorContainer.generateId("title")}
                  className={`column small-12 py-15 ${isMobile ? "" : "px-10"} `}
                >
                  {title && (
                    <h2
                      id={idGeneratorContainer.generateId("title-heading")}
                      className={`t-label word-wrap-break-word fw-medium`}
                    >
                      {title}
                    </h2>
                  )}
                  {titleContent ? titleContent : ""}
                </aside>
                <div className={"column small-12 py-30"}>
                  {jobDetailsRowContent}
                </div>
              </div>
            ) : (
              <div className={"column large-12 small-12 pb-40 "}>
                {jobDetailsRowContent}
              </div>
            )}
          </>
        )}
      </div>
    );
  }

  return content && content.length ? renderContent() : null;
}
