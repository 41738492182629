import { ProgressIndicatorLoader } from "@rpe-js/marcom-web-components";
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { IntlProvider } from "react-intl";
import { useNavigate } from "react-router";
import APP_CONSTANTS from "../../../../utilities/appConstants";
import { getTranslationPromise } from "../../../api/fetchClient";
import { DEFAULT_LOCALE } from "../../../app.constants";
import AppContext from "../../../AppContext";
import { useLocaleAwareLink } from "../../../components/feature/LocaleAwareLink";
import { useCurrentUserContext } from "../../../CurrentUserContext";
import { ApplicationLayout } from "./ApplicationLayout";
import { MyApplicationContext } from "./context/context";
import { getInitialState, reducer } from "./context/reducer";
import { useFetchTalent } from "./hooks/useFetchTalent";

export function YourApplication() {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const { currentUser } = useCurrentUserContext();
  const { appUIState } = useContext(AppContext);
  const { locale, translations } = appUIState.appData;
  const navigate = useNavigate();
  const getLink = useLocaleAwareLink();

  const { talent, isPageDataLoading } = state;
  const [updatedTranslations, setUpdatedTranslations] = useState(translations);
  const [updatedLocale, setUpdatedLocale] = useState(locale);
  const [translationsLoading, setTranslationsLoading] = useState(true);

  const {
    fetchTalent,
    fetchTalentError,
    isFetchTalentError,
    isFetchTalentLoading,
    isFetchTalentSuccess,
    talent: incomingTalent,
  } = useFetchTalent(currentUser?.talentId as string);

  const getTranslations = useCallback(() => {
    if (locale !== DEFAULT_LOCALE) {
      getTranslationPromise({
        headers: { locale: DEFAULT_LOCALE },
      })().then((result: any) => {
        setTranslationsLoading(false);
        setUpdatedTranslations(result.data);
        setUpdatedLocale(DEFAULT_LOCALE);
      });
    } else {
      setTranslationsLoading(false);
    }
  }, [locale]);

  useEffect(() => {
    if (!currentUser.enableUSEmploymentApplication) {
      navigate(getLink(true, "profile/info"));
    }
  }, [currentUser, navigate, getLink]);

  useEffect(() => {
    if (!isFetchTalentLoading && isFetchTalentError && fetchTalentError) {
      dispatch({ type: "SET_PAGE_ERROR", payload: true });
    }

    if (
      !isFetchTalentLoading &&
      isFetchTalentSuccess &&
      incomingTalent &&
      !talent
    ) {
      dispatch({ type: "SET_TALENT", payload: incomingTalent });
    }

    if (!isFetchTalentLoading && !incomingTalent && !talent) {
      dispatch({ type: "SET_PAGE_LOADING", payload: true });
      fetchTalent();
    }

    if (translationsLoading) {
      getTranslations();
    }
  }, [
    fetchTalent,
    fetchTalentError,
    isFetchTalentError,
    isFetchTalentLoading,
    isFetchTalentSuccess,
    incomingTalent,
    talent,
    dispatch,
    translationsLoading,
    getTranslations,
  ]);

  return (
    <div
      dir={APP_CONSTANTS.HTML_DIRECTION.LTR}
      lang={APP_CONSTANTS.DEFAULT_HTML_LANG}
      className="u-border-top d-flex flex-column align-center application-container"
    >
      {!translationsLoading && !isPageDataLoading && (
        <IntlProvider locale={updatedLocale} messages={updatedTranslations}>
          <MyApplicationContext.Provider value={{ state, dispatch }}>
            <ApplicationLayout />
          </MyApplicationContext.Provider>
        </IntlProvider>
      )}
      {(translationsLoading || isPageDataLoading) && (
        <ProgressIndicatorLoader showLoading={isPageDataLoading} />
      )}
    </div>
  );
}
