import { RefData } from "@rpe-js/core/src/types/refData";
import {
  Dropdown,
  DropDownOptionProps,
  Textbox,
  TextButton,
} from "@rpe-js/marcom-web-components";
import React, { useCallback, useContext } from "react";
import { getTalentCompanyByInput } from "../../../api/fetchClient";
import AppContext from "../../../AppContext";
import ErrorMessage from "../../../components/base/ErrorMessage";
import { Info } from "../../../components/base/Info";
import { Label } from "../../../components/base/Label";
import TypeaheadComponent, {
  typeaheadstandardCssClasses,
} from "../../../components/base/Typeahead/TypeaheadComponent";
import { useFormInputValidator } from "../../../components/feature/form/inputValidators/useFormInputValidator";
import RadioGroup from "../../../components/feature/form/radioGroup";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { idGenerator } from "../../../utils/idGenerator";
import { EmploymentForm } from "./employmentFormUtils";

type EditEmployeeSectionProps = {
  employmentsForm: EmploymentForm[];
  displayErrors: boolean;
  mode: "profile" | "parsedmodal";
  monthOptions: { value: string; label: string }[];
  yearOptions: { value: string; label: string }[];
  addEmployer: (index: number) => void;
  removeEmployer: (key: number, index: number) => void;
  handleValueChange: (index: number, option: any, name: string) => void;
};

export const EditEmployeeSection = ({
  displayErrors,
  employmentsForm,
  mode,
  addEmployer,
  handleValueChange,
  removeEmployer,
  monthOptions,
  yearOptions,
}: EditEmployeeSectionProps) => {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const { appUIState } = useContext(AppContext);
  const { isInternal } = appUIState.appData;
  const profileEmployerId = idGenerator(mode, "employer").generateId();
  const profileCurrentEmployerId = idGenerator(
    mode,
    "currentemployer",
  ).generateId();

  const profileJobTitleId = idGenerator(mode, "jobtitle").generateId();
  const profileStartMonthId = idGenerator(mode, "startmonth").generateId();
  const profileStartYearId = idGenerator(mode, "startyear").generateId();
  const profileEndMonthId = idGenerator(mode, "endmonth").generateId();
  const profileEndYearId = idGenerator(mode, "endyear").generateId();
  const { validateJobTitle, onPasteValidateJobTitle } = useFormInputValidator();

  const getCurrentEmployerOptions = useCallback(
    (key: number) => {
      return [
        {
          label: t("jobsite.common.yes") as string,
          value: "Yes",
          id: idGenerator(mode, "currentemployer").generateId(
            `yes-${key}`,
            false,
          ),
        },
        {
          label: t("jobsite.common.no") as string,
          value: "No",
          id: idGenerator(mode, "currentemployer").generateId(
            `no-${key}`,
            false,
          ),
        },
      ];
    },
    [mode, t],
  );

  return (
    <div>
      {employmentsForm.map(
        (employment: EmploymentForm, employmentIndex: number) =>
          isInternal &&
          employment.currentEmployer.value === "Yes" &&
          employment.empAdd !== true &&
          !employment.addedSource &&
          mode === "profile" ? (
            <li role="listitem" key={employment.key}>
              <div className="d-flex flex-column">
                <Label
                  label={t("jobsite.common.employer") as string}
                  variant="prominent"
                />
                <Info value={employment.employerName.value} />
                <div className="d-flex flex-column mt-10">
                  <Label
                    label={t("jobsite.common.jobTitle") as string}
                    variant="prominent"
                  />
                  <Info value={employment.jobTitle.value} />
                </div>
                <div className="d-flex flex-column mt-10 mb-10">
                  <Label
                    label={t("jobsite.common.currentEmployer") as string}
                    variant="prominent"
                  />
                  <Info value={t("jobsite.common.yes") as string} />
                </div>
              </div>
              {employmentIndex === employmentsForm.length - 1 && (
                <TextButton
                  label={t("jobsite.common.addAnotherEmployer") as string}
                  onClick={() => addEmployer(employmentIndex)}
                  icon="icon-pluscircle"
                />
              )}
            </li>
          ) : (
            <div
              className={employmentIndex !== 0 ? "u-border-top pt-20" : ""}
              key={employment.key}
            >
              <div className="row">
                <div
                  className={`column large-6 small-12 mb-10 ${!isMobile ? "pr-15" : ""}`}
                >
                  <TypeaheadComponent
                    id={`${profileEmployerId}-${employmentIndex}`}
                    elementToFocusOnClose={`${profileCurrentEmployerId}-${employment.key}`} // bring the focus back to radio group
                    apiEndpoint={getTalentCompanyByInput}
                    label={t("jobsite.common.enterEmployerName") as string}
                    strict={false}
                    onSelect={(e) =>
                      handleValueChange(employmentIndex, e, "employer")
                    }
                    onBlur={(e) =>
                      handleValueChange(employmentIndex, e, "employer")
                    }
                    onReset={() =>
                      handleValueChange(employmentIndex, "", "employer")
                    }
                    highlightMatches={true}
                    showSearchIcon={true}
                    suggestionPlaceholder={
                      t("jobsite.common.empTitle") as string
                    }
                    getSuggestionLabel={(suggestion: RefData) =>
                      suggestion?.name
                    }
                    minChars={1}
                    initialValue={employment.employerName?.value}
                    classNames={typeaheadstandardCssClasses}
                    errorMessage={
                      displayErrors &&
                      employment.employerName.requiredError === true
                        ? (t("jobsite.common.erroremployer") as string)
                        : ""
                    }
                    errorA11y={t("jobsite.common.errorIconLabel") as string}
                  />
                </div>
                <div className="column large-6 small-12 mb-10">
                  <RadioGroup
                    required={true}
                    id={`${profileCurrentEmployerId}-${employment.key}`}
                    classes={{ root: "mt-0" }}
                    title={t("jobsite.common.currentEmployer") as string}
                    name={`currentEmployer${employmentIndex}`}
                    options={getCurrentEmployerOptions(employment.key)}
                    selectedValue={employment.currentEmployer?.value}
                    onChange={(e) =>
                      handleValueChange(
                        employmentIndex,
                        e.target.value,
                        "currentEmployer",
                      )
                    }
                    errorMessage={
                      displayErrors &&
                      employment.currentEmployer.requiredError === true
                        ? (t("jobsite.common.chooseOption") as string)
                        : ""
                    }
                    errorA11y={t("jobsite.common.errorIconLabel") as string}
                  ></RadioGroup>
                </div>
              </div>
              <div className="row">
                <div
                  className={`column large-6 small-12 mb-10 ${!isMobile ? "pr-15" : ""}`}
                >
                  <Textbox
                    errorA11y={t("jobsite.common.errorIconLabel") as string}
                    id={`${profileJobTitleId}-${employmentIndex}`}
                    required={true}
                    label={t("jobsite.common.jobTitle") as string}
                    value={employment.jobTitle?.value}
                    onValueChange={(e) =>
                      handleValueChange(employmentIndex, e, "jobTitle")
                    }
                    onKeyDown={validateJobTitle}
                    maxLength={60}
                    onPaste={onPasteValidateJobTitle}
                    error={
                      displayErrors && employment.jobTitle.requiredError
                        ? (t("jobsite.common.errorjobtitle") as string)
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="row">
                <fieldset
                  className={`column large-6 small-12 mb-10 ${!isMobile ? "pr-15" : ""}`}
                >
                  <legend>
                    <Label
                      label={t("jobsite.common.startDate") as string}
                      variant="prominent"
                    />
                  </legend>
                  <div className="row mt-5">
                    <div
                      className={`column large-6 small-12 ${!isMobile ? "pr-15" : ""}`}
                    >
                      <Dropdown
                        required={true}
                        id={`${profileStartMonthId}-${employmentIndex}`}
                        options={monthOptions}
                        value={employment.startMonthID?.value}
                        handleValueSelect={(
                          evt: React.ChangeEvent,
                          option: DropDownOptionProps,
                        ) =>
                          handleValueChange(
                            employmentIndex,
                            option,
                            "startMonth",
                          )
                        }
                        name={t("jobsite.common.month") as string}
                        label={t("jobsite.common.month") as string}
                        {...(displayErrors &&
                        employment.startMonthID.requiredError === true
                          ? {
                              error: t("jobsite.common.errormonth") as string,
                            }
                          : {})}
                        errorA11y={t("jobsite.common.errorIconLabel") as string}
                      />
                    </div>
                    <div
                      className={`column large-6 small-12 ${!isMobile ? "pr-15" : ""}`}
                    >
                      <Dropdown
                        required={true}
                        id={`${profileStartYearId}-${employmentIndex}`}
                        options={yearOptions}
                        value={employment.startYear?.value}
                        handleValueSelect={(
                          evt: React.ChangeEvent,
                          option: DropDownOptionProps,
                        ) =>
                          handleValueChange(
                            employmentIndex,
                            option,
                            "startYear",
                          )
                        }
                        name={t("jobsite.common.year") as string}
                        label={t("jobsite.common.year") as string}
                        {...(displayErrors &&
                        employment.startYear.requiredError === true
                          ? {
                              error: t("jobsite.common.erroryear") as string,
                            }
                          : {})}
                        errorA11y={t("jobsite.common.errorIconLabel") as string}
                      />
                    </div>
                    {displayErrors &&
                      employment.startMonthID.invalidErrorMessage && (
                        <ErrorMessage
                          message={
                            t(
                              employment.startMonthID.invalidErrorMessage,
                            ) as string
                          }
                          errorA11y={
                            t("jobsite.common.errorIconLabel") as string
                          }
                        />
                      )}
                  </div>
                </fieldset>
                {employment.currentEmployer?.value === "No" && (
                  <fieldset
                    className={`column large-6 small-12 mb-10 ${!isMobile ? "pr-15" : ""}`}
                  >
                    <legend>
                      <Label
                        label={t("jobsite.common.endDate") as string}
                        variant="prominent"
                      />
                    </legend>
                    <div className="row mt-5">
                      <div
                        className={`column large-6 small-12 ${!isMobile ? "pr-15" : ""}`}
                      >
                        <Dropdown
                          required={true}
                          id={`${profileEndMonthId}-${employmentIndex}`}
                          options={monthOptions}
                          value={employment.endMonthID?.value}
                          handleValueSelect={(
                            evt: React.ChangeEvent,
                            option: DropDownOptionProps,
                          ) =>
                            handleValueChange(
                              employmentIndex,
                              option,
                              "endMonth",
                            )
                          }
                          name={t("jobsite.common.month") as string}
                          label={t("jobsite.common.month") as string}
                          {...(displayErrors &&
                          employment.endMonthID.requiredError === true
                            ? {
                                error: t("jobsite.common.errormonth") as string,
                              }
                            : {})}
                          errorA11y={
                            t("jobsite.common.errorIconLabel") as string
                          }
                        />
                      </div>
                      <div
                        className={`column large-6 small-12 ${!isMobile ? "pr-15" : ""}`}
                      >
                        <Dropdown
                          required={true}
                          id={`${profileEndYearId}-${employmentIndex}`}
                          options={yearOptions}
                          value={employment.endYear?.value}
                          handleValueSelect={(
                            evt: React.ChangeEvent,
                            option: DropDownOptionProps,
                          ) =>
                            handleValueChange(
                              employmentIndex,
                              option,
                              "endYear",
                            )
                          }
                          name={t("jobsite.common.year") as string}
                          label={t("jobsite.common.year") as string}
                          {...(displayErrors &&
                          employment.endYear.requiredError === true
                            ? {
                                error: t("jobsite.common.erroryear") as string,
                              }
                            : {})}
                          errorA11y={
                            t("jobsite.common.errorIconLabel") as string
                          }
                        />
                      </div>
                      {displayErrors &&
                        employment.endMonthID.invalidErrorMessage && (
                          <ErrorMessage
                            message={
                              t(
                                employment.endMonthID.invalidErrorMessage,
                              ) as string
                            }
                            errorA11y={
                              t("jobsite.common.errorIconLabel") as string
                            }
                          />
                        )}
                    </div>
                  </fieldset>
                )}
              </div>
              <div className="d-flex mb-20">
                {employmentsForm.length !== 1 && (
                  <span className="mr-20">
                    <TextButton
                      id={idGenerator(mode, "remove-employment").generateId(
                        `${employmentIndex}`,
                      )}
                      label={t("jobsite.common.remove") as string}
                      onClick={() =>
                        removeEmployer(employment.key, employmentIndex)
                      }
                      icon="icon-minuscircle"
                      aria-label={`${t("jobsite.common.removeEntity", { name: `${t("jobsite.common.empSummaryTitle")} ${employmentIndex + 1}` })}`}
                    />
                  </span>
                )}
                {employmentIndex === employmentsForm.length - 1 && (
                  <TextButton
                    id={idGenerator(mode, "add-employment").generateId(
                      `${employmentIndex}`,
                    )}
                    label={t("jobsite.common.addAnotherEmployer") as string}
                    onClick={() => addEmployer(employmentIndex)}
                    icon="icon-pluscircle"
                  />
                )}
              </div>
            </div>
          ),
      )}
    </div>
  );
};
