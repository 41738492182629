import React, { createContext, ReactNode, useContext, useEffect } from "react";
import { useLocation } from "react-router";
import { AlertWrapper } from "../components/base/AlertWrapper";
import { useAlertState } from "../hooks/useAlertState";
import useIntlMessage from "../hooks/useIntlMessage";
import { idGenerator } from "../utils/idGenerator";

export interface AppAlertContextType {
  updateAlert: (
    isError?: boolean,
    hideChildren?: boolean,
    message?: string,
  ) => void;
  deleteAlert: () => void;
}

interface AppAlertContextProviderProps {
  children: ReactNode;
}

export const AppAlertContext = createContext<AppAlertContextType>({
  updateAlert: () => {},
  deleteAlert: () => {},
});

export const useAppAlertContext = (): AppAlertContextType => {
  const context = useContext(AppAlertContext);
  if (!context) {
    throw new Error(
      "usePageAlertContext must be used within a AppAlertContextProvider",
    );
  }
  return context;
};

export const AppAlert = ({ children }: AppAlertContextProviderProps) => {
  const { alert, isError, updateAlert, deleteAlert } = useAlertState();
  const { t } = useIntlMessage();
  const location = useLocation();

  useEffect(() => {
    deleteAlert();
  }, [deleteAlert, location]);

  return (
    <AppAlertContext.Provider
      value={{
        updateAlert,
        deleteAlert,
      }}
    >
      {alert && (
        <div className="mt-5">
          <AlertWrapper
            id={idGenerator("global", "alert").generateId()}
            closePosition="right"
            remove={{
              closeBtnAriaLabel: t("jobsite.common.closeAlert") as string,
              onRemove: deleteAlert,
            }}
            error={isError}
            message={alert}
            classes={`app-alert ${!isError ? "yellow-alert" : ""} sticky-alert `}
          />
        </div>
      )}
      {children}
    </AppAlertContext.Provider>
  );
};
