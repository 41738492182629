import { transformUrl } from "@rpe-js/core";
import dateUtil from "@rpe-js/core/dist/util/dateUtil";
import Accordion, {
  AccordionItem,
} from "@rpe-js/marcom-web-components/lib/CustomAccordion/CustomAccordion";

import { isEmpty } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  useLocation,
  useParams,
  useRouteLoaderData,
  useSearchParams,
} from "react-router";
import APP_CONSTANTS from "../../../utilities/appConstants";
import {
  getDirectionForMainContent,
  getEEOContent,
  getLocalizedJobDetails,
  getLocation,
  getPageTitleAndMeta,
  getTeam,
} from "../../../utilities/util";
import {
  DEFAULT_TRANSLATION_LOCALE,
  RECENTLY_VIEWED_ROLES,
} from "../../app.constants";
import AppContext from "../../AppContext";
import HtmlRenderer from "../../components/base/HtmlRenderer";
import translateReplace from "../../hooks/translateReplace";
import useDtm from "../../hooks/useAdobeAnalytics";
import useAutoFocus from "../../hooks/useAutoFocus";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { RouteID } from "../../routes";
import { idGenerator } from "../../utils/idGenerator";
import { getTranslatedContentHeading } from "../../utils/jobDetailUtil";
import { SessionStorage } from "../../utils/sessionStorageUtil";
import InternalJobDetails from "./InternalJobDetails";
import InternalJobInformationContainer from "./InternalJobInformationContainer";
import JobDetailFooter from "./JobDetailFooter";
import JobDetailHeader from "./JobDetailHeader";
import JobDetailsLocaleSelect from "./JobDetailsLocaleSelect";
import JobDetailsRowContainer from "./JobDetailsRowContainer";

function getInitialJobData(jobData: any) {
  if (jobData) {
    return jobData;
  }
  return {
    jobSummary: "",
    keyQualifications: "",
    description: "",
    educationAndExperience: "",
    additionalRequirements: "",
    postingSupplements: {},
    preferredQualifications: "",
    minimumQualifications: "",
    postingTitle: "",
    selectedLocale: "",
    localizations: {},
  };
}

export default function JobDetails() {
  const headingRef = useAutoFocus<HTMLHeadingElement>();
  const { appUIState } = useContext(AppContext);
  const { isInternal, locale, countryCode, baseUrl } = appUIState.appData;
  const { jobId } = useParams();
  const prevLocation = useLocation();
  const [searchParams] = useSearchParams();
  const loaderData = useRouteLoaderData(RouteID.jobDetails) as any;
  const links = loaderData.metaLinks ? loaderData.metaLinks : [];
  const team = searchParams.get("team") || "";
  const [jobData, setJobData] = useState({
    ...getInitialJobData(loaderData.jobsData),
  });

  const {
    jobSummary = "",
    keyQualifications = "",
    description = "",
    educationAndExperience = "",
    additionalRequirements = "",
    postingSupplements = {},
    preferredQualifications = "",
    minimumQualifications = "",
    postingTitle = "",
    selectedLocale = "",
    localizations = {},
    internalDetails = "",
    internalInformation = {},
    isInternalJobDetails = "",
    postDateInGMT,
    localeInfo,
    postingDateMeta,
    standardWeeklyHours,
    jobNumber,
    homeOffice,
    translationCd,
  } = jobData;
  const { t } = useIntlMessage();
  const [selectedLang, setSelectedLang] = useState(translationCd); // lang attribute which gets updated on locale select dropdown change
  const isMobile = useIsMobile();
  const jobDetails = "jobdetails";
  let { postingDate } = jobData;
  const { pageTitle, meta } = getPageTitleAndMeta(
    isInternal,
    baseUrl,
    locale,
    t,
    postingTitle,
    countryCode,
    loaderData.requestUrl,
    false,
    jobData.jobType,
  );
  const { teamName } = getTeam(team, localizations, selectedLocale);
  const { location, store } = getLocation(localizations, selectedLocale);
  const eeoContent =
    getEEOContent(localizations, selectedLocale) || jobData?.eeoContent || "";
  const localSelectId = idGenerator(jobDetails, "localeselect").generateId();
  const accordionItemClasses = {
    content: "t-body",
    title: "t-label fw-medium",
    icon: "rc-accordion-plus-icon",
  };
  const directionForMainContent = getDirectionForMainContent(jobData);
  const handleFavoriteChange = (isFavorited: boolean) => {
    setJobData({
      ...jobData,
      isFavorited,
    });
  };
  const handleShareJob = () => {};
  const handleSubmitResume = () => {};

  const localeSelectHandler = (selectedLocale: string) => {
    setSelectedLang(findSelectedLocaleTranslation(selectedLocale));
    setJobData(getLocalizedJobDetails(jobData, selectedLocale));
  };

  const isLocaleSelectEnabled =
    jobData &&
    !isEmpty(jobData.localeLanguages) &&
    jobData.localeLanguages.length > 1;
  const localeSelect = isLocaleSelectEnabled ? renderLocaleSelect() : null;
  const jobDetailTranslations =
    jobData && jobData?.translations && jobData.translations[selectedLocale];

  const { trackPageLoad } = useDtm();

  function renderLocaleSelect() {
    return (
      <div id={localSelectId} className={"job-details-locale-select"}>
        <JobDetailsLocaleSelect
          locales={jobData.localeLanguages || []}
          selectedLocale={jobData.selectedLocale}
          localeSelectHandler={localeSelectHandler}
        ></JobDetailsLocaleSelect>
      </div>
    );
  }

  const findSelectedLocaleTranslation = useCallback(
    (selectedLocale: string) => {
      let selectedLocaleTranslation =
        translationCd || DEFAULT_TRANSLATION_LOCALE;
      const localeSelected = jobData.localeLanguages.filter(
        (locale: any) => locale.code === selectedLocale,
      );
      if (!isEmpty(localeSelected) && localeSelected[0].translation) {
        selectedLocaleTranslation = localeSelected[0].translation;
      }
      return selectedLocaleTranslation;
    },
    [jobData.localeLanguages, translationCd],
  );

  const getTitleContent = () => {
    const selectedLocaleTranslation =
      findSelectedLocaleTranslation(selectedLocale);
    if (postDateInGMT && typeof window !== "undefined") {
      const dateFormat = jobDetailTranslations
        ? jobDetailTranslations["jobsite.common.mediumDate"]
        : localeInfo.dateFormat;
      postingDate = dateUtil.getLocaleDate(
        selectedLocaleTranslation,
        postDateInGMT,
        dateFormat,
      );
    }
    const postingLocaleDate = postingDate;

    const postingDateLabel = jobDetailTranslations
      ? jobDetailTranslations["jobsite.jobdetails.jobPosted"]
      : t("jobsite.jobdetails.jobPosted", {
          postedDate: `
                <strong><time id="${idGenerator(jobDetails, "posteddate").generateId()}" dateTime=${postingDateMeta}>${postingLocaleDate}</time></strong>`,
        });
    const weeklyHoursLabel = jobDetailTranslations
      ? jobDetailTranslations["jobsite.jobdetails.weeklyHoursFulltime"]
      : t("jobsite.jobdetails.weeklyHoursFulltime", {
          fullTime: `<strong id="${idGenerator(jobDetails, "weeklyhours").generateId()}">${standardWeeklyHours}</strong>`,
        });
    const yesLabel = jobDetailTranslations
      ? jobDetailTranslations["jobsite.common.yes"]
      : t("jobsite.common.yes");
    const noLabel = jobDetailTranslations
      ? jobDetailTranslations["jobsite.common.no"]
      : t("jobsite.common.no");

    return (
      <div>
        {postingDate ? (
          <HtmlRenderer
            htmlTag="div"
            initialContent={translateReplace(
              postingDateLabel,
              ["{postedDate}"],
              [
                `
                <strong><time id="${idGenerator(jobDetails, "jobpostdate").generateId()}" dateTime=${postingDateMeta}>${postingLocaleDate}</time></strong>`,
              ],
            )}
            classes="t-body-reduced-tight d-flex flex-wrap"
            id={idGenerator(jobDetails, "postingdate").generateId()}
          />
        ) : null}

        {standardWeeklyHours ? (
          <HtmlRenderer
            htmlTag="div"
            initialContent={translateReplace(
              weeklyHoursLabel,
              ["{fullTime}"],
              [
                `<strong id="${idGenerator(jobDetails, "weeklyhours").generateId()}">${standardWeeklyHours}</strong>`,
              ],
            )}
            classes="t-body-reduced-tight d-flex flex-wrap"
            id={idGenerator(jobDetails, "weeklyworkinghours").generateId()}
          />
        ) : null}
        {jobNumber ? (
          <div
            id={idGenerator(jobDetails, "rolenumber").generateId()}
            className="t-body-reduced-tight d-flex flex-wrap"
          >
            {jobDetailTranslations
              ? jobDetailTranslations["jobsite.jobdetails.roleNumber"]
              : t("jobsite.jobdetails.roleNumber")}
            <strong
              id={idGenerator(jobDetails, "jobnumber").generateId()}
              itemProp="identifier"
            >
              {jobNumber}
            </strong>
          </div>
        ) : null}
        {isInternal || homeOffice ? (
          <div
            id={idGenerator(jobDetails, "jobhomeoffice").generateId()}
            className="t-body-reduced-tight d-flex flex-wrap"
          >
            {jobDetailTranslations
              ? jobDetailTranslations["jobsite.jobdetails.jobHomeOffice"]
              : t("jobsite.jobdetails.jobHomeOffice")}
            <strong id={idGenerator(jobDetails, "homeoffice").generateId()}>
              {" "}
              {homeOffice ? yesLabel : noLabel}
            </strong>
          </div>
        ) : null}
      </div>
    );
  };

  function getJobDetailsSection(
    id: string,
    content: string,
    type: "list" | "text" = "text",
    title: string = "",
  ) {
    return (
      <JobDetailsRowContainer
        id={id}
        content={content}
        type={type}
        title={title}
      />
    );
  }

  const jobDescription = description
    ? getJobDetailsSection(
        "jobdescription",
        description,
        "text",
        getTranslatedContentHeading(
          jobDetailTranslations,
          "jobsite.jobdetails.description",
          t,
        ),
      )
    : null;

  const jobMinQualification = minimumQualifications
    ? getJobDetailsSection(
        "minimumqualifications",
        minimumQualifications,
        "list",
        getTranslatedContentHeading(
          jobDetailTranslations,
          "jobsite.jobdetails.minimumQualifications",
          t,
        ),
      )
    : null;

  const jobKeyQualifications = keyQualifications
    ? getJobDetailsSection(
        "keyqualifications",
        keyQualifications,
        "list",
        getTranslatedContentHeading(
          jobDetailTranslations,
          "jobsite.jobdetails.keyQualifications",
          t,
        ),
      )
    : null;

  const jobPreferredQualifications = preferredQualifications
    ? getJobDetailsSection(
        "preferredqualifications",
        preferredQualifications,
        "list",
        getTranslatedContentHeading(
          jobDetailTranslations,
          "jobsite.jobdetails.preferredQualifications",
          t,
        ),
      )
    : null;

  const jobEducationAndExperience = educationAndExperience
    ? getJobDetailsSection(
        "educationexperience",
        educationAndExperience,
        "text",
        getTranslatedContentHeading(
          jobDetailTranslations,
          "jobsite.jobdetails.eduAndExp",
          t,
        ),
      )
    : null;

  const jobAdditionalRequirements = additionalRequirements
    ? getJobDetailsSection(
        "additionalrequirements",
        additionalRequirements,
        "list",
        getTranslatedContentHeading(
          jobDetailTranslations,
          "jobsite.jobdetails.addlReq",
          t,
        ),
      )
    : null;

  function getPostingSupplementFooter(
    index: number,
    content: string,
    label: string,
  ) {
    return (
      <JobDetailsRowContainer
        key={index}
        id={`postingsupplementfooter-${index}`}
        content={content}
        type="text"
        title={label}
      />
    );
  }

  function getAccordionItem(
    key: string,
    content: React.ReactNode,
    title?: string,
  ) {
    return (
      <AccordionItem
        titleWrapperTag={"h2"}
        noPadding={true}
        key={key}
        title={title}
        expandableIconsPlus={true}
        clickableTitle={true}
        classes={accordionItemClasses}
      >
        {content}
      </AccordionItem>
    );
  }

  const updateRolesInSession = useCallback(() => {
    if (SessionStorage.get(RECENTLY_VIEWED_ROLES)) {
      let viewedRoles = JSON.parse(
        SessionStorage.get(RECENTLY_VIEWED_ROLES) as any,
      );
      viewedRoles = viewedRoles.filter((role: any) => role.reqId !== jobId);
      viewedRoles.push({
        reqId: jobId,
        postingTitle: jobData.postingTitle,
        jobNumber: jobData.jobNumber,
      });
      SessionStorage.set(
        RECENTLY_VIEWED_ROLES,
        JSON.stringify(viewedRoles.slice(-5)), // we show only maximum 5 recently viewed roles
      );
    } else {
      const viewedRoles = [
        {
          reqId: jobId,
          postingTitle: jobData.postingTitle,
          jobNumber: jobData.jobNumber,
        },
      ];
      SessionStorage.set(RECENTLY_VIEWED_ROLES, JSON.stringify(viewedRoles));
    }
  }, [jobData.jobNumber, jobData.postingTitle, jobId]);
  useEffect(() => {
    const pageInfo = {
      pageName: transformUrl(jobData.postingTitle),
      locale: appUIState?.appData?.locale || APP_CONSTANTS.DEFAULT_LOCALE,
      title: APP_CONSTANTS.ANALYTICS.PAGE_NAME.JOB_DETAILS,
      isInternal: appUIState?.appData?.isInternal,
      jobType: jobData?.jobType,
      jobId: jobId,
    };
    trackPageLoad(pageInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    updateRolesInSession();
  }, [updateRolesInSession]);
  return (
    <div
      id={idGenerator(jobDetails, "wrapper").generateId()}
      dir={directionForMainContent}
      lang={selectedLang}
    >
      <Helmet title={pageTitle} meta={meta} link={[...links]} />
      {jobData && jobData.id ? (
        <>
          <div>
            <main
              className={`text-center ${isLocaleSelectEnabled ? "" : "mt-50"}`}
            >
              {localeSelect}
              <h1
                id={idGenerator(jobDetails, "postingtitle").generateId()}
                className={`t-headline fw-medium ${isMobile ? "mx-30" : ""}`}
                ref={headingRef}
                tabIndex={0}
              >
                {postingTitle}
              </h1>
              <div className={`mt-20 row`}>
                {store && (
                  <label className={"t-intro column large-12"}>{store}</label>
                )}
                <label
                  id={idGenerator(jobDetails, "joblocation").generateId()}
                  className={`column large-12 t-intro ${store ? "mt-0" : ""}`}
                >
                  {location}
                </label>
                <label
                  id={idGenerator(jobDetails, "teamname").generateId()}
                  className={"column large-12 t-intro mt-0"}
                >
                  {teamName}
                </label>
              </div>
              <JobDetailHeader
                id={"jobdetailheader"}
                handleFavoriteChange={handleFavoriteChange}
                handleShareJob={handleShareJob}
                handleSubmitResume={handleSubmitResume}
                jobData={jobData}
                previousLocation={prevLocation}
              ></JobDetailHeader>
              <section className="hyperlink-underline">
                {isInternalJobDetails && internalDetails && (
                  <InternalJobDetails
                    id={"internaldetails"}
                    title={getTranslatedContentHeading(
                      jobDetailTranslations,
                      "jobsite.jobdetails.internalDetails",
                      t,
                    )}
                    content={internalDetails}
                  />
                )}

                <JobDetailsRowContainer
                  id={"jobsummary"}
                  content={jobSummary}
                  type={"text"}
                  title={getTranslatedContentHeading(
                    jobDetailTranslations,
                    "jobsite.jobdetails.summary",
                    t,
                  )}
                  titleContent={getTitleContent()}
                />
                {isMobile && (
                  <Accordion compact={true}>
                    {description &&
                      getAccordionItem(
                        `job-description-${jobData.reqId}`,
                        jobDescription,
                        getTranslatedContentHeading(
                          jobDetailTranslations,
                          "jobsite.jobdetails.description",
                          t,
                        ),
                      )}

                    {minimumQualifications &&
                      getAccordionItem(
                        `minimum-qualifications-${jobData.reqId}`,
                        jobMinQualification,
                        getTranslatedContentHeading(
                          jobDetailTranslations,
                          "jobsite.jobdetails.minimumQualifications",
                          t,
                        ),
                      )}

                    {keyQualifications &&
                      getAccordionItem(
                        `key-qualifications-${jobData.reqId}`,
                        jobKeyQualifications,
                        getTranslatedContentHeading(
                          jobDetailTranslations,
                          "jobsite.jobdetails.keyQualifications",
                          t,
                        ),
                      )}

                    {preferredQualifications &&
                      getAccordionItem(
                        `preferred-qualifications-${jobData.reqId}`,
                        jobPreferredQualifications,
                        getTranslatedContentHeading(
                          jobDetailTranslations,
                          "jobsite.jobdetails.preferredQualifications",
                          t,
                        ),
                      )}

                    {educationAndExperience &&
                      getAccordionItem(
                        `education-experience-${jobData.reqId}`,
                        jobEducationAndExperience,
                        getTranslatedContentHeading(
                          jobDetailTranslations,
                          "jobsite.jobdetails.eduAndExp",
                          t,
                        ),
                      )}

                    {additionalRequirements &&
                      getAccordionItem(
                        `additional-requirements-${jobData.reqId}`,
                        jobAdditionalRequirements,
                        getTranslatedContentHeading(
                          jobDetailTranslations,
                          "jobsite.jobdetails.addlReq",
                          t,
                        ),
                      )}

                    {postingSupplements &&
                      postingSupplements.footer &&
                      postingSupplements.footer.map(
                        (
                          footer: { content: string; label: string },
                          index: number,
                        ) => (
                          <AccordionItem
                            titleWrapperTag={"h2"}
                            noPadding={true}
                            key={`posting-supplement-footer-${index}-${jobData.reqId}`}
                            title={footer.label}
                            expandableIconsPlus={true}
                            clickableTitle={true}
                            classes={accordionItemClasses}
                          >
                            {getPostingSupplementFooter(
                              index,
                              footer.content,
                              footer.label,
                            )}
                          </AccordionItem>
                        ),
                      )}

                    {eeoContent && (
                      <AccordionItem
                        titleWrapperTag={"h2"}
                        noPadding={true}
                        key={`eeo-statement-${jobData.reqId}`}
                        expandableIconsPlus={true}
                        clickableTitle={true}
                        classes={accordionItemClasses}
                      >
                        {getJobDetailsSection(
                          "eeo-statement",
                          eeoContent,
                          "text",
                        )}
                      </AccordionItem>
                    )}

                    {isInternalJobDetails && internalInformation && (
                      <AccordionItem
                        titleWrapperTag={"h2"}
                        noPadding={true}
                        key={`internal-information-${jobData.reqId}`}
                        expandableIconsPlus={true}
                        clickableTitle={true}
                        classes={accordionItemClasses}
                        title={getTranslatedContentHeading(
                          jobDetailTranslations,
                          "jobsite.jobdetails.internalInformation",
                          t,
                        )}
                      >
                        <InternalJobInformationContainer
                          id={"internalinformation"}
                          title={getTranslatedContentHeading(
                            jobDetailTranslations,
                            "jobsite.jobdetails.internalInformation",
                            t,
                          )}
                          jobDetails={internalInformation}
                          jobDetailsTranslations={jobDetailTranslations}
                        />
                      </AccordionItem>
                    )}
                  </Accordion>
                )}
                {!isMobile && jobDescription}
                {!isMobile && jobMinQualification}
                {!isMobile && jobKeyQualifications}
                {!isMobile && jobPreferredQualifications}
                {!isMobile && jobEducationAndExperience}
                {!isMobile && jobAdditionalRequirements}
                {!isMobile &&
                  postingSupplements &&
                  postingSupplements.footer &&
                  postingSupplements.footer.map(
                    (
                      footer: { content: string; label: string },
                      index: number,
                    ) =>
                      getPostingSupplementFooter(
                        index,
                        footer.content,
                        footer.label,
                      ),
                  )}
                {!isMobile &&
                  eeoContent &&
                  getJobDetailsSection("eeo-statement", eeoContent, "text")}
                {!isMobile && isInternalJobDetails && internalInformation && (
                  <InternalJobInformationContainer
                    id="internalinformation"
                    title={getTranslatedContentHeading(
                      jobDetailTranslations,
                      "jobsite.jobdetails.internalInformation",
                      t,
                    )}
                    jobDetails={internalInformation}
                    jobDetailsTranslations={jobDetailTranslations}
                  />
                )}
              </section>
              <JobDetailFooter
                id="jobdetailfooter"
                jobData={jobData}
                handleFavoriteChange={handleFavoriteChange}
                handleShareJob={handleShareJob}
                handleSubmitResume={handleSubmitResume}
                previousLocation={prevLocation}
              ></JobDetailFooter>
            </main>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
}
