import React, { useContext, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useRouteLoaderData } from "react-router";
import APP_CONSTANTS from "../../../utilities/appConstants";
import AppContext from "../../AppContext";
import useDtm from "../../hooks/useAdobeAnalytics";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { useSearchContext } from "../../hooks/useSearchContext";
import useSearchPageQueryParams from "../../hooks/useSearchPageQueryParams";
import { RouteID } from "../../routes";
import { idGenerator } from "../../utils/idGenerator";
import {
  getAppliedFilterNames,
  getSearchPageTitle,
} from "../../utils/pageTitleUtil";
import FiltersSectionAccordion from "./FiltersSectionAccordion";

const FiltersSection: React.FC = () => {
  const searchLoaderData = useRouteLoaderData(RouteID.search) as any;
  const { t } = useIntlMessage();
  const { state } = useSearchContext();
  const { setFilters } = useSearchPageQueryParams();
  const { appUIState } = useContext(AppContext);
  const isMobile = useIsMobile();
  const allFiltersNames = getAppliedFilterNames(state.filters);
  const { pageTitle, meta, links } = getSearchPageTitle(
    t,
    allFiltersNames,
    appUIState,
    searchLoaderData.requestUrl,
    searchLoaderData?.searchMeta,
    state.totalRecords,
    state.page,
  );
  const isFirstRender = useRef(true);
  const { trackPageLoad, trackFilterChange } = useDtm();
  useEffect(() => {
    const pageInfo = {
      pageName: APP_CONSTANTS.ANALYTICS.PAGE_NAME.SEARCH,
      locale: appUIState?.appData?.locale || APP_CONSTANTS.DEFAULT_LOCALE,
      title: APP_CONSTANTS.ANALYTICS.PAGE_NAME.SEARCH,
      isInternal: appUIState?.appData?.isInternal,
    };
    trackPageLoad(pageInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const hiringManagersNameArray =
      state?.filters?.hiringManagers?.map((mgr) => mgr.uniqueKey || mgr.id) ||
      [];
    const jobLevelIdArray =
      state?.filters?.jobLevel?.map((level) => level.id) || [];
    setFilters(
      {
        location: state.filters.locations || [],
        key: state.filters.keywords || [],
        team: state.filters.teams || [],
        product: state.filters.products || [],
        lang: state.filters.languages || [],
        search: state.search || "",
        sort: state.sort || "",
        page: state.page || 1,
        homeOffice:
          state && state.filters && state.filters.homeOffice ? "true" : "false",
        role: state.filters.retailRoles || [],
        minHours:
          state && state.filters && state.filters.minimumHours
            ? state.filters.minimumHours
            : "",
        maxHours:
          state && state.filters && state.filters.maximumHours
            ? state.filters.maximumHours
            : "",
        hiringManager: hiringManagersNameArray,
        jobLevel: jobLevelIdArray || [],
      },
      state.preventScrollReset,
    );
    trackFilterChange(state.filters, state.search, state.sort, state.page);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filters, state.search, state.sort, state.page]);
  return (
    <>
      <Helmet title={pageTitle} meta={[...meta]} link={[...links]} />
      {!isMobile && (
        <div
          className={`filters-section py-30 pl-0 pr-20 ${!state.filterVisible ? "hide" : ""}`}
          id={idGenerator("search", "filters").generateId("container")}
        >
          <h2 className={"t-label fw-medium"}>
            {t("jobsite.search.refineBy")}
          </h2>
          <FiltersSectionAccordion />
        </div>
      )}
    </>
  );
};

export default FiltersSection;
