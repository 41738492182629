import { formatDateByLocale } from "@rpe-js/core/dist/util/dateUtil";
import {
  Button,
  MultiLinDropdown,
  MultiLinDropdownOptionProps,
  TextButton,
} from "@rpe-js/marcom-web-components";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RefData } from "../../../../shared/types/refData";
import {
  SelfDisclosureConfig,
  SelfDisclosures as SelfDisclosuresRefData,
} from "../../../../shared/types/selfDisclosures";
import {
  CountrySpecificSelfDisclosureConfig,
  Disability,
  DisabilityInfo,
  SelfDisclosure,
} from "../../../../shared/types/talent/talent";
import { getSelfDisclosureRefData } from "../../../api/fetchClient";
import {
  COUNTRY_USA,
  ETHNICITY,
  GENDER,
  NATIONALITY,
  VETERAN_STATUS,
} from "../../../app.constants";
import AppContext from "../../../AppContext";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import ApplyActionContainer from "../../../pages/apply/ApplyActionContainer";
import { SelfDisclosureHeader } from "../../../pages/apply/SelfDisclosureHeader";
import { idGenerator } from "../../../utils/idGenerator";
import TextIcon from "../../base/TextIcon";
import { DisabilityModal } from "../selfDisclosureModals/DisabilityModal";
import { GenderModal } from "../selfDisclosureModals/GenderModal";
import { VeteranStatusModal } from "../selfDisclosureModals/VeteranStatusModal";
import { useSelfDisclosure } from "./useSelfDisclosure";

interface OnChangePayload {
  type: string;
  option: MultiLinDropdownOptionProps | Disability;
}

interface SelfDisclosureWidgetProps {
  noHeader?: boolean; // set to true if no header is required
  noActions?: boolean; // set to true if no action buttons are required
  noLeftPadding?: boolean; // set to true if left padding of 20px not required
  hideDisabilityCompletedOn?: boolean; // set to true if no completed label is required
  locale: string; // locale used for localisation of strings
  dataLocale?: string; // locale used for api data fetch
  selfDisclosureConfig:
    | SelfDisclosureConfig
    | CountrySpecificSelfDisclosureConfig; // fields required for display
  selectedSelfDisclosure: SelfDisclosure; // values for each field
  selfDisclosuresRefData?: SelfDisclosuresRefData | undefined;
  onSelfDisclosureFieldChange: (
    field: {
      type: string;
      option: MultiLinDropdownOptionProps | Disability;
    },
    hasError: boolean,
  ) => void; //  callback for each field change
  showError?: boolean; // set to true if error to be shown
  showReviewSubmit?: boolean; // extra review submit action button specifically added for apply flow
  moveNext: (selfDisclosureData: any) => void; // callback for next step
  goPrevious: () => void; // callback for previous step
  reviewSubmit?: () => void; // callback for review submit specifically added for apply flow
}

function mapper(item: RefData): MultiLinDropdownOptionProps {
  return {
    id: item.id,
    title: item.name,
    text: "",
    value: item.name,
    disabled: false,
  };
}

export function SelfDisclosureWidget({
  locale,
  dataLocale,
  showError = false,
  noHeader = false,
  noActions = false,
  noLeftPadding = false,
  hideDisabilityCompletedOn = false,
  showReviewSubmit = false,
  selfDisclosureConfig,
  selectedSelfDisclosure,
  selfDisclosuresRefData,
  onSelfDisclosureFieldChange,
  moveNext,
  goPrevious,
  reviewSubmit,
}: SelfDisclosureWidgetProps) {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const { appUIState } = useContext(AppContext);
  const { countryCode } = appUIState.appData;
  const headerId = idGenerator("selfdisclosure", "header").generateId();
  const genderModalId = idGenerator("selfdisclosure", "gender").generateId(
    "modal",
  );
  const veteranStatusModalId = idGenerator(
    "selfdisclosure",
    "veteranstatus",
  ).generateId("modal");

  const DisabilityModalId = idGenerator(
    "selfdisclosure",
    "disabilitymodal",
  ).generateId("modal");

  const selfDisclosureFormId = idGenerator(
    "selfdisclosure",
    "form",
  ).generateId();
  const selfDisclosureFooterActionsId = idGenerator(
    "selfdisclosure",
    "footer",
  ).generateId();
  const VETERAN_STATUS_MODAL_BUTTON = idGenerator(
    "selfdisclosure",
    "veteran-modal",
  ).generateId("button");
  const GENDER_STATUS_MODAL_BUTTON = idGenerator(
    "selfdisclosure",
    "gender-modal",
  ).generateId("button");

  const isUSAIndicator =
    selfDisclosureConfig && selfDisclosureConfig.countryID == COUNTRY_USA;

  const { getSelectedValueBasedOnLocale } = useSelfDisclosure();

  const [selectedGenderValue, setSelectedGenderValue] =
    useState<MultiLinDropdownOptionProps | null>(null);

  const [selectedEthnicityValue, setSelectedEthnicityValue] =
    useState<MultiLinDropdownOptionProps | null>(null);

  const [selectedNationalityValue, setSelectedNationalityValue] =
    useState<MultiLinDropdownOptionProps | null>(null);

  const [selectedVeteranStatusValue, setSelectedVeteranStatusValue] =
    useState<MultiLinDropdownOptionProps | null>(null);

  const [disabilityStatus, setDisabilityStatus] =
    useState<DisabilityInfo | null>(selectedSelfDisclosure?.disability || null);
  const [completedOn, setCompletedOn] = useState<string>(
    t("jobsite.common.completedDate", {
      date: formatDateByLocale(
        selectedSelfDisclosure?.disability?.completedOn
          ? new Date(
              selectedSelfDisclosure.disability?.completedOn as string,
            ).getTime()
          : new Date().getTime(),
        locale,
      ),
    }) as string,
  );

  // Self Disclosure Dropdown options
  const [genderOptions, setGenderOptions] = useState<
    MultiLinDropdownOptionProps[]
  >([]);
  const [ethnicityOptions, setEthnicityOptions] = useState<
    MultiLinDropdownOptionProps[]
  >([]);
  const [nationalityOptions, setNationalityOptions] = useState<
    MultiLinDropdownOptionProps[]
  >([]);
  const [veteranStatusOptions, setVeteranStatusOptions] = useState<
    MultiLinDropdownOptionProps[]
  >([]);

  const [disabilityRefData, setDisabilityRefData] = useState<Array<RefData>>(
    [],
  );

  // Modal State Variables
  const [showGenderModal, setShowGenderModal] = useState(false);
  const [showVeteranStatusModal, setShowVeteranStatusModal] = useState(false);
  const [showDisabilityStatusModal, setShowDisabilityStatusModal] =
    useState(false);

  const [canShowError, setCanShowError] = useState(showError);

  useEffect(() => {
    setCanShowError(showError);
  }, [showError]);

  const updateSelectedSelfDisclosureInfo = useCallback(
    async (selfDisclosures: SelfDisclosuresRefData) => {
      // Call getSelectedValueBasedOnLocale with the selected dropdown value to update the selected value with localised string received in above getSelfDisclosureRefData response
      if (selfDisclosures.gender) {
        const genderRefData = selfDisclosures.gender.map(mapper);
        setGenderOptions([...genderRefData]);
        setSelectedGenderValue(
          getSelectedValueBasedOnLocale<MultiLinDropdownOptionProps>(
            genderRefData,
            selectedSelfDisclosure?.genderID,
          ),
        );
      }
      if (selfDisclosures.protectedVeteranStatus) {
        const protectedVeteranStatusRefData =
          selfDisclosures.protectedVeteranStatus.map(mapper);
        setVeteranStatusOptions([...protectedVeteranStatusRefData]);
        setSelectedVeteranStatusValue(
          getSelectedValueBasedOnLocale<MultiLinDropdownOptionProps>(
            protectedVeteranStatusRefData,
            selectedSelfDisclosure?.veteranStatus?.veteranStatusID,
          ),
        );
      }
      if (selfDisclosures.atpEthnicClass) {
        const atpEthnicClassRefData =
          selfDisclosures.atpEthnicClass.map(mapper);
        setEthnicityOptions([...selfDisclosures.atpEthnicClass.map(mapper)]);
        setSelectedEthnicityValue(
          getSelectedValueBasedOnLocale<MultiLinDropdownOptionProps>(
            atpEthnicClassRefData,
            selectedSelfDisclosure?.ethnicityID,
          ),
        );
      }
      if (selfDisclosures.atpNationality) {
        const atpNationalityRefData = selfDisclosures.atpNationality.map(
          (item: RefData) => {
            return {
              id: item.code,
              title: item.name,
              text: "",
              value: item.name,
              disabled: false,
            };
          },
        );
        setNationalityOptions([...atpNationalityRefData]);

        setSelectedNationalityValue(
          getSelectedValueBasedOnLocale<MultiLinDropdownOptionProps>(
            atpNationalityRefData,
            selectedSelfDisclosure?.nationalityID,
          ),
        );
      }
      if (selfDisclosures.disabilityStatus)
        setDisabilityRefData([...selfDisclosures.disabilityStatus]);
    },
    [
      getSelectedValueBasedOnLocale,
      selectedSelfDisclosure?.ethnicityID,
      selectedSelfDisclosure?.genderID,
      selectedSelfDisclosure?.nationalityID,
      selectedSelfDisclosure?.veteranStatus?.veteranStatusID,
    ],
  );

  const getSelfDisclosureInfo = useCallback(async () => {
    // Make refData call to fetch the refData for self disclosure If the selfDisclosuresRefData is not provided in props
    if (!selfDisclosuresRefData || !selfDisclosuresRefData?.gender?.length) {
      const selfDisclosures = await getSelfDisclosureRefData({
        headers: { locale: dataLocale || countryCode?.data },
        queryParams: { countryId: selfDisclosureConfig.countryID as string },
      });
      if (selfDisclosures) {
        updateSelectedSelfDisclosureInfo(selfDisclosures);
      }
    } else if (
      selfDisclosuresRefData &&
      selfDisclosuresRefData?.gender?.length
    ) {
      updateSelectedSelfDisclosureInfo(selfDisclosuresRefData);
    }
  }, [
    countryCode?.data,
    dataLocale,
    selfDisclosureConfig.countryID,
    selfDisclosuresRefData,
    updateSelectedSelfDisclosureInfo,
  ]);

  const isValidField = useCallback(
    (field: MultiLinDropdownOptionProps | null) => {
      return field != null && field.id;
    },
    [],
  );

  const validateSelfDisclosure = useCallback(() => {
    if (selfDisclosureConfig) {
      if (selfDisclosureConfig.gender && !isValidField(selectedGenderValue)) {
        return false;
      }
      if (
        selfDisclosureConfig.nationality &&
        !isValidField(selectedNationalityValue)
      ) {
        return false;
      }
      if (
        selfDisclosureConfig.ethnicity &&
        !isValidField(selectedEthnicityValue)
      ) {
        return false;
      }
      if (
        selfDisclosureConfig.veteranStatus &&
        !isValidField(selectedVeteranStatusValue)
      ) {
        return false;
      }
      if (selfDisclosureConfig.disability && !disabilityStatus) {
        return false;
      }
    }
    return true;
  }, [
    disabilityStatus,
    isValidField,
    selectedEthnicityValue,
    selectedGenderValue,
    selectedNationalityValue,
    selectedVeteranStatusValue,
    selfDisclosureConfig,
  ]);

  const onChange = useCallback(
    (payload: OnChangePayload) => {
      onSelfDisclosureFieldChange(payload, validateSelfDisclosure());
    },
    [validateSelfDisclosure, onSelfDisclosureFieldChange],
  );

  const genderChange = useCallback(
    (evt: any, gender: MultiLinDropdownOptionProps) => {
      setSelectedGenderValue(gender);
      onChange({ type: GENDER, option: gender });
    },
    [onChange],
  );

  const ethnicityChange = useCallback(
    (evt: any, ethnicity: MultiLinDropdownOptionProps) => {
      setSelectedEthnicityValue(ethnicity);
      onChange({ type: ETHNICITY, option: ethnicity });
    },
    [onChange],
  );

  const nationalityChange = useCallback(
    (evt: any, nationality: MultiLinDropdownOptionProps) => {
      setSelectedNationalityValue(nationality);
      onChange({ type: NATIONALITY, option: nationality });
    },
    [onChange],
  );
  const veteranStatusChange = useCallback(
    (evt: any, veteranStatus: MultiLinDropdownOptionProps) => {
      setSelectedVeteranStatusValue(veteranStatus);
      onChange({
        type: VETERAN_STATUS,
        option: veteranStatus,
      });
    },
    [onChange],
  );

  const genderHelp = useCallback(() => {
    setShowGenderModal(true);
  }, []);

  const veteranStatusHelp = useCallback(() => {
    setShowVeteranStatusModal(true);
  }, []);

  const updateDisabilityForm = useCallback(() => {
    setShowDisabilityStatusModal(true);
  }, []);

  const onGenderModalClose = useCallback(() => {
    setShowGenderModal(false);
  }, []);

  const onVeteranStatusModalClose = useCallback(() => {
    setShowVeteranStatusModal(false);
  }, []);

  const onDisabilityModalSubmit = useCallback(
    (disabilityData: DisabilityInfo) => {
      setCompletedOn(
        t("jobsite.common.completedDate", {
          date: formatDateByLocale(new Date().getTime(), locale),
        }) as string,
      );
      setDisabilityStatus({ ...disabilityData });
      setShowDisabilityStatusModal(false);
      onChange({
        type: "disability",
        option: disabilityData,
      });
    },
    [locale, onChange, t],
  );

  const onDisabilityModalCancel = useCallback(() => {
    setShowDisabilityStatusModal(false);
  }, []);

  const onDisabilityModalClose = useCallback(() => {
    setShowDisabilityStatusModal(false);
  }, []);

  const moveToNextStep = useCallback(() => {
    const selfDisclosureData: any = {};

    if (selfDisclosureConfig.gender) {
      selfDisclosureData.genderID = selectedGenderValue?.id as string;
      selfDisclosureData.genderName = selectedGenderValue?.value as string;
    }
    if (selfDisclosureConfig.ethnicity) {
      selfDisclosureData.ethnicityID = selectedEthnicityValue?.id as string;
      selfDisclosureData.ethnicityName =
        selectedEthnicityValue?.value as string;
    }
    if (selfDisclosureConfig.nationality) {
      selfDisclosureData.nationalityID = selectedNationalityValue?.id as string;
      selfDisclosureData.nationalityName =
        selectedNationalityValue?.value as string;
    }
    if (selfDisclosureConfig.veteranStatus) {
      selfDisclosureData.veteranStatus = {
        veteranStatusID: selectedVeteranStatusValue?.id as string,
        veteranStatusName: selectedVeteranStatusValue?.value as string,
      };
    }
    if (selfDisclosureConfig.disability) {
      selfDisclosureData.disability = { ...disabilityStatus };
    }

    moveNext(selfDisclosureData);
  }, [
    selfDisclosureConfig.gender,
    selfDisclosureConfig.ethnicity,
    selfDisclosureConfig.nationality,
    selfDisclosureConfig.veteranStatus,
    selfDisclosureConfig.disability,
    moveNext,
    selectedGenderValue?.id,
    selectedGenderValue?.value,
    selectedEthnicityValue?.id,
    selectedEthnicityValue?.value,
    selectedNationalityValue?.id,
    selectedNationalityValue?.value,
    selectedVeteranStatusValue?.id,
    selectedVeteranStatusValue?.value,
    disabilityStatus,
  ]);

  useEffect(() => {
    if (!genderOptions.length) getSelfDisclosureInfo();
  }, [genderOptions, getSelfDisclosureInfo]);

  return (
    <>
      {!noHeader && (
        <section id={headerId} className="mtb-auto-40 text-center">
          <h1 className="t-headline-reduced mb-40">
            {t("jobsite.apply.selfDisclosure")}
          </h1>
          <SelfDisclosureHeader
            isUSAIndicator={isUSAIndicator}
            countryCode={
              selfDisclosureConfig.countryID
                ? selfDisclosureConfig.countryID
                    .replace("iso-country-", "")
                    .toUpperCase()
                : ""
            }
          ></SelfDisclosureHeader>
        </section>
      )}
      <section
        id={selfDisclosureFormId}
        className="mtb-auto-0 selfdisclosure-form"
      >
        {selfDisclosureConfig.gender && (
          <section
            className={`w-100 pos-rel ${noLeftPadding ? "" : "pl-20"} pr-20`}
          >
            <MultiLinDropdown
              required={true}
              id={selectedGenderValue?.id || ""}
              options={genderOptions}
              name={t("jobsite.apply.selectGender") as string}
              label={t("jobsite.apply.selectGender") as string}
              value={selectedGenderValue?.value || ""}
              onValueChange={genderChange}
              aria-labelledby=""
              error={
                canShowError &&
                (!selectedGenderValue || !selectedGenderValue.id)
                  ? (t("jobsite.apply.pleaseSelectGender") as string)
                  : ""
              }
            ></MultiLinDropdown>
            <section className="pos-abs top-20 right-0 t-caption">
              <TextButton
                id={GENDER_STATUS_MODAL_BUTTON}
                iconClasses="mr-0 label-grey"
                icon="icon-infocircle"
                onClick={genderHelp}
                aria-label={t("jobsite.apply.gender") as string}
              />
            </section>
          </section>
        )}
        {selfDisclosureConfig.ethnicity && (
          <section
            className={`w-100 pos-rel ${noLeftPadding ? "" : "pl-20"} pr-20`}
          >
            <MultiLinDropdown
              required={true}
              options={ethnicityOptions}
              id={selectedEthnicityValue?.id || ""}
              name={
                selectedEthnicityValue?.value ||
                (t("jobsite.apply.selectEthnicity") as string)
              }
              label={t("jobsite.apply.selectEthnicity") as string}
              value={selectedEthnicityValue?.value || ""}
              onValueChange={ethnicityChange}
              aria-labelledby=""
              error={
                canShowError &&
                (!selectedEthnicityValue || !selectedEthnicityValue.id)
                  ? (t("jobsite.apply.pleaseSelectEthnicity") as string)
                  : ""
              }
            ></MultiLinDropdown>
          </section>
        )}
        {selfDisclosureConfig.veteranStatus && (
          <section
            className={`w-100 pos-rel ${noLeftPadding ? "" : "pl-20"} pr-20`}
          >
            <MultiLinDropdown
              required={true}
              id={selectedVeteranStatusValue?.id || ""}
              options={veteranStatusOptions}
              name={t("jobsite.apply.selectProtectedVeteran") as string}
              label={t("jobsite.apply.selectProtectedVeteran") as string}
              value={selectedVeteranStatusValue?.value || ""}
              onValueChange={veteranStatusChange}
              aria-labelledby=""
              error={
                canShowError &&
                (!selectedVeteranStatusValue || !selectedVeteranStatusValue.id)
                  ? (t("jobsite.apply.pleaseSelectProtectedVeteran") as string)
                  : ""
              }
            ></MultiLinDropdown>
            <section className="pos-abs top-20 right-0 t-caption">
              <TextButton
                id={VETERAN_STATUS_MODAL_BUTTON}
                iconClasses="mr-0 label-grey"
                icon="icon-infocircle"
                onClick={veteranStatusHelp}
                aria-label={t("jobsite.apply.protectedVeteraninfo") as string}
              />
            </section>
          </section>
        )}
        {selfDisclosureConfig.nationality && (
          <section
            className={`w-100 pos-rel ${noLeftPadding ? "" : "pl-20"} pr-20`}
          >
            <MultiLinDropdown
              required={true}
              id={selectedNationalityValue?.id || ""}
              options={nationalityOptions}
              label={t("jobsite.apply.selectNationality") as string}
              value={selectedNationalityValue?.value || ""}
              name={t("jobsite.apply.selectNationality") as string}
              onValueChange={nationalityChange}
              aria-labelledby=""
              error={
                canShowError &&
                (!selectedNationalityValue || !selectedNationalityValue.id)
                  ? (t("jobsite.apply.pleaseSelectNationality") as string)
                  : ""
              }
            ></MultiLinDropdown>
          </section>
        )}
        {selfDisclosureConfig.disability && (
          <section
            className={`d-flex justify-content-spacebetween w-100 pos-rel ${noLeftPadding ? "" : "pl-20"} pr-20 small-flex-column`}
          >
            <section
              className={`d-inline-block ${isMobile ? "w-100" : "w-75"}`}
            >
              <h3
                className={`t-body ${canShowError && (!disabilityStatus || !disabilityStatus.statusID) ? "error-message" : ""}`}
              >
                {t("jobsite.common.disabilityStatus")}
              </h3>
              {disabilityStatus && disabilityStatus.statusID && (
                <p>{disabilityStatus?.statusName}</p>
              )}
              {(!disabilityStatus || !disabilityStatus.statusID) && (
                <p>{t("jobsite.common.notSpecified") as string}</p>
              )}
              {disabilityStatus &&
                disabilityStatus.statusID &&
                !hideDisabilityCompletedOn && (
                  <p className="mt-0 label-grey">{completedOn}</p>
                )}
              {canShowError &&
                (!disabilityStatus || !disabilityStatus.statusID) && (
                  <p role="alert" aria-live="polite" className="t-caption">
                    <TextIcon
                      name="icon-exclamationcircle"
                      label={
                        t("jobsite.common.disabilityCompleteError") as string
                      }
                      textColor="red"
                      marginClass="ml-2"
                    ></TextIcon>
                  </p>
                )}
            </section>
            <section className="d-inline-block selfdisclosure-disability-updateformbtn">
              <Button
                id={idGenerator("selfdisclosure", "update-form").generateId(
                  "button",
                )}
                size="base"
                blockedVariant={true}
                label={t("jobsite.common.updateForm") as string}
                onClick={updateDisabilityForm}
                color="secondary"
              ></Button>
            </section>
          </section>
        )}
        {!noActions && (
          <section
            id={selfDisclosureFooterActionsId}
            className={`d-flex justify-center ${noLeftPadding ? "" : "pl-20"} pr-20`}
          >
            <ApplyActionContainer
              cancelLabelName={t("jobsite.common.back") as string}
              saveLabelName={t("jobsite.common.continue") as string}
              reviewLabelName={t("jobsite.common.reviewSubmit") as string}
              showReviewButton={showReviewSubmit}
              onBack={goPrevious}
              onContinue={() => {
                setCanShowError(true);
                if (validateSelfDisclosure()) moveToNextStep();
              }}
              onReviewSubmit={() => {
                setCanShowError(true);
                if (validateSelfDisclosure()) if (reviewSubmit) reviewSubmit();
              }}
            ></ApplyActionContainer>
          </section>
        )}
      </section>
      {showGenderModal && (
        <GenderModal
          id={genderModalId}
          showModal={showGenderModal}
          closeModal={onGenderModalClose}
          isUSAIndicator={isUSAIndicator}
          triggeredButtonId={GENDER_STATUS_MODAL_BUTTON}
        ></GenderModal>
      )}
      {showVeteranStatusModal && (
        <VeteranStatusModal
          id={veteranStatusModalId}
          locale={locale}
          showModal={showVeteranStatusModal}
          closeModal={onVeteranStatusModalClose}
          triggeredButtonId={VETERAN_STATUS_MODAL_BUTTON}
        ></VeteranStatusModal>
      )}
      {showDisabilityStatusModal && (
        <DisabilityModal
          id={DisabilityModalId}
          locale={locale}
          showModal={showDisabilityStatusModal}
          referenceData={disabilityRefData}
          disabilityData={disabilityStatus || null}
          onCancel={onDisabilityModalCancel}
          onSubmit={onDisabilityModalSubmit}
          onClose={onDisabilityModalClose}
          elementIdToFocus={idGenerator(
            "selfdisclosure",
            "update-form",
          ).generateId("button")}
        ></DisabilityModal>
      )}
    </>
  );
}
