import { Dropdown, Textbox } from "@rpe-js/marcom-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { BaseRefData } from "../../../../shared/types/refData";
import useIntlMessage from "../../../hooks/useIntlMessage";

import { Talent } from "../../../../shared/types";
import useIsMobile from "../../../hooks/useIsMobile";
import { idGenerator, ModuleName } from "../../../utils/idGenerator";
import { PreferredEmail } from "../emailVerification/preferredEmail";
import { useFormInputValidator } from "../form/inputValidators/useFormInputValidator";
import { NameView } from "../NameView";
import { PronounsView } from "../PronounsView";

interface ContactReviewSectionProps {
  module: ModuleName;
  isEdit: boolean;
  locale: string;
  countryData: BaseRefData[] | null;
  talent: Talent;
  canShowError: boolean;
  onContactFieldChange: (option: { key: string; value: string }) => void;
  onAddressFieldChange: (option: { key: string; value: any }) => void;
  onPronounsFieldChange?: (option: { key: string; value: boolean }) => void;
  showAddressSection: boolean;
}

export function ContactReviewSection({
  module,
  isEdit = true,
  countryData,
  talent,
  canShowError,
  onContactFieldChange,
  onAddressFieldChange,
  onPronounsFieldChange,
  showAddressSection,
}: ContactReviewSectionProps) {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const idSeqGenerator = idGenerator(module, "review");
  const { validateNameEntered: validateName, onPasteValidateName } =
    useFormInputValidator();
  const [countryList, setCountryList] = useState<any>(null);
  const [firstName, setFirstName] = useState<string>(
    talent?.contact?.firstName || "",
  );
  const [lastName, setLastName] = useState<string>(
    talent?.contact?.lastName || "",
  );
  const [preferredPhone, setPreferredPhone] = useState<string>(
    talent?.contact?.preferredPhone || "",
  );
  const [preferredEmail, setPreferredEmail] = useState<string>(
    talent?.contact?.preferredEmail || "",
  );

  const [localDisplayPronoun, setLocalDisplayPronoun] = useState<boolean>(
    talent?.displayPronouns || false,
  );

  const address = (talent &&
    talent.addresses &&
    talent.addresses.length &&
    talent.addresses[0]) || { countryID: "", state: "" };
  const [country, setCountry] = useState<string>(address.countryID || "");
  const [stateName, setStateName] = useState<string>(address.state || "");

  const updateCountryList = useCallback(() => {
    const defaultCountrySelect = [
      {
        label: "",
        value: "",
        disabled: true,
      },
    ];
    if (countryData) {
      setCountryList(
        defaultCountrySelect.concat(
          countryData.map((country: any) => ({
            label: country.name,
            value: country.id,
            disabled: false,
          })),
        ),
      );
    } else {
      setCountryList(defaultCountrySelect);
    }
  }, [countryData]);

  const getCountryValue = useCallback((talent: Talent) => {
    const address = (talent &&
      talent.addresses &&
      talent.addresses.length &&
      talent.addresses[0]) || { countryID: "", state: "" };
    return address.countryID;
  }, []);

  useEffect(() => {
    updateCountryList();
  }, [countryData, updateCountryList]);

  // Handler for pronoun toggle change
  const handleTogglePronouns = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalDisplayPronoun(event.target.checked);
    if (onPronounsFieldChange) {
      onPronounsFieldChange({
        key: "displayPronouns",
        value: event.target.checked,
      });
    }
  };

  return (
    <section
      className="mb-40"
      role="group"
      aria-labelledby="review-contact-info-header"
    >
      <h2
        className="u-border-bottom t-eyebrow-reduced pb-5"
        id="review-contact-info-header"
      >
        {t("jobsite.common.contactInfo")}
      </h2>
      {isEdit && (
        <section>
          <section className={`row mt-30 ${!isMobile ? "gap-10" : ""}`}>
            <section className="column large-grow small-12">
              <Textbox
                id={idSeqGenerator.generateId("firstname")}
                required={true}
                label={t("jobsite.common.firstName") as string}
                value={firstName}
                onValueChange={(value: string) => {
                  setFirstName(value);
                  onContactFieldChange({ key: "firstName", value });
                }}
                error={
                  canShowError && !firstName
                    ? (t("jobsite.common.errorfirstname") as string)
                    : ""
                }
                errorA11y={t("jobsite.common.errorIconLabel") as string}
                maxLength={32}
                onKeyDown={validateName}
                onPaste={onPasteValidateName}
              ></Textbox>
            </section>
            <section className="`column large-grow small-12">
              <Textbox
                id={idSeqGenerator.generateId("lastname")}
                required={true}
                label={t("jobsite.common.lastName") as string}
                value={lastName}
                onValueChange={(value: string) => {
                  setLastName(value);
                  onContactFieldChange({ key: "lastName", value });
                }}
                maxLength={32}
                error={
                  canShowError && !lastName
                    ? (t("jobsite.common.errorlastname") as string)
                    : ""
                }
                errorA11y={t("jobsite.common.errorIconLabel") as string}
                onKeyDown={validateName}
                onPaste={onPasteValidateName}
              ></Textbox>
            </section>
          </section>
          <section className="row mb-10">
            <div className="column large-9 small-12">
              <div className="row">
                <PreferredEmail
                  isEdit={isEdit}
                  preferredEmail={preferredEmail}
                  verifiedStatus={talent.emails?.preferredEmail.verifiedStatus}
                  onSuccess={(value: string) => {
                    setPreferredEmail(value);
                    onContactFieldChange({ key: "preferredEmail", value });
                  }}
                />
              </div>
            </div>
          </section>
          <section className="flex-column column flex-1">
            <Textbox
              id={idSeqGenerator.generateId("phone")}
              required={true}
              label={t("jobsite.common.preferredPhone") as string}
              value={preferredPhone}
              onValueChange={(value: string) => {
                setPreferredPhone(value);
                onContactFieldChange({ key: "preferredPhone", value });
              }}
              maxLength={20}
              error={
                canShowError && !preferredPhone
                  ? (t("jobsite.common.errorphone") as string)
                  : ""
              }
              errorA11y={t("jobsite.common.errorIconLabel") as string}
            ></Textbox>
          </section>
          {showAddressSection && (
            <>
              {countryList && (
                <section className="flex-column column flex-1">
                  <Dropdown
                    required={true}
                    id={idSeqGenerator.generateId("country")}
                    label={t("jobsite.apply.countryOfResidence") as string}
                    name={t("jobsite.apply.countryOfResidence") as string}
                    value={getCountryValue(talent) || ""}
                    options={countryList}
                    handleValueSelect={(evt: any, option: any) => {
                      setCountry(option.value);
                      onAddressFieldChange({ key: "country", value: option });
                    }}
                    error={
                      canShowError && !country
                        ? (t("jobsite.apply.errorCountryResidence") as string)
                        : ""
                    }
                    errorA11y={t("jobsite.common.errorIconLabel") as string}
                  ></Dropdown>
                </section>
              )}
              <section className="flex-column column flex-1">
                <Textbox
                  id={idSeqGenerator.generateId("state")}
                  required={true}
                  label={t("jobsite.common.state") as string}
                  value={stateName}
                  onValueChange={(value) => {
                    setStateName(value);
                    onAddressFieldChange({ key: "state", value });
                  }}
                  error={
                    canShowError && !stateName
                      ? (t("jobsite.common.errorState") as string)
                      : ""
                  }
                  errorA11y={t("jobsite.common.errorIconLabel") as string}
                ></Textbox>
              </section>
            </>
          )}
        </section>
      )}
      {!isEdit && (
        <>
          <div className="row mb-10">
            <div className="column large-6 small-12 d-flex flex-column">
              <NameView labelKey="firstName" value={firstName}></NameView>
            </div>
            <div className="column large-6 small-12 d-flex flex-column">
              <NameView labelKey="lastName" value={lastName}></NameView>
            </div>
          </div>
          <div className="row mb-10">
            <div className="column large-6 small-12 d-flex flex-column">
              <PreferredEmail
                isEdit={isEdit}
                classes={"large-12 small-12"}
                preferredEmail={preferredEmail}
                verifiedStatus={talent.emails?.preferredEmail.verifiedStatus}
              />
            </div>
            <div className="column large-6 small-12 d-flex flex-column">
              <PronounsView
                displayPronouns={localDisplayPronoun}
                onTogglePronouns={handleTogglePronouns}
                genderPronoun={talent?.genderPronoun}
              />
            </div>
          </div>
          <div className="column large-12 small-12 d-flex flex-column">
            <NameView
              labelKey="preferredPhone"
              value={
                preferredPhone || (t("jobsite.common.notSpecified") as string)
              }
            ></NameView>
          </div>
        </>
      )}
    </section>
  );
}
