import { useCallback, useState } from "react";
import { focusElement } from "../utils/focusUtil";
import { idGenerator } from "../utils/idGenerator";
import useIntlMessage from "./useIntlMessage";

export const useAlertState = () => {
  const [alert, setAlert] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(true);
  const [hideChildren, setHideChildren] = useState<boolean>(false);
  const { t } = useIntlMessage();
  const updateAlert = useCallback(
    (
      isError = true,
      hideChildren = false,
      message: string = t("jobsite.common.serviceError") as string,
    ) => {
      setAlert(message);
      setIsError(isError);
      setHideChildren(hideChildren);
      focusElement(idGenerator("global", "alert").generateId());
    },
    [t],
  );

  const deleteAlert = useCallback(() => {
    setAlert("");
    setHideChildren(false);
  }, []);

  return { alert, isError, hideChildren, updateAlert, deleteAlert };
};
