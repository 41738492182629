import { Textbox } from "@rpe-js/marcom-web-components";
import React from "react";
import { useFormInputValidator } from "../../../../components/feature/form/inputValidators/useFormInputValidator";
import useIntlMessage from "../../../../hooks/useIntlMessage";
import { ProfileFormField } from "../../types";
import { getFormFieldLabel } from "./utils";

export function NameTextBox(props: {
  profileFormField: ProfileFormField;
  showErrors?: boolean;
  onChange: (field: ProfileFormField, value: string) => void;
}) {
  const { profileFormField, onChange, showErrors } = props;
  const { t } = useIntlMessage();
  const { validateNameEntered, onPasteValidateName } = useFormInputValidator();

  return (
    <Textbox
      id={profileFormField.id as string}
      required={profileFormField.required || false}
      label={getFormFieldLabel(profileFormField as ProfileFormField, t)}
      maxLength={profileFormField.maxLength}
      error={
        showErrors && profileFormField.error
          ? (t(profileFormField.errori18nMsgKey as string, {
              ...(profileFormField.errori18nMsgParams || {}),
            }) as string)
          : false
      }
      errorA11y={t("jobsite.common.errorIconLabel") as string}
      onKeyDown={validateNameEntered}
      onPaste={onPasteValidateName}
      value={profileFormField.value}
      aria-required={profileFormField.required}
      onValueChange={(value) => {
        onChange(profileFormField as ProfileFormField, value);
      }}
    />
  );
}
