import { useEffect, useState } from "react";
import { scrollToFirstErrorField } from "../utils/focusUtil";

/**
 * @param parentID String that specifies from where code should start checking error fields
 */
export default function useFocusFirstErrorField(parentID: string) {
  const [focusFirstErrorField, setFocusFirstErrorField] = useState(false);

  useEffect(() => {
    if (focusFirstErrorField) {
      scrollToFirstErrorField(parentID);
      setFocusFirstErrorField(false);
    }
  }, [focusFirstErrorField, parentID]);

  return { setFocusFirstErrorField };
}
