import React, { useCallback } from "react";
import { MappedLanguages } from "../../../shared/types/refData";
import {
  FILTER_TYPE,
  SET_FILTER,
  UNSET_FILTER,
} from "../../contexts/actionTypes";
import { FilterValue, SEARCH_FILTER_TYPE } from "../../contexts/SearchContext";
import useIsMobile from "../../hooks/useIsMobile";
import { useSearchContext } from "../../hooks/useSearchContext";
import { FilterOption } from "./FilterCheckboxGroup";
import { LanguageFilter } from "./LanguageFilter";

export type MappedLanguagesFilterOptions = FilterOption<MappedLanguages>;

interface LanguageFilterWrapperProps {
  languageFilterData: Array<MappedLanguages>;
  onMobileFilterChange?: (
    filterType: SEARCH_FILTER_TYPE,
    filterValue: FilterValue,
  ) => void;
}

export const LanguageFilterWrapper = ({
  languageFilterData,
  onMobileFilterChange,
}: LanguageFilterWrapperProps) => {
  const { dispatch } = useSearchContext();
  const isMobile = useIsMobile();

  const modifiedLanguageData = languageFilterData.map((item) => {
    return { ...item, selected: true } as MappedLanguagesFilterOptions;
  });

  const updateMobileFilters = useCallback(
    (filterType: SEARCH_FILTER_TYPE, filterValue: FilterValue) => {
      if (onMobileFilterChange) onMobileFilterChange(filterType, filterValue);
    },
    [onMobileFilterChange],
  );

  // dispatch a filter action in case of desktop / invoke mobileFilterChange callback incase of mobile
  const onUpdateLanguages = useCallback(
    (languages: Array<MappedLanguagesFilterOptions>) => {
      if (!isMobile) {
        dispatch({
          type: SET_FILTER,
          filterName: FILTER_TYPE.LANGUAGES,
          payload: languages,
        });
      } else {
        updateMobileFilters(
          FILTER_TYPE.LANGUAGES as SEARCH_FILTER_TYPE,
          languages,
        );
      }
    },
    [dispatch, isMobile, updateMobileFilters],
  );

  // dispatch a filter action in case of desktop / invoke mobileFilterChange callback incase of mobile
  const onRemoveLanguages = useCallback(() => {
    if (!isMobile) {
      dispatch({
        type: UNSET_FILTER,
        filterName: FILTER_TYPE.LANGUAGES,
      });
    } else {
      updateMobileFilters(FILTER_TYPE.LANGUAGES as SEARCH_FILTER_TYPE, []);
    }
  }, [dispatch, isMobile, updateMobileFilters]);

  return (
    <LanguageFilter
      languageData={modifiedLanguageData}
      onUpdateLanguages={onUpdateLanguages}
      onRemoveLanguages={onRemoveLanguages}
    />
  );
};
