import React, { useCallback, useContext, useEffect } from "react";
import {
  AllowedMyApplicationUpdateAction,
  MyApplication,
} from "../../../../../shared/types/talent/myApplication";
import { SelfDisclosure } from "../../../../../shared/types/talent/talent";
import APP_CONSTANTS from "../../../../../utilities/appConstants";
import { COUNTRY_USA } from "../../../../app.constants";
import AppContext from "../../../../AppContext";
import { useCurrentUserContext } from "../../../../CurrentUserContext";
import useDtm from "../../../../hooks/useAdobeAnalytics";
import useSetPageTitle, {
  PAGE_TITLES,
} from "../../../../hooks/useSetPageTitle";
import { handleScrollToElement } from "../../../../utils/focusUtil";
import { SelfDisclosureConfig } from "../../../apply/context/ApplyState";
import { ActionContainer } from "../ActionContainer";
import { ContactInfoSection } from "../components/sections/contactInfo";
import { EducationSection } from "../components/sections/education";
import { EmployeeBackgroundSection } from "../components/sections/employeeBackground";
import { InformationReleaseSection } from "../components/sections/informationRelease";
import { LegalSection } from "../components/sections/legal";
import { ProfessionalExperienceSection } from "../components/sections/professionalExperience";
import { ReferenceSection } from "../components/sections/references";
import { SelfDisclosureSection } from "../components/sections/selfDisclosure";
import { useMyApplicationContext } from "../context/context";
import { MyApplicationActionDispatcher } from "../context/state";
import {
  ApplicationSection,
  ReviewSection,
  SectionMode,
} from "../context/types";
import { useUpdateApplication } from "../hooks/createAndUpdateApplicationHooks";
import { useGotoNextStep } from "../hooks/useGotoNextStep";
import { useSectionIdGenerator } from "../hooks/useSectionIdGenerator";

export function ReviewSubmitStep() {
  const COMPLETE = "COMPLETE";
  const { currentUser } = useCurrentUserContext();
  const { state, dispatch } = useMyApplicationContext();
  const { submitted, application } = state;
  const goToNextStep = useGotoNextStep();
  const { setPageTitle } = useSetPageTitle();
  const { reviewStep, currentStep, page, talent, selfDisclosureConfig } = state;
  const selfDisclosureData = talent?.selfDisclosures
    ? talent?.selfDisclosures[COUNTRY_USA]
    : {};
  const {
    contactInfoSectionId,
    eduSectionId,
    empBckSectionId,
    profExpSectionId,
    refSectionId,
    legSectionId,
    selfDisclosureSectionId,
    infoReleaseSectionId,
  } = useSectionIdGenerator("review");

  const {
    //isUpdateApplicationError,
    isUpdateApplicationLoading,
    isUpdateApplicationSuccess,
    updateApplication,
    resetUpdateApplication,
    updatedApplication,
  } = useUpdateApplication();

  const {
    contactInformation,
    employmentBackground,
    education,
    professionalExperience,
    references,
    legal,
    selfDisclosure: selfDisclosureMode,
    infoRelease,
  } = reviewStep;
  const { appUIState } = useContext(AppContext);
  const { trackPageLoad } = useDtm();

  const onSectionSave = useCallback(
    (
      sectionName: ApplicationSection | ReviewSection,
      mode: SectionMode,
      application: MyApplication,
    ) => {
      MyApplicationActionDispatcher.onApplicationSectionSave(
        dispatch,
        sectionName,
        mode,
        application,
      );
      // safari issue fix: added this to execute handlescroll in the end
      setTimeout(() => {
        handleScrollToElement(`profileApplication-review-${sectionName}`);
      }, 100);
    },
    [dispatch],
  );

  useEffect(() => {
    setPageTitle(
      PAGE_TITLES.YOUR_APPLICATION.REVIEW,
      currentStep ? page.stepMapping[currentStep] : "",
      page.stepNames.length,
    );
  }, [currentStep, page.stepMapping, page.stepNames.length, setPageTitle]);

  useEffect(() => {
    if (
      !isUpdateApplicationLoading &&
      isUpdateApplicationSuccess &&
      updatedApplication
    ) {
      MyApplicationActionDispatcher.onApplicationSubmit(
        dispatch,
        updatedApplication as MyApplication,
      );
      resetUpdateApplication();
      goToNextStep("next");
    }
  }, [
    dispatch,
    isUpdateApplicationLoading,
    isUpdateApplicationSuccess,
    updatedApplication,
    resetUpdateApplication,
    goToNextStep,
  ]);

  const submitApplication = useCallback(async () => {
    if (application && currentUser.talentId) {
      const pageInfo = {
        pageName: APP_CONSTANTS.ANALYTICS.YOURAPPLICATION_THANKS,
        locale: appUIState?.appData?.locale || APP_CONSTANTS.DEFAULT_LOCALE,
        title: APP_CONSTANTS.ANALYTICS.YOUR_APPLICATION,
        isInternal: appUIState?.appData?.isInternal,
      };
      if (typeof window !== "undefined") {
        trackPageLoad(pageInfo);
      }
      await updateApplication(
        currentUser.talentId,
        application?.id,
        AllowedMyApplicationUpdateAction.submit,
        {
          status: COMPLETE,
        } as MyApplication,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application, currentUser, updateApplication]);

  return (
    <>
      {
        <ul role="list" className="list-nobullet w-100 u-border-bottom">
          <li role="listitem">
            <ContactInfoSection
              id={contactInfoSectionId}
              mode={contactInformation}
              onSave={onSectionSave}
            />
          </li>
          <li role="listitem">
            <EmployeeBackgroundSection
              id={empBckSectionId}
              mode={employmentBackground}
              onSave={onSectionSave}
            />
          </li>
          <li role="listitem">
            <EducationSection
              id={eduSectionId}
              mode={education}
              onSave={onSectionSave}
            />
          </li>
          <li role="listitem">
            <ProfessionalExperienceSection
              id={profExpSectionId}
              mode={professionalExperience}
              onSave={onSectionSave}
            />
          </li>
          <li role="listitem">
            <ReferenceSection
              id={refSectionId}
              mode={references}
              onSave={onSectionSave}
            />
          </li>
          <li role="listitem">
            <LegalSection
              id={legSectionId}
              mode={legal}
              onSave={onSectionSave}
            />
          </li>
          <li role="listitem">
            <SelfDisclosureSection
              id={selfDisclosureSectionId}
              mode={selfDisclosureMode}
              selfDisclosureConfig={
                selfDisclosureConfig as SelfDisclosureConfig
              }
              selfDisclosure={selfDisclosureData as SelfDisclosure}
              onSave={onSectionSave}
            />
          </li>
          <li role="listitem">
            <InformationReleaseSection
              id={infoReleaseSectionId}
              mode={infoRelease}
              onSave={() => {}}
            />
          </li>
        </ul>
      }
      {!submitted && (
        <ActionContainer
          saveDisabled={!state.reviewSubmitStep.canSave}
          onSave={() => submitApplication()}
          onBack={() => goToNextStep("prev")}
          onSubmit={() => {}}
        />
      )}
    </>
  );
}
