import dateUtil, { formatDateMMDDYYYY } from "@rpe-js/core/dist/util/dateUtil";
import { Overlay } from "@rpe-js/marcom-web-components";
import Handlebars from "handlebars";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RefData } from "../../../../shared/types/refData";
import {
  Disability,
  DisabilityInfo,
} from "../../../../shared/types/talent/talent";
import APP_CONSTANTS from "../../../../utilities/appConstants";
import { getDisabilityTemplateForm } from "../../../api/fetchClient";
import AppContext from "../../../AppContext";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";
import { idGenerator } from "../../../utils/idGenerator";
import HtmlRendererWithScripts from "../../base/HtmlRendererWithScripts";
import { SaveCancelAction } from "../saveAndCancel";

interface DisabilityModalProps {
  id: string;
  locale: string;
  showModal: boolean;
  referenceData: Array<RefData>;
  disabilityData: DisabilityInfo | null;
  elementIdToFocus: string;
  onSubmit: (data: Disability) => void;
  onCancel: () => void;
  onClose: () => void;
}

export function DisabilityModal({
  id,
  locale,
  showModal,
  referenceData,
  disabilityData,
  elementIdToFocus,
  onSubmit,
  onCancel,
  onClose,
}: DisabilityModalProps) {
  const { t } = useIntlMessage();
  const [template, setTemplate] = useState<string>("");
  const { appUIState } = useContext(AppContext);
  const isMobile = useIsMobile();
  const [templateData] = useState({
    referenceData,
    isInternalNameValueExists:
      disabilityData && disabilityData.name && disabilityData.name.length > 0,
    employeeID: disabilityData?.employeeID || "",
    jobTitle: disabilityData?.jobTitle || "",
    dateOfHire: disabilityData?.dateOfHire || "",
    readonly: "disabled",
    disabilityAckName: disabilityData?.name,
    disabilityStatusDate: dateUtil.formatDateMMDDYYYY(Date.now(), locale),
    disabilityStatus: disabilityData?.statusID,
  });
  let disabilityStatusResult = { ...disabilityData };

  const showErrorField = (id: string) => {
    const element = document.querySelector<HTMLInputElement>(`${id}`);
    if (element) {
      element.style.display = "block";
      element.setAttribute("tabindex", "-1");
      element.focus();
    }
  };

  const clearErrorField = (id: string) => {
    const element = document.querySelector<HTMLInputElement>(`${id}`);
    if (element) {
      element.style.display = "none";
    }
  };

  const canSubmitDisabilityModal = (): any => {
    clearErrorField("#error-message_1_1");
    clearErrorField("#error-message_1_0");
    clearErrorField("#error-message_0_1");
    const disabilityStatus = document.querySelector<HTMLInputElement>(
      'input[name="disabilityStatusCd"]:checked',
    );
    const disabilityAckName =
      document.querySelector<HTMLInputElement>("#disabilityAckName");
    let error = false;
    if (disabilityStatus == null && disabilityAckName?.value == "") {
      showErrorField("#error-message_1_1"); // error to indicate both name and status not filled
      error = true;
    } else if (disabilityStatus == null) {
      showErrorField("#error-message_1_0"); // error to indicate status not filled
      error = true;
    } else if (disabilityAckName?.value == "") {
      showErrorField("#error-message_0_1"); // error to indicate name not filled
      error = true;
    }
    if (error) {
      return false;
    }
    const status = templateData.referenceData.filter((item: any) => {
      return item.id == disabilityStatus?.value;
    })[0];

    disabilityStatusResult = {
      employeeID: templateData.employeeID,
      jobTitle: templateData.jobTitle,
      dateOfHire: templateData.dateOfHire as Date,
      statusID: status.id,
      statusName: status.name,
      name: disabilityAckName?.value,
      completedOn: new Date(),
    };
    return true;
  };

  const onSubmitModal = () => {
    onSubmit(disabilityStatusResult as Disability);
  };

  const getDisabilityStatusForm = useCallback(async () => {
    const disabilityStatusForm = await getDisabilityTemplateForm({
      headers: { locale },
    });
    const disabilityTemplate = Handlebars.compile(
      disabilityStatusForm?.name || "",
    );

    const html = disabilityTemplate({
      ...templateData,
      dateOfHire: templateData.dateOfHire
        ? formatDateMMDDYYYY(
            new Date(templateData.dateOfHire).getTime(),
            locale,
          )
        : "",
    });
    setTemplate(html);
  }, [locale, templateData]);

  useEffect(() => {
    if (!template) getDisabilityStatusForm();
  }, [getDisabilityStatusForm, template]);

  return (
    <>
      {template && (
        <section>
          <Overlay
            id={id}
            wide={true}
            visible={showModal}
            isFullscreen={isMobile}
            onClose={onClose}
            disableEsc={false}
            closeButtonAttrs={{
              ariaLabel: t("jobsite.common.close") as string,
            }}
            elementIdToFocus={elementIdToFocus}
            footerContent={
              <div className="d-flex justify-center mb-40">
                <SaveCancelAction
                  onCancel={onCancel}
                  onSave={() => {
                    if (canSubmitDisabilityModal()) {
                      onSubmitModal();
                    }
                  }}
                  cancelLabelName={t("jobsite.common.cancel") as string}
                  saveLabelName={t("jobsite.common.submit") as string}
                  cancelButtonId={idGenerator(
                    "selfdisclosure",
                    "disability-modal",
                  ).generateId("cancel-button")}
                  saveButtonId={idGenerator(
                    "selfdisclosure",
                    "disability-modal",
                  ).generateId("submit-button")}
                />
              </div>
            }
            ariaLabel="disability-header"
          >
            <>
              <p className="text-center">
                {t("jobsite.common.residentsOutside")}
              </p>
              <div dir={APP_CONSTANTS.HTML_DIRECTION.LTR}>
                <HtmlRendererWithScripts
                  initialContent={template}
                  nc={appUIState.appData.jbNc}
                ></HtmlRendererWithScripts>
              </div>
              <p className="label-grey text-center">
                {t("jobsite.common.disabilityUpdateNote")}
              </p>
            </>
          </Overlay>
        </section>
      )}
    </>
  );
}
