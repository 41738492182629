/**
 * Examples: (All parent containers can have id's in the below form)
 * [Module]-[Container]
 * [Module]-[Container]-[SubContainer]

 * [apply]-[resumewidgetwrapper]
 * [apply]-[resume]-[supportingfile]
 *
 * Examples:
 * [Module]-[container]-[index]
 * [apply]-[resume]-[supportfile]-1
 * [apply]-[resume]-[supportfile]-2
 * [apply]-[resume]-[supportfile]-3
 * [apply]-[resume]-[supportlink]-1
 * [apply]-[resume]-[supportlink]-2
 * [apply]-[resume]-[supportlink]-3
 */
export type ModuleName =
  | "apply"
  | "profile"
  | "erp"
  | "getDiscovered"
  | "filter"
  | "global"
  | "page"
  | "alert"
  | "selfdisclosure"
  | "privacypolicy"
  | "savedsearches"
  | "yourroles"
  | "profileApplication"
  | "search"
  | "resume"
  | "linkedin"
  | "file"
  | "supportfile"
  | "supportlink"
  | "attachfile"
  | "jobdetails"
  | "email"
  | "signout"
  | "sessioninactivity"
  | "parsedmodal"
  | "locationPicker"
  | "shareJob"
  | "questionaire"
  | "emailverification"
  | "locationpicker"
  | "verifyEmail"; // should add types by individual

export function idGenerator(moduleName: ModuleName, containerName: string) {
  const indexMap = new Map();
  return {
    generateId: function (
      subContainerName: string = "",
      includeIndex: boolean = false,
    ) {
      let key = `${moduleName}-${containerName}`;

      if (subContainerName) {
        key = `${key}-${subContainerName}`;
      }

      // Retrieve or initialize the index
      const currentIndex = indexMap.get(key) || 0;

      indexMap.set(key, currentIndex + 1);
      // Return the generated ID
      return includeIndex ? `${key}-${currentIndex}` : `${key}`;
    },
  };
}
