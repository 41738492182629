import { useContext, useMemo } from "react";
import { createIntl, createIntlCache, IntlShape } from "react-intl";
import AppContext from "../AppContext";

// Create an intl cache
const cache = createIntlCache();

// Singleton to store the cached global intl instance
let cachedGlobalIntl: IntlShape | null = null;

export default function useCachedGlobalIntl(): IntlShape {
  const { appUIState } = useContext(AppContext);
  const { locale, translations } = appUIState.appData;

  return useMemo(() => {
    if (!cachedGlobalIntl) {
      // Initialize and cache the global Intl instance
      cachedGlobalIntl = createIntl({ locale, messages: translations }, cache);
    }
    return cachedGlobalIntl;
  }, [locale, translations]);
}
