import {
  NativeButton,
  Overlay,
  ProgressIndicatorLoader,
} from "@rpe-js/marcom-web-components";
import { AccordionItem } from "@rpe-js/marcom-web-components/lib/CustomAccordion/CustomAccordion";
import { findIndex } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import {
  MappedTeams,
  SubTeamRefData,
  TeamsRefData,
} from "../../../shared/types/refData";
import { getTeamsByInput, getTeamsOfInterest } from "../../api/fetchClient";
import { LabelValue } from "../../components/base/LabelValue";
import { PillButtonListView } from "../../components/base/PillButtonListView";
import TypeaheadComponent, {
  typeaheadstandardCssClasses,
} from "../../components/base/Typeahead/TypeaheadComponent";
import { SaveCancelAction } from "../../components/feature/saveAndCancel";
import { TeamOfInterestWidget } from "../../components/feature/widgets/teamsOfInterest/teamOfInterestWidget";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { getCheckboxClasses } from "../../util";
import { mergeSelectedIntoAll } from "../../utils/filterUtil";
import { idGenerator } from "../../utils/idGenerator";
import { FilterCheckboxGroup, FilterOption } from "./FilterCheckboxGroup";

interface TeamsFilterProps {
  teams: Array<MappedTeams>;
  onRemoveTeams: () => void;
  onUpdateTeams: (selectedTeams: Array<TeamFilterOption>) => void;
  onApplyTeams: (teams: Array<TeamFilterOption>) => void;
}

type TeamFilterOption = SubTeamRefData & FilterOption;

function getInitialSelectedTeams(teams: any) {
  if (!teams) return {};
  const teamsObj: Record<string, { subTeam: string[]; isSelectAll: boolean }> =
    {};
  for (let i = 0; i < teams.length; i++) {
    const teamId = `teamsAndSubTeams-${teams[i].teamCode}`;
    const subTeamId = `subTeam-${teams[i].code}`;
    if (teams[i].teamCode) {
      if (!teamsObj[teamId]) {
        teamsObj[teamId] = {
          subTeam: [subTeamId],
          isSelectAll: false,
        };
      } else {
        teamsObj[teamId].subTeam.push(subTeamId);
      }
    }
  }
  return teamsObj;
}

function mapTeamsToFilterOption(teams: Array<MappedTeams>) {
  return teams.map((team: MappedTeams) => {
    return {
      code: team.code,
      id: team.id,
      name: team.name,
      displayName: team.displayName,
      listTitle: team.listTitle,
      teamCode: team.teamCode,
      teamID: team.teamID,
      teamName: team.teamName,
      team_en_US: team.team_en_US,
      uniqueKey: team.uniqueKey,
      subTeam_en_US: team.subTeam_en_US,
      selected: true,
    };
  });
}

export default function TeamsFilter({
  teams,
  onApplyTeams,
  onUpdateTeams,
  onRemoveTeams,
}: TeamsFilterProps) {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const [teamsList, setTeamsList] = useState<TeamsRefData[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<
    Record<string, { subTeam: string[]; isSelectAll: boolean }>
  >(getInitialSelectedTeams(teams));
  const [teamPillButtonList, setTeamPillButtonList] = useState<
    Array<TeamFilterOption>
  >([]);
  const [teamFilters, setTeamFilters] = useState<Array<TeamFilterOption>>(
    mapTeamsToFilterOption(teams),
  );
  const [teamFilterCount, setTeamFilterCount] = useState<number>(0);
  const [showTeamsModal, setShowTeamsModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const ACCORDION_ID = idGenerator(
    "search",
    "teams-filter-accordion",
  ).generateId();
  const SEARCH_VIEW_TEAMS_BTN_ID = idGenerator(
    "search",
    "view-teams",
  ).generateId("button");
  const SEARCH_TEAMS_MODAL_TITLE_ID = idGenerator(
    "search",
    "view-teams",
  ).generateId("title");

  /**
   * This method prepares the selected teams object needed for teams of interest widget
   * @param teamsData
   * @param teamFilters
   * @returns
   */
  const initialSelectedTeams = useCallback(
    (teamsData: Array<TeamsRefData>, teamFilters: Array<TeamFilterOption>) => {
      const updatedSelectedTeams: Record<
        string,
        { subTeam: string[]; isSelectAll: boolean }
      > = {};

      teamFilters.map((teamFilter: TeamFilterOption) => {
        const teamID = teamFilter.teamID;
        if (updatedSelectedTeams[teamID]) {
          updatedSelectedTeams[teamID] = {
            subTeam: [
              ...updatedSelectedTeams[teamID].subTeam,
              teamFilter.id.toString(),
            ],
            isSelectAll: false,
          };
        } else {
          updatedSelectedTeams[teamID] = {
            subTeam: [teamFilter.id],
            isSelectAll: false,
          };
        }
      });

      Object.keys(updatedSelectedTeams).map((teamId: string) => {
        for (let i = 0; i < teamsData.length; i++) {
          if (
            teamsData[i].id == teamId &&
            teamsData[i].teams.length ==
              updatedSelectedTeams[teamId].subTeam.length
          ) {
            updatedSelectedTeams[teamId].isSelectAll = true;
          }
        }
      });
      return updatedSelectedTeams;
    },
    [],
  );

  const getTeamsRefDataInfo = useCallback(async () => {
    let teamsData: Array<TeamsRefData> = [...teamsList];
    if (teamsData.length == 0) {
      teamsData = (await getTeamsOfInterest()) || [];
    }
    setLoading(false);
    setTeamsList(teamsData as any);
    const initialTeams = teamFilters.filter(
      (teamFilter) => teamFilter.selected,
    );
    setTeamPillButtonList([...initialTeams]);
    setSelectedTeams(initialSelectedTeams(teamsData, initialTeams));
  }, [initialSelectedTeams, teamFilters, teamsList]);

  const findFilterById = useCallback(
    (filterList: Array<TeamFilterOption>, subTeam: SubTeamRefData) => {
      return findIndex(
        filterList,
        (option) => option.id == subTeam.id && option.teamID == subTeam.teamID,
      );
    },
    [],
  );

  const updatePillButtonList = useCallback(
    (
      subTeam: SubTeamRefData,
      checked: boolean,
      pillButtonList: Array<TeamFilterOption>,
    ) => {
      const updatedPillButtonList = [...pillButtonList];
      if (checked) {
        const index = findFilterById(updatedPillButtonList, subTeam);
        if (index < 0) {
          updatedPillButtonList.push({
            ...subTeam,
            id: subTeam.id,
            selected: checked,
          });
        }
      } else {
        const index = findFilterById(updatedPillButtonList, subTeam);
        if (index > -1) {
          updatedPillButtonList.splice(index, 1);
        }
      }
      return updatedPillButtonList;
    },
    [findFilterById],
  );

  const onChange = useCallback(
    (updatedTeam: TeamsRefData, subTeam: SubTeamRefData, checked: boolean) => {
      setSelectedTeams((prevSelectedTeams) => {
        const updatedTeams = { ...prevSelectedTeams };
        const teamID = updatedTeam.id;
        const subTeamID = subTeam.id as string;
        updatedTeams[teamID] = checked
          ? updatedTeams[teamID]
            ? {
                subTeam: [...updatedTeams[teamID].subTeam, subTeamID],
                isSelectAll: false,
              }
            : { subTeam: [subTeamID], isSelectAll: false }
          : {
              subTeam:
                updatedTeams[teamID]?.subTeam?.filter(
                  (id) => id !== subTeamID,
                ) || [],
              isSelectAll: false,
            };
        if (updatedTeams[teamID]?.subTeam.length === 0) {
          delete updatedTeams[teamID];
        } else {
          // when all the individual sub teams are checked in a team, set the select/deselect all to true
          if (updatedTeam.teams.length == updatedTeams[teamID].subTeam.length) {
            updatedTeams[teamID] = {
              subTeam: [...updatedTeams[teamID].subTeam],
              isSelectAll: true,
            };
          }
        }

        return updatedTeams;
      });

      setTeamPillButtonList(
        updatePillButtonList(subTeam, checked, [...teamPillButtonList]),
      );
    },
    [teamPillButtonList, updatePillButtonList],
  );

  const onSelectDeselect = useCallback(
    (team: TeamsRefData, checked: boolean) => {
      setSelectedTeams((prevSelectedTeams) => {
        const updatedTeams = { ...prevSelectedTeams };
        updatedTeams[team.id] = checked
          ? {
              subTeam: [...team.teams.map((subTeam) => subTeam.id)],
              isSelectAll: true,
            }
          : {
              subTeam: [],
              isSelectAll: false,
            };
        return updatedTeams;
      });

      // add/remove all subteams in/from team pill button list
      let updatedPillButtonList = [...teamPillButtonList];
      for (let i = 0; i < team.teams.length; i++) {
        updatedPillButtonList = updatePillButtonList(
          team.teams[i],
          checked,
          updatedPillButtonList,
        );
      }
      setTeamPillButtonList([...updatedPillButtonList]);
    },
    [teamPillButtonList, updatePillButtonList],
  );

  const onOpen = useCallback(() => {
    setShowTeamsModal(true);
    setLoading(true);
    getTeamsRefDataInfo();
  }, [getTeamsRefDataInfo]);

  const onClose = useCallback(() => {
    setShowTeamsModal(false);
  }, []);

  const updateTeamFilters = useCallback(
    (id: string, selected: boolean, teamCode?: string) => {
      const updatedTeamFilters = teamFilters.map((team: TeamFilterOption) =>
        team.id === id && team.teamCode == teamCode
          ? { ...team, selected }
          : team,
      );
      setTeamFilters(updatedTeamFilters);
      if (selected) setTeamFilterCount(teamFilterCount + 1);
      else setTeamFilterCount(teamFilterCount - 1);
      const filteredTeams = updatedTeamFilters.filter(
        (teamFilter: TeamFilterOption) => teamFilter.selected,
      );

      if (filteredTeams.length == 0) {
        onRemoveTeams();
      } else {
        onUpdateTeams(filteredTeams);
      }
    },
    [onRemoveTeams, onUpdateTeams, teamFilterCount, teamFilters],
  );

  const onRemovePillButton = useCallback(
    (option: TeamFilterOption) => {
      const index = findFilterById(teamPillButtonList, option);
      if (index >= 0) {
        const updatedPillButtonList = [...teamPillButtonList];
        updatedPillButtonList.splice(index, 1);
        setTeamPillButtonList(updatedPillButtonList);
        // update selected teams
        setSelectedTeams((prevSelectedTeams) => {
          const updatedTeams = { ...prevSelectedTeams };
          Object.keys(updatedTeams).forEach((team) => {
            if (updatedTeams[team].subTeam.includes(option.id)) {
              updatedTeams[team].subTeam = updatedTeams[team].subTeam.filter(
                (sub) => sub !== option.id,
              );
              updatedTeams[team].isSelectAll = false;
            }
          });
          return updatedTeams;
        });
      }
    },
    [findFilterById, teamPillButtonList],
  );

  const onClearAll = useCallback(() => {
    setSelectedTeams({});
    setTeamPillButtonList([]);
  }, []);

  const getNonSelectedTeams = useCallback(
    (
      teamFilters: Array<TeamFilterOption>,
      teamPillButtonList: Array<TeamFilterOption>,
    ) => {
      const allTeamList = [];
      for (let i = 0; i < teamFilters.length; i++) {
        const matchedIndex = findFilterById(teamPillButtonList, teamFilters[i]);
        if (matchedIndex < 0) {
          allTeamList.push({ ...teamFilters[i], selected: false });
        }
      }
      return allTeamList;
    },
    [findFilterById],
  );

  const onApply = useCallback(() => {
    setShowTeamsModal(false);
    setTeamFilters([...teamPillButtonList]);
    setTeamFilterCount(teamPillButtonList.length);
    // merge non selected and selected into one list to show in hiring manager modal
    const previousUnSelectedFilters = getNonSelectedTeams(
      teamFilters,
      teamPillButtonList,
    );

    setTeamFilters([...previousUnSelectedFilters, ...teamPillButtonList]);
    onApplyTeams([...teamPillButtonList]);
  }, [getNonSelectedTeams, onApplyTeams, teamFilters, teamPillButtonList]);

  const handleAddTeams = useCallback(
    (newTeam: TeamFilterOption) => {
      const existingNonSelectedTeams = teamFilters.filter(
        (team) => team.id == newTeam.id && !team.selected,
      );
      const existingSelectedTeams = teamFilters.filter(
        (team) => team.id == newTeam.id && team.selected,
      );
      const updatedFilters = [...teamFilters];
      if (existingNonSelectedTeams.length > 0) {
        const index = findIndex(teamFilters, (team) => team.id == newTeam.id);
        updatedFilters.push({
          ...newTeam,
        });
        updatedFilters.splice(index, 1);
        setTeamFilters([...updatedFilters]);
        onUpdateTeams(updatedFilters.filter((item) => item.selected));
      } else {
        if (existingSelectedTeams.length == 0) {
          const updatedFilters = [...teamFilters];
          updatedFilters.push({
            ...newTeam,
          });
          setTeamFilters([...updatedFilters]);
          onUpdateTeams(updatedFilters.filter((item) => item.selected));
        }
      }
      setTeamFilterCount(
        updatedFilters.filter((filter) => filter.selected).length,
      );
    },
    [onUpdateTeams, teamFilters],
  );

  const onCancel = useCallback(() => {
    setShowTeamsModal(false);
  }, []);

  useEffect(() => {
    const selectedTeamList = [];
    const teamsMap = getInitialSelectedTeams(teams);
    for (const teamKey in teamsMap) {
      for (let i = 0; i < teamsMap[teamKey].subTeam.length; i++) {
        selectedTeamList.push({
          id: teamsMap[teamKey].subTeam[i],
          teamID: teamKey,
        });
      }
    }
    const updatedTeamFilters = [
      ...mergeSelectedIntoAll(teamFilters, selectedTeamList),
    ];
    setTeamFilters(updatedTeamFilters);
    const selectedFilters = updatedTeamFilters.filter(
      (team: TeamFilterOption) => team.selected,
    );
    setTeamFilterCount(selectedFilters.length);
    // setFilters({ team: selectedFilters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams]);

  return (
    <>
      <AccordionItem
        titleWrapperTag={"h3"}
        titleWrapperTagAttrs={{
          className: "d-flex",
        }}
        title={`<span class="d-flex-equal fw-medium">${t("jobsite.savedsearch.teams")}</span>
      <span class="d-inline-block mr-5 mt-0 ${teamFilterCount > 0 ? "counter" : "d-none"}" aria-hidden="true">${teamFilterCount}</span>
      <span class="a11y">${t("jobsite.common.filterAppliedCount", { count: teamFilterCount })}</span>`}
        clickableTitle={true}
        expandableIconsPlus={true}
        noPadding={true}
        index={2}
        id={ACCORDION_ID}
      >
        <TypeaheadComponent
          elementToFocusOnClose="filter-mobile-modal-save-button" // Id to Bring back focus to when
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          apiEndpoint={getTeamsByInput}
          disableSelectedItem={true}
          selectedItems={{ key: "displayName", items: teamFilters as any }}
          onSelect={(e) => {
            handleAddTeams({
              ...(e as MappedTeams),
              selected: true,
            });
          }}
          hideLabel={true}
          removeOnSelect={true}
          getSuggestionLabel={(suggestion) => suggestion?.displayName}
          minChars={2}
          highlightMatches={true}
          showSearchIcon={!isMobile}
          placeholder={t("jobsite.search.enterTeams") as string}
          suggestionPlaceholder={t("jobsite.search.suggestedTeams") as string}
          isInputRequired={false}
          classNames={{
            ...typeaheadstandardCssClasses,
            list: "filter-typeahead-list",
            listItem: "filter-typeahead-list-item",
            button: "filter-typeahead-button",
            suggestionText: "filter-typeahead-suggestion-text",
            suggestionPlaceholder: "filter-typeahead-suggestion-placeholder",
            suggestionIcon: "filter-typeahead-suggestion-icon",
            highlightedItem: "filter-typeahead-highlighted-item",
          }}
          id={idGenerator("filter", "teams-typeahead").generateId()}
          strict={true}
          resetA11y={t("jobsite.search.clearTeamVO") as string}
        />
        <NativeButton
          id={SEARCH_VIEW_TEAMS_BTN_ID}
          onClick={onOpen}
          className="link"
          label={t("jobsite.search.viewAllTeams") as string}
        />
        <div className="mt-10">
          <FilterCheckboxGroup
            id={idGenerator("filter", "teams").generateId()}
            filterList={teamFilters}
            onFilterChange={updateTeamFilters}
            isTeamsFilter={true}
            classNames={getCheckboxClasses(isMobile)}
          ></FilterCheckboxGroup>
        </div>

        {showTeamsModal && (
          <Overlay
            id="teams-modal"
            visible={showTeamsModal}
            wide={true}
            onClose={onClose}
            disableClickAway={true}
            noPadding={true}
            classes={{ root: "custom-overlay-fullscreen" }}
            isFullscreen={isMobile}
            ariaLabel={SEARCH_TEAMS_MODAL_TITLE_ID}
            elementIdToFocus={SEARCH_VIEW_TEAMS_BTN_ID}
            closeButtonAttrs={{
              ariaLabel: t("jobsite.common.close") as string,
            }}
            footerContent={
              <>
                <div className="u-border-top">
                  {!isMobile && (
                    <div className="d-flex selected-options justify-center px-30 pb-10">
                      <PillButtonListView
                        moduleName="filter"
                        selectTranslationLabel={"jobsite.common.countSelected"}
                        countReplacementKey="count"
                        itemAriaLabel={t("jobsite.savedsearch.teams") as string}
                        pillBtnList={teamPillButtonList}
                        pillBtnOptions={{
                          getKey: (data: TeamFilterOption) =>
                            `teams_${data.uniqueKey}`,
                          getLabel: (data: TeamFilterOption) =>
                            `${data.displayName}`,
                          getLabelNode: (data: TeamFilterOption) => (
                            <LabelValue
                              label={data.teamName}
                              value={data.name}
                            ></LabelValue>
                          ),
                        }}
                        onPillBtnRemove={onRemovePillButton}
                        onClearAllBtnClick={onClearAll}
                      ></PillButtonListView>
                    </div>
                  )}
                  <div className="d-flex justify-center px-20 pb-20">
                    <SaveCancelAction
                      onCancel={onCancel}
                      onSave={() => onApply()}
                      cancelLabelName={t("jobsite.common.cancel") as string}
                      saveLabelName={
                        t(
                          isMobile
                            ? "jobsite.profile.yourRoles.done"
                            : "jobsite.search.applyFilters",
                        ) as string
                      }
                      cancelButtonId={idGenerator(
                        "filter",
                        "teams-cancel",
                      ).generateId("button")}
                      saveButtonId={idGenerator(
                        "filter",
                        "teams-save",
                      ).generateId("button")}
                    />
                  </div>
                </div>
              </>
            }
          >
            <>
              {isMobile ? (
                <h2
                  className="mobile-modal-header t-eyebrow"
                  id={SEARCH_TEAMS_MODAL_TITLE_ID}
                >
                  {t("jobsite.savedsearch.teams")}
                </h2>
              ) : (
                <>
                  <h2
                    className="t-headline pt-50 text-center"
                    id={SEARCH_TEAMS_MODAL_TITLE_ID}
                  >
                    {t("jobsite.savedsearch.teams")}
                  </h2>
                  <div className="u-border-bottom teams-modal-header"></div>
                </>
              )}

              <div className="pl-10 pr-10 teams-modal-content">
                <TeamOfInterestWidget
                  teamsList={teamsList}
                  selectedTeams={selectedTeams}
                  onChange={onChange}
                  onSelectDeselectClick={onSelectDeselect}
                  enableSelectDeselectAll={true}
                ></TeamOfInterestWidget>
                {loading && (
                  <div className="d-flex pos-rel teams-modal-loader">
                    <ProgressIndicatorLoader
                      showLoading={loading}
                    ></ProgressIndicatorLoader>
                  </div>
                )}
              </div>
            </>
          </Overlay>
        )}
      </AccordionItem>
    </>
  );
}
