import React from "react";
import useIntlMessage from "../../../hooks/useIntlMessage";
import useIsMobile from "../../../hooks/useIsMobile";

export default function JobDetailHeaderIcons({}) {
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  return (
    <div className={"form-group"}>
      <h2
        id="share-job-header"
        className={`${isMobile ? "t-eyebrow-reduced" : "t-headline-reduced"} share-job-header`}
      >
        {t("jobsite.search.shareRoleDesc") as string}
      </h2>
      <h3
        className={`${isMobile ? "t-body-reduced" : "t-body"}  share-job-header mb-20 align-self-center`}
      >
        {t("jobsite.search.shareRoleHeaderText") as string}
      </h3>
    </div>
  );
}
