import { ProgressIndicatorLoader } from "@rpe-js/marcom-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { MobilityPreference } from "../../../../shared/types/talent/talent";
import { updateTalent } from "../../../api/fetchClient";
import { PROFILE_SECTIONS, YES } from "../../../app.constants";
import { Label } from "../../../components/base/Label";
import RadioGroup from "../../../components/feature/form/radioGroup";
import { SaveCancelAction } from "../../../components/feature/saveAndCancel";
import { useAppAlertContext } from "../../../contexts/AppAlert";
import { useFetchData } from "../../../hooks/useFetchData";
import useFocusFirstErrorField from "../../../hooks/useFocusFirstErrorField";
import useIntlMessage from "../../../hooks/useIntlMessage";
import {
  getYesAndNoRadioOptions,
  getYesOrNoi18nStr,
  getYesOrNoValue,
} from "../../../util";
import { handleScrollToElement } from "../../../utils/focusUtil";
import { idGenerator } from "../../../utils/idGenerator";
import { updateMobilityPreferences } from "../context/Actions";
import { useProfileContext } from "../context/ProfileContext";
import { ProfileSection } from "./profileSection";

export const MobilityPreferencesSection = () => {
  const [isEdit, setEdit] = useState<boolean>(false);
  const { state, dispatch } = useProfileContext();

  const { isLoading, isSuccess, isError, fetchData } =
    useFetchData(updateTalent);
  const [willingToRelocate, setWillingToRelocate] = useState<string | null>(
    getYesOrNoValue(state?.mobilityPreferences?.willingToRelocate),
  );
  const [willingToTravel, setWillingToTravel] = useState<string | null>(
    getYesOrNoValue(state?.mobilityPreferences?.willingToTravel),
  );
  const [relocateError, setRelocateError] = useState<string | null>(null);
  const [travelError, setTravelError] = useState<string | null>(null);
  const { t } = useIntlMessage();
  const { updateAlert, deleteAlert } = useAppAlertContext();
  const mobilityPreferencesIdGenerator = idGenerator(
    "profile",
    "mobilityPreferences",
  );
  const mobilityPreferencesSectionId =
    mobilityPreferencesIdGenerator.generateId();
  const { setFocusFirstErrorField } = useFocusFirstErrorField(
    mobilityPreferencesSectionId,
  );

  const handleRelocateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWillingToRelocate(event.target.value);
    setRelocateError(null);
  };

  const handleTravelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWillingToTravel(event.target.value);
    setTravelError(null);
  };

  const handleSave = () => {
    deleteAlert();
    let isValid = true;

    if (!willingToRelocate) {
      setRelocateError(t("jobsite.common.chooseOption") as string);
      isValid = false;
    }

    if (!willingToTravel) {
      setTravelError(t("jobsite.common.chooseOption") as string);
      isValid = false;
    }

    if (!isValid) {
      setFocusFirstErrorField(true);
      return;
    }

    if (isValid) {
      const payload: { mobilityPreferences: MobilityPreference } = {
        mobilityPreferences: {
          willingToRelocate: willingToRelocate === YES ? true : false,
          willingToTravel: willingToTravel === YES ? true : false,
        },
      };
      fetchData([
        state?.talentId,
        PROFILE_SECTIONS.MOBILITY_PREFERENCES,
        payload,
      ]);
    }
  };

  const handleCancel = useCallback(() => {
    setRelocateError(null);
    setTravelError(null);
    setWillingToRelocate(
      getYesOrNoValue(state?.mobilityPreferences?.willingToRelocate),
    );
    setWillingToTravel(
      getYesOrNoValue(state?.mobilityPreferences?.willingToTravel),
    );
    setEdit(false);
  }, [state?.mobilityPreferences]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(
        updateMobilityPreferences({
          willingToRelocate: willingToRelocate === YES ? true : false,
          willingToTravel: willingToTravel === YES ? true : false,
        }),
      );
      setEdit(false);
      handleScrollToElement(mobilityPreferencesSectionId);
    }
    if (!isLoading && isError) {
      updateAlert(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess, isError]);

  const radioOptions = getYesAndNoRadioOptions("relocateYes", "relocateNo", t);
  const travelOptions = getYesAndNoRadioOptions("travelYes", "travelNo", t);

  return (
    <ProfileSection
      isEdit={isEdit}
      i18nTitle="jobsite.common.mobilityPreferences"
      onEdit={() => setEdit(true)}
      id={mobilityPreferencesSectionId}
      sectionName="mobilityPreferences"
    >
      {isEdit && (
        <>
          {isLoading && <ProgressIndicatorLoader showLoading={true} />}
          <div className="row mb-10">
            <div className="column large-6 small-12 mb-10 pr-15">
              <RadioGroup
                required={true}
                title={t("jobsite.common.willingToRelocate") as string}
                name="relocate"
                options={radioOptions}
                selectedValue={willingToRelocate}
                errorMessage={relocateError ?? ""}
                errorA11y={t("jobsite.common.errorIconLabel") as string}
                onChange={handleRelocateChange}
              />
            </div>
            <div className="column large-6 small-12 mb-10 pr-15">
              <RadioGroup
                required={true}
                title={t("jobsite.common.willingToTravel") as string}
                name="travel"
                options={travelOptions}
                selectedValue={willingToTravel}
                onChange={handleTravelChange}
                errorMessage={travelError ?? ""}
              />
            </div>

            <SaveCancelAction
              onCancel={handleCancel}
              onSave={handleSave}
              cancelButtonId={mobilityPreferencesIdGenerator.generateId(
                "cancelButton",
              )}
              saveButtonId={mobilityPreferencesIdGenerator.generateId(
                "saveButton",
              )}
              scrollToTopSectionId={mobilityPreferencesSectionId}
            />
          </div>
        </>
      )}
      {!isEdit && (
        <div className="row">
          <div className="column large-6 small-12 mb-10">
            <Label label={t("jobsite.common.willingToRelocate") as string} />
            <p>
              {t(
                getYesOrNoi18nStr(
                  state?.mobilityPreferences?.willingToRelocate,
                ),
              )}
            </p>
          </div>

          <div className="column large-6 small-12 mb-10">
            <Label label={t("jobsite.common.willingToTravel") as string} />
            <p>
              {t(
                getYesOrNoi18nStr(state?.mobilityPreferences?.willingToTravel),
              )}
            </p>
          </div>
        </div>
      )}
    </ProfileSection>
  );
};
