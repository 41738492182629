export const ESCAPE_SEQUENCE_REGEX = () =>
  /[~`!#$%\^&*+=\-\[\]\\'();,/{}|\\":<>\?]/g;

export interface TypeaheadCssClasses {
  container?: string;
  inputWrapper?: string; // Class for input wrapper to position icons/buttons
  input?: string;
  list?: string;
  listItem?: string;
  button?: string;
  highlightedItem?: string;
  searchIcon?: string; // Class for main search icon
  suggestionIcon?: string; // Class for conditional suggestion icon
  suggestionText?: string; // Class for suggestion text
  clearButton?: string; // Class for clear button
  suggestionPlaceholder?: string; // Class for suggestion placeholder text
  highlight?: string; // Class for highlighted text in suggestion
}

export interface HandleKeyDownProps<T> {
  e: React.KeyboardEvent;
  isOpen: boolean;
  strict: boolean;
  suggestions: T[];
  suggestionsRef: React.RefObject<HTMLOListElement | HTMLUListElement>;
  highlightedIndex: number;
  inputValue: string;
  inputRef: React.RefObject<HTMLInputElement>;
  query: string;
  setHighlightedIndex: React.Dispatch<React.SetStateAction<number>>;
  handleSuggestionSelect: (suggestion: T) => void;
  handleCustomInputSelect: (input: string) => void;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getSuggestionLabel: (suggestion: T) => string;
}
