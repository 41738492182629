import { Checkbox } from "@rpe-js/marcom-web-components";
import React, { useState } from "react";
import { Answer } from "../../../../shared/types/questionnaire";
import useIntlMessage from "../../../hooks/useIntlMessage";
import ErrorMessage from "../../base/ErrorMessage";

interface MultiChoiceMultiAnsQuesProps {
  questionId: string;
  question: string;
  answerOptions: Array<Answer>;
  instructions: string;
  possibleAnswers: number;
  errorMessage: string;
  updateAnswer: (result: any) => void;
}

function mapper(item: Answer) {
  return {
    answerId: item.answerId,
    selected: false,
  };
}

export function MultiChoiceMultiAnsQues({
  questionId,
  question,
  answerOptions,
  instructions,
  errorMessage,
  updateAnswer,
}: MultiChoiceMultiAnsQuesProps) {
  const { t } = useIntlMessage();
  const [answers, setAnswers] = useState<
    Array<{ answerId: string; selected: boolean }>
  >(answerOptions.map(mapper));
  const optionChange = (answerId: string, checked: boolean) => {
    const selectedAnswer = answers.filter((item) => item.answerId == answerId);
    if (selectedAnswer) {
      selectedAnswer[0].selected = checked;
      setAnswers(answers);
      updateAnswer({ questionId, answer: answerId, answered: checked });
    }
  };
  return (
    <>
      <legend className={errorMessage ? "red mb-10" : "mb-10"}>
        {question}
      </legend>
      {instructions && <p>{instructions}</p>}
      {answerOptions.map((item) => (
        <Checkbox
          required={true}
          id={item.answerId}
          key={item.answerId}
          label={item.label}
          checked={item.answeredMultiple ?? false}
          defaultChecked={false}
          onValueChange={(isChecked: boolean) =>
            optionChange(item.answerId as string, isChecked)
          }
        />
      ))}
      {errorMessage && (
        <ErrorMessage
          errorId={`${questionId}_error`}
          message={errorMessage}
          errorA11y={t("jobsite.common.errorIconLabel") as string}
        ></ErrorMessage>
      )}
    </>
  );
}
