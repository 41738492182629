import { useCallback, useContext } from "react";
import { DEFAULT_LOCALE } from "../app.constants";
import AppContext from "../AppContext";
import useIntlMessage from "./useIntlMessage";

export const PAGE_TITLES = {
  PROFILE: {
    INFORMATION: "jobsite.profile.pageTitle",
    ROLES: "jobsite.profile.yourRoles.pageTitle",
    SAVED_SEARCHES: "jobsite.profile.savedsearch.pageTitle",
  },
  APPLY: {
    RESUME: "jobsite.apply.yourResume.pageTitle",
    SELF_DISCLOSURE: "jobsite.apply.selfDisclosurePageTitle",
    QUESTIONNAIRE: "jobsite.apply.questionnaire.pageTitle",
    REVIEW: "jobsite.apply.reviewPageTitle",
  },
  YOUR_APPLICATION: {
    CONTACT_INFO: "jobsite.profile.myApplication.contactInfoPagetitle",
    SELF_DISCLOSURE: "jobsite.profile.myApplication.selfDisclosurePagetitle",
    INFO_RELEASE: "jobsite.profile.myApplication.infoReleasePagetitle",
    REVIEW: "jobsite.profile.myApplication.reviewPagetitle",
    BACKGROUND: "jobsite.profile.myApplication.empBackPagetitle",
    EDUCATION: "jobsite.profile.myApplication.educationPagetitle",
    EXPERIENCE: "jobsite.profile.myApplication.profExpPagetitle",
    REFERENCE: "jobsite.profile.myApplication.referencePagetitle",
    LEGAL: "jobsite.profile.myApplication.legalPagetitle",
    THANKS: "jobsite.profile.myApplication.thanksPagetitle",
    DISABLED: "jobsite.profile.myApplication.disabledPagetitle",
  },
  ERP: {
    CONTACT_INFO: "jobsite.erp.refInfoPageTitle",
    INTEREST: "jobsite.erp.teamsInterestPageTitle",
    LOCATION: "jobsite.erp.roleLocationPageTitle",
    REVIEW_INFO: "jobsite.erp.reviewPageTitle",
    THANKS_PAGE: "jobsite.erp.submissionPageTitle",
  },
  SIGNED_OUT: "jobsite.common.signedOutPageTitle",
  PRIVACY_POLICY: "jobsite.common.applePrivacyPageTitle",
};

export default function useSetPageTitle() {
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { isInternal, locale, countryCode } = appUIState.appData;
  const titleDomainKeyName =
    isInternal || locale.toLocaleLowerCase() === DEFAULT_LOCALE
      ? (t("jobsite.common.careersAtApple") as string)
      : (t("jobsite.common.jobsAtApple") as string);

  const setPageTitle = useCallback(
    (
      translationKey: string,
      startNo?: string | number,
      endNo?: string | number,
      jobTitle?: string,
    ) => {
      document.title = t(translationKey, {
        titleDomainKeyName,
        startNo,
        endNo,
        jobTitle,
        countryName: countryCode?.countryLocaleCode,
      }) as string;
    },
    [countryCode?.countryLocaleCode, t, titleDomainKeyName],
  );

  return { setPageTitle };
}
