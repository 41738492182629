import { Radio } from "@rpe-js/marcom-web-components";
import React from "react";
import ErrorMessage from "../../base/ErrorMessage";

export interface RadioOption {
  id?: string;
  label: string;
  value: string;
}

type RadioGroupProps = {
  id?: string;
  required: boolean;
  title: string;
  name: string;
  options: RadioOption[];
  selectedValue: string | null;
  columnClassName?: string;
  labelClassName?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  vertical?: boolean;
  errorMessage?: string;
  errorA11y?: string;
  classes?: {
    root?: string;
  };
};

const RadioGroup = ({
  id = "radiogroup",
  required,
  title,
  name,
  options,
  columnClassName = "large-7",
  labelClassName,
  selectedValue,
  onChange,
  vertical = false,
  errorMessage,
  classes,
  errorA11y,
}: RadioGroupProps) => {
  return (
    <fieldset
      id={id}
      className={`${errorMessage ? "is-error" : ""} ${classes?.root || "mt-20"}`}
    >
      <legend className={`${errorMessage ? "red" : ""} form-label mb-10`}>
        {title}
      </legend>
      <div
        className={`${vertical ? "column" : "row"} ${columnClassName} small-12 mb-5`}
      >
        {options.map((option, idx) => (
          <div
            key={`${option.id}`}
            className={`${idx !== options.length - 1 ? (vertical ? "mb-2" : "mr-30") : ""}`}
          >
            <Radio
              id={`${option.id}`}
              required={required}
              name={name}
              value={option.value}
              checked={selectedValue === option.value}
              onValueChange={onChange}
            >
              <span className={labelClassName}>{option.label}</span>
            </Radio>
          </div>
        ))}
      </div>
      {errorMessage && (
        <ErrorMessage
          errorId={`${id}_error`}
          message={errorMessage}
          errorA11y={errorA11y as string}
        ></ErrorMessage>
      )}
    </fieldset>
  );
};

export default RadioGroup;
