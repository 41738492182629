import React from "react";
import { EmploymentDetails } from "../../../../../shared/types/talent/talent";
import { Info } from "../../../../components/base/Info";
import { Label } from "../../../../components/base/Label";
import useIntlMessage from "../../../../hooks/useIntlMessage";

interface EmployeeSummaryViewProps {
  employments: EmploymentDetails[];
  employmentHasAtLeastOneJob: boolean;
  monthOptions: { value: string; label: string }[];
}

export const EmployeeSummaryView = ({
  employments,
  employmentHasAtLeastOneJob,
  monthOptions,
}: EmployeeSummaryViewProps) => {
  const { t } = useIntlMessage();
  const getcurrentEmployer = (currentEmployer: boolean) => {
    if (currentEmployer === true) {
      return "jobsite.common.yes";
    } else if (currentEmployer === false) {
      return "jobsite.common.no";
    }
    return "jobsite.common.notSpecified";
  };

  return (
    <>
      {!employments?.length && employmentHasAtLeastOneJob !== false && (
        <div className="column large-6 small-12 d-flex flex-column">
          <Info value={t("jobsite.common.notSpecified") as string} />
        </div>
      )}
      {!employments?.length && employmentHasAtLeastOneJob === false && (
        <div className="column d-flex flex-column">
          <Label label={t("jobsite.common.empSummaryHistory") as string} />
          <Info value={t("jobsite.common.no") as string} />
        </div>
      )}
      {(employments?.length as number) > 0 && (
        <>
          {employments?.map((employment: EmploymentDetails, index: number) => (
            <div
              className={index !== 0 ? "u-border-top pt-10" : ""}
              key={index}
            >
              <div className="row">
                <div className="column large-6 small-12 d-flex flex-column mb-10">
                  <Label label={t("jobsite.common.employer") as string} />
                  <Info
                    value={
                      employment.employer
                        ? employment.employer
                        : employment.employerName
                    }
                  />
                </div>
                <div className="column large-6 small-12 d-flex flex-column mb-10">
                  <Label
                    label={t("jobsite.common.currentEmployer") as string}
                  />
                  <Info
                    value={
                      t(
                        getcurrentEmployer(
                          employment.currentEmployer as boolean,
                        ),
                      ) as string
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="column large-6 small-12 d-flex flex-column mb-10">
                  <Label label={t("jobsite.common.jobTitle") as string} />
                  <Info value={employment.jobTitle} />
                </div>
              </div>
              {employment.startYear && (
                <div className="row">
                  <div className="column large-6 small-12 d-flex flex-column mb-10">
                    <Label label={t("jobsite.common.startDate") as string} />
                    <Info
                      value={
                        t("jobsite.common.dateDisplayName", {
                          month: monthOptions.find(
                            (month) =>
                              month.value === (employment.startMonthID ?? ""),
                          )?.label,
                          year: employment.startYear,
                        }) as string
                      }
                    />
                  </div>
                  {employment.endYear && (
                    <div className="column large-6 small-12 d-flex flex-column mb-10">
                      <Label label={t("jobsite.common.endDate") as string} />
                      <Info
                        value={
                          t("jobsite.common.dateDisplayName", {
                            month: monthOptions.find(
                              (month) =>
                                month.value === (employment.endMonthID ?? ""),
                            )?.label,
                            year: employment.endYear,
                          }) as string
                        }
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </>
      )}
    </>
  );
};
