import React, { ChangeEvent, useCallback, useState } from "react";
import useIntlMessage from "../../../hooks/useIntlMessage";
import {
  profileUpdateMode,
  ProfileUpdateSelection,
} from "../../../pages/apply/context/ApplyState";
import { ChoiceInput } from "../../../types/ChoiceInput";
import RadioGroup from "../form/radioGroup";

interface ProfileFillOptionProps {
  module?: string;
  selection?: ProfileUpdateSelection;
  options: Array<ChoiceInput>;
  changeProfileOption: (selection: ProfileUpdateSelection) => void;
}

export function ProfileFillOption({
  module,
  selection = profileUpdateMode.MANUAL,
  options,
  changeProfileOption,
}: ProfileFillOptionProps) {
  const [selectedOption, setSelectedOption] = useState(selection);
  const { t } = useIntlMessage();

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSelectedOption(event.target.value as ProfileUpdateSelection);
      changeProfileOption(event.target.value as ProfileUpdateSelection);
    },
    [changeProfileOption],
  );

  return (
    <div className="profile-fill-option-container p-30">
      {module == "profile" && (
        <h3 className="t-tout text-center">
          {t("jobsite.common.profileOptionsLabel")}
        </h3>
      )}
      <RadioGroup
        required={true}
        title=""
        name="profileFillOptions"
        options={options.filter((option) => option.showOption)}
        selectedValue={selectedOption}
        onChange={onChange}
        vertical={true}
        columnClassName="large-12"
      ></RadioGroup>
    </div>
  );
}
