import React from "react";
import { SelfDisclosure } from "../../../../shared/types/talent/talent";
import { SelfDisclosureConfig } from "../../../pages/apply/context/ApplyState";
import {
  SelfDisclosureDisability,
  SelfDisclosureEthnicity,
  SelfDisclosureGender,
  SelfDisclosureNationality,
  SelfDisclosureVeteranStatus,
} from "../selfDisclosure/SelfDisclosureFields";

interface SelfDisclosureSectionProps {
  selfDisclosureConfig: SelfDisclosureConfig | undefined;
  selfDisclosure: SelfDisclosure | undefined;
}
export function SelfDisclosureSection({
  selfDisclosureConfig,
  selfDisclosure,
}: SelfDisclosureSectionProps) {
  return (
    <>
      {selfDisclosureConfig && selfDisclosure && (
        <section className="t-body">
          <section className="row">
            {selfDisclosureConfig.gender && (
              <SelfDisclosureGender
                showLabelBold={true}
                value={selfDisclosure.genderName as string}
              ></SelfDisclosureGender>
            )}

            {selfDisclosureConfig.veteranStatus && (
              <SelfDisclosureVeteranStatus
                showLabelBold={true}
                value={
                  selfDisclosure.veteranStatus?.veteranStatusName as string
                }
              ></SelfDisclosureVeteranStatus>
            )}
          </section>
          <section className="row">
            {selfDisclosureConfig.ethnicity && selfDisclosure.ethnicityName && (
              <SelfDisclosureEthnicity
                showLabelBold={true}
                value={selfDisclosure.ethnicityName}
              ></SelfDisclosureEthnicity>
            )}
            {selfDisclosureConfig.disability && (
              <SelfDisclosureDisability
                showLabelBold={true}
                value={selfDisclosure.disability?.statusName as string}
              ></SelfDisclosureDisability>
            )}
          </section>
          {selfDisclosureConfig && selfDisclosureConfig.nationality && (
            <SelfDisclosureNationality
              showLabelBold={true}
              value={selfDisclosure.nationalityName as string}
            ></SelfDisclosureNationality>
          )}
        </section>
      )}
    </>
  );
}
