import React, { useState } from "react";
import { RefData } from "../../../../../shared/types/refData";
import { getSchoolByInput } from "../../../../api/fetchClient";
import TypeaheadComponent, {
  typeaheadstandardCssClasses,
} from "../../../../components/base/Typeahead/TypeaheadComponent";
import { useFormInputValidator } from "../../../../components/feature/form/inputValidators/useFormInputValidator";
import useIntlMessage from "../../../../hooks/useIntlMessage";

export function EducationSchool(props: {
  id: string;
  elementToFocusOnClose: string;
  onSuggestionSelect: (value: RefData | string) => void;
  onBlur: (value: string | null) => void;
  onReset: () => void;
  initialValue?: string;
  showError?: boolean;
}) {
  const { t } = useIntlMessage();
  const { validateSchoolSkill, onPasteValidateSchoolSkill } =
    useFormInputValidator();
  const [educationInput, setEducationInput] = useState<string | null>(
    props.initialValue || "",
  );
  const {
    id,
    initialValue,
    showError = false,
    onSuggestionSelect,
    onBlur,
    onReset,
  } = props;
  return (
    <TypeaheadComponent
      id={id}
      elementToFocusOnClose={props.elementToFocusOnClose}
      apiEndpoint={getSchoolByInput}
      hideLabel={false}
      label={t("jobsite.common.enterSchool") as string}
      strict={false}
      onSelect={onSuggestionSelect}
      onBlur={onBlur}
      onReset={() => {
        setEducationInput("");
        onReset();
      }}
      getSuggestionLabel={(suggestion: any) => suggestion?.name}
      minChars={1}
      highlightMatches={true}
      showSearchIcon={true}
      initialValue={initialValue}
      onInputChange={(val: string | null) => {
        setEducationInput(val);
      }}
      suggestionPlaceholder={t("jobsite.common.displayTitle") as string}
      classNames={typeaheadstandardCssClasses}
      errorMessage={
        showError && !educationInput
          ? (t("jobsite.common.schoolmsg") as string)
          : null
      }
      errorA11y={t("jobsite.common.errorIconLabel") as string}
      validators={{
        onKeyDown: validateSchoolSkill,
        onPaste: onPasteValidateSchoolSkill,
      }}
    />
  );
}
