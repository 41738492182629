import React from "react";
import {
  EducationDegreeRefData,
  GraduationStatusRefData,
  RefData,
} from "../../../../shared/types/refData";
import { getTypeaheadTextBoxId } from "../../../components/base/Typeahead/typeaheadUtils";
import useIsMobile from "../../../hooks/useIsMobile";
import { idGenerator } from "../../../utils/idGenerator";
import { EducationDegree } from "../components/educationSummary/EducationDegree";
import { EducationGradStatus } from "../components/educationSummary/EducationGradStatus";
import { EducationMajor } from "../components/educationSummary/EducationMajor";
import { EducationSchool } from "../components/educationSummary/EducationSchool";
import { EducationSummaryEditActions } from "../components/educationSummary/EducationSummaryEditActions";
import { EducationDegreeForm } from "../components/educationSummary/form";

type EditEducationSectionProps = {
  degreeOptions: EducationDegreeRefData[];
  gradStatusOptions: GraduationStatusRefData[];
  educationDegreesForm: EducationDegreeForm[];
  displayErrors: boolean;
  mode: "profile" | "parsedmodal";
  handleValueChange: (
    index: number,
    option: any,
    field: "degree" | "gradStatus",
  ) => void;
  onSchoolOrMajorChange: (
    index: number,
    option: string | RefData | null,
    type: "school" | "major",
    event: "select" | "blur" | "reset",
  ) => void;
  onRemoveEducationDegree: (key: number, index: number) => void;
  onAddEducationDegree: (index: number) => void;
};

export const EditEducationSection = ({
  degreeOptions,
  gradStatusOptions,
  educationDegreesForm,
  mode,
  displayErrors,
  handleValueChange,
  onSchoolOrMajorChange,
  onRemoveEducationDegree,
  onAddEducationDegree,
}: EditEducationSectionProps) => {
  const educationIdGenerator = (containerName: string) =>
    idGenerator(mode, containerName);
  const isMobile = useIsMobile();

  return (
    <div>
      {educationDegreesForm.map(
        (educationDegree: EducationDegreeForm, index: number) => {
          const educationMajorId = educationIdGenerator("major").generateId(
            `${index}`,
          );
          const removeEducationDegreeId = idGenerator(
            mode,
            `remove-education-degree`,
          ).generateId(`${index}`);

          const addEducationDegreeId = idGenerator(
            mode,
            `add-education-degree`,
          ).generateId(`${index}`);
          return (
            <div
              className={index !== 0 ? "u-border-top pt-20" : ""}
              key={educationDegree.key}
            >
              <div className="row">
                <div
                  className={`column large-6 small-12 mb-10 ${!isMobile ? "pr-15" : ""}`}
                >
                  <EducationDegree
                    id={educationIdGenerator("degree").generateId(`${index}`)}
                    refData={degreeOptions}
                    onChange={(evt, option) =>
                      handleValueChange(index, option, "degree")
                    }
                    showError={educationDegree.degree.requiredError === true}
                    value={educationDegree.degree.value}
                  />
                </div>
                <div
                  className={`column large-6 small-12 mb-10 ${!isMobile ? "pr-15" : ""}`}
                >
                  <EducationGradStatus
                    id={educationIdGenerator("gradstatus").generateId(
                      `${index}`,
                    )}
                    handleChange={(e) => {
                      handleValueChange(index, e, "gradStatus");
                    }}
                    value={educationDegree.gradStatus.value || ""}
                    showError={
                      educationDegree.gradStatus.requiredError === true
                    }
                    refData={gradStatusOptions}
                  />
                </div>
              </div>
              <div className="row">
                <div
                  className={`column large-6 small-12 mb-10 ${!isMobile ? "pr-15" : ""}`}
                >
                  <EducationSchool
                    elementToFocusOnClose={getTypeaheadTextBoxId(
                      educationMajorId,
                    )}
                    id={educationIdGenerator("school").generateId(`${index}`)}
                    onSuggestionSelect={(e) =>
                      onSchoolOrMajorChange(index, e, "school", "select")
                    }
                    onBlur={(e: string | null) =>
                      onSchoolOrMajorChange(index, e, "school", "blur")
                    }
                    onReset={() =>
                      onSchoolOrMajorChange(index, null, "school", "reset")
                    }
                    initialValue={educationDegree.school.label}
                    showError={
                      displayErrors &&
                      (educationDegree.school.requiredError === true ||
                        educationDegree.school.invalidError === true)
                    }
                  />
                </div>
                <div
                  className={`column large-6 small-12 mb-10 ${!isMobile ? "pr-15" : ""}`}
                >
                  <EducationMajor
                    id={educationMajorId}
                    elementToFocusOnClose={
                      document.getElementById(addEducationDegreeId)
                        ? addEducationDegreeId
                        : removeEducationDegreeId
                    } // set the focus to add button, if add button not present, focus on remove button
                    onSuggestionSelect={(e) =>
                      onSchoolOrMajorChange(index, e, "major", "select")
                    }
                    onBlur={(e: string | null) =>
                      onSchoolOrMajorChange(index, e, "major", "blur")
                    }
                    onReset={() =>
                      onSchoolOrMajorChange(index, null, "major", "reset")
                    }
                    initialValue={educationDegree.major.label}
                    showError={
                      displayErrors &&
                      (educationDegree.major.requiredError === true ||
                        educationDegree.major.invalidError === true)
                    }
                  />
                </div>
              </div>
              <EducationSummaryEditActions
                degreesCount={educationDegreesForm.length}
                index={index}
                onAddEducationDegree={() => {
                  onAddEducationDegree(index);
                }}
                onRemoveEducationDegree={() =>
                  onRemoveEducationDegree(educationDegree.key, index)
                }
                removeButtonId={removeEducationDegreeId}
                addButtonId={addEducationDegreeId}
                mode="profileinformation"
              />
            </div>
          );
        },
      )}
    </div>
  );
};
