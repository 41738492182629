import React from "react";
import { ILocation } from "../app/types/JobDetails";
import { getOGImageURL } from "../app/utils/pageTitleUtil";
import { CountryCode } from "../server/types";
import { I18nValues } from "../shared/types";
import APP_CONSTANTS from "./appConstants";

function getLocalizedTeam(teamArray: Array<any>, queryTeam: string | null) {
  const foundObj = teamArray.find((obj) => obj.code === queryTeam);
  return foundObj ? foundObj : teamArray[0];
}

function getLocalizedLocation(locationObj: ILocation): string {
  const city = locationObj.city || locationObj.metro || null;
  const state = locationObj.stateProvince || locationObj.region || null;
  const country = locationObj.countryName || null;
  let locationString: string = "";
  if (city && city !== country) {
    locationString += city;
    locationString += ", ";
  }
  if (state) {
    locationString += state;
    locationString += ", ";
  }
  if (country) {
    locationString += country;
  }
  return locationString;
}

export function getTeam(
  queryTeam: string | null,
  localizedTeams: any,
  locale: string,
): { teamCode: string; teamName: string } {
  if (!localizedTeams || !localizedTeams[locale]) {
    return { teamCode: "", teamName: "" };
  }
  // const dataLocale = getDataLocale(getLocaleObject(locale));
  const teamsArray = localizedTeams[locale].teams || [];
  const localizedTeam = getLocalizedTeam(teamsArray, queryTeam);
  return { teamCode: localizedTeam.code, teamName: localizedTeam.name };
}

export function getStoreName(locationObj: ILocation) {
  if (!locationObj) {
    return "";
  }
  const store: string =
    locationObj && (locationObj.level === 6 || locationObj.level === 7)
      ? locationObj.name
      : "";
  return store;
}

export function getEEOContent(localization: any, selectedLocale: string) {
  if (!localization || !localization[selectedLocale]) {
    return "";
  }
  return localization[selectedLocale]?.posting?.eeoContent;
}

export function getLocation(
  localizedLocation: any,
  locale: string,
): { location: string | null; store: string | null } {
  if (!localizedLocation || !localizedLocation[locale]) {
    return { location: null, store: null };
  }
  const locationArray = localizedLocation[locale].location;
  return {
    location: (locationArray && getLocalizedLocation(locationArray)) || "",
    store: (locationArray && getStoreName(locationArray)) || "",
  };
}

export function convertTextToList(
  text = "",
  removeDots: boolean,
  delimiter = /[\n]+/,
) {
  let list = text.split(delimiter);
  if (removeDots) {
    list = list.map((li) => li.replace(/•|▪|\s+\./g, ""));
  }
  list = list.map((li) => li.trim());
  return list;
}

export const getLocalizedJobDetails = (
  jobDetails: any,
  selectedLocale = "en-US",
) => {
  let localizedJobDetails = {};

  if (selectedLocale === "en-US" || !jobDetails.localizations) {
    localizedJobDetails = jobDetails;
  } else {
    const selectedLocalizations = jobDetails.localizations[selectedLocale];
    const localizationsPosting = selectedLocalizations
      ? selectedLocalizations.posting
      : {};

    if (localizationsPosting.title) {
      localizationsPosting.postingTitle = localizationsPosting.title;
    }

    localizedJobDetails = {
      ...jobDetails,
      selectedLocale,
      ...localizationsPosting,
    };
  }

  return localizedJobDetails;
};

export function getDirectionForMainContent(jobData: any) {
  let selectedLocale: string = APP_CONSTANTS.DEFAULT_DATA_LOCALE;
  let localeObj;
  if (jobData.selectedLocale) {
    selectedLocale = jobData.selectedLocale;
  }
  if (jobData.localeLanguages) {
    localeObj = jobData.localeLanguages.find(
      (locale: any) => locale.code === selectedLocale,
    );
  }

  return localeObj?.direction ? localeObj.direction : "ltr";
}

export function getDefaultTitle(isInternal: boolean, locale: string, t: any) {
  if (locale === APP_CONSTANTS.DEFAULT_LOCALE) {
    if (isInternal) {
      return t("jobsite.common.careersAtApple") as string;
    } else {
      return t("jobsite.common.careersAtAppleEN.US") as string;
    }
  } else {
    return t("jobsite.common.jobsAtApple") as string;
  }
}

export function getPageTitleAndMeta(
  isInternal: boolean,
  baseUrl: string,
  locale: string,
  t: (id: string, values?: I18nValues) => React.ReactNode,
  postingTitle: string,
  countryCode: CountryCode | null,
  requestUrl: string,
  forLocationPicker?: boolean,
  jobType?: string,
) {
  const description = t("jobsite.jobdetails.metaDescription", {
    jobTitle: postingTitle,
  }) as string;
  const pageTitle =
    getLocalizedTitle(isInternal, locale, t, postingTitle, countryCode) || "";
  const meta = [
    { name: "description", content: description },
    { property: "og:type", content: "website" },
    { property: "og:title", content: pageTitle },
    { property: "og:url", content: requestUrl },
    { property: "og:description", content: description },
    {
      property: "og:image",
      content: getOGImageURL(baseUrl, locale, jobType),
    },
  ];

  if (forLocationPicker) {
    meta.push({ property: "robots", content: "noindex" });
  }

  return { pageTitle, meta };
}

export function getLocalizedTitle(
  isInternal: boolean,
  locale: string,
  t: any,
  postingTitle: any,
  countryCode: CountryCode | null,
) {
  const titleDomainKeyName = getDefaultTitle(isInternal, locale, t);
  const countryLocaleCode = countryCode?.countryLocaleCode;
  const pageTitle = t("jobsite.jobdetails.pageTitle", {
    jobTitle: postingTitle,
    titleDomainKeyName: titleDomainKeyName,
    countryName: countryLocaleCode,
  }) as string;
  return pageTitle;
}
