import React from "react";

export function LabelValue({ label, value }: { label: string; value: string }) {
  return (
    <>
      <span className="label-grey">{label}: </span>
      {value}
    </>
  );
}
