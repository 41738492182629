import { TextButton } from "@rpe-js/marcom-web-components";
import React from "react";
import useIntlMessage from "../../../../hooks/useIntlMessage";

export function EducationSummaryEditActions(props: {
  degreesCount: number;
  index: number;
  onRemoveEducationDegree: () => void;
  onAddEducationDegree: () => void;
  mode?: "profileinformation" | "profileMyApplication";
  addButtonId: string;
  removeButtonId: string;
}) {
  const {
    degreesCount,
    index,
    onAddEducationDegree,
    onRemoveEducationDegree,
    addButtonId,
    removeButtonId,
    mode = "profileinformation",
  } = props;
  const { t } = useIntlMessage();
  return (
    <>
      <div className="d-flex mb-20">
        {!(degreesCount == 1 && index == 0) && (
          <span className="mr-20">
            <TextButton
              label={t("jobsite.common.remove") as string}
              onClick={onRemoveEducationDegree}
              icon="icon-minuscircle"
              id={removeButtonId}
              aria-label={`${t("jobsite.common.removeEntity", { name: `${t("jobsite.common.educationSummaryTitle")} ${index + 1}` })}`}
            />
          </span>
        )}
        {index === degreesCount - 1 && (
          <TextButton
            label={
              t(
                `${mode === "profileinformation" ? "jobsite.common.addAnotherSchool" : "jobsite.profile.myApplication.addInstitution"}`,
              ) as string
            }
            onClick={onAddEducationDegree}
            icon="icon-pluscircle"
            id={addButtonId}
          />
        )}
      </div>
    </>
  );
}
