import {
  Button,
  Overlay,
  ProgressIndicatorLoader,
} from "@rpe-js/marcom-web-components";
import { isEmpty } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router";
import {
  Email,
  EmailCodeGenerationRequest,
  EmailCodeGenerationResponse,
} from "../../../shared/types/emailVerification";
import { VerifyInvitedProfile } from "../../../shared/types/talent/talent";
import {
  generateEmailVerificationCode,
  getPrivacyPolicy,
} from "../../api/fetchClient";
import { LOGOUT } from "../../app.constants";
import AppContext from "../../AppContext";
import ErrorMessage from "../../components/base/ErrorMessage";
import HtmlRenderer from "../../components/base/HtmlRenderer";
import TextIcon from "../../components/base/TextIcon";
import { EmailCodeGenerationContent } from "../../components/feature/emailVerification/emailCodeGeneration/emailCodeGenerationContent";
import { EmailCodeVerificationContent } from "../../components/feature/emailVerification/emailCodeVerification/emailCodeVerificationContent";
import { useLocaleAwareLink } from "../../components/feature/LocaleAwareLink";
import translateReplace from "../../hooks/translateReplace";
import { useFetchData } from "../../hooks/useFetchData";
import useIntlMessage from "../../hooks/useIntlMessage";
import { getFullPath, redirectToPath } from "../../util";
import { idGenerator } from "../../utils/idGenerator";

function useFetchTalentPrivacyStatus() {
  const {
    data: privacyPolicyData,
    isError: isPrivacyPolicyDataError,
    isLoading: isPrivacyPolicyDataLoading,
    isSuccess: isPrivacyPolicyDataSuccess,
    error: privacyPolicyDataError,
    fetchData: fetchprivacyPolicyData,
  } = useFetchData(getPrivacyPolicy);

  return {
    privacyPolicyData,
    isPrivacyPolicyDataError,
    isPrivacyPolicyDataLoading,
    isPrivacyPolicyDataSuccess,
    privacyPolicyDataError,
    fetchprivacyPolicyData,
  };
}

function getCompleteUrl(relativePath: string) {
  return `${window.location.protocol}//${window.location.host}${relativePath}`;
}

interface VerifyEmailModal {
  showVerifyAccessDetailsModal: boolean;
  showVerifyEmailModal: boolean;
  showCodeGenerationModal: boolean;
}

export function VerifyEmail() {
  const [verifyEmailModalConfig, setVerifyEmailModal] =
    useState<VerifyEmailModal>();
  const { appUIState } = useContext(AppContext);
  const { appData } = appUIState;
  const { locale, appBasePathPrefix, isExternal } = appData;
  const [emailCodeGeneratedData, setEmailCodeGeneratedData] = useState<Email>();
  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get("returnUrl");
  const getLink = useLocaleAwareLink();

  const { t } = useIntlMessage();
  const signOutId = idGenerator("privacypolicy", "verifyemail").generateId(
    "signout",
  );

  const onSignout = useCallback(() => {
    if (isExternal) {
      const routePath = `${locale}/${LOGOUT}?action=${LOGOUT}`;
      redirectToPath(appBasePathPrefix, routePath);
    } else {
      redirectToPath(appBasePathPrefix, `${locale}/logout-page`);
    }
  }, [appBasePathPrefix, isExternal, locale]);

  const {
    fetchprivacyPolicyData,
    isPrivacyPolicyDataLoading,
    isPrivacyPolicyDataError,
    isPrivacyPolicyDataSuccess,
    privacyPolicyData,
  } = useFetchTalentPrivacyStatus();

  useEffect(() => {
    if (
      !isPrivacyPolicyDataLoading &&
      !privacyPolicyData &&
      !isPrivacyPolicyDataSuccess
    ) {
      fetchprivacyPolicyData();
    }

    if (
      !isPrivacyPolicyDataLoading &&
      privacyPolicyData &&
      isPrivacyPolicyDataSuccess &&
      !verifyEmailModalConfig
    ) {
      if (
        privacyPolicyData.verifyInvitedProfile &&
        !isEmpty(privacyPolicyData.verifyInvitedProfile)
      ) {
        setVerifyEmailModal({
          showVerifyAccessDetailsModal: true,
          showVerifyEmailModal: false,
          showCodeGenerationModal: false,
        });
      } else if (privacyPolicyData.preferredEmailVerified === false) {
        setVerifyEmailModal({
          showVerifyAccessDetailsModal: false,
          showVerifyEmailModal: true,
          showCodeGenerationModal: false,
        });
      } else {
        setVerifyEmailModal({
          showVerifyAccessDetailsModal: false,
          showVerifyEmailModal: false,
          showCodeGenerationModal: false,
        });
      }
    }
  }, [
    fetchprivacyPolicyData,
    isPrivacyPolicyDataLoading,
    privacyPolicyData,
    isPrivacyPolicyDataSuccess,
    verifyEmailModalConfig,
  ]);

  const onCodeGenerate = useCallback(
    (res: Email) => {
      if (res?.response?.preferredEmailVerified) {
        setVerifyEmailModal({
          showCodeGenerationModal: false,
          showVerifyAccessDetailsModal: false,
          showVerifyEmailModal: false,
        });
        // In app routing cannot be done here as we want to trigger middlewares to check for privacy policy acceptance
        // Invoking a window location href will reload the app , trigger the middlewares.
        window.location.href = getCompleteUrl(
          returnUrl || getLink(false, "search"),
        );
      } else {
        setEmailCodeGeneratedData(res);
        setVerifyEmailModal({
          showCodeGenerationModal: true,
          showVerifyAccessDetailsModal: false,
          showVerifyEmailModal: false,
        });
      }
    },
    [returnUrl, getLink],
  );

  const onCodeVerificationCancel = useCallback(() => {
    setVerifyEmailModal({
      showVerifyAccessDetailsModal: !isEmpty(
        privacyPolicyData?.verifyInvitedProfile,
      ),
      showCodeGenerationModal: false,
      showVerifyEmailModal:
        isEmpty(privacyPolicyData?.verifyInvitedProfile) &&
        !privacyPolicyData?.preferredEmailVerified,
    });
  }, [privacyPolicyData]);

  const onCodeVerificationSuccess = useCallback(() => {
    setVerifyEmailModal({
      showCodeGenerationModal: false,
      showVerifyAccessDetailsModal: false,
      showVerifyEmailModal: false,
    });
    // In app routing cannot be done here as we want to trigger middlewares to check for privacy policy acceptance
    // Invoking a window location href will reload the app , trigger the middlewares.
    window.location.href = getCompleteUrl(
      returnUrl || getLink(false, "search"),
    );
  }, [returnUrl, getLink]);

  const onVerifyEmailCodeGenSuccess = useCallback((res: Email) => {
    setEmailCodeGeneratedData(res);
    setVerifyEmailModal({
      showCodeGenerationModal: true,
      showVerifyAccessDetailsModal: false,
      showVerifyEmailModal: false,
    });
  }, []);

  return (
    <>
      {isPrivacyPolicyDataLoading && (
        <ProgressIndicatorLoader showLoading={true} />
      )}
      {isPrivacyPolicyDataError && (
        <>
          <div>
            <div>
              <h1>{t("jobsite.general.serviceError")}</h1>
            </div>
            <Button
              id={signOutId}
              size="base"
              color="primary"
              blockedVariant={true}
              label={t("jobsite.common.signOut") as string}
              onClick={onSignout}
            />
          </div>
        </>
      )}

      {!isPrivacyPolicyDataLoading && privacyPolicyData && (
        <>
          {verifyEmailModalConfig?.showVerifyAccessDetailsModal && (
            <Overlay
              id={idGenerator("verifyEmail", "accesscontent").generateId(
                "overlay",
              )}
              elementIdToFocus="" // passing empty Id here as this is not a user triggered action. Once complete the page reloads and follow the focus of the page.
              visible={verifyEmailModalConfig?.showVerifyAccessDetailsModal}
              onClose={() => {}}
              noCloseButton={true}
            >
              <VerifyEmailAccessContent
                verifyInvitedProfile={
                  privacyPolicyData.verifyInvitedProfile as VerifyInvitedProfile
                }
                onCodeGenSuccess={onVerifyEmailCodeGenSuccess}
                onSignOut={onSignout}
              />
            </Overlay>
          )}
          {verifyEmailModalConfig?.showVerifyEmailModal && (
            <Overlay
              id={idGenerator("verifyEmail", "codegenerate").generateId(
                "overlay",
              )}
              elementIdToFocus="" // passing empty Id here as this is not a user triggered action. Once complete the page reloads and follow the focus of the page.
              visible={verifyEmailModalConfig?.showVerifyEmailModal}
              onClose={() => {}}
              noCloseButton={true}
            >
              <EmailCodeGenerationContent
                t={t}
                onCancel={onSignout}
                onContinue={(selected) => onCodeGenerate(selected)}
                canceli18nLabel="jobsite.common.signOut"
              />
            </Overlay>
          )}
          {verifyEmailModalConfig?.showCodeGenerationModal && (
            <Overlay
              id={idGenerator("verifyEmail", "codeverify").generateId(
                "overlay",
              )}
              elementIdToFocus="" // passing empty Id here as this is not a user triggered action. Once complete the page reloads and follow the focus of the page.
              visible={verifyEmailModalConfig.showCodeGenerationModal}
              noCloseButton={true}
              onClose={() => {}}
              wide={false}
            >
              <EmailCodeVerificationContent
                t={t}
                emailData={emailCodeGeneratedData as Email}
                onCancel={onCodeVerificationCancel}
                onAction={onCodeVerificationSuccess}
              />
            </Overlay>
          )}
        </>
      )}
    </>
  );
}

interface VerifyEmailAccessContentProps {
  onSignOut: () => void;
  onCodeGenSuccess: (res: Email) => void;
  verifyInvitedProfile: VerifyInvitedProfile;
}

function VerifyEmailAccessContent(props: VerifyEmailAccessContentProps) {
  const { t } = useIntlMessage();
  const { appUIState } = useContext(AppContext);
  const { appData } = appUIState;
  const [disableContinueButton, setDisableContinueButton] = useState(false);
  const { locale, appBasePathPrefix, isExternal } = appData;
  const [serviceErrorMessage, setServiceErrorMessage] = useState(""); //State variable which stores service side validation errors
  const { verifyInvitedProfile, onSignOut, onCodeGenSuccess } = props;
  const {
    data: generateEmailCodeResponse,
    isLoading: generateEmailCodeLoading,
    isSuccess: generateEmailCodeSuccess,
    isError: isGenerateEmailCodeError,
    fetchData: generateEmailCode,
    resetFetchStatus: generateEmailCodeResetStatus,
  } = useFetchData(generateEmailVerificationCode);

  const getPayload = useCallback(() => {
    const emailObj: EmailCodeGenerationRequest = {
      emailId: verifyInvitedProfile.invitedEmailId,
      locale,
      verifyAccess: true,
    };
    return emailObj;
  }, [verifyInvitedProfile, locale]);

  const triggerEmail = useCallback(async () => {
    const emailObj = getPayload();
    generateEmailCode([emailObj]);
  }, [generateEmailCode, getPayload]);

  useEffect(() => {
    if (!generateEmailCodeLoading && isGenerateEmailCodeError) {
      setServiceErrorMessage(t("jobsite.common.serverError") as string);
      generateEmailCodeResetStatus();
    }

    if (!generateEmailCodeLoading && generateEmailCodeSuccess) {
      if (
        typeof generateEmailCodeResponse?.canGenerateNewCode !== undefined &&
        !generateEmailCodeResponse?.canGenerateNewCode &&
        generateEmailCodeResponse?.code === 1009
      ) {
        setDisableContinueButton(true);
        setServiceErrorMessage(t("jobsite.common.reachedMaxLimit") as string);
      } else {
        onCodeGenSuccess({
          request: getPayload(),
          response: generateEmailCodeResponse as EmailCodeGenerationResponse,
        });
      }
      generateEmailCodeResetStatus();
    }
  }, [
    generateEmailCodeLoading,
    generateEmailCodeResetStatus,
    generateEmailCodeResponse,
    generateEmailCodeSuccess,
    getPayload,
    isGenerateEmailCodeError,
    onCodeGenSuccess,
    t,
  ]);

  const getSignoutUrl = useCallback(() => {
    if (isExternal) {
      const routePath = `${locale}/${LOGOUT}?action=${LOGOUT}`;
      return getFullPath(appBasePathPrefix, routePath);
    } else {
      return getFullPath(appBasePathPrefix, `${locale}/logout-page`);
    }
  }, [appBasePathPrefix, isExternal, locale]);

  return (
    <>
      <section>
        <div>
          <div className="d-flex align-center">
            <TextIcon name="icon-exclamationsolid" textColor="warning" />
            <h3 className="ml-5 text-left">
              {t("jobsite.common.verifyAccess")}
            </h3>
          </div>
          <div className="pt-10 ml-30">
            <HtmlRenderer
              classes="hyperlink-underline"
              htmlTag="p"
              initialContent={translateReplace(
                t("jobsite.common.verifyEmailDescription") as string,
                ["{sendTo}", "{appleId}"],
                [
                  verifyInvitedProfile.invitedEmailId,
                  verifyInvitedProfile.loggedInUserEmailId,
                ],
              )}
            />
          </div>
        </div>
        <div className="mt-20 pr-20">
          <div className="text-left">
            {serviceErrorMessage && (
              <ErrorMessage
                message={serviceErrorMessage}
                errorA11y={t("jobsite.common.errorIconLabel") as string}
              />
            )}
          </div>
        </div>
        <div className="mt-30 d-flex justify-end w-100">
          <div className="d-inline-block">
            <Button
              id={idGenerator("email", "verify-modal").generateId(
                "signout-button",
              )}
              size="base"
              label={t("jobsite.common.signOut") as string}
              color="secondary"
              blockedVariant={true}
              onClick={onSignOut}
            ></Button>
          </div>
          <div className="ml-20 d-inline-block">
            <Button
              id={idGenerator("email", "verify-modal").generateId(
                "continue-button",
              )}
              size="base"
              label={t("jobsite.common.continue") as string}
              color="primary"
              blockedVariant={true}
              onClick={triggerEmail}
              disabled={disableContinueButton}
            ></Button>
          </div>
        </div>
        <div className="mt-30 ml-30 d-flex justify-start w-100">
          <HtmlRenderer
            htmlTag="p"
            initialContent={translateReplace(
              t("jobsite.common.notYouCreateAppleID") as string,
              ["{linkurl}"],
              [getSignoutUrl()],
            )}
          />
        </div>
      </section>
    </>
  );
}
