import { getApplicationStepData } from "../../../../api/fetchClient";
import { useFetchData } from "../../../../hooks/useFetchData";

export function useFetchApplicationStepData() {
  const {
    data: applicationStepRefData,
    isError: isApplicationStepDataError,
    isLoading: isApplicationStepDataLoading,
    isSuccess: isApplicationStepDataSuccess,
    error: applicationStepDataError,
    fetchData: fetchApplicationStepData,
  } = useFetchData(getApplicationStepData);

  return {
    applicationStepRefData,
    isApplicationStepDataError,
    isApplicationStepDataLoading,
    isApplicationStepDataSuccess,
    applicationStepDataError,
    fetchApplicationStepData,
  };
}
