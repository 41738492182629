import { AccordionItem } from "@rpe-js/marcom-web-components/lib/CustomAccordion/CustomAccordion";
import React, { useCallback, useEffect, useState } from "react";
import useIntlMessage from "../../hooks/useIntlMessage";
import { mergeSelectedIntoAll } from "../../utils/filterUtil";
import { idGenerator } from "../../utils/idGenerator";
import { FilterCheckboxGroup, FilterOption } from "./FilterCheckboxGroup";
interface CheckboxFilterProps<T> {
  title: string;
  data: Array<T>;
  onUpdateSelectedItems: (items: Array<T>) => void;
  onRemoveAllItems: () => void;
  selectedCount: number;
  isAccordionOpen: boolean;
  minElementsForShowMore?: number;
  legendLabel: string;
  idPrefix: string;
  filterParamKey?: string; // Dynamic query parameter key
  index: number;
  accordionId: string;
  classNames?: {
    listWrapper?: string; // Class for wrapper of checkbox to show in column view
    input?: string; // Class for checkbox input to position in a tile
    label?: string; // Class for label to position as a tile
  };
}

const CheckboxFilter = <T extends FilterOption>({
  title,
  data,
  onUpdateSelectedItems,
  onRemoveAllItems,
  selectedCount,
  minElementsForShowMore = 5,
  legendLabel,
  idPrefix,
  index,
  accordionId,
  classNames,
}: CheckboxFilterProps<T>) => {
  const { t } = useIntlMessage();
  const [items, setItems] = useState<T[]>(data);
  const [showMore, setShowMore] = useState(false);
  const [count, setCount] = useState(selectedCount);

  const handleItemSelect = useCallback(
    (id: string, selected: boolean) => {
      const updatedItems = items.map((item) =>
        item.id === id ? { ...item, selected } : item,
      );
      setItems(updatedItems);

      const selectedItems = updatedItems.filter((item) => item.selected);
      setCount(selectedItems.length);

      if (selectedItems.length === 0) {
        onRemoveAllItems();
      } else {
        onUpdateSelectedItems(selectedItems);
      }
    },
    [items, onRemoveAllItems, onUpdateSelectedItems],
  );

  useEffect(() => {
    const selectedData = data.filter((d) => d.selected);
    const updatedItems = [...mergeSelectedIntoAll(items, selectedData)];
    setItems(updatedItems);
    setCount(selectedData.length);
    // const selectedItems = updatedItems.filter((item) => item.selected);
    // setFilters({ [filterParamKey]: selectedItems }); // Use dynamic key
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <AccordionItem
        titleWrapperTag={"h3"}
        titleWrapperTagAttrs={{
          className: "d-flex",
        }}
        clickableTitle={true}
        noPadding={true}
        title={`<span class="d-flex-equal fw-medium">${title}</span>
                <span class="d-inline-block mr-5 mt-0 ${
                  count > 0 ? "counter" : "d-none"
                }" aria-hidden="true">${count}</span>
                <span class="a11y">${t("jobsite.common.filterAppliedCount", { count: count })}</span>`}
        expandableIconsPlus={true}
        index={index}
        id={accordionId}
      >
        <FilterCheckboxGroup
          id={idGenerator("search", `filter-${idPrefix}`).generateId()}
          title={title}
          classNames={classNames}
          filterList={items}
          legendLabel={legendLabel}
          showMoreEnabled={true}
          showMore={showMore}
          setShowMore={setShowMore}
          minElementsForShowMore={minElementsForShowMore}
          onFilterChange={(id, selected) => handleItemSelect(id, selected)}
        />
      </AccordionItem>
    </>
  );
};

export default CheckboxFilter;
