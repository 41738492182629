import dateUtils from "@rpe-js/core/dist/util/dateUtil";
import React, { useCallback, useContext } from "react";
import { DEFAULT_LOCALE } from "../../app.constants";
import AppContext from "../../AppContext";
import RouterLinkComponent, {
  RouterLinkProps,
} from "../base/RouterLinkComponent";

interface LocaleAwareLinkProps extends RouterLinkProps {
  addAppPrefix?: boolean;
}

export function useLocaleAwareLink() {
  const { appUIState } = useContext(AppContext);
  const currentLocale = appUIState.appData.locale || DEFAULT_LOCALE;
  const appBasePathPrefix = appUIState.appData.appBasePathPrefix;

  const getLink = useCallback(
    (addAppPrefix: boolean, link: string) => {
      const localeAwareLinkUrl = `${appBasePathPrefix}${addAppPrefix ? "/app" : ""}/${currentLocale}/${link}`;
      return localeAwareLinkUrl;
    },
    [currentLocale, appBasePathPrefix],
  );

  return getLink;
}

function LocaleAwareLink(props: LocaleAwareLinkProps) {
  const {
    link,
    addAppPrefix = false,
    classes,
    ariaLabel,
    suffixIcon,
    id,
    state,
    ...rest
  } = props;

  const getLink = useLocaleAwareLink();

  const localeAwareLinkUrl = getLink(addAppPrefix, link || "");

  return (
    <RouterLinkComponent
      link={localeAwareLinkUrl}
      {...rest}
      classes={classes}
      ariaLabel={ariaLabel}
      suffixIcon={suffixIcon}
      id={id}
      state={state}
    />
  );
}

export function LocaleAwareDateFormat(props: { dateInMillis: number }) {
  const { appUIState } = useContext(AppContext);
  const currentLocale = appUIState.appData.locale || DEFAULT_LOCALE;
  return props.dateInMillis
    ? dateUtils.formatDateByLocale(props.dateInMillis, currentLocale)
    : "";
}

export default LocaleAwareLink;
